export const LoadboardAdminIcon = () => {
    return (
        <svg width="50%" height="30" viewBox="0 0 66 41" style= {{backgroundColor: 'Orange', margin: '25%'}} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.23592 31.4336H2.73438C2.73438 32.521 3.16634 33.5638 3.93523 34.3327C4.70413 35.1016 5.74697 35.5336 6.83436 35.5336H7.55185C7.68605 34.0281 8.27319 32.5987 9.23592 31.4336Z" fill="white"/>
            <path d="M41.0002 31.4336H20.832C21.7947 32.5987 22.3817 34.0282 22.5158 35.5336H41.0002C41.3627 35.5336 41.7103 35.3896 41.9666 35.1333C42.2229 34.877 42.3669 34.5293 42.3669 34.1669V32.8003C42.3669 32.4378 42.2229 32.0902 41.9666 31.8339C41.7103 31.5776 41.3627 31.4336 41.0002 31.4336Z" fill="white"/>
            <path d="M15.0342 41.0001C17.676 41.0001 19.8175 38.8586 19.8175 36.2169C19.8175 33.5751 17.676 31.4336 15.0342 31.4336C12.3925 31.4336 10.251 33.5751 10.251 36.2169C10.251 38.8586 12.3925 41.0001 15.0342 41.0001Z" fill="white"/>
            <path d="M64.9879 22.9557L59.7368 11.4036C59.3014 10.4491 58.6011 9.63962 57.719 9.07155C56.837 8.50349 55.8103 8.20071 54.7612 8.19922H46.4673C46.1048 8.19922 45.7572 8.34321 45.5009 8.59952C45.2446 8.85582 45.1006 9.20345 45.1006 9.56592V31.0674C46.0913 30.0122 47.366 29.2659 48.7711 28.9185C50.1762 28.571 51.6517 28.6372 53.0201 29.109C54.3884 29.5809 55.5912 30.4382 56.4835 31.5779C57.3758 32.7175 57.9196 34.0908 58.0494 35.5324H61.5005C62.5879 35.5324 63.6307 35.1004 64.3996 34.3315C65.1685 33.5626 65.6005 32.5198 65.6005 31.4324V25.7833C65.6005 24.8078 65.3916 23.8437 64.9879 22.9557ZM58.7569 21.8657H49.8839C49.5214 21.8657 49.1739 21.7217 48.9176 21.4654C48.6613 21.2092 48.5173 20.8616 48.5173 20.4992V15.0325C48.5173 14.67 48.6613 14.3224 48.9176 14.0661C49.1739 13.8098 49.5215 13.6658 49.884 13.6658H55.3922C55.9169 13.6657 56.4306 13.8167 56.8718 14.1007C57.3131 14.3847 57.6632 14.7897 57.8805 15.2673L60.0015 19.9336C60.0961 20.1418 60.137 20.3705 60.1203 20.5986C60.1037 20.8267 60.0301 21.047 59.9062 21.2392C59.7824 21.4315 59.6123 21.5896 59.4115 21.6991C59.2106 21.8086 58.9856 21.8659 58.7569 21.8659V21.8657Z" fill="white"/>
            <path d="M50.5665 41.0001C53.2082 41.0001 55.3497 38.8586 55.3497 36.2169C55.3497 33.5751 53.2082 31.4336 50.5665 31.4336C47.9247 31.4336 45.7832 33.5751 45.7832 36.2169C45.7832 38.8586 47.9247 41.0001 50.5665 41.0001Z" fill="white"/>
            <path d="M38.2671 0H6.83379C5.74641 0 4.70356 0.431961 3.93466 1.20086C3.16577 1.96975 2.73381 3.0126 2.73381 4.09998V20.4999H1.43975C0.759666 20.4999 0.128653 20.965 0.0192349 21.637C-0.0140076 21.8331 -0.00407273 22.0341 0.0483469 22.226C0.100767 22.4179 0.194409 22.596 0.322746 22.748C0.451082 22.9 0.611024 23.0222 0.791418 23.106C0.971812 23.1898 1.16832 23.2332 1.36723 23.2333H4.71128C5.39213 23.2333 6.02302 23.6984 6.13244 24.3704C6.16568 24.5665 6.15574 24.7675 6.10332 24.9594C6.0509 25.1513 5.95726 25.3295 5.82893 25.4814C5.70059 25.6334 5.54065 25.7556 5.36025 25.8394C5.17986 25.9232 4.98335 25.9667 4.78444 25.9667H1.44039C0.759666 25.9666 0.128653 26.4321 0.0192349 27.1036C-0.0140076 27.2997 -0.00407273 27.5007 0.0483469 27.6926C0.100767 27.8845 0.194409 28.0626 0.322746 28.2146C0.451082 28.3666 0.611024 28.4887 0.791418 28.5725C0.971812 28.6563 1.16832 28.6998 1.36723 28.6999H41.0003C41.3628 28.6999 41.7104 28.5559 41.9667 28.2996C42.2231 28.0433 42.367 27.6956 42.367 27.3332V4.09998C42.367 3.0126 41.9351 1.96975 41.1662 1.20086C40.3973 0.431961 39.3545 0 38.2671 0Z" fill="white"/>
        </svg>
        
    )
}
