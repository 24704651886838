import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

/************************Add prospective client************************* */
export const addProspectiveClientApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.addprospectiveclient,
            method: 'post',
            data: data
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Adding prospective client failed');
    }
};

/************************Add prospective in bulk client************************* */
export const addProspectiveClientInBulkApiCall = async (fileName) => {
    try {
        let response = await axios({
            url: environmentVariables.addprospectiveclientsinbulk,
            method: 'post',
            data: fileName
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Adding prospective client in bulk failed');
    }
};

/* ************************ Get prospective clients Api Call************************* */
export const getprospectiveclientsApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.getprospectiveclients,
            method: 'get',
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Get clients error');
    }
};
