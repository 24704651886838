import React from 'react';
import './HeaderChat.css';
import Avatar from '@mui/material/Avatar';

export default function HeaderChat(props) {
    return (
        <div className="headerChat">
            <div className="headerChatLeft">
                <Avatar src={props.avatar}>{props.name.charAt(0).toUpperCase()} </Avatar>
                <div className="title">{props.name} {(props.companyName !== undefined && props.companyName !== '') && ('(' + props.companyName + ')')}
                </div>
            </div>
            <div className="headerChatRight">
                {
                    (props.mcnumber !== '') && (
                        <div>
                            <span className="titleMcNumber">{props.mcnumber}</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
