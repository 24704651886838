import React from 'react';

import Footer from '../../components/Footer';
import ResetPassword from '../../module/ResetPassword';

const ResetPasswordPage = () => {
    return (
        <div>
            <ResetPassword />
            <Footer />
        </div>
    );
};

export default ResetPasswordPage;