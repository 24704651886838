import React, { useState } from 'react';
// import feedback
import FeedbackUser from '../../module/feedback';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Fab from '@mui/material/Fab';
import { Grid, Tooltip } from '@material-ui/core';

const FloatingButtonForFeedBack = () => {

    //Constant To handle feedBack
    const [feedBackOpen, setFeedBackOpen] = useState(false);

    /*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@Function To Handle FeedBack@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/
    const handleOpenFeedBack = (event) => {
        setFeedBackOpen(event);
    };

    const handleCloseFeedBack = (event) => {
        setFeedBackOpen(event);
    };

    return (
        <div>
            {
                (feedBackOpen !== true) ?
                    <div>
                        <Grid>
                            <Tooltip title="FeedBack">
                                <Fab id={window.location.pathname !== '/' ? 'sticky' : 'centerOfPage'} color="primary" onClick={() => handleOpenFeedBack(true)} >
                                    <FeedbackIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </div>
                    :
                    <FeedbackUser closeForm={handleCloseFeedBack} />
            }
        </div>
    );
};
export default FloatingButtonForFeedBack;