import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

{/* ************************ Load API calls ************************* */ }

export const createLoadApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.createload,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const createLoadNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.createloadnotification,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const fetchLoadApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getcarrierloadboard + '?carrier_name=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};


export const getAwardedLoadsApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getawardedloads + '?carrier_name=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'get awarded Loads Error');
    }
};

export const getBidsWonLoadsApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getwonloads + '?carrier_name=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'get loads for bids won Error');
    }
};

export const getExpiredLoadsApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getexpiredloads + '?carrier_name=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'get Expired Loads Error');
    }
};

export const getExpiredBidsApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getbidhistory + '?carrier_name=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'get Expired Bids Loads Error');
    }
};

export const getDriverLoadsApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getdriverloads + '?driver_name=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    } 
    catch (error) {
        console.log(error, 'get Driver Loads Error');
    }
};

export const editLoadDetailApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.editloaddetail,
            method: 'patch',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'error in updating the loadetails');
    }

};

export const deleteLoadApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.deleteloaddetail,
            method: 'patch',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'error in deleting loadetails');
    }
};
