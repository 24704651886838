import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Grid,
    Switch,
    Snackbar,
    Paper,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Row } from 'react-bootstrap';
import MUIDrawer from '../../components/Drawer'
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import Divider from '@mui/material/Divider';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { getAllUserInformationApiCall, updateLoadeoUserDetailsApiCall } from '../../apis/user';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 10,
        height: '80vh'
    },
    hide: {
        display: 'none',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        marginTop: '1%'
    }

}));

const EmailSettings = () => {
    const userReduxData = useSelector((state) => {
        return state.userReducer
      });
    const classes = useStyles();
    const { user } = useAuth0();
    const history = useHistory();
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    // All variables
    const [user_id, setUser_id] = React.useState('');
    const [user_name, setUser_name] = React.useState('');
    const [user_first_name, setUser_first_name] = React.useState('');
    const [user_last_name, setUser_last_name] = React.useState('');

    // Constant to handle logged in user role
    // const [userReduxData.userData.user_role, setuserReduxData.userData.user_role] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in company role
    // const [userReduxData.userData.company_role, setuserReduxData.userData.company_role] = useState(window.localStorage.getItem('company_role'));

    //Swith and Circular
    const [loading, setLoading] = useState('');
    const [allEmailStatus, setAllEmailStatus] = useState('activate');
    const [toggleStatus, setToggleStatus] = useState('activate');
    const [bidStatus, setBidStatus] = useState('activate');
    const [bidAcceptStatus, setBidAcceptStatus] = useState('activate');
    const [saveLaneStatu, setSaveLaneStatus] = useState('activate');
    const [counterOfferStatus, setCounterOfferStatus] = useState('activate');
    const [counterOfferAcceptStatus, setCounterOfferAcceptStatus] = useState('activate');


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    //Function to check the user role
    const checkUserRole = () => {
        if (window.localStorage.ifUserExist) {
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER && userReduxData.userData.user_role !== "Driver") {
            history.push({ pathname: '/callback' });
        }
    }

    //Function to Check Email activation 
    const getAllResponse = async () => {
        let alluserinformationdata = await getAllUserInformationApiCall();
        if (alluserinformationdata !== undefined) {
            const findUser = alluserinformationdata.find(
                ({ email_address }) => email_address === user.email
            );
            setLoading('Success');
            if (findUser.email_settings !== null) {
                if (findUser.email_settings.length !== 0) {
                    setAllEmailStatus('deactivate');
                }
                togetStoredData(findUser.email_settings);
            }
            setUser_id(findUser.user_id);
            setUser_name((findUser.user_name));
            setUser_first_name(findUser.first_name);
            setUser_last_name(findUser.last_name);
        }
    }

    // on page load event
    useEffect(() => {
        getAllResponse();
        if(Object.keys(userReduxData.userData).length){
            checkUserRole();
        }
    }, []);

    /********************Function to Store Activated and Deactivated Email Type**************************/
    //Function to get user Detail to activate or Deactivate Email
    const activateOrdeactivateEmail = async (Email, Type) => {
        let alluserinformationdata = await getAllUserInformationApiCall();
        if (alluserinformationdata !== undefined) {
            const findUser = alluserinformationdata.find(
                ({ email_address }) => email_address === user.email
            );
            if (Type === 'activate') {
                activateEmailStatus(findUser, Email);
            }
            else {
                deactivateEmailStatus(findUser, Email);
            }
        }
    }

    //Function to Update User Detials
    const changeEmailSettings = async (listValues) => {
        listValues.mc_numbers = JSON.parse(listValues.mc_numbers);
        let loadeoUserDetailsModifying = await updateLoadeoUserDetailsApiCall(listValues, 'emailsettings');
        if (loadeoUserDetailsModifying !== undefined) {
            getAllResponse(loadeoUserDetailsModifying.company_mc_number);
        }
    }

    /*******************************Activate To Recieve Email******************************************************/
    const activateEmailFunctionality = (Email) => {
        Swal.fire({
            text: 'Are you sure you want to activate ' + Email + '?',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value === true) {
                activateOrdeactivateEmail(Email, 'activate');
                setSnackBarMessageText('Email Activated');
                setOpenSnackBar(true);
            }
        });
    }

    //Function to remove Data From email_settings
    const activateEmailStatus = async (userDetails, EmailType) => {
        let listValues = userDetails;
        const findEmailSettings = listValues.email_settings.find(({ type }) => type === EmailType);
        if (listValues.email_settings.length < 1 || EmailType === 'All Emails') {
            listValues.email_settings = [];
            setAllEmailStatus('activate');
        }
        else {
            findEmailSettings.deactivate = false;
        }
        toggleLoop(EmailType, 'activate');
        await changeEmailSettings(listValues);
    }
    /*********************************Stop Receiving Email**************************************************/
    // Function to show pop-up while Stopping Email
    const handledeactivateEmail = (Email) => {
        Swal.fire({
            text: 'Are you sure you want to deactivate ' + Email + '?',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value === true) {
                setAllEmailStatus('deactivate');
                toggleLoop(Email, 'deactivate');
                activateOrdeactivateEmail(Email, 'deactivate');
                setSnackBarMessageText('Email Deactivated');
                setOpenSnackBar(true);
            }
        });
    }

    const togetStoredData = (Email) => {
        let activateStatus;
        if (Email.length === 0) {
            setAllEmailStatus('activate');
        }
        if (Email.length !== 0 && Email.find(({ deactivate }) => deactivate === true)) {
            setAllEmailStatus('deactivate');
        }
        if (Email.find(({ type }) => type === 'Posted Loads')) {
            activateStatus = Email.find(({ type }) => type === 'Posted Loads');
            if (activateStatus.deactivate === true) {
                setToggleStatus('deactivate');
            }
        }
        if (Email.find(({ type }) => type === 'Save Lane')) {
            activateStatus = Email.find(({ type }) => type === 'Save Lane');
            if (activateStatus.deactivate === true) {
                setSaveLaneStatus('deactivate');
            }
        }
        if (Email.find(({ type }) => type === 'Bid Placed')) {
            activateStatus = Email.find(({ type }) => type === 'Bid Placed');
            if (activateStatus.deactivate === true) {
                setBidStatus('deactivate');
            }
        }
        if (Email.find(({ type }) => type === 'Counter Offer')) {
            activateStatus = Email.find(({ type }) => type === 'Counter Offer');
            if (activateStatus.deactivate === true) {
                setCounterOfferStatus('deactivate');
            }
        }
        if (Email.find(({ type }) => type === 'Counter Offer Accepted')) {
            activateStatus = Email.find(({ type }) => type === 'Counter Offer Accepted');
            if (activateStatus.deactivate === true) {
                setCounterOfferAcceptStatus('deactivate');
            }
        }
        if (Email.find(({ type }) => type === 'Bid Accepted')) {
            activateStatus = Email.find(({ type }) => type === 'Bid Accepted');
            if (activateStatus.deactivate === true) {
                setBidAcceptStatus('deactivate');
            }
        }
        if (Email.find(({ type }) => type === 'All Emails')) {
            activateStatus = Email.find(({ type }) => type === 'All Emails');
            if (activateStatus.deactivate === true) {
                setAllEmailStatus('deactivate');
                setToggleStatus('deactivate');
                setSaveLaneStatus('deactivate');
                setBidStatus('deactivate');
                setCounterOfferStatus('deactivate');
                setCounterOfferAcceptStatus('deactivate');
                setBidAcceptStatus('deactivate');
            }
        }
    }

    //Function to deactivate
    const toggleLoop = (Email, status) => {
        if (Email === 'All Emails') {
            setAllEmailStatus(status);
        }
        if (Email === 'Counter Offer Accepted' || Email === 'All Emails') {
            setCounterOfferAcceptStatus(status);
        }
        if (Email === 'Posted Loads' || Email === 'All Emails') {
            setToggleStatus(status);
        }
        if (Email === 'Bid Placed' || Email === 'All Emails') {
            setBidStatus(status);
        }
        if (Email === 'Bid Accepted' || Email === 'All Emails') {
            setBidAcceptStatus(status);
        }
        if (Email === 'Save Lane' || Email === 'All Emails') {
            setSaveLaneStatus(status);
        }
        if (Email === 'Counter Offer' || Email === 'All Emails') {
            setCounterOfferStatus(status);
        }
        if (Email === 'Counter Offer Accepted' || Email === 'All Emails') {
            setCounterOfferAcceptStatus(status);
        }

    }

    //Function to store Email Status
    const deactivateEmailStatus = async (userDetails, EmailType) => {
        let listValues = userDetails;
        if (userDetails.email_settings === null || userDetails.email_settings.length === 0) {
            listValues = { ...listValues, 'email_settings': [{ type: EmailType, deactivate: true }] };
        }
        else {
            const findUserSavedLane = listValues.email_settings.find(({ type }) => type === EmailType);
            if (!findUserSavedLane) {
                listValues.email_settings.push({ type: EmailType, deactivate: true });
            }
            else {
                findUserSavedLane.deactivate = true;
            }
        }
        await changeEmailSettings(listValues);
    }

    const handletoggleClick = (event, status, type) => {
        if (status === 'deactivate') {
            event.stopPropagation();
            activateEmailFunctionality(type);
        }
        else if (status === 'activate') {
            event.stopPropagation();
            handledeactivateEmail(type);
        }

    }
    /********************************************************************************************/
    return (
        <>
            <div className="fixed-navbar animated fadeInDown active">
                <Header />
            </div>
            <div className={classes.root}>
                <MUIDrawer />
                {(loading !== '') ?
                    <div>
                        <main className={classes.content}>
                            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                                <Alert onClose={handleCloseSnackbar} severity="success">
                                    {snackBarMessageText}
                                </Alert>
                            </Snackbar>
                            <Paper square style={{ width: '100%' }}>
                                <Grid container direction="column" alignItems="center" justify="center" >
                                    <Grid container direction="row">
                                        <Grid container direction="column" style={{ width: '100%' }}>
                                            <Grid container style={{ width: '90%' }}>
                                                <Row style={{ width: '100%', display: 'flex', marginBottom: 10, marginTop: '1%', marginLeft: '1%' }}>
                                                    <h5 style={{ marginLeft: '19.5%' }}>Email Notification</h5>
                                                </Row>
                                                <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <span style={{ width: '30%', fontSize: 'medium', fontWeight: 'bold' }} size="small" >Type</span>
                                                    <span style={{ width: '30%', fontSize: 'medium', fontWeight: 'bold', textAlign: 'right' }} size="small" >Off/On </span>
                                                </Row>
                                                <Divider />
                                                {// <Row style={{ width: "97%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    //     <span style={{ width: "30%", fontSize: "medium" }} variant="standard" size="small" >All Emails </span>
                                                    //     <span style={{ width: "10%" }}></span>
                                                    //     <span style={{ width: "30%", textAlign: "right" }} variant="standard" size="small" >
                                                    //         <Switch
                                                    //             disabled={allEmailStatus === "activate"}
                                                    //             checked={(allEmailStatus === "activate") ? true : false}
                                                    //             color="primary"
                                                    //             onClick={(event) => {
                                                    //                 handletoggleClick(event, allEmailStatus, "All Emails")
                                                    //             }}
                                                    //         >
                                                    //         </Switch>
                                                    //     </span>
                                                    // </Row>
                                                }
                                                {(userReduxData.userData.user_role === 'Carrier' || userReduxData.userData.company_role === 'Carrier') && (
                                                    <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                        <span style={{ width: '30%', fontSize: 'medium' }} size="small" >New Posted Loads</span>
                                                        <span style={{ width: '30%', textAlign: 'right' }} size="small" >
                                                            <Switch
                                                                checked={(toggleStatus === 'activate') ? true : false}
                                                                color="primary"
                                                                onClick={(event) => {
                                                                    handletoggleClick(event, toggleStatus, 'Posted Loads');
                                                                }}
                                                            >
                                                            </Switch>
                                                        </span>
                                                    </Row>
                                                )}
                                                <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <span style={{ width: '30%', fontSize: 'medium' }} size="small" >Counter Offer</span>
                                                    <span style={{ width: '30%', textAlign: 'right' }} size="small" >
                                                        <Switch
                                                            checked={(counterOfferStatus === 'activate') ? true : false}
                                                            color="primary"
                                                            onClick={(event) => {
                                                                handletoggleClick(event, counterOfferStatus, 'Counter Offer');
                                                            }}
                                                        >
                                                        </Switch>
                                                    </span>
                                                </Row>
                                                {(userReduxData.userData.user_role === 'Broker' || userReduxData.userData.company_role === 'Broker') && (
                                                    <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                        <span style={{ width: '30%', fontSize: 'medium' }} size="small" >Bid Placed </span>
                                                        <span style={{ width: '30%', textAlign: 'right' }} size="small" >
                                                            <Switch
                                                                checked={(bidStatus === 'activate') ? true : false}
                                                                color="primary"
                                                                onClick={(event) => {
                                                                    handletoggleClick(event, bidStatus, 'Bid Placed');
                                                                }}
                                                            >
                                                            </Switch>
                                                        </span>
                                                    </Row>
                                                )}
                                                {(userReduxData.userData.user_role === 'Carrier' || userReduxData.userData.company_role === 'Carrier') && (
                                                    <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                        <span style={{ width: '30%', fontSize: 'medium' }} size="small" >Saved Lane</span>
                                                        <span style={{ width: '30%', textAlign: 'right' }} size="small" >
                                                            <Switch
                                                                checked={(saveLaneStatu === 'activate') ? true : false}
                                                                color="primary"
                                                                onClick={(event) => {
                                                                    handletoggleClick(event, saveLaneStatu, 'Save Lane');
                                                                }}
                                                            >
                                                            </Switch>
                                                        </span>
                                                    </Row>)}
                                                <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <span style={{ width: '30%', fontSize: 'medium' }} size="small" >Counter Offer Accepted</span>
                                                    <span style={{ width: '30%', textAlign: 'right' }} size="small" >
                                                        <Switch
                                                            checked={(counterOfferAcceptStatus === 'activate') ? true : false}
                                                            color="primary"
                                                            onClick={(event) => {
                                                                handletoggleClick(event, counterOfferAcceptStatus, 'Counter Offer Accepted');
                                                            }}
                                                        >
                                                        </Switch>
                                                    </span>
                                                </Row>
                                                <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                    <span style={{ width: '30%', fontSize: 'medium' }} size="small" >Bid Accepted </span>
                                                    <span style={{ width: '30%', textAlign: 'right' }} size="small" >
                                                        <Switch
                                                            checked={(bidAcceptStatus === 'activate') ? true : false}
                                                            color="primary"
                                                            onClick={(event) => {
                                                                handletoggleClick(event, bidAcceptStatus, 'Bid Accepted');
                                                            }}
                                                        >
                                                        </Switch>
                                                    </span>
                                                </Row>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </main>
                    </div>
                    :
                    <Row style={{ width: '97%', display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress style={{ marginTop: '10%', height: '20vh', width: '20vh' }} />
                    </Row>}
            </div>
            <div className="fixed-footer animated fadeInDown active">
                <Footer />
            </div>
        </>

    );

};

export default EmailSettings;