import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

{/* ************************ Chat API calls ************************* */}

export const createCommentApiCall = async (data, action) => {
    try {
        let response = await axios({
            url:  environmentVariables.createupdatetestcomment + '?action=' + action,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const updateCommentApiCall = async (data, action) => {
    try {
        let response = await axios({
            url: environmentVariables.updatetestcomment + '?action=' + action,
            method: 'patch',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const getCommentApiCall = async (loadId, userId) => {
    try {
        let response = await axios({
            url: environmentVariables.gettestcomment + '?load_id=' + loadId + userId,
            method: 'get',
        });
        return JSON.parse(response.data);
    } catch (error) {
        console.log(error);
    }
};