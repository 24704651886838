import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

{/* ************************ Bid API calls ************************* */ }

export const createBidApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.createbid,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const getBidApiCall = async (Id, type) => {
    let url;
        url = environmentVariables.getbid + "?id=" + Id +"&type=" + type;
    try {
        let response = await axios({
            url: url,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const deleteBidApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.deletebid,
            method: 'patch',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const acceptBidApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.acceptbid,
            method: 'patch',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const acceptBidNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.acceptbidnotification,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const editBidApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.editbid,
            method: 'patch',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};