import React, { useEffect } from 'react';
import AllRoute from './router';
import './theme/App.css';

const App = () => {

    useEffect(() => {
        if ('caches' in window) {
            caches.keys().then((names) => {
                names.forEach(name => {
                    caches.delete(name);
                });
            });
        }
    }, []);

    return (
        <div className="App">
            <AllRoute />
        </div>
    );
};

export default App;
