import React, { useEffect, useRef } from 'react';
import './Chat.css';
import Message from '../Message/Message';
import { useAuth0 } from '@auth0/auth0-react';

export default function Chat(props) {
    // Constants to handle drawer functionality
    const { user } = useAuth0();
    const messageEl = useRef('');
    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [props]);

    let allMessages;
    if (props.discussion && props.discussion.messages) {
        allMessages = props.discussion.messages.map((element) => {
            if (element.messanger_id === user.email) {
                return (
                    <Message key={element.message} me="true" user={element.user} message={element.message} date={element.date} type={element.message_type} />
                );
            }
            else {
                return (
                    <Message key={element.message} me="false" user={element.user} message={element.message} date={element.date} type={element.message_type} />
                );
            }
        });
    }

    return (
        <React.Fragment>
            <div className="chatBody" ref={messageEl}>
                {allMessages}
            </div>
        </React.Fragment>
    );
}
