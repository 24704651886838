/*global google*/
import React from 'react';
import { GoogleMap, useJsApiLoader , DirectionsRenderer} from '@react-google-maps/api';
import Apin from '../../assets/images/Apin.jpg';
import Bpin from '../../assets/images/Bpin.jpg';
import './index.css';

const containerStyle = {
    width: '37vw',
    height: '480px',
};

const center = {
    lat: 40.756795,
    lng: -73.954298
};

const CenterControlA = (mapmarkerA, map, latlng) =>{
    const controlUIA = document.createElement('img');
    controlUIA.className = 'markerImage';
    controlUIA.src = Apin;
    mapmarkerA.appendChild(controlUIA);
    controlUIA.addEventListener('click', () => {
        map.panTo(latlng);
    });
};

const CenterControlB = (mapmarkerB, map, latlng) => {
    const controlUIB = document.createElement('img');
    controlUIB.className = 'markerImage';
    controlUIB.src = Bpin;
    mapmarkerB.appendChild(controlUIB);
    controlUIB.addEventListener('click', () => {
        map.panTo(latlng);
    });
};


const Map = (props) => {
    const origin = { lat: +props.data.fromlatitude, lng: +props.data.fromlongitude };
    const destination = { lat: +props.data.tolatitude, lng: +props.data.tolongitude };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDQvKYDzsqNRXO3jypkYdDjGM1Urb9bo_4'
    });

    const [map, setMap] = React.useState(null);
    const [directions, setDirections] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        console.log('inside onload');
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);

        const directionsService = new google.maps.DirectionsService();

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true,
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    console.log('inside directionapi');
                    setDirections(result);

                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );

        const mapmarkerA = document.createElement('div');
        mapmarkerA.className = 'mapmarkerA';
        CenterControlA(mapmarkerA, map, origin);
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapmarkerA);

        const mapmarkerB = document.createElement('div');
        mapmarkerB.className = 'mapmarkerB';
        CenterControlB(mapmarkerB, map, destination);
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapmarkerB);

        setMap(null);

    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={13}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <DirectionsRenderer
                directions={directions}
            />      
        </GoogleMap>      
    ) : <></>;
};

export default React.memo(Map);