import React from 'react';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Badge from '@mui/material/Badge';
/***********************SVG ICON***************************/
import { LoadboardIcon } from '../../assets/SvgIcons/LoadboardIcon';
import { ActiveListIcon } from '../../assets/SvgIcons/ActiveListIcon';
import { AllUserIcon } from '../../assets/SvgIcons/AllUser';
import { AwardedLoadIcon } from '../../assets/SvgIcons/AwardedLoadIcon';
import { ExpiredLoadsIcon } from '../../assets/SvgIcons/ExpiredLoadIcon';
import { UserIcon } from '../../assets/SvgIcons/UserIcon';
import { PreferredCarrierIcon } from '../../assets/SvgIcons/PreferredCarrierIcon';
import { LoadsWonIcon } from '../../assets/SvgIcons/LoadsWonIcon';
import { DashBoardIcon } from '../../assets/SvgIcons/DashBoardIcon';
import { ExpiredBidIcon } from '../../assets/SvgIcons/ExpiredBidIcon';
import { SavedLaneIcon } from '../../assets/SvgIcons/SaveLane';
import { InviteUserIcon } from '../../assets/SvgIcons/InviteUserIcon';
import { InvitedUserIcon } from '../../assets/SvgIcons/InvitedUserIcon';
import { CertificatesIcon } from '../../assets/SvgIcons/CertificatesIcon';
import { MailSettingIcon } from '../../assets/SvgIcons/MailSettingIcon';
import { MyProfileIccon } from '../../assets/SvgIcons/MyProfileIcon';
import { HomeIcon } from '../../assets/SvgIcons/HomeIcon';
import { SandwichIcon } from '../../assets/SvgIcons/Menu';
import { CompanyManagementIcon } from '../../assets/SvgIcons/CompanyManagement';
/****************************Driver Role Icon***********************/
import { Assigned } from '../../assets/SvgIcons/assigned';
import { Progress } from '../../assets/SvgIcons/progress';
import { Completed } from '../../assets/SvgIcons/completed';
import { DropDownArrow } from '../../assets/SvgIcons/DropDownArrow';
import { UpwardIcon } from '../../assets/SvgIcons/UpwardIcon';
/***************************COLORS********************************/
import { COLORS } from '../../theme/colors';
/*******************************************************************/
import {
    Drawer as MUIDrawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    IconButton,
    Grid,
    Tooltip,
} from '@material-ui/core';
import { environmentVariables } from '../../constants/environment';
import { MCVerifyIcon } from '../../assets/SvgIcons/MCVerifyIcon';
import { getNotificationApiCall } from '../../apis/notifications';
// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';

/********************************************************************/
const drawerWidth = 230;
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginTop: 40,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '1%'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        position: 'relative',
        width: drawerWidth,
        flexShrink: 0,
        marginTop: 91,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        /*zIndex: "auto",*/
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaper: {
        zIndex: 'auto',
        width: drawerWidth,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        /*zIndex: "auto",*/
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    bottomTool: {
        marginBottom: 91,
    }
}));

const Drawer = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const { history } = props;
    const { user } = useAuth0();
    const [open, setOpen] = useState(localStorage.getItem('drawerValue'));
    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });
    // constants for storing applications details
    const [applicationVersion, setApplicationVersion] = useState('');

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    // Constant to handle logged in company role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');

    const [unreadCountForAwardedLoads, setUnreadCountForAwardedLoads] = useState(0);
    const [unreadCountForLoadsWon, setUnreadCountForLoadsWon] = useState(0);
    const [unreadCountForActiveLoads, setUnreadCountForActiveLoads] = useState(0);
    const [unreadCountForSavedLanes, setUnreadCountForSavedLane] = useState(0);
    const [expandDown, setExpandDown] = useState(false);

    // Constant to handle Path for SystemAdminUser
    const systemAdminUser = {
        pathname: '/systemadmin',
        hash: '#users',
    };

    // Constant to handle Path for CompanyAdminUser
    const companyAdminUser = {
        pathname: '/companyadmin',
        hash: '#users',
    };

    //Function to check the user role
    const checkUserRole = () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.user_role) {
                setLoggedInuserRole(userReduxData.userData.user_role);
            }
            if (userReduxData.userData.company_role) {
                setLoggedInuserCompanyRole(userReduxData.userData.company_role);
            }
        }
    };

    // function to get application details
    const getApplicationDetails = async () => {
        try {
            let response = await axios({
                url: environmentVariables.getapplicationdetails,
                method: 'get',
            });
            let newResponse = JSON.parse(response.data.body);
            setApplicationVersion(newResponse[0][0]);
        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle drawer close
    const handleDrawerClose = () => {
        const closed = 'false';
        localStorage.setItem('drawerValue', closed);
        setOpen(closed);
    };

    // Function to handle drawer open
    const handleDrawerOpen = () => {
        const opened = 'true';
        localStorage.setItem('drawerValue', opened);
        setOpen(opened);
    };

    // const handleGetNotifications = async () => {
    //     try {
    //         const getNotificationResponse = await getNotificationApiCall(user.email);
    //         if (getNotificationResponse) {
    //             if (getNotificationResponse !== undefined) {
    //                 let notificationDetails = [];
    //                 let noOfUnreadAwaredMessages = 0;
    //                 let noOfUnreadWonMessages = 0;
    //                 let noOfUnreadActiveMessages = 0;
    //                 let noOfUnreadCountForSavedLanes = 0;
    //                 getNotificationResponse.forEach(element => {
    //                     if (element.read_status === false) {
    //                         if ((element.message === 'ThereIsANewMessageFromBroker' && element.won_message === true) || (element.message === 'YourLoadHasBennAccepted')) {
    //                             noOfUnreadWonMessages = noOfUnreadWonMessages + 1;
    //                             notificationDetails.push({ ...element });
    //                         }
    //                         else if (element.message === 'ThereIsANewMessageFromCarrier' && element.won_message === true) {
    //                             noOfUnreadAwaredMessages = noOfUnreadAwaredMessages + 1;
    //                             notificationDetails.push({ ...element });
    //                         }
    //                         else if ((element.message === 'ThereIsANewMessageFromCarrier' || element.message === 'ThereIsANewMessageFromBroker' || element.message === 'ThereIsANewBid' || element.message === 'ABidHasBeenRemoved' || element.message === 'ABidHasExpired' || element.message === 'ThereIsACounterOffer' || element.message === 'CounterOfferIsUpdated')) {
    //                             noOfUnreadActiveMessages = noOfUnreadActiveMessages + 1;
    //                         }
    //                         else if (element.message === 'ThereIsALoadMatchesYourSavedLane') {
    //                             noOfUnreadCountForSavedLanes = noOfUnreadCountForSavedLanes + 1;
    //                             notificationDetails.push({ ...element });
    //                         }
    //                     }
    //                     else {
    //                         notificationDetails.push({ ...element });
    //                     }
    //                 });
    //                 setUnreadCountForAwardedLoads(noOfUnreadAwaredMessages);
    //                 setUnreadCountForLoadsWon(noOfUnreadWonMessages);
    //                 setUnreadCountForActiveLoads(noOfUnreadActiveMessages);
    //                 setUnreadCountForSavedLane(noOfUnreadCountForSavedLanes);
    //                 notificationDetails.sort((a, b) =>
    //                     b.time_stamp_notification.localeCompare(a.time_stamp_notification)
    //                 );
    //                 let data = JSON.stringify(notificationDetails);
    //                 const arr = [];
    //                 notificationDetails.forEach(element => {
    //                     arr.push(element);
    //                 });
    //                 return data;
    //             }
    //         }
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // };

    //function to check logged Role
    const checkLoggedRole = () => {
        if (loggedInuserCompanyRole === 'Broker' || loggedInuserRole === 'Broker') {
            history.push({ pathname: '/activeloads' });
        }
        else if ((loggedInuserCompanyRole === 'Carrier' || loggedInuserRole === 'Carrier')) {
            history.push({ pathname: '/activebids' });
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollTopOnPageChange = (path) => {
        history.push({ pathname: path })
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if((window.location.pathname === '/dashboard')){ 
            setExpandDown(!expandDown);
        } 
    }


    useEffect(() => {
        let currentPath = window.location.pathname ;
        dispatch(handleUserDataValue(user.email));
        if((currentPath === '/dashboard' || 
        currentPath === '/allexpiredbids' || 
        currentPath === '/allsavedlanes' || 
        currentPath === '/allbidswon' || 
        currentPath === '/allactivebids')){
            setExpandDown(true);
        }
    }, []);

    useEffect(() => {
        checkUserRole();
        getApplicationDetails();
    }, [userReduxData]);

    //temporarily commented
    // useEffect(() => {
    //     handleGetNotifications();
    // }, [props.notificationSent === true]);

    /***************************************************************************************************************** */
    return (
        <Grid>
            {(props.isValueChanged === undefined) && (
                <MUIDrawer open
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open === 'true',
                        [classes.drawerClose]: open === 'false',
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            [classes.drawerOpen]: open === 'true',
                            [classes.drawerClose]: open === 'false',
                        }),
                    }}
                >
                    <div className={open === 'true' ? classes.toolbar : classes.menuButton}>
                    <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            hidden={open === 'true'}
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, { [classes.hide]: open === 'true' })}>
                            <SandwichIcon />
                        </IconButton>
                        <IconButton hidden={open === 'false'} onClick={handleDrawerClose}  >
                            <Tooltip title="Expand Left">
                                <ChevronLeftIcon />
                            </Tooltip>
                        </IconButton>
                    </div>
                    <Divider />
                    {(window.location.pathname !== '/settings' && window.location.pathname !== '/profile' && window.location.pathname !== '/emailsettings' && (loggedInuserCompanyRole !== '' || user.email === process.env.REACT_APP_ADMIN_USER)) && (
                        <div>
                            {
                                (loggedInuserRole === 'Driver') && (
                                    <div>
                                        <List >
                                            <ListItem button onClick={() => scrollTopOnPageChange('/loadsassigned')}>
                                                <ListItemIcon>
                                                    <Tooltip title="Loads Assigned">
                                                        <div>
                                                            <Assigned />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'Loads Assigned'} />
                                            </ListItem>
                                        </List>
                                        <List >
                                            <ListItem button onClick={() => scrollTopOnPageChange('/loadspickedup')} >
                                                <ListItemIcon>
                                                    <Tooltip title="Loads Picked Up">
                                                        <div>
                                                            <Progress />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'Loads Picked Up'} />
                                            </ListItem>
                                        </List>
                                        <List >
                                            <ListItem button onClick={() => scrollTopOnPageChange('/loadsdelivered')}>
                                                <ListItemIcon>
                                                    <Tooltip title="Loads Delivered">
                                                        <div>
                                                            <Completed />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'Loads Delivered '} />
                                            </ListItem>
                                        </List>
                                    </div>
                                )}
                            {
                                (user.email === process.env.REACT_APP_ADMIN_USER  || (loggedInuserRole === 'CompanyAdmin' && loggedInuserCompanyRole !== 'Broker')) && (
                                    <List >
                                        <ListItem button onClick={() => user.email === process.env.REACT_APP_ADMIN_USER ? history.push({ pathname: '/admin' }) : scrollTopOnPageChange('/dashboard')}>
                                            <ListItemIcon>
                                                <Tooltip title="DashBoard">
                                                    <div>
                                                        <DashBoardIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'DashBoard'} />
                                            <Tooltip title="Expand Down">
                                            <IconButton  hidden= {(user.email === process.env.REACT_APP_ADMIN_USER || expandDown === true)}>
                                                <DropDownArrow  />
                                            </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Expand Up">
                                            <IconButton  hidden= {user.email === process.env.REACT_APP_ADMIN_USER || expandDown === false}>
                                                <UpwardIcon   />
                                            </IconButton>
                                            </Tooltip>
                                        </ListItem>
                                    </List>
                                )
                            }
                            {
                                (user.email !== process.env.REACT_APP_ADMIN_USER && expandDown) && (
                                    <ListItem button style={{ backgroundColor: "#F0F8FF" }} onClick={() => { history.push({ pathname: '/allactivebids' }); }} >
                                        <ListItemIcon >
                                            <Tooltip title={(loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker') ? ' All Active Bids' : 'All Active Loads'}>
                                                <Badge badgeContent={unreadCountForActiveLoads} color="error">
                                                    <ActiveListIcon />
                                                </Badge>
                                            </Tooltip>
                                        </ListItemIcon>
                                       <ListItemText primary={(loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker') ? 'All Active Bids' : 'All Active Loads'} />
                                    </ListItem>
                                )
                            }

                            {
                                (user.email !== process.env.REACT_APP_ADMIN_USER && expandDown) && (
                                    <ListItem button  style={{ backgroundColor: "#F0F8FF" }} onClick={() => { history.push({ pathname: '/allsavedlanes' }); }} >
                                        <ListItemIcon>
                                            <Tooltip title=" All Saved Lane">
                                                <Badge badgeContent={unreadCountForSavedLanes} color="error">
                                                    <SavedLaneIcon />
                                                </Badge>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={'All Saved Lane'} />
                                    </ListItem>
                                )
                            }
                            {
                                    (user.email !== process.env.REACT_APP_ADMIN_USER && expandDown) && (
                                        <ListItem button style={{ backgroundColor: "#F0F8FF" }} onClick={() => { history.push({ pathname: '/allbidswon' }); }} >
                                            <ListItemIcon>
                                                <Tooltip title="Bids Won">
                                                    <Badge badgeContent={unreadCountForLoadsWon} color="error">
                                                        <LoadsWonIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'All Bids Won'} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (user.email !== process.env.REACT_APP_ADMIN_USER && expandDown) && (
                                        <ListItem button style={{ backgroundColor: "#F0F8FF" }} onClick={() => { history.push({ pathname: '/allexpiredbids' }); }} >
                                            <ListItemIcon>
                                                <Tooltip title="Expired Bids">
                                                    <div>
                                                        <ExpiredBidIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'All Expired Bids'} />
                                        </ListItem>
                                    )
                                }
                            {
                                (loggedInuserRole !== 'Driver' && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                    <div>
                                        <List >
                                            <ListItem button onClick={() => scrollTopOnPageChange('/loadboard')}>
                                                <ListItemIcon>
                                                    <Tooltip title="LaodBoard">
                                                        <div>
                                                            <LoadboardIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'LoadBoard'} />
                                            </ListItem>
                                        </List>
                                    </div>
                                )}

                            {
                                (user.email === process.env.REACT_APP_ADMIN_USER) && (
                                    <div>
                                        <List >
                                            <ListItem button onClick={() => scrollTopOnPageChange('/mcverify')}>
                                                <ListItemIcon>
                                                    <Tooltip title="MC Verify">
                                                        <div>
                                                            <MCVerifyIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'MC Verify'} />
                                            </ListItem>
                                        </List>
                                        <Divider />
                                    </div>
                                )}

                            {/* {
                        (user.email === process.env.REACT_APP_ADMIN_USER) && (
                            <div>
                                <List >
                                    <ListItem button onClick={() => { history.push({ pathname: '/prospectiveclients' }) }} >
                                        <ListItemIcon>
                                            <Tooltip title="Prospective Clients">
                                                <div>
                                                    <ProspectiveClientIcon />
                                                </div>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={"Prospective Clients"} />
                                    </ListItem>
                                </List>
                                <Divider /> */}
                            {/* </div>
                        )} */}
                            <List>
                                {/* {
                            (user.email === process.env.REACT_APP_ADMIN_USER) && (
                                <List >
                                    <ListItem button onClick={() => { history.push({ pathname: '/allloads' }) }} >
                                        <ListItemIcon>
                                            <Tooltip title="LoadBoard">
                                                <div>
                                                    <LoadboardIcon />
                                                </div>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={"LoadBoard"} />
                                    </ListItem>
                                </List>
                            )} */}
                                {
                                    (loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker' && loggedInuserRole !== 'Driver' && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => scrollTopOnPageChange('/savedlanes')}>
                                            <ListItemIcon>
                                                <Tooltip title="Saved Lane">
                                                    <Badge badgeContent={unreadCountForSavedLanes} color="error">
                                                        <SavedLaneIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'Saved Lane'} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (user.email !== process.env.REACT_APP_ADMIN_USER && loggedInuserRole !== 'Driver' && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => { checkLoggedRole(); }} >
                                            <ListItemIcon >
                                                <Tooltip title={(loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker') ? 'Active Bids' : 'Active Loads'}>
                                                    <Badge badgeContent={unreadCountForActiveLoads} color="error">
                                                        <ActiveListIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={(loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker') ? 'Active Bids' : 'Active Loads'} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (loggedInuserCompanyRole !== 'Carrier' && loggedInuserRole !== 'Carrier' && loggedInuserRole !== 'Driver' && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => scrollTopOnPageChange('/awardedloads')} style={(user.email === process.env.REACT_APP_ADMIN_USER && classes.drawerOpen === 'open') ? { paddingLeft: '6%' } : { paddingLeft: 14 }}>
                                            <ListItemIcon>
                                                <Tooltip title="Awarded Loads">
                                                    <Badge badgeContent={unreadCountForAwardedLoads} color="error">
                                                        {(user.email !== process.env.REACT_APP_ADMIN_USER) ? <LoadsWonIcon /> : <AwardedLoadIcon />}
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'Awarded Loads'} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker' && loggedInuserRole !== 'Driver' && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => scrollTopOnPageChange('/bidswon')} >
                                            <ListItemIcon>
                                                <Tooltip title="Bids Won">
                                                    <Badge badgeContent={unreadCountForLoadsWon} color="error">
                                                        <LoadsWonIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'Bids Won'} />
                                        </ListItem>
                                    )
                                }
                                <ListItem button hidden={(loggedInuserRole === 'Driver' || loggedInuserRole === 'Carrier' || loggedInuserCompanyRole === 'Carrier' || user.email === process.env.REACT_APP_SUPPORT_USER) ? true : false} onClick={() => scrollTopOnPageChange('/expiredloads')}>
                                    <ListItemIcon>
                                        <Tooltip title="Expired Loads">
                                            <div>
                                                {(user.email !== process.env.REACT_APP_ADMIN_USER) ? <ExpiredBidIcon /> : <ExpiredLoadsIcon />}
                                            </div>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={'Expired Loads'} />
                                </ListItem>
                                {
                                    (loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker' && loggedInuserRole !== 'Driver' && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => scrollTopOnPageChange('/expiredbids')} >
                                            <ListItemIcon>
                                                <Tooltip title="Expired Bids">
                                                    <div>
                                                        <ExpiredBidIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'Expired Bids'} />
                                        </ListItem>
                                    )
                                }
                            </List>
                            <Divider />
                            {/* {loggedInuserRole === "CompanyAdmin" && (
                        <List >
                            <ListItem button onClick={() => { history.push({ pathname: '/companyprofile' }) }} >
                                <ListItemIcon>
                                    <Tooltip title="Company Profile">
                                        <div>
                                            <CompanyProfileIcon />
                                        </div>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={"Company Profile"} />
                            </ListItem>
                        </List>
                    )} */}
                            {
                                (user.email === process.env.REACT_APP_ADMIN_USER) && (
                                    <div>
                                        <List >
                                            <ListItem className={classes.nestedItems} button onClick={() => scrollTopOnPageChange('/systemadmin')}>
                                                <ListItemIcon>
                                                    <Tooltip title="All User">
                                                        <div>
                                                            <AllUserIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'All User'} />
                                            </ListItem>
                                            <a href="/systemadmin#Users">
                                                <ListItem button onClick={() => { history.push(systemAdminUser); }}>
                                                    <ListItemIcon>
                                                        <Tooltip title="Users">
                                                            <div>
                                                                <UserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Users'} style={{ color: COLORS.black }} />
                                                </ListItem>
                                            </a>
                                            <a href='/systemadmin#Companies'>
                                            <ListItem button onClick={() => { history.push({ pathname: '/systemadmin' }) }}>
                                                <ListItemIcon>
                                                    <Tooltip title="Companies">
                                                        <CompanyManagementIcon />
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={"Companies"} style={{ color: "black" }} />
                                            </ListItem>
                                            </a>
                                        </List>
                                    </div>
                                )
                            }
                            {
                                (loggedInuserRole === 'CompanyAdmin') && (
                                    <div>
                                        <List style={{ paddingTop: 0 }}>
                                            <a href="/companyadmin#invitedusersform">
                                                <ListItem button style={{ paddingTop: 0 }} onClick={() => scrollTopOnPageChange('/companyadmin')}>
                                                    <ListItemIcon>
                                                        <Tooltip title="Invite User">
                                                            <div>
                                                                <InviteUserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Invite User'} />
                                                </ListItem>
                                            </a>
                                            <a href="/companyadmin#InvitedUsers">
                                                <ListItem button onClick={() => scrollTopOnPageChange('/companyadmin')}>
                                                    <ListItemIcon>
                                                        <Tooltip title="Invited User">
                                                            <div>
                                                                <InvitedUserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Invited User'} />
                                                </ListItem>
                                            </a>
                                            <a href="/companyadmin#NewUsers">
                                                <ListItem button onClick={() => scrollTopOnPageChange('/companyadmin')}>
                                                    <ListItemIcon>
                                                        <Tooltip title="All User">
                                                            <div>
                                                                <AllUserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'All User'} />
                                                </ListItem>
                                            </a>
                                            <a href="/companyadmin#Users">
                                                <ListItem button onClick={() => { history.push(companyAdminUser); }}>
                                                    <ListItemIcon>
                                                        <Tooltip title="Users">
                                                            <div>
                                                                <UserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Users'} style={{ color: COLORS.black }} />
                                                </ListItem>
                                            </a>
                                            {/* <ListItem className={classes.nestedItems} button >
                                    <ListItemIcon>
                                        <Tooltip title="Company Profile">
                                            <DashboardIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={"Company Profile"} />
                                </ListItem> */}
                                            <ListItem style={{ paddingLeft: 10 }} button onClick={() => scrollTopOnPageChange('/preferredcarrier')} hidden={(loggedInuserCompanyRole === 'Carrier')}>
                                                <ListItemIcon>
                                                    <Tooltip title="Preferred Carriers">
                                                        <div>
                                                            <PreferredCarrierIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={'Preferred Carriers'} />
                                            </ListItem>
                                        </List>
                                    </div>
                                )
                            }
                            {
                                (loggedInuserRole === 'CompanyAdmin' && loggedInuserCompanyRole === 'Carrier' && user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                    <List>
                                        <ListItem button style={{ paddingTop: 0 }} onClick={() => scrollTopOnPageChange('/documents')}>
                                            <ListItemIcon>
                                                <Tooltip title="Documents">
                                                    <div>
                                                        <CertificatesIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={'Documents'} />
                                        </ListItem>
                                    </List>
                                )}
                        </div>)}

                    {(window.location.pathname === '/settings' || window.location.pathname === '/profile' || window.location.pathname === '/emailsettings') && (
                        <div>
                            <List>
                                <ListItem button onClick={() => { history.push({ pathname: '/callback' }); }} >
                                    <ListItemIcon>
                                        <Tooltip title="Home">
                                            <div>
                                                <HomeIcon />
                                            </div>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={'Home'} />
                                </ListItem>
                            </List>
                            <ListItem button onClick={() => { history.push({ pathname: '/profile' }); }} >
                                <ListItemIcon>
                                    <Tooltip title="Profile">
                                        <div>
                                            <MyProfileIccon />
                                        </div>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={'Profile'} />
                            </ListItem>
                            {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                <ListItem button onClick={() => { history.push({ pathname: '/emailsettings' }); }} >
                                    <ListItemIcon>
                                        <Tooltip title="Email Settings">
                                            <div>
                                                <MailSettingIcon />
                                            </div>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={'Email Settings'} />
                                </ListItem>
                            )}
                            <div style={{ marginBottom: '110%' }}></div>
                            {(applicationVersion !== '' && open === 'true') && (
                                <div style={{ marginLeft: '3%' }}>
                                    <span
                                        style={{ padding: '0%', margin: '0%', fontSize: '12px', color: COLORS.darkGrey, position: 'fixed' }}>Revision: {applicationVersion}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    <div className={classes.bottomTool}></div>
                </MUIDrawer>
            )}
        </Grid>
    );
};
export default withRouter(Drawer);