// react related imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

// Auth0 related imports
import { useAuth0 } from '@auth0/auth0-react';

// MUI components related imports
import { Grid, TextField, Box, Button, Snackbar, Typography, IconButton, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import MUIDataTable from 'mui-datatables';
import SimpleModal from '../../staticPages/ProgressBar';

// MUI styles related imports
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';

// bootstrap components related imports
import { Row, Col } from 'react-bootstrap';

// MUI icons related imports
import CloseIcon from '@material-ui/icons/Close';

// websocket related imports
import useWebSocket, { } from 'react-use-websocket';

// Redux related imports
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';

// API calls related imports
import axios from 'axios';
import { environmentVariables } from '../../constants/environment';
import { addMCCertificateApiCall, getMCCertificateApiCall, extractJSONDataApiCall, deleteMCCertificateApiCall } from '../../apis/certification';
import { getparticularcompanyinformationApiCall } from '../../apis/companies';

import DateFnsUtils from '@date-io/date-fns';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Swal from 'sweetalert2';
import { uploadFile, deleteFile } from 'react-s3';
import * as AWS from 'aws-sdk';

import Navbar from '../../components/Navbar';
import MUIDrawer from '../../components/Drawer';
import './index.css';

const s3 = new AWS.S3({
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, accessKeyId: process.env.REACT_APP_ACCESS_KEY, region: process.env.REACT_APP_S3_BUCKET_REGION
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 10,
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
        color: '#101e35'
    },
    indicator: {
        backgroundColor: '#101e35',
        color: '#101e35'
    }

}));

const Certifications = () => {
    const dispatch = useDispatch();
    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const classes = useStyles();
    const { user } = useAuth0();
    const history = useHistory();
    const DIRECTORY = 'loadeo-develop/loadeo-mc-certificates';
    const [serviceStartDate, setServiceStartDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState('');
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileInBinary, setSelectedFileInBinary] = useState('');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('');
    const [certificateLink, setCertificateLink] = useState('');
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [certificationID, setCertificationID] = useState('');
    const [verifiedTimeStamp, setVerifiedTimeStamp] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [documentComment, setDocumentComment] = useState('');
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [colorseverity, setColorseverity] = useState('');
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    //constant to handle logged in company name
    // const [userReduxData.userData.company_name, setuserReduxData.userData.company_name] = useState(window.localStorage.getItem('userCompanyName'));
    // const [userReduxData.userData.company_mc_number, setuserReduxData.userData.company_mc_number] = useState(window.localStorage.getItem('company_mc_number'));


    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        dirName: DIRECTORY,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,

    };

    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        paddingLeft: '1.5%'
                    }
                },
                MUIDataTableHeadCell: {
                    root: {
                        paddingLeft: '2%'
                    }
                }
            }
        });

    const handleGetCompanyInfo = async () => {
        try {
            let companyInformationData = await getparticularcompanyinformationApiCall('?company_mc_number=', userReduxData.userData.company_mc_number);
            if (companyInformationData) {
                setCompanyId(companyInformationData[0].company_id);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to handle Row click
    const handleDetails = (selectedRowState, rowState) => {
        if (data.length > 0) {
            if (selectedRowState !== undefined && selectedRowState !== null) {
                let completeSelectedRowData = data.find(({ certificate_id }) => certificate_id === selectedRowState[0]);
                if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                    setVerificationStatus(completeSelectedRowData.verification_status);
                    setFileName(completeSelectedRowData.certificate_link.split('loadeo-mc-certificates/')[1]);
                    setCertificateLink(completeSelectedRowData.certificate_link);
                    setCertificationID(completeSelectedRowData.certificate_id);
                    setServiceStartDate(new Date(completeSelectedRowData.service_start_date));
                    setDocumentComment(completeSelectedRowData.comment_reason);
                    setVerifiedTimeStamp(moment.utc(completeSelectedRowData.time_stamp).local().format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        }
        else {
            setVerificationStatus('Upload Pending');
        }

        handleGetCompanyInfo();
        setMainGridPercent('45%');
        setIsPaneOpen(true);
    };

    const options = {
        filter: false,
        search: false,
        responsive: 'vertical',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) =>
            handleDetails(rowData, rowState),
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    'No loads are posted'
            },
        }
    };

    // Function to get all certificates 
    const handleGetCertificate = async () => {
        if (userReduxData.userData.company_mc_number) {
            try {
                let particularCertificateData = await getMCCertificateApiCall(userReduxData.userData.company_mc_number);
                if (particularCertificateData !== undefined) {
                    if (particularCertificateData.length > 0 && particularCertificateData !== 'NoCertificateAreAvailable') {
                        setData(particularCertificateData);
                        setLoading(false);
                        setIsFetched(true);
                    }
                    else {
                        setLoading(true);
                        setIsFetched(true);
                    }
                }
            } catch (error) {
                console.log('get cretificate error', error);
            }
        }
    };

    const emptyData = [['', userReduxData.userData.company_name, userReduxData.userData.company_mc_number, 'NA', 'MC Certificate', 'Upload Pending', 'NA']];
    const emptyColumnDefs = ['Certicate ID', 'Company Name', 'MC Number', 'Certificate Issue Date', 'Document Type', 'Status', 'Comments'];

    const columnDefs = [
        { label: 'Certificate ID', name: 'certificate_id', options: { display: false } },
        {
            label: 'Company Name', name: 'company_name'
        },
        {
            label: 'MC Number',
            name: 'company_mc_number',
        },
        {
            label: 'Certificate Issue Date',
            name: 'service_start_date',
        },
        {
            label: 'Document Type',
            name: '',
            options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    return 'MC Certificate';
                }
            }
        },
        {
            name: 'verification_status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, rowData) => {
                    if (value === 'Verified') {
                        return (
                            <span style={{ color: '#32CD32', fontWeight: 'bold' }}>{value}</span>
                        );
                    }
                    else if (value === 'Data missing' || value === 'Document Corrupted') {
                        return (
                            <span style={{ color: 'Red', fontWeight: 'bold' }}>{value}</span>
                        );
                    }
                    else if (value === 'Data not matching') {
                        return (
                            <span style={{ color: '#FAD02C', fontWeight: 'bold' }}>{value}</span>
                        );
                    }
                    else {
                        return (
                            <span>{value}</span>
                        );
                    }
                }
            }
        },
        {
            name: 'comment_reason',
            label: 'Comments',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, rowData) => {
                    if (value !== null && value !== '' && value !== 'NA') {
                        return (
                            <div style={{ width: '100%' }}>
                                <Tooltip title={value}>
                                    <TextField
                                        variant="outlined"
                                        value={value}
                                        disabled={true}
                                    />
                                </Tooltip>
                            </div>
                        );
                    }
                    else {
                        return 'NA';
                    }
                }
            }
        },
    ];

    // Function to recive all the socket io messages
    const recieveMessageForSocketIo = async (message) => {
        let recievedMessage = message.data.replace(/\'/g, '');

        if (recievedMessage.includes('Your MC Certificate Verification is Succeeded')) {
            // window.localStorage.removeItem('mc_certified')
            // handleLocalStorageValue(user.email)
            dispatch(handleUserDataValue(user.email));

        }
    };

    //Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    // Function to handle MuiAlert
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant='filled' {...props} />;
    };

    // Function to handle field validation 
    const validate = () => {
        let certificateValidation = {};
        certificateValidation.fileName = fileName ? '' : 'error';

        if (selectedFile === null) {
            setErrorMessage('empty file');
        }
        else {
            setErrorMessage('');
        }

        setErrors({
            ...certificateValidation
        });
        return Object.values(certificateValidation).every(x => x === '');
    };

    // Function to handle Snackbar close event
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    // Function to handle Date picker Onchange event
    const handleServiceStartDateChange = (date) => {
        setServiceStartDate(date);
    };

    // Function to handle Cancel button click
    const handleClearFields = () => {
        setVerificationStatus('Upload Pending');
        setFileName('');
        setCertificateLink('');
        setCertificationID('');
        setDocumentComment('');
        setSelectedFile(null);
        setErrorMessage('');
        setServiceStartDate(new Date());
    };

    // Function to handle Secondary panel close
    const closeSecondaryPane = () => {
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        handleClearFields();
    };

    // function to convert normal file to base64 format
    const handleConvertingBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    };

    // Function to handle Onchange event of input file field
    const handleFileChange = event => {
        if (event.target.files[0] && event.target.files[0].name) {
            setFileName(event.target.files[0].name);
        }
        if (event.target.files[0] && event.target.files[0].size > 5e6) {
            setErrorMessage('max size');
        }
        else {
            setErrorMessage('');
        }
        var file = event.target.files[0];
        var temp = file.slice(0, file.size);
        setSelectedFile(new File([temp], `${userReduxData.userData.company_name}.pdf`));
        handleConvertingBase64(file).then(
            (data) => {
                setSelectedFileInBinary(data);
            }
        );
    };

    // Function to reset input File field
    const onInputClick = (event) => {
        event.target.value = '';
    };

    const handleRemoveFileButton = () => {
        setFileName('');
        setSelectedFile(null);
        setErrorMessage('');
    };

    const handleGetVerificationStatus = async (mcNumber) => {
        try {
            let response = await axios({
                url: 'https://flask-api.stage.loadeo.com/api/loadeo_get_verification_status?mc_number=' + mcNumber,
                method: 'get',
            });
            if (response) {
                setVerificationStatus(JSON.parse(response.data.body)[0].verification_status);
                setOpenSnackBar(false);
            }
        }
        catch (error) {
            console.log('get status error', error);
        }
    };

    // Function to handle Submit confirmation button click
    const handleSaveButtonChange = async (fileLocation) => {
        let diffDays;
        setPageLoading(true);
        closeSecondaryPane();
        setShowModal(true);
        try {
            let jsonData = await extractJSONDataApiCall(companyId, selectedFileInBinary, `${userReduxData.userData.company_name}.pdf`);
            if (jsonData !== undefined) {
                if (jsonData.response && jsonData.response.data === 'Error reading the file, Please validate the uploaded file might be corrupted') {
                    try {
                        let certificateData = {
                            'extracted_data': jsonData.response.data,
                            'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            'certificate_link': fileLocation,
                            'company_name': userReduxData.userData.company_name,
                            'service_start_date': moment(serviceStartDate).format('MM/DD/YYYY'),
                            'company_mc_number': userReduxData.userData.company_mc_number,
                            'company_id': companyId,
                            'day_difference': diffDays
                        };

                        let uploadResponse = await addMCCertificateApiCall(certificateData);
                        if (uploadResponse !== undefined) {
                            handleGetVerificationStatus(userReduxData.userData.company_mc_number);
                            handleGetCertificate();
                            setPageLoading(false);
                            setShowModal(false);
                            dispatch(handleUserDataValue(user.email));
                            setDisableSubmitButton(false);
                        }
                    }
                    catch (error) {
                        console.log('extract JSON error', error);
                        setDisableSubmitButton(false);
                        setOpenSnackBar(true);
                        setShowModal(false);
                        setSnackBarMessageText('Verification Failed');
                        setColorseverity('error');
                    }
                }
                else {
                    let completeSelectedRowData = jsonData.data;
                    if (completeSelectedRowData.service_date) {
                        completeSelectedRowData.service_date = moment(completeSelectedRowData.service_date).format('MM/DD/YYYY');
                    }
                    if (jsonData !== 'Exception in text extraction :\'NoneType\' object has no attribute \'group\'') {
                        const date1 = new Date(completeSelectedRowData.service_date);
                        const date2 = new Date();
                        const diffTime = Math.abs(date2 - date1);
                        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    }
                    try {
                        let certificateData = {
                            'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            'certificate_link': fileLocation,
                            'company_name': userReduxData.userData.company_name,
                            'service_start_date': moment(serviceStartDate).format('MM/DD/YYYY'),
                            'extracted_data': completeSelectedRowData,
                            'company_mc_number': userReduxData.userData.company_mc_number,
                            'company_id': companyId,
                            'day_difference': diffDays
                        };

                        let uploadResponse = await addMCCertificateApiCall(certificateData);
                        if (uploadResponse !== undefined) {
                            handleGetVerificationStatus(userReduxData.userData.company_mc_number);
                            handleGetCertificate();
                            setPageLoading(false);
                            setShowModal(false);
                            dispatch(handleUserDataValue(user.email));
                            setDisableSubmitButton(false);
                        }
                    }
                    catch (error) {
                        console.log('extract JSON error', error);
                        setDisableSubmitButton(false);
                        setOpenSnackBar(true);
                        setShowModal(false);
                        setSnackBarMessageText('Verification Failed');
                        setColorseverity('error');
                    }
                }
            }
        }
        catch (error) {
            console.log('extract json error', error);
        }
    };

    // Function to handle Save button click
    const handleSave = async () => {
        if (validate() && errorMessage === '') {
            setDisableSubmitButton(true);
            uploadFile(selectedFile, config)
                .then((data) => {
                    handleSaveButtonChange(data.location);
                })
                .catch((err) => {
                    console.log('upload error', err);
                }
                );
        }
    };

    // Function to handle Delete confirmation button click
    const handleDeleteButtonClick = async () => {
        let completeSelectedRowData = data.find(({ certificate_id }) => certificate_id === certificationID);
        if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
            completeSelectedRowData.verification_status = '';
            completeSelectedRowData = { ...completeSelectedRowData, 'certificate_name': certificateLink.split('loadeo-mc-certificates/')[1] };
        }
        try {
            let response = await deleteMCCertificateApiCall(completeSelectedRowData);
            if (response !== undefined) {
                window.localStorage.removeItem('mc_certified');
                dispatch(handleUserDataValue(user.email));
                closeSecondaryPane();
                setColorseverity('error');
                setSnackBarMessageText('File Delete Succeeded');
                setOpenSnackBar(true);
                handleGetCertificate();
                setData([]);
                closeSecondaryPane();
            }
        } catch (error) {
            console.log('delete cretificate error', error);
        }
    };

    // Function to handle Delete button click
    const handleDeleteFile = async () => {
        if (verificationStatus === 'Verified') {
            Swal.fire({
                title: '<h5>Are you sure you want to Delete this file?</h5>',
                html: '<i>Note : Deleting file will change status to Unverified and you will have to Re-verify your MC certificate</i>',
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            })
                .then((result) => {
                    if (result.value) {
                        handleDeleteButtonClick();
                    }
                });
        }
        else {
            Swal.fire({
                text: 'Are you sure you want to Delete this file?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handleDeleteButtonClick();
                }
            });
        }

    };

    // Function to check the user role
    const checkUserRole = async () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === "ProfileAccepted") {
                if (userReduxData.userData.user_role === "Driver" || userReduxData.userData.company_role !== "Carrier" || user.email === process.env.REACT_APP_ADMIN_USER) {
                    history.push({ pathname: "/forbidden" });
                }
            }
        }
        else {
            history.push({ pathname: "/forbidden" });
        }
    };


    useEffect(() => {
        checkUserRole();
        handleGetCertificate();
    }, [userReduxData]);

    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    return (
        <div className={classes.root}>
            <Navbar />
            <MUIDrawer />
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            {/* {(pageLoading === false) ? */}
            <main className={classes.content}>
                <Typography component={'div'} style={{ paddingBottom: '15%' }}>
                    <Box boxShadow={3} color='black' border={0} padding={1} >
                        <Grid container direction='row'>
                            <Grid xs={12} sm={14}>
                                <Typography component={'div'}>
                                    <Grid container direction='row' margin={2}>
                                        <div style={{ width: mainGridPercent }} >
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <MUIDataTable
                                                    title={'Documents'}
                                                    data={(data.length === 0 && isFetched === true) ? emptyData : data}
                                                    columns={(data.length === 0 && isFetched === true) ? emptyColumnDefs : columnDefs}
                                                    options={options}
                                                ></MUIDataTable>
                                            </MuiThemeProvider>
                                        </div>
                                        {isPaneOpen && (
                                            <React.Fragment>
                                                <div style={{ width: '1%' }}></div>
                                                <div style={{ width: '54%' }} border={1}>
                                                    <Box
                                                        boxShadow={3}
                                                        style={{ width: '100%', overflowY: 'auto', maxHeight: '100%', height: '100%' }}
                                                        color='black'
                                                        border={0}
                                                        padding={2}
                                                    >
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ fontSize: 18 }} justify-content='flex-end'>
                                                                {(verificationStatus === 'Upload Pending') ? <b>Upload Certificate</b> : <b>View Certificate</b>}
                                                            </Grid>
                                                            <Grid container xs={4} justify='flex-end'>
                                                                <IconButton onClick={closeSecondaryPane}>
                                                                    <Tooltip title="Close">
                                                                        <CloseIcon />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        <Row >
                                                            <Col sm>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ height: 5, paddingTop: '5%' }} />
                                                        <a style={{ fontSize: '14px', fontStyle: 'italic', paddingLeft: '9%' }} href='https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-mc-certificates/sample-mc-certificate/sample_template.pdf' rel='noopener nor'>
                                                            Click here
                                                        </a>
                                                        <Typography variant='caption' style={{ fontSize: '14px', fontStyle: 'italic' }}> to view the sample of the document needed (pdf/image)  </Typography>
                                                        <div style={{ height: 5, paddingTop: 5 }} />
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={1}>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <div style={{ height: '15%' }}></div>
                                                                <Grid container direction='column' spacing={1}>
                                                                    <Grid item>
                                                                        <TextField disabled label='Company Name' style={{ width: '85%' }} variant='outlined' value={userReduxData.userData.company_name}></TextField>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TextField disabled label='MC Number' style={{ width: '85%' }} variant='outlined' value={userReduxData.userData.company_mc_number}></TextField>
                                                                    </Grid>
                                                                    {(verificationStatus === 'Verified') && (
                                                                        <Grid item>
                                                                            <TextField disabled label='Verified Time' style={{ width: '85%' }} variant='outlined' value={verifiedTimeStamp}></TextField>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <span style={{ fontSize: '12px' }}>Certificate Issue Date</span>
                                                                <Grid container direction='column' spacing={1}>
                                                                    <Grid item style={{ width: '100%' }}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <DatePicker
                                                                                id='serviceDate'
                                                                                selected={serviceStartDate}
                                                                                value={new Date()}
                                                                                dateFormat='MM/dd/yyyy'
                                                                                onChange={handleServiceStartDateChange}
                                                                                autoComplete='off'
                                                                                disabled={verificationStatus !== 'Upload Pending'}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Grid>
                                                                    <Grid style={{ marginBottom: '5%' }} item>
                                                                        <input type='file' id='myFile' disabled={verificationStatus !== 'Upload Pending'} onChange={handleFileChange} onClick={onInputClick}></input>
                                                                        <br />
                                                                        {(fileName !== '' && verificationStatus === 'Upload Pending') ? (
                                                                            <div className='fileName'>
                                                                                <Row>
                                                                                    <Col style={{ textAlign: 'center' }}>
                                                                                        <span id='file-chosen'>{fileName === '' ? 'No file chosen' : fileName.slice(0, 22) + '..'}</span>
                                                                                    </Col>
                                                                                    <Col sm={3}>
                                                                                        <IconButton style={{ fontSize: '5px', padding: '0px' }} onClick={handleRemoveFileButton}>
                                                                                            <CloseIcon style={{ fontSize: '12px', padding: '0px' }} />
                                                                                        </IconButton>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ) :
                                                                            (<span id='file-chosed'>{fileName}</span>)}
                                                                        {(errorMessage !== '') && (
                                                                            <span id='invalid-file-chosen'>{errorMessage === 'empty file' ? 'Choose file (Max 5MB)' : 'File exceeded 5MB'}</span>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {(documentComment !== '' && documentComment !== null) && (
                                                            <Grid container spacing={3}>
                                                                <Grid item sm={1}>
                                                                </Grid >
                                                                <Grid item>
                                                                    <TextField
                                                                        label='Comment'
                                                                        autoComplete='off'
                                                                        multiline
                                                                        rows={4}
                                                                        variant='outlined'
                                                                        style={{
                                                                            width: '235%',
                                                                        }}
                                                                        value={documentComment}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={1}>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        <div>
                                                            <div className='certificateBox'>
                                                                {(verificationStatus === 'Upload Pending') && (
                                                                    <Box paddingRight={5} paddingLeft={5} className='certificateBox'>
                                                                        <Button variant='contained' onClick={handleSave} disabled={disableSubmitButton} className='buttonStyle'>Submit</Button>
                                                                        <span style={{ margin: '3%' }}></span>
                                                                        <Button variant='contained' onClick={handleClearFields} className='buttonStyle'>Clear</Button>
                                                                    </Box>
                                                                )}
                                                                {(verificationStatus !== 'Upload Pending') && (
                                                                    <Box paddingRight={5} paddingLeft={5} className={documentComment !== '' && documentComment !== null ? 'certificateBoxOnComment' : 'certificateBox'}>
                                                                        <Button variant='contained' href={certificateLink} className='buttonStyle'>View</Button>
                                                                        <span style={{ margin: '3%' }}></span>
                                                                        <Button variant='contained' onClick={handleDeleteFile} className='buttonStyle'>Delete</Button>
                                                                    </Box>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Box>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Typography>
            </main >
            {(showModal === true) && (
                <SimpleModal showModal={showModal} />
            )}
        </div >
    );
};
export default Certifications;