import React from 'react';
export const ColorDisappointed = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 513 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M256.1 512C397.485 512 512.1 397.385 512.1 256C512.1 114.615 397.485 0 256.1 0C114.715 0 0.100098 114.615 0.100098 256C0.100098 397.385 114.715 512 256.1 512Z" fill="#ECB13A" />
            <path d="M248.9 486.5C382.829 486.5 491.4 377.929 491.4 244C491.4 110.071 382.829 1.5 248.9 1.5C114.971 1.5 6.3999 110.071 6.3999 244C6.3999 377.929 114.971 486.5 248.9 486.5Z" fill="#FFDE4F" />
            <path d="M248.6 263.7C180.4 263.7 124.8 308.8 124.8 364.2C124.8 368.8 128.5 372.5 133.1 372.5C137.7 372.5 141.4 368.8 141.4 364.2C141.4 317.9 189.5 280.3 248.6 280.3C307.7 280.3 355.8 318 355.8 364.2C355.8 368.8 359.5 372.5 364.1 372.5C368.7 372.5 372.4 368.8 372.4 364.2C372.4 308.8 316.8 263.7 248.6 263.7V263.7Z" fill="#645033" />
            <path d="M143.5 198.3C162.499 198.3 177.9 182.899 177.9 163.9C177.9 144.901 162.499 129.5 143.5 129.5C124.502 129.5 109.1 144.901 109.1 163.9C109.1 182.899 124.502 198.3 143.5 198.3Z" fill="#645033" />
            <path d="M365.4 198.7C384.399 198.7 399.8 183.299 399.8 164.3C399.8 145.301 384.399 129.9 365.4 129.9C346.401 129.9 331 145.301 331 164.3C331 183.299 346.401 198.7 365.4 198.7Z" fill="#645033" />
        </svg>

    );
};
