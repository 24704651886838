import React, { useState, useEffect, } from 'react';
import { useHistory } from 'react-router';
import {
    Grid,
    CssBaseline,
    Typography,
    Tooltip,
    Switch
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import {
    MuiThemeProvider,
    makeStyles,
    createTheme
} from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllCompaniesApiCall, updateCompanyStatusApiCall,getparticularcompanyinformationApiCall } from '../../apis/companies';

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

import MUIDrawer from '../../components/Drawer';
import { pascalCase } from '../../utils';

//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    compRoleFormControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

}));

const getMuiTheme = () =>
    createTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: '#b8cae9'
                },
                colorSecondary: {
                    '&$checked': {
                        // Controls checked color for the thumb
                        color: '#142440'
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.7,
                    backgroundColor: '#b8cae9',
                    '$checked$checked + &': {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: '#142440'
                    }
                }
            },
            MUIDataTable: {
                root: {},
                paper: {
                    boxShadow: 'none',
                },
            },
            MUIDataTableBodyRow: {
                root: {
                    '&:nth-child(odd)': {
                        backgroundColor: '#ECECEC',
                    },
                    '&:hover': { backgroundColor: '#aab5be!important' },
                    '&:focus': { backgroundColor: '#000!important' },
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    justifyContent: 'center',
                }
            },
            MUIDataTableHeadCell: {},
        },
    });

const CompanyDetails = () => {

    //-------------------------Declaring all the constants ------------------------------
    const history = useHistory();
    const classes = useStyles();
    const { user } = useAuth0();

    // Constant to handle main MuiDatatable
    const [companyTableRowData, setCompanyTableRowData] = useState([]);

    //constant to handle loader for mui data table
    const [loading, setLoading] = useState(false);

    const getAllCompanies = async () => {
        try {
            let allcompaniesdata = await getAllCompaniesApiCall();
            if (allcompaniesdata !== undefined) {
                if (allcompaniesdata !== 'CompanyNotFound') {
                    if (allcompaniesdata.length === 0) {
                        setLoading(false);
                    }
                    else {
                        setLoading(true);
                        let companiesData = [];
                        allcompaniesdata.forEach(element => {
                            if (element.company_mc_number) {
                                let temp = element['company_mc_number'];
                                companiesData.push({ ...element, 'mc_number': temp });
                            }
                        });
                        companiesData.sort((a, b) =>
                            a.status.localeCompare(b.status)
                        );
                        setCompanyTableRowData(companiesData);
                    }
                }
                else {
                    setCompanyTableRowData([]);
                    if (allcompaniesdata.length === 0) {
                        setLoading(false);
                    }
                    else {
                        setLoading(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error, 'Get all companies error in system admin companies');
        }
    };

    const companyDetails = (rowValues) => {
        history.push({ pathname: '/companyprofile', mcNumber: rowValues[2] });
    };

    const options = {
        onRowClick: (clickAllUserRowData, rowState) =>
            companyDetails(clickAllUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: true,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    'No companies are registered'
            },
        }
    };

    const activateCompany = async (rowValues) => {
        try {
            let companyInformationData = await getparticularcompanyinformationApiCall('?company_mc_number=', rowValues[2]);
            const firstCompanyData = companyInformationData[0] ;
            if (companyInformationData){
                const listValues = {
                    company_id: firstCompanyData.company_id,
                    company_mc_number: firstCompanyData.company_mc_number,
                    status: 'ProfileAccepted',
                    company_description: firstCompanyData.company_description,
                    address:firstCompanyData.address,
                    city:firstCompanyData.city,
                    state:firstCompanyData.state,
                    logo:firstCompanyData.logo,
                    website:firstCompanyData.website,
                    phone_number:firstCompanyData.phone_number,
                    email_address:companyInformationData[0].email_address,
                };
                Swal.fire({
                    text: 'Are you sure you want activate ' + pascalCase(rowValues[1]),
                    cancelButtonColor: '#0066cc',
                    confirmButtonText: 'Ok',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0066cc'
                }).then(async (result) => {
                    if (result.value) {
                        try {
                            let activateCompanyData = await updateCompanyStatusApiCall(listValues);
                            if (activateCompanyData !== undefined) {
                                getAllCompanies();
                                }
                            }
                        catch (error) {
                            console.log(error, 'Activating company error in system admin companies');
                        }
                    }
                });
            }
        }
        catch (error) {
            console.log(error, 'Error in getting company info');
        }
    };

    const deactivateCompany = async (rowValues) => {
        try {
            let companyInformationData = await getparticularcompanyinformationApiCall('?company_mc_number=', rowValues[2]);
            const firstCompanyData = companyInformationData[0] ;
            if (companyInformationData){
                const listValues = {
                    company_id: firstCompanyData.company_id,
                    company_mc_number: firstCompanyData.company_mc_number,
                    status: 'ProfileSuspended',
                    company_description: firstCompanyData.company_description,
                    address:firstCompanyData.address,
                    city:firstCompanyData.city,
                    state:firstCompanyData.state,
                    logo:firstCompanyData.logo,
                    website:firstCompanyData.website,
                    phone_number:firstCompanyData.phone_number,
                    email_address:firstCompanyData.email_address,
                };
                Swal.fire({
                    text: 'Are you sure you want deactivate ' + pascalCase(rowValues[1]),
                    cancelButtonColor: '#0066cc',
                    confirmButtonText: 'Ok',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0066cc'
                }).then(async (result) => {
                    if (result.value) {
                        try {
                            let deactivateCompanyData = await updateCompanyStatusApiCall(listValues);
                            if (deactivateCompanyData !== undefined) {
                                getAllCompanies();
                            }
                        }
                        catch (error) {
                            console.log(error, 'Deactivating company error in system admin companies');
                        }
                    }
                });
                                
            }
        }
        catch (error) {
            console.log(error, 'Error in getting company info');
        }
    };

    //Column definition for company details table
    const companyTableColumnDefs = [
        { label: 'Company ID', name: 'company_id', options: { display: false } },
        {
            label: 'Company Name',
            name: 'company_name',
            options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[1].toUpperCase()
                    );
                }
            }
        },
        {
            label: 'MC Number',
            name: 'company_mc_number',
            options: { display: true }
        },
        {
            label: 'Role',
            name: 'company_role',
            options: {
                customBodyRender: (value) => {
                    if (value === 'Broker&Carrier') {
                        return 'Broker & Carrier';
                    }
                    else {
                        return value;
                    }

                }
            }
        },
        {
            label: 'Status',
            name: 'status',
            options: {
                customBodyRender: (value) => {
                    if (value === 'ProfileSuspended') {
                        return 'Suspended';
                    }
                    else if (value === 'ProfileAccepted') {
                        return 'Active';
                    }
                    else if (value === 'ProfileCreated') {
                        return 'Created';
                    }
                    else if(value === 'ProfileRejected'){
                        return 'Not Approved'
                    }

                },
            }
        },
        { label: 'Description', name: 'companyDescription', options: { display: false } },
        { label: 'Address', name: 'address', options: { display: false } },
        { label: 'City', name: 'city', options: { display: false } },
        { label: 'State', name: 'state', options: { display: false } },
        { label: 'Logo', name: 'logo', options: { display: false } },
        { label: 'Website', name: 'website', options: { display: false } },
        { label: 'Phone Number', name: 'phone_number', options: { display: false } },
        { label: 'Email Address', name: 'email_address', options: { display: false } },
        {
            label: ' ',
            name: 'status',
            options: {
                customBodyRender: (value, row) => {
                    return (
                        <Tooltip title={value === 'ProfileSuspended' ? `Activate ${row.rowData[1]}` : `Deactivate ${row.rowData[1]}`}>
                            <Switch
                                checked={value === 'ProfileSuspended' ? false : value === 'ProfileRejected' ? false : value === 'ProfileCreated' ? false : true}
                                onClick={(event) => {
                                    if (value === 'ProfileSuspended') {
                                        event.stopPropagation();
                                        activateCompany(row.rowData);
                                    }
                                    else if (value === 'ProfileAccepted') {
                                        event.stopPropagation();
                                        deactivateCompany(row.rowData);
                                    }
                                    else if (value === 'ProfileRejected'){
                                        event.stopPropagation();
                                        Swal.fire({
                                            text: 'Can not activate already rejected company',
                                            confirmButtonText: 'Ok',
                                            type: null,
                                            allowOutsideClick: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#0066cc'
                                        })
                                    }
                                    else if (value === 'ProfileCreated'){
                                        event.stopPropagation();
                                        Swal.fire({
                                            text: 'Company still not approved by the admin',
                                            confirmButtonText: 'Ok',
                                            type: null,
                                            allowOutsideClick: false,
                                            showConfirmButton: true,
                                            confirmButtonColor: '#0066cc'
                                        })
                                    }
                                }}
                            >
                            </Switch>
                        </Tooltip>

                    );
                }
            }
        },
    ];

    useEffect(() => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/forbidden' });
        }
        getAllCompanies();
    }, [history, user.email]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: '100%' }} id="Companies">
                            <div style={{ paddingBottom: '15%' }} >
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={'Company Details'}
                                        data={companyTableRowData}
                                        columns={companyTableColumnDefs}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                    </Grid>
                </Typography>
            </main >
        </div>
    );
};

export default CompanyDetails;