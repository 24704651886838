import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Grid, TextField, MenuItem, Typography, Box, Select } from '@material-ui/core';
import Countdown from 'react-countdown';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';
import Edit from '@material-ui/icons/Edit';
// import map
import Map from '../../module/Map';
import { withScriptjs } from 'react-google-maps';
import { toShowIcon } from '../../staticPages/YesOrNA';
import moment from 'moment';
import { pascalCase } from '../../utils/index';
import { getDriverLoadsApiCall } from '../../apis/loads';
import { acceptBidApiCall } from '../../apis/bids';
import { COLORS } from '../../theme/colors';
import './index.css';

const LoadDetailsPannel = (props) => {
    const { user } = useAuth0();
    const [assignedDriver, setAssignedDriver] = useState('');
    //Constant to handle path
    const [pathName, setPathName] = useState(props.location.pathname);
    // Constants to handle secondary pane load details
    const [loadNumberDetail, setLoadNumberDetail] = useState('');
    // Constants for showing Loaddetails in the secondary pane
    const [loadPostedByUser, setLoadPostedByUser] = useState('');
    const [loadPostedBy, setLoadPostedBy] = useState('');
    const [loadLoadType, setLoadLoadType] = useState('');
    const [loadLoadNotes, setLoadLoadNotes] = useState('');

    const [loadPickUpdate, setLoadPickUpdate] = useState('');
    //const [loadPickUpAsap, setLoadPickUpAsap] = useState("");
    const [loadPickUpWindow, setLoadPickUpWindow] = useState('');
    const [loadPickUpDestination, setLoadPickUpDestination] = useState('');

    const [loadDeliverByDate, setLoadDeliverByDate] = useState('');
    //const [loadDeliverDirect, setLoadDeliverDirect] = useState("");
    const [loadDeliverWindow, setLoadDeliverWindow] = useState('');
    const [loadDeliverDestination, setLoadDeliverDestination] = useState('');

    const [loadMaxWeight, setLoadMaxWeight] = useState('');
    const [loadNoOfPieces, setLoadNoOfPieces] = useState('');

    const [loadStackable, setLoadStackable] = useState(false);
    const [loadHazmat, setLoadHazmat] = useState(false);
    const [loadDockHigh, setLoadDockHigh] = useState(false);
    const [loadTempControl, setLoadTempControl] = useState(false);
    // ---- Sagar Commented as the feature was not necessary at this moment---------------
    // const [loadRate, setLoadRate] = useState("");
    const [loadFreightDimension, setLoadFreightDimension] = useState('');
    const [loadCityDistance, setLoadCityDistance] = useState('');

    // Constant to handle load notes
    const [loadStatus, setLoadStatus] = useState('');
    const [loadPostedTimeBidHistory, setloadPostedTimeBidHistory] = useState('');

    const MapLoader = withScriptjs(Map);
    const [mapDistance, setMapDistance] = useState('');

    // constants to handle driver avaliablity
    const [availableStatus, setAvaliableStatus] = useState('');

    // constant to disable driver Option
    const [disableDriverOption, setDisableDriverOption] = useState(false);

    const [disableStatusChange, setDisableStatusChange] = useState(false);

    const [showHelperText, setShowHelperText] = useState(false);
    const [statustobeSelected, setStatustobeSelected] = useState('');

    const [progress, setProgress] = React.useState(10);

    let status = '';

    const populatePropsForLoadDetails = () => {
        if (props.allLoadDetails.length !== 0) {
            setShowHelperText(false);
            setMapDistance(props.allLoadDetails);
            setLoadNumberDetail(props.allLoadDetails.load_number);
            setLoadPostedByUser(props.allLoadDetails.posted_by_user);
            setLoadPostedBy(props.allLoadDetails.posted_by_company);
            setLoadLoadNotes(props.allLoadDetails.load_notes);
            setLoadMaxWeight(props.allLoadDetails.max_weight);
            setLoadPickUpdate(props.allLoadDetails.pickupfrom_date);
            setLoadDeliverByDate(props.allLoadDetails.pickupto_date);
            if (props.allLoadDetails.status !== 'onsite_for_pickup' || props.allLoadDetails.status !== 'picked_up' || props.allLoadDetails.status !== 'in_transit' || props.allLoadDetails.status !== 'onsite_for_delivery' || props.allLoadDetails.status !== 'delivered') {
                setLoadStatus(props.allLoadDetails.status === 'assigned' ? 'Assigned to Driver' : props.allLoadDetails.status);
            }
            setloadPostedTimeBidHistory(moment.utc(props.allLoadDetails.time_stamp).local().format('YYYY-MM-DD HH:mm:ss'));
            if (props.allLoadDetails.truck_type === '') {
                setLoadLoadType('NA');
            }
            else {
                setLoadLoadType(props.allLoadDetails.truck_type.toString().replace(/,/g, ', '));
            }
            if (props.allLoadDetails.total_item_count === '') {
                setLoadNoOfPieces('NA');
            }
            else {
                setLoadNoOfPieces(props.allLoadDetails.total_item_count);
            }
            let freightDimension = '';
            if (props.allLoadDetails.freight_dimension_length.toString().trim() === '' &&
                props.allLoadDetails.freight_dimension_width.toString().trim() === '' &&
                props.allLoadDetails.freight_dimension_height.toString().trim() === '') {
                setLoadFreightDimension('NA');
            }
            else {
                if (props.allLoadDetails.freight_dimension_length.toString().trim() === '') {
                    freightDimension += 'NA X ';
                }
                else {
                    freightDimension += props.allLoadDetails.freight_dimension_length.toString().trim() + 'L X ';
                }
                if (props.allLoadDetails.freight_dimension_width.toString().trim() === '') {
                    freightDimension += 'NA X ';
                }
                else {
                    freightDimension += props.allLoadDetails.freight_dimension_width.toString().trim() + 'W X ';
                }
                if (props.allLoadDetails.freight_dimension_height.toString().trim() === '') {
                    freightDimension += 'NA';
                }
                else {
                    freightDimension += props.allLoadDetails.freight_dimension_height.toString().trim() + 'H';
                }
                setLoadFreightDimension(freightDimension);
            }
            setLoadStackable(Boolean(props.allLoadDetails.stackable));
            setLoadHazmat(Boolean(props.allLoadDetails.hazmat));
            setLoadDockHigh(Boolean(props.allLoadDetails.dockhigh));
            setLoadTempControl(Boolean(props.allLoadDetails.tempcontrol));
            if (props.allLoadDetails.cities_distance) {
                setLoadCityDistance(props.allLoadDetails.cities_distance);
            }
            if (
                props.allLoadDetails.pickup_from_window === 'ASAP' &&
                props.allLoadDetails.pickup_to_window === 'ASAP'
            ) {
                setLoadPickUpWindow('ASAP');
            }
            else {
                setLoadPickUpWindow(
                    props.allLoadDetails.pickup_from_window + ' - ' + props.allLoadDetails.pickup_to_window
                );
            }
            if (
                props.allLoadDetails.deliver_from_window.includes('Direct') &&
                props.allLoadDetails.deliver_to_window.includes('Direct')
            ) {
                setLoadDeliverWindow('Deliver Direct(Non-Stop)');
            }
            else {
                setLoadDeliverWindow(
                    props.allLoadDetails.deliver_from_window + ' - ' + props.allLoadDetails.deliver_to_window
                );
            }
            setLoadPickUpDestination(props.allLoadDetails.pickup_dest);
            setLoadDeliverDestination(props.allLoadDetails.deliverZip);
            setLoadDeliverDestination(props.allLoadDetails.delivery_dest);
        }
    };

    useEffect(() => {
        populatePropsForLoadDetails();
    }, [props]);

    //Function to execute condition statement on sweet alert
    const sweetAlertOkOrCancel = (result, event, avaliablity) => {
        if (result.value === true) {
            setDisableDriverOption(true);
            props.changeDriver(event.target.value, avaliablity);
            setAssignedDriver(event.target.value);
        }
        else {
            setAssignedDriver('');
            setDisableDriverOption(false);
        }
    };

    const updateDriverStatus = async (driverStatus, load_number, driverName) => {
        let driver_name = props.allLoadDetails.driver_name;
        if (props.allLoadDetails.driver_name === null) {
            driver_name = driverName;
        }
        let getLoadResponse = await getDriverLoadsApiCall(driver_name);
        let data = {};
        if (getLoadResponse !== undefined) {
            let assignToDriverList = getLoadResponse.filter(function (driverLoadDetails) {
                return driverLoadDetails['driver_name'] === driver_name && driverLoadDetails['status'] === 'Queued';
            });
            assignToDriverList.forEach(async function (element) {
                if (element.driver_status && element.load_number !== load_number) {
                    data = {
                        load_id: element.load_id,
                        bid_id: element.bid_id,
                        carrier_name: element.carrier_name,
                        status: 'queued',
                        carrier_company: element.carrier_company,
                        driver_name: element.driver_name,
                        driver_status: driverStatus,
                    };
                    try {
                        await acceptBidApiCall(data);
                    }
                    catch (error) {
                        console.log(error, 'Error in updating driver_status');
                    }
                }
            });
        }
    };

    // Function to handle onchange value for Driver select
    const handleOnchangeValueOfDriverSelectBox = async (event) => {
        let getLoadResponse = await getDriverLoadsApiCall(event.target.value);
        if (getLoadResponse) {
            let assignToDriverList = getLoadResponse.filter(function (driverLoadDetails) {
                return driverLoadDetails['driver_name'] === event.target.value &&
                    (driverLoadDetails['status'] !== 'Delivered' &&
                        driverLoadDetails['status'] !== 'Queued');
            });
            if (assignToDriverList.length >= 1) {
                setAvaliableStatus('Busy');
                Swal.fire({
                    text: ' Driver have load in progress. Do you want to add load to the queue ?',
                    cancelButtonColor: COLORS.websiteColor,
                    confirmButtonText: 'Ok',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: COLORS.websiteColor
                }).then((result) => {
                    sweetAlertOkOrCancel(result, event, 'Busy');
                });
            }
            else {
                setAvaliableStatus('Free');
                Swal.fire({
                    text: 'Are you sure you want to assign this load to ' + pascalCase(event.target.value.substring(0, event.target.value.lastIndexOf('@'))) + '?',
                    cancelButtonColor: COLORS.websiteColor,
                    confirmButtonText: 'Ok',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: COLORS.websiteColor
                }).then((result) => {
                    sweetAlertOkOrCancel(result, event, 'Free');
                });
            }
            updateDriverStatus('Busy', props.allLoadDetails.load_number, event.target.value);
        }
    };

    // Function to selected enabled status
    const handleOnchangeValueOfLoadStatus = async (event) => {
        status = event.target.value;
        setDisableStatusChange(false);
        let getLoadResponse = await getDriverLoadsApiCall(props.allLoadDetails.driver_name);
        if (getLoadResponse) {
            let assignToDriverList = getLoadResponse.filter(function (driverLoadDetails) {
                return driverLoadDetails['driver_name'] === event.target.value &&
                    (driverLoadDetails['status'] !== 'Delivered' &&
                        driverLoadDetails['status'] !== 'Queued');
            });
            if ((assignToDriverList.length === 0 || assignToDriverList.length >= 1) && (event.target.value !== 'Delivered')) {
                updateDriverStatus('Busy', props.allLoadDetails.load_number);
                props.saveStatus(event.target.value, 'Busy');
            }
            else {
                props.saveStatus(event.target.value, 'Free');
                updateDriverStatus('Free');
            }
        }
    };

    // Function to selected enabled status
    const handleOnCloseAction = () => {
        if (loadStatus === 'On-site for Pick up') {
            status = 'Picked Up';
        }
        if (loadStatus === 'Picked Up') {
            status = 'In-Transit';
        }
        if (loadStatus === 'In-Transit') {
            status = 'On-site for Delivery';
        }
        if (loadStatus === 'On-site for Delivery') {
            status = 'Delivered';
        }
    };

    const handleEnablingDropDown = () => {
        setDisableStatusChange(true);
    };

    return (
        <React.Fragment>
            <Grid style={{ fontSize: 14 }} xs={12}>
                <Grid container direction="row">
                    <Grid xs={6}>
                        <Row>
                            <Col sm>Load ID:</Col>
                            <Col sm>{loadNumberDetail}</Col>
                        </Row>
                        <div style={{ height: 13 }} />
                        <Row>
                            <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                            <Col sm style={{ fontWeight: 'bold' }}>{loadPickUpDestination}</Col>
                        </Row>
                        <div style={{ height: 13 }} />
                        <Row>
                            <Col sm>Pick-Up Window:</Col>
                            <Col sm>{loadPickUpWindow}</Col>
                        </Row>
                        <div style={{ height: 13 }} />
                        <Row>
                            <Col sm>Pick-Up Date:</Col>
                            <Col sm>{loadPickUpdate}</Col>
                        </Row>
                        <div style={{ height: 13 }} />
                        {((pathName === '/bidswon' && props.allLoadDetails.status !== 'Delivered' && props.allLoadDetails.status !== 'Awarded') || ((pathName === '/bidswon' || pathName === '/loadsassigned') && (props.allLoadDetails.status !== 'Queued' && props.allLoadDetails.status !== 'Awarded' && props.allLoadDetails.status !== 'Free')) || (pathName === '/loadspickedup') || (pathName === '/loadsassigned' && props.allLoadDetails.status === 'Queued' && props.allLoadDetails.driver_status === 'Free')) ?
                            <Box className='color' boxShadow={3} style={((props.allLoadDetails.driver_status === 'Busy' && props.allLoadDetails.status === 'Queued') || (props.allLoadDetails.status === 'Delivered')) ? { width: '95%', backgroundColor: COLORS.background, height: '17%' } : (pathName === '/bidswon' && props.allLoadDetails.status !== 'Queued') ? { width: '95%', backgroundColor: COLORS.background, height: '25%' } : (pathName === '/bidswon' && props.allLoadDetails.status === 'Queued') ? { width: '95%', backgroundColor: COLORS.background, height: '22%' } : { width: '95%', backgroundColor: COLORS.background, height: '19%' }}
                                color='#101e35' border={0} padding={2} paddingTop={'2%'}>
                                {
                                    (props.allLoadDetails.driver_name === null && props.allLoadDetails.status !== 'Assigned to Driver' && props.allLoadDetails.status === 'Confirmed' && loadPostedByUser !== user.email) && (
                                        <Row style={{ fontWeight: 'bold' }}>
                                            <Col sm style={{ paddingTop: '4%' }}>Select Driver :</Col>
                                            <Col sm style={{ padding: 0, paddingTop: '2%' }}>
                                                <TextField
                                                    variant='outlined'
                                                    id="driver"
                                                    select
                                                    style={{ minWidth: '90%', fontSize: '5px', marginLeft: '4%' }}
                                                    value={assignedDriver}
                                                    onChange={handleOnchangeValueOfDriverSelectBox}
                                                    disabled={disableDriverOption}
                                                >
                                                    {(props.driversList !== 'UserNotPresent') && (
                                                        props.driversList.map((driver, index) => (
                                                            <MenuItem style={{ fontSize: '11px' }} key={index} value={driver.email_address}>
                                                                <div style={{ marginRight: '5%' }}>{pascalCase(driver.email_address.substring(0, driver.email_address.lastIndexOf('@')))}</div>
                                                                <div className={availableStatus !== 'Busy' ? COLORS.success : COLORS.red}>{availableStatus}</div>
                                                            </MenuItem>
                                                        ))
                                                    )}
                                                </TextField>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {(pathName === '/bidswon' && (assignedDriver !== '' || props.allLoadDetails.driver_name !== null) && loadStatus !== 'Awarded' && loadStatus !== 'Confirmed') && (
                                    <Row style={{ fontWeight: 'bold' }}>
                                        <Col sm>Assigned to:</Col>
                                        <Col sm style={{ paddingLeft: '8%' }}>{assignedDriver !== '' ? assignedDriver : props.allLoadDetails.driver_name}</Col>
                                    </Row>
                                )}
                                <div>
                                    {((disableStatusChange || loadStatus !== 'Assigned to Driver') && (disableStatusChange || props.allLoadDetails.status !== 'Queued') && props.allLoadDetails.status !== 'Delivered' && props.allLoadDetails.status !== 'Closed' && props.allLoadDetails.status !== 'Awarded' && props.allLoadDetails.status !== 'Confirmed' && loadPostedByUser !== user.email) ?
                                        <div>
                                            <div style={{ height: 13 }} />
                                            <Row>
                                                <Col sm style={{ marginTop: '2%' }}> Load Status:</Col>
                                                <Col sm={4} style={{ padding: 0, minWidth: 211 }}>
                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        value={loadStatus}
                                                        style={{ minWidth: 50, fontSize: '15px', marginTop: '2%', marginLeft: '18%', paddingLeft: '16%', textAlign: 'center' }}
                                                        onChange={handleOnchangeValueOfLoadStatus}
                                                        disabled={!disableStatusChange}
                                                        onClose={handleOnCloseAction}
                                                    >
                                                        <MenuItem hidden={props.location.pathname !== '/loadsassigned' && props.location.pathname !== '/bidswon'} disabled={loadStatus !== 'Assigned to Driver' && loadStatus !== 'Queued'} value="On-site for Pick up">On-site for Pick up</MenuItem>
                                                        <MenuItem hidden={props.location.pathname !== '/loadsassigned' && props.location.pathname !== '/bidswon'} disabled={loadStatus !== 'On-site for Pick up'} value="Picked up">Picked up</MenuItem>
                                                        <MenuItem hidden={props.location.pathname !== '/loadspickedup' && props.location.pathname !== '/bidswon'} disabled={loadStatus !== 'Picked up'} value="In-Transit">In-Transit</MenuItem>
                                                        <MenuItem hidden={props.location.pathname !== '/loadspickedup' && props.location.pathname !== '/bidswon'} disabled={loadStatus !== 'In-Transit'} value="On-site for Delivery">On-site for Delivery</MenuItem>
                                                        <MenuItem hidden={props.location.pathname !== '/loadspickedup' && props.location.pathname !== '/bidswon'} disabled={loadStatus !== 'On-site for Delivery'} value="Delivered">Delivered</MenuItem>
                                                    </Select>
                                                </Col>
                                                <Col sm={1} style={{ marginTop: '2%' }}>
                                                    <Edit hidden={!loadStatus || disableStatusChange} className='coediticon' onClick={() => handleEnablingDropDown()} />
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ height: 13 }} />
                                            <Row style={{ fontWeight: 'bold' }}>
                                                <Col sm>Load Status:</Col>
                                                <Col sm={6} style={{ padding: 0, paddingLeft: '6%' }}>{loadStatus}
                                                    <Edit style={{ marginLeft: 10 }} hidden={!loadStatus || disableStatusChange || loadStatus === "Delivered" || (loadStatus === "Queued" && props.location.pathname === '/bidswon') || loadStatus === 'Closed' || loadStatus === 'Awarded' || loadStatus === 'Confirmed'} className='coediticon' onClick={() => handleEnablingDropDown()} />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </div>
                                <Row style={{ paddingLeft: '53%', marginTop: '2%' }}>
                                    <Typography className="helperText" component={'div'} hidden={!showHelperText} variant='caption' style={{ fontSize: '12px', paddingLeft: '10%', color: COLORS.red }}>Status to be selected is {statustobeSelected}</Typography>
                                </Row>
                            </Box>
                            :
                            <Row style={{ fontWeight: 'bold' }}>
                                <Col sm>Load Status:</Col>
                                <Col sm style={{ paddingLeft: '3%' }}>{loadStatus}</Col>
                            </Row>
                        }

                        <div style={{ height: 13 }} />
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col sm>Weight:</Col>
                            <Col sm>
                                {loadMaxWeight}
                            </Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <Row>
                            <Col sm>Stackable:</Col>
                            <Col sm>
                                {toShowIcon(loadStackable)}
                            </Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <Row>
                            <Col sm>Hazmat:</Col>
                            <Col sm>
                                {toShowIcon(loadHazmat)}
                            </Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <Row>
                            <Col sm>Dock High:</Col>
                            <Col sm>
                                {toShowIcon(loadDockHigh)}
                            </Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <Row>
                            <Col sm>Temperature Control:</Col>
                            <Col sm>
                                {toShowIcon(loadTempControl)}
                            </Col>
                        </Row>
                        {(pathName === '/bidswon' && (props.allLoadDetails.status === 'Delivered' || props.allLoadDetails.status === 'Queued' || props.allLoadDetails.status === 'Awarded')) && (
                            <div>
                                <div style={{ height: 14 }} />
                                <Row>
                                    <Col sm>Load Posted at:</Col>
                                    <Col sm >{loadPostedTimeBidHistory}</Col>
                                </Row>
                                <div style={{ height: 15 }} /><Row>
                                    <Col sm>Load Expired In:</Col>
                                    <Col sm >{
                                        <Countdown autoStart={true} date={Date.now() + 0}>
                                        </Countdown>
                                    }</Col>
                                </Row>
                            </div>
                        )}
                        <div style={{ height: 10 }} />
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col sm>Approximate Distance {<div>(Drive Time):</div>}</Col>
                            <Col sm >{loadCityDistance}</Col>
                        </Row>

                        <div style={{ height: 20 }} />
                    </Grid>
                    <Grid xs={6}>
                        <Row>
                            <Col sm>Posted By:</Col>
                            <Col sm>{loadPostedBy}</Col>
                        </Row>
                        <div style={{ height: 12 }} />
                        <Row>
                            <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                            <Col sm style={{ fontWeight: 'bold' }}>{loadDeliverDestination}</Col>
                        </Row>
                        <div style={{ height: 13 }} />
                        <Row>
                            <Col sm>Delivery Window:</Col>
                            <Col sm>{loadDeliverWindow}</Col>
                        </Row>
                        <div style={{ height: 13 }} />
                        <Row>
                            <Col sm>Deliver Date:</Col>
                            <Col sm>{loadDeliverByDate}</Col>
                        </Row>
                        <div style={{ height: 15 }} />
                        <TextField
                            label="Load Notes"
                            multiline
                            rows={pathName === '/bidswon' && props.allLoadDetails.status !== 'Delivered' && props.allLoadDetails.status !== 'Queued' && props.allLoadDetails.driver_status !== 'Free' ? 5 : pathName === '/bidswon' && props.allLoadDetails.driver_status === 'Free' && props.allLoadDetails.status !== 'Delivered' ? 5 : 3}
                            variant="outlined"
                            style={{
                                width: '97%',
                                backgroundColor: COLORS.background
                            }}
                            value={loadLoadNotes}
                            disabled={true}
                        />
                        <div style={{ height: 18 }} />
                        <Row sm style={{ fontWeight: 'bold' }}>
                            <Col sm>Number Of Pieces:</Col>
                            <Col sm >
                                {loadNoOfPieces}
                            </Col>
                        </Row>
                        <div style={{ height: 18 }} />
                        <Row>
                            <Col sm>Freight Dimension:</Col>
                            <Col sm >{loadFreightDimension}</Col>
                        </Row>
                        <div style={{ height: 10 }} />
                        <Row>
                            <Col sm>Truck Type:</Col>
                            <Col sm>
                                {loadLoadType}
                            </Col>
                        </Row>
                        {(pathName === '/bidswon' && props.allLoadDetails.status !== 'Delivered' && props.allLoadDetails.status !== 'Queued' && props.allLoadDetails.status !== 'Awarded') && (
                            <div>
                                <div style={{ height: 14 }} />
                                <Row>
                                    <Col sm>Load Posted at:</Col>
                                    <Col sm >{loadPostedTimeBidHistory}</Col>
                                </Row>
                                <div style={{ height: 15 }} /><Row>
                                    <Col sm>Load Expired In:</Col>
                                    <Col sm >{
                                        <Countdown autoStart={true} date={Date.now() + 0}>
                                        </Countdown>
                                    }</Col>
                                </Row>
                            </div>
                        )}
                        <div style={{ height: 15 }} />
                        <Row style={{ fontWeight: 'bold' }}>
                            <Col sm>Posted By User:</Col>
                            <Col sm>{loadPostedByUser}</Col>
                        </Row>
                    </Grid>
                    {/*<div style={{ justifyContent: "center", width: "100%", display: "flex" }}>

                        <div ><div>
                            <MapLoader data={{ "fromlatitude": mapDistance.pickuplat, "fromlongitude": mapDistance.pickuplng, "tolatitude": mapDistance.deliverylat, "tolongitude": mapDistance.deliverylng }}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB6w_WDy6psJ5HPX15Me1-o6CkS5jTYWnE"
                                loadingElement={<div style={{ height: `100%` }} />}
                            />
                        </div></div>
                        </div>*/}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
export default withRouter(LoadDetailsPannel);