import { combineReducers } from 'redux';

/* ---------------  reducers  --------------- */
import sessionReducer from './session/reducer';
import { userReducer } from './users/reducer';

export default combineReducers({
    sessionReducer,
    userReducer,
});
