import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { COLORS } from '../../theme/colors';
import {
    Tabs,
    Tab,
    Snackbar,
    CssBaseline,
    List,
    ListItem,
    Divider,
    IconButton,
    Typography,
    Grid,
    Paper,
    Box,
    Button,
    TextField,
    InputAdornment,
    InputBase,
    Tooltip,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete as MUIAutoComplete } from '@material-ui/lab';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import cities from 'cities';
// import zipcodes from 'zipcodes';
import Swal from 'sweetalert2';
import moment from 'moment';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import useWebSocket, { } from 'react-use-websocket';
import addNotification from 'react-push-notification';
import slugid from 'slugid';
import { toNumber } from 'lodash';
import Countdown from 'react-countdown';
import { animateScroll } from 'react-scroll';
import { pascalCase, scrollTopOnRowClick } from '../../utils/index';

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// Imports to have all the Icons
import { NotVerifiedUserIcon } from '../../assets/SvgIcons/NotVerifiedUserIcon';
import CloseIcon from '@material-ui/icons/Close';
import { AttachMoney } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';
import { ExpandMoreIcon } from '../../assets/SvgIcons/ExpandMore';

// import pin and Unpin icons
import { Pinned } from '../../assets/SvgIcons/Pinned';
import { UnPinned } from '../../assets/SvgIcons/UnPinned';

import StarIcon from '@material-ui/icons/Star';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { ExpandLessIcon } from '../../assets/SvgIcons/ExpandLess';
import { AcceptCounterOffer, ExpiredCounterOffer, BookItButton } from '../../components/Button/Button';
import { reduceCountOnLoadExpire, updateCount, sendEmailForMatchedLane } from '../SavedSearchLane/updatecount';

// Import statements for Chat Component
import Header from '../../components/CommentsComponent/Header/Header';
import HeaderChat from '../../components/CommentsComponent/HeaderChat/HeaderChat';
import Chat from '../../components/CommentsComponent/Chat/Chat';

// Import statements for CSS and environment
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { environmentVariables } from '../../constants/environment';
import SearchForm from '../SearchForm';
import MUIDrawer from '../../components/Drawer';
import Navbar from '../../components/Navbar';
import sortDate from '../../components/Sorting/sortDate';
import sortNum from '../../components/Sorting/sortNum';
import PostLoadForm from '../PostLoadForm';
import { toShowIcon } from '../../staticPages/YesOrNA';

//import statement to handle post load form edit
import PromptComponent from '../Prompt';
// import map
import Map from '../Map';
import { addNotificationApiCall, deleteNotificationApiCall, editNotificationApiCall, getTabNotificationApiCall } from '../../apis/notifications';
import { createLoadApiCall, deleteLoadApiCall, fetchLoadApiCall } from '../../apis/loads';
import { acceptBidApiCall, createBidApiCall, deleteBidApiCall, editBidApiCall, getBidApiCall } from '../../apis/bids';
import { editLoadDetailApiCall } from '../../apis/loads';
import { createCommentApiCall, getCommentApiCall, updateCommentApiCall } from '../../apis/chat';
import { getMatchedLoadApiCall, saveLaneApiCall } from '../../apis/searchedLane';
import { acceptBidNotificationApiCall, acceptCounterOfferNotificationApiCall, counterOfferNotificationApiCall, createBidNotificationApiCall, createLoadNotificationApiCall } from '../../apis/emailNotifications';


// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';
import { handleSendMessageForSocketIO, handleSocketReceiveMessage } from '../WebSocketConnection';

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isUserCarrier = false;
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
let allPostedLoades = [];
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 10,
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
        color: '#101e35'
    },
    indicator: {
        backgroundColor: '#101e35',
        color: '#101e35'
    }

}));

// Function to handle MuiAlert
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
// function to get top tab pannels
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
//--------------------------------------------*********************-----------------------------------------
const LandingPage = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const dispatch = useDispatch();

    //constats to handle post load form
    const [postLoadFormFunctionality, setPostLoadFormFunctionality] = useState('Post Load');
    const [loadUpdatedStatus, setLoadUpdatedStatus] = useState(false);
    const PostLoadFormRef = useRef();
    const SearchFormRef = useRef();
    const [Prompt, setShowPrompt, setPristine] = PromptComponent('Changes you made may not be saved');

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState('');
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    // Constant to handle expand and colapse the top pannel
    const [expandColapseTopPannel, setExpandColapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle logged in user details
    const [userCompanyName, setUserCompanyName] = useState('');
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState('')
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);
    const [enableSetButton, setEnableSetButton] = useState(true);
    // $$$$$$$$$$$$$$$$$$$$ Constants to handle  top box $$$$$$$$$$$$$$$$$$$$$$$$$$$
    // Constant to handle LoadNumber
    const [loadNumber, setLoadNumber] = useState('');
    const [loadNumberDetail, setLoadNumberDetail] = useState('');

    // Constants to handle Hours and minutes
    const hourValue = ['01', '03', '06', '12', '24'];
    const minutes = ['15', '30', '45'];

    // Variable to handle length width height
    const [txtLength, setTxtLength] = useState('');
    const [txtWidth, setTxtWidth] = useState('');
    const [txtHeight, setTxtHeight] = useState('');

    // Constant to handle errors
    const [errors, setErrors] = useState({});
    //---------- Hiding code as the feature is not necessary currently ------------//
    // const [txtRate, setTxtRate] = useState('');

    // Hidden code for above market rate checkbox
    // const [checkBoxAboveMarketRate, setCheckBoxAboveMarketRate] = React.useState(false);

    // Constant to handle bottom grid open and close
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [hint, setHint] = useState('');
    const [guide, setGuide] = useState([]);

    // Constants for search component
    const [loadNumberLength, setLoadNumberLength] = useState(0);
    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState('');
    const [searchPickUpFromState, setSearchPickUpFromState] = useState('');
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState('');
    const [searchPickUpMiles, setSearchPickUpMiles] = useState('');

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState('');
    const [searchDeliverToState, setSearchDeliverToState] = useState('');
    const [searchDeliverToZip, setSearchDeliverToZip] = useState('');

    // Constant to handle main MuiDatatable
    const [rowData, setRowData] = useState([]);
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [isPaneOpen, setIsPaneOpen] = useState(false);

    //constant to handle mui data table loader
    const [loading, setLoading] = useState(false);

    //constants to handle bit table loader
    // const [loadingBids, setLoadingBids] = useState(false);
    const [loadingActiveBids, setLoadingActiveBids] = useState(false);
    const [loadingClosedBids, setLoadingClosedBids] = useState(false);

    //constants to handle post load form
    const [rowDataForPostLoadForm, setRowDataForPostLoadForm] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);

    // Constant for max weight 
    const [txtMilesOut, updateTxtMilesOut] = useState('');

    // Constant to handle load notes
    const [BidNotes, updateBidNotes] = useState('');

    // variable to handle counter offer
    const [counterOffer, setCounterOffer] = useState('');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [mapDistance, setMapDistance] = useState('');

    //counter Offer Open Form
    const [counteropen, setCounterOpen] = useState(false);
    const [counteropencarrier, setCounterOpenCarrier] = useState(false);
    const [disableBookit, setDisableBookit] = useState(false);
    const [disableCounterButton, setDisableCounterButton] = useState(false);
    const [disableEdit, setDisadbleEdit] = useState(false);

    //constant To Handle Label
    const [label, setLabel] = useState('Post Load');
    const [isNotificationSent, setIsNotificationSent] = useState(false);

    // Constant to handle Pin and Unpin
    const [pinned, setPinned] = useState(false);

    const [mapUpdate, setMapUpdate] = useState(true);

    const options = {
        filter: false,
        search: false,
        responsive: 'vertical',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onChangePage: () => (HandlePageChange()),
        onTableChange: (rowData, rowindex) => (setMuiDatatableColour(rowindex)),
        onRowClick: (rowData, rowState) =>
            handleRowClickConfirmation(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex);
                }
            }
            const findResult = rowData.find(
                ({ load_id }) => load_id === row[0]
            );
            if (findResult !== undefined) {
                if (findResult.read_users) {
                    const findStatus = findResult.read_users.find(
                        ({ name }) => name === user.email
                    );
                    if (findStatus === undefined) {
                        return {
                            style: { backgroundColor: '#dceede', fontStyle: 'italic' }
                        };
                    }
                }
            }
            let filteredArray = chatDetails.filter(({ load_id }) => load_id === row[0]);
            let rowStatus = 0;
            filteredArray.forEach(element => {
                if (element.read_status) {
                    const findChatStatus = element.read_status.find(
                        ({ name }) => name === user.email
                    );
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValue.load_id !== row[0]) {
                        rowStatus = rowStatus + 1;
                    }
                }
            });
            if (rowStatus > 0) {
                return {
                    style: { backgroundColor: '#DAF7A6' }
                };
            }

        },

        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    'No loads are posted'
            },
        }
    };

    const columnDefs = [
        { label: 'PostId', name: 'load_id', options: { display: false } },
        {
            label: ' ',
            name: 'unread_chat_count',
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined && value.includes(',')) {
                        let normalChat = value.split(',')[0];
                        let bidChat = value.split(',')[1];
                        if (normalChat > 0 && bidChat > 0 && selectedRowDataValueForSocketIO.load_id !== row.rowData[0]) {
                            return (
                                <div>
                                    < MessageRoundedIcon />
                                    < AttachMoney />
                                </div>
                            );
                        }
                        else if (normalChat > 0 && selectedRowDataValueForSocketIO.load_id !== row.rowData[0]) {
                            return (
                                < MessageRoundedIcon />
                            );
                        }
                        else if (bidChat > 0 && selectedRowDataValueForSocketIO.load_id !== row.rowData[0]) {
                            return (
                                < AttachMoney />
                            );
                        }
                    }
                }
            }
        },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pickupfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Miles (Time)',
            name: 'cities_distance',
            options: {
                sort: true,
                display: displaySpecificColums, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '' && value.length !== 0) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== '') {
                                if (valEnteredNumber.includes(',')) {
                                    let splittingValue = valEnteredNumber.split(',');
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== 'NaN' && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        // {
        //     label: 'Above Market Rate',
        //     name: 'abovemarketrate',
        //     options: {
        //         display: displaySpecificColums
        //     }
        // },
        {
            label: 'TimeStamp',
            name: 'time_stamp',
            options: {
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = 'false';
                        }
                    }
                }
            }
        },
        {
            label: 'LoadExpirationTime',
            name: 'load_expiration_time',
            options:
            {
                display: false,
                viewColumns: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = 'false';
                        }
                    }
                }
            }
        },
        {
            label: 'Load Expires In',
            name: 'LoadExpiresIn',
            options: {
                display: displaySpecificColums,
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[10];
                    var localTime = moment.utc(TimeStamp).local().format('YYYY-MM-DD HH:mm:ss');
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(LoadExpirationInMiliseconds(rowIndex.rowData[11]))}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: 'Posting Company',
            name: 'posted_by_company',
            options: {
                display: displaySpecificColums
            }
        },
        {
            label: 'Load ID',
            name: 'load_number',
            options: {
                // display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
        {
            label: 'Status', name: 'status', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        <div style={{ fontWeight: 'bold' }}>{rowuserdata.rowData[15].toUpperCase()}</div>
                    );
                }
            }
        },
    ];
    // Variable to handle column defs of load table
    const [columnDefsLoadTable, setColumnDefsLoadTable] = useState(columnDefs);
    // Constants to handle secondary pane load details

    // Constant to handle watch_load 
    const [loadWatchLoad, setLoadWatchLoad] = useState(false);

    // Constant to handle saved_lane 
    const [loadSavedLane, setLoadSavedLane] = useState(false);

    // Constants for showing Loaddetails in the secondary pane
    const [loadPostedByUser, setLoadPostedByUser] = useState('');
    const [loadPostedBy, setLoadPostedBy] = useState('');
    const [loadLoadType, setLoadLoadType] = useState('');
    const [loadLoadNotes, setLoadLoadNotes] = useState('');

    const [loadPickUpdate, setLoadPickUpdate] = useState('');
    const [loadPickUpWindow, setLoadPickUpWindow] = useState('');
    const [loadPickUpDestination, setLoadPickUpDestination] = useState('');

    const [loadDeliverByDate, setLoadDeliverByDate] = useState('');
    //const [loadDeliverDirect, setLoadDeliverDirect] = useState('');
    const [loadDeliverWindow, setLoadDeliverWindow] = useState('');
    const [loadDeliverDestination, setLoadDeliverDestination] = useState('');

    const [loadMaxWeight, setLoadMaxWeight] = useState('');
    const [loadNoOfPieces, setLoadNoOfPieces] = useState('');

    const [loadStackable, setLoadStackable] = useState(false);
    const [loadHazmat, setLoadHazmat] = useState(false);
    const [loadDockHigh, setLoadDockHigh] = useState(false);
    const [loadTempControl, setLoadTempControl] = useState(false);
    // const [loadRate, setLoadRate] = useState('');
    const [loadFreightDimension, setLoadFreightDimension] = useState('');
    const [loadBidAmount, setLoadBidAmount] = useState('');
    const [bidExpiration, setBidExpiration] = useState('');
    const [loadBidAmountDisable, setLoadBidAmountDisable] = useState(false);
    const [loadStatus, setLoadStatus] = useState('');
    const [loadCityDistance, setLoadCityDistance] = useState('');
    const [loadPostedTime, setloadPostedTime] = useState('');
    const [loadExpiresIn, setLoadExpiresIn] = useState('');
    const [loadUpdateLoadExpirationTimeForHour, setLoadUpdateLoadExpirationTimeForHour] = useState('');
    const [loadUpdateLoadExpirationTimeForMin, setLoadUpdateLoadExpirationTimeForMin] = useState('');
    const [isUpdateLoadExpirationValueChanged, setIsUpdateLoadExpirationValueChanged] = useState(false);
    const [loadBidNotesDisable, setLoadBidNotesDisable] = useState(false);
    const [MilesOutDisable, setMilesOutDisable] = useState(false);
    const [lengthDisable, setLengthDisable] = useState(false);
    const [widthDisable, setWidthDisable] = useState(false);
    const [heightDisable, setHeightDisable] = useState(false);

    // Constant to handle selected row (List on single row selected)
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    // Constant to handle second muidatatble 
    const [rowDataActiveBidTable, setRowDataActiveBidTable] = useState([]);

    // Constant to handle third muidatatble 
    const [rowDataClosedBidTable, setRowDataClosedBidTable] = useState([]);

    // constants to handle disabling buttons after first click
    const [disableBidButton, setDisableBidButton] = useState(false);
    const [disablePostLoadButton, setDisablePostLoadButton] = useState(false);
    const [disableClearFormButton, setDisableClearFormButton] = useState(false);
    const [disableUpdateLoadButton, setDisableUpdateLoadButton] = useState(false);
    const [disableCancelButton, setDisableCancelButton] = useState(false);

    // constant to Enable Steps
    const [enabled, setEnabled] = useState(true);

    //Function to exist Step
    const onExit = () => {
        setEnabled(false);
        setHint('');
    };

    //Function To Start Steps
    const steps = [
        {
            element: '#txtFieldBidAmount',
            intro: 'It will allow user to input bid amount for a load.',
            position: 'bottom',
        },
        {
            element: '#minutes',
            intro: 'It will allow user to input time that Bid should be active',
            position: 'bottom',
        },
        {
            element: '#MilesOut',
            intro: 'It allow user to input distance away from load',
            position: 'bottom',
        },
        {
            element: '#BidNotes',
            intro: 'It will allow user to input additional details about bid',
            position: 'bottom',
        },
        {
            element: '#truckDimension',
            intro: ' It will allow user to input Size of trailer/truck/Van and  User can click on bid now to bid on the loads',
            position: 'bottom',
        },
        {
            element: '#activeBids',
            intro: 'User can see active bids. It will also allow user to update counter offer by broker',
            position: 'left',
        },
        {
            element: '#closeBids',
            intro: 'User can see Expired/Closed bids and Counter Offer',
            position: 'left',
        },
        {
            element: '#chatguide',
            intro: ' User submitting Bid , Bid Chat is generatred and  can livechat with broker user',
            position: 'left',
        }
    ];
    /*******************************************************************************************************************************/
    const steps1 = [
        {
            element: '#SaveLane',
            intro: 'This Load Details will be saved and any Load Posted with same Destination You will get email and Notification',
            position: 'bottom',
        },
        {
            element: '#CloseLoadDetail',
            intro: 'Load Details will be closed',
            position: 'bottom',
        },
        {
            element: '#LoadDetails',
            intro: 'Detail information about the load posted by broker',
            position: 'left',
        },
        {
            element: '#Mapguide',
            intro: ' User can see location for pick up and delivery on the map',
            position: 'left',
        }
    ];

    //Function To Display Particular Steps
    const ShowIntro = (data) => {
        setEnabled(true);
        if (data === 'Bid') {
            setHint('Bid');
            setGuide(steps);
        }
        else {
            setHint('Details');
            setGuide(steps1);
        }
    };

    // Function to get bid status for bid tables
    const getStatusForBidTables = (status) => {
        let newStatus;
        if (status === 'new') {
            newStatus = 'New';
        }
        else if (status === 'accepted') {
            newStatus = 'Won';
        }
        else {
            newStatus = 'Closed';
        }
        return newStatus;
    };

    // Bid datatable Row and column defination
    const columnDefsBidTable = [
        {
            label: ' ',
            name: 'mc_certified',
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== 'Verified' && loggedInuserCompanyRole === 'Broker') {
                            return (
                                <div>
                                    <Tooltip title='Not Verified Company'>
                                        <IconButton>
                                            <NotVerifiedUserIcon className='notVerified' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        }
                    }
                }
            }
        },
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier Company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[15] === 'newoffer') && rowIndex.rowData[16] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[15] !== 'newoffer' && rowIndex.rowData[16] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[17]} </p>}
                        {rowIndex.rowData[15] !== 'newoffer' && rowIndex.rowData[16] === 'Accept' && <p className='afterBidDisplay'> $ {rowIndex.rowData[17]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        { label: 'bid_expiration_time', name: 'bid_expiration_time', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === '' || rowuserdata.rowData[10] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== '' || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[11] === '' || rowuserdata.rowData[11] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[11] !== '' || rowuserdata.rowData[11] !== undefined) && rowuserdata.rowData[11]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[12] === 'LXWXH' && <p className='afterBidDisplay'> NA
                        </p>}
                        {rowuserdata.rowData[12] !== 'LXWXH' && rowuserdata.rowData[12]}
                    </>
                    );
                }
            }
        },

        {
            label: 'Bid Expiration',
            name: 'BidExpiration',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[8];
                    var localTime = moment.utc(TimeStamp).local().format('YYYY-MM-DD HH:mm:ss');
                    let BidExpireTime = parseInt(rowIndex.rowData[9].replace(/\D/g, ''));
                    let time = '';
                    if (BidExpireTime === 5) {
                        time = '00:0' + BidExpireTime;
                    }
                    else {
                        time = '00:' + BidExpireTime;
                    }
                    let timeParts = time.split(':');
                    let timeInMiliseconds = ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(timeInMiliseconds)}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: 'Accept',
            name: 'Accept',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || loggedInuserRole !== 'Carrier') && selectedRowDataValue.posted_by_user === user.email,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        <Button variant='contained' className='buttonColor' id='buttonColorBookit' style={(disableBookit === false && (rowIndex.rowData[16] === 'Accept' || rowIndex.rowData[15] === 'newoffer')) ? { fontSize: 12, backgroundColor: '#0d2346' } : { fontSize: 12 }}
                            disabled={disableBookit}
                            onClick={() => acceptBidData(rowIndex)}> Book It</Button>
                    </>
                    );
                },
            },
        },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || user.email === process.env.REACT_APP_ADMIN_USER || (loggedInuserCompanyRole === 'Broker&Carrier' && selectedRowDataValue.posted_by_user === user.email)),
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[15];
                    let counterofferstatus = rowIndex.rowData[16];
                    let counteroffer = rowIndex.rowData[17];

                    return (<>
                        {disableBookit === false && counteroffersent === 'newoffer' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={user.email !== process.env.REACT_APP_ADMIN_USER ? { backgroundColor: '#0d2346', fontSize: 12 } : { fontSize: 12 }} disabled={disableCounterButton} onClick={() => handleOpen(rowIndex)}>  Counter Offer</Button>}
                        {disableBookit === true && counteroffersent === 'newoffer' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ fontSize: 12 }} disabled={disableCounterButton}>  Counter Offer</Button>}
                        {counteroffersent === 'carrier' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346' }} disabled={disableCounterButton} onClick={() => handleOpenCarrier(counteroffer, rowIndex)}> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {(counteroffersent === 'carrier' || counteroffersent === 'broker') && counterofferstatus === 'Accept' && <AcceptCounterOffer counteroffer={counteroffer} />}
                        {disableBookit === false && counteroffersent === 'broker' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346' }} disabled={disableCounterButton} onClick={() => handleOpenCarrier(counteroffer, rowIndex)}> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {disableBookit === true && counteroffersent === 'broker' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' disabled={disableCounterButton} > ${counteroffer} <Edit className='coediticon' disabled={disableEdit} /> </Button>}
                    </>
                    );
                },
            },
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[16];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && selectedRowDataValue.posted_by_user !== user.email)),
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[17];
                    let counterofferstatus = rowuserdata.rowData[16];

                    return (<>
                        {(rowuserdata.rowData[6] === 'accepted' || rowuserdata.rowData[6] === 'Closed') && <Button style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer
                        </Button>}
                        {(counteroffer === '' || counteroffer === undefined) && counterofferstatus === 'New' && <p className='afterBidDisplay'> NA
                        </p>}
                        {disableBookit === false && counteroffer !== '' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346' }} onClick={() => handleOpenCarrier(counteroffer, rowuserdata)}> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {disableBookit === true && counteroffer !== '' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor'> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && <AcceptCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                }
            },
        },
    ];

    const optionsBidTable = {
        filter: false,
        responsive: 'vertical',
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingActiveBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        },
    };

    // Bid datatable Row and column defination
    const columnDefsClosedBidTable = [
        {
            label: ' ',
            name: 'mc_certified',
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value !== 'Verified' && loggedInuserCompanyRole === 'Broker') {
                            return (
                                <div>
                                    <Tooltip title='Not Verified Company'>
                                        <IconButton>
                                            <NotVerifiedUserIcon className='notVerified' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        }
                    }
                }
            }
        },
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[15] === 'newoffer') && rowIndex.rowData[16] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[5]}</p>}
                        {rowIndex.rowData[15] !== 'newoffer' && rowIndex.rowData[16] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[17]} </p>}
                        {rowIndex.rowData[15] !== 'newoffer' && rowIndex.rowData[16] === 'Accept' && <p className='afterBidDisplay'> $ {rowIndex.rowData[17]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[6]);
                    return (
                        bidStatus
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        { label: 'bid_expiration_time', name: 'bid_expiration_time', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === '' || rowuserdata.rowData[10] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== '' || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[11] === '' || rowuserdata.rowData[11] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[11] !== '' || rowuserdata.rowData[11] !== undefined) && rowuserdata.rowData[11]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[12] === 'LXWXH' && <p className='afterBidDisplay'> NA
                        </p>}
                        {rowuserdata.rowData[12] !== 'LXWXH' && rowuserdata.rowData[12]}
                    </>
                    );
                }
            }
        },

        {
            label: 'Bid Expiration',
            name: 'BidExpiration',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.rowData[6] === 'Closed') {
                        return (
                            <Countdown autoStart={true} date={Date.now() + 0}>
                            </Countdown>
                        );
                    }
                },
            }
        },
        {
            label: 'Accept',
            name: 'Accept',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || loggedInuserRole !== 'Carrier') && selectedRowDataValue.posted_by_user === user.email,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (
                        <BookItButton />
                    );
                },
            },
        },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || user.email === process.env.REACT_APP_ADMIN_USER || (loggedInuserCompanyRole === 'Broker&Carrier' || selectedRowDataValue.posted_by_user === user.email)),
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[15];
                    let counterofferstatus = rowIndex.rowData[16];
                    let counteroffer = rowIndex.rowData[17];

                    return (<>
                        {(counteroffersent === 'newoffer') && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer
                        </Button>}
                        {(counteroffersent === 'carrier' || counteroffersent === 'broker') && counterofferstatus === 'New' && <p className='afterBidDisplay'> ${counteroffer} </p>}
                        {(counteroffersent === 'carrier' || counteroffersent === 'broker') && counterofferstatus === 'Accept' && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                },
            },
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[16];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && selectedRowDataValue.posted_by_user !== user.email)),
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[17];
                    let counterofferstatus = rowuserdata.rowData[16];

                    return (<>
                        {(counteroffer === undefined || counteroffer === '') && counterofferstatus === 'New' && <p className='afterBidDisplay'> NA
                        </p>}
                        {(counteroffer !== undefined && counteroffer !== '') && counterofferstatus === 'New' && <p className='afterBidDisplay'> ${counteroffer}
                        </p>}
                        {(counteroffer !== undefined || counteroffer !== '') && counterofferstatus === 'Accept' && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                }
            },
        },
    ];

    const optionsClosedBidTable = {
        filter: false,
        responsive: 'vertical',
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingClosedBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState('');
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState('');
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState('');
    const [chatFirstCompanyName, setchatFirstCompanyName] = useState('');
    const [chatAllMessages, setChatAllMessages] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);

    //Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    // const MapLoader = withScriptjs(Map);
    // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 
    //--------------------------------------------*********************--------------------------
    //---------------------------- Place to handle all the functions ----------------------------
    // Function that is triggered on page load

    //Function to scroll On recieveing Message
    const ScrollForParticularLoad = (recievedMessage) => {
        if (isPaneOpen === true) {
            if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                scrollToBottom();
            }
        }
    };

    // Adding values to the secondary pane
    const populateSecondaryPaneValues = (findResult) => {
        setMapUpdate(false);
        setTimeout(() => {
            setMapUpdate(true);
        }, 0);
        setMapDistance(findResult);
        setLoadNumberDetail(findResult.load_number);
        setLoadPostedByUser(findResult.posted_by_user);
        setLoadPostedBy(findResult.posted_by_company);
        setLoadLoadNotes(findResult.load_notes);
        setLoadMaxWeight(findResult.max_weight);
        setLoadPickUpdate(findResult.pickupfrom_date);
        setLoadDeliverByDate(findResult.pickupto_date);
        setLoadStatus(findResult.status);
        if (findResult.truck_type === '' || findResult.truck_type.length === 0) {
            setLoadLoadType('NA');
        }
        else {
            setLoadLoadType(findResult.truck_type.toString().replace(/,/g, ', '));
        }
        if (findResult.total_item_count === '') {
            setLoadNoOfPieces('NA');
        }
        else {
            setLoadNoOfPieces(findResult.total_item_count);
        }
        // if (findResult.expectedRate === "") {
        //     setLoadRate("NA");
        // }
        // else {
        //     setLoadRate("$" + findResult.expectedRate);
        // }
        let freightDimension = '';
        if (findResult.freight_dimension_length.toString().trim() === '' &&
            findResult.freight_dimension_width.toString().trim() === '' &&
            findResult.freight_dimension_height.toString().trim() === '') {
            setLoadFreightDimension('NA');
        }
        else {
            if (findResult.freight_dimension_length.toString().trim() === '') {
                freightDimension += 'NA X ';
            }
            else {
                freightDimension += findResult.freight_dimension_length.toString().trim() + 'L X ';
            }
            if (findResult.freight_dimension_width.toString().trim() === '') {
                freightDimension += 'NA X ';
            }
            else {
                freightDimension += findResult.freight_dimension_width.toString().trim() + 'W X ';
            }
            if (findResult.freight_dimension_height.toString().trim() === '') {
                freightDimension += 'NA';
            }
            else {
                freightDimension += findResult.freight_dimension_height.toString().trim() + 'H';
            }
            setLoadFreightDimension(freightDimension);
        }
        setLoadStackable(Boolean(findResult.stackable));
        setLoadHazmat(Boolean(findResult.hazmat));
        setLoadDockHigh(Boolean(findResult.dockhigh));
        setLoadTempControl(Boolean(findResult.tempcontrol));

        if (findResult.cities_distance) {
            setLoadCityDistance(findResult.cities_distance);
        }


        if (
            findResult.pickup_from_window === 'ASAP' &&
            findResult.pickup_to_window === 'ASAP'
        ) {
            setLoadPickUpWindow('ASAP');
        } else {
            setLoadPickUpWindow(
                findResult.pickup_from_window + ' - ' + findResult.pickup_to_window
            );
        }
        if (
            findResult.deliver_from_window.includes('Direct') &&
            findResult.deliver_to_window.includes('Direct')
        ) {
            setLoadDeliverWindow('Deliver Direct(Non-Stop)');
        } else {
            setLoadDeliverWindow(
                findResult.deliver_from_window + ' - ' + findResult.deliver_to_window
            );
        }
        if (loggedInuserCompanyRole !== "Broker" || loggedInuserCompanyRole === "Carrier") {
            if (findResult.bid_amount) {
                setLoadBidAmount(findResult.bid_amount);
                setLoadBidAmountDisable(true);
            }
            else {
                updateBidNotes('');
                updateTxtMilesOut('');
                setLoadBidAmount('');
                setCounterOffer('');
                setLoadBidAmountDisable(false);
                setTxtLength('');
                setTxtHeight('');
                setTxtWidth('');
                setLengthDisable(false);
                setWidthDisable(false);
                setHeightDisable(false);
                setLoadBidNotesDisable(false);
                setMilesOutDisable(false);

            }

            if (findResult.bid_notes) {
                updateBidNotes(findResult.bid_notes);
                setLoadBidNotesDisable(true);
            }
            else {
                if (findResult.bid_notes && findResult.bid_notes === null || findResult.bid_notes === '') {
                    if (findResult.bid_amount === '' || findResult.bid_amount === undefined) {
                        updateBidNotes('');
                        setLoadBidNotesDisable(false);
                    }
                    else {
                        setLoadBidNotesDisable(true);
                        updateBidNotes(findResult.bid_notes);
                    }
                }
            }
            if (findResult.miles_out) {
                updateTxtMilesOut(findResult.miles_out);
                setMilesOutDisable(true);
            }
            else {
                if (findResult.miles_out && findResult.miles_out === null || findResult.miles_out === '') {
                    if (findResult.bid_amount === undefined || findResult.bid_amount === '') {
                        updateTxtMilesOut('');
                        setMilesOutDisable(false);
                    }
                    else {
                        setMilesOutDisable(true);
                        updateTxtMilesOut('');
                    }
                }
            }
            if (findResult.truck_dimension && findResult.truck_dimension !== null && findResult.truck_dimension !== '{}') {
                if (findResult.truck_dimension !== 'LXWXH') {
                    setTxtLength(findResult.truck_dimension_length);
                    setTxtHeight(findResult.truck_dimension_height);
                    setTxtWidth(findResult.truck_dimension_width);
                    setLengthDisable(true);
                    setWidthDisable(true);
                    setHeightDisable(true);
                }
                else if (findResult.truck_dimension === 'LXWXH') {
                    setTxtLength('');
                    setTxtHeight('');
                    setTxtWidth('');
                    setLengthDisable(true);
                    setWidthDisable(true);
                    setHeightDisable(true);
                }
                else if (findResult.truck_dimension === null) {
                    setTxtLength('');
                    setTxtHeight('');
                    setTxtWidth('');
                    setLengthDisable(false);
                    setWidthDisable(false);
                    setHeightDisable(false);
                }

            }
            else if (findResult.truck_dimension === 'LXWXH') {
                setTxtLength('');
                setTxtHeight('');
                setTxtWidth('');
                setLengthDisable(true);
                setWidthDisable(true);
                setHeightDisable(true);
            }
            else if (!findResult.truck_dimension || findResult.truck_dimension === null || findResult.truck_dimension === '{}') {
                setTxtLength('');
                setTxtHeight('');
                setTxtWidth('');
                setLengthDisable(false);
                setWidthDisable(false);
                setHeightDisable(false);
            }
            // Handle bid expiration dropdown
            if (findResult.bid_expiration_time) {
                setBidExpiration(findResult.bid_expiration_time);
                setLoadBidAmountDisable(true);
            }
            else {
                setBidExpiration('15');
                setLoadBidAmountDisable(false);
            }
        }
        if (findResult.status) {
            setLoadStatus(findResult.status);
            if (findResult.status === 'Closed' || userReduxData.userData.company_role === "Broker") {
                setLoadBidAmountDisable(true);
            }
            else {
                if (!findResult.bid_amount && userReduxData.userData.company_role !== "Broker") {
                    setLoadBidAmountDisable(false);
                }
            }
        }
        if (findResult.time_stamp) {
            var localTime = moment.utc(findResult.time_stamp).local().format('YYYY-MM-DD HH:mm:ss');
            setloadPostedTime(localTime);
        }
        if (findResult.load_expiration_time) {
            setLoadUpdateLoadExpirationTimeForHour(findResult.load_expiration_time.split(':')[0]);
            setLoadUpdateLoadExpirationTimeForMin(findResult.load_expiration_time.split(':')[1]);
            setLoadExpiresIn(LoadExpirationInMiliseconds(findResult.load_expiration_time));
        }
        else {
            setLoadExpiresIn(0);
        }
        setLoadPickUpDestination(findResult.pickup_dest);
        setLoadDeliverDestination(findResult.deliverzip);
        setLoadDeliverDestination(findResult.delivery_dest);
        setMapDistance(findResult);

        if (findResult.watch_load) {
            if (findResult.watch_load.length !== 0 && findResult.watch_load.length !== undefined) {
                const findUserWatchLoad = findResult.watch_load.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserWatchLoad) {
                    setLoadWatchLoad(true);
                }
                else {
                    setLoadWatchLoad(false);
                }
            }
            else {
                setLoadWatchLoad(false);
            }
        }
        if (findResult.saved_lane) {
            if (findResult.saved_lane.length !== 0 && findResult.saved_lane.length !== undefined) {
                const findUserSavedLane = findResult.saved_lane.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserSavedLane) {
                    setLoadSavedLane(true);
                }
                else {
                    setLoadSavedLane(false);
                }
            }
            else {
                setLoadSavedLane(false);
            }
        }
    };

    //Function to execeute On Accepting Counter Offer
    const openPaneOnAcceptCounterOffer = async (findResult) => {
        populateSecondaryPaneValues(findResult);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = false;
            }
        }
        setColumnDefsLoadTable(FilterColumns);
        setMainGridPercent('35%');
        setIsPaneOpen(true);
        setDisplaySpecificColums(false);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        setSelectedRowDataValue(findResult);
        loadAndRealoadSecondGrid(findResult.load_id);
    };

    const onAcceptCheckLoadId = async (recievedMessage) => {
        try {
            let LoadboardLoads = await fetchLoadApiCall(user.email);
            if (LoadboardLoads) {
                LoadboardLoads.forEach(element => {
                    if (element.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                        openPaneOnAcceptCounterOffer(element);
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    //On Submitting Counter Offer
    const ifPaneClose = (recievedMessage) => {
        Swal.fire({
            text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been submitted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]} Click here 'Ok' to review counter offer or 'Cancel' to review later`,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok'
        }
        ).then((result) => {
            if (result.value === true) {
                if (isPaneOpen === true) {
                    if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                        loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1]);
                    }
                    else {
                        closeSecondaryPane();
                        window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                        fetchMainMuiDatatbleValues();
                    }
                }
                else {
                    window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                    fetchMainMuiDatatbleValues();
                }
            }
        });
    };

    const handleUpdatePostLoadTable = async (recievedMessage) => {
        try {
            let LoadboardLoads = await fetchLoadApiCall(user.email);
            if (LoadboardLoads) {
                LoadboardLoads.forEach(element => {
                    if (element.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                        selectedRowDataValueForSocketIO = element;
                        setSelectedRowDataValue(element);
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    //Function to scroll bottom
    const scrollToBottom = () => {
        animateScroll.scrollToBottom({});
    };

    //function to handle repeated code
    const panelOne = () => {
        setExpandColapseTopPannel(false);
        setTopTabPannelValue(1);
        setShowing(false);
    };

    const toHandlePanelZero = () => {
        setExpandColapseTopPannel(false);
        setTopTabPannelValue(0);
    };

    const toHandlePanelOne = () => {
        setExpandColapseTopPannel(false);
        setTopTabPannelValue(1);
    };

    const toExpandPanel = () => {
        setExpandColapseTopPannel(true);
        localStorage.setItem('panelValue', 'true');
        closeSecondaryPane();
    };

    const toClosePanel = () => {
        setExpandColapseTopPannel(false);
        localStorage.setItem('panelValue', 'false');
        closeSecondaryPane();
    };

    const tohandleCarrier = () => {
        isUserCarrier = true;
        setTopTabPannelValue(1);
    };

    const leaveEditFormOnRowClick = (selectedRowData, rowMeta) => {
        let leavingResponse = handleLeavingEditLoadForm();
        if (leavingResponse === true) {
            handleRowClick(selectedRowData, rowMeta);
        }
    };

    const leaveEditFormOnClosingPanel = () => {
        let leavingResponse = handleLeavingEditLoadForm();
        if (leavingResponse === true) {
            closeSecondaryPane();
        }
    };

    const toClearPostLoadForm = () => {
        setShowing(!showing);
        setRowDataForPostLoadForm([]);
        setPristine();
        setPostLoadFormFunctionality('Post Load');
    };

    const toOpenEditForm = () => {
        clearPostLoadForm();
        clearFilter();
        setLabel('Edit Load');
        setShowing(true);
        setTopTabPannelValue(0);
        setPostLoadFormFunctionality('Edit Load');
        setRowDataForPostLoadForm(selectedRowDataValue);
    };
    // Function to check the user role
    const checkUserRole = () => {
        if (userReduxData.userData.login_status === "ProfileAccepted") {
            if (userReduxData.userData.company_name) {
                setUserCompanyName(userReduxData.userData.company_name);
            }
            if (userReduxData.userData.company_role) {
                setLoggedInuserCompanyRole(userReduxData.userData.company_role)
            }
            if (userReduxData.userData.user_role) {
                setLoggedInuserRole(userReduxData.userData.user_role)
            }
            if (userReduxData.userData.company_mc_number) {
                setLoggedInCompanyMCNumber(userReduxData.userData.company_mc_number)
            }
            if (userReduxData.userData.user_role === "Broker"
                || userReduxData.userData.user_role === "Carrier"
                || userReduxData.userData.user_role === "CompanyAdmin"
                || userReduxData.userData.user_role === "Broker&Carrier") {
                if (window.localStorage.getItem('panelValue')) {
                    let panelValue = window.localStorage.getItem('panelValue');
                    if (panelValue === 'true') {
                        setExpandColapseTopPannel(true);
                    }
                    if (panelValue === 'false') {
                        if ((loggedInuserCompanyRole === 'Carrier') || (loggedInuserCompanyRole === 'Broker&Carrier' && userReduxData.userData.user_role === 'Carrier')) {
                            toHandlePanelOne();
                            isUserCarrier = true;
                        }
                        if ((userReduxData.userData.user_role !== 'Carrier' && loggedInuserCompanyRole === 'Broker&Carrier') || (loggedInuserCompanyRole === 'Broker')) {
                            toHandlePanelZero();
                        }
                    }
                }
                else {
                    if ((userReduxData.userData.user_role !== 'Carrier' && loggedInuserCompanyRole === 'Broker&Carrier') || (loggedInuserCompanyRole === 'Broker')) {
                        toHandlePanelZero();
                    }
                    if ((loggedInuserCompanyRole === 'Carrier') || (loggedInuserCompanyRole === 'Broker&Carrier' && userReduxData.userData.user_role === 'Carrier')) {
                        toHandlePanelOne();
                        isUserCarrier = true;
                    }
                }
            }
            if (userReduxData.userData.user_role === 'Driver') {
                history.push({ pathname: '/forBidden' });
            }
        }
        else if (user.email === process.env.REACT_APP_ADMIN_USER) {
            toHandlePanelOne();
        }
        else {
            history.push({ pathname: '/forBidden' });
        }
    };

    // function to get confirmation for row click
    const handleRowClickConfirmation = (selectedRowData, rowMeta) => {
        if (postLoadFormFunctionality === 'Edit Load') {
            let getResponse = PostLoadFormRef.current.handleSendingResponse();
            if (getResponse) {
                if (getResponse[0].isValueChanged === true) {
                    leaveEditFormOnRowClick(selectedRowData, rowMeta);
                }
            }
        }
        else if (isUpdateLoadExpirationValueChanged === true) {
            leaveEditFormOnRowClick(selectedRowData, rowMeta);
        }
        else if (loadBidAmount !== '' && loadBidAmountDisable === false) {
            leaveEditFormOnRowClick(selectedRowData, rowMeta);
        }
        else {
            handleRowClick(selectedRowData, rowMeta);
        }
    };

    //function to assign values on row click in userdetails table
    const handleRowClick = (selectedRowData, rowMeta) => {
        scrollTopOnRowClick(expandColapseTopPannel)
        setDisableBookit(false);
        setEnableSetButton(true);
        setDisableCounterButton(false);
        setDisadbleEdit(false);
        setIsPaneOpen(true);
        handleReadTabNotification(selectedRowData);
        if (loadBidAmountDisable === true || isUpdateLoadExpirationValueChanged === true) {
            setPristine();
        }
        setLoadUpdatedStatus(false);
        getBidData(selectedRowData, rowMeta);
        setSelectedRow(selectedRowData);
        setRowDataForPostLoadForm([]);
        setPristine();
        setPostLoadFormFunctionality('Post Load');
    };

    const handleReadTabNotification = async (selectedRowData) => {
        try {
            const tabNotificationResponse = await getTabNotificationApiCall(selectedRowData[0]);
            if (tabNotificationResponse) {
                let newList = [];
                tabNotificationResponse.forEach(element => {
                    let userCount = 0;
                    if (element.read_users_list) {
                        for (let i = 0; i < element.read_users_list.length; i++) {
                            if (i === user.email) {
                                userCount = userCount + 1;
                            }
                        }
                        if (userCount === 0 && element.read_users_list.length === undefined) {
                            element.read_users_list = [{ name: user.email }];
                        }
                        else {
                            element.read_users_list.push({ name: user.email });
                        }
                    }
                    newList.push(element);
                });
                try {
                    await editNotificationApiCall(newList, 'read');
                } catch (e) {
                    console.log(e);
                }
            }
        }
        catch (error) {
            console.log('error'.error);
        }
    };

    const handleRedirectLoadFromEmailTemplate = async (rowData) => {
        if (location.state) {
            if (location.state.loadId && window.localStorage.getItem('loadboardLoadId')) {
                // OPen secondary pane
                const findLoad = rowData.find(
                    ({ load_id }) => load_id === location.state.loadId
                );
                if (findLoad) {
                    removeBackroundColour();
                    populateSecondaryPaneValues(findLoad);
                    setDisplaySpecificColums(false);
                    const FilterColumns = [...columnDefsLoadTable];
                    for (let i = 1; i < FilterColumns.length; i++) {
                        if (FilterColumns[i].options.display !== undefined) {
                            FilterColumns[i].options.display = false;
                        }
                    }
                    setColumnDefsLoadTable(FilterColumns);
                    setMainGridPercent('35%');
                    setIsPaneOpen(true);
                    isPaneOpenForSocketIO = true;
                    selectedRowDataValueForSocketIO = findLoad;
                    fetchAllComments(findLoad);
                    setSelectedRowDataValue(findLoad);
                    loadAndRealoadSecondGrid(location.state.loadId);
                    window.localStorage.removeItem('loadboardLoadId');
                }
            }
            window.localStorage.removeItem('loadboardLoadId');
        }
    };

    // Function to fetch all the data main MuiDatatble
    const fetchMainMuiDatatbleValues = async () => {
        try {
            let LoadboardLoads = await fetchLoadApiCall(user.email);
            if (LoadboardLoads) {
                LoadboardLoads.sort((a, b) =>
                    b.time_stamp.localeCompare(a.time_stamp)
                );
                if (LoadboardLoads.length !== 0) {
                    let loadIds = [];
                    setLoading(true);
                    LoadboardLoads.forEach(element => {
                        if (element.load_number) {
                            loadIds.push(element.load_number);
                        }
                    });
                    setLoadNumberLength(Math.max(...loadIds).toString().length);
                }
                else {
                    setLoadNumberLength(8);
                    setLoading(true);
                }
                let arrayToRemove = LoadboardLoads.filter(function (singleVal) {
                    return singleVal['load_delete_status'] !== true && singleVal['load_expired_status'] !== true && singleVal['status'] !== 'Closed' && singleVal['status'] !== 'Awarded ' && singleVal['status'] !== 'Assigned to Driver' && singleVal['status'] !== 'assigned' && singleVal['status'] !== 'queued';
                });

                const tempData = arrayToRemove.map(item => {
                    // item.abovemarketrate = item.abovemarketrate ? 'Yes' : ''
                    return item;
                });
                setRowData(tempData);
                allPostedLoades = tempData;
                await handleRedirectLoadFromEmailTemplate(tempData);
                return tempData;
            }
        }
        catch (error) {
            console.log('fetch load error', error);
        }
    };

    // Function to handle remove removing expired loads
    const RemoveExpiredLoads = async () => {
        // const allNewBids = await ExpireAndFetchAllBids();
        allPostedLoades.forEach(async (element) => {
            if (element.load_expiration_time) {
                const localVarExpirationTime = element.load_expiration_time.split(':');
                let hrtomin = (Number(localVarExpirationTime[0]) * 60 + Number(localVarExpirationTime[1]));
                let futureTime = '';
                if (hrtomin === 0) {
                    let stillUtc = moment.utc(element.time_stamp).toDate();
                    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                    futureTime = moment(local).add(hrtomin, 'minutes');
                }
                else if (hrtomin > 0) {
                    let stillUtc = moment.utc(element.time_stamp).toDate();
                    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                    futureTime = moment(local).add(hrtomin, 'minutes');
                }
                let diference = futureTime.diff(moment(), 'minutes');
                if (diference <= 0) {
                    // RemoveBidsWhenLoadExpires(allNewBids, element.load_id);
                    let singleElement = JSON.parse(JSON.stringify(element));
                    let listToUpdateExpiration = { ...singleElement, 'load_expired_status': true };
                    listToUpdateExpiration.watch_load = [];
                    listToUpdateExpiration.read_users = [];
                    listToUpdateExpiration.saved_lane = [];
                    try {
                        const editLoadResponse = await editLoadDetailApiCall(listToUpdateExpiration);
                        if (editLoadResponse) {
                            fetchMainMuiDatatbleValues();
                            handleDeletingExpiredLoadNotification(listToUpdateExpiration.load_id, 'Load Expiration');
                            handleGettingConfirmationForClosingSecondaryPane();
                            if (user.email === element.posted_by_user) {
                                let carriers = await handlefetchCarriersForLoad(element.load_id)
                                sendMessageForSocketIO('BrokerLoadHasBeenExpired||load_id:' + element.load_id, carriers, true, editLoadResponse);
                                sendMessageForSocketIO('newNotification', '', false);
                            }
                            reduceCountOnLoadExpire(element, user.email);
                        }
                    }
                    catch (error) { }
                }
            }
        });
    };

    const RemoveBidsWhenLoadExpires = async (allNewBids, loadId) => {
        if (allNewBids.length !== 0) {
            allNewBids.forEach(element => {
                if (element.load_id === loadId) {
                    if (element.status !== 'accepted' && element.status !== 'Closed') {
                        DeleteExpiredBids(element, 'loadExpired');
                    }
                }
            });
        }
    };

    // Function to handle get all bids
    const ExpireAndFetchAllBids = async () => {
        try {
            const getBidResponse = await getBidApiCall(user.email, "allbids");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                ExpireBidsAfterTimeOut(getBidResponse);
                return getBidResponse;
            }
            else {
                return [];
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to check expiration difference
    const CheckBidExpirationDifference = (expirationTime, bidTimeStamp) => {
        const localVarExpirationTime = parseInt(expirationTime.replace(/\D/g, ''));
        var stillUtc = moment.utc(bidTimeStamp).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        let futureTime = moment(local).add(localVarExpirationTime, 'minutes');
        let diference = futureTime.diff(moment(), 'minutes');
        return diference;
    };

    const DeleteExpiredBids = async (bidData, functionality) => {
        let listValues = bidData;
        listValues.status = 'Closed';
        try {
            const deleteBidResponse = await deleteBidApiCall(listValues);
            if (deleteBidResponse) {
                let fetchAllCommentResponse = await fetchAllComment();
                const findResult = fetchAllCommentResponse.find(
                    ({ load_id }) => load_id === bidData.load_id
                );
                loadAndRealoadSecondGrid(bidData.load_id);
                if (bidData.posted_by_user === user.email) {
                    onSendMessageClick('Bid Closed', 'System Message', findResult);
                }
                fetchAllComments(bidData);
                sendMessageForSocketIO('YourBidHasExpired||load_id:' + bidData.load_id + '||posted_by_user:' + bidData.posted_by_user, bidData.carrier_name, true, selectedRowDataValue, bidData);
                sendMessageForSocketIO('newNotification', '', false);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    // Function to handle bid expiration
    const ExpireBidsAfterTimeOut = (allBidInformation) => {
        allBidInformation.forEach(sindleBids => {
            if ((sindleBids.status !== 'accepted') && (sindleBids.status !== 'Closed')) {
                if (sindleBids.bid_expiration_time) {
                    if (CheckBidExpirationDifference(sindleBids.bid_expiration_time, sindleBids.bid_time_stamp) <= 0) {
                        DeleteExpiredBids(sindleBids, 'bidExpired');
                    }
                }
            }
        });
    };

    // Function to handle delete unwanted notifications
    const handleDeletingExpiredLoadNotification = async (loadId, functionality) => {
        let listValues =
        {
            'load_id': loadId
        };
        try {
            await deleteNotificationApiCall(listValues, functionality);
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    // function to handle topTab Pannel repeated code
    const showPostLoadForm = () => {
        setLabel('Post Load');
        SearchFormRef.current.isSearchFieldValueChanged;
        toHandlePanelZero();
        setShowing(false);
        isUserCarrier = false;
        setRowDataForPostLoadForm([]);
        clearFilter();
        setTopTabPannelValue(0);
        if (isPaneOpen === true) {
            closeSecondaryPane();
        }
    }

    // Function to handle top tabpannel change
    const handleTopTabPannelChange = (event, newValue) => {
        if (newValue === 0 && label !== 'Edit Load') {
            if (SearchFormRef.current.isSearchFieldValueChanged === true) {
                if (label === 'Post Load') {
                    let leavingResponse = handleLeavingSearchLoadForm();
                    if (leavingResponse === true) {
                        showPostLoadForm();
                    }
                    else {
                        tohandleCarrier();
                    }
                }
            }
            else {
                if (loggedInuserCompanyRole === 'Broker') {
                    showPostLoadForm();
                }
            }
        }
        else if (newValue === 1 && label !== 'Edit Load') {
            if (PostLoadFormRef.current.isValueChanged !== true) {
                searchButtonClicked();
            }
        }
        else if (newValue === 1 && label === 'Edit Load') {
            if (PostLoadFormRef.current.isValueChanged === true) {
                let leavingResponse = handleLeavingEditLoadForm();
                if (leavingResponse === true) {
                    tohandleCarrier();
                    toClosePanel();
                }
            }
        }
    };

    // Function to handle expand more icon click
    const handleExpandMoreIconClick = () => {
        toClosePanel();
        if ((loggedInuserCompanyRole === 'Carrier') || (loggedInuserCompanyRole === 'Broker&Carrier' && loggedInuserRole === 'Carrier')) {
            searchButtonClicked();
        }
    };

    // Function to handle expand less icon click
    const handleExpandLessIconClick = () => {
        if (postLoadFormFunctionality === 'Edit Load') {
            let getResponse = PostLoadFormRef.current.handleSendingResponse();
            if (getResponse) {
                if (getResponse[0].isValueChanged === true) {
                    let leavingResponse = handleLeavingEditLoadForm();
                    if (leavingResponse === true) {
                        toExpandPanel();
                    }
                }
            }
        }
        else {
            toExpandPanel();
        }
    };

    //$$$$$$$$$$$$$$$$$$$$$$$$$$ Functions to handle top box $$$$$$$$$$$$$$$$$$$$$$$$$$$$
    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    const handleLeavingEditLoadForm = () => {
        if (window.confirm('Changes you made may not be saved')) {
            setLoadUpdatedStatus(true);
            return true;
        }
        else {
            if (postLoadFormFunctionality !== 'Post Load') {
                setShowing(true);
                setLoadUpdatedStatus(false);
                return false;
            }
        }
    };

    const handleLeavingSearchLoadForm = () => {
        if (label === 'Post Load') {
            if (window.confirm('Search you made will be cleared')) {
                setLoadUpdatedStatus(true);
                return true;
            }
        }
    };

    // ----------------------------------- Functions to handle all Muidatatble functionality -------------------------------------------
    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowData.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute('style');
                if (styleObject !== null) {
                    if (styleObject.toString().includes('9FD2F3')) {
                        domObject.removeAttribute('style', 'background: #9FD2F3');
                    }
                }
            }

        }
    };

    // Function to handle txtlength
    const handletxtLength = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setTxtLength(event.target.value);
            }
        }
    };
    const handletxtWidth = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setTxtWidth(event.target.value);
            }
        }
    };
    const handletxtHeight = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setTxtHeight(event.target.value);
            }
        }
    };

    // Function to handle Muidatatable Page change
    const HandlePageChange = () => {
        handleGettingConfirmationForClosingSecondaryPane();
    };

    const setMuiDatatableColour = (allRowsData) => {
        if (allRowsData.data.length > 0) {
            allRowsData.data.forEach((data) => {
                if (data.data[data.data.length - 3] === 'Yes') {
                    // const vaal = document.getElementById(`MUIDataTableBodyRow-${data.index}`);
                    // if (vaal !== null) {
                    //     vaal.setAttribute('style', 'background: #81ff9e');
                    // }
                }
            });
        }
    };
    //----------------------------------------------------Favorite And Save-----------------------------------------------------------

    //Function Used Repeatedly If Socket Open
    const executeIfSocketOpen = async (data) => {
        if (isPaneOpenForSocketIO) {
            try {
                let LoadboardLoads = await fetchLoadApiCall(user.email);
                if (LoadboardLoads) {
                    const findResult = LoadboardLoads.find(
                        ({ load_id }) => load_id === selectedRowDataValue.load_id
                    );
                    populateSecondaryPaneValues(findResult);
                    selectedRowDataValueForSocketIO = findResult;
                    setSelectedRowDataValue(findResult);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    const cleanListForUpdatingWatchLoad = (list) => {
        if (list.bid_amount)
            delete list.bid_amount;
        if (list.carrier_name)
            delete list.carrier_name;
        if (list.bid_id)
            delete list.bid_id;
        if (list.bid_expiration_time)
            delete list.bid_expiration_time;
        if (list.bid_time_stamp)
            delete list.bid_time_stamp;
        if (list.bid_notes)
            delete list.bid_notes;
        if (list.miles_Out)
            delete list.miles_Out;
        if (list.truck_dimension)
            delete list.truck_dimension;
        list.status = 'new';
        list.load_expired_status = false;
        list.load_delete_status = false;
        return list;
    };
    /*****************************************Save Lane In Load Detail*******************************************************************/

    //Function to handle saved Lane Color
    const changeLaneColor = async (list) => {
        if (list.saved_lane === null || list.saved_lane.length === 0 || list.saved_lane.length === undefined) {
            list.saved_lane = [{ name: user.email, accStatus: true }]
        }
        else {
            const findUserSavedLane = list.saved_lane.find(({ name }) => name === user.email);
            if (!findUserSavedLane) {
                list.saved_lane.push({ name: user.email, accStatus: true })
            }
            else {
                findUserSavedLane.accStatus = true;
            }
        }
        populateSecondaryPaneValues(list);
        try {
            let response = await editLoadDetailApiCall(list)
            if (response !== undefined) {
                let data = JSON.parse(response.data.body)
                sendMessageForSocketIO('LoadAddedToSavedLane||load_id:' + data.load_id, "", false);
                populateSecondaryPaneValues(data);
                selectedRowDataValueForSocketIO = data;
                setSelectedRowDataValue(data);
                setSnackBarMessageText(`Load Details Added To Saved Lane`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
            }
        }
        catch (error) {
            console.log(error, 'Failed to update lane details')
        }
    }

    //Function to handle saved Lane click
    const handleSavedLaneClick = async () => {
        changeLaneColor(selectedRowDataValue)
        let listValue =
        {
            'pick_upfrom_date': moment(selectedRowDataValue.pickupfrom_date).format('MM/DD/YYYY'),
            'pickup_dest': selectedRowDataValue.pickup_dest,
            'delivery_dest': selectedRowDataValue.delivery_dest,
            'pickupto_date': moment(selectedRowDataValue.pickupto_date).format('MM/DD/YYYY'),
            'carrier_name': user.email,
            'max_weight': selectedRowDataValue.max_weight,
            'total_item_count': selectedRowDataValue.total_item_count,
            'truck_type': selectedRowDataValue.truck_type,
            'miles_from': 'NA',
            'miles_to': 'NA',
            'delete_lane': false,
            'matched_lane': '0',
            'load_id': selectedRowDataValue.load_id,
            'time_stamp': selectedRowDataValue.time_stamp,
            'carrier_company': userCompanyName,
            'company_mc_number': loggedInCompanyMCNumber
        };
        try {
            const saveLaneResponse = await saveLaneApiCall(listValue);
            if (saveLaneResponse !== undefined) {
                setSnackBarMessageText(`Load details added to saved lane`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
            }
        }
        catch (error) {
            console.log(error, 'failed to save a lane')
        }
    }

    const toRemoveLaneDetails = async (listValues) => {
        try {
            let response = await updateCount(listValues);
            if (response) {
                const username = user.email;
                const findResult = rowData.find(
                    ({ load_id }) => load_id === selectedRowDataValue.load_id
                );
                let newList = findResult;
                const findUserSavedLane = newList.saved_lane.find(({ name }) => name === username);
                if (findUserSavedLane) {
                    findUserSavedLane.accStatus = false;
                }
                let editLoadResponse = await editLoadDetailApiCall(newList);
                if (editLoadResponse) {
                    executeIfSocketOpen(editLoadResponse);
                    setSnackBarMessageText('Saved Lane is removed');
                    setSnackBarSeverity('success');
                    setOpenSnackBar(true);
                    sendMessageForSocketIO('LoadRemovedFromSavedLane||load_id:' + editLoadResponse.load_id, '', false);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleRemoveSavedLaneClick = async () => {
        let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
        if (matchLaneResponse) {
            let savedLaneList = matchLaneResponse.filter(function (singleVal) {
                return singleVal['carrier_name'] === user.email && singleVal['delete_lane'] === false &&
                    (singleVal['pickup_dest'] === selectedRowDataValue.pickup_dest ||
                        singleVal['delivery_dest'] === selectedRowDataValue.delivery_dest);
            });
            savedLaneList.forEach(laneDetails => {
                if (laneDetails.load_id === selectedRowDataValue.load_id) {
                    let listValues =
                    {
                        'pick_upfrom_date': laneDetails.pick_upfrom_date,
                        'pickup_dest': laneDetails.pickup_dest,
                        'delivery_dest': laneDetails.delivery_dest,
                        'pickupto_date': laneDetails.pickupto_date,
                        'carrier_name': user.email,
                        'max_weight': laneDetails.max_weight,
                        'total_item_count': laneDetails.total_item_count,
                        'truck_type': laneDetails.truck_type,
                        'miles_from': laneDetails.miles_from,
                        'miles_to': laneDetails.miles_to,
                        'lane_id': laneDetails.lane_id,
                        'delete_lane': true,
                        'matched_lane': '0',
                        'load_id': laneDetails.load_id,
                        'lane_number': laneDetails.lane_number,
                        'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        'carrier_company': laneDetails.carrier_company
                    };
                    toRemoveLaneDetails(listValues);
                }
            });
        }
    };

    //Function to handle watch load click
    const handleWatchLoadClick = async () => {
        const username = user.email;
        const findResult = rowData.find(
            ({ load_id }) => load_id === selectedRowDataValue.load_id
        );
        let listValue = JSON.parse(JSON.stringify(findResult));
        if (listValue.watch_load.length === 0) {
            listValue.watch_load = [{ name: username, accStatus: true }];
        }
        else {
            const findUserWatchLoad = listValue.watch_load.find(({ name }) => name === username);
            if (!findUserWatchLoad) {
                listValue.watch_load.push({ name: username, accStatus: true });
            }
            else {
                findUserWatchLoad.accStatus = true;
            }
        }
        let editLoadResponse = await editLoadDetailApiCall(listValue);
        if (editLoadResponse) {
            fetchMainMuiDatatbleValues();
            setSnackBarMessageText('Load succesfully added to watch list');
            setSnackBarSeverity('success');
            setOpenSnackBar(true);
            sendMessageForSocketIO('BrokerLoadHasBeenWatchList||load_id:' + editLoadResponse.load_id, '', false);
            populateSecondaryPaneValues(editLoadResponse);
            executeIfSocketOpen(editLoadResponse);
        }
    };

    // Function to handle stop watching click 
    const handleStopWatchLoadClick = async () => {
        const username = user.email;
        const findResult = rowData.find(
            ({ load_id }) => load_id === selectedRowDataValue.load_id
        );
        let listValue = JSON.parse(JSON.stringify(findResult));
        const findUserWatchLoad = listValue.watch_load.find(({ name }) => name === username);
        if (findUserWatchLoad) {
            findUserWatchLoad.accStatus = false;
        }
        let editLoadResponse = await editLoadDetailApiCall(listValue);
        if (editLoadResponse) {
            fetchMainMuiDatatbleValues();
            setSnackBarMessageText('Load removed from watch list');
            setSnackBarSeverity('success');
            setOpenSnackBar(true);
            sendMessageForSocketIO('BrokerLoadHasBeenWatchListRemoved||load_id:' + editLoadResponse.load_id, '', false);
            populateSecondaryPaneValues(editLoadResponse);
            executeIfSocketOpen(editLoadResponse);
        }
    };

    const handleGettingConfirmationForClosingSecondaryPane = () => {
        if (showing) {
            if (postLoadFormFunctionality === 'Edit Load') {
                let getResponse = PostLoadFormRef.current.handleSendingResponse();
                if (getResponse) {
                    if (getResponse[0].isValueChanged === true || isUpdateLoadExpirationValueChanged === true) {
                        let leavingResponse = handleLeavingEditLoadForm();
                        if (leavingResponse === true) {
                            closeSecondaryPane();
                        }
                        else {
                            if (leavingResponse === undefined) {
                                setShowing(showing);
                            }
                        }
                    }
                    else {
                        if (getResponse[0].isValueChanged === false) {
                            setLoadUpdatedStatus(true);
                            toClearPostLoadForm();
                            closeSecondaryPane();
                        }
                    }

                }
                else {
                    if (getResponse === undefined) {
                        if (!loadUpdatedStatus) {
                            toClearPostLoadForm();
                            leaveEditFormOnClosingPanel();
                        }
                    }
                }
            }
        }
        else if (isUpdateLoadExpirationValueChanged === true) {
            leaveEditFormOnClosingPanel();
        }
        else {
            closeSecondaryPane();
        }
    };

    // Function to handle pane close
    const closeSecondaryPane = async () => {
        if (topTabPannelValue !== 1) {
            setLabel('Post Load');
            window.localStorage.removeItem('LoadId');
            setPristine();
            setPostLoadFormFunctionality('Post Load');
            setIsUpdateLoadExpirationValueChanged(false);
        }
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        isPaneOpenForSocketIO = false;
        selectedRowDataValueForSocketIO = [];
        setDisplaySpecificColums(true);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = true;
            }
        }
        setColumnDefsLoadTable(FilterColumns);
        removeBackroundColour();
        fetchMainMuiDatatbleValues();
        let fetchAllCommentResponse = await fetchAllComments(selectedRowDataValue);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowDataValue.load_id
                );
                updateChatReadStatus(findChatResult);
            }
        }
        setLoadBidAmount('');
        setTxtHeight('');
        setTxtWidth('');
        setTxtLength('');
        updateBidNotes('');
        updateTxtMilesOut('');
        if (topTabPannelValue === 0) {
            setRowDataForPostLoadForm([]);
        }
        if (topTabPannelValue === 1) {
        }
        setShowing(false);
    };
    const getBidData = async (selectedRowData, selectedRowState) => {
        localStorage.setItem('selectedLoadId', selectedRowData[0]);
        removeBackroundColour();
        const findResult = rowData.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        if (!window.localStorage.getItem('LoadId')) {
            // let row = document.getElementById(`MUIDataTableBodyRow-${selectedRowState.dataIndex}`);
            // console.log("row", row)
            // row.setAttribute('style', 'background: #9FD2F3');
        }
        populateSecondaryPaneValues(findResult);
        setDisplaySpecificColums(false);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = false;
            }
        }
        setColumnDefsLoadTable(FilterColumns);
        setMainGridPercent('35%');
        setIsPaneOpen(true);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        setSelectedRowDataValue(findResult);
        loadAndRealoadSecondGrid(selectedRowData[0]);
        let fetchAllCommentResponse = await fetchAllComments(findResult);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowData[0]
                );
                updateChatReadStatus(findChatResult);
            }
        }
        handleLoadReadStatus(findResult);
        return findResult;
    };

    // Function to calculate miliseconds for load expiration
    const LoadExpirationInMiliseconds = (expirationTime) => {
        let timeWithoutMinOrHr = expirationTime.split(':');
        let timeInMiliseconds = ((timeWithoutMinOrHr[0] * (60000 * 60)) + (timeWithoutMinOrHr[1] * 60000));
        return timeInMiliseconds;
    };

    const handleLoadReadStatus = async (selectedRowData) => {
        let listValues = JSON.parse(JSON.stringify(selectedRowData));
        if (listValues.read_users) {
            const findUsers = listValues.read_users.find(
                ({ name }) => name === user.email
            );
            if (findUsers === undefined) {
                listValues.read_users.push({ name: user.email });
                try {
                    const editLoadResponse = await editLoadDetailApiCall(cleanListForUpdatingWatchLoad(listValues));
                    if (editLoadResponse) {
                        sendMessageForSocketIO('LoadHasBeenRead||load_id:' + editLoadResponse.load_id, '', false);
                    }
                }
                catch (error) { }
            }
        }
    };

    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    // function to accept bid
    const acceptBidData = async (acceptedRow) => {
        if (acceptedRow.rowData[16] === 'Accept' || acceptedRow.rowData[15] === 'newoffer') {
            setDisableBookit(true);
            setDisableCounterButton(true);
            setDisadbleEdit(true);
            const findResult = rowData.find(
                ({ load_id }) => load_id === selectedRowDataValue.load_id
            );

            let listValue = findResult;
            listValue = {
                ...listValue, 'posted_by_company': acceptedRow.rowData[1],
                'bid_amount': acceptedRow.rowData[5],
                'load_id': acceptedRow.rowData[2],
                'broker_id': user.email,
                'carrier_id': acceptedRow.rowData[7],
                'load_dimension': loadFreightDimension,
                'equipment_needed': loadLoadType,
                'bid_notes': acceptedRow.rowData[10],
                'miles_out': acceptedRow.rowData[11],
                'load_notes': loadLoadNotes,
                'carrier_company': acceptedRow.rowData[4],
                'truck_dimension': acceptedRow.rowData[12],
                'broker_loadUrl': window.location.origin + '/awardedloads?loadId=' + slugid.encode(acceptedRow.rowData[2]),
                'carrier_loadUrl': window.location.origin + '/bidswon?loadId=' + slugid.encode(acceptedRow.rowData[2])
            };
            if (selectedRowDataValue.posted_by_user === user.email) {
                if (selectedRowDataValue.load_id === acceptedRow.rowData[2]) {
                    if (selectedRowDataValue.status === 'Closed') {
                        Swal.fire({
                            text: 'A bid is already accepted',
                            type: null,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            confirmButtonColor: '#0066cc'
                        });
                    }
                    else {
                        //Nested axios
                        let data = {
                            load_id: acceptedRow.rowData[2],
                            bid_id: acceptedRow.rowData[3],
                            carrier_name: acceptedRow.rowData[7],
                            status: 'accepted',
                            carrier_company: acceptedRow.rowData[4],
                            driver_name: selectedRowDataValue.driver_name,
                            driver_status: selectedRowDataValue.driver_status
                        };
                        // setSnackBarMessageText('Bid Accepting In-progress');
                        // setSnackBarSeverity('info');
                        // setOpenSnackBar(true);
                        try {
                            const acceptBidResponse = await acceptBidApiCall(data);
                            if (acceptBidResponse) {
                                onSendMessageClick(`Bid of amount $${acceptedRow.rowData[5]} accepted `, 'System Message');
                                handleDeletingExpiredLoadNotification(acceptedRow.rowData[2], 'Load Accepted');
                                //setOpenSnackBar(false);
                                setSnackBarMessageText('Bid accepted');
                                setSnackBarSeverity('success');
                                setOpenSnackBar(true);
                                fetchMainMuiDatatbleValues();
                                if (loggedInuserCompanyRole === 'Carrier' || loggedInuserCompanyRole === 'Broker&Carrier') {
                                    sendMessageForSocketIO('YourLoadHasBennAccepted||load_id:' + acceptedRow.rowData[2] + '||posted_by_user:' + selectedRowDataValue.posted_by_user, acceptedRow.rowData[7], true, selectedRowDataValue, acceptedRow);
                                    sendMessageForSocketIO('newNotification', '', false);
                                }
                                else if (loggedInuserCompanyRole === 'Broker' || loggedInuserCompanyRole === 'Broker&Carrier') {
                                    sendMessageForSocketIO('YourLoadHasBennAccepted||load_id:' + acceptedRow.rowData[2] + '||posted_by_user:' + selectedRowDataValue.posted_by_user, acceptedRow.rowData[7], true, selectedRowDataValue, acceptedRow);
                                    sendMessageForSocketIO('newNotification', '', false);
                                }
                                return await acceptBidNotificationApiCall(listValue);
                            }
                        }
                        catch (error) { }
                    }
                }
            }
            else {
                Swal.fire({
                    text: 'Not authorized to accept the bid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    };

    // Function to load and reload bid details
    const loadAndRealoadSecondGrid = async (loadId) => {
        let findBids = [];
        let closedBids = [];
        try {
            const getBidResponse = await getBidApiCall(loadId, "loadspecific");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                getBidResponse.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                if (getBidResponse.length !== 0) {
                    setLoadingActiveBids(true);
                    setLoadingClosedBids(true);
                }
                else {
                    setLoadingActiveBids(false);
                    setLoadingClosedBids(false);
                }
                getBidResponse.forEach(element => {
                    if (element.load_id === loadId && (element.carrier_name === user.email || element.posted_by_user === user.email || user.email === process.env.REACT_APP_ADMIN_USER)) {
                        if (element.status === 'new') {
                            findBids.push(element);
                            setLoadBidAmount(element.bid_amount);
                        }
                        else {
                            closedBids.push(element);
                        }
                    }
                });
                if (findBids.length === 0) {
                    setLoadingActiveBids(true);
                }
                if (closedBids.length === 0) {
                    setLoadingClosedBids(true);
                }
                setRowDataActiveBidTable(findBids);
                setRowDataClosedBidTable(closedBids);
            }
            else {
                setRowDataActiveBidTable([]);
                setRowDataClosedBidTable([]);
                setLoadingClosedBids(true);
                setLoadingActiveBids(true);
            }
        }
        catch (error) {
            console.log('fetch bid error', error);
            setRowDataActiveBidTable([]);
            setRowDataClosedBidTable([]);
        }
        return findBids.concat(closedBids);
    };

    const handlefetchCarriersForLoad = async (loadId) => {
        let carriers = [];
        try {
            const getBidResponse = await getBidApiCall(loadId, "loadspecific");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                getBidResponse.forEach(element => {
                    if (element.load_id === loadId && (element.carrier_name === user.email || element.posted_by_user === user.email || user.email === process.env.REACT_APP_ADMIN_USER)) {
                        carriers.push(element.carrier_name)
                    }
                });
            }
        }
        catch (error) {
            console.log('fetch bid error', error);
        }
        return carriers;
    }


    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };


    // ^^^^^^^^^^^^^^^^^^^^^^^Function to handle All the chat^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    const getSingleCarrierMessage = async (event) => {
        if (event.target.id !== null && event.target.id !== '') {
            tosendOffer(event.target.id);
        }
        else {
            tosendOffer(event.target.parentElement.id);
        }
    };

    // function to handle send message for enter button click
    const onEnterButtonForChat = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSendMessageClick(document.getElementById('inputMesage').value, 'Normal Message');
        }
    };

    // function to handle send message click button
    const handleOnSendMessageClick = () => {
        onSendMessageClick(document.getElementById('inputMesage').value, 'Normal Message');
    };

    //Function to createupdatestcomment
    const updateTestComment = async (secondaryArray, action) => {
        if (secondaryArray.carrier_company === null) {
            if (selectedRowDataValue.posted_by_user !== user.email) {
                secondaryArray.carrier_company = userCompanyName
            }
        }
        try {
            let updateCommentResponse = await updateCommentApiCall(secondaryArray, action);
            return updateCommentResponse;
        }
        catch (error) {
            console.log('update comment', error);
        }
    };

    //Function to createCommentNotification
    const createCommentNotification = async (data) => {
        try {
            let response = await axios({
                url: environmentVariables.createCommentNotification,
                method: 'patch',
                data: data,
            });
            return response;
        }
        catch (error) { }
    };

    const onSendMessageClick = async (enteredMessage, messageType, loadChatMessages) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            if (loadChatMessages === undefined || loadChatMessages.length === 0) {
                loadChatMessages = chatAllMessages;
            }
            if (enteredMessage !== null && enteredMessage !== '') {
                if (loadChatMessages && loadChatMessages.length === 0 && (selectedRowDataValue.posted_by_user === user.email)) {
                    Swal.fire({
                        text: 'Broker Is not Authorized to Initiate Chat',
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    }
                    );
                    document.getElementById('inputMesage').value = '';
                }
                else {
                    let Bid = '';
                    if (messageType === 'System Message') {
                        if (enteredMessage === 'Bid Closed') {
                            Bid = 'Bid Closed';
                        }
                        else if (counterOffer !== '' && counterOffer !== undefined) {
                            Bid = counterOffer;
                        }
                        else if (loadBidAmount !== '') {
                            Bid = loadBidAmount;
                        }
                        else if (chatAllMessages.bid_amount !== undefined && chatAllMessages.bid_amount !== 'Bid Closed') {
                            Bid = chatAllMessages.bid_amount;
                        }

                    }
                    if (document.getElementById('inputMesage') && document.getElementById('inputMesage').value !== null) {
                        document.getElementById('inputMesage').value = '';
                    }
                    if (loadChatMessages && loadChatMessages.length === 0) {
                        let companyName = userCompanyName;
                        if (companyName === null) {
                            companyName = userCompanyName;
                        }
                        const secondaryArray = [
                            {
                                time_stamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                                broker_id: selectedRowDataValue.posted_by_user,
                                broker_company: selectedRowDataValue.posted_by_company,
                                broker_mc_number: selectedRowDataValue.posted_by_mc_number,
                                carrier_id: user.email,
                                carrier_company: companyName,
                                carrier_mc_number: loggedInCompanyMCNumber,
                                load_id: selectedRowDataValue.load_id,
                                bid_amount: '$' + Bid,
                                driver_id: selectedRowDataValue.driver_name,
                                pinned_user: pinned,
                                messages: [
                                    {
                                        date: moment().format('hh:mm A'),
                                        messanger_id: user.email,
                                        message: enteredMessage,
                                        user: user.nickname,
                                        message_type: messageType,
                                        read_status: [{ name: user.email, status: true }, { name: selectedRowDataValue.posted_by_user, status: false }],
                                    },
                                ],
                            },
                        ];
                        let createCommentResponse = await createCommentApiCall(secondaryArray[0], 'put');
                        if (createCommentResponse) {
                            fetchAllComments(createCommentResponse);
                            setChatAllMessages(createCommentResponse);
                        }
                        if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Broker' || loadPostedByUser === user.email) {
                            sendMessageForSocketIO('ThereIsANewMessageFromBroker||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, secondaryArray.carrier_id, true, selectedRowDataValue, []);
                            sendMessageForSocketIO('newNotification', '', false);
                        }
                        else if (user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser !== user.email) {
                            sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                            sendMessageForSocketIO('newNotification', '', false);
                        }
                        return await createCommentNotification({
                            data: {
                                load_id: selectedRowDataValue.load_id,
                                fromEmail: user.email,
                                toEmail: selectedRowDataValue.posted_by_user,
                                message: enteredMessage,
                            },
                        });
                    } else {
                        if (enteredMessage !== 'Bid Closed' || (enteredMessage === 'Bid Closed' && loadChatMessages.bid_amount !== 'Bid Closed')) {
                            const secondaryArray = loadChatMessages;
                            secondaryArray.time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                            secondaryArray.driver_name = selectedRowDataValue.driver_name;
                            if (messageType === 'System Message') {
                                if (enteredMessage === 'Bid Closed') {
                                    secondaryArray.bid_amount = 'Bid Closed';
                                }
                                else if (counterOffer !== '' && counterOffer !== undefined) {
                                    secondaryArray.bid_amount = '$' + counterOffer;
                                }
                                else if (loadBidAmount !== '') {
                                    secondaryArray.bid_amount = '$' + loadBidAmount;
                                }
                                else if (chatAllMessages.bid_amount !== undefined && chatAllMessages.bid_amount !== 'Bid Closed') {
                                    secondaryArray.bid_amount = chatAllMessages.bid_amount;
                                }

                            }
                            secondaryArray.messages = [
                                ...secondaryArray.messages,
                                {
                                    date: moment().format('hh:mm A'),
                                    messanger_id: user.email,
                                    message: enteredMessage,
                                    user: user.nickname,
                                    message_type: messageType,
                                    read_status: [{ name: user.email, status: true }, { name: user.email === secondaryArray.broker_id ? secondaryArray.carrier_id : secondaryArray.broker_id, status: false }],
                                },
                            ];
                            let response = await updateTestComment(secondaryArray, 'patch');
                            if (response) {
                                setChatAllMessages(response);
                                if (enteredMessage.includes('Bid of amount') && enteredMessage.includes('accepted')) {
                                    handleGettingConfirmationForClosingSecondaryPane();
                                }
                            }
                            if (document.getElementById('inputMesage') && document.getElementById('inputMesage').value !== null) {
                                document.getElementById('inputMesage').value = '';
                            }
                            if (user.email === selectedRowDataValue.posted_by_user || user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Broker' || loadPostedByUser === user.email) {
                                fetchAllComments(
                                    {
                                        'load_id': selectedRowDataValue.load_id,
                                        'posted_by_user': selectedRowDataValue.posted_by_user
                                    }
                                );
                                sendMessageForSocketIO('ThereIsANewMessageFromBroker||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, secondaryArray.carrier_id, true, selectedRowDataValue, []);
                                sendMessageForSocketIO('newNotification', '', false);
                            }
                            else if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || loadPostedByUser !== user.email) {
                                fetchAllComments(response);
                                sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                                sendMessageForSocketIO('newNotification', '', false);
                            }
                            return await createCommentNotification({
                                data: {
                                    load_id: selectedRowDataValue.load_id,
                                    fromEmail: user.email,
                                    toEmail: selectedRowDataValue.posted_by_user,
                                    message: enteredMessage
                                },
                            });
                        }
                    }
                }
            }
        }
    };

    const showChatAccordingToUser = (loadInformation, data) => {
        setChatAllMessages(data);
        if (loadInformation.posted_by_user === user.email) {
            setChatFirstCarrierName(data.carrier_id);
            setchatFirstCompanyName(data.carrier_company);
            setChatCarrierMCNumber(`MC #${data.carrier_mc_number}`);
        }
        else {
            setChatFirstCarrierName(data.broker_id);
            setchatFirstCompanyName(data.broker_company);
            setChatCarrierMCNumber(`MC #${data.broker_mc_number}`);
        }
    };

    const fetchAllComments = async (loadInformation) => {
        if (loadInformation.posted_by_user === undefined) {
            if (loadInformation.broker_id !== undefined) {
                loadInformation.posted_by_user = loadInformation.broker_id;
            }
            else {
                loadInformation.posted_by_user = loadInformation.broker_name;
            }
        }
        let allChatResponse;
        let loadId = '';
        let userId = '';
        if (loadInformation.posted_by_user.replaceAll('"', '') === user.email.replaceAll('"', '') || user.email === process.env.REACT_APP_ADMIN_USER) {
            loadId = loadInformation.load_id;
            userId = '&broker_id=' + user.email;
        }
        else {
            loadId = loadInformation.load_id;
            userId = '&carrier_id=' + user.email;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                if (getCommentResponse !== undefined) {
                    if (isPaneOpen === true) {
                        if (loadInformation.posted_by_user === user.email) {
                            getCommentResponse.forEach(data => {
                                if (data.carrier_id === chatFirstCarrierName) {
                                    showChatAccordingToUser(loadInformation, data);
                                }
                            });
                        }
                        else {
                            showChatAccordingToUser(loadInformation, getCommentResponse[0]);
                        }
                    }
                    else {
                        showChatAccordingToUser(loadInformation, getCommentResponse[0]);
                    }
                    setAllChat(getCommentResponse);
                    allChatResponse = getCommentResponse;
                }
            }
        }
        catch (error) {
            if (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' && ((loggedInuserRole !== 'Carrier' && loadInformation.posted_by_user === user.email) || (loggedInuserRole === 'Broker')))) {
                setAllChat([]);
                setChatAllMessages([]);
                setChatFirstCarrierName('');
                setChatCarrierMCNumber('');
                setchatFirstCompanyName('');
            }
            else {
                let tempAllChatArray = {
                    'Items': [{
                        'broker_id': loadInformation.posted_by_user,
                        'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss')
                    }]
                };
                setAllChat(tempAllChatArray);
                allChatResponse = tempAllChatArray;
                setChatFirstCarrierName(loadInformation.posted_by_user);
                setChatCarrierMCNumber(`MC #${loadInformation.posted_by_mc_number}`);
                setchatFirstCompanyName(loadInformation.posted_by_company);
                setChatAllMessages([]);
            }
        }
        return allChatResponse;
    };

    //function to handle Pin and Unpin
    const pinChat = async (item) => {
        if (item.pinned_user === true) {
            item.pinned_user = false;
            setPinned(false);
            sendMessageForSocketIO('BrokerhaveUnPinnedYou||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, item.carrier_id, false, selectedRowDataValue, item);
        }
        else {
            item.pinned_user = true;
            setPinned(true);
            sendMessageForSocketIO('BrokerhavePinnedYou||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, item.carrier_id, false, selectedRowDataValue, item);
        }
        let update = await updateTestComment(item, 'patch');
        if (update !== undefined) {
            fetchAllComments(item);
        }
    };

    // Function to update chat read status after clicking on row
    const updateChatReadStatus = async (selectedRowChatDetails) => {
        let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails));
        if (listValues !== undefined) {
            listValues.forEach(element => {
                if (element.messages !== undefined) {
                    element.messages.forEach(message => {
                        if (message.read_status && message.read_status !== undefined) {
                            if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                                const findUsers = message.read_status.find(
                                    ({ name }) => name === user.email
                                );
                                if (findUsers.status === false) {
                                    findUsers.status = true;
                                }
                            }
                        }
                    });
                    element.messages = JSON.stringify(element.messages);
                }
            });
            let response = await updateTestComment(listValues, 'bulk');
            if (response) {
                sendMessageForSocketIO('chatHasBeenRead||load_id:' + response.load_id, '', false);
                sendMessageForSocketIO('newNotification', '', false);
            }
        }
    };

    // Function to get all chat for logged in user
    const fetchAllComment = async () => {
        try {
            let response = await axios({
                url: environmentVariables.getchatdetails + '?user_id=' + user.email,
                method: 'get'
            });
            if (response) {
                if (response.data.Items !== undefined) {
                    setChatDetails(response.data.Items);
                    fetchMainMuiDatatbleValues();
                }
            }
            return response.data.Items;
        }
        catch (error) {
            console.log(error);
        }
    };

    // ++++++++++++++++++++++++++++++++Function to handle new order click++++++++++++++++++++++++++++++++++++++++++
    const handleNewOrderClick = async () => {
        let getResponse = PostLoadFormRef.current.handleSendingResponse();
        if (getResponse && getResponse !== 'validate failed') {
            setDisablePostLoadButton(true);
            setDisableClearFormButton(true);
            delete getResponse[0].isValueChanged;
            if (getResponse[0].posted_by_company === null) {
                getResponse[0].posted_by_company = userCompanyName;
            }
            // setSnackBarMessageText('Load Posting In-progress');
            // setSnackBarSeverity('info');
            // setOpenSnackBar(true);
            try {
                let createLoadResponse = await createLoadApiCall(getResponse[0])
                if (createLoadResponse) {
                    setPristine();
                    let loadDimension;
                    if (createLoadResponse.freight_dimension_height !== '' || createLoadResponse.freight_dimension_length !== '' || createLoadResponse.freight_dimension_width !== '') {
                        if (createLoadResponse.freight_dimension_length === '') {
                            loadDimension = 'NA X ';
                        }
                        else {
                            loadDimension = createLoadResponse.freight_dimension_length.toString().trim() + 'L X ';
                        }
                        if (createLoadResponse.freight_dimension_width === '') {
                            loadDimension += 'NA X ';
                        }
                        else {
                            loadDimension += createLoadResponse.freight_dimension_width.toString().trim() + 'W X ';
                        }
                        if (createLoadResponse.freight_dimension_height === '') {
                            loadDimension += 'NA';
                        }
                        else {
                            loadDimension += createLoadResponse.freight_dimension_height.toString().trim() + 'H';
                        }
                    }
                    else {
                        loadDimension = '';
                    }
                    loadMatcheSaveLaneIncrementCountSendEmail(createLoadResponse, loadDimension);
                    try {
                        let data = {
                            posted_by_company: createLoadResponse.posted_by_company,
                            load_id: createLoadResponse.load_id,
                            pickupfrom_date: createLoadResponse.pickupfrom_date,
                            pickupto_date: createLoadResponse.pickupto_date,
                            max_weight: createLoadResponse.max_weight,
                            pickup_dest: createLoadResponse.pickup_dest,
                            delivery_dest: createLoadResponse.delivery_dest,
                            posted_by_user: user.email,
                            posted_comp_mc_number: loggedInCompanyMCNumber,
                            loadUrl: window.location.origin + '/loadboard?loadId=' + slugid.encode(createLoadResponse.load_id),
                            equipment_needed: createLoadResponse.truck_type,
                            load_dimension: loadDimension,
                            load_number: createLoadResponse.load_number,
                            load_notes: createLoadResponse.load_notes
                        };
                        let createLoadEmailResponse = await createLoadNotificationApiCall(data);
                        if (createLoadEmailResponse) {
                            fetchMainMuiDatatbleValues();
                            setRowDataForPostLoadForm([]);
                            // setOpenSnackBar(false);
                            setSnackBarMessageText('Load Succesfully Posted');
                            setSnackBarSeverity('success');
                            setOpenSnackBar(true);
                            sendMessageForSocketIO('newLoadHasBeenPlaced||load_id:' + createLoadResponse.load_id, '', true, createLoadResponse, []);
                            sendMessageForSocketIO('newNotification', '', false);
                        }
                    }
                    catch (error) {
                        console.log("post load error", error)
                    }
                }
            }
            catch (error) {
                console.log('create load error', error)
            }
        }
        setDisablePostLoadButton(false);
        setDisableClearFormButton(false);
    };
    // +++++++++++++++++++++++++++++++++++++ Function to handle edit changes +++++++++++++++++++++++++++++++++++++++++++++
    const handleEditButtonClick = async () => {
        if (SearchFormRef.current !== undefined && SearchFormRef.current !== null) {
            setEnableSetButton(true);
            setLoadUpdateLoadExpirationTimeForMin(selectedRowDataValue.load_expiration_time.split(':')[1])
            setLoadUpdateLoadExpirationTimeForHour(selectedRowDataValue.load_expiration_time.split(':')[0])
            if (SearchFormRef.current.isSearchFieldValueChanged === true) {
                let formLeaveStatus = handleLeavingSearchLoadForm();
                if (formLeaveStatus === true) {
                    toOpenEditForm();
                }
            }
            else {
                if (SearchFormRef.current.isSearchFieldValueChanged !== true) {
                    toOpenEditForm();
                }
                else {
                    let formLeaveStatus = handleLeavingEditLoadForm();
                    if (formLeaveStatus === true) {
                        toOpenEditForm();
                    }
                }
            }
        }
        else {
            setEnableSetButton(true);
            setLoadUpdateLoadExpirationTimeForMin(selectedRowDataValue.load_expiration_time.split(':')[1])
            setLoadUpdateLoadExpirationTimeForHour(selectedRowDataValue.load_expiration_time.split(':')[0])
            toOpenEditForm();
        }
    };
    // +++++++++++++++++++++++++++++++++++++ Function to handle soft delete +++++++++++++++++++++++++++++++++++++++++++++
    const handleSoftDeleteConfirmClick = async () => {
        setPostLoadFormFunctionality('Delete Load');
        if (selectedRowDataValue.posted_by_user === user.email && (selectedRowDataValue.status === 'new' || selectedRowDataValue.status === 'Active')) {
            Swal.fire({
                text: 'Are you sure you want to Delete this load?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    let getResponse = PostLoadFormRef.current.handleSendingResponse();
                    if (getResponse) {
                        if (getResponse[0].isValueChanged === true || isUpdateLoadExpirationValueChanged === true) {
                            let leavingResponse = handleLeavingEditLoadForm();
                            if (leavingResponse === true) {
                                handleSoftDelete();
                            }
                        }
                        else {
                            handleSoftDelete();
                        }
                    }
                    else {
                        handleSoftDelete();
                    }
                }
            });
        }
        else if (selectedRowDataValue.status === 'Closed') {
            Swal.fire({
                text: 'Cannot delete load after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
        else {
            Swal.fire({
                text: 'Not authorized to delete this load',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
    };

    const ConfirmationOnDeleteLoad = () => {
        if (isUpdateLoadExpirationValueChanged === false) {
            handleSoftDeleteConfirmClick();
        }
        else {
            let leavingResponse = handleLeavingEditLoadForm();
            if (leavingResponse === true) {
                handleSoftDeleteConfirmClick();
            }
        }
    };

    const handleSoftDelete = async () => {
        let listValues = selectedRowDataValue;
        listValues = { ...listValues, 'load_Delete_Status': true };
        try {
            const deleteLoadResponse = await deleteLoadApiCall(cleanListForUpdatingWatchLoad(listValues));
            if (deleteLoadResponse) {
                setRowDataForPostLoadForm([]);
                setShowing(false);
                fetchMainMuiDatatbleValues();
                sendMessageForSocketIO('BrokerLoadHasBeenDeleted||load_id:' + selectedRowDataValue.load_id, '', false);
                sendMessageForSocketIO('newNotification', '', false);
                reduceCountOnLoadExpire(deleteLoadResponse, user.email);
                handleGettingConfirmationForClosingSecondaryPane();
            }
        }
        catch (error) { }
    };
    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //function on clicking Clear to clear Values from PostLoad
    const clearPostLoadForm = async () => {
        if (postLoadFormFunctionality === 'Post Load') {
            if (PostLoadFormRef.current.onTabPannelPostLoad !== undefined) {
                PostLoadFormRef.current.onTabPannelPostLoad();
                PostLoadFormRef.current.removeErrorColor();
            }
        }
    };

    //function on clicking cancel to clear values
    const onCancelRePostClick = async () => {
        if (postLoadFormFunctionality === 'Edit Load') {
            let getResponse = PostLoadFormRef.current.handleSendingResponse();
            if (getResponse) {
                if (getResponse[0].isValueChanged === true || getResponse === 'validate failed') {
                    let leavingResponse = handleLeavingEditLoadForm();
                    if (leavingResponse === true) {
                        setLabel('Post Load');
                        setShowing(!showing);
                        setRowDataForPostLoadForm([]);
                        setPristine();
                        setPostLoadFormFunctionality('Post Load');
                        closeSecondaryPane();
                        PostLoadFormRef.current.removeErrorColor();
                    }
                    else {
                        if (leavingResponse === undefined) {
                            setShowing(showing);
                        }
                    }
                }
                else if (getResponse[0].isValueChanged === false) {
                    setLoadUpdatedStatus(true);
                    setLabel('Post Load');
                    setShowing(!showing);
                    setRowDataForPostLoadForm([]);
                    setPristine();
                    setPostLoadFormFunctionality('Post Load');
                    closeSecondaryPane();
                }
            }
        }
        else {
            setShowing(!showing);
            setRowDataForPostLoadForm([]);
            setPristine();
            setPostLoadFormFunctionality('Post Load');
        }
    };

    // FUnction to handle Repost load with updated values
    const handleRepostChanges = async () => {
        setDisableUpdateLoadButton(true);
        setDisableCancelButton(true);
        let getResponse = PostLoadFormRef.current.handleSendingResponse();
        if (getResponse && getResponse !== 'validate failed') {
            delete getResponse[0].isValueChanged;
            // setSnackBarMessageText('Load Updating In-progress');
            // setSnackBarSeverity('info');
            // setOpenSnackBar(true);
            try {
                const editLoadResponse = await editLoadDetailApiCall(getResponse[0]);
                if (editLoadResponse) {
                    // setOpenSnackBar(false);
                    setSnackBarMessageText('Load Succesfully Updated');
                    setSnackBarSeverity('success');
                    setOpenSnackBar(true);
                    setDisableUpdateLoadButton(false);
                    setDisableCancelButton(false);
                    setPristine();
                    setShowing(!showing);
                    fetchMainMuiDatatbleValues();
                    setRowDataForPostLoadForm([]);
                    setLabel('Post Load');
                    sendMessageForSocketIO('BrokerLoadHasBeenUpdated||load_id:' + editLoadResponse.load_id, '', true, editLoadResponse, []);
                    sendMessageForSocketIO('newNotification', '', false);
                    if (isPaneOpenForSocketIO) {
                        editLoadResponse.status = selectedRowDataValue.status;
                        populateSecondaryPaneValues(editLoadResponse);
                        selectedRowDataValueForSocketIO = editLoadResponse;
                        setSelectedRowDataValue(editLoadResponse);
                        setPostLoadFormFunctionality('Post Load');
                    }
                    return setLoadUpdatedStatus(true);
                }
            }
            catch (error) {
            }
        }
        setDisableUpdateLoadButton(false);
        setDisableCancelButton(false);
        setLoadUpdatedStatus(false);
    };
    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // Functions to search the load
    const searchButtonClicked = () => {
        if (postLoadFormFunctionality === 'Edit Load') {
            let getResponse = PostLoadFormRef.current.handleSendingResponse();
            if (getResponse) {
                if (getResponse[0].isValueChanged === true || getResponse === 'validate failed') {
                    let leavingResponse = handleLeavingEditLoadForm();
                    if (leavingResponse === true) {
                        closeSecondaryPane();
                        handleTopTabPannelChange('search', 1);
                        setShowing(false);
                    }
                    else {
                        handleTopTabPannelChange('edit', 0);
                        setLabel('Edit Load');
                    }
                }
                else if (isPaneOpen === true) {
                    closeSecondaryPane();
                    panelOne();
                }
            }
        }
        else {
            localStorage.setItem('panelValue', 'false');
            handleGettingConfirmationForClosingSecondaryPane();
            panelOne();
        }
    };

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === '') {
            const valForFilter = place.target.value;
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === '') {
                FilterColumns[14].options.filterList = [];
            }
            else {
                FilterColumns[14].options.filterList = filterList;
            }
            setColumnDefsLoadTable(FilterColumns);
        }
    };

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[2].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns);
        }
    };

    // function to get all nearby pickup cities within specified miles
    const getNearestPickUpCities = async (event) => {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            setSearchPickUpMiles(event.target.value);
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // } 
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=pickupcitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest);
                            }
                            else {
                                filterList.push(element.pickup_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsLoadTable];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const FilterColumns = [...columnDefsLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                FilterColumns[2].options.filterList = filterList;
                setColumnDefsLoadTable(FilterColumns);
            }
        }
    };

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[3].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns);
        }
        else {
            const FilterColumns = [...columnDefsLoadTable];
            FilterColumns[3].options.filterList = [];
            setColumnDefsLoadTable(FilterColumns);
        }

    };

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[4].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns);
        }
    };

    // function to get all nearby deliver cities within specified miles
    const getNearestCitiesForDeliverCity = async (event) => {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchDeliverToCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            // setSearchDeliverMiles(event.target.value)
            // var rad = zipcodes.radius(searchDeliverToZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchDeliverToZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchDeliverToZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=delivercitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchDeliverToCity + ', ' + searchDeliverToState + ' - ' + searchDeliverToZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.delivery_dest === valForFilter) {
                                filterList.unshift(element.delivery_dest);
                            }
                            else {
                                filterList.push(element.delivery_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsLoadTable];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const FilterColumns = [...columnDefsLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                FilterColumns[4].options.filterList = filterList;
                setColumnDefsLoadTable(FilterColumns);
            }
        }
    };

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const FilterColumns = [...columnDefsLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[6].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns);
        }
        else {
            const FilterColumns = [...columnDefsLoadTable];
            FilterColumns[6].options.filterList = [];
            setColumnDefsLoadTable(FilterColumns);
        }
    };

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const FilterColumns = [...columnDefsLoadTable];
        if (allTruckType && allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[7].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns);
        }
        else {
            FilterColumns[7].options.filterList = [];
            setColumnDefsLoadTable(FilterColumns);
        }
    };

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        const FilterColumns = [...columnDefsLoadTable];
        if (allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[7].options.filterList = filterList;
            setColumnDefsLoadTable(FilterColumns);
        }
        else {
            FilterColumns[7].options.filterList = [];
            setColumnDefsLoadTable(FilterColumns);
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const FilterColumns = [...columnDefsLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    FilterColumns[8].options.filterList = [];
                }
                else {
                    FilterColumns[8].options.filterList = filterList;
                }
                setColumnDefsLoadTable(FilterColumns);
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const FilterColumns = [...columnDefsLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    FilterColumns[9].options.filterList = [];
                }
                else {
                    FilterColumns[9].options.filterList = filterList;
                }
                setColumnDefsLoadTable(FilterColumns);
            }
        }
    };

    //Function To Send Notification On Search Value Matches the Saved Lane
    const loadMatcheSaveLaneIncrementCountSendEmail = async (list, loadDimension) => {
        try {
            let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
            if (matchLaneResponse !== undefined) {
                let LaneCarrierName = [];
                matchLaneResponse.forEach(Lane => {
                    if (Lane.delete_lane === false) {
                        if ((new Date(Lane.pick_upfrom_date) >= new Date(list.pickupfrom_date) && Lane.pickup_dest === list.pickup_dest) || (new Date(Lane.pickupto_date) >= new Date(list.pickupto_date) && Lane.delivery_dest === list.delivery_dest)) {
                            LaneCarrierName.push(Lane.carrier_name);
                            let count = parseInt(Lane.matched_lane);
                            count = count + 1;
                            let listValues =
                            {
                                'pick_upfrom_date': Lane.pick_upfrom_date,
                                'pickup_dest': Lane.pickup_dest,
                                'delivery_dest': Lane.delivery_dest,
                                'pickupto_date': Lane.pickupto_date,
                                'carrier_name': Lane.carrier_name,
                                'max_weight': Lane.max_weight,
                                'total_item_count': Lane.total_item_count,
                                'truck_type': Lane.truck_type,
                                'miles_from': Lane.miles_from,
                                'miles_to': Lane.miles_to,
                                'delete_lane': false,
                                'matched_lane': count.toString(),
                                'lane_number': Lane.lane_number,
                                'load_id': list.load_id,
                                'lane_id': Lane.lane_id,
                                'time_stamp': Lane.time_stamp,
                                'carrier_company': Lane.carrier_company
                            };
                            updateCount(listValues);
                            if (LaneCarrierName.length !== 0) {
                                sendEmailForMatchedLane(list, loadDimension, LaneCarrierName);
                            }
                        }
                    }
                });
                sendMessageForSocketIO('ThereIsALoadMatchesYourSavedLane||load_id:' + list.load_id + '||posted_by_user:' + list.posted_by_user, LaneCarrierName, true, list, LaneCarrierName);
                sendMessageForSocketIO('newNotification', '', false);
            }
        }
        catch (error) { }
    };

    //Function to clear filter On switching to post Load Form
    const clearFilter = () => {
        const FilterColumns = [...columnDefsLoadTable];
        FilterColumns[2].options.filterList = [];
        FilterColumns[3].options.filterList = [];
        FilterColumns[4].options.filterList = [];
        FilterColumns[6].options.filterList = [];
        FilterColumns[7].options.filterList = [];
        FilterColumns[8].options.filterList = [];
        FilterColumns[9].options.filterList = [];
        FilterColumns[14].options.filterList = [];
    };
    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        if (topTabPannelValue === 1) {
            const FilterColumns = [...columnDefsLoadTable];
            switch (index) {
                case 2:
                    {
                        FilterColumns[2].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(2);
                        return;
                    }
                case 3:
                    {
                        FilterColumns[3].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(3);
                        return;
                    }
                case 4:
                    {
                        FilterColumns[4].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(4);
                        return;
                    }
                case 6:
                    {
                        FilterColumns[6].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(6);
                        return;
                    }
                case 7:
                    {
                        const FilterColumns = [...columnDefsLoadTable];
                        FilterColumns[7].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(7);
                        return;
                    }
                case 8:
                    {
                        FilterColumns[8].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(8);
                        return;
                    }
                case 9:
                    {
                        FilterColumns[9].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(9);
                        return;
                    }
                case 14:
                    {
                        FilterColumns[14].options.filterList = [];
                        setColumnDefsLoadTable(FilterColumns);
                        SearchFormRef.current.onSearchClear(14);
                        return;
                    }
                default:
                    return;
            }
        }
    };
    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const updateLoadBidAmount = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setLoadBidAmount(event.target.value);
                setShowPrompt();
            }
        }
    };

    // Function to set and check MilesOut textfield
    const handelTxtMilesOutChange = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                updateTxtMilesOut(event.target.value);
            }
        }
    };

    //Function to Show Alert ON Miles Out andTruck Dimension Is empty
    const milesTruckDimension = async (Data) => {
        setDisableBidButton(false);
        Swal.fire({
            text: `Providing ${Data} greatly increases the chances of winning the bid, Click 'OK' to Continue OR Click 'Cancel' to Enter Value `,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
        }
        )
            .then((result) => {
                if ((result.value === true) || (txtMilesOut !== '' && txtLength !== '' && txtHeight !== '' && txtWidth !== '')) {
                    disableBidDetails();
                    addBidDetails();
                }
            });
    };

    //Function to Validate Bid Now
    const bidValidate = () => {
        let temp = {};
        if (loadBidAmount === '') {
            temp.loadBidAmount = 'error';
        }
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    //Function to Validate ReBid 
    const reBidValidate = () => {
        let temp = {};
        if (loadBidAmount === '') {
            temp.loadBidAmount = 'error';
        }
        if (BidNotes === '0' || BidNotes === 0) {
            temp.BidNotes = 'error'
        }
        if (bidExpiration === '0' || bidExpiration === 0) {
            temp.bidExpiration = 'error'
        }
        if (txtMilesOut === '0' || txtMilesOut === 0) {
            temp.txtMilesOut = 'error'
        }
        if (txtHeight === '0' || txtHeight === 0) {
            temp.txtHeight = 'error'
        }
        if (txtLength === '0' || txtLength === 0) {
            temp.txtLength = 'error'
        }
        if (txtWidth === '0' || txtWidth === 0) {
            temp.txtWidth = 'error'
        }
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    // Function to handle bid button
    const bidForALoad = async () => {
        if (bidValidate()) {
            if (loadBidAmount.toString().trim().replace(' ', '') !== '' && loadBidAmount !== '' && parseInt(loadBidAmount) !== 0) {
                setDisableBidButton(true);
                setPristine();
                if (txtMilesOut === '' && (txtLength === '' || txtHeight === '' || txtWidth === '')) {
                    milesTruckDimension('Miles out and Truck Dimensions');
                }
                else if (txtMilesOut === '') {
                    milesTruckDimension('Miles out');
                }
                else if (txtLength === '' || txtHeight === '' || txtWidth === '') {
                    milesTruckDimension('Truck Dimensions');
                }
                else {
                    disableBidDetails();
                    addBidDetails();
                }
            }
            else {
                setLoadBidAmount('');
                setDisableBidButton(false);
            }
        }
    };

    const changeBidForLoad = async () => {
        if (reBidValidate()) {
            const findResult = rowData.find(
                ({ load_id }) => load_id === selectedRowDataValue.load_id
            );
            let listValue = findResult;
            listValue = {
                ...listValue, 'carrier_name': user.email,
                'carrier_company': userCompanyName,
                'bid_amount': loadBidAmount,
                'bid_Notes': BidNotes,
                'status': 'new',
                'bid_expiration_time': bidExpiration,
                'load_dimension': loadFreightDimension,
                'miles_Out': txtMilesOut,
                'Truck_dimension_length': txtLength,
                'Truck_dimension_width': txtWidth,
                'Truck_dimension_height': txtHeight,
                'truck_dimension': `${txtLength}LX${txtWidth}WX${txtHeight}H`,
                'loadUrl': `${window.location.origin}/activeloads?loadId=${slugid.encode(selectedRowDataValue.load_id)}`,
            };
            listValue.bid_time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            listValue.counter_offer = '';
            listValue.counteroffer_sent = 'newoffer';
            listValue.counteroffer_status = 'New';
            listValue.emailType = 'bidupdated';
            if (listValue) {
                Swal.fire({
                    text: 'Are you sure you want to change this bid?',
                    cancelButtonColor: '#0066cc',
                    confirmButtonText: 'Ok',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0066cc'
                })
                    .then(async (result) => {
                        if (result.value === true) {
                            const editBidResponse = await editBidApiCall(listValue);
                            if (editBidResponse) {
                                setSnackBarMessageText(`Bid update to ${selectedRowDataValue.posted_by_company}`);
                                setSnackBarSeverity('success');
                                setOpenSnackBar(true);
                                toGetBidResponse(editBidResponse);
                                bidDetailMsg(editBidResponse, 'Updatebid');
                                await createBidNotificationApiCall(listValue);
                                fetchAllComments(listValue);
                            }
                        }
                    })
                    .catch((error) => { console.log(error); });
            }

        }
    }
    //Function to StoreBidValue
    const addBidDetails = async () => {
        setPristine();
        setDisableBidButton(true);
        const findResult = rowData.find(
            ({ load_id }) => load_id === selectedRowDataValue.load_id
        );
        let listValue = findResult;
        listValue = {
            ...listValue, 'carrier_name': user.email,
            'carrier_company': userCompanyName,
            'bid_amount': loadBidAmount,
            'bid_Notes': BidNotes,
            'status': 'new',
            'bid_expiration_time': bidExpiration + ' min',
            'load_dimension': loadFreightDimension,
            'miles_Out': txtMilesOut,
            'Truck_dimension_length': txtLength,
            'Truck_dimension_width': txtWidth,
            'Truck_dimension_height': txtHeight,
            'truck_dimension': txtLength + 'LX' + txtWidth + 'WX' + txtHeight + 'H',
            'loadUrl': window.location.origin + '/activeloads?loadId=' + slugid.encode(selectedRowDataValue.load_id)
        };
        listValue.bid_time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
        listValue.counter_offer = '';
        listValue.counteroffer_sent = 'newoffer';
        listValue.counteroffer_status = 'New';
        listValue.emailType = 'bidplaced';
        await createBidNotificationApiCall(listValue);
        toStoreCreateBid(listValue);
        fetchAllComments(listValue);
    }

    const reBidBtnStyle = (
        loadBidAmount.toString().trim().replace(' ', '') &&
        loadBidAmount !== selectedRowDataValue.bid_amount &&
        selectedRowDataValue.counter_offer === '' ||
        BidNotes !== selectedRowDataValue.bid_notes ||
        txtMilesOut !== selectedRowDataValue.miles_out ||
        bidExpiration !== selectedRowDataValue.bid_expiration_time ||
        txtLength !== selectedRowDataValue.truck_dimension_length ||
        txtHeight !== selectedRowDataValue.truck_dimension_height ||
        txtWidth !== selectedRowDataValue.truck_dimension_width
    )
    const isDisabled = !(
        loadBidAmount.toString().trim().replace(' ', '') &&
        loadBidAmount !== selectedRowDataValue.bid_amount &&
        selectedRowDataValue.counter_offer === '' ||
        BidNotes !== selectedRowDataValue.bid_notes ||
        txtMilesOut !== selectedRowDataValue.miles_out ||
        bidExpiration !== selectedRowDataValue.bid_expiration_time ||
        txtLength !== selectedRowDataValue.truck_dimension_length ||
        txtHeight !== selectedRowDataValue.truck_dimension_height ||
        txtWidth !== selectedRowDataValue.truck_dimension_width
    );

    //function to send system message For Bid Details
    const bidDetailMsg = async (listValue, bidType) => {
        if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
            onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes} ,
            Miles Out : ${listValue.miles_Out} ,
            Truck Dimension :${listValue.truck_dimension} is submitted `, 'System Message');
        }
        else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
            onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes} ,
            Miles Out : ${listValue.miles_Out} ,
            Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
        }
        else {
            if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && bidType === 'Newbid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes}, Miles Out : ${listValue.miles_Out} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes}, Miles Out : ${listValue.miles_Out} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out}, 
            Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out}, 
            Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes}, Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes}, Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && bidType === 'Newbid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Bid Notes: ${listValue.bid_Notes} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && bidType === 'Newbid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
                onSendMessageClick(`Bid Amount: ${'$' + listValue.bid_amount}, Truck Dimension :${listValue.truck_dimension}is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && bidType === 'Newbid') {
                onSendMessageClick(`Bid of ${'$' + loadBidAmount} submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`Bid of ${'$' + loadBidAmount} updated`, 'System Message');
            }
        }
    };

    //Function used repeatedly To Diasble Bid Related Details
    const disableBidDetails = () => {
        setLoadBidAmountDisable(true);
        setMilesOutDisable(true);
        setLoadBidNotesDisable(true);
        setLengthDisable(true);
        setWidthDisable(true);
        setHeightDisable(true);
    };
    //Function to handle listValue For Bid
    const toStoreCreateBid = async (listValue) => {
        if (listValue.carrier_company === null) {
            listValue.carrier_company = userCompanyName;
        }
        // setSnackBarMessageText(`Bid sending to ${selectedRowDataValue.posted_by_company}...`);
        // setSnackBarSeverity('info');
        // setOpenSnackBar(true);
        try {
            const createBidResponse = await createBidApiCall(listValue);
            if (createBidResponse) {
                bidDetailMsg(listValue, 'Newbid');
                //setOpenSnackBar(false);
                setSnackBarMessageText(`Bid sent to ${selectedRowDataValue.posted_by_company}`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
                setDisableBidButton(false);
                fetchMainMuiDatatbleValues();
                setSelectedRowDataValue(listValue);
                loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
                sendMessageForSocketIO('ThereIsANewBid||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, listValue);
                sendMessageForSocketIO('newNotification', '', false);
                disableBidDetails();
                toGetBidResponse(listValue);
            }
        }
        catch (error) { }
    };

    //Function toStoreBidResponse
    const toGetBidResponse = async (selectedRowDataValue) => {
        try {
            let LoadboardLoads = await fetchLoadApiCall(user.email);
            if (LoadboardLoads) {
                const singleLoadId = LoadboardLoads.find(
                    ({ load_id }) => load_id === selectedRowDataValue.load_id
                );
                populateSecondaryPaneValues(singleLoadId);
                fetchAllComments(singleLoadId);
                selectedRowDataValueForSocketIO = singleLoadId;
                setSelectedRowDataValue(singleLoadId);
            }
        }
        catch (error) {
            console.log(error);
        }
        try {
            await fetchLoadApiCall(user.email);
        }
        catch (error) {
            console.log(error);
        }

    };
    // function to handle bidcancel
    const cancelBid = async () => {
        let listValues = selectedRowDataValue;
        let loadDimension;
        if (listValues.freight_dimension_height !== '' || listValues.freight_dimension_length !== '' || listValues.freight_dimension_width !== '') {
            if (listValues.freight_dimension_length === '') {
                loadDimension = 'NA X ';
            }
            else {
                loadDimension = listValues.freight_dimension_length.toString().trim() + 'L X ';
            }
            if (listValues.freight_dimension_width === '') {
                loadDimension += 'NA X ';
            }
            else {
                loadDimension += listValues.freight_dimension_width.toString().trim() + 'W X ';
            }
            if (listValues.freight_dimension_height === '') {
                loadDimension += 'NA';
            }
            else {
                loadDimension += listValues.freight_dimension_height.toString().trim() + 'H';
            }
        }
        else {
            loadDimension = '';
        }

        listValues.status = 'Closed';
        listValues.bid_notes = BidNotes;
        listValues.miles_out = txtMilesOut;
        if (listValues.counter_offer === null) {
            listValues.counter_offer = counterOffer;
        }
        listValues.load_dimension = loadDimension;
        if (selectedRowDataValue.status !== 'accepted') {
            Swal.fire({
                text: 'Are you sure you want to close this bid?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            })
                .then(async (result) => {
                    if (result.value === true) {
                        return await deleteBidApiCall(listValues);
                    }
                })
                .then(async (response) => {
                    if (response !== undefined) {
                        fetchAllComments(listValues);
                        loadAndRealoadSecondGrid(listValues.load_id);
                        populateSecondaryPaneValues(listValues);
                        onSendMessageClick('Bid Closed', 'System Message', chatAllMessages);
                        sendMessageForSocketIO('ABidHasBeenRemoved||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, listValues);
                        sendMessageForSocketIO('newNotification', '', false);
                        fetchMainMuiDatatbleValues();
                        return await fetchLoadApiCall(user.email);
                    }
                })
                .then((fetchLoadResponse) => {
                    if (fetchLoadResponse !== undefined) {
                        const singleLoadId = fetchLoadResponse.find(
                            ({ load_id }) => load_id === selectedRowDataValue.load_id
                        );
                        populateSecondaryPaneValues(singleLoadId);
                        selectedRowDataValueForSocketIO = singleLoadId;
                        setSelectedRowDataValue(singleLoadId);
                    }
                })
                .catch((error) => { console.log(error); });
        }
        else {
            Swal.fire({
                text: 'Cannot delete Bid after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
    };

    // Function to handle onchange value for hour field(load details panel)
    const handleSetLoadExpirationOnchangeValueForHour = (event, newValue) => {
        if (newValue !== selectedRowDataValue.load_expiration_time.split(':')[0]) {
            setEnableSetButton(false);
            if (newValue === '00') {
                setLoadUpdateLoadExpirationTimeForMin('30');
            }
            else {
                setLoadUpdateLoadExpirationTimeForHour(newValue);
            }
            setIsUpdateLoadExpirationValueChanged(true);
            setShowPrompt();
        }
        else {
            setEnableSetButton(true);
            setLoadUpdateLoadExpirationTimeForHour(newValue);
            setIsUpdateLoadExpirationValueChanged(false);
        }
    };

    // Function to handle Load ExpirationChange update load Information Area For  hours
    const handleSetLoadExpirationOnInputchangeValueForHour = (event, newInputValue) => {
        if (newInputValue !== selectedRowDataValue.load_expiration_time.split(':')[0]) {
            setEnableSetButton(false);
            if (newInputValue.length === 2) {
                if (newInputValue <= 23) {
                    setLoadUpdateLoadExpirationTimeForHour(newInputValue);
                } else {
                    setLoadUpdateLoadExpirationTimeForHour('23');
                }
            }
            else if (newInputValue.length > 2) {
                setLoadUpdateLoadExpirationTimeForHour('23');
            }
            else {
                setLoadUpdateLoadExpirationTimeForHour(newInputValue);
            }
            setIsUpdateLoadExpirationValueChanged(true);
            setShowPrompt();
        }
        else {
            setEnableSetButton(true);
            setLoadUpdateLoadExpirationTimeForHour(newInputValue);
            setIsUpdateLoadExpirationValueChanged(false);
        }
    };

    // Function to handle Load Expiration close update load Information Area For Hours

    const loadHandleLoadExpirationCloseForHour = () => {
        if (loadUpdateLoadExpirationTimeForHour === '00' && loadUpdateLoadExpirationTimeForMin === '00') {
            setLoadUpdateLoadExpirationTimeForMin('30');
        }
        let valueOnClose = loadUpdateLoadExpirationTimeForHour;
        if ((valueOnClose >= '0' && valueOnClose <= '9')) {
            if (valueOnClose.length < 2) {
                setLoadUpdateLoadExpirationTimeForHour('0' + valueOnClose);
            }
        }
        else {
            setLoadUpdateLoadExpirationTimeForHour('00');
        }
    };

    // Function to handle onchange value for min(load details panel)
    const handleSetLoadExpirationOnchangeValueForMin = (event, newValue) => {
        if (newValue !== selectedRowDataValue.load_expiration_time.split(':')[1]) {
            setEnableSetButton(false);
            if (loadUpdateLoadExpirationTimeForHour === '00') {
                if (newValue === '00') {
                    setLoadUpdateLoadExpirationTimeForMin('30');
                }
            }
            else {
                setLoadUpdateLoadExpirationTimeForMin(newValue);
            }
            setIsUpdateLoadExpirationValueChanged(true);
            setShowPrompt();
        }
        else {
            setEnableSetButton(true);
            setLoadUpdateLoadExpirationTimeForMin(newValue);
            setIsUpdateLoadExpirationValueChanged(false);
        }
    };

    // Function to handle Load Expiration update load Information Area For Minutes
    const loadHandleLoadExpirationInputChangeForMin = (event, newInputValue) => {
        if (newInputValue !== selectedRowDataValue.load_expiration_time.split(':')[1]) {
            setEnableSetButton(false);
            let newValue = newInputValue % 5;
            if (newInputValue.length === 2) {
                if (newInputValue < 58) {
                    if (newInputValue % 5 === 0) {
                        setLoadUpdateLoadExpirationTimeForMin(newInputValue);
                    } else if (newValue <= 2.5) {
                        let timeValue = JSON.stringify(newInputValue - newValue);
                        setLoadUpdateLoadExpirationTimeForMin(timeValue);
                    } else if (newValue > 2.5) {
                        let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                        setLoadUpdateLoadExpirationTimeForMin(timeValue);
                    }
                }
                else {
                    setLoadUpdateLoadExpirationTimeForMin('59');
                }
            } else if (newInputValue.length > 2) {
                setLoadUpdateLoadExpirationTimeForMin('59');
            }
            else {
                setLoadUpdateLoadExpirationTimeForMin(newInputValue);
            }
            setIsUpdateLoadExpirationValueChanged(true);
            setShowPrompt();
        }
        else {
            setEnableSetButton(true);
            setLoadUpdateLoadExpirationTimeForMin(newInputValue);
            setIsUpdateLoadExpirationValueChanged(false);
        }
    };

    // Function to handle Load Expiration close update load Information Area For Minutes
    const loadHandleLoadExpirationCloseForMin = () => {
        if (loadUpdateLoadExpirationTimeForHour === '00' && loadUpdateLoadExpirationTimeForMin === '00') {
            setLoadUpdateLoadExpirationTimeForMin('30');
        }
        let valueOnClose = loadUpdateLoadExpirationTimeForMin;
        if ((valueOnClose >= '0' && valueOnClose <= '9')) {
            if (valueOnClose.length < 2) {
                setLoadUpdateLoadExpirationTimeForMin('0' + valueOnClose);
            }
        }
        else {
            setLoadUpdateLoadExpirationTimeForMin('30');
        }
    };

    // Function to handle Update Load expiration Chanage
    async function handleUpdateLoadExpirationUpdateChnage() {
        setIsUpdateLoadExpirationValueChanged(false);
        setPristine();
        if (selectedRowDataValue.posted_by_user === user.email && (selectedRowDataValue.status === 'Active' || selectedRowDataValue.status === 'new')) {
            const findResult = rowData.find(
                ({ load_id }) => load_id === selectedRowDataValue.load_id
            );
            let listToUpdateExpirationDate = JSON.parse(JSON.stringify(findResult));
            listToUpdateExpirationDate.load_expiration_time = loadUpdateLoadExpirationTimeForHour + ':' + loadUpdateLoadExpirationTimeForMin;
            // setSnackBarMessageText('Updating Load Expiration Time In-progress');
            // setSnackBarSeverity('info');
            // setOpenSnackBar(true);
            try {
                const editLoadResponse = await editLoadDetailApiCall(cleanListForUpdatingWatchLoad(listToUpdateExpirationDate));
                if (editLoadResponse) {
                    setEnableSetButton(true);
                    // setOpenSnackBar(false);
                    setSnackBarMessageText('Load Expiration Time Succesfully Updated to - ' + listToUpdateExpirationDate.load_expiration_time);
                    setSnackBarSeverity('success');
                    setOpenSnackBar(true);
                    fetchMainMuiDatatbleValues();
                    sendMessageForSocketIO('BrokerLoadHasBeenUpdated||load_id:' + editLoadResponse.load_id, '', true, editLoadResponse, []);
                    sendMessageForSocketIO('newNotification', '', false);
                    if (isPaneOpenForSocketIO) {
                        editLoadResponse.status = 'Active';
                        populateSecondaryPaneValues(editLoadResponse);
                        selectedRowDataValueForSocketIO = editLoadResponse;
                        setSelectedRowDataValue(editLoadResponse);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        else if (selectedRowDataValue.status === 'Closed') {
            Swal.fire({
                text: 'Cannot modify load after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setLoadUpdateLoadExpirationTimeForHour(selectedRowDataValue.load_expiration_time.split(':')[0]);
            setLoadUpdateLoadExpirationTimeForMin(selectedRowDataValue.load_expiration_time.split(':')[1]);
        }
        else {
            Swal.fire({
                text: 'Not authorized to edit this load',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setLoadUpdateLoadExpirationTimeForHour(selectedRowDataValue.load_expiration_time.split(':')[0]);
            setLoadUpdateLoadExpirationTimeForMin(selectedRowDataValue.load_expiration_time.split(':')[1]);
        }
    }
    const handleLoadExpireNow = async () => {
        Swal.fire({
            text: 'Are you sure you want to Expire this load?',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        })
            .then(async (result) => {
                if (result.value) {
                    let listToUpdateExpirationDate = JSON.parse(JSON.stringify(selectedRowDataValue));
                    listToUpdateExpirationDate.load_expiration_time = '00:00';
                    listToUpdateExpirationDate.read_users = [];
                    listToUpdateExpirationDate.watch_load = [];
                    listToUpdateExpirationDate.saved_lane = [];
                    listToUpdateExpirationDate = { ...listToUpdateExpirationDate, 'load_expired_status': true };
                    return await editLoadDetailApiCall(listToUpdateExpirationDate);
                }
            })
            .then(async (editLoadResponse) => {
                fetchMainMuiDatatbleValues();
                handleDeletingExpiredLoadNotification(editLoadResponse.load_id, 'Load Expiration');
                handleGettingConfirmationForClosingSecondaryPane();
                const allNewBids = await ExpireAndFetchAllBids();
                RemoveBidsWhenLoadExpires(allNewBids, editLoadResponse.load_id);
                sendMessageForSocketIO('BrokerLoadHasBeenExpired||load_id:' + editLoadResponse.load_id, '', true, editLoadResponse);
                sendMessageForSocketIO('newNotification', '', false);
                setIsNotificationSent(true);
                reduceCountOnLoadExpire(editLoadResponse, user.email);
            })
            .catch((error) => {
                console.log('update load error2', error);
            });
    };

    //----------------------------------------------Counter Offer------------------------------------------------------------
    //----------------------------------------OpenForm and CloseForm----------------------------------------------------
    const toOpen = (acceptedRow) => {
        const findResult = rowDataActiveBidTable.find(
            ({ bid_id }) => bid_id === acceptedRow.rowData[3]
        );
        setSelectedRowDataValue(findResult);
    };

    const handleOpen = (acceptedRow) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            setCounterOpen(true);
            toOpen(acceptedRow);
            tosendOffer(acceptedRow.rowData[7]);
            setCounterOffer('');
        }
    };

    const handleOpenCarrier = (data, acceptedRow) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            toOpen(acceptedRow);
            tosendOffer(acceptedRow.rowData[7]);
            setCounterOpenCarrier(true);
            setCounterOffer(data);
        }
    };

    const handleClose = () => {
        setCounterOpen(false);
        setCounterOpenCarrier(false);
    };

    //---------------------------------ToSubmitOffer---------------------------------------------------------------------//

    const toSubmitOffer = () => {
        if (counterOffer !== '' && counterOffer !== '0' && counterOffer !== '00') {
            let regExp = /^0+$/g;
            let found = regExp.test(counterOffer);
            let result = found ? true : false;
            if (result === false) {
                toStoreCounterOffer(selectedRowDataValue);
            }

        }
        else {
            toShowAlert();
        }
    };

    const toShowAlert = () => {
        if (counterOffer === '') {
            Swal.fire({
                text: 'Counter Offer Value Is Empty',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setCounterOpen(false);
            setCounterOpenCarrier(false);
        }
    };

    const SubmitCounterOffer = () => {
        toSubmitOffer();
        setCounterOpen(false);
        setCounterOpenCarrier(false);
    };
    const CancelCounterOffer = () => {
        setCounterOpen(false);
        setCounterOffer('');
    };

    const CloseForm = () => {
        setCounterOpenCarrier(false);
    };
    //-----------------------------------------Handle Changes------------------------------------------------------------------------//
    const handleKeyPress = (event) => {
        if (event.key !== 'Enter') {
            handleCounterOffer(event);
        }
        else {
            event.preventDefault();
            SubmitCounterOffer();
        }
    };
    const handleCounterOffer = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setCounterOffer(event.target.value);
            }
        }
    };
    //-----------------------------------Function to Store , Submit, Update and Accept-------------------------------------------------------------------//

    const toGetData = () => {
        fetchMainMuiDatatbleValues();
        loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
        fetchAllComments(selectedRowDataValue);
    };

    const tosendOffer = async (nameCarrier) => {
        let carrierName = nameCarrier;
        let loadId = '';
        let userId = '';
        if (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier')) {
            loadId = selectedRowDataValue.load_id
            userId = '&broker_id=' + user.email
        }
        else {
            loadId = selectedRowDataValue.load_id;
            userId = '&carrier_id=' + user.email;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                getCommentResponse.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        setChatAllMessages(element);
                        setChatFirstCarrierName(element.carrier_id);
                        setchatFirstCompanyName(element.carrier_company);
                        setChatCarrierMCNumber(`MC #${element.carrier_mc_number}`);
                    }
                });
            }
        }
        catch (error) { }
    };
    async function toStoreCounterOffer(selectedRowDataValue) {
        const data = {
            load_id: selectedRowDataValue.load_id,
            bid_id: selectedRowDataValue.bid_id,
            carrier_name: selectedRowDataValue.carrier_name,
            carrier_company: selectedRowDataValue.carrier_company,
            bid_amount: counterOffer,
            bid_time_stamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            counter_offer: counterOffer,
            counteroffer_sent: (loadPostedByUser === user.email) ? 'broker' : 'carrier',
            counteroffer_status: 'New',
            bid_Notes: selectedRowDataValue.bid_notes,
            bid_expiration_time: selectedRowDataValue.bid_expiration_time,
            miles_Out: selectedRowDataValue.miles_out,
            Truck_dimension_height: selectedRowDataValue.truck_dimension_height,
            Truck_dimension_length: selectedRowDataValue.truck_dimension_length,
            Truck_dimension_width: selectedRowDataValue.truck_dimension_width,
            truck_dimension: selectedRowDataValue.truck_dimension
        };
        try {
            const counterOfferResponse = await editBidApiCall(data);
            if (counterOfferResponse) {
                let companyName = userCompanyName;
                if (userCompanyName === null) {
                    companyName = userCompanyName;
                }
                onSendMessageClick(`Counter Offer of $${counterOffer} submitted by ${companyName} `, 'System Message');
                toGetData();
                if (loggedInuserCompanyRole === 'Carrier' || loadPostedByUser !== user.email) {
                    sendMessageForSocketIO('CounterOfferIsUpdated||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Loads Posted', selectedRowDataValue.posted_by_user, true, selectedRowDataValue, selectedRowDataValue);
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser === user.email) {
                    sendMessageForSocketIO('ThereIsACounterOffer||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Favorites', selectedRowDataValue.carrier_name, true, selectedRowDataValue, selectedRowDataValue);
                }
                try {
                    let mailList = selectedRowDataValue;
                    let emailReceiver = '';
                    let loadUrl = '';
                    if (loadPostedByUser !== user.email) {
                        emailReceiver = selectedRowDataValue.posted_by_user
                        loadUrl = window.location.origin + '/activeloads?loadId=' + slugid.encode(selectedRowDataValue.load_id)
                    }
                    else {
                        emailReceiver = selectedRowDataValue.carrier_name
                        loadUrl = window.location.origin + '/activebids?loadId=' + slugid.encode(selectedRowDataValue.load_id)
                    }
                    mailList = {
                        ...mailList,
                        'posted_by_user': (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && loggedInuserRole !== 'Broker' && selectedRowDataValue.posted_by_user !== user.email)) ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name,
                        'counter_offer': counterOffer,
                        'load_notes': selectedRowDataValue.load_notes,
                        'load_dimension': loadFreightDimension,
                        'load_posted_by': selectedRowDataValue.posted_by_user,
                        'loadUrl': loadUrl,
                        'email_receiver': emailReceiver,
                        'bid_amount': selectedRowDataValue.bid_amount,
                        'bid_Notes': selectedRowDataValue.bid_notes,
                        'miles_Out': selectedRowDataValue.miles_out,
                        'truck_dimension': selectedRowDataValue.truck_dimension
                    };
                    const counterOfferEmailResponse = await counterOfferNotificationApiCall(mailList);
                    if (counterOfferEmailResponse) {
                        setSnackBarMessageText(`Counter Offer is Submitted by ${companyName} and Email Sent`);
                        loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
                        setSnackBarSeverity('success');
                        setOpenSnackBar(true);
                    }
                    document.getElementById('ButtonColorBookit').disabled = true;
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
        catch (error) { }
    }

    const AcceptCounterOfferCarrier = async () => {
        if (counterOffer !== '' && counterOffer !== '0' && counterOffer !== '00') {
            setDisableBookit(false);
            setCounterOpenCarrier(false);
            setCounterOffer('');
            let listValue = selectedRowDataValue;
            listValue.status = 'new';
            listValue.bid_time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            listValue.counter_offer = counterOffer;
            listValue.counteroffer_status = selectedRowDataValue.counteroffer_sent === 'newoffer' ? 'broker' : 'carrier';
            listValue.counteroffer_status = 'Accept';
            listValue.bid_Notes = selectedRowDataValue.bid_notes;
            listValue.miles_Out = selectedRowDataValue.miles_out;
            listValue.Truck_dimension_height = selectedRowDataValue.truck_dimension_height;
            listValue.Truck_dimension_length = selectedRowDataValue.truck_dimension_length;
            listValue.Truck_dimension_width = selectedRowDataValue.truck_dimension_width;
            try {
                const counterOfferResponse = await editBidApiCall(listValue);
                if (counterOfferResponse) {
                    let companyName = userCompanyName;
                    if (userCompanyName === null) {
                        companyName = userCompanyName;
                    }
                    onSendMessageClick(` ${companyName} Accepted  Counter Offer  $${counterOffer}`, 'System Message');
                    setSnackBarMessageText(`${companyName} Accepted Counter Offer`);
                    if (loggedInuserCompanyRole === 'Carrier' || loadPostedByUser !== user.email) {
                        sendMessageForSocketIO('CounterOfferAccepted||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Loads Posted', selectedRowDataValue.posted_by_user, false, selectedRowDataValue, selectedRowDataValue);
                    }
                    else if (loadPostedByUser === user.email) {
                        sendMessageForSocketIO('CounterOfferAccepted||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Favorites', selectedRowDataValue.carrier_name, false, selectedRowDataValue, selectedRowDataValue);
                    }
                    toGetData();
                    try {
                        let mailList = selectedRowDataValue;
                        mailList = {
                            ...mailList, 'counter_offer': counterOffer,
                            'load_notes': selectedRowDataValue.load_notes,
                            'load_dimension': loadFreightDimension,
                            'load_posted_by': selectedRowDataValue.posted_by_user,
                            'broker_loadUrl': window.location.origin + '/activeloads?loadId=' + slugid.encode(selectedRowDataValue.load_id),
                            'carrier_loadUrl': window.location.origin + '/activebids?loadId=' + slugid.encode(selectedRowDataValue.load_id),
                            'name': loadPostedByUser !== user.email ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name,
                            'bid_amount': selectedRowDataValue.bid_amount,
                            'bid_Notes': selectedRowDataValue.bid_notes,
                            'miles_Out': selectedRowDataValue.miles_out,
                            'truck_dimension': selectedRowDataValue.truck_dimension,
                            'carrier_name': selectedRowDataValue.carrier_name,
                            'posted_by_user': selectedRowDataValue.posted_by_user
                        };
                        const acceptCounterOfferEmailResponse = await acceptCounterOfferNotificationApiCall(mailList);
                        if (acceptCounterOfferEmailResponse) {
                            setSnackBarMessageText(`${userCompanyName} Accepted Counter Offer and Email Sent`);
                            setSnackBarSeverity('success');
                            setOpenSnackBar(true);
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }
            catch (error) {
            }
        }
        else {
            Swal.fire({
                text: 'Counter Offer Cannot be Zero Or Empty Value',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setCounterOpenCarrier(false);
        }
    };

    /************************************************Setting Bid Time ******************************************************/
    // Function to handle Bid Expiration  Minutes On Input
    const loadHandleBidExpirationChangeForMin = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setBidExpiration(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setBidExpiration(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setBidExpiration(timeValue);
                }
            }
            else {
                setBidExpiration('59');
            }
        } else if (newInputValue.length > 2) {
            setBidExpiration('59');
        }
        else {
            setBidExpiration(newInputValue);
        }
        if (newInputValue === '00') {
            setBidExpiration('15');
        }
        setShowPrompt();
    };

    //Function To handle Bid Expiration On Change From autocomplete
    const handleSetBidExpirationOnchangeValueForMin = (event, newValue) => {
        if (newValue === '00') {
            setBidExpiration('15');
        }
        else {
            setBidExpiration(newValue);
        }
        setShowPrompt();
    };

    //Function to handle On Close Of Bid Expiration time Field
    const loadHandleBidExpirationCloseForMin = () => {
        if (bidExpiration === '00') {
            setBidExpiration('15');
        }
        let valueOnClose = bidExpiration;
        if (valueOnClose === '1' || valueOnClose === '2' || valueOnClose === '3' || valueOnClose === '4') {
            setBidExpiration('05');
        }
        else if ((valueOnClose >= '0' && valueOnClose <= '9')) {
            if (valueOnClose.length < 2) {
                setBidExpiration('0' + valueOnClose);
            }
        }
        else {
            setBidExpiration('15');
        }
    };

    //------------------------- Place to define Socket related functions ------------------------------

    // Function to handle send Socket IO messages
    const sendMessageForSocketIO = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        try {
            handleAddNotification(message, recieverId, sendNotification, selectedRow, bidDetails);
            const connectionIds = await handleSendMessageForSocketIO(message, recieverId, sendNotification, selectedRow, bidDetails)
            sendJsonMessage({ 'action': 'onMessage', 'message': message, 'connectionIds': connectionIds.toString() });
        }
        catch (e) {
            console.log('send message error', e);
        }
    };

    const handleAddNotification = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        let loadId = '';
        let messages = '';
        if (message.includes('load_id')) {
            loadId = message.split('||')[1].split(':')[1];
            messages = message.split('||')[0];
        }
        let postedByUser = '';
        let wonUser = '';
        let bidCarrier = '';
        let postedCompany = '';
        let carrierCompany = '';
        let bidCarriers = [];
        let loadNumber = '';
        if (sendNotification === true) {
            if (messages === 'ThereIsANewBid') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'CounterOfferIsUpdated') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'ThereIsACounterOffer') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                postedCompany = selectedRow.posted_by_company;
            }
            else if (messages === 'ABidHasBeenRemoved') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'YourLoadHasBennAccepted') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                wonUser = bidDetails.rowData[6];
                carrierCompany = bidDetails.rowData[3];
            }
            else if (messages === 'YourBidHasExpired') {
                loadNumber = bidDetails.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'ThereIsANewMessageFromBroker') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                postedCompany = selectedRow.posted_by_company;
            }
            else if (messages === 'ThereIsANewMessageFromCarrier') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                carrierCompany = userCompanyName;
            }
            let listValues = {
                'load_id': loadId,
                'receiver_id': recieverId,
                'bid_carrier': bidCarrier,
                'won_carrier': wonUser,
                'load_posted_by_user': postedByUser,
                'notified_user': user.email,
                'message': messages,
                'carrier_company': carrierCompany,
                'load_expired_status': false,
                'posted_company': postedCompany,
                'bid_carriers': bidCarriers,
                'won_message': false,
                'user_id': '',
                'load_number': loadNumber,
                'time_stamp_notification': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                'generated_date': moment(new Date()).format('MM/DD/YYYY'),
                'read_users_list': [],
                'deleted_by_users': []
            };
            try {
                const addNotificationResponse = await addNotificationApiCall(listValues);
                if (addNotificationResponse) {
                    setIsNotificationSent(true);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    // Function to recive all the socket io messages
    const recieveMessageForSocketIo = async (message) => {
        const recievedMessage = await handleSocketReceiveMessage(message, user.email);
        setIsNotificationSent(true);

        // Recieveing a message for carrier for new load /pin and unpin
        if (recievedMessage.includes("newLoadHasBeenPlaced")) {
            fetchMainMuiDatatbleValues();
        }

        if (recievedMessage.includes("BrokerhavePinnedYou") || recievedMessage.includes("BrokerhaveUnPinnedYou")) {
            fetchMainMuiDatatbleValues();
            fetchAllComments(
                {
                    'load_id': recievedMessage.split('||')[1].split(':')[1],
                    'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                }
            );
        }

        if (recievedMessage === 'companyDetailsChanged') {
            dispatch(handleUserDataValue(user.email));
        }

        // Recieve message for a new chat
        if (recievedMessage.includes('ThereIsANewMessageFromCarrier')) {
            ScrollForParticularLoad(recievedMessage);
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    fetchAllComments(
                        {
                            'load_id': recievedMessage.split('||')[1].split(':')[1],
                            'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                        }
                    );
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        // Receive a message on counter offer
        if (recievedMessage.includes('ThereIsACounterOffer') || recievedMessage.includes('CounterOfferIsUpdated')) {
            setIsNotificationSent(true);
            if (isPaneOpen === false) {
                ifPaneClose(recievedMessage);
            }
            else {
                if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been submitted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]} Click here 'Ok' to review counter offer`,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    });
                    loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1]);
                }
                else {
                    ifPaneClose(recievedMessage);
                }
            }
            let messageText = '';
            if (recievedMessage.includes('ThereIsACounterOffer')) {
                messageText = 'There is a counter offer';
            }
            if (recievedMessage.includes('CounterOfferIsUpdated')) {
                messageText = 'Counter Offer is updated';
            }
            fetchMainMuiDatatbleValues();
        }
        // Receive a message on counter offer
        if (recievedMessage.includes('CounterOfferAccepted')) {
            setIsNotificationSent(true);
            fetchMainMuiDatatbleValues();
            if (isPaneOpen === false) {
                Swal.fire({
                    text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been accepted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]}Click here 'Ok' to review counter offer or 'Cancel' to review later `,
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0066cc',
                    cancelButtonColor: '#0066cc',
                    confirmButtonText: 'Ok'
                }
                ).then(async (result) => {
                    if (result.value === true) {
                        if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                            onAcceptCheckLoadId(recievedMessage);
                        }
                    }
                });
            }
            else {
                if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                    loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been accepted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]} Click here 'Ok' to review counter offer`,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    });
                    onAcceptCheckLoadId(recievedMessage);
                }
                else {
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been accepted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]}Click here 'Ok' to review counter offer or 'Cancel' to review later `,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonColor: '#0066cc',
                        cancelButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    }
                    ).then(async (result) => {
                        if (result.value === true) {
                            closeSecondaryPane();
                            fetchMainMuiDatatbleValues();
                            window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                            onAcceptCheckLoadId(recievedMessage);
                        }

                    });
                }

            }
        }
        if (isPaneOpenForSocketIO) {
            if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1]);
                fetchAllComments(
                    {
                        'load_id': recievedMessage.split('||')[1].split(':')[1],
                        'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                    }
                );
            }
        }

        // Recieve message for update bid
        if (recievedMessage.includes('BrokerLoadHasBeenWatchList') || recievedMessage.includes('BrokerLoadHasBeenWatchListRemoved')) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        if (recievedMessage.includes('LoadHasBeenRead')) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        // receiving message for new notification
        if (recievedMessage.includes('ThereIsNewNotifiction')) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                handleUpdatePostLoadTable(recievedMessage);
            }
        }

        if (recievedMessage.includes('chatHasBeenRead')) {
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        // Recieveing a message from carrier for new message
        if (recievedMessage.includes('ThereIsANewMessageFromBroker')) {
            ScrollForParticularLoad(recievedMessage);
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    fetchAllComments({
                        'load_id': recievedMessage.split('||')[1].split(':')[1],
                        'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                    }
                    );
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        if (recievedMessage.includes('YourLoadHasBennAccepted')) {
            let notificationMessage = '';
            if (recievedMessage.split('||')[2].split(':')[1] === user.email) {
                notificationMessage = 'Your bid has been accepted';
            }
            else {
                notificationMessage = 'Load has been awarded';
            }
            fetchMainMuiDatatbleValues();
            if (isPaneOpenForSocketIO) {
                Swal.fire({
                    text: notificationMessage,
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: COLORS.websiteColor
                });
            }
            else {
                isPaneOpenForSocketIO = true;
                if (isPaneOpenForSocketIO) {
                    Swal.fire({
                        text: notificationMessage,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: COLORS.websiteColor
                    });
                }
                isPaneOpenForSocketIO = false;
            }
            handleGettingConfirmationForClosingSecondaryPane();
        }

        if (recievedMessage.includes('BrokerLoadHasBeenUpdated')) {
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        if (recievedMessage.includes('BrokerLoadHasBeenDeleted') || recievedMessage.includes('BrokerLoadHasBeenExpired')) {
            let messageText = '';
            if (recievedMessage.includes('BrokerLoadHasBeenDeleted')) {
                messageText = 'Load has been deleted';
            }
            if (recievedMessage.includes('BrokerLoadHasBeenExpired')) {
                messageText = 'Load has been Expired';
            }
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleGettingConfirmationForClosingSecondaryPane();
                    Swal.fire({
                        text: messageText,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                }
            }
        }

        // Recive a message on new bid
        if (recievedMessage.includes('YourBidHasExpired')) {
            if (recievedMessage.split('||')[2].split(':')[1] === user.email) {
                fetchAllComments({
                    'load_id': recievedMessage.split('||')[1].split(':')[1],
                    'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                });
                if (isPaneOpenForSocketIO) {
                    if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                        loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1],);
                        handleUpdatePostLoadTable(recievedMessage);
                    }
                }
                else {
                    fetchMainMuiDatatbleValues();
                }
            }
        }

        if (recievedMessage.includes('Your MC Certificate Verification is Succeeded')) {
            // handleLocalStorageValue(user.email)
            dispatch(handleUserDataValue(user.email));

            fetchAllComments({
                'load_id': selectedRowDataValueForSocketIO.load_id,
                'posted_by_user': selectedRowDataValueForSocketIO.posted_by_user
            });
            loadAndRealoadSecondGrid(selectedRowDataValueForSocketIO.load_id);
        }
    };

    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
        try {
            setInterval(async () => {
                RemoveExpiredLoads();
                ExpireAndFetchAllBids();
            }, 120000);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            if (Object.keys(userReduxData.userData).length) {
                checkUserRole();
                fetchMainMuiDatatbleValues();
                ExpireAndFetchAllBids();
            }
        }
        else {
            fetchMainMuiDatatbleValues();
            ExpireAndFetchAllBids();
            checkUserRole();
        }

    }, [userReduxData]);

    //--------------------------------------------*********************--------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarSeverity === 'info' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackBarSeverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} notificationSent={isNotificationSent} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                <div style={{ height: 5 }} />
                {Prompt}
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor='#101e35' value={topTabPannelValue} onChange={handleTopTabPannelChange} classes={{ indicator: classes.indicator }} indicatorColor='primary'>
                                        {(loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' && loggedInuserRole !== 'Carrier')) &&
                                            <Tab className={`${classes.rightAlign}`} label={label} {...a11yProps(0)} disabled={(loggedInuserRole === 'Carrier' || loggedInuserCompanyRole === 'Carrier') ? true : false} />
                                        }
                                        <Tab className={`${classes.leftAlign}`} style={{ color: '#101e35' }} label='Search Load' />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Expand More">
                                        <IconButton button='true' onClick={() => handleExpandMoreIconClick()} hidden={!expandColapseTopPannel} >
                                            <ExpandMoreIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Expand Less">
                                        <IconButton button='true' onClick={() => handleExpandLessIconClick()} hidden={expandColapseTopPannel}>
                                            <ExpandLessIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            {/* ************************ firtst tab************************* */}
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandColapseTopPannel}>
                                {((loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' && loggedInuserRole !== 'Carrier'))) &&
                                    <form>
                                        <div style={{ height: 5 }} />
                                        <PostLoadForm selectedRowData={rowDataForPostLoadForm} functionality={postLoadFormFunctionality} loadNumber={loadNumber} ref={PostLoadFormRef} handleNewOrderClick={handleNewOrderClick} loadUpdatedStatus={loadUpdatedStatus} updatedUser={user.email} />
                                        <div style={{ height: 10 }} />
                                        <Grid container item xs={12} justify='flex-end'>
                                            <Tooltip arrow title='Ctrl+Enter'>
                                                <Button onClick={handleNewOrderClick} variant='contained' hidden={showing} disabled={disablePostLoadButton} style={{ marginRight: 20, textTransform: 'none' }}>Post New Load</Button>
                                            </Tooltip>
                                            <Button onClick={clearPostLoadForm} variant='contained' hidden={showing} disabled={disableClearFormButton} style={{ marginRight: 20, textTransform: 'none' }}>Clear</Button>
                                            <Button onClick={onCancelRePostClick} variant='contained' hidden={!showing} disabled={disableCancelButton} style={{ marginRight: 20, textTransform: 'none' }}>Cancel</Button>
                                            <Button onClick={handleRepostChanges} variant='contained' hidden={!showing} disabled={disableUpdateLoadButton} style={{ marginRight: 20, textTransform: 'none' }}>Update Load</Button>
                                        </Grid>
                                    </form>
                                }
                            </TabPanel>
                            {/* ***************************** Second Tab******************************* */}
                            <TabPanel value={topTabPannelValue} index={1} hidden={expandColapseTopPannel}>
                                <div style={{ height: 5 }} />
                                <SearchForm isPaneOpen={isPaneOpen} userMCnumber={loggedInCompanyMCNumber} userCompanyName={userCompanyName} closeSecondaryPane={closeSecondaryPane} ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPicesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={counteropen}
                    onClose={handleClose}
                >
                    <DialogTitle>Counter Offer ($)</DialogTitle>
                    <DialogContent>
                        <Box
                            noValidate
                            component='form'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <div style={{ padding: '2px 12px' }}>
                                <TextField
                                    placeholder='Counter Amount'
                                    autoComplete='off'
                                    value={counterOffer}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    size='large'
                                    onChange={handleKeyPress}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <AttachMoney fontSize='inherit'
                                                    style={{ fontSize: '20px', marginBottom: 15, marginTop: 15, display: 'inline' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: 'flex', marginTop: '6%', justifyContent: 'end', marginRight: '5%' }}>
                            <button className='counterOfferSubmitBtn' onClick={SubmitCounterOffer}>
                                Submit
                            </button>
                            <button className='counterOfferCancelBtn' onClick={CancelCounterOffer}>
                                Cancel
                            </button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={counteropencarrier}
                    onClose={handleClose}
                >
                    <Grid container item xs={12} justify='flex-end'>
                        <Tooltip title="Close">
                            <IconButton onClick={CloseForm}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <DialogTitle style={{ paddingTop: 0 }}>Counter Offer ($)</DialogTitle>
                    <DialogContent>
                        <Box
                            noValidate
                            component='form'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <div style={{ padding: '2px 12px' }}>
                                <TextField
                                    placeholder='Counter Amount'
                                    autoComplete='off'
                                    value={counterOffer}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    size='large'
                                    onChange={handleKeyPress}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <AttachMoney fontSize='inherit'
                                                    style={{ fontSize: '20px', marginBottom: 15, marginTop: 15, display: 'inline' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: 'flex', marginTop: '6%', justifyContent: 'end', marginRight: '5%' }}>
                            {(counterOffer !== selectedRowDataValue.counter_offer) ?
                                <button className='counterOfferSubmitBtn' onClick={SubmitCounterOffer}>
                                    Update
                                </button>
                                :
                                <Button style={{ textTransform: 'capitalize', borderRadius: 5, width: maxWidth, color: '#7d7d7d', background: '#ccc', borderColor: '#7c7c7c' }} variant='outlined' disabled>Update</Button>
                            }
                            {
                                (counterOffer === selectedRowDataValue.counter_offer && ((selectedRowDataValue.counteroffer_sent === 'broker' && loadPostedByUser !== user.email) || (selectedRowDataValue.counteroffer_sent === 'carrier' && loadPostedByUser === user.email))) ?
                                    <button className='counterOfferAcceptBtn' onClick={AcceptCounterOfferCarrier} >
                                        Accept
                                    </button>
                                    :
                                    <Button style={{ textTransform: 'capitalize', borderRadius: 5, width: maxWidth, color: '#7d7d7d', background: '#ccc', borderColor: '#7c7c7c' }} variant='outlined' disabled>Accept</Button>
                            }
                        </div>
                    </DialogActions>
                </Dialog>
                <Typography component={'div'}>
                    <Grid container direction='row' margin={2}>
                        <div style={{ width: mainGridPercent, paddingBottom: '35%', display: 'table', tableLayout: 'fixed' }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'Posted Loads'}
                                    data={rowData}
                                    columns={columnDefsLoadTable}
                                    options={options}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {
                            isPaneOpen && (
                                <React.Fragment>
                                    <div style={{ width: '1%' }}></div>
                                    <div style={{ width: '64%' }} border={1}>
                                        <Box
                                            boxShadow={3}>
                                            <Paper square className='square'>
                                                <Grid container item xs={12} justify='flex-end'>
                                                    {((loggedInuserRole !== 'Carrier' && loadPostedByUser === user.email) || (loggedInuserCompanyRole === 'Carrier' && loadPostedByUser === user.email)) && (
                                                        <React.Fragment>
                                                            <Tooltip title="Delete Load">
                                                                <IconButton onClick={ConfirmationOnDeleteLoad}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit Load">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        handleEditButtonClick(selectedRow);
                                                                    }}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    )}
                                                    {
                                                        (loggedInuserCompanyRole !== 'Broker' && loggedInuserRole !== 'Broker' && loadPostedByUser !== user.email && user.email !== process.env.REACT_APP_ADMIN_USER) &&
                                                        (
                                                            <React.Fragment>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Tooltip title="Help">
                                                                        <IconButton onClick={() => { ShowIntro('Details'); }}>
                                                                            < HelpOutlineIcon style={{ fontSize: 17, textShadow: 2 }} ></HelpOutlineIcon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <div id='SaveLane'>
                                                                        <IconButton hidden={loadSavedLane} onClick={handleSavedLaneClick}>
                                                                            <Tooltip title='Save Lane'>
                                                                                <BookmarkBorderIcon />
                                                                            </Tooltip>
                                                                        </IconButton>
                                                                        <IconButton hidden={!loadSavedLane} onClick={handleRemoveSavedLaneClick}>
                                                                            <Tooltip title='Remove SavedLane'>
                                                                                <BookmarkIcon style={{ fill: '#0BDA51' }} />
                                                                            </Tooltip>
                                                                        </IconButton>
                                                                    </div>
                                                                    {/* <IconButton hidden={loadWatchLoad} onClick={handleWatchLoadClick}>
                                                                        <Tooltip title='Watch Load'>
                                                                            <StarBorderIcon />
                                                                        </Tooltip>
                                                                    </IconButton> */}
                                                                    <IconButton hidden={!loadWatchLoad} onClick={handleStopWatchLoadClick}>
                                                                        <Tooltip title='Stop Watching'>
                                                                            <StarIcon style={{ fill: '#ffe007' }} />
                                                                        </Tooltip>
                                                                    </IconButton>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    <div id='CloseLoadDetail'>
                                                        <Tooltip title="Close">
                                                            <IconButton onClick={handleGettingConfirmationForClosingSecondaryPane}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </div>
                                                    <Grid item style={{ fontSize: 14 }} xs={12}>
                                                        <Grid container direction='row' id='LoadDetails'>
                                                            <Grid item xs={6}>
                                                                <Row style={{ fontSize: 18 }}>
                                                                    <Col sm><b>Load Details</b></Col>
                                                                    <Col style={{ marginRight: 15 }} sm></Col>
                                                                </Row>
                                                                <div style={{ height: 7 }} />
                                                                <Row>
                                                                    <Col sm>Load ID:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>{loadNumberDetail}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                                                                    <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadPickUpDestination}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Pick-Up Window:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>{loadPickUpWindow}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Pick-Up Date:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>{loadPickUpdate}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                                                                    <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadDeliverDestination}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Delivery Window:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>{loadDeliverWindow}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Delivery Date:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>{loadDeliverByDate}</Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={{ fontWeight: 'bold' }}>Weight:</Col>
                                                                    <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>
                                                                        {loadMaxWeight}
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    (loadPostedByUser !== user.email) && (
                                                                        <React.Fragment>
                                                                            <div style={{ height: 15 }} />
                                                                            <Row>
                                                                                <Col sm>Status:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm >{loadStatus}</Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div style={{ height: 33 }} />
                                                                <Row>
                                                                    <Col sm style={{ fontWeight: 'bold' }}>Approximate Distance <div>(Drive Time):</div></Col>
                                                                    <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm >{loadCityDistance}</Col>
                                                                </Row>
                                                                <div style={{ height: 5 }} />
                                                                <Row>
                                                                    <Col sm style={{ fontWeight: 'bold' }}>Number Of Pieces:</Col>
                                                                    <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>
                                                                        {loadNoOfPieces}
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ height: 5 }} />
                                                                <Row>
                                                                    <Col sm>Freight Dimension:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm >{loadFreightDimension}</Col>
                                                                </Row>
                                                                {
                                                                    (loadPostedByUser === user.email) ?
                                                                        <div style={{ height: 5 }} />
                                                                        :
                                                                        <div style={{ height: 15 }} />
                                                                }
                                                                <Row>
                                                                    <Col sm>Truck Type:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                        {loadLoadType}
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <TextField
                                                                    label='Load Notes'
                                                                    autoComplete='off'
                                                                    multiline
                                                                    rows={6}
                                                                    paddingTop={3}
                                                                    variant='outlined'
                                                                    style={{
                                                                        width: '97%',
                                                                    }}
                                                                    value={loadLoadNotes}
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {
                                                                    (loadPostedByUser === user.email) &&
                                                                    (
                                                                        <React.Fragment>
                                                                            <Grid style={{ paddingTop: '2%', paddingBottom: '2%' }}>
                                                                                <Box className='heightFix'
                                                                                    color='#101e35' border={0} padding={2} paddingTop={'3%'} >
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <h6><b>Load Expiration Time</b></h6>
                                                                                    </div>
                                                                                    <Row id='loadexptime'>
                                                                                        <Col xs={4} className='setCloseButtonSm3'>
                                                                                            <MUIAutoComplete
                                                                                                disabled={postLoadFormFunctionality === 'Edit Load'}
                                                                                                onClose={() => loadHandleLoadExpirationCloseForHour()}
                                                                                                //freeSolo
                                                                                                disableClearable
                                                                                                value={loadUpdateLoadExpirationTimeForHour}
                                                                                                onChange={(event, newValue) => {
                                                                                                    handleSetLoadExpirationOnchangeValueForHour(event, newValue);
                                                                                                }}
                                                                                                inputValue={loadUpdateLoadExpirationTimeForHour}
                                                                                                onInputChange={(event, newInputValue) =>
                                                                                                    handleSetLoadExpirationOnInputchangeValueForHour(event, newInputValue)
                                                                                                }
                                                                                                options={hourValue}
                                                                                                style={{ marginTop: 10, width: '90%', marginLeft: 20 }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField {...params} variant='outlined' label='Select Hour' />
                                                                                                )}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col xs={4} className='expirationButton'>
                                                                                            <MUIAutoComplete
                                                                                                disabled={postLoadFormFunctionality === 'Edit Load'}
                                                                                                onClose={() => loadHandleLoadExpirationCloseForMin()}
                                                                                                //freeSolo
                                                                                                disableClearable
                                                                                                value={loadUpdateLoadExpirationTimeForMin}
                                                                                                onChange={(event, newValue) => {
                                                                                                    handleSetLoadExpirationOnchangeValueForMin(event, newValue);
                                                                                                }}
                                                                                                inputValue={loadUpdateLoadExpirationTimeForMin}
                                                                                                onInputChange={(event, newInputValue) =>
                                                                                                    loadHandleLoadExpirationInputChangeForMin(event, newInputValue)
                                                                                                }
                                                                                                options={minutes}
                                                                                                style={{ width: '90%', marginTop: 10, marginLeft: 20 }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField {...params} variant='outlined' label='Select Minute' />
                                                                                                )}
                                                                                            />
                                                                                        </Col>
                                                                                        <div>
                                                                                            <Button disabled={enableSetButton} style={enableSetButton !== true ? { marginTop: 20, marginLeft: 12, width: '20%', textTransform: 'none', backgroundColor: '#101e35', color: 'white' } : { marginTop: 20, marginLeft: 12, width: '20%', textTransform: 'none', color: 'white' }} variant='contained' size='medium' onClick={handleUpdateLoadExpirationUpdateChnage}>
                                                                                                Set
                                                                                            </Button>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Button style={{ marginTop: 20, marginLeft: 12, width: '20%', textTransform: 'none', backgroundColor: '#101e35', color: 'white' }} variant='contained' size='medium' onClick={handleLoadExpireNow}>
                                                                                                Close
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Row>
                                                                                </Box>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                {
                                                                    ((((loggedInuserRole === 'CompanyAdmin' && loggedInuserCompanyRole !== 'Broker') || (loggedInuserRole === 'Carrier') || (loggedInuserRole === 'Broker&Carrier')) && loadPostedByUser !== user.email) && (user.email !== process.env.REACT_APP_ADMIN_USER)) && (
                                                                        <React.Fragment>
                                                                            <div style={{ height: 7 }} />
                                                                            <Grid item style={{ marginBottom: 10 }} xs={12} >
                                                                                <Box className='color' boxShadow={3}
                                                                                    style={{ width: '99%', height: '350%', backgroundColor: '#ececec' }}
                                                                                    color='#101e35' border={0} padding={2} paddingTop={'2%'}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 1 }}>
                                                                                        <h6><b>Bid Now</b></h6>
                                                                                        <Tooltip title="Help">
                                                                                            <IconButton style={{ paddingTop: '1%', paddingBottom: '2%' }} onClick={() => { ShowIntro('Bid'); }}>
                                                                                                < HelpOutlineIcon style={{ fontSize: 17, textShadow: 2 }} ></HelpOutlineIcon>
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <Grid item xs={12}>
                                                                                        <Row style={{ marginLeft: '3%' }}>
                                                                                            <Col>
                                                                                                <Row>
                                                                                                    <TextField
                                                                                                        id='txtFieldBidAmount'
                                                                                                        value={loadBidAmount}
                                                                                                        autoComplete='off'
                                                                                                        inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                                                                                        onChange={(val) => updateLoadBidAmount(val)}
                                                                                                        disabled={selectedRowDataValue.counter_offer}
                                                                                                        style={{ marginRight: '2%', width: '29%' }}
                                                                                                        variant='outlined'
                                                                                                        label='Amount * ($)'
                                                                                                        error={(errors.loadBidAmount && loadBidAmount === '') || loadBidAmount === '0'}
                                                                                                    />
                                                                                                    <Steps
                                                                                                        enabled={enabled}
                                                                                                        steps={guide}
                                                                                                        initialStep={0}
                                                                                                        onExit={onExit}
                                                                                                    />
                                                                                                    <MUIAutoComplete
                                                                                                        id='minutes'
                                                                                                        onClose={() => loadHandleBidExpirationCloseForMin()}
                                                                                                        //freeSolo
                                                                                                        disableClearable
                                                                                                        disabled={selectedRowDataValue.counter_offer}
                                                                                                        value={bidExpiration}
                                                                                                        onChange={(event, newValue) => {
                                                                                                            handleSetBidExpirationOnchangeValueForMin(event, newValue);
                                                                                                        }}
                                                                                                        inputValue={bidExpiration}
                                                                                                        onInputChange={(event, newInputValue) =>
                                                                                                            loadHandleBidExpirationChangeForMin(event, newInputValue)
                                                                                                        }
                                                                                                        options={minutes}
                                                                                                        style={{ width: '29%', marginRight: '2%' }}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField {...params} variant='outlined' label='Select Minute' style={{ height: 50 }} />
                                                                                                        )}
                                                                                                    />
                                                                                                    {

                                                                                                        <TextField
                                                                                                            id='MilesOut'
                                                                                                            label='Miles Out'
                                                                                                            variant='outlined'
                                                                                                            placeholder='miles'
                                                                                                            value={txtMilesOut}
                                                                                                            autoComplete='off'
                                                                                                            error={txtMilesOut < 0}
                                                                                                            helperText={txtMilesOut < 0 ? 'Not Valid Input' : ''}
                                                                                                            inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                            onChange={handelTxtMilesOutChange}
                                                                                                            style={{ width: '29%' }}
                                                                                                            disabled={selectedRowDataValue.counter_offer}
                                                                                                        />
                                                                                                    }
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <TextField
                                                                                                    id='BidNotes'
                                                                                                    label='Bid Notes'
                                                                                                    multiline
                                                                                                    rows={1}
                                                                                                    variant='outlined'
                                                                                                    autoComplete='off'
                                                                                                    style={{ width: '90%', margin: 10, backgroundColor: '#ffffff', marginLeft: '3%', marginRight: '2%' }}
                                                                                                    value={BidNotes}
                                                                                                    onChange={(val) => updateBidNotes(val.target.value)}
                                                                                                    disabled={selectedRowDataValue.counter_offer}
                                                                                                    placeholder="Maximum 100 characters"
                                                                                                    inputProps={{ maxLength: 100 }}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Row id='truckDimension' style={{ marginLeft: '3%' }}>
                                                                                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                                <p style={{ fontSize: 13, marginBottom: 0 }}> Truck Dimension (Inch) </p>
                                                                                                <TextField
                                                                                                    label='Length'
                                                                                                    variant='outlined'
                                                                                                    value={txtLength}
                                                                                                    autoComplete='off'
                                                                                                    style={(!loadBidAmountDisable) ? { width: '22%' } : { width: '16%' }}
                                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                    onChange={handletxtLength}
                                                                                                    disabled={selectedRowDataValue.counter_offer}
                                                                                                />
                                                                                                <Tooltip title="Close">
                                                                                                    <IconButton style={{ padding: '0%', width: '5%', top: 8 }} disabled={true}>
                                                                                                        <CloseIcon className='closeIcon'
                                                                                                            fontSize='small'
                                                                                                            style={{ marginBottom: 10, padding: 0 }}
                                                                                                        />
                                                                                                    </IconButton>
                                                                                                </Tooltip>

                                                                                                <TextField
                                                                                                    label='Width'
                                                                                                    variant='outlined'
                                                                                                    value={txtWidth}
                                                                                                    autoComplete='off'
                                                                                                    style={(!loadBidAmountDisable) ? { width: '22%' } : { width: '16%' }}
                                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                    onChange={handletxtWidth}
                                                                                                    disabled={selectedRowDataValue.counter_offer}
                                                                                                />
                                                                                                <Tooltip title="Close">
                                                                                                    <IconButton style={{ padding: '0%', width: '5%', top: 8 }} disabled={true}>
                                                                                                        <CloseIcon className='closeIcon'
                                                                                                            fontSize='small'
                                                                                                            style={{ marginBottom: 10, padding: 0 }}
                                                                                                        />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                                <TextField
                                                                                                    label='Height'
                                                                                                    variant='outlined'
                                                                                                    value={txtHeight}
                                                                                                    autoComplete='off'
                                                                                                    style={(!loadBidAmountDisable) ? { width: '21%' } : { width: '16%' }}
                                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                    onChange={handletxtHeight}
                                                                                                    disabled={selectedRowDataValue.counter_offer}
                                                                                                />
                                                                                                <Button className='buttonColor' style={(loadBidAmount.toString().trim().replace(' ', '') !== '' && loadBidAmount !== '' && parseInt(loadBidAmount) !== 0) ? { marginLeft: '2%', height: 55, textTransform: 'none', backgroundColor: '#101e35' } : { textTransform: 'none', marginLeft: '2%', height: 55, backgroundColor: '#9e9e9ec2' }} variant='contained' size='medium' hidden={loadBidAmountDisable} disabled={!loadBidAmount} onClick={bidForALoad}>
                                                                                                    Bid Now
                                                                                                </Button>
                                                                                                <Button style={{ marginLeft: '2%', width: '12%', color: 'white', height: 55, textTransform: 'none', backgroundColor: '#101e35' }} variant='contained' size='medium' color='primary' hidden={!loadBidAmountDisable} onClick={cancelBid}>
                                                                                                    Close Bid
                                                                                                </Button>
                                                                                                <Button className='buttonColor' style={reBidBtnStyle ? { marginLeft: '2%', height: 55, textTransform: 'none', backgroundColor: '#101e35' } : { textTransform: 'none', marginLeft: '2%', height: 55, backgroundColor: '#9e9e9ec2' }} variant='contained' size='medium' hidden={!loadBidAmountDisable} disabled={isDisabled} onClick={changeBidForLoad}>
                                                                                                    Change Bid
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </Grid>
                                                            <Grid container direction='row'>
                                                                <Grid item xs={6}>
                                                                    {
                                                                        (loadPostedByUser === user.email) ?
                                                                            <div style={{ height: 5 }} />
                                                                            :
                                                                            <div style={{ height: 15 }} />
                                                                    }
                                                                    <Row>
                                                                        <Col sm>Stackable:</Col>
                                                                        <Col style={{ marginRight: 15 }} sm>
                                                                            {toShowIcon(loadStackable)}
                                                                        </Col>
                                                                    </Row>
                                                                    <div style={{ height: 15 }} />
                                                                    <Row>
                                                                        <Col sm>Hazmat:</Col>
                                                                        <Col style={{ marginRight: 15 }} sm>
                                                                            {toShowIcon(loadHazmat)}
                                                                        </Col>
                                                                    </Row>
                                                                    <div style={{ height: 15 }} />
                                                                    <Row>
                                                                        <Col sm>Dock High:</Col>
                                                                        <Col style={{ marginRight: 15 }} sm>
                                                                            {toShowIcon(loadDockHigh)}
                                                                        </Col>
                                                                    </Row>
                                                                    <div style={{ height: 15 }} />
                                                                    <Row>
                                                                        <Col sm>Temperature Control:</Col>
                                                                        <Col style={{ marginRight: 15 }} sm>
                                                                            {toShowIcon(loadTempControl)}
                                                                        </Col>
                                                                    </Row>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {
                                                                        <div>
                                                                            {
                                                                                (loadPostedByUser === user.email) ?
                                                                                    <div style={{ height: 5 }} />
                                                                                    :
                                                                                    <div style={{ height: 18 }} />
                                                                            }
                                                                            <Row>
                                                                                <Col sm>Posted By:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>{loadPostedBy}</Col>
                                                                            </Row>
                                                                            <div style={{ height: 18 }} />
                                                                            <Row>
                                                                                <Col sm>Posted By User:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>{loadPostedByUser}</Col>
                                                                            </Row>
                                                                            <div style={{ height: 18 }} />
                                                                            <Row>
                                                                                <Col sm>Load Posted at:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm>{loadPostedTime}</Col>
                                                                            </Row>
                                                                            <div style={{ height: 18 }} />
                                                                            <Row>
                                                                                <Col sm>Load Expires In:</Col>
                                                                                <Col style={{ marginRight: 15 }} sm >{
                                                                                    <Countdown onComplete={() => RemoveExpiredLoads()} autoStart={true} date={Date.parse(loadPostedTime) + parseInt(loadExpiresIn)}>
                                                                                    </Countdown>
                                                                                }</Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <div style={{ marginTop: '25%' }} />
                                                            <div id='Mapguide' style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
                                                                <div ><div>
                                                                    {mapUpdate && <Map data={{ 'fromlatitude': mapDistance.pickuplat, 'fromlongitude': mapDistance.pickuplng, 'tolatitude': mapDistance.deliverylat, 'tolongitude': mapDistance.deliverylng }}
                                                                        loadingElement={<div style={{ height: '100%' }} />}
                                                                    />}
                                                                </div></div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Box>
                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        {(loadPostedByUser === user.email || loggedInuserCompanyRole === 'Carrier' || loggedInuserCompanyRole === 'Broker&Carrier' || user.email === process.env.REACT_APP_ADMIN_USER) && (
                                            <React.Fragment>
                                                <div style={{ height: 10 }} />
                                                <div id='activeBids'>
                                                    <MUIDataTable
                                                        title={'Active Bids'}
                                                        data={rowDataActiveBidTable}
                                                        columns={columnDefsBidTable}
                                                        options={optionsBidTable}
                                                    ></MUIDataTable>
                                                </div>
                                                <div style={{ height: 10 }} />
                                                <div id='closeBids'>
                                                    <MUIDataTable
                                                        title={'Closed Bids'}
                                                        data={rowDataClosedBidTable}
                                                        columns={columnDefsClosedBidTable}
                                                        options={optionsClosedBidTable}
                                                    ></MUIDataTable>
                                                </div>
                                            </React.Fragment>
                                        )
                                        }
                                        <div style={{ height: 10 }} />
                                        {(loadPostedByUser === user.email ||
                                            ((loggedInuserCompanyRole === 'Carrier') || user.email === process.env.REACT_APP_ADMIN_USER ||
                                                (loggedInuserCompanyRole === 'Broker' && loadPostedByUser === user.email) ||
                                                (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin' || loggedInuserRole === 'Carrier'))
                                            )) &&
                                            (
                                                <React.Fragment>
                                                    <Divider />
                                                    <Grid>
                                                        <Box boxShadow={3}>
                                                            <Paper square className='paper' id='chatguide'>
                                                                <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor='primary' textColor='primary'>
                                                                    <Tab label='Comments' />
                                                                </Tabs>
                                                                <TabPanel value={tabPannerlValue} index={0}>
                                                                    <React.Fragment>
                                                                        <div className='homePage'>
                                                                            <div className='leftSide'>
                                                                                <Header />
                                                                                {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                                {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                    <List className='Custom-MuiList-root' style={{ maxHeight: 'calc(100vh - 109px)', overflow: 'auto', objectFit: 'cover', height: 415 }}>
                                                                                        {
                                                                                            allChat
                                                                                                .sort(function (b, a) {
                                                                                                    return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                                }).sort(function (x, y) {
                                                                                                    return x.pinned_user === true ? -1 : y.pinned_user === true ? 1 : 0;
                                                                                                }).map((item, i) => {
                                                                                                    return (loggedInuserRole === 'Broker' || (selectedRowDataValue.posted_by_user === user.email)) ?
                                                                                                        <ListItem button alignItems='flex-start' id={item.carrier_id} value={item.carrier_id} className='Custom-MuiListItem-root' key={item.carrier_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                            {
                                                                                                                (item.carrier_id !== undefined) && (
                                                                                                                    <div id={item.carrier_id} className='discussion'>
                                                                                                                        <div id={item.carrier_id} className='leftDiscussion'>
                                                                                                                            {
                                                                                                                                (item.carrier_id.charAt(0) !== undefined) && (
                                                                                                                                    <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div id={item.carrier_id} className='rightDiscussion'>
                                                                                                                            <div id={item.carrier_id} className='rightDiscussionTop'>
                                                                                                                                <span id={item.carrier_id} className='rightDiscussionTopLeft'>{item.carrier_company}</span>
                                                                                                                            </div>
                                                                                                                            <div id={item.carrier_id} className='rightDiscussionBas'>
                                                                                                                                {
                                                                                                                                    (item.bid_amount !== undefined && item.bid_amount !== '$') && (
                                                                                                                                        <span style={{ paddingTop: '10px', paddingRight: '2%' }} className='rightDiscussionTopRight'>{'Bid: ' + item.bid_amount || loadBidAmount}</span>
                                                                                                                                    )

                                                                                                                                }
                                                                                                                                {(item.mc_certified !== 'Verified') && (
                                                                                                                                    <Tooltip title='Not Verified Company'>
                                                                                                                                        <IconButton>
                                                                                                                                            <NotVerifiedUserIcon />
                                                                                                                                        </IconButton>

                                                                                                                                    </Tooltip>
                                                                                                                                )}
                                                                                                                                {(item.pinned_user === false ?
                                                                                                                                    <Tooltip title='Pin'>
                                                                                                                                        <IconButton id={item.chat_id} onClick={() => pinChat(item)} className='pin_btn'>
                                                                                                                                            <Pinned />
                                                                                                                                        </IconButton>
                                                                                                                                    </Tooltip>
                                                                                                                                    :
                                                                                                                                    <Tooltip title='Unpin'>
                                                                                                                                        <IconButton id={item.chat_id} onClick={() => pinChat(item)} className='pin_btn'>
                                                                                                                                            <UnPinned />
                                                                                                                                        </IconButton>
                                                                                                                                    </Tooltip>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        </ListItem>
                                                                                                        :
                                                                                                        <ListItem button alignItems='flex-start' id={item.broker_id} value={item.broker_id} className='Custom-MuiListItem-root' key={item.broker_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                            {
                                                                                                                (item.broker_id !== undefined) ?
                                                                                                                    <div id={item.broker_id} className='discussion'>
                                                                                                                        <div id={item.broker_id} className='leftDiscussion'>
                                                                                                                            {
                                                                                                                                (item.broker_id.charAt(0) !== undefined) && (
                                                                                                                                    <Avatar>{item.broker_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div id={item.broker_id} className='rightDiscussion'>
                                                                                                                            <div id={item.broker_id} className='rightDiscussionTop'>
                                                                                                                                <div id={item.broker_id} className='rightDiscussionTopLeft'>{item.broker_company || selectedRowDataValue.posted_by_company}</div>
                                                                                                                            </div>
                                                                                                                            <div id={item.broker_id} className='rightDiscussionBas'>
                                                                                                                                {
                                                                                                                                    (item.bid_amount !== undefined && item.bid_amount !== '$') && (
                                                                                                                                        <span style={{ paddingTop: '10px' }} className='rightDiscussionTopRight'>{'Bid: ' + item.bid_amount || loadBidAmount}</span>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div id={selectedRowDataValue.posted_by_user} className='discussion'>
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className='leftDiscussion'>
                                                                                                                            {
                                                                                                                                (selectedRowDataValue.posted_by_user.charAt(0) !== undefined) && (
                                                                                                                                    <Avatar>{selectedRowDataValue.posted_by_user.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className='rightDiscussion'>
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionTop'>
                                                                                                                                <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionTopLeft'>{selectedRowDataValue.posted_by_company}</div>
                                                                                                                            </div>
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionBas'>
                                                                                                                                {
                                                                                                                                    (selectedRowDataValue.bid_amount !== undefined && selectedRowDataValue.bid_amount !== '$') && (
                                                                                                                                        <div className='rightDiscussionTopRight'>{'Bid: ' + selectedRowDataValue.bid_amount || loadBidAmount}</div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </ListItem>;
                                                                                                }
                                                                                                )
                                                                                        }
                                                                                    </List>
                                                                                )}
                                                                            </div>
                                                                            <div className='rightSide'>
                                                                                {
                                                                                    (chatFirstCarrierName !== undefined) && (
                                                                                        <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf('@'))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                    )
                                                                                }
                                                                                <Chat discussion={chatAllMessages} />
                                                                                <div>
                                                                                    <form onKeyPress={(e) => { onEnterButtonForChat(e); }}>
                                                                                        <Paper component='form' className={classes.customPaper}>
                                                                                            <InputBase id='inputMesage' autoComplete='off' className={classes.CustomInput} style={{ width: '85%' }} placeholder='Enter your message' />
                                                                                            <Tooltip title="Send">
                                                                                                <IconButton onClick={() => handleOnSendMessageClick()}>
                                                                                                    <SendIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Paper>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </TabPanel>
                                                            </Paper>
                                                        </Box>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main>
        </div >
    );
};
export default LandingPage;