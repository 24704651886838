import React from 'react';
export const Completed = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="done" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="5152465001634472862" transform="translate(2.000000, 2.000000)" fill="#000000" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="0.3">
                    <path d="M9.77635179,18.8027428 L9.48755468,18.9472592 L0.999985197,15.1750203 L0.999985197,5.2693422 L9.29686702,8.95687759 C9.42619402,9.01433721 9.57380598,9.01433721 9.70313298,8.95687759 L18.0000148,5.2693422 L18.0000148,9.49997425 C18.0026703,9.7742251 18.2257449,9.99515128 18.5,9.99515128 C18.7742699,9.99515128 18.9973445,9.7742251 19,9.49997425 L19,4.49993183 C19,4.30236705 18.8836526,4.12333128 18.7031404,4.04303876 L9.70313298,0.0430329542 C9.57378852,-0.0143443181 9.42621148,-0.0143443181 9.29686702,0.0430329542 L0.296859619,4.04303876 C0.116347406,4.12333128 0,4.30236705 0,4.49993183 L0,15.5 C0,15.6975344 0.116361409,15.8765252 0.296859619,15.9567588 L9.29686702,19.9567646 C9.43331299,20.0175328 9.58978548,20.0140938 9.72342987,19.9473896 L10.2234459,19.6974039 C10.3842805,19.6181035 10.4903413,19.4587546 10.5014153,19.2797744 C10.5124894,19.1007942 10.4268813,18.9295861 10.2770492,18.8310636 C10.1272171,18.732541 9.93610167,18.7217888 9.77616429,18.8028835 L9.77635179,18.8027428 Z M9.49978906,7.95280971 L5.9625049,6.38056691 L13.7031206,2.94034535 C13.7120269,2.93636096 13.7194332,2.93054842 13.7279644,2.92628278 L17.268858,4.49993183 L9.49978906,7.95280971 Z M9.49978906,1.04700708 L12.5003072,2.38051423 L4.79686332,5.80428257 C4.77702634,5.8139253 4.75785832,5.82488746 4.73948827,5.83709525 L1.7312358,4.49993183 L9.49978906,1.04700708 Z" id="Shape"></path>
                    <path d="M15.0001199,10 C12.977834,10 11.1546648,11.2181621 10.3807564,13.0865052 C9.60684791,14.9548484 10.0346089,17.1054138 11.4645743,18.5353926 C12.8945397,19.9653714 15.045101,20.3931525 16.9134514,19.6192616 C18.7818018,18.8453706 20,17.0222129 20,14.9999269 C19.996926,12.2398361 17.7602107,10.0030999 15.0001199,10 Z M15.0001199,18.9998403 C12.7910288,18.9998403 11.0002065,17.2090181 11.0002065,14.9999269 C11.0002065,12.7908357 12.7910288,11.0000135 15.0001199,11.0000135 C17.2092111,11.0000135 19.0000334,12.7908357 19.0000334,14.9999269 C18.9975019,17.2079687 17.2081617,18.9973089 15.0001199,18.9998403 L15.0001199,18.9998403 Z" id="Shape"></path>
                    <path d="M14.8463854,15.9641866 L13.9027077,15.1948611 C13.770285,15.084387 13.575243,15.0404691 13.3920417,15.0798739 C13.2088405,15.1192786 13.065761,15.2359229 13.017426,15.3852762 C12.9690909,15.5346295 13.0229619,15.6936359 13.1584728,15.8015924 L14.4742927,16.8743036 C14.5729696,16.9547838 14.7068298,17 14.8464101,17 C14.9859904,17 15.1198506,16.9547838 15.2185275,16.8743036 L17.850118,14.7289214 C18.0516103,14.5608256 18.049709,14.291858 17.8458536,14.1256666 C17.6419982,13.9594751 17.3120745,13.9579251 17.1058831,14.1221901 L14.8463854,15.9641866 Z" id="Path"></path>
                </g>
            </g>
        </svg>
    );
};
