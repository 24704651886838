import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    CssBaseline,
    Avatar,
    Paper,
    Box,
} from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import './index.css';
import { useAuth0 } from '@auth0/auth0-react';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import MUIDrawer from '../../components/Drawer';
import { pascalCase } from '../../utils';
import { getAllUserInformationApiCall } from '../../apis/user';

import FloatingButtonForFeedBack from '../../staticPages/FloatingButtonForFeedBack';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 10,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1)
    }

}));

const UserProfile = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();

    // All variables
    const [user_id, setUser_id] = React.useState('');
    const [user_role, setUser_role] = React.useState('');
    const [user_name, setUser_name] = React.useState('');
    const [user_first_name, setUser_first_name] = React.useState('');
    const [user_last_name, setUser_last_name] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [mc_number, setMc_number] = React.useState('');
    const [phone_number, setPhone_number] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [companyRole, setCompanyRole] = useState('');


    // on page load event
    useEffect(() => {
        (async () => {
            try {
                let alluserinformationdata = await getAllUserInformationApiCall();
                if (alluserinformationdata !== undefined) {
                    const findUser = alluserinformationdata.find(
                        ({ email_address }) => email_address === user.email
                    );
                    setUser_id(findUser.user_id);
                    setUser_name((findUser.user_name));
                    setUser_first_name(findUser.first_name);
                    setUser_last_name(findUser.last_name);
                    if ((findUser.login_status) === 'ProfileAccepted') {
                        if (findUser.mobile_number) {
                            setPhone_number(findUser.mobile_number);
                        }
                        if (findUser.address) {
                            setAddress(findUser.address);
                        }
                        if (findUser.city) {
                            setCity(findUser.city);
                        }
                        if (findUser.state) {
                            setState(findUser.state);
                        }
                        if (findUser.country) {
                            setCountry(findUser.country);
                        }
                        if (findUser.company_name) {
                            setCompanyName(findUser.company_name);
                        }
                        if (findUser.company_mc_number) {
                            setMc_number(findUser.company_mc_number);
                        }
                        if (findUser.user_role) {
                            setUser_role(findUser.user_role);
                        }
                        if (findUser.company_role) {
                            setCompanyRole(findUser.company_role);
                        }
                    }
                }
            }
            catch (error) {
                console.log(error, 'Get all user error in user profile');
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    history.push({ pathname: '/callback' });
                }
            }
        })();
    }, [user, history]);


    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <MUIDrawer />
                <div className='marginTop'>
                    <Paper square className ={user.email !== process.env.REACT_APP_ADMIN_USER ? 'profilePaper'  : 'profilePaperForAdmin' }>
                        <main className={classes.content}>
                            <Row className='marginTop'>
                                <h4 className='profileLeft'>User Profile</h4>
                                <Avatar className="MuiAvatar-square" src={user.picture}></Avatar>
                            </Row>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid container direction="row" className="marginTop">
                                    <Grid item xs={6}>
                                        <Row className='marginForRow'>
                                            <Col sm><h5>Name</h5></Col>
                                        </Row>
                                        <Row className='marginForRow'>
                                            <Col sm>
                                                {user_first_name || user_last_name ?
                                                    <TextField
                                                        value={pascalCase(user_first_name) + ' ' + pascalCase(user_last_name)}
                                                        inputProps={{ readOnly: true, disabled: true, }} className='profileTextBoxWidth' variant="outlined" />
                                                    :
                                                    <TextField value={user.email} inputProps={{ readOnly: true, disabled: true, }} className='profileTextBoxWidth' variant="outlined" />}
                                            </Col>
                                        </Row>
                                        <Row className='email'>
                                            <Col sm><h5>Email ID</h5></Col>
                                        </Row>
                                        <Row className='marginForRow'>
                                            <Col sm>
                                                <TextField value={user.email} inputProps={{ readOnly: true, disabled: true, }} className="profileTextBoxWidth" variant="outlined" />
                                            </Col>
                                        </Row>
                                    </Grid>
                                    <Paper square className='boxShadow'>
                                        <Grid item xs={6}>
                                            <Box border={0} padding={1} className='boxForDetails'>
                                                {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                                    <div>
                                                        <Row style={{ marginLeft: "200%", width: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                            <TextField label="Company Name" value={pascalCase(companyName)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                            <span style={{ width: "10%" }}></span>
                                                            <TextField label="Mc Number" value={mc_number} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                        </Row>
                                                        <Row style={{ width: "200%", marginLeft: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                            <TextField label="Phone Number" value={phone_number} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                            <span style={{ width: "10%" }}></span>
                                                            <TextField label="User Role" value={user_role} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                        </Row>
                                                        <Row style={{ width: "200%", marginLeft: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 10 }}>
                                                            <TextField label="Company Role" value={pascalCase(companyRole)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                            <span style={{ width: "10%" }}></span>
                                                            <TextField label="Address" value={pascalCase(address)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                        </Row>
                                                        <Row style={{ width: "200%", marginLeft: "200%", display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 5 }}>
                                                            <TextField label="City" value={pascalCase(city)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                            <span style={{ width: "10%" }}></span>
                                                            <TextField label="State" value={pascalCase(state)} inputProps={{ readOnly: true, disabled: true, }} style={{ width: "40%" }} variant="standard" size="small" />
                                                        </Row>
                                                    </div>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </main>
                        <div className='bottomSpace'>
                        </div>
                    </Paper>
                </div>
            </div>
            {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                <FloatingButtonForFeedBack />
            )}
        </>
    );
};

export default UserProfile;