import React from 'react';
export const Excellent = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="miter">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="8" y1="9" x2="8.01" y2="9" strokeWidth="2" strokeLinecap="round"></line>
            <line x1="15.99" y1="9" x2="16" y2="9" strokeWidth="2" strokeLinecap="round"></line>
            <path d="M6,13H18s-1,5-6,5S6,13,6,13Z"></path>
        </svg>
    );
};
