import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

/* ************************ get Approved Company Details************************* */
export const getApprovedUserDetailsForCompanyApiCall = async (company_mc_number) => {
    try {
        let response = await axios({
            url: environmentVariables.getapproveduserdetailsforcompany + '?company_mc_number=' + company_mc_number,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Get approved company user details failed');
    }
};

/* ************************ Get AllCompanies Api Call************************* */
export const getAllCompaniesApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.getcompanyinformation,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Get all companies error');
    }
};

/* ************************Get Company Informatiom********************* */
export const getparticularcompanyinformationApiCall = async (type, rowValues) => {
    try {
        let response = await axios({
            url: environmentVariables.getcompanyinformation + type + rowValues,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'getting company Information failed');
    }
};

/* ************************ Activating or deactivating company in system admin page************** */
export const updateCompanyStatusApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.updatecompanyprofile,
            method: 'patch',
            data: listValues
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Company status activation/decativation failed to update');
    }
};

/****************************************Preferred Carrier************************************/
/************************Get preferred and hold companies data************************* */
export const getPreferredAndHoldCompaniesApiCall = async (loggedInCompanyMCNumber) => {
    try {
        let response = await axios({
            url: environmentVariables.getpreferredandholdcompanies +
                '?company_mc_number=' +
                loggedInCompanyMCNumber,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Error in getting preferred and hold companies data');
    }
};

/************************Get preferred companies data************************* */
export const getPreferredCompaniesApiCall = async (loggedInCompanyMCNumber) => {
    try {
        let response = await axios({
            url: environmentVariables.getpreferredcompanies +
                '?company_mc_number=' +
                loggedInCompanyMCNumber,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Error in getting preferredcompanies data');
    }
};

/************************Get hold companies data************************* */
export const getHoldCompaniesApiCall = async (loggedInCompanyMCNumber) => {
    try {
        let response = await axios({
            url: environmentVariables.getholdcompanies +
                '?company_mc_number=' +
                loggedInCompanyMCNumber,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Error in getting preferred and hold companies data');
    }
};

/***********************************Company Profile***********************************************/
/************************Update company profile************************* */
export const updateCompanyProfileApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.updatecompanyprofile,
            method: 'patch',
            data: listValues,
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Error in updating company profile');
    }
};

/************************Update Prefered Status************************* */
export const updatePreferStatusApiCall = async (completeSelectedRowData) => {
    try {
        let response = await axios({
            url: environmentVariables.updatepreferstatus,
            method: 'patch',
            data: completeSelectedRowData,
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Error in updating prefered status data');
    }
};