import React, { useState, useEffect } from 'react';
import {
    CssBaseline,
    Divider,
    IconButton,
    Typography,
    Grid,
    Paper,
    Button,
    Snackbar,
    Box,
    TextField,
    Tabs,
    Tab,
    InputAdornment,
    List,
    ListItem,
    InputBase,
    Tooltip
} from '@material-ui/core';
import './index.css';
import { COLORS } from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete as MUIAutoComplete } from '@material-ui/lab';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import cities from 'cities';
// import zipcodes from 'zipcodes';
import DateFnsUtils from '@date-io/date-fns';
import DatePicker from 'react-datepicker';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from 'react-google-autocomplete';
import { toNumber } from 'lodash';
import useWebSocket from 'react-use-websocket';
import addNotification from 'react-push-notification';
import { animateScroll } from 'react-scroll';
import Countdown from 'react-countdown';
import slugid from 'slugid';
import Badge from '@mui/material/Badge';
import { reduceCountOnLoadExpire, updateCount } from './updatecount';
/************************Counter Offer Import***************************************** */
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { AttachMoney, Visibility } from '@material-ui/icons';
// Imports to have all the Icons
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';
// Import statements for CSS and environment
import { environmentVariables } from '../../constants/environment';
import SearchForm from '../SearchForm';
import Navbar from '../../components/Navbar';
import MUIDrawer from '../../components/Drawer';
import sortDate from '../../components/Sorting/sortDate';
import sortNum from '../../components/Sorting/sortNum';
import { toShowIcon } from '../../staticPages/YesOrNA';
import { BookItButton, AcceptCounterOffer, ExpiredCounterOffer } from '../../components/Button/Button';

//Import Svg Icons
import { ExpandLeft } from '../../assets/SvgIcons/ExpandLeft';
import { ExpandRight } from '../../assets/SvgIcons/ExpandRight';
// Import statements for Chat Component
import SearchBar from '../../components/CommentsComponent/SearchBar/SearchBar';
import Header from '../../components/CommentsComponent/Header/Header';
import HeaderChat from '../../components/CommentsComponent/HeaderChat/HeaderChat';
import Chat from '../../components/CommentsComponent/Chat/Chat';
import { pascalCase } from '../../utils/index';
import { editLoadDetailApiCall, fetchLoadApiCall } from '../../apis/loads';
import { createBidApiCall, deleteBidApiCall, editBidApiCall, getBidApiCall } from '../../apis/bids';
import { createCommentApiCall, getCommentApiCall, updateCommentApiCall } from '../../apis/chat';
import { addNotificationApiCall } from '../../apis/notifications';
import { getMatchedLoadApiCall, saveLaneApiCall } from '../../apis/searchedLane';
import { acceptCounterOfferNotificationApiCall, counterOfferNotificationApiCall, createBidNotificationApiCall } from '../../apis/emailNotifications';

// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';
import { handleSendMessageForSocketIO } from '../WebSocketConnection';

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
let allPostedLoades = [];
let deliveryCity;
let pickUpPlace;
let pickingUpDate;
let DeliverDate;
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    }
}));

const options = [
    'All trucks',
    'Sprinter/Cargo Van',
    'Small Straight',
    'Large Straight'
];

// Function to handle MuiAlert
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const SavedLanes = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState('');
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    // Constant to handle logged in user details
    const [userCompanyName, setUserCompanyName] = useState('');
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState('')
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    // Constant to handel main grid percentage
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [selectedRowDataValueForLane, setSelectedRowDataValueForLane] = useState([]);
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    //constant to handle mui data table loader
    const [loading, setLoading] = useState(false);

    // constant to handle drawer notification badge
    const [isNotificationSent, setIsNotificationSent] = useState(false);

    /*************************************Constant to handle  Matched Lane***********************************************/
    const [rowDataForMatchedLane, setRowDataForMatchedLane] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    const [loadingForMatchedLane, setLoadingForMatchedLane] = useState(false);
    const [mainGridPercentForMatchedLane, setMainGridPercentForMatchedLane] = useState('100%');

    // constants to handle disabling buttons after first click
    const [disableBidButton, setDisableBidButton] = useState(false);

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState('');
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState('');
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState('');
    const [chatFirstCompanyName, setchatFirstCompanyName] = useState('');
    const [chatAllMessages, setChatAllMessages] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);

    //constants to handle bit table loader
    const [loadingActiveBids, setLoadingActiveBids] = useState(false);
    const [loadingClosedBids, setLoadingClosedBids] = useState(false);

    // Constant to handle second muidatatble 
    const [rowDataActiveBidTable, setRowDataActiveBidTable] = useState([]);

    // Constant to handle third muidatatble 
    const [rowDataClosedBidTable, setRowDataClosedBidTable] = useState([]);

    // Constant for max weight 
    const [txtMilesOut, updateTxtMilesOut] = useState('');

    // Constant to handle load notes
    const [BidNotes, updateBidNotes] = useState('');

    // variable to handle counter offer
    const [counterOffer, setCounterOffer] = useState('');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    //counter Offer Open Form
    const [counteropen, setCounterOpen] = useState(false);
    const [counteropencarrier, setCounterOpenCarrier] = useState(false);
    const [disableBookit, setDisableBookit] = useState(false);
    const [disableCounterButton, setDisableCounterButton] = useState(false);
    const [disableEdit, setDisadbleEdit] = useState(false);
    const [isPaneOpenForMatchedLane, setIsPaneOpenForMatchedLane] = useState(false);

    // Constant to handle watch_load 
    const [loadWatchLoad, setLoadWatchLoad] = useState(false);

    // Constant to handle watch_load 
    const [loadSavedLane, setLoadSavedLane] = useState(false);

    // Constants for showing Loaddetails in the secondary pane
    const [loadPostedByUser, setLoadPostedByUser] = useState('');
    const [loadPostedBy, setLoadPostedBy] = useState('');
    const [loadLoadTypeForMatchedLane, setLoadLoadTypeForMatchedLane] = useState('');
    const [loadLoadNotes, setLoadLoadNotes] = useState('');

    const [loadPickUpdateForMatchedLame, setLoadPickUpdateForMatchedLame] = useState('');
    const [loadPickUpWindow, setLoadPickUpWindow] = useState('');
    const [loadPickUpDestinationForMatchedLane, setLoadPickUpDestinationForMatchedLane] = useState('');

    const [loadDeliverByDateForMatchedLane, setLoadDeliverByDateForMatchedLane] = useState('');
    const [loadDeliverWindow, setLoadDeliverWindow] = useState('');
    const [loadDeliverDestinationForMatchedLane, setLoadDeliverDestinationForMatchedLane] = useState('');

    const [loadMaxWeightForMatchedLane, setLoadMaxWeightForMatchedLane] = useState('');
    const [loadNoOfPiecesForMatchedLane, setLoadNoOfPiecesForMatchedLane] = useState('');

    // Constants to handle Hours and minutes
    const minutes = ['15', '30', '45'];

    // Variable to handle length width height
    const [txtLength, setTxtLength] = useState('');
    const [txtWidth, setTxtWidth] = useState('');
    const [txtHeight, setTxtHeight] = useState('');

    const [loadStackable, setLoadStackable] = useState(false);
    const [loadHazmat, setLoadHazmat] = useState(false);
    const [loadDockHigh, setLoadDockHigh] = useState(false);
    const [loadTempControl, setLoadTempControl] = useState(false);
    // const [loadRate, setLoadRate] = useState('');
    const [loadFreightDimension, setLoadFreightDimension] = useState('');
    const [loadBidAmount, setLoadBidAmount] = useState('');
    const [bidExpiration, setBidExpiration] = useState('');
    const [loadBidAmountDisable, setLoadBidAmountDisable] = useState(false);
    const [loadStatus, setLoadStatus] = useState('');
    const [loadCityDistance, setLoadCityDistance] = useState('');
    const [loadPostedTime, setloadPostedTime] = useState('');
    const [loadExpiresIn, setLoadExpiresIn] = useState('');
    const [loadUpdateLoadExpirationTimeForHour, setLoadUpdateLoadExpirationTimeForHour] = useState('');
    const [loadUpdateLoadExpirationTimeForMin, setLoadUpdateLoadExpirationTimeForMin] = useState('');
    const [loadBidNotesDisable, setLoadBidNotesDisable] = useState(false);
    const [MilesOutDisable, setMilesOutDisable] = useState(false);
    const [lengthDisable, setLengthDisable] = useState(false);
    const [widthDisable, setWidthDisable] = useState(false);
    const [heightDisable, setHeightDisable] = useState(false);

    // $$$$$$$$$$$$$$$$$$$$ Constants to handle  top box $$$$$$$$$$$$$$$$$$$$$$$$$$$
    // Constant to handle LoadNumber
    const [loadNumberDetail, setLoadNumberDetail] = useState('');

    /**********************************Constant to handle On click of Edit all text flied*******************************************/
    // Constants to handle from city,state,zip
    const [txtFromZip, updateTxtFromZip] = useState('');
    const [txtFromState, updateTxtFromState] = useState('');
    const [txtFromCity, updateTxtFromCity] = useState('');
    const [isFromCityValid, setIsFromCityValid] = useState(false);
    const [selectedTxtFromCity, setSelectedTxtFromCity] = useState('');

    // Constants to handle to city,state,zip
    const [txtToZip, updateTxtToZip] = useState('');
    const [txtToState, updateTxtToState] = useState('');
    const [txtToCity, updateTxtToCity] = useState('');
    const [isToCityValid, setIsToCityValid] = useState(false);
    const [selectedTxtToCity, setSelectedTxtToCity] = useState('');
    const [clickEdit, setClickEdit] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [disableCancel, setDisableCancel] = useState(false);
    const [deliverBydate, updateDeliverBydate] = useState(new Date());
    const [pickUpDate, updatePickUpDate] = useState(new Date());
    const [deliverDateChanged, setDeliverDateChanged] = useState(false);
    const [pickupDateChanged, setPickUpDateChanged] = useState(false);
    // Constant for number of pieces
    const [milesWithinFromDestination, updateMilesWithinFromDestination] = useState('');
    // Constant for number of pieces
    const [milesWithinToDestination, updateMilesWithinToDestination] = useState('');
    // Constant for max weight 
    const [txtMaxWeight, updateTxtMaxWeight] = useState('');
    // Constant for number of pieces
    const [txtTotalNoOfPices, updateTxtTotalNoOfPices] = useState('');
    // Constant List to handle truck type Checkboxes
    const [saveCheckboxes, setSaveCheckboxes] = useState([]);

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // $$$$$$$$$$$$$$$$$$$$ Constants to handle  top box $$$$$$$$$$$$$$$$$$$$$$$$$$$
    // Constant to handle LoadNumber
    const [loadId, setLoadId] = useState('');
    const [laneNumberDetail, setLaneNumberDetail] = useState('');
    const [laneCount, setLaneCount] = useState('');
    const [loadPickUpdate, setLoadPickUpdate] = useState('');
    const [loadPickUpDestination, setLoadPickUpDestination] = useState('');
    const [milesWithinFrom, setMilesWithinFrom] = useState('');
    const [milesWithinTo, setMilesWithinTo] = useState('');
    const [loadDeliverByDate, setLoadDeliverByDate] = useState('');
    const [loadDeliverDestination, setLoadDeliverDestination] = useState('');

    const [loadMaxWeight, setLoadMaxWeight] = useState('');
    const [loadNoOfPieces, setLoadNoOfPieces] = useState('');
    const [loadLoadType, setLoadLoadType] = useState('');

    // Constants to handle Awarded Loads
    const [rowDataForSavedLane, setRowDataForSavedLane] = useState([]);

    //function to Add Badge Count
    const badgeCount = (count) => {
        if (count !== '0' && count !== 'NaN') {
            return (
                <Badge badgeContent={count} color='error'>
                    <Tooltip title='Expand Row'>
                        <div>
                            <ExpandRight />
                        </div>
                    </Tooltip>
                </Badge>
            );
        }
        else {
            return (
                <Tooltip title='Expand Row'>
                    <div>
                        <ExpandRight />
                    </div>
                </Tooltip>
            );
        }
    };

    /*********************Constant to handle Matched Lane*********************************/
    // Function to get bid status for bid tables
    const getStatusForBidTables = (status) => {
        let newStatus;
        if (status === 'new') {
            newStatus = 'New';
        }
        else if (status === 'accepted') {
            newStatus = 'Won';
        }
        else {
            newStatus = 'Closed';
        }
        return newStatus;
    };

    //----------------------------------------Counter Offer----------------------------------------------------
    //Function to send counter Offer Or Chat Switching
    const tosendOffer = async (nameCarrier) => {
        let carrierName = nameCarrier;
        let loadId = '';
        let userId = '';
        if (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier')) {
            loadId = selectedRowDataValue.load_id;
            userId = '&broker_id=' + user.email;
        }
        else {
            loadId = selectedRowDataValue.load_id;
            userId = '&carrier_id=' + user.email;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                getCommentResponse.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        setChatAllMessages(element);
                        setChatFirstCarrierName(element.carrier_id);
                        setchatFirstCompanyName(element.carrier_company);
                        setChatCarrierMCNumber(`MC #${element.carrier_mc_number}`);
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    /***********************Open and Close Counter Offer Dialog***********************************************/
    const toOpen = (acceptedRow) => {
        const findResult = rowDataActiveBidTable.find(
            ({ bid_id }) => bid_id === acceptedRow.rowData[2]
        );
        setSelectedRowDataValue(findResult);
    };

    const handleOpen = (acceptedRow) => {
        setCounterOpen(true);
        toOpen(acceptedRow);
        tosendOffer(acceptedRow.rowData[6]);
        setCounterOffer('');
    };

    const handleOpenCarrier = (data, acceptedRow) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            toOpen(acceptedRow);
            tosendOffer(acceptedRow.rowData[6]);
            setCounterOpenCarrier(true);
            setCounterOffer(data);
        }
    };

    const handleClose = () => {
        setCounterOpen(false);
        setCounterOpenCarrier(false);
    };

    /***********************Colunm Definition************************************/
    // Bid datatable Row and column defination
    const columnDefsBidTable = [
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier Company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[14] === 'newoffer') && rowIndex.rowData[15] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[14] !== 'newoffer' && rowIndex.rowData[15] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[16]} </p>}
                        {rowIndex.rowData[14] !== 'newoffer' && rowIndex.rowData[15] === 'Accept' && <p className='afterBidDisplay'> $ {rowIndex.rowData[16]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[5]);
                    return (
                        bidStatus
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        { label: 'bid_expiration_time', name: 'bid_expiration_time', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === '' || rowuserdata.rowData[9] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== '' || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === '' || rowuserdata.rowData[10] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== '' || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === 'LXWXH' && <p className='afterBidDisplay'> NA
                        </p>}
                        {rowuserdata.rowData[11] !== 'LXWXH' && rowuserdata.rowData[11]}
                    </>
                    );
                }
            }
        },

        {
            label: 'Bid Expiration',
            name: 'BidExpiration',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[7];
                    var localTime = moment.utc(TimeStamp).local().format('YYYY-MM-DD HH:mm:ss');
                    let BidExpireTime = parseInt(rowIndex.rowData[8].replace(/\D/g, ''));
                    let time = '';
                    if (BidExpireTime === 5) {
                        time = '00:0' + BidExpireTime;
                    }
                    else {
                        time = '00:' + BidExpireTime;
                    }
                    let timeParts = time.split(':');
                    let timeInMiliseconds = ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(timeInMiliseconds)}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: 'Accept',
            name: 'Accept',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
            },
        },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' && selectedRowDataValue.posted_by_user === user.email)),
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[14];
                    let counterofferstatus = rowIndex.rowData[15];
                    let counteroffer = rowIndex.rowData[16];

                    return (<>
                        {disableBookit === false && counteroffersent === 'newoffer' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346', fontSize: 12 }} disabled={disableCounterButton} onClick={() => handleOpen(rowIndex)}>  Counter Offer</Button>}
                        {disableBookit === true && counteroffersent === 'newoffer' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ fontSize: 12 }} disabled={disableCounterButton}>  Counter Offer</Button>}
                        {counteroffersent === 'carrier' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346' }} disabled={disableCounterButton} onClick={() => handleOpenCarrier(counteroffer, rowIndex)}> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {(counteroffersent === 'carrier' || counteroffersent === 'broker') && counterofferstatus === 'Accept' && <AcceptCounterOffer counteroffer={counteroffer} />}
                        {disableBookit === false && counteroffersent === 'broker' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346' }} disabled={disableCounterButton} onClick={() => handleOpenCarrier(counteroffer, rowIndex)}> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {disableBookit === true && counteroffersent === 'broker' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' disabled={disableCounterButton} > ${counteroffer} <Edit className='coediticon' disabled={disableEdit} /> </Button>}
                    </>
                    );
                },
            },
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[15];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && selectedRowDataValue.posted_by_user !== user.email)),
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[16];
                    let counterofferstatus = rowuserdata.rowData[15];

                    return (<>
                        {(rowuserdata.rowData[5] === 'accepted' || rowuserdata.rowData[5] === 'Closed') && <Button style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer
                        </Button>}
                        {(counteroffer === '' || counteroffer === undefined) && counterofferstatus === 'New' && <p className='afterBidDisplay'> NA
                        </p>}
                        {disableBookit === false && counteroffer !== '' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ backgroundColor: '#0d2346' }} onClick={() => handleOpenCarrier(counteroffer, rowuserdata)}> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {disableBookit === true && counteroffer !== '' && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor'> ${counteroffer} <Edit className='coediticon' disabled={disableEdit} />
                        </Button>}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && <AcceptCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                }
            },
        },
    ];

    const optionsBidTable = {
        filter: false,
        responsive: 'vertical',
        filterType: false,
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingActiveBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        },
    };

    // Bid datatable Row and column defination
    const columnDefsClosedBidTable = [
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[14] === 'newoffer') && rowIndex.rowData[15] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[14] !== 'newoffer' && rowIndex.rowData[15] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[16]} </p>}
                        {rowIndex.rowData[14] !== 'newoffer' && rowIndex.rowData[15] === 'Accept' && <p className='afterBidDisplay'> $ {rowIndex.rowData[16]}
                        </p>}
                    </>
                    );
                }
            },
        },
        {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[5]);
                    return (
                        bidStatus
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        { label: 'bid_expiration_time', name: 'bid_expiration_time', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === '' || rowuserdata.rowData[9] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== '' || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[10] === '' || rowuserdata.rowData[10] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[10] !== '' || rowuserdata.rowData[10] !== undefined) && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[11] === 'LXWXH' && <p className='afterBidDisplay'> NA
                        </p>}
                        {rowuserdata.rowData[11] !== 'LXWXH' && rowuserdata.rowData[11]}
                    </>
                    );
                }
            }
        },

        {
            label: 'Bid Expiration',
            name: 'BidExpiration',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.rowData[5] === 'Closed') {
                        return (
                            <Countdown autoStart={true} date={Date.now() + 0}>
                            </Countdown>
                        );
                    }
                },
            }
        },
        {
            label: 'Accept',
            name: 'Accept',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || loggedInuserRole !== 'Carrier') && selectedRowDataValue.posted_by_user === user.email,
                customBodyRender: () => {
                    return (
                        <BookItButton />
                    );
                },
            },
        },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' || selectedRowDataValue.posted_by_user === user.email)),
                customBodyRender: (dataIndex, rowIndex) => {
                    let counteroffersent = rowIndex.rowData[14];
                    let counterofferstatus = rowIndex.rowData[15];
                    let counteroffer = rowIndex.rowData[16];

                    return (<>
                        {(counteroffersent === 'newoffer') && counterofferstatus === 'New' && <Button variant='contained' className='buttonColor' style={{ fontSize: 12 }} disabled={true}>
                            Counter Offer
                        </Button>}
                        {(counteroffersent === 'carrier' || counteroffersent === 'broker') && counterofferstatus === 'New' && <p className='afterBidDisplay'> ${counteroffer} </p>}
                        {(counteroffersent === 'carrier' || counteroffersent === 'broker') && counterofferstatus === 'Accept' && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                },
            },
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[15];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && selectedRowDataValue.posted_by_user !== user.email)),
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[16];
                    let counterofferstatus = rowuserdata.rowData[15];

                    return (<>
                        {(counteroffer === undefined || counteroffer === '') && counterofferstatus === 'New' && <p className='afterBidDisplay'> NA
                        </p>}
                        {(counteroffer !== undefined && counteroffer !== '') && counterofferstatus === 'New' && <p className='afterBidDisplay'> ${counteroffer}
                        </p>}
                        {(counteroffer !== undefined || counteroffer !== '') && counterofferstatus === 'Accept' && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                }
            },
        },
    ];

    const optionsClosedBidTable = {
        filter: false,
        responsive: 'vertical',
        filterType: false,
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingClosedBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    // Function to calculate miliseconds for load expiration
    const loadExpirationInMiliseconds = (expirationTime) => {
        let timeWithoutMinOrHr = expirationTime.split(':');
        let timeInMiliseconds = ((timeWithoutMinOrHr[0] * (60000 * 60)) + (timeWithoutMinOrHr[1] * 60000));
        return timeInMiliseconds;
    };

    const setMuiDatatableColour = (allRowsData) => {
        if (allRowsData.data.length > 0) {
            allRowsData.data.forEach((data) => {
                if (data.data[data.data.length - 3] === 'Yes') {
                    // const vaal = document.getElementById(`MUIDataTableBodyRow-${data.index}`);
                    // if (vaal !== null) {
                    //     vaal.setAttribute('style', 'background: #81ff9e');
                    // }
                }
            });
        }
    };

    const columnDefsForMatchedLane = [
        { label: 'PostId', name: 'load_id', options: { display: false } },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pickupfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Miles (Time)',
            name: 'cities_distance',
            options: {
                sort: true,
                display: displaySpecificColums, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },

        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '' && value.length !== 0) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== '') {
                                if (valEnteredNumber.includes(',')) {
                                    let splittingValue = valEnteredNumber.split(',');
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== 'NaN' && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'TimeStamp',
            name: 'time_stamp',
            options: {
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = 'false';
                        }
                    }
                }
            }
        },
        {
            label: 'LoadExpirationTime',
            name: 'load_expiration_time',
            options:
            {
                display: false,
                viewColumns: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    if (rowIndex.columnData) {
                        if (rowIndex.columnData.display) {
                            rowIndex.columnData.display = 'false';
                        }
                    }
                }
            }
        },
        {
            label: 'Load Expires In',
            name: 'LoadExpiresIn',
            options: {
                display: displaySpecificColums,
                customBodyRender: (dataIndex, rowIndex) => {
                    let TimeStamp = rowIndex.rowData[9];
                    var localTime = moment.utc(TimeStamp).local().format('YYYY-MM-DD HH:mm:ss');
                    return (
                        <Countdown autoStart={true} date={Date.parse(localTime) + parseInt(loadExpirationInMiliseconds(rowIndex.rowData[10]))}>
                        </Countdown>
                    );
                },
            }
        },
        {
            label: 'Posting Company',
            name: 'posted_by_company',
            options: {
                display: displaySpecificColums
            }
        },
        {
            label: 'Load ID',
            name: 'load_number',
            options: {
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
        {
            label: 'Status', name: 'status', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        <div style={{ fontWeight: 'bold' }}>{rowuserdata.rowData[14].toUpperCase()}</div>
                    );
                }
            }
        },
    ];
    // Variable to handle column defs of load table
    const [columnDefsLoadTable, setColumnDefsLoadTable] = useState(columnDefsForMatchedLane);

    // Function to handle remove backround color from selection
    const removeBackgroundColour = () => {
        for (let i = 0; i < rowDataForSavedLane.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute('style');
                if (styleObject !== null) {
                    if (styleObject.toString().includes('9FD2F3')) {
                        domObject.removeAttribute('style', 'background: #9FD2F3');
                    }
                }
            }
        }
    };

    // Function to load and reload bid details
    const loadAndRealoadSecondGrid = async (loadId) => {
        let findBids = [];
        let closedBids = [];
        try {
            const getBidResponse = await getBidApiCall(loadId, "loadspecific");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                getBidResponse.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                if (getBidResponse.length !== 0) {
                    setLoadingActiveBids(true);
                    setLoadingClosedBids(true);
                }
                else {
                    setLoadingActiveBids(false);
                    setLoadingClosedBids(false);
                }
                getBidResponse.forEach(element => {
                    if (element.carrier_name === user.email || element.posted_by_user === user.email || user.email === process.env.REACT_APP_ADMIN_USER) {
                        if (element.status === 'new') {
                            findBids.push(element);
                        }
                        else {
                            closedBids.push(element);
                        }
                    }
                });
                if (findBids.length === 0) {
                    setLoadingActiveBids(true);
                }
                if (closedBids.length === 0) {
                    setLoadingClosedBids(true);
                }
                setRowDataActiveBidTable(findBids);
                setRowDataClosedBidTable(closedBids);
            }
            else {
                setRowDataActiveBidTable([]);
                setRowDataClosedBidTable([]);
                setLoadingClosedBids(true);
                setLoadingActiveBids(true);
            }
        }
        catch (error) {
            setRowDataActiveBidTable([]);
            setRowDataClosedBidTable([]);
        }
        return findBids.concat(closedBids);
    };

    //function to fetch all chat's
    const fetchAllComments = async (loadInformation) => {
        let allChatResponse;
        let loadId = '';
        let userId = '';
        if (user.email === process.env.REACT_APP_ADMIN_USER || loadInformation.posted_by_user === user.email) {
            loadId = loadInformation.load_id;
            userId = '&broker_id=' + loadInformation.posted_by_user;
        }
        else {
            loadId = loadInformation.load_id;
            userId = '&carrier_id=' + user.email;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                if (getCommentResponse !== undefined) {
                    setChatAllMessages(getCommentResponse[0]);
                    if (loadInformation.posted_by_user === user.email) {
                        setChatFirstCarrierName(getCommentResponse[0]['carrier_id']);
                        setchatFirstCompanyName(getCommentResponse[0]['carrier_company']);
                        setChatCarrierMCNumber(`MC #${getCommentResponse[0]['carrier_mc_number']}`);
                    }
                    else {
                        setChatFirstCarrierName(getCommentResponse[0]['broker_id']);
                        setchatFirstCompanyName(getCommentResponse[0]['broker_company']);
                        setChatCarrierMCNumber(`MC #${getCommentResponse[0]['broker_mc_number']}`);
                    }
                    setAllChat(getCommentResponse);
                    allChatResponse = getCommentResponse;
                }
            }
        }
        catch (error) {
            if (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' && ((loggedInuserRole !== 'Carrier' && loadInformation.posted_by_user === user.email) || (loggedInuserRole === 'Broker')))) {
                setAllChat([]);
                setChatAllMessages([]);
                setChatFirstCarrierName('');
                setChatCarrierMCNumber('');
                setchatFirstCompanyName('');
            }
            else {
                let tempAllChatArray = {
                    'Items': [{
                        'broker_id': loadInformation.posted_by_user,
                        'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss')
                    }]
                };
                setAllChat(tempAllChatArray);
                allChatResponse = tempAllChatArray;
                setChatFirstCarrierName(loadInformation.posted_by_user);
                setChatCarrierMCNumber(`MC #${loadInformation.posted_by_mc_number}`);
                setchatFirstCompanyName(loadInformation.posted_by_company);
                setChatAllMessages([]);
            }
        }
        return allChatResponse;
    };

    //Function to createupdatestcomment
    const updateTestComment = async (secondaryArray, action) => {
        if (secondaryArray.carrier_company === null) {
            secondaryArray.carrier_company = userCompanyName;
        }
        try {
            let updateCommentResponse = await updateCommentApiCall(secondaryArray, action);
            return updateCommentResponse;
        }
        catch (error) {
            console.log('create comment', error);
        }
    };

    // function to add notification in bell icon
    const handleAddNotification = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        let loadId = '';
        let messages = '';
        if (message.includes('load_id')) {
            loadId = message.split('||')[1].split(':')[1];
            messages = message.split('||')[0];
        }
        let postedByUser = '';
        let wonUser = '';
        let bidCarrier = '';
        let postedCompany = '';
        let carrierCompany = '';
        let bidCarriers = [];
        let loadNumber = '';
        if (sendNotification === true) {
            if (messages === 'ThereIsANewBid') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'CounterOfferIsUpdated') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'ThereIsACounterOffer') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                postedCompany = selectedRow.posted_by_company;
            }
            else if (messages === 'ABidHasBeenRemoved') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'YourLoadHasBennAccepted') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                wonUser = bidDetails.rowData[6];
                carrierCompany = bidDetails.rowData[3];
            }
            else if (messages === 'YourBidHasExpired') {
                loadNumber = bidDetails.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = user.email;
                carrierCompany = bidDetails.carrier_company;
            }
            else if (messages === 'ThereIsANewMessageFromBroker') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                postedCompany = selectedRow.posted_by_company;
            }
            else if (messages === 'ThereIsANewMessageFromCarrier') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                carrierCompany = userCompanyName;
            }
            let listValues = {
                'load_id': loadId,
                'receiver_id': recieverId,
                'bid_carrier': bidCarrier,
                'won_carrier': wonUser,
                'load_posted_by_user': postedByUser,
                'notified_user': user.email,
                'message': messages,
                'carrier_company': carrierCompany,
                'load_expired_status': false,
                'posted_company': postedCompany,
                'bid_carriers': bidCarriers,
                'won_message': false,
                'load_number': loadNumber,
                'time_stamp_notification': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                'generated_date': moment(new Date()).format('MM/DD/YYYY'),
                'read_users_list': [],
                'deleted_by_users': []
            };
            try {
                const addNotificationResponse = await addNotificationApiCall(listValues);
                if (addNotificationResponse) {
                    setIsNotificationSent(true);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    // Function to handle send Socket IO messages
    const sendMessageForSocketIO = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        try {
            handleAddNotification(message, recieverId, sendNotification, selectedRow, bidDetails);
            const connectionIds = await handleSendMessageForSocketIO(message, recieverId, sendNotification, selectedRow, bidDetails);
            sendJsonMessage({ 'action': 'onMessage', 'message': message, 'connectionIds': connectionIds.toString() });
        }
        catch (e) {
            console.log(e);
        }
    };

    // Function to update chat read status after clicking on row
    const updateChatReadStatus = async (selectedRowChatDetails) => {
        let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails));
        if (listValues !== undefined) {
            listValues.forEach(element => {
                if (element.messages !== undefined) {
                    element.messages.forEach(message => {
                        if (message.read_status && message.read_status !== undefined) {
                            const findUsers = message.read_status.find(
                                ({ name }) => name === user.email
                            );
                            if (findUsers.status === false) {
                                findUsers.status = true;
                            }
                        }

                    });
                }
            });
            let response = await updateTestComment(listValues, 'patch');
            if (response) {
                let data = JSON.parse(response.data.body);
                sendMessageForSocketIO('chatHasBeenRead||load_id:' + data.load_id, '', false);
                sendMessageForSocketIO('newNotification', '', false);
            }
        }
    };

    // Function to handle pane close
    const closeSecondaryPaneForMatchedLane = async () => {
        window.localStorage.removeItem('LoadId');
        removeBackgroundColour();
        let fetchAllCommentResponse = await fetchAllComments(selectedRowDataValue);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowDataValue.load_id
                );
                updateChatReadStatus(findChatResult);
            }
        }
        fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
        setMainGridPercentForMatchedLane('100%');
        setIsPaneOpenForMatchedLane(false);
        isPaneOpenForSocketIO = false;
        selectedRowDataValueForSocketIO = [];
        setDisplaySpecificColums(true);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = true;
            }
        }
        setColumnDefsLoadTable(FilterColumns);
        setLoadBidAmount('');
        setTxtHeight('');
        setTxtWidth('');
        setTxtLength('');
        updateBidNotes('');
        updateTxtMilesOut('');
    };

    // Function to handle pane close
    const closeSecondaryPane = async () => {
        window.localStorage.removeItem('LaneId');
        window.localStorage.removeItem('lane_number');
        window.localStorage.removeItem('matched_lane');
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        setSelectedRowDataValueForLane([]);
        removeBackgroundColour();
        setDisplaySpecificColums(true);
        setClickEdit(false);
    };

    // Function to fetch all the data main MuiDatatble
    const fetchMainMuiDatatableValues = async () => {
        try {
            let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
            if (matchLaneResponse) {
                if (matchLaneResponse) {
                    matchLaneResponse.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );

                    if (matchLaneResponse.length !== 0) {
                        let laneIds = [];
                        setLoading(true);
                        matchLaneResponse.forEach(element => {
                            if (element.lane_number) {
                                laneIds.push(element.lane_number);
                            }
                        });
                    }
                    else {
                        setLoading(true);
                    }
                }
                let today = new Date();
                let currentDate = (moment(today).local().format('MM/DD/YYYY HH:mm:ss')).split(' ')[0];
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    let savedLaneList = matchLaneResponse.filter(function (singleVal) {
                        return singleVal['carrier_name'] !== user.email;
                    });
                    setRowDataForSavedLane(savedLaneList);
                    removeBackgroundColour();
                }
                else {
                    if (window.location.pathname === '/allsavedlanes') {
                        let savedLaneList = matchLaneResponse.filter(function (singleVal) {
                            return singleVal['company_mc_number'] === userReduxData.userData.company_mc_number && singleVal['delete_lane'] === false && (new Date(singleVal['pick_upfrom_date']) >= new Date(currentDate) || new Date(singleVal['pickupto_date']) >= new Date(currentDate));
                        });
                        setRowDataForSavedLane(savedLaneList);
                    }
                    else {
                        let savedLaneList = matchLaneResponse.filter(function (singleVal) {
                            return singleVal['carrier_name'] === user.email && singleVal['delete_lane'] === false && (new Date(singleVal['pick_upfrom_date']) >= new Date(currentDate) || new Date(singleVal['pickupto_date']) >= new Date(currentDate));
                        });
                        setRowDataForSavedLane(savedLaneList);

                    }


                    removeBackgroundColour();
                }
            }
        }
        catch (error) {
            console.log(error, 'get lane');
        }
    };

    //function to handle Back to saved Lane Table
    const handleGoBack = async () => {
        closeSecondaryPaneForMatchedLane();
        setIsExpand(false);
        closeSecondaryPane();
        fetchMainMuiDatatableValues();
    };

    //function to fetch matched data
    const fetchMatchedData = async (pickUpcity, deliverCity, pickUpDate, deliverDate, callFrom) => {
        let fetchLoadResponse = await fetchLoadApiCall(user.email);
        if (fetchLoadResponse) {
            fetchLoadResponse.sort((a, b) =>
                b.time_stamp.localeCompare(a.time_stamp)
            );
            if (fetchLoadResponse.length !== 0) {
                setLoadingForMatchedLane(true);
            }
            else {
                setLoadingForMatchedLane(false);
            }
            let arrayToRemove = fetchLoadResponse.filter(function (singleVal) {
                return ((singleVal['load_Expired_Status'] !== true && singleVal['status'] !== 'Assigned to Driver' && singleVal['load_delete_status'] === false) && (singleVal['pickup_dest'] === pickUpcity || singleVal['delivery_dest'] === deliverCity) && (singleVal['pickupfrom_date'] >= pickUpDate || singleVal['pickupto_date'] >= deliverDate));
            });
            if (callFrom === 'removeLane') {
                handleGoBack();
            }
            else if (callFrom === 'expandMore') {
                setIsExpand(true);
                setMainGridPercentForMatchedLane('100%');
            }
            const tempData = arrayToRemove.map(item => {
                return item;
            });
            setRowDataForMatchedLane(tempData);
            return tempData;
        }
    };

    //------------------------- Place to define Socket related functions ------------------------------
    // Function to get all chat for logged in user
    const fetchAllComment = async () => {
        try {
            let response = await axios({
                url: environmentVariables.getchatdetails + '?user_id=' + user.email,
                method: 'get'
            });
            let data;
            if (response) {
                data = JSON.parse(response.data.body);
                if (data.Items !== undefined) {
                    setChatDetails(data.Items);
                    fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
                }
            }
            return data.Items;
        }
        catch (error) {
            console.log(error);
        }
    };

    //On Submitting Counter Offer
    const ifPaneClose = (recievedMessage) => {
        Swal.fire({
            text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been submitted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]} Click here 'Ok' to review counter offer or 'Cancel' to review later`,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok'
        }
        ).then(async (result) => {
            if (result.value === true) {
                if (isPaneOpenForMatchedLane === true) {
                    if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                        loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1]);
                    }
                    else {
                        closeSecondaryPaneForMatchedLane();
                        window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                    }
                }
                else {
                    window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                    let fetchLoadResponse = await fetchLoadApiCall(user.email);
                    if (fetchLoadResponse) {
                        fetchLoadResponse.forEach(element => {
                            if (element.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                                fetchMatchedData(element.pickup_dest, element.delivery_dest, element.pick_upfrom_date, element.pickupto_date);
                            }
                        });
                    }
                }
            }
        });
    };

    // Adding values to the secondary pane
    const populateSecondaryPaneValuesForMatchedLane = (findResult) => {
        setLoadNumberDetail(findResult.load_number);
        setLoadPostedByUser(findResult.posted_by_user);
        setLoadPostedBy(findResult.posted_by_company);
        setLoadLoadNotes(findResult.load_notes);
        setLoadMaxWeightForMatchedLane(findResult.max_weight);
        setLoadPickUpdateForMatchedLame(findResult.pickupfrom_date);
        setLoadDeliverByDateForMatchedLane(findResult.pickupto_date);
        setLoadStatus(findResult.status);
        if (findResult.truck_type === '' || findResult.truck_type.length === 0) {
            setLoadLoadTypeForMatchedLane('NA');
        }
        else {
            setLoadLoadTypeForMatchedLane(findResult.truck_type.toString().replace(/,/g, ', '));
        }
        if (findResult.status) {
            if (findResult.status === 'Closed') {
                setLoadBidAmountDisable(true);
            }
            else {
                if (!findResult.bid_amount) {
                    setLoadBidAmountDisable(false);
                }
            }
        }
        if (findResult.total_item_count === '') {
            setLoadNoOfPiecesForMatchedLane('NA');
        }
        else {
            setLoadNoOfPiecesForMatchedLane(findResult.total_item_count);
        }
        let freightDimension = '';
        if (findResult.freight_dimension_length.toString().trim() === '' &&
            findResult.freight_dimension_width.toString().trim() === '' &&
            findResult.freight_dimension_height.toString().trim() === '') {
            setLoadFreightDimension('NA');
        }
        else {
            if (findResult.freight_dimension_length.toString().trim() === '') {
                freightDimension += 'NA X ';
            }
            else {
                freightDimension += findResult.freight_dimension_length.toString().trim() + 'L X ';
            }
            if (findResult.freight_dimension_width.toString().trim() === '') {
                freightDimension += 'NA X ';
            }
            else {
                freightDimension += findResult.freight_dimension_width.toString().trim() + 'W X ';
            }
            if (findResult.freight_dimension_height.toString().trim() === '') {
                freightDimension += 'NA';
            }
            else {
                freightDimension += findResult.freight_dimension_height.toString().trim() + 'H';
            }
            setLoadFreightDimension(freightDimension);
        }
        setLoadStackable(Boolean(findResult.stackable));
        setLoadHazmat(Boolean(findResult.hazmat));
        setLoadDockHigh(Boolean(findResult.dockhigh));
        setLoadTempControl(Boolean(findResult.tempcontrol));
        if (findResult.cities_distance) {
            setLoadCityDistance(findResult.cities_distance);
        }
        if (
            findResult.pickup_from_window === 'ASAP' &&
            findResult.pickup_to_window === 'ASAP'
        ) {
            setLoadPickUpWindow('ASAP');
        } else {
            setLoadPickUpWindow(
                findResult.pickup_from_window + ' - ' + findResult.pickup_to_window
            );
        }
        if (
            findResult.deliver_from_window.includes('Direct') &&
            findResult.deliver_to_window.includes('Direct')
        ) {
            setLoadDeliverWindow('Deliver Direct(Non-Stop)');
        } else {
            setLoadDeliverWindow(
                findResult.deliver_from_window + ' - ' + findResult.deliver_to_window
            );
        }
        if (findResult.bid_amount) {
            setLoadBidAmount(findResult.bid_amount);
            setLoadBidAmountDisable(true);
        }
        else {
            setLoadBidAmount('');
            setLoadBidAmountDisable(false);
        }
        if (findResult.bid_notes) {
            updateBidNotes(findResult.bid_notes);
            setLoadBidNotesDisable(true);
        }
        else {
            if (findResult.bid_notes === undefined || findResult.bid_notes === '') {
                if (findResult.bid_amount === '' || findResult.bid_amount === undefined) {
                    updateBidNotes('');
                    setLoadBidNotesDisable(false);
                }
                else {
                    setLoadBidNotesDisable(true);
                }
            }
        }
        if (findResult.miles_out) {
            updateTxtMilesOut(findResult.miles_out);
            setMilesOutDisable(true);
        }
        else {
            if (findResult.miles_out === undefined || findResult.miles_out === '') {
                if (findResult.bid_amount === '' || findResult.bid_amount === undefined) {
                    updateTxtMilesOut('');
                    setMilesOutDisable(false);
                }
                else {
                    setMilesOutDisable(true);
                }
            }

        }
        if (findResult.truck_dimension && findResult.truck_dimension !== undefined && findResult.truck_dimension !== '{}') {
            if (findResult.truck_dimension !== 'LXWXH') {
                setTxtLength(findResult.truck_dimension_length);
                setTxtHeight(findResult.truck_dimension_height);
                setTxtWidth(findResult.truck_dimension_width);
                setLengthDisable(true);
                setWidthDisable(true);
                setHeightDisable(true);
            }
            else if (findResult.truck_dimension === 'LXWXH') {
                setTxtLength('');
                setTxtHeight('');
                setTxtWidth('');
                setLengthDisable(true);
                setWidthDisable(true);
                setHeightDisable(true);
            }
            else if (findResult.truck_dimension === undefined) {
                setTxtLength('');
                setTxtHeight('');
                setTxtWidth('');
                setLengthDisable(false);
                setWidthDisable(false);
                setHeightDisable(false);
                setMilesOutDisable(false);
            }

        }
        else if (findResult.truck_dimension === 'LXWXH') {
            setTxtLength('');
            setTxtHeight('');
            setTxtWidth('');
            setLengthDisable(true);
            setWidthDisable(true);
            setHeightDisable(true);
        }
        else if (findResult.truck_dimension === undefined || findResult.truck_dimension === '{}') {
            setTxtLength('');
            setTxtHeight('');
            setTxtWidth('');
            setLengthDisable(false);
            setWidthDisable(false);
            setHeightDisable(false);
            setMilesOutDisable(false);
        }
        // Handle bid expiration dropdown
        if (findResult.bid_expiration_time) {
            setBidExpiration(findResult.bid_expiration_time);
            setLoadBidAmountDisable(true);
        }
        else {
            setBidExpiration('15');
            setLoadBidAmountDisable(false);
        }
        if (findResult.status) {
            setLoadStatus(findResult.status);
            if (findResult.status === 'Closed') {
                setLoadBidAmountDisable(true);
            }
            else {
                if (!findResult.bid_amount) {
                    setLoadBidAmountDisable(false);
                }
            }
        }
        if (findResult.time_stamp) {
            var localTime = moment.utc(findResult.time_stamp).local().format('YYYY-MM-DD HH:mm:ss');
            setloadPostedTime(localTime);
        }
        if (findResult.load_expiration_time) {
            setLoadUpdateLoadExpirationTimeForHour(findResult.load_expiration_time.split(':')[0]);
            setLoadUpdateLoadExpirationTimeForMin(findResult.load_expiration_time.split(':')[1]);
            setLoadExpiresIn(loadExpirationInMiliseconds(findResult.load_expiration_time));
        }
        else {
            setLoadExpiresIn(0);
        }
        setLoadPickUpDestinationForMatchedLane(findResult.pickup_dest);
        setLoadDeliverDestinationForMatchedLane(findResult.deliverzip);
        setLoadDeliverDestinationForMatchedLane(findResult.delivery_dest);
        if (findResult.watch_load) {
            if (findResult.watch_load.length && findResult.watch_load.length !== 0) {
                const findUserWatchLoad = findResult.watch_load.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserWatchLoad) {
                    setLoadWatchLoad(true);
                }
                else {
                    setLoadWatchLoad(false);
                }
            }
            else {
                setLoadWatchLoad(false);
            }
        }
        if (findResult.saved_lane) {
            if (findResult.saved_lane.length !== 0 && findResult.saved_lane.length !== undefined) {
                const findUserSavedLane = findResult.saved_lane.find(({ name, accStatus }) => name === user.email && accStatus === true);
                if (findUserSavedLane) {
                    setLoadSavedLane(true);
                }
                else {
                    setLoadSavedLane(false);
                }
            }
            else {
                setLoadSavedLane(false);
            }
        }
    };

    //Function to execeute On Accepting Counter Offer
    const openPaneOnAcceptCounterOffer = async (findResult) => {
        populateSecondaryPaneValuesForMatchedLane(findResult);
        fetchAllComment();
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = false;
            }
        }
        setColumnDefsLoadTable(FilterColumns);
        setMainGridPercentForMatchedLane('35%');
        setIsPaneOpenForMatchedLane(true);
        setDisplaySpecificColums(false);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        setSelectedRowDataValue(findResult);
        loadAndRealoadSecondGrid(findResult.load_id);
    };

    const onAcceptCheckLoadId = async (recievedMessage) => {
        try {
            let fetchLoadResponse = await fetchLoadApiCall(user.email);
            if (fetchLoadResponse) {
                fetchLoadResponse.forEach(element => {
                    if (element.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                        openPaneOnAcceptCounterOffer(element);
                    }
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //Function to scroll bottom
    const scrollToBottom = () => {
        animateScroll.scrollToBottom({});
    };

    //Function to scroll On recieveing Message
    const scrollForParticularLoad = (recievedMessage) => {
        if (isPaneOpen === true) {
            if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                scrollToBottom();
            }
        }
    };

    const handleUpdatePostLoadTable = async (recievedMessage) => {
        try {
            let fetchLoadResponse = await fetchLoadApiCall(user.email);
            if (fetchLoadResponse) {
                const findResult = fetchLoadResponse.find(
                    ({ load_id }) => load_id === recievedMessage.split('||')[1].split(':')[1]
                );
                selectedRowDataValueForSocketIO = findResult;
                setSelectedRowDataValue(findResult);
                populateSecondaryPaneValuesForMatchedLane(findResult);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    // Function to recive all the socket io messages
    const recieveMessageForSocketIo = async (message) => {
        let recievedMessage = message.data.replace(/\'/g, '');

        // Recieveing a message from carrier for new load
        if (recievedMessage.includes('newLoadHasBeenPlaced')) {
            if (isExpand === false) {
                fetchMainMuiDatatableValues();
            }
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
        }

        // Receive a message on counter offer
        if (recievedMessage.includes('ThereIsACounterOffer') || recievedMessage.includes('CounterOfferIsUpdated')) {
            if (isPaneOpenForMatchedLane === false) {
                ifPaneClose(recievedMessage);
            }
            else {
                if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been submitted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]} Click here 'Ok' to review counter offer`,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    });
                    loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1]);
                    fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
                }
                else {
                    ifPaneClose(recievedMessage);
                }
            }
        }
        // Receive a message on counter offer
        if (recievedMessage.includes('CounterOfferAccepted')) {
            if (isPaneOpenForMatchedLane === false) {
                Swal.fire({
                    text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been accepted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]}Click here 'Ok' to review counter offer or 'Cancel' to review later `,
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0066cc',
                    cancelButtonColor: '#0066cc',
                    confirmButtonText: 'Ok'
                }
                ).then(async (result) => {
                    if (result.value === true) {
                        window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                        onAcceptCheckLoadId(recievedMessage);
                    }
                });
            }
            else {
                if (recievedMessage.split('||')[1].split(':')[1] === selectedRowDataValue.load_id) {
                    loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been accepted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]} Click here 'Ok' to review counter offer`,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    });
                    onAcceptCheckLoadId(recievedMessage);
                }
                else {
                    Swal.fire({
                        text: `Counter offer of $${recievedMessage.split('||')[3].split(':')[1]} has been accepted on Load from ${recievedMessage.split('||')[4].split(':')[1]} to ${recievedMessage.split('||')[5].split(':')[1]} for the Load ID ${recievedMessage.split('||')[6].split(':')[1]}Click here 'Ok' to review counter offer or 'Cancel' to review later `,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonColor: '#0066cc',
                        cancelButtonColor: '#0066cc',
                        confirmButtonText: 'Ok'
                    }
                    ).then(async (result) => {
                        if (result.value === true) {
                            closeSecondaryPaneForMatchedLane();
                            fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
                            window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
                            onAcceptCheckLoadId(recievedMessage);
                        }

                    });
                }

            }
        }

        // Recieve message for update bid
        if (recievedMessage.includes('BrokerLoadHasBeenWatchList') || recievedMessage.includes('BrokerLoadHasBeenWatchListRemoved')) {
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        // receiving message for new notification
        if (recievedMessage.includes('ThereIsNewNotifiction')) {
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
            if (isPaneOpenForSocketIO) {
                handleUpdatePostLoadTable(recievedMessage);
            }
        }

        if (recievedMessage.includes('chatHasBeenRead')) {
            fetchAllComment();
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        // Recieveing a message from carrier for new message
        if (recievedMessage.includes('ThereIsANewMessageFromBroker')) {
            fetchAllComment();
            scrollForParticularLoad(recievedMessage);
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    fetchAllComments({
                        'load_id': recievedMessage.split('||')[1].split(':')[1],
                        'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                    }
                    );
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
            else {
                fetchAllComment();
            }
        }

        if (recievedMessage.includes('YourLoadHasBennAccepted')) {
            let notificationMessage = '';
            if (recievedMessage.split('||')[2].split(':')[1] === user.email) {
                notificationMessage = 'Your bid has been accepted';
            }
            else {
                notificationMessage = 'Load has been awarded';
            }
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
            closeSecondaryPaneForMatchedLane();
            if (isPaneOpenForSocketIO) {
                Swal.fire({
                    text: notificationMessage,
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: COLORS.websiteColor
                });
            }
            else {
                isPaneOpenForSocketIO = true;
                if (isPaneOpenForSocketIO) {
                    Swal.fire({
                        text: notificationMessage,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: COLORS.websiteColor
                    });
                }
                isPaneOpenForSocketIO = false;
            }
        }

        if (recievedMessage.includes('BrokerLoadHasBeenUpdated')) {
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    handleUpdatePostLoadTable(recievedMessage);
                }
            }
        }

        if (recievedMessage.includes('BrokerLoadHasBeenDeleted') || recievedMessage.includes('BrokerLoadHasBeenExpired')) {
            let messageText = '';
            if (recievedMessage.includes('BrokerLoadHasBeenDeleted')) {
                messageText = 'Load has been deleted';
            }
            if (recievedMessage.includes('BrokerLoadHasBeenExpired')) {
                messageText = 'Load has been Expired';
            }
            if (isExpand === false) {
                fetchMainMuiDatatableValues();
            }
            if (isPaneOpenForMatchedLane === true) {
                fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
            }
            closeSecondaryPaneForMatchedLane();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    Swal.fire({
                        text: messageText,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                }
            }
        }

        // Recive a message on new bid
        if (recievedMessage.includes('YourBidHasExpired')) {
            if (recievedMessage.split('||')[2].split(':')[1] === user.email) {
                fetchAllComments({
                    'load_id': recievedMessage.split('||')[1].split(':')[1],
                    'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                });
                if (isPaneOpenForSocketIO) {
                    if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                        loadAndRealoadSecondGrid(recievedMessage.split('||')[1].split(':')[1],);
                        if (isPaneOpenForMatchedLane === true) {
                            fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
                        }
                        handleUpdatePostLoadTable(recievedMessage);
                    }
                }
                else {
                    if (isPaneOpenForMatchedLane === true) {
                        fetchMatchedData(selectedRowDataValue.pickup_dest, selectedRowDataValue.delivery_dest, selectedRowDataValue.pick_upfrom_date, selectedRowDataValue.pickupto_date);
                    }
                }
            }
        }
    };

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    //Function to createCommentNotification
    const createCommentNotification = async (data) => {
        try {
            let response = await axios({
                url: environmentVariables.createCommentNotification,
                method: 'patch',
                data: data,
            });
            return response;
        }
        catch (error) {
            console.log(error);
        }
    };

    const onSendMessageClick = async (enteredMessage, messageType, loadChatMessages) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            if (loadChatMessages === undefined || loadChatMessages.length === 0) {
                loadChatMessages = chatAllMessages;
            }
            if (enteredMessage !== null && enteredMessage !== '') {
                let Bid = '';
                if (messageType === 'System Message') {
                    if (enteredMessage === 'Bid Closed') {
                        Bid = 'Bid Closed';
                    }
                    else if (counterOffer !== '' && counterOffer !== undefined) {
                        Bid = counterOffer;
                    }
                    else {
                        Bid = loadBidAmount;
                    }
                }
                if (document.getElementById('inputMesage') && document.getElementById('inputMesage').value !== null) {
                    document.getElementById('inputMesage').value = '';
                }
                let pin = selectedRowDataValue.pinned_user;
                if (selectedRowDataValue.pinned_user === undefined) {
                    pin = false;
                }
                if (loadChatMessages && loadChatMessages.length === 0) {
                    const secondaryArray = [
                        {
                            time_stamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            broker_id: selectedRowDataValue.posted_by_user,
                            broker_company: selectedRowDataValue.posted_by_company,
                            broker_mc_number: selectedRowDataValue.posted_by_mc_number,
                            carrier_id: user.email,
                            carrier_company: userCompanyName,
                            carrier_mc_number: loggedInCompanyMCNumber,
                            load_id: selectedRowDataValue.load_id,
                            bid_amount: '$' + Bid,
                            driver_id: selectedRowDataValue.driver_name,
                            pinned_user: pin,
                            messages: [
                                {
                                    date: moment().format('hh:mm A'),
                                    messanger_id: user.email,
                                    message: enteredMessage,
                                    user: user.nickname,
                                    message_type: messageType,
                                    read_status: [{ name: user.email, status: true }, { name: selectedRowDataValue.posted_by_user, status: false }],
                                },
                            ],
                        },
                    ];
                    let createCommentResponse = await createCommentApiCall(secondaryArray[0], 'put');
                    if (createCommentResponse) {
                        fetchAllComments(createCommentResponse);
                        setChatAllMessages(createCommentResponse);
                        fetchAllComment();
                    }
                    if (user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser !== user.email) {
                        sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                        sendMessageForSocketIO('newNotification', '', false);
                        setIsNotificationSent(true);
                    }
                    return await createCommentNotification({
                        data: {
                            load_id: selectedRowDataValue.load_id,
                            fromEmail: user.email,
                            toEmail: selectedRowDataValue.posted_by_user,
                            message: enteredMessage,
                        },
                    });
                } else {
                    if (enteredMessage !== 'Bid Closed' || (enteredMessage === 'Bid Closed' && loadChatMessages.bid_amount !== 'Bid Closed')) {
                        const secondaryArray = loadChatMessages;
                        secondaryArray.time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                        if (messageType === 'System Message') {
                            if (enteredMessage === 'Bid Closed') {
                                secondaryArray.bid_amount = 'Bid Closed';
                            }
                            else if (counterOffer !== '' && counterOffer !== undefined) {
                                secondaryArray.bid_amount = '$' + counterOffer;
                            }
                            else {
                                secondaryArray.bid_amount = '$' + loadBidAmount;
                            }
                        }
                        secondaryArray.messages = [
                            ...secondaryArray.messages,
                            {
                                date: moment().format('hh:mm A'),
                                messanger_id: user.email,
                                message: enteredMessage,
                                user: user.nickname,
                                message_type: messageType,
                                read_status: [{ name: user.email, status: true }, { name: user.email === secondaryArray.broker_id ? secondaryArray.carrier_id : secondaryArray.broker_id, status: false }],
                            },
                        ];
                        let response = await updateTestComment(secondaryArray, 'patch');
                        if (response) {
                            setChatAllMessages(JSON.parse(response.data.body));
                            fetchAllComment();
                        }
                        if (document.getElementById('inputMesage') && document.getElementById('inputMesage').value !== null) {
                            document.getElementById('inputMesage').value = '';
                        }
                        if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || loadPostedByUser !== user.email) {
                            fetchAllComments(JSON.parse(response.data.body));
                            sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                            sendMessageForSocketIO('newNotification', '', false);
                            setIsNotificationSent(true);
                        }
                        return await createCommentNotification({
                            data: {
                                load_id: selectedRowDataValue.load_id,
                                fromEmail: user.email,
                                toEmail: selectedRowDataValue.posted_by_user,
                                message: enteredMessage
                            },
                        });
                    }
                }
            }
        }
    };

    //---------------------------------ToSubmitOffer---------------------------------------------------------------------//

    const toShowAlert = () => {
        if (counterOffer === '') {
            Swal.fire({
                text: 'Counter Offer Value Is Empty',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setCounterOpen(false);
            setCounterOpenCarrier(false);
        }
    };

    const toGetData = () => {
        fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
        loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
        fetchAllComments(selectedRowDataValue);
    };

    const toStoreCounterOffer = async (selectedRowDataValue) => {
        const data = {
            load_id: selectedRowDataValue.load_id,
            bid_id: selectedRowDataValue.bid_id,
            carrier_name: selectedRowDataValue.carrier_name,
            carrier_company: selectedRowDataValue.carrier_company,
            bid_amount: counterOffer,
            bid_time_stamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            counter_offer: counterOffer,
            counteroffer_sent: (loadPostedByUser === user.email) ? 'broker' : 'carrier',
            counteroffer_status: 'New',
            bid_Notes: selectedRowDataValue.bid_notes,
            bid_expiration_time: selectedRowDataValue.bid_expiration_time,
            miles_Out: selectedRowDataValue.miles_out,
            Truck_dimension_height: selectedRowDataValue.truck_dimension_height,
            Truck_dimension_length: selectedRowDataValue.truck_dimension_length,
            Truck_dimension_width: selectedRowDataValue.truck_dimension_width,
            truck_dimension: selectedRowDataValue.truck_dimension
        };
        try {
            const counterOfferResponse = await editBidApiCall(data);
            if (counterOfferResponse) {
                onSendMessageClick(`Counter Offer of $${counterOffer} submitted by ${userCompanyName} `, 'System Message');
                setSnackBarMessageText(`Counter Offer is Submitted by ${userCompanyName} and Email Sent`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
                toGetData();
                if (loggedInuserCompanyRole === 'Carrier' || loadPostedByUser !== user.email) {
                    sendMessageForSocketIO('CounterOfferUpdated||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValue, selectedRowDataValue);
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser === user.email) {
                    sendMessageForSocketIO('ThereIsACounterOffer||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Favorites', selectedRowDataValue.carrier_name, true, selectedRowDataValue, selectedRowDataValue);
                    let emailReceiver = '';
                    let loadUrl = '';
                    if (loadPostedByUser !== user.email) {
                        emailReceiver = selectedRowDataValue.posted_by_user;
                        loadUrl = window.location.origin + '/activeloads?loadId=' + slugid.encode(selectedRowDataValue.load_id);
                    }
                    else {
                        emailReceiver = selectedRowDataValue.carrier_name;
                        loadUrl = window.location.origin + '/activebids?loadId=' + slugid.encode(selectedRowDataValue.load_id);
                    }
                    let mailList = selectedRowDataValue;
                    mailList = {
                        ...mailList, 'posted_by_user': (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && loggedInuserRole !== 'Broker' && selectedRowDataValue.posted_by_user !== user.email)) ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name,
                        'counter_offer': counterOffer,
                        'load_notes': selectedRowDataValue.load_note,
                        'load_dimension': loadFreightDimension,
                        'load_posted_by': selectedRowDataValue.posted_by_user,
                        'loadUrl': loadUrl, 'name': emailReceiver,
                        'bid_amount': selectedRowDataValue.bid_amount,
                        'bid_Notes': selectedRowDataValue.bid_notes,
                        'miles_Out': selectedRowDataValue.miles_out,
                        'truck_dimension': selectedRowDataValue.truck_dimension
                    };
                    const counterOfferEmailResponse = await counterOfferNotificationApiCall(mailList);
                    if (counterOfferEmailResponse) {
                        setSnackBarMessageText(`Counter Offer is Submitted by ${userCompanyName} and Email Sent`);
                        setSnackBarSeverity('success');
                        setOpenSnackBar(true);
                    }
                }
                // catch (err) {
                //     console.log(err);
                // }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const toSubmitOffer = () => {
        if (counterOffer !== '' && counterOffer !== '0' && counterOffer !== '00') {
            let regExp = /^0+$/g;
            let found = regExp.test(counterOffer);
            let result = found ? true : false;
            if (result === false) {
                toStoreCounterOffer(selectedRowDataValue);
            }
        }
        else {
            toShowAlert();
        }
    };

    const submitCounterOffer = () => {
        toSubmitOffer();
        setCounterOpen(false);
        setCounterOpenCarrier(false);
    };

    const cancelCounterOffer = () => {
        setCounterOpen(false);
        setCounterOffer('');
    };

    const closeForm = () => {
        setCounterOpenCarrier(false);
    };
    //-----------------------------------------Handle Changes------------------------------------------------------------------------//
    const handleCounterOffer = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setCounterOffer(event.target.value);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key !== 'Enter') {
            handleCounterOffer(event);
        }
        else {
            event.preventDefault();
            submitCounterOffer();
        }
    };

    // Adding values to the secondary pane
    const populateSecondaryPaneValues = (findResult) => {
        setLoadId(findResult.load_id);
        setLaneNumberDetail(findResult.lane_number);
        setLaneCount(findResult.matched_lane);
        if (findResult.max_weight !== '') {
            setLoadMaxWeight(findResult.max_weight);
            if (findResult.max_weight !== 'NA') {
                updateTxtMaxWeight(findResult.max_weight);
            }
        }
        else {
            setLoadMaxWeight('NA');
        }
        if (findResult.pick_upfrom_date === 'Invalid date' || findResult.pick_upfrom_date === 'NA') {
            setLoadPickUpdate('NA');
        }
        else {
            setLoadPickUpdate(findResult.pick_upfrom_date);
        }
        if (findResult.pickupto_date === 'Invalid date' || findResult.pickupto_date === 'NA') {
            setLoadDeliverByDate('NA');
        }
        else {
            setLoadDeliverByDate(findResult.pickupto_date);
        }
        if (findResult.truck_type === '' || findResult.truck_type.length === 0) {
            setLoadLoadType('NA');
        }
        else {
            setLoadLoadType(findResult.truck_type.toString().replace(',', ', '));
            setSaveCheckboxes(findResult.truck_type);
        }
        if (findResult.total_item_count === '') {
            setLoadNoOfPieces('NA');
        }
        else {
            if (findResult.total_item_count !== 'NA') {
                updateTxtTotalNoOfPices(findResult.total_item_count);
            }
            setLoadNoOfPieces(findResult.total_item_count);
        }
        if (findResult.pickup_dest === ',  - ') {
            setLoadPickUpDestination('NA');
        }
        else {
            setLoadPickUpDestination(findResult.pickup_dest);
            updateTxtFromCity(findResult.pickup_dest);
            setSelectedTxtFromCity(findResult.pickup_dest);
        }
        if (findResult.miles_from === '') {
            setMilesWithinFrom('NA');
        }
        else {
            if (findResult.miles_from !== 'NA') {
                updateMilesWithinFromDestination(findResult.miles_from);
            }
            setMilesWithinFrom(findResult.miles_from);
        }
        if (findResult.delivery_dest === ',  - ') {
            setLoadDeliverDestination('NA');
        }
        else {
            setLoadDeliverDestination(findResult.delivery_dest);
            updateTxtToCity(findResult.delivery_dest);
            setSelectedTxtToCity(findResult.delivery_dest);
        }
        if (findResult.miles_to === '') {
            setMilesWithinTo('NA');
        }
        else {
            if (findResult.miles_to !== 'NA') {
                updateMilesWithinToDestination(findResult.miles_to);
            }
            setMilesWithinTo(findResult.miles_to);
        }
    };

    // Function to handle txtlength
    const handletxtLength = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setTxtLength(event.target.value);
            }
        }
    };

    const handletxtWidth = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setTxtWidth(event.target.value);
            }
        }
    };

    const handletxtHeight = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setTxtHeight(event.target.value);
            }
        }
    };

    // Function to set and check MilesOut textfield
    const handelTxtMilesOutChange = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                updateTxtMilesOut(event.target.value);
            }
        }
    };

    /******************Function to accept counter Offer*************************************/
    const acceptCounterOfferCarrier = async () => {
        if (counterOffer !== '' && counterOffer !== '0' && counterOffer !== '00') {
            setCounterOpenCarrier(false);
            setCounterOffer('');
            let listValue = selectedRowDataValue;
            listValue.status = 'new';
            listValue.bid_time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            listValue.counter_offer = counterOffer;
            listValue.counteroffer_status = selectedRowDataValue.counteroffer_sent === 'newoffer' ? 'broker' : 'carrier';
            listValue.counteroffer_status = 'Accept';
            listValue.bid_Notes = selectedRowDataValue.bid_notes;
            listValue.miles_Out = selectedRowDataValue.miles_out;
            listValue.Truck_dimension_height = selectedRowDataValue.truck_dimension_height;
            listValue.Truck_dimension_length = selectedRowDataValue.truck_dimension_length;
            listValue.Truck_dimension_width = selectedRowDataValue.truck_dimension_width;
            let counterOfferResponse = await editBidApiCall(listValue);
            if (counterOfferResponse) {
                onSendMessageClick(` ${userCompanyName} Accepted  Counter Offer  $${counterOffer}`, 'System Message');
                setSnackBarMessageText(`${userCompanyName} Accepted Counter Offer and Email Sent`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
                if (loggedInuserCompanyRole === 'Carrier' || loadPostedByUser !== user.email) {
                    sendMessageForSocketIO('CounterOfferAccepted||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Loads Posted', selectedRowDataValue.posted_by_user, false, selectedRowDataValue, selectedRowDataValue);
                    setIsNotificationSent(true);
                }
                else if (loadPostedByUser === user.email) {
                    sendMessageForSocketIO('CounterOfferAccepted||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user + '||amount:' + counterOffer + '||from:' + selectedRowDataValue.pickup_dest + '||to:' + selectedRowDataValue.delivery_dest + '||load_number:' + selectedRowDataValue.load_number + '||table_name:Favorites', selectedRowDataValue.carrier_name, false, selectedRowDataValue, selectedRowDataValue);
                    setIsNotificationSent(true);
                }
                toGetData();
                try {
                    let mailList = selectedRowDataValue;
                    mailList = {
                        ...mailList, 'counter_offer': counterOffer,
                        'load_notes': selectedRowDataValue.load_notes,
                        'load_dimension': loadFreightDimension,
                        'load_posted_by': selectedRowDataValue.posted_by_user,
                        'broker_loadUrl': window.location.origin + '/activeloads?loadId=' + slugid.encode(selectedRowDataValue.load_id),
                        'carrier_loadUrl': window.location.origin + '/activebids?loadId=' + slugid.encode(selectedRowDataValue.load_id),
                        'name': loadPostedByUser !== user.email ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name,
                        'bid_amount': selectedRowDataValue.bid_amount,
                        'bid_Notes': selectedRowDataValue.bid_notes,
                        'miles_Out': selectedRowDataValue.miles_out,
                        'truck_dimension': selectedRowDataValue.truck_dimension,
                        'carrier_name': selectedRowDataValue.carrier_name,
                        'posted_by_user': selectedRowDataValue.posted_by_user
                    };
                    const acceptCounterOfferEmailResponse = await acceptCounterOfferNotificationApiCall(mailList);
                    if (acceptCounterOfferEmailResponse) {
                        setSnackBarMessageText(`${userCompanyName} Accepted Counter Offer and Email Sent`);
                        setSnackBarSeverity('success');
                        setOpenSnackBar(true);
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
        else {
            Swal.fire({
                text: 'Counter Offer Cannot be Zero Or Empty Value',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
            setCounterOpenCarrier(false);
        }
    };

    /************************************************Bid Functionality ******************************************************/
    // Function to handle Bid Expiration  Minutes On Input
    const loadHandleBidExpirationChangeForMin = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setBidExpiration(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setBidExpiration(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setBidExpiration(timeValue);
                }
            }
            else {
                setBidExpiration('59');
            }
        } else if (newInputValue.length > 2) {
            setBidExpiration('59');
        }
        else {
            setBidExpiration(newInputValue);
        }
        if (newInputValue === '00') {
            setBidExpiration('15');
        }
    };

    //Function To handle Bid Expiration On Change From autocomplete
    const handleSetBidExpirationOnchangeValueForMin = (event, newValue) => {
        if (newValue === '00') {
            setBidExpiration('15');
        }
        else {
            setBidExpiration(newValue);
        }
    };

    //Function to handle On Close Of Bid Expiration time Field
    const loadHandleBidExpirationCloseForMin = () => {
        if (bidExpiration === '00') {
            setBidExpiration('15');
        }
        let valueOnClose = bidExpiration;
        if (valueOnClose === '1' || valueOnClose === '2' || valueOnClose === '3' || valueOnClose === '4') {
            setBidExpiration('05');
        }
        else if ((valueOnClose >= '0' && valueOnClose <= '9')) {
            if (valueOnClose.length < 2) {
                setBidExpiration('0' + valueOnClose);
            }
        }
        else {
            setBidExpiration('15');
        }
    };

    //function to send system message For Bid Details
    const bidDetailMsg = async (listValue, bidType) => {
        if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
            onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes} ,
                Miles Out : ${listValue.miles_Out} ,
                Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
        }
        else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
            onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes} ,
                Miles Out : ${listValue.miles_Out} ,
                Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
        }
        else {
            if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && bidType === 'Newbid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}, Miles Out : ${listValue.miles_Out} is submitted `, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && listValue.miles_Out !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}, Miles Out : ${listValue.miles_Out} is updated `, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out}, 
                Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out}, 
                Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}, Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes}, Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && bidType === 'Newbid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.bid_Notes !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, BidNotes: ${listValue.bid_Notes} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && bidType === 'Newbid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && listValue.miles_Out !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, Miles Out : ${listValue.miles_Out} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Newbid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, Truck Dimension :${listValue.truck_dimension} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && (listValue.truck_dimension !== '' && listValue.truck_dimension !== 'LXWXH') && bidType === 'Updatebid') {
                onSendMessageClick(`BidAmount: ${'$' + listValue.bid_amount}, Truck Dimension :${listValue.truck_dimension} is updated`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && bidType === 'Newbid') {
                onSendMessageClick(`Bid of ${'$' + loadBidAmount} is submitted`, 'System Message');
            }
            else if (listValue.bid_amount !== '' && bidType === 'Updatebid') {
                onSendMessageClick(`Bid of ${'$' + loadBidAmount} is updated`, 'System Message');
            }
        }
    };

    //Function used repeatedly To Diasble Bid Related Details
    const disableBidDetails = () => {
        setLoadBidAmountDisable(true);
        setMilesOutDisable(true);
        setLoadBidNotesDisable(true);
        setLengthDisable(true);
        setWidthDisable(true);
        setHeightDisable(true);
    };

    //Function toStoreBidResponse
    const toGetBidResponse = async (selectedRowDataValue) => {
        let fetchLoadResponse = await fetchLoadApiCall(user.email);
        if (fetchLoadResponse !== undefined) {
            const singleLoadId = fetchLoadResponse.find(
                ({ load_id }) => load_id === selectedRowDataValue.load_id
            );
            populateSecondaryPaneValuesForMatchedLane(singleLoadId);
            fetchAllComments(singleLoadId);
            selectedRowDataValueForSocketIO = singleLoadId;
            setSelectedRowDataValue(singleLoadId);
        }
    };

    //Function to handle listValue For Bid
    const toStoreCreateBid = async (listValue) => {
        // setSnackBarMessageText(`Bid sending to ${selectedRowDataValue.posted_by_company}...`);
        // setSnackBarSeverity('info');
        // setOpenSnackBar(true);
        try {
            const createBidResponse = await createBidApiCall(listValue);
            if (createBidResponse) {
                bidDetailMsg(listValue, 'Newbid');
                setDisableBidButton(false);
                fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
                loadAndRealoadSecondGrid(selectedRowDataValue.load_id);
                setSnackBarMessageText(`Bid sent to ${selectedRowDataValue.posted_by_company}`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
                sendMessageForSocketIO('ThereIsANewBid||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, listValue);
                sendMessageForSocketIO('newNotification', '', false);
                setIsNotificationSent(true);
                disableBidDetails();
                toGetBidResponse(listValue);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //Function to StoreBidValue
    const addBidDetails = async () => {
        setDisableBidButton(true);
        const findResult = rowDataForMatchedLane.find(
            ({ load_id }) => load_id === selectedRowDataValue.load_id
        );
        let listValue = {
            ...findResult,
            'carrier_name': user.email,
            'carrier_company': userCompanyName,
            'bid_amount': loadBidAmount,
            'bid_Notes': BidNotes,
            'status': 'new',
            'bid_expiration_time': bidExpiration + ' min',
            'load_dimension': loadFreightDimension,
            'miles_Out': txtMilesOut,
            'Truck_dimension_length': txtLength,
            'Truck_dimension_width': txtWidth,
            'Truck_dimension_height': txtHeight,
            'truck_dimension': txtLength + 'LX' + txtWidth + 'WX' + txtHeight + 'H',
            'loadUrl': window.location.origin + '/activeloads?loadId=' + slugid.encode(selectedRowDataValue.load_id),
            'bid_time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            'counter_offer': '',
            'counteroffer_sent': 'newoffer',
            'counteroffer_status': 'New',
        };
        await createBidNotificationApiCall(listValue);
        toStoreCreateBid(listValue);
        fetchAllComments(listValue);
    };

    //Function to Show Alert ON Miles Out andTruck Dimension Is empty
    const milesTruckDimension = async (Data) => {
        setDisableBidButton(false);
        Swal.fire({
            text: `Providing ${Data} greatly increases the chances of winning the bid, Click 'OK' to Continue OR Click 'Cancel' to Enter Value `,
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
        }
        )
            .then((result) => {
                if ((result.value === true) || (txtMilesOut !== '' && txtLength !== '' && txtHeight !== '' && txtWidth !== '')) {
                    disableBidDetails();
                    addBidDetails();
                }
            });
    };

    //Function to Validate Bid Now
    const bidValidate = () => {
        let temp = {};
        if (loadBidAmount === '') {
            temp.loadBidAmount = 'error';
        }
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    //Function to Validate ReBid 
    const reBidValidate = () => {
        let temp = {};
        if (loadBidAmount === '') {
            temp.loadBidAmount = 'error';
        }
        if (BidNotes === '0' || BidNotes === 0) {
            temp.BidNotes = 'error'
        }
        if (bidExpiration === '0' || bidExpiration === 0) {
            temp.bidExpiration = 'error'
        }
        if (txtMilesOut === '0' || txtMilesOut === 0) {
            temp.txtMilesOut = 'error'
        }
        if (txtHeight === '0' || txtHeight === 0) {
            temp.txtHeight = 'error'
        }
        if (txtLength === '0' || txtLength === 0) {
            temp.txtLength = 'error'
        }
        if (txtWidth === '0' || txtWidth === 0) {
            temp.txtWidth = 'error'
        }
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    // Function to handle bid button
    const bidForALoad = () => {
        if (bidValidate()) {
            if (loadBidAmount.toString().trim().replace(' ', '') !== '' && loadBidAmount !== '' && parseInt(loadBidAmount) !== 0) {
                setDisableBidButton(true);
                if (txtMilesOut === '' && (txtLength === '' || txtHeight === '' || txtWidth === '')) {
                    milesTruckDimension('Miles out and Truck Dimensions');
                }
                else if (txtMilesOut === '') {
                    milesTruckDimension('Miles out');
                }
                else if (txtLength === '' || txtHeight === '' || txtWidth === '') {
                    milesTruckDimension('Truck Dimensions');
                }
                else {
                    disableBidDetails();
                    addBidDetails();
                }
            }
            else {
                setLoadBidAmount('');
                setDisableBidButton(false);
            }
        }
    };

    const changeBidForLoad = async () => {
        if (reBidValidate()) {
            const findResult = rowDataForMatchedLane.find(
                ({ load_id }) => load_id === selectedRowDataValue.load_id
            );
            let listValue = findResult;
            listValue = {
                ...listValue, 'carrier_name': user.email,
                'carrier_company': userCompanyName,
                'bid_amount': loadBidAmount,
                'bid_Notes': BidNotes,
                'status': 'new',
                'bid_expiration_time': bidExpiration,
                'load_dimension': loadFreightDimension,
                'miles_Out': txtMilesOut,
                'Truck_dimension_length': txtLength,
                'Truck_dimension_width': txtWidth,
                'Truck_dimension_height': txtHeight,
                'truck_dimension': `${txtLength}LX${txtWidth}WX${txtHeight}H`,
                'loadUrl': `${window.location.origin}/activeloads?loadId=${slugid.encode(selectedRowDataValue.load_id)}`,
            };
            listValue.bid_time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            listValue.counter_offer = '';
            listValue.counteroffer_sent = 'newoffer';
            listValue.counteroffer_status = 'New';
            listValue.emailType = 'bidupdated';
            if (listValue) {
                Swal.fire({
                    text: 'Are you sure you want to change this bid?',
                    cancelButtonColor: '#0066cc',
                    confirmButtonText: 'Ok',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0066cc'
                })
                    .then(async (result) => {
                        if (result.value === true) {
                            const editBidResponse = await editBidApiCall(listValue);
                            if (editBidResponse) {
                                setSnackBarMessageText(`Bid update to ${selectedRowDataValue.posted_by_company}`);
                                setSnackBarSeverity('success');
                                setOpenSnackBar(true);
                                toGetBidResponse(editBidResponse);
                                bidDetailMsg(editBidResponse, 'Updatebid');
                                await createBidNotificationApiCall(listValue);
                                fetchAllComments(listValue);
                            }
                        }
                    })
                    .catch((error) => { console.log(error); });
            }

        }
    };

    const reBidBtnStyle = (
        loadBidAmount.toString().trim().replace(' ', '') &&
        loadBidAmount !== selectedRowDataValue.bid_amount &&
        selectedRowDataValue.counter_offer === '' ||
        BidNotes !== selectedRowDataValue.bid_notes ||
        txtMilesOut !== selectedRowDataValue.miles_out ||
        bidExpiration !== selectedRowDataValue.bid_expiration_time ||
        txtLength !== selectedRowDataValue.truck_dimension_length ||
        txtHeight !== selectedRowDataValue.truck_dimension_height ||
        txtWidth !== selectedRowDataValue.truck_dimension_width
    )
    const isDisabled = !(
        loadBidAmount.toString().trim().replace(' ', '') &&
        loadBidAmount !== selectedRowDataValue.bid_amount &&
        selectedRowDataValue.counter_offer === '' ||
        BidNotes !== selectedRowDataValue.bid_notes ||
        txtMilesOut !== selectedRowDataValue.miles_out ||
        bidExpiration !== selectedRowDataValue.bid_expiration_time ||
        txtLength !== selectedRowDataValue.truck_dimension_length ||
        txtHeight !== selectedRowDataValue.truck_dimension_height ||
        txtWidth !== selectedRowDataValue.truck_dimension_width
    );

    // function to handle bidcancel
    const cancelBid = async () => {
        let listValues = selectedRowDataValue;
        let loadDimension;
        if (listValues.freight_dimension_height !== '' || listValues.freight_dimension_length !== '' || listValues.freight_dimension_width !== '') {
            if (listValues.freight_dimension_length === '') {
                loadDimension = 'NA X ';
            }
            else {
                loadDimension = listValues.freight_dimension_length.toString().trim() + 'L X ';
            }
            if (listValues.freight_dimension_width === '') {
                loadDimension += 'NA X ';
            }
            else {
                loadDimension += listValues.freight_dimension_width.toString().trim() + 'W X ';
            }
            if (listValues.freight_dimension_height === '') {
                loadDimension += 'NA';
            }
            else {
                loadDimension += listValues.freight_dimension_height.toString().trim() + 'H';
            }
        }
        else {
            loadDimension = '';
        }
        listValues.status = 'Closed';
        listValues.bid_notes = BidNotes;
        listValues.miles_out = txtMilesOut;
        if (listValues.counter_offer === null) {
            listValues.counter_offer = counterOffer;
        }
        listValues.load_dimension = loadDimension;
        if (selectedRowDataValue.status !== 'accepted') {
            Swal.fire({
                text: 'Are you sure you want to close this bid?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            })
                .then(async (result) => {
                    if (result.value === true) {
                        return await deleteBidApiCall(listValues);
                    }
                })
                .then(async (response) => {
                    if (response !== undefined) {
                        fetchAllComments(listValues);
                        loadAndRealoadSecondGrid(listValues.load_id);
                        populateSecondaryPaneValuesForMatchedLane(listValues);
                        onSendMessageClick('Bid Closed', 'System Message', chatAllMessages);
                        sendMessageForSocketIO('ABidHasBeenRemoved||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, listValues);
                        sendMessageForSocketIO('newNotification', '', false);
                        setIsNotificationSent(true);
                        fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
                        return await fetchLoadApiCall(user.email);
                    }
                })
                .then((response) => {
                    if (response !== undefined) {
                        const singleLoadId = response.find(
                            ({ load_id }) => load_id === selectedRowDataValue.load_id
                        );
                        populateSecondaryPaneValuesForMatchedLane(singleLoadId);
                        selectedRowDataValueForSocketIO = singleLoadId;
                        setSelectedRowDataValue(singleLoadId);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            Swal.fire({
                text: 'Cannot delete Bid after a bid has been accepted.',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            });
        }
    };

    const deleteExpiredBids = async (bidData) => {
        let listValues = bidData;
        listValues.status = 'Closed';
        try {
            const deleteBidResponse = await deleteBidApiCall(listValues);
            if (deleteBidResponse) {
                let fetchAllCommentResponse = await fetchAllComment();
                const findResult = fetchAllCommentResponse.find(
                    ({ load_id }) => load_id === bidData.load_id
                );
                loadAndRealoadSecondGrid(bidData.load_id);
                if (bidData.posted_by_user === user.email) {
                    onSendMessageClick('Bid Closed', 'System Message', findResult);
                }
                fetchAllComments(bidData);
                sendMessageForSocketIO('YourBidHasExpired||load_id:' + bidData.load_id + '||posted_by_user:' + bidData.posted_by_user, bidData.carrier_name, true, selectedRowDataValue, bidData);
                sendMessageForSocketIO('newNotification', '', false);
                setIsNotificationSent(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const removeBidsWhenLoadExpires = async (allNewBids, loadId) => {
        if (allNewBids.length !== 0) {
            allNewBids.forEach(element => {
                if (element.load_id === loadId) {
                    if (element.status !== 'accepted' && element.status !== 'Closed') {
                        deleteExpiredBids(element, 'loadExpired');
                    }
                }
            });
        }
    };

    // Function to check expiration difference
    const checkBidExpirationDifference = (expirationTime, bidTimeStamp) => {
        const localVarExpirationTime = parseInt(expirationTime.replace(/\D/g, ''));
        var stillUtc = moment.utc(bidTimeStamp).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        let futureTime = moment(local).add(localVarExpirationTime, 'minutes');
        let diference = futureTime.diff(moment(), 'minutes');
        return diference;
    };

    // Function to handle bid expiration
    const expireBidsAfterTimeOut = (allBidInformation) => {
        allBidInformation.forEach(sindleBids => {
            if ((sindleBids.status !== 'accepted') && (sindleBids.status !== 'Closed')) {
                if (sindleBids.bid_expiration_time) {
                    if (checkBidExpirationDifference(sindleBids.bid_expiration_time, sindleBids.bid_time_stamp) <= 0) {
                        deleteExpiredBids(sindleBids, 'bidExpired');
                    }
                }
            }
        });
    };

    // Function to handle get all bids
    const expireAndFetchAllBids = async () => {
        try {
            const getBidResponse = await getBidApiCall(user.email, "allbids");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                expireBidsAfterTimeOut(getBidResponse);
                return getBidResponse;
            }
            else {
                return [];
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    /******************************Watch List and Saved Lane*************************************************** */
    const cleanListForUpdatingWatchLoad = (list) => {
        if (list.bid_amount)
            delete list.bid_amount;
        if (list.carrier_name)
            delete list.carrier_name;
        if (list.bid_id)
            delete list.bid_id;
        if (list.bid_expiration_time)
            delete list.bid_expiration_time;
        if (list.bid_time_stamp)
            delete list.bid_time_stamp;
        if (list.bid_notes)
            delete list.bid_notes;
        if (list.miles_Out)
            delete list.miles_Out;
        if (list.truck_dimension)
            delete list.truck_dimension;
        list.status = 'new';
        return list;
    };

    // Function to handle remove removing expired loads
    const removeExpiredLoads = async () => {
        const allNewBids = await expireAndFetchAllBids();
        allPostedLoades.forEach(async (element) => {
            if (element.load_expiration_time) {
                const localVarExpirationTime = element.load_expiration_time.split(':');
                let hrtomin = (Number(localVarExpirationTime[0]) * 60 + Number(localVarExpirationTime[1]));
                let futureTime = '';
                if (hrtomin === 0) {
                    let stillUtc = moment.utc(element.time_stamp).toDate();
                    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                    futureTime = moment(local).add(hrtomin, 'minutes');
                }
                else if (hrtomin > 0) {
                    let stillUtc = moment.utc(element.time_stamp).toDate();
                    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
                    futureTime = moment(local).add(hrtomin, 'minutes');
                }
                let diference = futureTime.diff(moment(), 'minutes');
                if (diference <= 0) {
                    removeBidsWhenLoadExpires(allNewBids, element.load_id);
                    let singleElement = JSON.parse(JSON.stringify(element));
                    let listToUpdateExpiration = { ...singleElement, 'load_expired_status': true };
                    listToUpdateExpiration.watch_load = [];
                    listToUpdateExpiration.read_users = [];
                    listToUpdateExpiration.saved_lane = [];
                    try {
                        const editLoadResponse = await editLoadDetailApiCall(listToUpdateExpiration);
                        if (editLoadResponse) {
                            fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
                            // handleDeletingExpiredLoadNotification(listToUpdateExpiration.load_id, 'Load Expiration');
                            closeSecondaryPaneForMatchedLane();
                            sendMessageForSocketIO('BrokerLoadHasBeenExpired||load_id:' + element.load_id, '', true, editLoadResponse);
                            sendMessageForSocketIO('newNotification', '', false);
                            reduceCountOnLoadExpire(element, user.email);
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            }
        });
    };

    const handleLoadReadStatus = async (selectedRowData) => {
        let listValues = JSON.parse(JSON.stringify(selectedRowData));
        if (listValues.read_users) {
            const findUsers = listValues.read_users.find(
                ({ name }) => name === user.email
            );
            if (findUsers === undefined) {
                listValues.read_users.push({ name: user.email });
                try {
                    const editLoadResponse = await editLoadDetailApiCall(cleanListForUpdatingWatchLoad(listValues));
                    if (editLoadResponse) {
                        sendMessageForSocketIO('LoadHasBeenRead||load_id:' + editLoadResponse.load_id, '', false);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const getBidData = async (selectedRowData, selectedRowState) => {
        localStorage.setItem('selectedLoadId', selectedRowData[0]);
        removeBackgroundColour();
        const findResult = rowDataForMatchedLane.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        populateSecondaryPaneValuesForMatchedLane(findResult);
        setDisplaySpecificColums(false);
        const FilterColumns = [...columnDefsLoadTable];
        for (let i = 1; i < FilterColumns.length; i++) {
            if (FilterColumns[i].options.display !== undefined) {
                FilterColumns[i].options.display = false;
            }
        }
        setColumnDefsLoadTable(FilterColumns);
        setMainGridPercentForMatchedLane('35%');
        setIsPaneOpenForMatchedLane(true);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        setSelectedRowDataValue(findResult);
        loadAndRealoadSecondGrid(selectedRowData[0]);
        let fetchAllCommentResponse = await fetchAllComments(findResult);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowData[0]
                );
                updateChatReadStatus(findChatResult);
            }
        }
        handleLoadReadStatus(findResult);
        if (!window.localStorage.getItem('LoadId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${selectedRowState.dataIndex}`);
            row.setAttribute('style', 'background: #9FD2F3');
        }
        return findResult;
    };

    //function to assign values on row click in userdetails table
    const handleRowClickForMatchedLane = (selectedRowData, rowMeta) => {
        setMainGridPercentForMatchedLane('35%');
        setDisableBookit(false);
        setDisableCounterButton(false);
        setDisadbleEdit(false);
        fetchAllComment();
        getBidData(selectedRowData, rowMeta);
    };

    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    // function to handle send message for enter button click
    const onEnterButtonForChat = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSendMessageClick(document.getElementById('inputMesage').value, 'Normal Message');
        }
    };

    // function to handle send message click button
    const handleOnSendMessageClick = () => {
        onSendMessageClick(document.getElementById('inputMesage').value, 'Normal Message');
    };

    /*******************************************************************************************************************************************/
    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    //---------------------------- Place to handle all the functions ----------------------------

    const updateLoadBidAmount = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setLoadBidAmount(event.target.value);
            }
        }
    };

    // Function to check the user role
    const checkUserRole = async () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === 'ProfileAccepted') {
                if (userReduxData.userData.company_role === 'Broker' || userReduxData.userData.user_role === 'Broker') {
                    history.push({ pathname: '/forbidden' });
                }
                if (userReduxData.userData.company_name) {
                    setUserCompanyName(userReduxData.userData.company_name);
                }
                if (userReduxData.userData.company_role) {
                    setLoggedInuserCompanyRole(userReduxData.userData.company_role)
                }
                if (userReduxData.userData.user_role) {
                    setLoggedInuserRole(userReduxData.userData.user_role)
                }
                if (userReduxData.userData.company_mc_number) {
                    setLoggedInCompanyMCNumber(userReduxData.userData.company_mc_number)
                }
            }
            else {
                history.push({ pathname: '/forbidden' });
            }
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/callback' });
        }
    };

    //--------------------------------------------*********************--------------------------
    const reduceCountOnExpand = async (pickUpcity, deliverCity, list) => {
        try {
            let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
            if (matchLaneResponse) {
                //To reduce lane count 
                if (user !== process.env.REACT_APP_ADMIN_USER) {
                    matchLaneResponse.forEach(Lane => {
                        if (Lane.delete_lane === false) {
                            if (Lane.pickup_dest === pickUpcity || Lane.delivery_dest === deliverCity) {
                                if (list[0] === Lane.lane_id) {
                                    let reduceCount = 0;
                                    let listValues =
                                    {
                                        'pick_upfrom_date': Lane.pick_upfrom_date,
                                        'pickup_dest': Lane.pickup_dest,
                                        'delivery_dest': Lane.delivery_dest,
                                        'pickupto_date': Lane.pickupto_date,
                                        'carrier_name': Lane.carrier_name,
                                        'max_weight': Lane.max_weight,
                                        'total_item_count': Lane.total_item_count,
                                        'truck_type': Lane.truck_type,
                                        'miles_from': Lane.miles_from,
                                        'miles_to': Lane.miles_to,
                                        'delete_lane': false,
                                        'matched_lane': reduceCount.toString(),
                                        'lane_number': Lane.lane_number,
                                        'load_id': Lane.load_id,
                                        'lane_id': Lane.lane_id,
                                        'time_stamp': Lane.time_stamp,
                                        'carrier_company': Lane.carrier_company
                                    };
                                    updateCount(listValues);
                                }

                            }
                        }
                    });


                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //function to handle Expand More
    const handleExpandMore = async (selectedRowData) => {
        window.localStorage.removeItem('LoadId');
        window.localStorage.removeItem('selectedLoadId');
        pickUpPlace = selectedRowData[2].length;
        if (pickUpPlace === 1) {
            pickUpPlace = selectedRowDataValue.pickup_dest;
        }
        else {
            pickUpPlace = selectedRowData[2];
        }
        deliveryCity = selectedRowData[5].length;
        if (deliveryCity === 1) {
            deliveryCity = selectedRowDataValue.delivery_dest;
        }
        else {
            deliveryCity = selectedRowData[5];
        }
        if (selectedRowDataValue.Pick_Up_date === undefined) {
            pickingUpDate = selectedRowData[3];
        }
        else {
            pickingUpDate = selectedRowDataValue.Pick_Up_date;
        }
        if (selectedRowDataValue.pickupto_date === undefined) {
            DeliverDate = selectedRowData[7];
        }
        else {
            DeliverDate = selectedRowDataValue.pickupto_date;
        }
        reduceCountOnExpand(pickUpPlace, deliveryCity, selectedRowData);
        fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate, 'expandMore');
    };

    // Function to handle Muidatatable Page change
    const handlePageChange = () => {
        closeSecondaryPaneForMatchedLane();
    };

    //function to handle Edit
    const handleEdit = async (selectedRowData) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            window.localStorage.removeItem('LaneId');
            window.localStorage.removeItem('lane_number');
            window.localStorage.removeItem('matched_lane');
            let Id;
            if (isPaneOpen === false) {
                Id = selectedRowData[0];
            }
            else {
                Id = selectedRowDataValueForLane.lane_id;
            }
            try {
                let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
                if (matchLaneResponse) {
                    if (matchLaneResponse.length !== 0) {
                        matchLaneResponse.forEach(element => {
                            if (element.lane_id === Id) {
                                setSelectedRowDataValueForLane(element);
                                populateSecondaryPaneValues(element);
                                window.localStorage.setItem('lane_number', element.lane_number);
                                window.localStorage.setItem('LaneId', element.lane_id);
                                window.localStorage.setItem('matched_lane', element.matched_lane);
                            }
                        });
                    }
                }
                setMainGridPercent('59%');
                setIsPaneOpen(true);
                setClickEdit(true);
                setIsFromCityValid(true);
                setIsToCityValid(true);
                removeBackgroundColour();
                setDisplaySpecificColums(false);
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    const columnDefs = [
        { label: 'LaneId', name: 'lane_id', options: { display: false } },
        {
            label: '',
            name: '',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    if (isExpand === false) {
                        return (
                            <div>
                                <IconButton
                                    onClick={() => {
                                        handleExpandMore(rowIndex.rowData);
                                    }}
                                >
                                    {badgeCount(rowIndex.rowData[14])}
                                </IconButton>
                            </div>
                        );
                    }
                },
            }
        },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    if (value !== '' && value !== ',  - ') {
                        return (
                            <div className='bold'>
                                {value}
                            </div>
                        );
                    }
                    else {
                        return 'NA';
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pick_upfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    if (value !== 'Invalid date' && value !== 'NA') {
                        return moment(value).format('MM/DD/YYYY');
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortDate(order);
                }
            },
        },
        {
            label: 'Miles_Within Pickup',
            name: 'miles_from',
            options: {
                sort: true,
                display: false, filterList: [],
                customBodyRender: (value) => {
                    if (value !== '' && value !== undefined) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                customBodyRender: (value) => {
                    if (value !== '' && value !== ',  - ') {
                        return (
                            <div className='bold'>
                                {value}
                            </div>
                        );
                    }
                    else {
                        return 'NA';
                    }
                }
            },
        },
        {
            label: 'Miles_within Deliver',
            name: 'miles_to',
            options: {
                sort: true,
                display: false, filterList: [],
                customBodyRender: (value) => {
                    if (value !== '' && value !== undefined) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    if (value !== 'Invalid date' && value !== 'NA') {
                        return moment(value).format('MM/DD/YYYY');
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortDate(order);
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '' && value !== undefined) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== '' && value !== undefined) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                display: false,
                customBodyRender: (value) => {
                    if (value !== '' && value !== undefined) {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                }
            },
        },
        {
            label: 'Carrier Company', name: 'carrier_company', options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[11]
                    );
                }
            }
        },
        {
            label: 'Lane ID',
            name: 'lane_number',
            options: {
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return 'L' + value;
                }
            }
        },
        {
            label: 'Actions',
            name: 'internal actions',
            options: {
                customBodyRender: (dataIndex, rowIndex) => {
                    return (
                        <div>
                            {(clickEdit === false && user.email !== process.env.REACT_APP_ADMIN_USER) ?
                                <Tooltip title="Edit Lane Details">
                                    <IconButton disabled={clickEdit}
                                        onClick={(event) => {
                                            handleEdit(rowIndex.rowData);
                                        }}
                                    >
                                        <Edit style={{ height: 15 }} />
                                    </IconButton>
                                </Tooltip>
                                :
                                <div>
                                    <Tooltip title="View Lane Details">
                                        <IconButton disabled={clickEdit}>
                                            <Visibility style={{ height: 15 }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    );
                },
                customHeadLabelRender: () => {
                    return (
                        <div>
                            <span style={{ fontWeight: 500, fontSize: 14 }}>Actions  </span>
                            <Tooltip title={
                                <>
                                    <Typography component={'div'} style={{ fontSize: 12 }} color='inherit'>Edit - Edit Lane.</Typography>
                                </>
                            } placement='top'>
                                < HelpOutlineIcon style={{ fontSize: 17, textShadow: 2 }} ></HelpOutlineIcon>
                            </Tooltip>
                        </div >
                    );
                }
            }
        },
        {
            label: 'matched_Lane_Count', name: 'matched_lane', options: {
                display: false,
            }
        },
        {
            label: 'load_id', name: 'load_id', options: {
                display: false,
            }
        },
    ];

    const [columnDefsForSavedLane, setColumnDefsForSavedLane] = useState(columnDefs);

    // Function to repopulate all the defaults for posting the load
    const onTabPannelPostLoad = async () => {
        updatePickUpDate(new Date());
        if (document.getElementById('PickupASAP'))
            document.getElementById('PickupASAP').checked = false;
        updateTxtFromZip('');
        updateTxtFromState('');
        updateTxtFromCity('');
        setIsFromCityValid(false);
        setSelectedTxtFromCity('');

        updateDeliverBydate(new Date());
        if (document.getElementById('DeliverDirect'))
            document.getElementById('DeliverDirect').checked = false;

        updateTxtToZip('');
        updateTxtToState('');
        updateTxtToCity('');
        setSelectedTxtToCity('');
        setIsToCityValid(false);

        updateTxtMaxWeight('');
        updateTxtTotalNoOfPices('');

        if (document.getElementById('Stackable'))
            document.getElementById('Stackable').checked = false;

        if (document.getElementById('Hazmat'))
            document.getElementById('Hazmat').checked = false;

        if (document.getElementById('DockHigh'))
            document.getElementById('DockHigh').checked = false;

        if (document.getElementById('TemperatureControl'))
            document.getElementById('TemperatureControl').checked = false;
        if (document.getElementById('all trucks'))
            document.getElementById('all trucks').checked = false;
        setTxtLength('');
        setTxtWidth('');
        setTxtHeight('');

        if (document.getElementById('Sprinter/Cargo Van'))
            document.getElementById('Sprinter/Cargo Van').checked = false;

        if (document.getElementById('Small Straight'))
            document.getElementById('Small Straight').checked = false;

        if (document.getElementById('Large Straight'))
            document.getElementById('Large Straight').checked = false;
        setSaveCheckboxes([]);
    };

    //function to assign values on row click in userdetails table
    const handleRowClick = async (selectedRowData, rowMeta) => {
        onTabPannelPostLoad();
        setMainGridPercent('59%');
        setClickEdit(false);
        setIsPaneOpen(true);
        const findResult = rowDataForSavedLane.find(
            ({ lane_id }) => lane_id === selectedRowData[0]
        );
        setSelectedRowDataValueForLane(findResult);
        populateSecondaryPaneValues(findResult);
        removeBackgroundColour();
        if (!window.localStorage.getItem('LaneId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
            row.setAttribute('style', 'background: #9FD2F3');
        }
        setDisplaySpecificColums(false);
    };

    const optionsForSavedLane = {
        filter: false,
        search: false,
        responsive: 'vertical',
        filterType: false,
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LaneId') && !isPaneOpen) {
                if (window.localStorage.getItem('LaneId') === row[0]) {
                    handleRowClick(row, dataIndex);
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    `There are no lane saved by ${pascalCase(user.nickname)}`
            },
        }
    };

    const optionsForMatchedLane = {
        filter: false,
        search: false,
        responsive: 'vertical',
        filterType: false,
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onChangePage: () => (handlePageChange()),
        onTableChange: (rowData, rowindex) => (setMuiDatatableColour(rowindex)),
        onRowClick: (rowData, rowState) =>
            handleRowClickForMatchedLane(rowData, rowState),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpenForMatchedLane) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClickForMatchedLane(row, dataIndex);
                }
            }
            const findResult = rowDataForMatchedLane.find(
                ({ load_id }) => load_id === row[0]
            );
            if (findResult !== undefined) {
                if (findResult.read_users) {
                    const findStatus = findResult.read_users.find(
                        ({ name }) => name === user.email
                    );
                    if (findStatus === undefined) {
                        return {
                            style: { backgroundColor: '#dceede', fontStyle: 'italic' }
                        };
                    }
                }
            }
            let filteredArray = chatDetails.filter(({ load_id }) => load_id === row[0]);
            let rowStatus = 0;
            filteredArray.forEach(element => {
                if (element.read_status) {
                    const findChatStatus = element.read_status.find(
                        ({ name }) => name === user.email
                    );
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValue.load_id !== row[0]) {
                        rowStatus = rowStatus + 1;
                    }
                }
            });
            if (rowStatus > 0) {
                return {
                    style: { backgroundColor: '#DAF7A6' }
                };
            }

        },

        textLabels: {
            body: {
                noMatch: !loadingForMatchedLane ?
                    <CircularProgress /> :
                    'No Loads Matched Your Saved Lane'
            },
        }
    };

    //Function to handle saved Lane Color
    const changeLaneColor = async (list) => {
        if (list.saved_lane === null || list.saved_lane.length === 0 || list.saved_lane.length === undefined) {
            list.saved_lane = [{ name: user.email, accStatus: true }];
        }
        else {
            const findUserSavedLane = list.saved_lane.find(({ name }) => name === user.email);
            if (!findUserSavedLane) {
                list.saved_lane.push({ name: user.email, accStatus: true });
            }
            else {
                findUserSavedLane.accStatus = true;
            }
        }
        populateSecondaryPaneValuesForMatchedLane(list);
        try {
            let response = await editLoadDetailApiCall(list);
            if (response !== undefined) {
                let data = JSON.parse(response.data.body);
                sendMessageForSocketIO('LoadAddedToSavedLane||load_id:' + data.load_id, '', false);
                populateSecondaryPaneValuesForMatchedLane(data);
                selectedRowDataValueForSocketIO = data;
                setSelectedRowDataValue(data);
            }
        }
        catch (error) {
            console.log(error, 'Failed to update lane details');
        }
    };

    //Function to handle saved Lane click
    const handleSavedLaneClick = async () => {
        changeLaneColor(selectedRowDataValue);
        let listValue =
        {
            'pick_upfrom_date': moment(selectedRowDataValue.pick_upfrom_date).format('MM/DD/YYYY'),
            'pickup_dest': selectedRowDataValue.pickup_dest,
            'delivery_dest': selectedRowDataValue.delivery_dest,
            'pickupto_date': moment(selectedRowDataValue.pickupto_date).format('MM/DD/YYYY'),
            'carrier_name': user.email,
            'max_weight': selectedRowDataValue.max_weight,
            'total_item_count': selectedRowDataValue.total_item_count,
            'truck_type': selectedRowDataValue.truck_type,
            'miles_from': 'NA',
            'miles_to': 'NA',
            'delete_lane': false,
            'matched_lane': '0',
            'load_id': selectedRowDataValue.load_id,
            'time_stamp': selectedRowDataValue.time_stamp,
            'carrier_company': userCompanyName,
            'company_mc_number': loggedInCompanyMCNumber
        };
        try {
            let response = await saveLaneApiCall(listValue);
            if (response) {
                setSnackBarMessageText(`Load details added to saved lane`);
                setSnackBarSeverity('success');
                setOpenSnackBar(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //Function Used Repeatedly If Socket Open
    const executeIfSocketOpen = async () => {
        if (isPaneOpenForSocketIO) {
            try {
                let fetchLoadResponse = await fetchLoadApiCall(user.email);
                if (fetchLoadResponse) {
                    const findResult = fetchLoadResponse.find(
                        ({ load_id }) => load_id === selectedRowDataValue.load_id
                    );
                    populateSecondaryPaneValuesForMatchedLane(findResult);
                    selectedRowDataValueForSocketIO = findResult;
                    setSelectedRowDataValue(findResult);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    //Function to handle watch load click
    const handleWatchLoadClick = async () => {
        const username = user.email;
        const findResult = rowDataForMatchedLane.find(
            ({ load_id }) => load_id === selectedRowDataValue.load_id
        );
        let listValue = findResult;
        if (selectedRowDataValue.saved_lane.length !== 0) {
            listValue.saved_lane.push({ name: username, accStatus: true });
        }
        if (listValue.watch_load.length === 0) {
            listValue.watch_load = [{ name: username, accStatus: true }];
        }
        else {
            const findUserWatchLoad = listValue.watch_load.find(({ name }) => name === username);
            if (!findUserWatchLoad) {
                listValue.watch_load.push({ name: username, accStatus: true });
            }
            else {
                findUserWatchLoad.accStatus = true;
            }
        }
        let editLoadResponse = await editLoadDetailApiCall(cleanListForUpdatingWatchLoad(listValue));
        if (editLoadResponse) {
            populateSecondaryPaneValuesForMatchedLane(editLoadResponse);
            setSnackBarMessageText('Load succesfully added to watch list');
            setSnackBarSeverity('success');
            setOpenSnackBar(true);
            fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
            sendMessageForSocketIO('BrokerLoadHasBeenWatchList||load_id:' + editLoadResponse.load_id, '', false);
            executeIfSocketOpen();
        }
    };

    // Function to handle stop watching click 
    const handleStopWatchLoadClick = async () => {
        const username = user.email;
        const findResult = rowDataForMatchedLane.find(
            ({ load_id }) => load_id === selectedRowDataValue.load_id
        );
        let listValue = findResult;
        const findUserWatchLoad = listValue.watch_load.find(({ name }) => name === username);
        if (findUserWatchLoad) {
            findUserWatchLoad.accStatus = false;
        }
        let editLoadResponse = await editLoadDetailApiCall(cleanListForUpdatingWatchLoad(listValue));
        if (editLoadResponse) {
            populateSecondaryPaneValuesForMatchedLane(editLoadResponse);
            fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
            setSnackBarMessageText('Load removed from watch list');
            setSnackBarSeverity('success');
            setOpenSnackBar(true);
            sendMessageForSocketIO('BrokerLoadHasBeenWatchListRemoved||load_id:' + editLoadResponse.load_id, '', false);
            executeIfSocketOpen();
        }
    };

    const handleRemoveSavedLaneClick = async (tempData) => {
        const username = user.email;
        let loadId = selectedRowDataValue.load_id;
        if (selectedRowDataValue.length === 0) {
            loadId = selectedRowDataValueForLane.load_id;
        }
        const findResult = tempData.find(
            ({ load_id }) => load_id === loadId
        );
        let listValue = findResult;
        const findUserSavedLane = listValue.saved_lane.find(({ name }) => name === username);
        if (findUserSavedLane) {
            findUserSavedLane.accStatus = false;
        }
        populateSecondaryPaneValuesForMatchedLane(listValue);
        let editLoadResponse = await editLoadDetailApiCall(cleanListForUpdatingWatchLoad(listValue));
        if (editLoadResponse) {
            setSnackBarMessageText('Saved Lane is removed');
            setSnackBarSeverity('success');
            setOpenSnackBar(true);
            fetchMatchedData(editLoadResponse.pickup_dest, editLoadResponse.delivery_dest, editLoadResponse.pickupfrom_date, editLoadResponse.pickupto_date, 'removeLane');
            sendMessageForSocketIO('LoadRemovedFromSavedLane||load_id:' + editLoadResponse.load_id, '', false);
        }
    };

    const removeFromLane = async (listValues) => {
        try {
            let response = await updateCount(listValues);
            if (response !== undefined) {
                fetchMatchedData(pickUpPlace, deliveryCity, pickingUpDate, DeliverDate);
                handleRemoveSavedLaneClick(rowDataForMatchedLane);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to fetch all the data main MuiDatatble
    const fetchSavedLaneData = async (pickUpCity, DeliveryCity, load_id) => {
        try {
            let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
            if (matchLaneResponse) {
                // Setting Up expired loads table
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    let savedLaneList = matchLaneResponse.filter(function (singleVal) {
                        return singleVal['carrier_name'] === user.email && singleVal['delete_lane'] === false &&
                            (singleVal['pickup_dest'] === pickUpCity ||
                                singleVal['delivery_dest'] === DeliveryCity);
                    });
                    savedLaneList.forEach(removeLane => {
                        if (removeLane.load_id === load_id) {
                            let listValues =
                            {
                                'pick_upfrom_date': removeLane.pick_upfrom_date,
                                'pickup_dest': removeLane.pickup_dest,
                                'delivery_dest': removeLane.delivery_dest,
                                'pickupto_date': removeLane.pickupto_date,
                                'carrier_name': user.email,
                                'max_weight': removeLane.max_weight,
                                'total_item_count': removeLane.total_item_count,
                                'truck_type': removeLane.truck_type,
                                'miles_from': removeLane.miles_from,
                                'miles_to': removeLane.miles_to,
                                'lane_id': removeLane.lane_id,
                                'delete_lane': true,
                                'matched_lane': removeLane.matched_lane,
                                'load_id': removeLane.load_id,
                                'lane_number': removeLane.lane_number,
                                'time_stamp': removeLane.time_stamp,
                                'carrier_company': removeLane.carrier_company
                            };
                            removeFromLane(listValues);
                        }
                    });
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleRemoveSavedLaneClickForMatchedLaneTable = async () => {
        fetchSavedLaneData(selectedRowDataValueForLane.pickup_dest, selectedRowDataValueForLane.delivery_dest, selectedRowDataValue.load_id);
    };

    const fetchDataFromLoadBoard = async (data) => {
        let fetchLoadResponse = await fetchLoadApiCall(user.email);
        if (fetchLoadResponse) {
            let arrayToRemove = fetchLoadResponse.filter(function (singleVal) {
                return singleVal['load_Delete_Status'] !== true &&
                    singleVal['load_Expired_Status'] !== true &&
                    (singleVal['pickup_dest'] === data.pickup_dest/*.props.children*/ || singleVal['delivery_dest'] === data.delivery_dest/*.props.children*/) &&
                    (singleVal['pickupfrom_date'] === data.pick_upfrom_date && singleVal['pickupto_date'] === data.pickupto_date) &&
                    singleVal['status'] !== 'Closed' &&
                    singleVal['status'] !== 'Awarded' &&
                    singleVal['status'] !== 'Assigned to Driver';
            });
            const tempData = arrayToRemove.map(item => {
                // item.abovemarketrate = item.abovemarketrate ? 'Yes' : ''
                return item;
            });
            setRowDataForMatchedLane(tempData);
            handleRemoveSavedLaneClick(tempData);
            return tempData;
        }
    };

    const handleSoftDeleteConfirmClick = async () => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            fetchDataFromLoadBoard(selectedRowDataValueForLane);
            Swal.fire({
                text: 'Are you sure you want to Delete this lane?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then(async (result) => {
                if (result.value === true) {
                    let listValues =
                    {
                        'pick_upfrom_date': selectedRowDataValueForLane.pick_upfrom_date,
                        'pickup_dest': selectedRowDataValueForLane.pickup_dest,
                        'delivery_dest': selectedRowDataValueForLane.delivery_dest,
                        'pickupto_date': selectedRowDataValueForLane.pickupto_date,
                        'carrier_name': user.email,
                        'max_weight': selectedRowDataValueForLane.max_weight,
                        'total_item_count': selectedRowDataValueForLane.total_item_count,
                        'truck_type': selectedRowDataValueForLane.truck_type,
                        'miles_from': selectedRowDataValueForLane.miles_from,
                        'miles_to': selectedRowDataValueForLane.miles_to,
                        'lane_id': selectedRowDataValueForLane.lane_id,
                        'delete_lane': true,
                        'matched_lane': selectedRowDataValueForLane.matched_lane,
                        'load_id': selectedRowDataValueForLane.load_id,
                        'lane_number': selectedRowDataValueForLane.lane_number,
                        'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        'carrier_company': selectedRowDataValueForLane.carrier_company,
                        'company_mc_number': user.mcnumber
                    };
                    let response = await updateCount(listValues);
                    if (response) {
                        closeSecondaryPane();
                        setSnackBarMessageText('Saved Lane is Deleted');
                        setSnackBarSeverity('success');
                        setOpenSnackBar(true);
                        fetchMainMuiDatatableValues();
                    }
                }
            });
        }
    };

    /*********************************************************************/
    // Function to handle onchange value for min(load details panel)
    const handletrucktypeOnchangeValue = (event, newValue) => {
        setSaveCheckboxes(newValue);
        setLoadLoadType(newValue);
    };

    /*************************Function To handle On click Of edit Button Tect Field******************************************/
    // function to handle onchange value for pick up from city
    const onFromPlaceChanged = (val) => {
        updateTxtFromState('');
        updateTxtFromZip('');
        if (txtFromCity !== '') {
            if (val.target.value === selectedTxtFromCity) {
                setIsFromCityValid(true);
            }
            else {
                setIsFromCityValid(false);
            }
        }
        updateTxtFromCity(val.target.value);
    };

    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };

    // function to update from city
    const onFromPlaceSelected = (place) => {
        updateTxtFromState(getState(place.address_components));
        updateTxtFromCity(getArea(place.address_components));
        setSelectedTxtFromCity(getArea(place.address_components));
        setIsFromCityValid(true);
        if (place.geometry) {
            if (cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng())) {
                updateTxtFromZip(cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode);
            }
        }
    };

    // function to handle onchange value for pick up from city
    const onToPlaceChanged = (val) => {
        updateTxtToState('');
        updateTxtToZip('');
        if (txtToCity !== '') {
            if (val.target.value === selectedTxtToCity) {
                setIsToCityValid(true);
            }
            else {
                setIsToCityValid(false);
            }
        }
        updateTxtToCity(val.target.value);
    };

    // function to update to city
    const onToPlaceSelected = (place) => {
        updateTxtToState(getState(place.address_components));
        updateTxtToCity(getArea(place.address_components));
        setSelectedTxtToCity(getArea(place.address_components));
        setIsToCityValid(true);

        if (place.geometry) {
            if (cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng())) {
                updateTxtToZip(cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode);
            }
        }
    };

    //Function to Change Pick Up Date
    const handlePickUpDateChange = date => {
        setPickUpDateChanged(true);
        let DeliverDate = date;
        if (moment(DeliverDate).isAfter(deliverBydate)) {
            updateDeliverBydate(DeliverDate);
            updatePickUpDate(DeliverDate);
        }
        else {
            updatePickUpDate(DeliverDate);
            updateDeliverBydate(deliverBydate);
        }
    };

    //Function to prevent Default
    const Default = (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
            return false;
        }
    };

    // function to handle deliver date
    const handleDeliverByDateChange = date => {
        if (moment(date).isBefore(pickUpDate)) {
            Swal.fire({
                text: 'Delivery Date cannot be before the Pick up date',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc',
            });
        }
        else {
            updateDeliverBydate(date);
            updatePickUpDate(pickUpDate);
            setDeliverDateChanged(true);
        }
    };

    // Function to set and check MaxWeight textfield
    const handelTxtMaxWeightChange = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                updateTxtMaxWeight(event.target.value);
                setLoadMaxWeight(event.target.value);
            }
        }
    };

    // function to set Total number of pieces
    const handeltxtTotalNoOfPices = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                updateTxtTotalNoOfPices(event.target.value);
                setLoadNoOfPieces(event.target.value);
            }
        }
    };

    // function to set Total number of pieces
    const handeltxtmileswithinPickUpDestination = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                updateMilesWithinFromDestination(event.target.value);
                setMilesWithinFrom(event.target.value);
            }
        }
    };

    // function to set Total number of pieces
    const handeltxtmileswithinDeliverDestination = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                updateMilesWithinToDestination(event.target.value);
                setMilesWithinTo(event.target.value);
            }
        }
    };

    /**********************Function to Validate edited Field , Save Changes, Clear TextField**********************************/
    //function to modify and stored Date
    const modifyLaneDetails = async () => {
        setDisableSave(true);
        setDisableCancel(true);
        let coustomId = '';
        let truckType = '';
        if (loadId === undefined) {
            coustomId = 'NA';
        }
        else {
            coustomId = loadId;
        }
        if (loadLoadType === 'All trucks') {
            truckType = 'Sprinter/Cargo Van, Small Straight, Large Straight';
        }
        else {
            truckType = loadLoadType;
        }
        let PickUpCity = '';
        let deliverCity = '';
        let Pick_Up_date = '';
        let deliver_date = '';
        if (txtFromState !== '' && txtFromZip !== '') {
            PickUpCity = txtFromCity + ', ' + txtFromState + ' - ' + txtFromZip;
        }
        else {
            PickUpCity = txtFromCity;
        }
        if (txtToState !== '' && txtToZip !== '') {
            deliverCity = txtToCity + ', ' + txtToState + ' - ' + txtToZip;
        }
        else {
            deliverCity = txtToCity;
        }
        if (pickupDateChanged === false && loadPickUpdate !== undefined) {
            Pick_Up_date = loadPickUpdate;
        }
        else {
            Pick_Up_date = moment(pickUpDate).format('MM/DD/YYYY');
        }
        if (deliverDateChanged === false && loadDeliverByDate !== undefined) {
            deliver_date = loadDeliverByDate;
        }
        else {
            deliver_date = moment(deliverBydate).format('MM/DD/YYYY');
        }
        let listValues =
        {
            'pick_upfrom_date': Pick_Up_date,
            'pickup_dest': PickUpCity,
            'delivery_dest': deliverCity,
            'pickupto_date': deliver_date,
            'carrier_name': user.email,
            'max_weight': loadMaxWeight,
            'total_item_count': loadNoOfPieces,
            'truck_type': truckType,
            'miles_from': milesWithinFrom,
            'miles_to': milesWithinTo,
            'lane_id': window.localStorage.getItem('LaneId'),
            'lane_number': window.localStorage.getItem('lane_number'),
            'delete_lane': false,
            'matched_lane': laneCount,
            'load_id': coustomId,
            'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            'carrier_company': userCompanyName
        };
        let response = await updateCount(listValues);
        if (response) {
            setDisableSave(false);
            setDisableCancel(false);
            setClickEdit(false);
            populateSecondaryPaneValues(listValues);
            fetchMainMuiDatatableValues();
            setSnackBarMessageText('Changes Updated');
            setSnackBarSeverity('success');
            setOpenSnackBar(true);
        }
    };

    //Function to Validate On changing from city
    const validateFromPlace = () => {
        let temp = {};
        temp.txtFromCity = txtFromCity ? '' : 'error';
        temp.txtFromState = txtFromState ? '' : 'error';
        temp.txtFromZip = txtFromZip ? '' : 'error';
        if (txtFromZip.length < 5) {
            temp.txtFromZipLength = 'error';
        }
        else {
            temp.txtFromZipLength = '';
        }
        temp.isFromCityValid = isFromCityValid ? '' : 'error';
        if (!isFromCityValid) {
            Swal.fire({
                text: 'invalid  From City name',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    //Function to Validate On changing to city
    const validateToPlace = () => {
        let temp = {};
        temp.txtToCity = txtToCity ? '' : 'error';
        temp.txtToState = txtToState ? '' : 'error';
        temp.txtToZip = txtToZip ? '' : 'error';
        temp.deliverBydate = deliverBydate ? '' : 'error';
        if (txtToZip.length < 5) {
            temp.txtToZipLength = 'error';
        }
        else {
            temp.txtToZipLength = '';
        }
        temp.isToCityValid = isToCityValid ? '' : 'error';
        if (!isToCityValid) {
            Swal.fire({
                text: 'invalid To City name',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    //Function To Validate Save Searched Values
    const ValidateSaveSearchLane = async () => {
        if (loadPickUpdate !== null || loadDeliverByDate !== null) {
            let pick = moment(loadPickUpdate, 'MM/DD/YYYY');
            let deliver = moment(loadDeliverByDate, 'MM/DD/YYYY');
            let difference = deliver.diff(pick, 'days');
            if (difference < 0) {
                Swal.fire({
                    text: 'Delivery Date should be after the Pick up Date',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }
                );
            }
            else {
                if (selectedTxtFromCity === txtFromCity && selectedTxtToCity === txtToCity) {
                    setDisableSave(true);
                    modifyLaneDetails();
                }
                else {
                    if (selectedTxtFromCity !== txtFromCity) {
                        if (txtFromCity !== '' || txtFromZip !== '' || txtFromState !== '') {
                            validateFromPlace();
                        }
                    }
                    else {
                        if (selectedTxtToCity !== txtToCity) {
                            validateToPlace();
                        }
                    }
                }
            }
        }
        else {
            Swal.fire({
                text: 'Cannot Update a Lane without Date',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc',
            }
            );
        }
    };

    //Function to Clear Edit form on click of cancel
    const clearEditForm = () => {
        populateSecondaryPaneValues(selectedRowDataValueForLane);
        setClickEdit(false);
        setDisableSave(true);
        setDisableCancel(true);
    };

    //Function To show Cancel Icon
    const toShowCancelIcon = (value) => {
        if (value !== '' && value !== 'NA' && value !== 'Invalid') {
            return (
                <div>
                    {value}
                </div>
            );
        }
        else {
            return (
                <div>
                    NA <CancelIcon className='notApplicable' />
                </div>
            );
        }
    };


    // Function that is triggered on page load
    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    useEffect(() => {
        if (Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
        fetchMainMuiDatatableValues();
        try {
            setInterval(async () => {
                removeExpiredLoads();
                expireAndFetchAllBids();
                fetchMainMuiDatatableValues();
            }, 120000);
        } catch (e) {
            console.log(e);
        }
    }, [userReduxData]);


    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarSeverity === 'info' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackBarSeverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                {isExpand === true && (
                    <React.Fragment>
                        <div style={{ width: '1%' }}></div>
                        <div style={{ width: '100%' }} border={1}>
                            <Box
                                boxShadow={3}>
                                <Paper square className='square'>
                                    <Row style={{ fontSize: 25, marginLeft: '35%' }}>
                                        <Col sm ><b>Lane Details</b>
                                            <IconButton style={{ marginLeft: '65%' }}
                                                onClick={() => {
                                                    handleGoBack();
                                                }}
                                            >
                                                <Tooltip title='Go Back'>
                                                    <div>
                                                        <ExpandLeft />
                                                    </div>
                                                </Tooltip>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                    <Grid container xs={12} justify='flex-end'>
                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                            <Grid container direction='row'>
                                                <Grid xs={6}>
                                                    <div style={{ height: 7 }} />
                                                    <Row>
                                                        <Col sm>Lane ID:</Col>
                                                        <Col style={{ marginRight: 15 }} sm>{'L' + laneNumberDetail}</Col>
                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(loadPickUpDestination)}</Col>

                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm >Miles within Pick up:</Col>
                                                        <Col style={{ marginRight: 15 }} sm>{toShowCancelIcon(milesWithinFrom)}</Col>
                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm style={{ fontWeight: 'bold' }} >Pick-Up Date:</Col>
                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(loadPickUpdate)}</Col>
                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm >Weight:</Col>
                                                        <Col style={{ marginRight: 15 }} sm>
                                                            {toShowCancelIcon(loadMaxWeight)}
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                                <Grid xs={6}>
                                                    <div style={{ height: 5 }} />
                                                    <Row>
                                                        <Col sm >Number Of Pieces:</Col>
                                                        <Col style={{ marginRight: 15 }} sm>
                                                            {toShowCancelIcon(loadNoOfPieces)}
                                                        </Col>
                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(loadDeliverDestination)}</Col>

                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm >Miles within Deliver:</Col>
                                                        <Col style={{ marginRight: 15 }} sm>{toShowCancelIcon(milesWithinTo)}</Col>
                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm style={{ fontWeight: 'bold' }}>Delivery Date:</Col>
                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(loadDeliverByDate)}</Col>
                                                    </Row>
                                                    <div style={{ height: 15 }} />
                                                    <Row>
                                                        <Col sm>Truck Type:</Col>
                                                        <Col style={{ marginRight: 15 }} sm>
                                                            {toShowCancelIcon(loadLoadType)}
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </div>
                    </React.Fragment>
                )}
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={counteropen}
                    onClose={handleClose}
                >
                    <DialogTitle>Counter Offer ($)</DialogTitle>
                    <DialogContent>
                        <Box
                            noValidate
                            component='form'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <div style={{ padding: '2px 12px' }}>
                                <TextField
                                    placeholder='Counter Amount'
                                    autoComplete='off'
                                    value={counterOffer}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    size='large'
                                    onChange={handleKeyPress}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <AttachMoney fontSize='inherit'
                                                    style={{ fontSize: '20px', marginBottom: 15, marginTop: 15, display: 'inline' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: 'flex', marginTop: '6%', justifyContent: 'end', marginRight: '5%' }}>
                            <button className='counterOfferSubmitBtn' onClick={submitCounterOffer}>
                                Submit
                            </button>
                            <button className='counterOfferCancelBtn' onClick={cancelCounterOffer}>
                                Cancel
                            </button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={counteropencarrier}
                    onClose={handleClose}
                >
                    <Grid container xs={12} justify='flex-end'>
                        <IconButton onClick={closeForm}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <DialogTitle style={{ paddingTop: 0 }}>Counter Offer ($)</DialogTitle>
                    <DialogContent>
                        <Box
                            noValidate
                            component='form'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <div style={{ padding: '2px 12px' }}>
                                <TextField
                                    placeholder='Counter Amount'
                                    autoComplete='off'
                                    value={counterOffer}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    size='large'
                                    onChange={handleKeyPress}
                                    onKeyPress={(event) => handleKeyPress(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <AttachMoney fontSize='inherit'
                                                    style={{ fontSize: '20px', marginBottom: 15, marginTop: 15, display: 'inline' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ display: 'flex', marginTop: '6%', justifyContent: 'end', marginRight: '5%' }}>
                            {(counterOffer !== selectedRowDataValue.counter_offer) ?
                                <button className='counterOfferSubmitBtn' onClick={submitCounterOffer}>
                                    Update
                                </button>
                                :
                                <Button style={{ textTransform: 'capitalize', borderRadius: 5, width: maxWidth, color: '#7d7d7d', background: '#ccc', borderColor: '#7c7c7c' }} variant='outlined' disabled>Update</Button>
                            }
                            {
                                (counterOffer === selectedRowDataValue.counter_offer && ((selectedRowDataValue.counteroffer_sent === 'broker' && loadPostedByUser !== user.email) || (selectedRowDataValue.counteroffer_sent === 'carrier' && loadPostedByUser === user.email))) ?
                                    <button className='counterOfferAcceptBtn' onClick={acceptCounterOfferCarrier} >
                                        Accept
                                    </button>
                                    :
                                    <Button style={{ textTransform: 'capitalize', borderRadius: 5, width: maxWidth, color: '#7d7d7d', background: '#ccc', borderColor: '#7c7c7c' }} variant='outlined' disabled>Accept</Button>
                            }
                        </div>
                    </DialogActions>
                </Dialog>
                <Typography component={'div'}>
                    <Grid container direction='row' margin={2}>
                        {
                            isExpand !== true ?
                                <div style={{ width: mainGridPercent, paddingBottom: '15%', display: 'table', tableLayout: 'fixed' }}>
                                    <MuiThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            title={'Saved Lanes'}
                                            data={rowDataForSavedLane}
                                            columns={columnDefsForSavedLane}
                                            options={optionsForSavedLane}
                                        ></MUIDataTable>
                                    </MuiThemeProvider>
                                </div>
                                :
                                <div style={{ width: mainGridPercentForMatchedLane, paddingBottom: '15%', marginTop: 20, display: 'table', tableLayout: 'fixed' }}>
                                    <MuiThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            title={'Matched Lane'}
                                            data={rowDataForMatchedLane}
                                            columns={columnDefsLoadTable}
                                            options={optionsForMatchedLane}
                                        ></MUIDataTable>
                                    </MuiThemeProvider>
                                </div>
                        }

                        {(isPaneOpenForMatchedLane && (isExpand === true)) && (
                            <React.Fragment>
                                <div style={{ width: '1%' }}></div>
                                <div style={{ width: '64%', paddingTop: 5 }} border={1}>
                                    <Box
                                        boxShadow={3}>
                                        <Paper square className='square' style={{ paddingLeft: 20, paddingBottom: 20 }}>
                                            <Grid container xs={12} justify='flex-end'>
                                                <React.Fragment>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                                            <div>
                                                                <IconButton hidden={loadSavedLane} onClick={handleSavedLaneClick}>
                                                                    <Tooltip title='Save Lane'>
                                                                        <BookmarkBorderIcon />
                                                                    </Tooltip>
                                                                </IconButton>
                                                                <IconButton hidden={!loadSavedLane} onClick={handleRemoveSavedLaneClickForMatchedLaneTable}>
                                                                    <Tooltip title='Remove SavedLane'>
                                                                        <BookmarkIcon style={{ fill: '#0BDA51' }} />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </div>
                                                        )}
                                                        {/* <IconButton hidden={loadWatchLoad} onClick={handleWatchLoadClick}>
                                                            <Tooltip title='Watch Load'>
                                                                <StarBorderIcon />
                                                            </Tooltip>
                                                        </IconButton> */}
                                                        <IconButton hidden={!loadWatchLoad} onClick={handleStopWatchLoadClick}>
                                                            <Tooltip title='Stop Watching'>
                                                                <StarIcon style={{ fill: '#ffe007' }} />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </div>
                                                </React.Fragment>
                                                <IconButton onClick={closeSecondaryPaneForMatchedLane}>
                                                    <Tooltip title="Close">
                                                        <CloseIcon />
                                                    </Tooltip>
                                                </IconButton>
                                                <Grid style={{ fontSize: 14 }} xs={12}>
                                                    <Grid container direction='row'>
                                                        <Grid xs={6}>
                                                            <Row style={{ fontSize: 18 }}>
                                                                <Col sm><b>Load Details</b></Col>
                                                                <Col style={{ marginRight: 15 }} sm></Col>
                                                            </Row>
                                                            <div style={{ height: 7 }} />
                                                            <Row>
                                                                <Col sm>Load ID:</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{loadNumberDetail}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm style={{ fontWeight: 'bold' }}>Pick-Up Destination:</Col>
                                                                <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadPickUpDestinationForMatchedLane}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm>Pick-Up Window:</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{loadPickUpWindow}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm>Pick-Up Date:</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{loadPickUpdateForMatchedLame}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm style={{ fontWeight: 'bold' }}>Deliver Destination:</Col>
                                                                <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{loadDeliverDestinationForMatchedLane}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm>Delivery Window:</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{loadDeliverWindow}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm>Delivery Date:</Col>
                                                                <Col style={{ marginRight: 15 }} sm>{loadDeliverByDateForMatchedLane}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm style={{ fontWeight: 'bold' }}>Weight:</Col>
                                                                <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>
                                                                    {loadMaxWeightForMatchedLane}
                                                                </Col>
                                                            </Row>
                                                            {
                                                                (loadPostedByUser !== user.email) && (
                                                                    <React.Fragment>
                                                                        <div style={{ height: 15 }} />
                                                                        <Row>
                                                                            <Col sm>Status:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm >{loadStatus}</Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </Grid>
                                                        <Grid xs={6}>
                                                            <div style={{ height: 33 }} />
                                                            <Row>
                                                                <Col sm style={{ fontWeight: 'bold' }}>Approximate Distance <div>(Drive Time):</div></Col>
                                                                <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm >{loadCityDistance}</Col>
                                                            </Row>
                                                            <div style={{ height: 5 }} />
                                                            <Row>
                                                                <Col sm style={{ fontWeight: 'bold' }}>Number Of Pieces:</Col>
                                                                <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>
                                                                    {loadNoOfPiecesForMatchedLane}
                                                                </Col>
                                                            </Row>
                                                            <div style={{ height: 5 }} />
                                                            <Row>
                                                                <Col sm>Freight Dimension:</Col>
                                                                <Col style={{ marginRight: 15 }} sm >{loadFreightDimension}</Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <Row>
                                                                <Col sm>Truck Type:</Col>
                                                                <Col style={{ marginRight: 15 }} sm>
                                                                    {loadLoadTypeForMatchedLane}
                                                                </Col>
                                                            </Row>
                                                            <div style={{ height: 15 }} />
                                                            <TextField
                                                                label='Load Notes'
                                                                autoComplete='off'
                                                                multiline
                                                                rows={6}
                                                                paddingTop={3}
                                                                variant='outlined'
                                                                style={{
                                                                    width: '97%',
                                                                }}
                                                                value={loadLoadNotes}
                                                                disabled={true}
                                                            />
                                                        </Grid>
                                                        <Grid xs={12}>
                                                            {
                                                                ((((loggedInuserRole === 'CompanyAdmin' && loggedInuserCompanyRole !== 'Broker') || (loggedInuserRole === 'Carrier' && loggedInuserCompanyRole !== 'Broker') || (loggedInuserRole === 'Broker&Carrier')) && loadPostedByUser !== user.email) || (user.email !== process.env.REACT_APP_ADMIN_USER && loadPostedByUser !== user.email)) && (
                                                                    <React.Fragment>
                                                                        <div style={{ height: 7 }} />
                                                                        <Grid style={{ marginBottom: 10 }} xs={12} >
                                                                            <Box className='color' boxShadow={3}
                                                                                style={{ width: '99%', height: '350%', backgroundColor: '#ececec' }}
                                                                                color='#101e35' border={0} padding={2} paddingTop={'2%'}>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 1 }}>
                                                                                    <h6><b>Bid Now</b></h6>
                                                                                </div>
                                                                                <Grid xs={12}>
                                                                                    <Row style={{ marginLeft: '3%' }}>
                                                                                        <Col>
                                                                                            <Row>
                                                                                                <TextField
                                                                                                    id='txtFieldBidAmount'
                                                                                                    value={loadBidAmount}
                                                                                                    autoComplete='off'
                                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                                                                                    onChange={(val) => updateLoadBidAmount(val)}
                                                                                                    disabled={selectedRowDataValue.counter_offer}
                                                                                                    style={{ marginRight: '2%', width: '29%' }}
                                                                                                    variant='outlined'
                                                                                                    label='Amount * ($)'
                                                                                                    error={(errors.loadBidAmount && loadBidAmount === '') || loadBidAmount === '0'}
                                                                                                />
                                                                                                <MUIAutoComplete
                                                                                                    onClose={() => loadHandleBidExpirationCloseForMin()}
                                                                                                    //freeSolo
                                                                                                    disableClearable
                                                                                                    disabled={selectedRowDataValue.counter_offer}
                                                                                                    value={bidExpiration}
                                                                                                    onChange={(event, newValue) => {
                                                                                                        handleSetBidExpirationOnchangeValueForMin(event, newValue);
                                                                                                    }}
                                                                                                    inputValue={bidExpiration}
                                                                                                    onInputChange={(event, newInputValue) =>
                                                                                                        loadHandleBidExpirationChangeForMin(event, newInputValue)
                                                                                                    }
                                                                                                    options={minutes}
                                                                                                    style={{ width: '29%', marginRight: '2%' }}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField {...params} variant='outlined' label='Select Minute' style={{ height: 50 }} />
                                                                                                    )}
                                                                                                />
                                                                                                {

                                                                                                    <TextField
                                                                                                        label='Miles Out'
                                                                                                        variant='outlined'
                                                                                                        placeholder='miles'
                                                                                                        value={txtMilesOut}
                                                                                                        autoComplete='off'
                                                                                                        error={txtMilesOut < 0}
                                                                                                        helperText={txtMilesOut < 0 ? 'Not Valid Input' : ''}
                                                                                                        inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                        onChange={handelTxtMilesOutChange}
                                                                                                        style={{ width: '29%' }}
                                                                                                        disabled={selectedRowDataValue.counter_offer}
                                                                                                    />
                                                                                                }
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Grid>
                                                                                <Grid xs={12}>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <TextField
                                                                                                label='Bid Notes'
                                                                                                multiline
                                                                                                rows={1}
                                                                                                variant='outlined'
                                                                                                autoComplete='off'
                                                                                                style={{ width: '90%', margin: 10, backgroundColor: '#ffffff', marginLeft: '3%', marginRight: '2%' }}
                                                                                                value={BidNotes}
                                                                                                onChange={(val) => updateBidNotes(val.target.value)}
                                                                                                disabled={selectedRowDataValue.counter_offer}
                                                                                                placeholder="Maximum 100 characters"
                                                                                                inputProps={{ maxLength: 100 }}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Grid>
                                                                                <Grid xs={12}>
                                                                                    <Row style={{ marginLeft: '3%' }}>
                                                                                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                                                            <p style={{ fontSize: 13, marginBottom: 0 }}> Truck Dimension (Inch) </p>
                                                                                            <TextField
                                                                                                label='Length'
                                                                                                variant='outlined'
                                                                                                value={txtLength}
                                                                                                autoComplete='off'
                                                                                                style={(!loadBidAmountDisable) ? { width: '22%' } : { width: '16%' }}
                                                                                                inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                onChange={handletxtLength}
                                                                                                disabled={selectedRowDataValue.counter_offer}
                                                                                            />
                                                                                            <IconButton style={{ padding: '0%', width: '5%', top: 8 }} disabled={true}>
                                                                                                <CloseIcon className='closeIcon'
                                                                                                    fontSize='small'
                                                                                                    style={{ marginBottom: 10, padding: 0 }}
                                                                                                />
                                                                                            </IconButton>

                                                                                            <TextField
                                                                                                label='Width'
                                                                                                variant='outlined'
                                                                                                value={txtWidth}
                                                                                                autoComplete='off'
                                                                                                style={(!loadBidAmountDisable) ? { width: '22%' } : { width: '16%' }}
                                                                                                inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                onChange={handletxtWidth}
                                                                                                disabled={selectedRowDataValue.counter_offer}
                                                                                            />
                                                                                            <IconButton style={{ padding: '0%', width: '5%', top: 8 }} disabled={true}>
                                                                                                <CloseIcon className='closeIcon'
                                                                                                    fontSize='small'
                                                                                                    style={{ marginBottom: 10, padding: 0 }}
                                                                                                />
                                                                                            </IconButton>
                                                                                            <TextField
                                                                                                label='Height'
                                                                                                variant='outlined'
                                                                                                value={txtHeight}
                                                                                                autoComplete='off'
                                                                                                style={(!loadBidAmountDisable) ? { width: '21%' } : { width: '16%' }}
                                                                                                inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                                onChange={handletxtHeight}
                                                                                                disabled={selectedRowDataValue.counter_offer}
                                                                                            />
                                                                                            <Button className='buttonColor' style={(loadBidAmount.toString().trim().replace(' ', '') !== '' && loadBidAmount !== '' && parseInt(loadBidAmount) !== 0) ? { marginLeft: '2%', height: 55, textTransform: 'none', backgroundColor: '#101e35' } : { textTransform: 'none', marginLeft: '2%', height: 55, backgroundColor: '#9e9e9ec2' }} variant='contained' size='medium' hidden={loadBidAmountDisable} disabled={!loadBidAmount} onClick={bidForALoad}>
                                                                                                Bid Now
                                                                                            </Button>
                                                                                            <Button style={{ marginLeft: '2%', width: '12%', color: 'white', height: 55, textTransform: 'none', backgroundColor: '#101e35' }} variant='contained' size='medium' color='primary' hidden={!loadBidAmountDisable} onClick={cancelBid}>
                                                                                                Close Bid
                                                                                            </Button>
                                                                                            <Button className='buttonColor' style={reBidBtnStyle ? { marginLeft: '2%', height: 55, textTransform: 'none', backgroundColor: '#101e35' } : { textTransform: 'none', marginLeft: '2%', height: 55, backgroundColor: '#9e9e9ec2' }} variant='contained' size='medium' hidden={!loadBidAmountDisable} disabled={isDisabled} onClick={changeBidForLoad}>
                                                                                                Change Bid
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </Grid>
                                                        <Grid container direction='row'>
                                                            <Grid xs={6}>
                                                                {
                                                                    (loadPostedByUser === user.email) ?
                                                                        <div style={{ height: 5 }} />
                                                                        :
                                                                        <div style={{ height: 15 }} />
                                                                }
                                                                <Row>
                                                                    <Col sm>Stackable:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                        {toShowIcon(loadStackable)}
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Hazmat:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                        {toShowIcon(loadHazmat)}
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Dock High:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                        {toShowIcon(loadDockHigh)}
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm>Temperature Control:</Col>
                                                                    <Col style={{ marginRight: 15 }} sm>
                                                                        {toShowIcon(loadTempControl)}
                                                                    </Col>
                                                                </Row>
                                                            </Grid>
                                                            <Grid xs={6}>
                                                                {
                                                                    <div>
                                                                        {
                                                                            (loadPostedByUser === user.email) ?
                                                                                <div style={{ height: 5 }} />
                                                                                :
                                                                                <div style={{ height: 18 }} />
                                                                        }
                                                                        <Row>
                                                                            <Col sm>Posted By:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{pascalCase(loadPostedBy)}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 18 }} />
                                                                        <Row>
                                                                            <Col sm>Posted By User:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadPostedByUser}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 18 }} />
                                                                        <Row>
                                                                            <Col sm>Load Posted at:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm>{loadPostedTime}</Col>
                                                                        </Row>
                                                                        <div style={{ height: 18 }} />
                                                                        <Row>
                                                                            <Col sm>Load Expires In:</Col>
                                                                            <Col style={{ marginRight: 15 }} sm >{
                                                                                <Countdown onComplete={() => removeExpiredLoads()} autoStart={true} date={Date.parse(loadPostedTime) + parseInt(loadExpiresIn)}>
                                                                                </Countdown>
                                                                            }</Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Box>
                                    <div style={{ height: 10 }} />
                                    <Divider />
                                    {(user.email === process.env.REACT_APP_ADMIN_USER || loadPostedByUser === user.email || loggedInuserCompanyRole === 'Carrier' || loggedInuserCompanyRole === 'Broker&Carrier') && (
                                        <React.Fragment>
                                            <div style={{ height: 10 }} />
                                            <MUIDataTable
                                                title={'Active Bids'}
                                                data={rowDataActiveBidTable}
                                                columns={columnDefsBidTable}
                                                options={optionsBidTable}
                                            ></MUIDataTable>
                                            <div style={{ height: 10 }} />
                                            <MUIDataTable
                                                title={'Closed Bids'}
                                                data={rowDataClosedBidTable}
                                                columns={columnDefsClosedBidTable}
                                                options={optionsClosedBidTable}
                                            ></MUIDataTable>
                                        </React.Fragment>
                                    )
                                    }
                                    <div style={{ height: 10 }} />
                                    {(loadPostedByUser === user.email ||
                                        ((loggedInuserCompanyRole === 'Carrier') || (user.email === process.env.REACT_APP_ADMIN_USER) ||
                                            (loggedInuserCompanyRole === 'Broker' && loadPostedByUser === user.email) ||
                                            (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin' || loggedInuserRole === 'Carrier'))
                                        )) &&
                                        (
                                            <React.Fragment>
                                                <Divider />
                                                <Grid>
                                                    <Box boxShadow={3}>
                                                        <Paper square className='paper'>
                                                            <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor='primary' textColor='primary'>
                                                                <Tab label='Comments' />
                                                            </Tabs>
                                                            <TabPanel value={tabPannerlValue} index={0}>
                                                                <React.Fragment>
                                                                    <div className='homePage'>
                                                                        <div className='leftSide'>
                                                                            <Header />
                                                                            {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                            {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                <List className='Custom-MuiList-root' style={{ maxHeight: 'calc(100vh - 109px)', overflow: 'auto', objectFit: 'cover', height: 415 }}>
                                                                                    {
                                                                                        allChat
                                                                                            .sort(function (b, a) {
                                                                                                return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                            }).map((item) => {
                                                                                                return (loggedInuserRole === 'Broker' || (selectedRowDataValue.posted_by_user === user.email)) ?
                                                                                                    <ListItem button alignItems='flex-start' id={item.carrier_id} value={item.carrier_id} className='Custom-MuiListItem-root' key={item.carrier_id}>
                                                                                                        {
                                                                                                            (item.carrier_id !== undefined) && (
                                                                                                                <div id={item.carrier_id} className='discussion'>
                                                                                                                    <div id={item.carrier_id} className='leftDiscussion'>
                                                                                                                        {
                                                                                                                            (item.carrier_id.charAt(0) !== undefined) && (
                                                                                                                                <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div id={item.carrier_id} className='rightDiscussion'>
                                                                                                                        <div id={item.carrier_id} className='rightDiscussionTop'>
                                                                                                                            <div id={item.carrier_id} className='rightDiscussionTopLeft'>{item.carrier_company}</div>
                                                                                                                        </div>
                                                                                                                        <div id={item.carrier_id} className='rightDiscussionBas'>
                                                                                                                            {
                                                                                                                                (item.bid_amount !== undefined && item.bid_amount !== '$') && (
                                                                                                                                    <div className='rightDiscussionTopRight'>{'Bid: ' + item.bid_amount || loadBidAmount}</div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    </ListItem>
                                                                                                    :
                                                                                                    <ListItem button alignItems='flex-start' id={item.broker_id} value={item.broker_id} className='Custom-MuiListItem-root' key={item.broker_id}>
                                                                                                        {
                                                                                                            (item.broker_id !== undefined) ?
                                                                                                                <div id={item.broker_id} className='discussion'>
                                                                                                                    <div id={item.broker_id} className='leftDiscussion'>
                                                                                                                        {
                                                                                                                            (item.broker_id.charAt(0) !== undefined) && (
                                                                                                                                <Avatar>{item.broker_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div id={item.broker_id} className='rightDiscussion'>
                                                                                                                        <div id={item.broker_id} className='rightDiscussionTop'>
                                                                                                                            <div id={item.broker_id} className='rightDiscussionTopLeft'>{item.broker_company || selectedRowDataValue.posted_by_company}</div>
                                                                                                                        </div>
                                                                                                                        <div id={item.broker_id} className='rightDiscussionBas'>
                                                                                                                            {
                                                                                                                                (item.bid_amount !== undefined && item.bid_amount !== '$') && (
                                                                                                                                    <div className='rightDiscussionTopRight'>{'Bid: ' + item.bid_amount || loadBidAmount}</div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div id={selectedRowDataValue.posted_by_user} className='discussion'>
                                                                                                                    <div id={selectedRowDataValue.posted_by_user} className='leftDiscussion'>
                                                                                                                        {
                                                                                                                            (selectedRowDataValue.posted_by_user.charAt(0) !== undefined) && (
                                                                                                                                <Avatar>{selectedRowDataValue.posted_by_user.charAt(0).toUpperCase()}</Avatar>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div id={selectedRowDataValue.posted_by_user} className='rightDiscussion'>
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionTop'>
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionTopLeft'>{selectedRowDataValue.posted_by_company}</div>
                                                                                                                        </div>
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionBas'>
                                                                                                                            {
                                                                                                                                (selectedRowDataValue.bid_amount !== undefined && selectedRowDataValue.bid_amount !== '$') && (
                                                                                                                                    <div className='rightDiscussionTopRight'>{'Bid: ' + selectedRowDataValue.bid_amount || loadBidAmount}</div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                        }
                                                                                                    </ListItem>;
                                                                                            }
                                                                                            )
                                                                                    }
                                                                                </List>
                                                                            )}
                                                                        </div>
                                                                        <div className='rightSide'>
                                                                            {
                                                                                (chatFirstCarrierName !== undefined) && (
                                                                                    <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf('@'))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                )
                                                                            }
                                                                            <Chat discussion={chatAllMessages} />
                                                                            <div>
                                                                                <form onKeyPress={(e) => { onEnterButtonForChat(e); }}>
                                                                                    <Paper component='form' className={classes.customPaper}>
                                                                                        <InputBase id='inputMesage' autoComplete='off' className={classes.CustomInput} style={{ width: '85%' }} placeholder='Enter your message' />
                                                                                        <IconButton onClick={() => handleOnSendMessageClick()}>
                                                                                            <SendIcon />
                                                                                        </IconButton>
                                                                                    </Paper>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            </TabPanel>
                                                        </Paper>
                                                    </Box>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </React.Fragment>
                        )}

                        {
                            (isPaneOpen && isExpand === false) && (
                                <React.Fragment>
                                    <div style={{ width: '1%' }}></div>
                                    <div style={{ width: '40%' }} border={1}>
                                        <Box style={{ marginBottom: '15%' }}
                                            boxShadow={3}>
                                            <Paper square className='square' style={{ paddingBottom: 35 }}>
                                                <Grid container xs={12} justify='flex-end'>
                                                    <React.Fragment>
                                                        {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                                            <div>
                                                                <IconButton disabled={clickEdit}
                                                                    onClick={(event) => {
                                                                        handleEdit(selectedRowDataValue.lane_id);
                                                                    }}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton onClick={handleSoftDeleteConfirmClick}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                    <IconButton onClick={closeSecondaryPane}>
                                                        <Tooltip title="Close">
                                                            <CloseIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <Grid style={{ fontSize: 14, marginLeft: '40%' }} xs={12}>
                                                        <Grid container direction='row'>
                                                            <Row style={{ fontSize: 18 }}>
                                                                {(clickEdit === false) ?
                                                                    <div> <b>Lane Details</b> </div>
                                                                    :
                                                                    <div> <b>Edit</b></div>
                                                                }
                                                            </Row>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ fontSize: 14 }} xs={12}>
                                                        <Grid container direction='row'>
                                                            <Grid xs={12}>
                                                                <div style={{ height: 7 }} />
                                                                <Row>
                                                                    <Col sm className='alignDetails'>Lane ID:</Col>
                                                                    {(clickEdit === false) ?
                                                                        <Col style={{ marginRight: 15 }} sm>{'L' + laneNumberDetail}</Col>
                                                                        :
                                                                        <Col sm>{'L' + laneNumberDetail}</Col>}
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%', fontWeight: 'bold' } : { paddingLeft: '15%', fontWeight: 'bold', paddingTop: '2%' }}>Pick-Up Destination:</Col>
                                                                    {(clickEdit === false) ?
                                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(loadPickUpDestination)}</Col>
                                                                        :
                                                                        <Col sm>
                                                                            {
                                                                                <Autocomplete
                                                                                    id='AutoCompFromCity'
                                                                                    style={{ height: 29, width: 130, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, }}
                                                                                    value={txtFromCity}
                                                                                    onChange={(val) => onFromPlaceChanged(val)}
                                                                                    onPlaceSelected={(place) => {
                                                                                        onFromPlaceSelected(place);
                                                                                    }}
                                                                                    placeholder='City'
                                                                                    types={['(regions)']}
                                                                                    componentRestrictions={{ country: 'us' }}
                                                                                />
                                                                            }

                                                                        </Col>}
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%', fontWeight: 'bold' } : { paddingLeft: '15%', fontWeight: 'bold', paddingTop: '2%' }}>Miles within Pick up :</Col>
                                                                    {(clickEdit === false) ?
                                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(milesWithinFrom)}</Col>
                                                                        :
                                                                        <Col sm >
                                                                            {
                                                                                <TextField value={milesWithinFromDestination}
                                                                                    autoComplete='off'
                                                                                    error={milesWithinFromDestination < 0}
                                                                                    helperText={milesWithinFromDestination < 0 ? 'Not Valid Input' : ''}
                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                    onChange={handeltxtmileswithinPickUpDestination}
                                                                                    style={{ width: 140 }} />
                                                                            }
                                                                        </Col>}
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%' } : { paddingLeft: '15%', paddingTop: '2%' }}>Pick-Up Date :</Col>
                                                                    {(clickEdit !== true) ?
                                                                        <Col style={{ marginRight: 15, marginTop: 10 }} sm>{toShowCancelIcon(loadPickUpdate)}</Col>
                                                                        :
                                                                        <Col sm>
                                                                            {
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} width={{ width: '100%' }}>
                                                                                    <DatePicker
                                                                                        id='pickup'
                                                                                        selected={pickUpDate}
                                                                                        dateFormat='MM/dd/yyyy'
                                                                                        minDate={new Date()}
                                                                                        onChange={handlePickUpDateChange}
                                                                                        className={pickUpDate ? 'datepicker-Box' : 'errors'}
                                                                                        onKeyDown={(event) => Default(event)}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            }
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%', fontWeight: 'bold' } : { paddingLeft: '15%', fontWeight: 'bold', paddingTop: '2%' }}>Deliver Destination:</Col>
                                                                    {(clickEdit !== true) ?
                                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(loadDeliverDestination)}</Col>
                                                                        :
                                                                        <Col sm>
                                                                            {
                                                                                <Autocomplete
                                                                                    id='AutoCompToCity'
                                                                                    style={{ height: 39, width: 140, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, }}
                                                                                    value={txtToCity}
                                                                                    onChange={(val) => onToPlaceChanged(val)}
                                                                                    onPlaceSelected={(place) => {
                                                                                        onToPlaceSelected(place);
                                                                                    }}
                                                                                    placeholder='City'
                                                                                    types={['(regions)']}
                                                                                    componentRestrictions={{ country: 'us' }}
                                                                                />
                                                                            }
                                                                        </Col>}
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%', fontWeight: 'bold' } : { paddingLeft: '15%', fontWeight: 'bold', paddingTop: '2%' }}>Miles within Deliver:</Col>
                                                                    {(clickEdit === false) ?
                                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>{toShowCancelIcon(milesWithinTo)}</Col>
                                                                        :
                                                                        <Col sm >
                                                                            {
                                                                                <TextField value={milesWithinToDestination}
                                                                                    autoComplete='off'
                                                                                    error={milesWithinToDestination < 0}
                                                                                    helperText={milesWithinToDestination < 0 ? 'Not Valid Input' : ''}
                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 3 }}
                                                                                    onChange={handeltxtmileswithinDeliverDestination}
                                                                                    style={{ width: 140 }} />
                                                                            }
                                                                        </Col>}
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%' } : { paddingLeft: '15%', paddingTop: '2%' }}>Delivery Date :</Col>
                                                                    {(clickEdit !== true) ?
                                                                        <Col style={{ marginRight: 15 }} sm>{toShowCancelIcon(loadDeliverByDate)}</Col>
                                                                        :
                                                                        <Col sm >
                                                                            {
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <DatePicker
                                                                                        id='deliverBy'
                                                                                        selected={deliverBydate}
                                                                                        dateFormat='MM/dd/yyyy'
                                                                                        minDate={new Date()}
                                                                                        onChange={handleDeliverByDateChange}
                                                                                        className={deliverBydate ? 'datepicker-Box' : 'errors'}
                                                                                        onKeyDown={(event) => Default(event)}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            }
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%', fontWeight: 'bold' } : { paddingLeft: '15%', fontWeight: 'bold', paddingTop: '2%' }}>Weight:</Col>
                                                                    {(clickEdit !== true) ?
                                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>
                                                                            {toShowCancelIcon(loadMaxWeight)}
                                                                        </Col>
                                                                        :
                                                                        <Col sm >
                                                                            {
                                                                                <TextField
                                                                                    placeholder='Unit (lbs)'
                                                                                    autoComplete='off'
                                                                                    value={txtMaxWeight}
                                                                                    helperText={txtMaxWeight < 0 ? 'Not Valid Input' : ''}
                                                                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                                                                    onChange={handelTxtMaxWeightChange}
                                                                                    error={(errors.txtMaxWeight && txtMaxWeight === '') || txtMaxWeight < 0}
                                                                                    style={{ width: 140 }}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%' } : { paddingLeft: '15%', paddingTop: '2%' }}>Truck Type:</Col>
                                                                    {(clickEdit !== true) ?
                                                                        <Col style={{ marginRight: 15 }} sm>
                                                                            {toShowCancelIcon(loadLoadType)}
                                                                        </Col>
                                                                        :
                                                                        <Col sm >
                                                                            <MUIAutoComplete
                                                                                //freeSolo
                                                                                disableClearable
                                                                                value={saveCheckboxes}
                                                                                onChange={(event, newValue) => {
                                                                                    handletrucktypeOnchangeValue(event, newValue);
                                                                                }}
                                                                                options={options}
                                                                                style={{ width: 140 }}
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} variant='standard' />
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                <Row>
                                                                    <Col sm style={(clickEdit === false) ? { paddingLeft: '15%', fontWeight: 'bold' } : { paddingLeft: '15%', fontWeight: 'bold', paddingTop: '2%' }}>Number Of Pieces:</Col>
                                                                    {(clickEdit !== true) ?
                                                                        <Col style={{ marginRight: 15, fontWeight: 'bold' }} sm>
                                                                            {toShowCancelIcon(loadNoOfPieces)}
                                                                        </Col>
                                                                        :
                                                                        <Col sm >
                                                                            {
                                                                                <TextField value={txtTotalNoOfPices}
                                                                                    autoComplete='off'
                                                                                    error={txtTotalNoOfPices < 0}
                                                                                    helperText={txtTotalNoOfPices < 0 ? 'Not Valid Input' : ''}
                                                                                    inputProps={{ pattern: '[0-9]*' }}
                                                                                    onChange={handeltxtTotalNoOfPices}
                                                                                    style={{ width: 140 }} />
                                                                            }
                                                                        </Col>}
                                                                </Row>
                                                                <div style={{ height: 15 }} />
                                                                {(clickEdit === true) && (
                                                                    <Row style={{ paddingBottom: '5%' }}>
                                                                        <Col style={{ marginRight: 15, paddingLeft: '15%' }}>
                                                                            {disableSave !== true ?
                                                                                <Button variant='contained' disabled={disableSave} style={{
                                                                                    position: 'absolute',
                                                                                    marginLeft: '10px',
                                                                                    textTransform: 'none',
                                                                                    color: 'white',
                                                                                    backgroundColor: '#101e35'
                                                                                }} onClick={() => ValidateSaveSearchLane()}>
                                                                                    Save
                                                                                </Button>
                                                                                :
                                                                                <Button variant='contained' disabled={disableSave} style={{
                                                                                    position: 'absolute',
                                                                                    marginLeft: '10px',
                                                                                    textTransform: 'none',
                                                                                }} >
                                                                                    Save
                                                                                </Button>
                                                                            }

                                                                        </Col>
                                                                        <Col>
                                                                            {disableCancel !== true ?
                                                                                <Button variant='contained' disabled={disableCancel} style={{
                                                                                    position: 'absolute',
                                                                                    margin: '0px, auto',
                                                                                    textTransform: 'none',
                                                                                    color: 'white',
                                                                                    backgroundColor: '#101e35'
                                                                                }} onClick={() => clearEditForm()}>
                                                                                    Cancel
                                                                                </Button>
                                                                                :
                                                                                <Button variant='contained' disabled={disableCancel} style={{
                                                                                    position: 'absolute',
                                                                                    margin: '0px, auto',
                                                                                    textTransform: 'none',
                                                                                }} >
                                                                                    Cancel
                                                                                </Button>
                                                                            }

                                                                        </Col>
                                                                    </Row>)}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Box>
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main >
        </div >
    );
};

export default SavedLanes;