import React from 'react';
export const ActiveBidsIcon = () => {
    return (
        <svg width="38" height="39" viewBox="0 0 38 39" fill="none" style= {{backgroundColor: 'royalBlue', margin: '25%',marginLeft:'20%',marginTop:'20%'}} xmlns="http://www.w3.org/2000/svg">
<path d="M29.0758 21.7224C24.6526 21.7224 21.0542 25.3208 21.0542 29.744C21.0542 34.1673 24.6526 37.7656 29.0758 37.7656C33.4991 37.7656 37.0974 34.1673 37.0974 29.744C37.0974 25.3208 33.4991 21.7224 29.0758 21.7224ZM29.0758 36.4287C25.3899 36.4287 22.3911 33.4299 22.3911 29.744C22.3911 26.0581 25.3899 23.0593 29.0758 23.0593C32.7617 23.0593 35.7605 26.0581 35.7605 29.744C35.7605 33.4299 32.7617 36.4287 29.0758 36.4287Z" fill="white" stroke="white"/>
<path d="M33.0865 29.0757H29.7442V25.7334C29.7442 25.3637 29.4454 25.0649 29.0757 25.0649C28.706 25.0649 28.4072 25.3637 28.4072 25.7334V29.7442C28.4072 30.1139 28.706 30.4127 29.0757 30.4127H33.0865C33.4562 30.4127 33.755 30.1139 33.755 29.7442C33.755 29.3745 33.4562 29.0757 33.0865 29.0757Z" fill="white" stroke="white"/>
<path d="M19.0488 10.3587H31.0812C31.4509 10.3587 31.7497 10.0599 31.7497 9.6902C31.7497 9.32053 31.4509 9.02173 31.0812 9.02173H19.0488C18.6792 9.02173 18.3804 9.32053 18.3804 9.6902C18.3804 10.0599 18.6792 10.3587 19.0488 10.3587Z" fill="white" stroke="white"/>
<path d="M19.0488 19.7168H31.0812C31.4509 19.7168 31.7497 19.418 31.7497 19.0483C31.7497 18.6787 31.4509 18.3799 31.0812 18.3799H19.0488C18.6792 18.3799 18.3804 18.6787 18.3804 19.0483C18.3804 19.418 18.6792 19.7168 19.0488 19.7168Z" fill="white" stroke="white"/>
<path d="M14.7566 5.84124C14.4772 5.60192 14.0547 5.63201 13.8148 5.91343L9.63817 10.7852L7.08329 8.86939C6.7885 8.64813 6.36937 8.70695 6.14811 9.00308C5.92618 9.29788 5.98634 9.71701 6.2818 9.93827L9.33736 12.2304C9.45702 12.3207 9.59807 12.3641 9.73778 12.3641C9.92695 12.3641 10.1141 12.2846 10.2458 12.1302L14.8295 6.78244C15.0701 6.50302 15.0374 6.08188 14.7566 5.84124Z" fill="white" stroke="white"/>
<path d="M14.7566 15.1985C14.4772 14.9585 14.0547 14.9899 13.8148 15.2707L9.63817 20.1424L7.08329 18.2266C6.7885 18.0054 6.36937 18.0642 6.14811 18.3603C5.92618 18.6551 5.98634 19.0742 6.2818 19.2955L9.33736 21.5877C9.45702 21.6779 9.59807 21.7214 9.73778 21.7214C9.92695 21.7214 10.1141 21.6418 10.2458 21.4874L14.8295 16.1397C15.0701 15.8602 15.0374 15.4391 14.7566 15.1985Z" fill="white" stroke="white"/>
<path d="M13.8148 25.2986L9.63817 30.1697L7.08329 28.2539C6.7885 28.0333 6.36937 28.0914 6.14811 28.3876C5.92618 28.6824 5.98634 29.1015 6.2818 29.3228L9.33736 31.6149C9.45702 31.7052 9.59807 31.7486 9.73778 31.7486C9.92695 31.7486 10.1141 31.6691 10.2458 31.5147L14.8295 26.1676C15.0701 25.8875 15.0381 25.4657 14.7573 25.2257C14.4772 24.9864 14.0547 25.0172 13.8148 25.2986Z" fill="white" stroke="white"/>
<path d="M28.958 1H9.13926C4.65117 1 1 4.65117 1 9.13926V28.958C1 33.4461 4.65117 37.0972 9.13926 37.0972H19.0486C19.4183 37.0972 19.7171 36.7984 19.7171 36.4288C19.7171 36.0591 19.4183 35.7603 19.0486 35.7603H9.13926C5.38782 35.7603 2.33693 32.7094 2.33693 28.958V9.13926C2.33693 5.38782 5.38782 2.33693 9.13926 2.33693H28.958C32.7094 2.33693 35.7603 5.38782 35.7603 9.13926V19.0486C35.7603 19.4183 36.0591 19.7171 36.4288 19.7171C36.7984 19.7171 37.0972 19.4183 37.0972 19.0486V9.13926C37.0972 4.65117 33.4461 1 28.958 1Z" fill="white" stroke="white"/>
</svg>

    );
};