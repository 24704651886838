import React from 'react';
//import icon
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export const toShowIcon = (value) => {
    if (value !== false) {
        return (
            <div>
                YES <CheckCircleIcon className="correctIcon" />
            </div>
        );
    }
    else {
        return (

            <div>
                NA <CancelIcon className="notApplicable" />
            </div>
        );
    }
};
