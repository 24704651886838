import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, TextField,
    Box, Button, MenuItem, Select, Tooltip
} from '@material-ui/core';
import {
    makeStyles,
} from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Col } from 'react-bootstrap';
import LogoComponent from '../../features/LogoComponent';
import { environmentVariables } from '../../constants/environment';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import './style.css';
import Swal from 'sweetalert2';
import { Row } from 'react-bootstrap';
import { sendingNewRequestEmailToAdminApiCall, SendingEmailToCompanyAdminApiCall } from '../../apis/emailtoAdmin';
import { getAllUserInformationApiCall, updateLoginNameApiCall } from '../../apis/user';
import { getparticularcompanyinformationApiCall, getApprovedUserDetailsForCompanyApiCall } from '../../apis/companies';
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 160,
    },
}));

//import Button Style from global css File
import '../../index.css';

const styles = {
    header: {
        // backgroundImage: `url(${"https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/01-Login-truck.png"})`,
        height: '140vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};
const UserCreateProfile = () => {

    // Variable declaration
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [mobileNumber, setMobileNumber] = useState('');
    const [address, setAddress] = useState('');
    const [mcNumber1, setMcNumber1] = useState('');
    const [city, setCity] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');

    /* hidden feature temperorily */
    // const [secondaryMcNumber, setSecondaryMcNumber] = useState("");

    const [secondaryMcNumbers, setSecondaryMcNumbers] = useState([]);
    const [fullName, setFullName] = useState('');
    const [companyRole, setCompanyRole] = useState('');
    const [changedValue, setChangedValue] = useState(false);
    const [disableCheckBox, setDisableCheckBox] = useState(false);
    const [disableDriverCheckBox, setDisableDriverCheckBox] = useState(false);
    const { user } = useAuth0();
    const [currentSelectedUser, setCurrentSelectedUser] = useState([]);

    // Variable to handle company status
    const [disableIfNotNewCompany, setDisableIfNotNewCompany] = React.useState(false);
    const [showText, setShowtext] = useState(false)
    // Function to handle send Socket IO messages
    const sendMessageForSocketIO = async (message, recieverId) => {
        try {
            let response = await axios({
                url: environmentVariables.websocketfetchallusers,
                method: 'get'
            });
            if (response) {
                let connectionIds = [];
                response.data.Items.forEach(element => {
                    if (recieverId === null || recieverId === '') {
                        connectionIds.push(element.connectionId);
                    }
                    else {
                        if (recieverId === element.userid) {
                            connectionIds.push(element.connectionId);
                        }
                    }
                });
                //sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleChangedValue = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            setChangedValue(true);
        }
        else {
            setChangedValue(false);
        }
    };

    //function to handle textaddress Change
    const handelTxtaddressChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setAddress(event.target.value);
        }
    };
    //function to handle textCity Change
    const handelTxtcityChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCity(event.target.value);
        }
    };

    //function to handle CompanyName Change
    const handelTxtcompanyNameChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyName(event.target.value);
        }
    };

    //function to handle State Change
    const handelTxtStateChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setState(event.target.value);
        }
    };

    /* Hidden temperorily */
    // function GenerateMcNumbers() {
    //     if (!disableIfNotNewCompany) {
    //         setSecondaryMcNumbers([...secondaryMcNumbers, ""])
    //     }
    // }

    //function to handle Name Change
    const handelNameChange = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setFullName(event.target.value);
        }
    };

    const clearingAllFields = () => {
        setAddress('');
        setCompanyName('');
        setCity('');
        setCompanyRole('');
        setState('');
        document.getElementById('Broker').checked = false;
        setDisableIfNotNewCompany(false);
        setDisableDriverCheckBox(false);
        document.getElementById('Carrier').checked = false;
        document.getElementById('Driver').checked = false;
        if (document.getElementById('NewCompany')) {
            document.getElementById('NewCompany').checked = false;
        }
        setDisableIfNotNewCompany(false);
    };

    //function to handle MC NUMBER Change
    const handelTxtmcNumber1Change = async (event) => {
        handleChangedValue(event);
        if (event.target.validity.valid || event.target.value === '') {
            setMcNumber1(event.target.value);
            if (event.target.value.length >= 6) {
                // Check company presentInfo
                try {
                    let companyInformationData = await getparticularcompanyinformationApiCall('?company_mc_number=', event.target.value);
                    if (companyInformationData) {
                        if (companyInformationData !== 'McNumberNotFound') {
                            setDisableIfNotNewCompany(true);
                            document.getElementById('Driver').checked = false;
                            setSecondaryMcNumbers([]);
                            setCompanyName(companyInformationData[0].company_name);
                            setCompanyRole(companyInformationData[0].company_role);
                            setAddress(companyInformationData[0].address);
                            setCity(companyInformationData[0].city);
                            setState(companyInformationData[0].state);
                            setStatus(companyInformationData[0].status);
                            if (companyInformationData[0].company_role === 'Broker' || companyInformationData[0].company_role === 'Carrier') {
                                setDisableCheckBox(true);
                            }
                            if (document.getElementById('NewCompany')) {
                                document.getElementById('NewCompany').checked = false;
                            }
                            if (companyInformationData[0].company_role === 'Broker') {
                                document.getElementById('Broker').checked = true;
                                setDisableDriverCheckBox(true);
                            }
                            if (companyInformationData[0].company_role === 'Carrier') {
                                document.getElementById('Carrier').checked = true;
                                setDisableDriverCheckBox(false);
                            }

                            /* commented Broker&Carrier role functionality temporerily */
                            // if (newResponse[0].company_role === "Broker&Carrier") {
                            //     document.getElementById("Broker").checked = true;
                            //     document.getElementById("Carrier").checked = true;
                            //     setDisableDriverCheckBox(false)
                            // }

                        }
                        else {
                            if (document.getElementById('NewCompany')) {
                                document.getElementById('NewCompany').checked = true;
                            }
                            setDisableIfNotNewCompany(false);
                            setCompanyName('');
                            setCompanyRole('');
                            setAddress('');
                            setCity('');
                            setState('');
                            document.getElementById('NewCompany').checked = true;
                            document.getElementById('Broker').checked = false;
                            document.getElementById('Carrier').checked = false;
                        }

                    }
                }
                catch (error) {
                    console.log(error, 'Getting company details Failed');
                }
            }
            else {
                clearingAllFields();
            }
        }
    };

    /* Hidden feature temperorily */
    //function to generate MCNumbers
    // function handleGenerateMCNumbersChange(e, mcnumber) {
    //     if (e.target.validity.valid || e.target.value === "") {
    //         let tempArray = secondaryMcNumbers;
    //         tempArray[mcnumber] = e.target.value
    //         setSecondaryMcNumber(e.target.value)
    //         setSecondaryMcNumbers(tempArray)
    //     }
    // }

    // Function to handle role changes in UserDetails Table
    const handleCompanyRoleChange = (event) => {
        setCompanyRole(event.target.value);
        if (event.target.value === 'Broker') {
            document.getElementById('Broker').checked = true;
            setDisableCheckBox(true);
            setDisableDriverCheckBox(true);
        }
        else {
            document.getElementById('Broker').checked = false;
        }
        if (event.target.value === 'Carrier') {
            document.getElementById('Carrier').checked = true;
            setDisableCheckBox(true);
        }
        else {
            document.getElementById('Carrier').checked = false;
        }

        /* commented Broker&Carrier role functionality temporerily */
        // if (event.target.value === "Broker&Carrier") {
        //     document.getElementById("Broker").checked = true;
        //     document.getElementById("Carrier").checked = true;
        //     setDisableCheckBox(false);
        // }
    };

    //function to validate the fields
    const validate = () => {
        let profileValidation = {};
        if (mcNumber1.length < 6 && mcNumber1.length > 10) {
            profileValidation.mcNumber1Length = 'error';
        }
        else {
            profileValidation.mcNumber1Length = '';
        }
        /* hidden this feature temperorily */
        // if (secondaryMcNumber !== "" &&  secondaryMcNumber.length < 6 && secondaryMcNumber.length > 10) {
        //     temp.secMCNumberLength = "error"
        // }
        // else {
        //     temp.secMCNumberLength = ""
        // }
        profileValidation.email = email ? '' : 'error';
        profileValidation.companyName = companyName ? '' : 'error';
        profileValidation.mcNumber1 = mcNumber1 ? '' : 'error';
        profileValidation.companyRole = companyRole ? '' : 'error';
        profileValidation.address = address ? '' : 'error';
        profileValidation.city = city ? '' : 'error';
        profileValidation.state = state ? '' : 'error';
        if(document.getElementById("Driver").checked && document.getElementById("Carrier").checked){
            profileValidation.userRoleCheckboxValidation = 'error';
            setShowtext(true);
        }
        else{
            profileValidation.userRoleCheckboxValidation = '';
            setShowtext(false);
        }
        setErrors({
            ...profileValidation
        });
        return Object.values(profileValidation).every(x => x === '');
    };

    // Function to send email to admin
    const emailAddress = async (email) => {
        try {
            let approveMailResponse = await sendingNewRequestEmailToAdminApiCall(email);
            if (approveMailResponse !== undefined) {
                sendMessageForSocketIO('NewUserUnderVerification', process.env.REACT_APP_ADMIN_USER);
            }
        }
        catch (error) {
            console.log(error, 'Sending new user request email to admin failed');
        }
    };

    //function to trigger on click of approve in newuser table
    const getUserDetailsResponse = async (company_mc_number) => {
        try {
            let approvedCompanyUserDetails = await getApprovedUserDetailsForCompanyApiCall(company_mc_number);
            if (approvedCompanyUserDetails !== undefined) {
                if (approvedCompanyUserDetails !== 'UserNotPresent') {
                    try {
                        await SendingEmailToCompanyAdminApiCall(email, approvedCompanyUserDetails);
                    }
                    catch (error) {
                        console.log(error, 'Sending email to company admin failed');
                    }
                }
            }
        }
        catch (error) {
            console.log(error, 'Getting approved compamy details failed');
        }
    };

    //function to post userDetails into DB
    const handlepushData = async () => {
        emailAddress(email);
        if (validate()) {
            if (mobileNumber.length === 10 && mobileNumber !== '') {
                let mcNumbersArray = [];
                let mcNumbers = [];
                let userRole = '';
                mcNumbers.push(mcNumber1);
                for (let i = 0; i < secondaryMcNumbers.length; i++) {
                    if (secondaryMcNumbers[i] !== '' && secondaryMcNumbers[i].length > 6) {
                        mcNumbers.push.apply(mcNumbers, secondaryMcNumbers);
                    }
                }
                mcNumbers.map((mcNumber) => {
                    let mcNumberobject = { mcNumber: mcNumber, role: '' };
                    mcNumbersArray.push(mcNumberobject);
                    getUserDetailsResponse(mcNumber);
                    return mcNumber;
                });
                if (document.getElementById('Broker').checked) {
                    userRole = 'Broker';
                }
                if (document.getElementById('Carrier').checked) {
                    userRole = 'Carrier';
                }

                /* commented Broker&Carrier role functionality temporerily */
                // if (document.getElementById("Broker").checked && document.getElementById("Carrier").checked) {
                //     userRole = "Broker&Carrier";
                // } 

                if (document.getElementById('NewCompany') && document.getElementById('NewCompany').checked) {
                    userRole = 'CompanyAdmin';
                }
                if (document.getElementById('Driver').checked) {
                    userRole = 'Driver';
                }
                let listValues = currentSelectedUser;
                listValues.user_name = fullName;
                listValues.address = address;
                listValues.mc_numbers = mcNumbersArray;
                listValues.first_name = fullName.split(' ')[0];
                listValues.last_name = fullName.split(' ')[1] ? fullName.split(' ')[1] : '';
                listValues.city = city;
                listValues.companyName = companyName.toUpperCase();
                listValues.state = state;
                listValues.mobile_number = mobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
                listValues.user_role = userRole;
                listValues.company_role = companyRole;
                listValues.login_status = 'ProfileCreated';
                listValues.status = 'ProfileCreated';
                listValues = { ...listValues, 'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss') };
                listValues = { ...listValues, 'new_user_status': 'true' };
                if (disableIfNotNewCompany === false) {
                    listValues = { ...listValues, 'create_new_company': 'true' };
                }
                listValues = { ...listValues, 'company_mc_number': mcNumber1 };
                try {
                    let response = await updateLoginNameApiCall(listValues);
                    if (response) {
                        history.push({ pathname: '/underverification' });
                    }
                }
                catch (error) {
                    console.log('create profile error', error);
                }
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    };

    const checkFirstUserStatus = () => {
        if ((status !== 'ProfileAccepted' && disableIfNotNewCompany)) {
            Swal.fire({
                text: 'Your company is not yet approved by admin',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handlepushData();
                }
            });
        }
        else {
            handlepushData();
        }
    };

    // Function that is triggered on page load
    useEffect(() => {
        (async () => {
            try {
                let allUserData = await getAllUserInformationApiCall();
                if (allUserData !== undefined) {
                    if (allUserData.length !== 0) {
                        const findUser = allUserData.find(({ email_address }) => email_address === user.email);
                        setEmail(findUser.email_address);
                        setFullName(findUser.user_name);
                        setCurrentSelectedUser(findUser);
                        if (findUser.login_status === 'ProfileCreated' && !findUser.user_role) {
                            if (findUser.mobile_number) {
                                setMobileNumber(findUser.mobile_number);
                            }
                            if (findUser.address) {
                                setAddress(findUser.address);
                            }
                            if (findUser.city) {
                                setCity(findUser.city);
                            }

                            if (findUser.company_name) {
                                setCompanyName(findUser.company_name);
                            }
                            if (findUser.state) {
                                setState(findUser.state);
                            }
                            if (findUser.user_name) {
                                setFullName(findUser.user_name);
                            }
                            if (findUser.user_role) {
                                if (findUser.user_role === 'Broker') {
                                    document.getElementById('Broker').checked = true;
                                }
                                if (findUser.user_role === 'Carrier') {
                                    document.getElementById('Carrier').checked = true;
                                }

                                /* commented Broker&Carrier role functionality temporerily */
                                // if (findUser.user_role === "Broker&Carrier") {
                                //     document.getElementById("Broker").checked = true;
                                //     document.getElementById("Carrier").checked = true;
                                // }

                                if (findUser.user_role === 'CompanyAdmin') {
                                    document.getElementById('Broker').checked = true;
                                    document.getElementById('Carrier').checked = true;
                                    document.getElementById('NewCompany').checked = true;
                                }
                            }
                            if (findUser.mc_numbers) {
                                if (findUser.mc_numbers.length === 1) {
                                    setMcNumber1(findUser.mc_numbers[0]['mcNumber']);
                                }
                                for (let i = 0; i < findUser.mc_numbers.length; i++) {
                                    if (i === 0) {
                                        setMcNumber1(findUser.mc_numbers[i]['mcNumber']);
                                    }
                                    else {
                                        if (!disableIfNotNewCompany) {
                                            setSecondaryMcNumbers([...secondaryMcNumbers, '']);
                                        }
                                        let tempArray = secondaryMcNumbers;
                                        tempArray[i - 1] = findUser.mc_numbers[i]['mcNumber'];
                                        setSecondaryMcNumbers(tempArray);
                                    }
                                }
                            }
                            history.push({ pathname: '/underverification' });
                        }
                        else if (findUser.login_status === 'AgreementAgreed' && findUser.user_role !== undefined && findUser.user_role !== '') {
                            try {
                                let companyInformationData = await getparticularcompanyinformationApiCall('?mc_number=', findUser.company_mc_number);
                                if (companyInformationData !== undefined) {
                                    if (companyInformationData !== 'McNumberNotFound') {
                                        setStatus(companyInformationData[0].status);
                                    }
                                    setCompanyName(findUser.company_name);
                                    setCompanyRole(findUser.company_role);
                                    setMcNumber1(findUser.company_mc_number);
                                    setAddress(findUser.address);
                                    setCity(findUser.city);
                                    setState(findUser.state);
                                    setDisableCheckBox(true);
                                    setDisableIfNotNewCompany(true);
                                    setDisableDriverCheckBox(true);
                                    if (findUser.user_role === 'Broker') {
                                        document.getElementById('Broker').checked = true;
                                    }
                                    if (findUser.user_role === 'Carrier') {
                                        document.getElementById('Carrier').checked = true;
                                    }

                                    /* commented Broker&Carrier role functionality temporerily */
                                    // if(findUser.user_role === "Broker&Carrier"){
                                    //     document.getElementById("Broker").checked = true;
                                    //     document.getElementById("Carrier").checked = true;
                                    // }

                                    if (findUser.user_role === 'Driver') {
                                        document.getElementById('Driver').checked = true;
                                    }
                                }
                            }
                            catch (error) {
                                console.log(error, 'Getting company details failed');
                            }
                        }
                    }
                }
            }
            catch (error) {
                console.log(error, 'Get user details in  create  profile failed');
            }
        })();
    }, [history, user.email, secondaryMcNumbers, disableIfNotNewCompany]);

    return (
        <div style={styles.header}>
            <div style={styles.content}>
                <br />
                <LogoComponent />
                <br />
                <h2 className='commonBox'><b>Step 3/4</b></h2>
                <Grid container direction="row-reverse" alignItems="center" justify="center" className="GridContainer">
                    <Grid container xs={12} sm={6} alignItems="end" direction="column" className="GridContainerOne">
                        <Paper elevation={0} className='CreateProfilePaper'>
                            <Grid container direction="row">
                                <Grid item xs={5}>
                                </Grid>
                                <div className='commonDiv' />
                                <div className='CreateProfileDivOne'>
                                    <Box paddingRight={5} paddingLeft={5} className="commonBox">
                                        <h2 style={{ marginLeft: '6%' }} ><b>Update Profile</b></h2>
                                    </Box>
                                </div>
                                <div className='commonDiv' />
                                <Grid container direction="row">
                                    <Box paddingRight={5} paddingLeft={5} className="commonBox">
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid container>
                                                        <TextField label="MC Number*" className='textFieldStyle' value={mcNumber1} onChange={handelTxtmcNumber1Change} error={errors.mcNumber1 || errors.mcNumber1Length} inputProps={{ pattern: '[0-9]*', maxLength: 10 }}></TextField>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container>
                                                        <TextField label="Company Name*" className='textFieldStyle'
                                                            disabled={disableIfNotNewCompany}
                                                            value={companyName}
                                                            onChange={handelTxtcompanyNameChange}
                                                            error={errors.companyName}>
                                                        </TextField>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container>
                                                        <FormControl className={classes.formControl} hidden={mcNumber1.length < 6 || disableIfNotNewCompany}>
                                                            <InputLabel style={{ width: '100%' }} id="demo-controlled-open-select-label" >Company Role</InputLabel>
                                                            <Select
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                value={companyRole}
                                                                error={errors.companyRole}
                                                                style={{ width: '115%', marginTop: (disableIfNotNewCompany || mcNumber1 === '') === false ? '12%' : '1%' }}
                                                                onChange={handleCompanyRoleChange}
                                                            >
                                                                <MenuItem value="Broker">Broker</MenuItem>
                                                                <MenuItem value="Carrier">Carrier</MenuItem>
                                                                {/* commented Broker&Carrier role functionality temporerily */}
                                                                {/* <MenuItem value="Broker&Carrier">Broker & Carrier</MenuItem> */}
                                                            </Select>
                                                        </FormControl>
                                                        <TextField disabled={disableIfNotNewCompany} label="Address " required className='textFieldStyle' style={{ marginTop: (disableIfNotNewCompany || mcNumber1 === '') === false ? '3%' : '1%' }} value={address} error={errors.address && address === ''} onChange={handelTxtaddressChange}>mahesh</TextField>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container>
                                                        <TextField disabled={disableIfNotNewCompany} label="City" required className='textFieldStyle' error={errors.state && city === ''} value={city} onChange={handelTxtcityChange}></TextField>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    {/* <Grid container>
                                                        <Grid container>
                                                            <AddCircleOutlinedIcon onClick={GenerateMcNumbers} />
                                                            <Typography variant='caption' style={{ fontSize: "11px", lineHeight: "2.40" }}> Add one more MC Number</Typography>
                                                        </Grid>
                                                        <Grid container>
                                                            {
                                                                secondaryMcNumbers.map((country, mcnumber) => {
                                                                    return (
                                                                        <div key={mcnumber}>
                                                                            <TextField label="MC Number" style={{ width: "80%" }} onChange={(e) => handleGenerateMCNumbersChange(e, mcnumber)} value={secondaryMcNumber} error={errors.secMCNumberLength} inputProps={{ pattern: "[0-9]*", maxLength: 10 }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid container>
                                                        <Tooltip title={email}>
                                                            <TextField disabled label="Email Address" className='textFieldStyle' value={email} error={errors.email}></TextField>
                                                        </Tooltip>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container>
                                                        <TextField disabled label="Full Name" className='textFieldStyle' onChange={handelNameChange} value={fullName}></TextField>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container>
                                                        <Grid container className='mobileGrid'>
                                                            <PhoneInput
                                                                value={mobileNumber}
                                                                onChange={(phone) => setMobileNumber(phone)}
                                                                localization={'us'}
                                                                onlyCountries={['us']}
                                                                country={'us'}
                                                                defaultCountry={'us'}
                                                                disableDropdown={true}
                                                                disableCountryCode={true}
                                                                placeholder='(xxx) xxx-xxxx'
                                                                error={errors.mobileNumber}
                                                                containerStyle={{
                                                                    border: '1px solid black',
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container>
                                                        <TextField disabled={disableIfNotNewCompany} label="State" className='textFieldStyle' value={state} required error={errors.state && state === ''} onChange={handelTxtStateChange} ></TextField>
                                                    </Grid>
                                                    <div className='commonDiv' />
                                                    <Grid container xs={9} >
                                                        {(!disableIfNotNewCompany) && (
                                                            <Row>
                                                                <Col sm>{<input type="checkbox" className="createProfileCheckBox" value="1" id="NewCompany" color="primary" hidden={disableIfNotNewCompany} readOnly={!disableIfNotNewCompany} />}New Company</Col>
                                                            </Row>
                                                        )}
                                                        <Row>
                                                            <Col sm={12} className="createProfileForCol">{<input type="checkbox" className="createProfileCheckBox" value="2" id="Broker" color="primary" readOnly={(disableCheckBox && companyRole === 'Carrier') || !disableIfNotNewCompany} />}Broker</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} className="createProfileForCol">{<input type="checkbox" className="createProfileCheckBox" value="3" id="Carrier" color="primary" readOnly={(disableCheckBox && companyRole === 'Broker') || !disableIfNotNewCompany} />}Carrier</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>{<input type="checkbox" className="createProfileCheckBox" value="3" id="Driver" color="primary" readOnly={(disableDriverCheckBox) || !disableIfNotNewCompany} />}Driver</Col>
                                                        </Row>
                                                    </Grid>
                                                    {
                                                        ((showText) && (
                                                           <span className="UserRoleHelper">Please select any one user role</span>
                                                        ))
                                                    } 
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <div className="commonDiv" />
                                <div className="commonBox">
                                    <Box paddingRight={5} paddingLeft={5} className="commonBox">
                                        <Button variant="contained" onClick={checkFirstUserStatus} className="commonButton">Submit</Button>
                                    </Box>
                                </div>
                                <div className="commonDiv" />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid container xs={12} sm={6} alignItems="baseline" direction="column" className="imageGrid">
                        <img src="https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/01-Login-truck.png" alt="Loadeo Logo" className="logoImg" />
                    </Grid>
                </Grid>
            </div>
        </div >
    );
};

export default UserCreateProfile;
