import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoComponent from '../../features/LogoComponent';
import { Grid, Paper, Button, Box, Avatar } from '@material-ui/core';
import { Row } from 'react-bootstrap';

const styles = {
    header: {
        backgroundImage: `url(${'https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/01-Login-truck.png'})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

const RejectedUser = () => {

    const { logout } = useAuth0();

    const homePage = () => {
        logout({
            returnTo: window.location.origin,
        });
    };


    return (
        <div style={styles.header}>
            <div style={styles.content}>
                <br />
                <LogoComponent />
                <br />
                <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100%' }}>
                    <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: '60%' }}>
                        <Paper elevation={3} style={{ width: '70%', height: '95%', display: 'flex' }}>
                            <Grid container direction="row">
                                <div style={{ width: '100%', height: 20 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Box paddingRight={5} paddingLeft={5} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Avatar alt="Remy Sharp" style={{ width: '10vh', height: '10vh' }} src="https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/acc_verification.png" />
                                    </Box>
                                </div>
                                <div style={{ width: '100%', height: 0 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Box paddingRight={5} paddingLeft={5} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <h2><b>Account Not Approved</b></h2>
                                    </Box>
                                </div>
                                <div style={{ width: '100%', height: 0 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Box paddingRight={5} paddingLeft={5} style={{ width: '100%' }}>
                                        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>Your account is not approved. Please contact</Row>
                                        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>Company Admin for more information</Row>
                                    </Box>
                                </div>
                                <div style={{ width: '100%', height: 5 }} />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Button variant="contained" onClick={homePage} style={{ width: '40%', marginTop: 10, backgroundColor: 'black', color: 'white', textTransform: 'none' }}> Ok</Button>
                                </div>
                                <div style={{ width: '100%', height: 20 }} />
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
};

export default RejectedUser;