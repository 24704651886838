import React from 'react';
export const Disappointed = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="miter">
        <circle cx="12" cy="12" r="10"></circle><line x1="8" y1="9" x2="8.01" y2="9" strokeWidth="2" strokeLinecap="round"></line>
        <line x1="15.99" y1="9" x2="16" y2="9" strokeWidth="2" strokeLinecap="round"></line>
        <path d="M7.39,16.05a5,5,0,0,1,2.66-2.66,5.07,5.07,0,0,1,3.9,0,5,5,0,0,1,2.66,2.66"></path>
</svg>
    );
};
