import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Button,
    Paper,
    Snackbar,
    CssBaseline,
    IconButton,
    TextField,
    Tooltip,
    Card,
    CardContent,
    Switch
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
    MuiThemeProvider,
    makeStyles,
    createTheme
} from '@material-ui/core/styles';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { uploadFile } from 'react-s3';
import * as AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import { pascalCase } from '../../utils';

// import statement for icons
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

import { DropzoneDialog } from 'material-ui-dropzone';
import MUIDrawer from '../../components/Drawer';
import { getAllUserInformationApiCall } from '../../apis/user';
import { getprospectiveclientsApiCall, addProspectiveClientApiCall, addProspectiveClientInBulkApiCall } from '../../apis/prospectiveclient';
import { handleUpdateEmailStatusApiCall, handleUpdateMainToggleStatusApiCall } from '../../apis/emailtoAdmin';

const s3 = new AWS.S3({
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, accessKeyId: process.env.REACT_APP_ACCESS_KEY, region: process.env.REACT_APP_S3_BUCKET_REGION
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    compRoleFormControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },

}));

const ProspectiveClients = () => {
    const classes = useStyles();
    const { user } = useAuth0();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [openDropZone, setOpenDropZone] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [lastName, setLastName] = useState('');
    const [rowData, setRowData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [inputList, setInputList] = useState([{ first_name: '', last_name: '', email_address: '' }]);

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSecondaryTextFields, setOpenSecondaryTextFields] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [colorseverity, setColorseverity] = useState('');

    // constant to handle circular loader
    const [loadingProspectiveClients, setLoadingProspectiveCients] = useState('');

    // constant to handle no of invalid emails
    const [emailErrorCount, setEmailErrorCount] = useState(0);

    //constatnt to set toggle status for activate and deactivate email functionality
    const [toggleStatus, setToggleStatus] = useState('');
    const [emailStatusId, setEmailStatusId] = useState('');

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    //function to check user Role
    function checkUserRole() {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/forbidden' });
        }
    }

    // Function to get all prospective clients
    const getPospectiveClients = async () => {
        try {
            let prospectiveclientsdata = await getprospectiveclientsApiCall();
            if (prospectiveclientsdata !== undefined) {
                if (prospectiveclientsdata.data !== 'UserNotPresent') {
                    if (prospectiveclientsdata.data.length === 0) {
                        setLoadingProspectiveCients(false);
                    }
                    else {
                        setLoadingProspectiveCients(true);
                        let userDetails = [];
                        prospectiveclientsdata.data.forEach(element => {
                            if (element.status_id) {
                                setToggleStatus(element.email_status);
                                setEmailStatusId(element.status_id);
                            }
                            else {
                                userDetails.push(element);
                            }
                        });
                        userDetails.sort((a, b) =>
                            b.time_stamp.localeCompare(a.time_stamp)
                        );
                        setRowData(userDetails);
                    }
                }
                else {
                    setRowData([]);
                    if (prospectiveclientsdata.data.length === 0) {
                        setLoadingProspectiveCients(false);
                    }
                    else {
                        setLoadingProspectiveCients(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error, 'Get prospective clients error');
        }
    };

    const activateEmailFunctionality = async (rowValues) => {
        const listValues = {
            user_id: rowValues[0],
            status: 'Activated'
        };
        try {
            let response = await handleUpdateEmailStatusApiCall(listValues);
            if (response !== undefined) {
                getPospectiveClients();
            }
        }
        catch (error) {
            console.log(error, 'Activating email functionality failed');
        }
    };

    const deactivateEmailFunctionality = async (rowValues) => {
        const listValues = {
            user_id: rowValues[0],
            status: 'Deactivated'
        };
        try {
            let response = await handleUpdateEmailStatusApiCall(listValues);
            if (response) {
                getPospectiveClients();
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 3,
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };

    const columnDefs = [
        {
            label: 'User Id',
            name: 'user_id',
            options: { display: false }
        },
        {
            label: 'First Name',
            name: 'first_name',
            options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[1] !== null && rowuserdata.rowData[1] !== undefined && rowuserdata.rowData[1] !== ' ' && (!rowuserdata.rowData[1].includes('@') || !/\d/.test(rowuserdata.rowData[1]))) {
                        return (
                            pascalCase(rowuserdata.rowData[1])
                        );
                    }
                    else {
                        return rowuserdata.rowData[1];
                    }

                }
            }
        },
        {
            label: 'Last Name',
            name: 'last_name',
            options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[2] !== null && rowuserdata.rowData[2] !== undefined && rowuserdata.rowData[2] !== ' ' && (!rowuserdata.rowData[2].includes('@') || !/\d/.test(rowuserdata.rowData[2]))) {
                        return (
                            pascalCase(rowuserdata.rowData[2])
                        );
                    }
                    else {
                        return rowuserdata.rowData[2];
                    }

                }
            }
        },
        {
            label: 'Email Id',
            name: 'email_address',
        },
        {
            label: 'Email Functionality',
            name: 'email_status',
            options: {
                customBodyRender: (value, row) => {
                    return (
                        <Switch
                            disabled={toggleStatus === 'Deactivated'}
                            checked={value === 'Deactivated' ? false : true}
                            color="primary"
                            onClick={(event) => {
                                if (value === 'Deactivated') {
                                    event.stopPropagation();
                                    activateEmailFunctionality(row.rowData);
                                }
                                else if (value === 'Activated') {
                                    event.stopPropagation();
                                    deactivateEmailFunctionality(row.rowData);
                                }
                            }}
                        >
                        </Switch>
                    );
                }
            }
        },
    ];

    const options = {
        headerStyle: { fontWeight: 600 },
        titleStyle: { fontWeight: 600 },
        responsive: 'vertical',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        search: true,
        filter: false,
        Add: false,
        textLabels: {
            body: {
                noMatch: !loadingProspectiveClients ?
                    <CircularProgress /> :
                    'There are no prospective clients'
            },
        }
    };

    // Function to handle MuiAlert
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    // Function to validate email field
    const validate = () => {
        let temp = {};
        temp.emailId = emailId ? '' : 'error';
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    // function to validate secondary users email fields
    const validateSecondaryEmailIdFields = () => {
        let errorCount = 0;
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i].email_address === '') {
                errorCount += 1;
            }
        }
        setEmailErrorCount(errorCount);
        return errorCount;
    };

    // Function to handle open drop down zone
    const handleOpen = () => {
        setOpenDropZone(true);
    };

    // Function to handle dropzone close
    const handleClose = () => {
        setOpenDropZone(false);
    };

    // Function to open form
    const handleOpenForm = () => {
        setInputList([]);
        setOpen(true);
    };

    // Function to clear text field values
    const clearingAllFields = async () => {
        document.getElementById('email').value = ' ';
        setFirstName('');
        setLastName('');
        setEmailId('');
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i].first_name) {
                inputList[i].first_name = '';
            }
            if (inputList[i].last_name) {
                inputList[i].last_name = '';
            }
            if (inputList[i].email_address) {
                inputList[i].email_address = '';
            }
        }
        setOpen(false);
    };

    //function to close secondary pane
    const closeSecondaryDetailPane = () => {
        setOpen(false);
        clearingAllFields();
    };

    // Function to handle first name change
    const handleFirstNameChange = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            setFirstName(event.target.value);
        }
    };

    // Function to handle email change
    const handleEmailIdChange = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            setEmailId(event.target.value);
        }
    };

    // Function to handle last name change
    const handleLastNameChange = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            setLastName(event.target.value);
        }
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // Function to remove textfield 
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // Function to add more than one text fields
    const handleAddClick = () => {
        setOpenSecondaryTextFields(true);
        setInputList([...inputList, { first_name: '', last_name: '', email_address: '' }]);
    };

    // Function to get all user details
    const getAllUsers = async () => {
        try {
            let alluserinformationdata = await getAllUserInformationApiCall();
            if (alluserinformationdata !== undefined) {
                setAllUsers(alluserinformationdata);
            }
        }
        catch (error) {
            console.log(error, 'Get all user details error in prospective client');
        }
    };

    useEffect(() => {
        checkUserRole();
        getPospectiveClients();
        getAllUsers();
    }, []);

    const activateMainToggleFunctionality = async () => {
        setToggleStatus('Activated');
        let listValue = [];
        listValue = { ...listValue, 'status_id': emailStatusId };
        listValue = { ...listValue, 'email_status': 'Activated' };

        try {
            let response = await handleUpdateMainToggleStatusApiCall(listValue);
            if (response !== undefined) {
                getPospectiveClients();
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const deactivateMainToggleEmailFunctionality = async () => {
        setToggleStatus('Deactivated');
        let listValue = [];
        listValue = { ...listValue, 'status_id': emailStatusId };
        listValue = { ...listValue, 'email_status': 'Deactivated' };
        try {
            let response = await handleUpdateMainToggleStatusApiCall(listValue);
            if (response) {
                getPospectiveClients();
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to add prospective clients to db
    const handleAddButtonClick = async () => {
        let errorCount = validateSecondaryEmailIdFields();
        let data = [];
        if (validate() && errorCount === 0) {

            /* not using tempororily */
            // let response = checkForDuplicateUserData(inputList, emailId)
            // let allUserResponse = checkForLoadeoRegularUser(inputList, emailId)
            // let getDuplicateResponse = checkForDuplicateEmailEntries(inputList, emailId)
            let mainUserObject = { first_name: firstName, last_name: lastName, email_address: emailId };
            inputList.push(mainUserObject);
            data = JSON.stringify(inputList);
            try {
                let clientData = await addProspectiveClientApiCall(data);
                if (clientData !== undefined) {
                    if (clientData.data === 'added records to the db') {
                        setSnackBarMessageText('Added user details successfully !!');
                        setColorseverity('success');
                        setOpenSnackBar(true);
                    }
                    else {
                        setSnackBarMessageText('Found all duplicate data !!');
                        setColorseverity('error');
                        setOpenSnackBar(true);
                    }
                    getPospectiveClients();
                    clearingAllFields();
                }
            }
            catch (error) {
                console.log(error, 'Error in adding prospective client');
            }
        }
    };

    /* not using this tempororily */
    // Function to check whether user exist in prospective cients db or not
    // const checkForDuplicateUserData = (inputList, emailId) => {
    //     let noOfUser = 0;
    //     rowData.forEach(element => {
    //         if (element.email_address) {
    //             if (element.email_address === emailId) {
    //                 noOfUser += 1;
    //                 Swal.fire({
    //                     text: `${pascalCase(emailId.split("@")[0])} user already exists in the mailing list`,
    //                     type: null,
    //                     allowOutsideClick: false,
    //                     showConfirmButton: true,
    //                     confirmButtonColor: '#0066cc'
    //                 });
    //                 return true;
    //             }
    //             for (let i = 0; i < inputList.length; i++) {
    //                 if (element.email_address === inputList[i].email_address) {
    //                     noOfUser += 1;
    //                     Swal.fire({
    //                         text: `${pascalCase(inputList[i].email_address.split("@")[0])} user already exists in the mailing list`,
    //                         type: null,
    //                         allowOutsideClick: false,
    //                         showConfirmButton: true,
    //                         confirmButtonColor: '#0066cc'
    //                     });
    //                     return true;
    //                 }
    //             }
    //         }
    //     });
    //     if (noOfUser === 0) {
    //         setOpen(false);
    //         return false;
    //     }
    // }

    /* not using this tempororily */
    // Function to check whether user exist in loadeo regular user db or not
    // const checkForLoadeoRegularUser = (inputList, emailId) => {
    //     let noOfUser = 0;
    //     allUsers.forEach(element => {
    //         if (element.email_address) {
    //             if (element.email_address === emailId) {
    //                 noOfUser += 1;
    //                 Swal.fire({
    //                     text: `${pascalCase(emailId.split("@")[0])} user already exists in the system`,
    //                     type: null,
    //                     allowOutsideClick: false,
    //                     showConfirmButton: true,
    //                     confirmButtonColor: '#0066cc'
    //                 });
    //                 return true;
    //             }
    //             for (let i = 0; i < inputList.length; i++) {
    //                 if (element.email_address === inputList[i].email_address) {
    //                     noOfUser += 1;
    //                     Swal.fire({
    //                         text: `${pascalCase(inputList[i].email_address.split("@")[0])} user already exists in the system`,
    //                         type: null,
    //                         allowOutsideClick: false,
    //                         showConfirmButton: true,
    //                         confirmButtonColor: '#0066cc'
    //                     });
    //                     return true;
    //                 }
    //             }
    //         }
    //     });
    //     if (noOfUser === 0) {
    //         setOpen(false);
    //         return false;
    //     }
    // }

    /* not using this tempororily */
    // Function to handle duplicate emails entries 
    // const checkForDuplicateEmailEntries = (inputList, emailId) => {
    //     let count = 0
    //     for (var i = 0; i < inputList.length; i++) {
    //         for (var j = 0; j < inputList.length; j++) {
    //             if (i !== j) {
    //                 if ((inputList[i].email_address === inputList[j].email_address)) {
    //                     inputList[j].email_address = ""
    //                     count = count + 1;
    //                     Swal.fire({
    //                         text: 'Found duplicate email entry',
    //                         type: null,
    //                         allowOutsideClick: false,
    //                         showConfirmButton: true,
    //                         confirmButtonColor: '#0066cc'
    //                     });
    //                     return true; // means there are duplicate values
    //                 }
    //             }
    //             if (inputList[i].email_address === emailId) {
    //                 inputList[i].email_address = ""
    //                 count = count + 1;
    //                 Swal.fire({
    //                     text: 'Found duplicate email entry',
    //                     type: null,
    //                     allowOutsideClick: false,
    //                     showConfirmButton: true,
    //                     confirmButtonColor: '#0066cc'
    //                 });
    //                 return true; // means there are duplicate values
    //             }
    //         }
    //     }
    //     if (count > 0) {
    //         Swal.fire({
    //             text: 'Found duplicate email entry',
    //             type: null,
    //             allowOutsideClick: false,
    //             showConfirmButton: true,
    //             confirmButtonColor: '#0066cc'
    //         });
    //         return true
    //     }
    //     return false; // means there are no duplicate values.
    // }

    // Function to check whether the selected file data is already exists in Data Base or not
    const checkForDuplicateFiles = (fileName) => {
        let noOfDuplicateFiles = 0;
        rowData.forEach(element => {
            if (element.file_name) {
                if (element.file_name === fileName) {
                    noOfDuplicateFiles += 1;
                    setOpenDropZone(false);
                    Swal.fire({
                        text: 'Found duplicate file',
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    return true;
                }
            }
        });
        if (noOfDuplicateFiles === 0) {
            return false;
        }
    };

    // Function to upload selected file to s3 bucket
    const handleSave = async (files) => {
        let response = checkForDuplicateFiles(files[0].name);
        if (response === false) {
            uploadFile(files[0], config)
                .then((data) => {
                    setLoadingProspectiveCients(false);
                    addPospectiveClientsInBulk(files[0].name);
                })
                .catch((err) => { }
                );
            //Saving files to state for further use and closing Modal.
            setOpenDropZone(false);
        }
    };

    // Function to send selected file name to api end point
    const addPospectiveClientsInBulk = async (fileName) => {
        try {
            let clientBulkData = await addProspectiveClientInBulkApiCall(fileName);
            if (clientBulkData) {
                setLoadingProspectiveCients(true);
                if (clientBulkData.data === 'File successfully uploaded !!') {
                    setSnackBarMessageText(clientBulkData.data);
                    setColorseverity('success');
                    setOpenSnackBar(true);
                }
                else if (clientBulkData.data !== 'File successfully uploaded !!') {
                    Swal.fire({
                        text: clientBulkData.data,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    deleteFile(fileName);
                }
                getPospectiveClients();
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to delete file from s3 bucket
    const deleteFile = async (fileName) => {
        await s3.deleteObject({
            Key: fileName,
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME
        }).promise().then(() => {
        });
    };

    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <div className="main" style={{ padding: '40px 30px 20px 0px', width: '102%' }} hidden={!open}>
                    <Paper elevation={3}  >
                        <Grid container spacing={3} style={{ marginRight: 60 }}>
                            <Grid item xs={4} style={{ fontSize: 20 }} >
                                <b style={{ paddingLeft: 50 }}>User Details</b>
                            </Grid>
                            <Grid item xs={3}  >
                            </Grid>
                            <Grid container xs={5}  >
                                <IconButton onClick={closeSecondaryDetailPane} style={{ marginLeft: '88%' }}>
                                    <Tooltip title="Close">
                                        <CloseIcon />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <div style={{ height: 15 }}></div>
                        <Card margin={(5, 1)} height={6} style={{ maxWidth: '80%', margin: '0 auto', paddingTop: '20px' }} >
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid container spacing={3}>
                                        <Grid item xs style={{ paddingLeft: '5%' }}>
                                            <TextField id="first" label="First Name" placeholder="Enter first name" variant="standard" style={{ width: '80%' }} onChange={handleFirstNameChange} value={firstName} />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField id="last" label="Last Name" placeholder="Enter last name" variant="standard" style={{ width: '80%' }} onChange={handleLastNameChange} value={lastName} />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField id="email" type='email' label="Email Address" placeholder="Enter email" variant="standard" style={{ width: '80%' }} error={errors.emailId} onChange={handleEmailIdChange} required />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div style={{ height: 40 }}></div>
                                {inputList.map((x, i) => {
                                    return (
                                        <div className="box" key={i}>
                                            <Grid container spacing={3} hidden={!openSecondaryTextFields} >
                                                <Grid item xs style={{ paddingLeft: '5%' }} >
                                                    <TextField id="first" name="first_name" label="First Name" placeholder="Enter first name" variant="standard" style={{ width: '80%' }} onChange={e => handleInputChange(e, i)} value={x.first_name} />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField id="last" name="last_name" label="Last Name" placeholder="Enter last name" variant="standard" style={{ width: '80%' }} onChange={e => handleInputChange(e, i)} value={x.last_name} />
                                                </Grid>
                                                <Grid item xs>
                                                    <TextField id="email" name="email_address" type='email' label="Email Address" placeholder="Enter email" variant="standard" style={{ width: '80%' }} error={emailErrorCount > 0 && x.email_address === ''} onChange={e => handleInputChange(e, i)} value={x.email_address} required />
                                                </Grid>
                                            </Grid>
                                            <div style={{ height: 15 }}></div>
                                            <Grid container xs={12} justify="flex-end" >
                                                {inputList.length > 0 && <IconButton hidden={!openSecondaryTextFields} onClick={() => handleRemoveClick(i)}>
                                                    <Tooltip title="Close">
                                                        <CloseIcon />
                                                    </Tooltip>
                                                </IconButton>}
                                            </Grid>
                                        </div>
                                    );
                                })}
                                <Grid container spacing={3}>
                                    <Grid item xs style={{ paddingLeft: '5%' }} >
                                        <AddCircleOutlinedIcon onClick={handleAddClick} />
                                        <Typography component={'div'} variant='caption' style={{ fontSize: '11px' }}>Please add one or more user</Typography>
                                    </Grid>
                                    <Grid item xs>
                                    </Grid>
                                    <Grid item xs>
                                    </Grid>
                                </Grid>
                                <div style={{ height: 15 }}></div>
                                <Grid container xs={12} justify="flex-end" paddingTop={35} style={{ paddingTop: 5 }}>
                                    <Tooltip >
                                        <Button type='submit' onClick={handleAddButtonClick} variant="contained" style={{ textTransform: 'none' }}>Add</Button>
                                    </Tooltip>
                                </Grid>
                            </CardContent>
                        </Card>
                        <div style={{ height: 15 }}></div>
                    </Paper>
                </div>
                <div style={{ height: 15 }}></div>
                <div style={{ paddingLeft: '40%' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component={'div'} variant='caption' style={{ fontSize: '13px' }}> Email Functionality</Typography>
                            {/* <Tooltip title={toggleStatus === "Deactivated" ? `Activate users` : `Deactivate users`}> */}
                            <Switch
                                label="abc"
                                checked={toggleStatus === 'Deactivated' ? false : true}
                                color="primary"
                                onClick={(event) => {
                                    if (toggleStatus === 'Deactivated') {
                                        activateMainToggleFunctionality();
                                    }
                                    else if (toggleStatus === 'Activated') {
                                        deactivateMainToggleEmailFunctionality();
                                    }
                                }}
                            />
                            {/* <Typography variant='caption' style={{ fontSize: "13px" }}> Email Functionality</Typography> */}
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={() => handleOpenForm()} type='submit' variant='contained' style={{ width: '95%', textTransform: 'none' }} >
                                Add New User
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={handleOpen} type='submit' variant='contained' style={{ width: '75%', textTransform: 'none' }}>
                                Bulk User Upload
                            </Button>
                            <DropzoneDialog
                                open={openDropZone}
                                onSave={handleSave}
                                acceptedFiles={['.xlsx']}
                                showPreviews={true}
                                maxFileSize={5000000}
                                onClose={handleClose}
                                filesLimit={1}
                            />
                        </Grid>
                    </Grid>
                </div >
                <div style={{ height: 25 }}></div>
                <Typography component={'div'} variant='caption' style={{ fontSize: '14px' }}>Please download the template for Bulk User Upload here </Typography>
                <a href="https://loadeo-prospective-client-template.s3.amazonaws.com/template.xlsx" rel="noopener nor" download>
                    download the template
                </a>
                <Typography component={'div'} variant='caption' style={{ fontSize: '14px', fontStyle: 'italic' }}> <br />Note: Please rename the file before you upload.</Typography>
                <div style={{ height: 20 }}></div>
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: '120%' }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'Prospective Clients'}
                                    data={rowData}
                                    columns={columnDefs}
                                    options={options}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                    </Grid>
                </Typography>
                <div style={{ height: 80 }}></div>
            </main >
        </div >
    );
};

export default ProspectiveClients;
