import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

export const createLoadNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.createloadnotification,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log('post load email error', error);
    }
};

//function to send Email for Bid Placed
export const createBidNotificationApiCall = async (listValue) => {
    try {
        await axios({
            url: environmentVariables.createbidemail,
            method: 'post',
            data: listValue,
        });
    }
    catch (error) {
        console.log('send email for Bid Placed Failed', error);
    }
};

export const acceptBidNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.acceptbidnotification,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const counterOfferNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.counteremail,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const acceptCounterOfferNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.acceptcounteroffernotification,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const confirmLoadNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.createconfirmbidnotification,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const assignLoadNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.assignloadnotification,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const reopenLoadNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.createreopenloadnotification,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};

export const sendFeedBackLinkForBroker = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.feedbackemailtobroker,
            method: 'post',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};