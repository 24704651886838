// react related imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

// Auth0 related imports
import { useAuth0 } from '@auth0/auth0-react';

// MUI components related imports
import {
    Grid,
    CssBaseline,
    Card,
    CardContent,
    Box,
} from '@material-ui/core';

// MUI styles related imports
import { makeStyles } from '@material-ui/core/styles';

// bootstrap components related imports
import { Row, Col } from 'react-bootstrap';

// websocket related imports
import addNotification from 'react-push-notification';
import useWebSocket, { } from 'react-use-websocket';
import moment from 'moment';

//imports for SVG Icons
import { AwardedLoadAdminIcon } from '../../assets/SvgIcons/AwardedLoadAdmin';
import { CompanyManagementAdminIcon } from '../../assets/SvgIcons/CompanyManagementAdmin';
import { ExpiredBidsAdminIcon } from '../../assets/SvgIcons/ExpiredBidsAdminIcon';
import { ExpiredLoadsAdminIcon } from '../../assets/SvgIcons/ExpiredLoadsAdmin';
import { LoadsWonAdminIcon } from '../../assets/SvgIcons/LoadsWonAdmin';
import { ProspectiveClientAdminIcon } from '../../assets/SvgIcons/prospectiveClientAdmin';
import { LoadboardAdminIcon } from '../../assets/SvgIcons/LoadBoardAdmin';
import { UserManagementAdminIcon } from '../../assets/SvgIcons/UserManagementAdmin';
import { SettingsAdminIcon } from '../../assets/SvgIcons/SeetingsAdmin';
import { MCVerifyAdminIcon } from '../../assets/SvgIcons/MCVerifyAdminIcon';

//import CSS, Drawer, Envirnoment
import './index.css';
import MUIDrawer from '../../components/Drawer';

// imports for API functions
import { getAllMCCertificateApiCall } from '../../apis/certification';
import { getprospectiveclientsApiCall } from '../../apis/prospectiveclient';
import { getAllUserInformationApiCall } from '../../apis/user';
import { getAllCompaniesApiCall } from '../../apis/companies';
import { fetchLoadApiCall, getAwardedLoadsApiCall, getExpiredBidsApiCall, getExpiredLoadsApiCall } from '../../apis/loads';
import { getMatchedLoadApiCall } from '../../apis/searchedLane';
import { environmentVariables } from '../../constants/environment';
import { COLORS } from '../../theme/colors';
import { SavedLaneAdminIcon } from '../../assets/SvgIcons/SavedLaneAdmin';
import { ActiveBidsIcon } from '../../assets/SvgIcons/ActiveBidsIcon';
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';
import { getApprovedUserDetailsForCompanyApiCall } from '../../apis/companies';
/*************************************************************************************** */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
}));

const Admin = () => {
    const classes = useStyles();
    const { user } = useAuth0();
    const history = useHistory();

    // Constant for User Count
    const [userCount, setUserCount] = useState('');

    //Constant for prospective Client count
    const [prospectiveClientCount, setProspectiveClientCount] = useState('');

    //Constant for prospective Client count
    const [allLoadsCount, setAllLoadsCount] = useState('');

    //Constant for prospective Client count
    const [awardedLoadsCount, setAwardedLoadsCount] = useState('');

    //Constant for prospective Client count
    const [expiredLoadsCount, setExpiredLoadsCount] = useState('');

    //Constant to handle Expired Bids Count
    const [expiredBidsCount, setExpiredBidsCount] = useState('');

    //Constant for prospective Client count
    const [loadsWonCount, setLoadsWonCount] = useState('');

    //Constant for prospective Client count
    const [companiesCount, setCompaniesCount] = useState('');

    //Constant for MC certificate count
    const [certificateCount, setCertificateCount] = useState('');

    //Constant for prospective Client count
    const [activeLaneCount, setActiveLaneCount] = useState('');

    //constant for saved lanes count
    const [savedLanesCount, setSavedLanesCount] = useState(0);

    const [allActiveBidsCount, setAllActiveBidsCount] = useState(0);

    const [companyUserCount, setCompanyUserCount] = useState(0);

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });
    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');

    const dispatch = useDispatch();

    /***************************************To handle function******************************************************* */
    //function to check user Role
    const checkUserRole = async () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.user_role) {
                setLoggedInuserRole(userReduxData.userData.user_role);
            }
            if (userReduxData.userData.company_role) {
                setLoggedInuserCompanyRole(userReduxData.userData.company_role);
            }
        }
        if (user.email !== process.env.REACT_APP_ADMIN_USER && userReduxData.userData.user_role !== 'CompanyAdmin') {
            history.push({ pathname: '/forbidden' });
        }
    };

    // Function to get all prospective clients
    const getPospectiveClients = async () => {
        try {
            let prospectiveclientsdata = await getprospectiveclientsApiCall();
            if (prospectiveclientsdata !== undefined) {
                if (prospectiveclientsdata.data !== 'UserNotPresent') {
                    setProspectiveClientCount(prospectiveclientsdata.data.length);
                }
            }
        }
        catch (error) {
            console.log(error, 'Get prospective client in admin page error');
        }
    };

    //Function to Fetch All active load
    const fetchAllActiveLoads = async () => {
        try {
            let allloadsdata = await fetchLoadApiCall(user.email);
            if (allloadsdata !== undefined) {
                let arrayToRemove = allloadsdata.filter(function (singleVal) {
                    return singleVal['load_delete_status'] !== true && singleVal['load_expired_status'] !== true && singleVal['status'] !== 'Closed' && singleVal['status'] !== 'Awarded';
                });
                setAllLoadsCount(arrayToRemove.length);
            }
        }
        catch (error) {
            console.log(error, 'Fetch all loads count for admin error');
        }
    };

    //Function to fetch Bids won and Loads Awarded Count
    const BidsWonLoadsAwarded = async () => {
        try {
            let awardedloadsdata = await getAwardedLoadsApiCall(user.email);
            let awardedLoadList;
            if (awardedloadsdata !== undefined) {
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    // Setting Up awarded loads table
                    if (loggedInuserRole === 'CompanyAdmin') {
                        awardedLoadList = awardedloadsdata.filter(function (singleVal) {
                            return singleVal['carrier_company'] === userReduxData.userData.company_name;

                        });
                    }
                    //setAwardedLoadsCount(awardedLoadList.length);
                    setLoadsWonCount(awardedLoadList.length);
                }
                else {
                    setAwardedLoadsCount(awardedloadsdata.length);
                    setLoadsWonCount(awardedloadsdata.length);
                }
                //setAwardedLoadsCount(awardedloadsdata.length);
                //setLoadsWonCount(awardedloadsdata.length);
            }
        } catch (error) {
            console.log('Fetch awarded load count error in admin page', error);
        }
    };

    //Function to fetch Expired Loads Count
    const fetchExpiredLoads = async () => {
        try {
            let expiredloadsdata = await getExpiredLoadsApiCall(user.email);
            if (expiredloadsdata !== undefined) {
                setExpiredLoadsCount(expiredloadsdata.length);
            }
        }
        catch (error) {
            console.log('Fetch expired load count error in admin page', error);
        }
    };

    //Function to fetch Expired Bids Count
    const fetchExpiredBidsLoads = async () => {
        try {
            let bidHistoryList;
            let expiredbidloadsdata = await getExpiredBidsApiCall(user.email);
            if (expiredbidloadsdata !== undefined) {
                if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    bidHistoryList = expiredbidloadsdata.filter(singleVal => {
                        return (singleVal['carrier_name'] !== ''
                            && singleVal['status'] === 'Closed');
                    });
                    setExpiredBidsCount(bidHistoryList.length);
                }
                else {
                    if (loggedInuserRole === 'CompanyAdmin') {
                        bidHistoryList = expiredbidloadsdata.filter(singleVal => {
                            return (singleVal['carrier_company'] === userReduxData.userData.company_name
                                && singleVal['status'] === 'Closed');
                        });
                    }
                    setExpiredBidsCount(bidHistoryList.length);
                }
                //setExpiredBidsCount(bidHistoryList.length);
            }
        } catch (error) {
            console.log('Fetch expired bids load count error in admin dashboard', error);
        }
    };

    // function to get all user information Count
    const getAllUserInformation = async () => {
        try {
            let alluserinformationdata = await getAllUserInformationApiCall();
            if (alluserinformationdata !== undefined) {
                setUserCount(alluserinformationdata.length);
            }
        }
        catch (error) {
            console.log(error, 'All user info count error in admin dashboard');
        }
    };

    // function to get all user information Count
    const getAllUserInformationForCompanyAdmin = async () => {
        try {
            let approvedCompanyUserDetails = await getApprovedUserDetailsForCompanyApiCall(userReduxData.userData.company_mc_number);
            if (approvedCompanyUserDetails !== undefined) {
                setCompanyUserCount(approvedCompanyUserDetails.length);
            }
        }
        catch (error) {
            console.log(error, 'All user info count error in admin dashboard');
        }
    };

    // function to get all companies Count
    const getAllCompanies = async () => {
        try {
            let allcompaniesdata = await getAllCompaniesApiCall();
            if (allcompaniesdata !== undefined) {
                setCompaniesCount(allcompaniesdata.length);
            }
        }
        catch (error) {
            console.log(error, 'Get all company info count error in admin dashboard');
        }
    };

    // function to get all certificate Count
    const getAllCertificates = async () => {
        try {
            let allcertificatesdata = await getAllMCCertificateApiCall();
            if (allcertificatesdata !== undefined) {
                setCertificateCount(allcertificatesdata.length);
            }
        }
        catch (error) {
            console.log(error, 'Get all certificate count error in admin dashboard');
        }
    };

    //Function to Fetch All active load
    const fetchAllActiveLanes = async () => {
        try {
            let savedlanesdata = await getMatchedLoadApiCall(userReduxData.userData.company_mc_number, moment(new Date()).format('MM/DD/YYYY'));
            if (savedlanesdata !== undefined) {
                setSavedLanesCount(savedlanesdata.length);
            }
        }
        catch (error) {
            console.log(error, 'get lane count');
        }
    };


    const fetchAllActiveBids = async () => {
        try {
            let allactivebidsdata = await fetchLoadApiCall(user.email);
            let favoritesList;
            if (allactivebidsdata !== undefined) {
                if (loggedInuserRole === 'CompanyAdmin') {
                    favoritesList = allactivebidsdata.filter(singleVal => {
                        return (singleVal['load_delete_status'] !== true
                            && singleVal['carrier_company'] === userReduxData.userData.company_name
                            && singleVal['status'] !== 'Assigned to Driver'
                            && singleVal['status'] !== 'queued'
                            && singleVal['status'] !== 'assigned'
                            && singleVal['status'] === 'Bid Pending')
                            ||
                            (singleVal['load_delete_status'] !== true
                                && singleVal['status'] !== 'Assigned to Driver'
                                && singleVal['status'] !== 'assigned'
                                && singleVal['status'] !== 'queued'
                                && singleVal['load_chat_initiated'] === user.email);
                    });
                }
                setAllActiveBidsCount(favoritesList.length);
            }
        }
        catch (error) {
            console.log(error, 'Fetch all loads count for admin error');
        }
    };

    //------------------------- Place to define Socket related functions ------------------------------

    // Function to recive all the socket io messages
    const recieveMessageForSocketIo = async (message) => {
        let recievedMessage = message.data.replace(/\'/g, '');

        // Recieveing a message from carrier for new load
        if (recievedMessage.includes('newLoadHasBeenPlaced')) {
            fetchAllActiveLoads();
        }

        if (recievedMessage.includes('YourLoadHasBennAccepted')) {
            BidsWonLoadsAwarded();
        }

        if (recievedMessage.includes('BrokerLoadHasBeenDeleted') || recievedMessage.includes('BrokerLoadHasBeenExpired')) {
            fetchExpiredLoads();
            fetchExpiredBidsLoads();
        }
    };

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });
    
    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    useEffect(() => {
        if ( Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
            fetchAllActiveLanes();
            fetchAllActiveLoads();
            fetchExpiredLoads();
            BidsWonLoadsAwarded();
            fetchExpiredBidsLoads();
            getAllUserInformation();
            getPospectiveClients();
            getAllCompanies();
            getAllCertificates();
            fetchAllActiveBids();
            getAllUserInformationForCompanyAdmin();
    }, [userReduxData]);

    

    /*****************************************************************************************************/
    return (
        <div className={classes.root}>
            <CssBaseline />
            <MUIDrawer />
            <Grid container direction="row-reverse" alignItems="center" justify="center" style={{ minHeight: '80vh' }}>
                <Grid container alignItems="center" justify="center" direction="column">
                    <Box style={{ backgroundColor: COLORS.white }}>
                        <main className={classes.content}>
                            <div style={{ height: 10 }}></div>
                            <Box className="box" boxShadow={3} color={COLORS.black} border={0} padding={2} margin={1}>
                                <Col style={{ paddingLeft: 20 }}></Col>
                                <Row>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent">
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.rockBlue }}>
                                                    <ProspectiveClientAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" style={{ paddingLeft: 0 }}>
                                                    <a className="link" style={{ paddingRight: 20 }} href="/prospectiveclients" rel="prospectiveclients" >
                                                        Prospective Clients
                                                    </a>
                                                    <div className="number"> {prospectiveClientCount}</div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card"  >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.warning }}>
                                                    <LoadboardAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/loadboard" rel="LoadBoard" >
                                                        LoadBoard
                                                    </a>
                                                    <div className="number"> {allLoadsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.red }}>
                                                    <AwardedLoadAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/awardedloads" rel="AwardedLoads" >
                                                        Awarded Loads
                                                    </a>
                                                    <div className="number"> {awardedLoadsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" hidden={user.email === process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.royalBlue, width: 65, height: 65 }}>
                                                    <ActiveBidsIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/allactivebids" rel="ActiveLoads" >
                                                        Active Bids
                                                    </a>
                                                    {(allActiveBidsCount >= 0) && (
                                                        <div className="number"> {allActiveBidsCount} </div>
                                                    )}

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.violet }}>
                                                    <LoadsWonAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/allbidswon" rel="LoadsWon" >
                                                        Bids Won
                                                    </a>
                                                    <div className="number" > {loadsWonCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Row>
                                <div style={{ height: 40 }} />
                                <Row>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.keppel }}>
                                                    <ExpiredLoadsAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/expiredloads" rel="ExpiredLoads" >
                                                        Expired Loads
                                                    </a>
                                                    <div className="number" > {expiredLoadsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.success }}>
                                                    <ExpiredBidsAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/allexpiredbids" rel="ExpireBids" >
                                                        Expired Bids
                                                    </a>
                                                    <div className="number" > {expiredBidsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card"  >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.Tan }}>
                                                    <SavedLaneAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/allsavedlanes" rel="SavedLanes" >
                                                        Saved Lane
                                                    </a>
                                                    <div className="number" > {savedLanesCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent">
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.deepSkyBlue }}>
                                                    <UserManagementAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/systemadmin" rel="NewUsers" >
                                                        User Management
                                                    </a>
                                                    <div className="number" > {userCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" hidden={user.email === process.env.REACT_APP_ADMIN_USER}>
                                        <CardContent className="CardContent">
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.deepSkyBlue }}>
                                                    <UserManagementAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/companyadmin" rel="NewUsers" >
                                                        User Management
                                                    </a>
                                                    {(companyUserCount !== 0) && (
                                                        <div className="number" > {companyUserCount} </div>
                                                    )}

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Row>
                                <div style={{ height: 40 }} />
                                <Row>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.darkGrey }}>
                                                    <SettingsAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/settings" rel="settings" >
                                                        Settings
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.tulip }}>
                                                    <MCVerifyAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="link" href="/mcverify" rel="MCVerify" >
                                                        MC Verify
                                                    </a>
                                                    <div className="number"> {certificateCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="card" hidden={user.email !== process.env.REACT_APP_ADMIN_USER} >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="icons" style={{ backgroundColor: COLORS.royalBlue }}>
                                                    <CompanyManagementAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <a className="link" href="/systemadmincompanies" rel="Active" >
                                                        Company management
                                                    </a>
                                                    <div className="number" > {companiesCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Row>
                            </Box>
                        </main >
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};

export default Admin;
