import React, { useState, useEffect, useRef } from 'react';
import {
    CssBaseline,
    Divider,
    IconButton,
    Typography,
    Grid,
    Paper,
    Button,
    Tooltip,
    Snackbar,
    Box,
    Tabs,
    Tab,
    List,
    ListItem,
    InputBase,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import cities from 'cities';
// import zipcodes from 'zipcodes';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import { toNumber } from 'lodash';
import useWebSocket, { } from 'react-use-websocket';
import Swal from 'sweetalert2';
import slugid from 'slugid';
import { pascalCase, scrollTopOnRowClick } from '../../utils/index';

// import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';
import { AcceptCounterOffer, ExpiredCounterOffer } from '../../components/Button/Button';

// Imports to have all the Icons
import { ExpandMoreIcon } from '../../assets/SvgIcons/ExpandMore';
import { ExpandLessIcon } from '../../assets/SvgIcons/ExpandLess';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

// Import statements for CSS and environment
import { environmentVariables } from '../../constants/environment';
import SearchForm from '../SearchForm';
import Navbar from '../../components/Navbar';
import MUIDrawer from '../../components/Drawer';
import sortDate from '../../components/Sorting/sortDate';
import sortNum from '../../components/Sorting/sortNum';
import PostLoadForm from '../PostLoadForm';
import './index.css';
import { updateCount, sendEmailForMatchedLane } from '../SavedSearchLane/updatecount';

// Import statements for Chat Component
import Header from '../../components/CommentsComponent/Header/Header';
import HeaderChat from '../../components/CommentsComponent/HeaderChat/HeaderChat';
import Chat from '../../components/CommentsComponent/Chat/Chat';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';
import { getBidApiCall } from '../../apis/bids';
import { getCommentApiCall, updateCommentApiCall } from '../../apis/chat';
import { getExpiredLoadsApiCall } from '../../apis/loads';
import { getMatchedLoadApiCall } from '../../apis/searchedLane';
import { createLoadNotificationApiCall, reopenLoadNotificationApiCall } from '../../apis/emailNotifications';

// import pin and Unpin icons
import { Pinned } from '../../assets/SvgIcons/Pinned';
import { UnPinned } from '../../assets/SvgIcons/UnPinned';

// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';
import LoadDetailsPannel from '../../components/LoadDetailsPannel';
import { handleSendMessageForSocketIO, handleSocketReceiveMessage } from '../WebSocketConnection';

//--------------------------------------------*********************-----------------------------------------

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];

//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: 'auto'
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    }
}));

// Function to handle MuiAlert
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const ExpiredLoads = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const dispatch = useDispatch();

    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    /// Constant to handle expand and colapse the top pannel
    const [expandColapseTopPannel, setExpandColapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    //Constant to  handle load id on load reopen
    const [loadId, setLoadId] = useState('');

    // Constant to handle errors
    const [isPaneOpen, setIsPaneOpen] = useState(false);

    // Constant to handle logged in user details
    const [userCompanyName, setUserCompanyName] = useState('');
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState('')
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    // Constants for search component
    const [loadNumberLength, setLoadNumberLength] = useState(0);
    const [label, setLabel] = useState('Search Load');
    const [bidAmount, setBidAmount] = useState('');
    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState('');
    const [searchPickUpFromState, setSearchPickUpFromState] = useState('');
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState('');
    const [searchPickUpMiles, setSearchPickUpMiles] = useState('');

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState('');
    const [searchDeliverToState, setSearchDeliverToState] = useState('');
    const [searchDeliverToZip, setSearchDeliverToZip] = useState('');
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState('');

    // constants to handle mui data table loader
    const [loading, setLoading] = useState(false);

    //constant to handle mui bid table loader
    const [loadingBids, setLoadingBids] = useState(false);

    const [openPostLoadForm, setOpenPostLoadForm] = useState(false);

    //constant to handle Disable Button
    const [disableSendButton, setDisableSendButton] = useState(false);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState('');
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState('');
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState('');
    const [chatFirstCompanyName, setChatFirstCompanyName] = useState('');
    const [chatAllMessages, setChatAllMessages] = useState([]);

    // Constants to handle Expired Loads
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);
    const [completeSelectedRowData, setCompleteSelectedRowData] = useState([]);
    const [rowDataForExpiredLoads, setRowDataForExpiredLoads] = useState([]);
    const PostLoadFormRef = useRef();
    const SearchFormRef = useRef();
    const [postLoadFormFunctionality, setPostLoadFormFunctionality] = useState('');

    // constant to handle drawer notification badge
    const [isNotificationSent, setIsNotificationSent] = useState(false);

    // Constant to handle Pin and Unpin
    const [pinned, setPinned] = useState(false);

    // this determines whether the button is disabled or not
    const [isReuseDisabled, setIsReuseDisabled] = useState(false);
    const [isReopenDisabled, setIsReopenDisabled] = useState(false);

    const optionsForExpiredLoads = {
        filter: false,
        search: false,
        responsive: 'vertical',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    `There are no expired loads for ${pascalCase(user.nickname)}`
            },
        },
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex);
                }
            }

        }
    };

    const columnDefs = [
        { label: 'PostId', name: 'load_id', options: { display: false } },
        {
            label: ' ',
            name: 'load_delete_status',
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined) {
                        if (value === true) {
                            return (
                                <div>
                                    <CancelIcon className='deletedLoad' />
                                </div>
                            );
                        }
                    }
                }
            }
        },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pickupfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                display: true,
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Miles (Time)',
            name: 'cities_distance',
            options: {
                sort: true,
                display: true, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== '') {
                                if (valEnteredNumber.includes(',')) {
                                    let splittingValue = valEnteredNumber.split(',');
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                display: false,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                display: false,
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== 'NaN' && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Posting Company', name: 'posted_by_company', options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[10] !== null && rowuserdata.rowData[10] !== undefined) {
                        return (
                            rowuserdata.rowData[10].toUpperCase()
                        );
                    }
                }
            }
        },
        {
            label: 'Load ID',
            name: 'load_number',
            options: {
                customBodyRender: (value) => {
                    return value;
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
        {
            label: 'Status', name: 'status', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[12] === 'Closed') {
                        return (
                            <div className='red' style={{ fontWeight: 'bold' }}>{rowuserdata.rowData[12].toUpperCase()}</div>
                        );
                    }
                    else {
                        return (
                            <div style={{ fontWeight: 'bold' }}>{rowuserdata.rowData[12].toUpperCase()}</div>
                        );
                    }
                }
            }
        },
    ];

    const [rowDataBidTable, setRowDataBidTable] = useState([]);

    const columnDefsBidTable = [
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier Company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {rowIndex.rowData[12] === 'newoffer' && rowIndex.rowData[13] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[12] !== 'newoffer' && rowIndex.rowData[13] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[14]} </p>}
                        {rowIndex.rowData[12] !== 'newoffer' && rowIndex.rowData[13] === 'Accept' && <p className='afterBidDisplay'>$ {rowIndex.rowData[14]}</p>}
                    </>
                    );
                }
            },
        },
        {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[5] = 'Closed'
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_Notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[8] === '' || rowuserdata.rowData[8] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[8] !== '' || rowuserdata.rowData[8] !== undefined) && rowuserdata.rowData[8]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === '' || rowuserdata.rowData[9] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== '' || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[10] === 'LXWXH' && <p className='afterBidDisplay'> NA
                        </p>}
                        {rowuserdata.rowData[10] !== 'LXWXH' && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        { label: 'bid_expiration_time', name: 'bid_expiration_time', options: { display: false } },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {rowIndex.rowData[12] !== 'newoffer' && rowIndex.rowData[13] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[14]} </p>}
                        {(rowIndex.rowData[14] === '' || rowIndex.rowData[14] === undefined) && <p className='afterBidDisplay'> NA </p>}
                        {rowIndex.rowData[12] !== 'newoffer' && rowIndex.rowData[13] === 'Accept' && rowIndex.rowData[5] === 'accepted' && <AcceptCounterOffer counteroffer={rowIndex.rowData[14]} />}
                        {rowIndex.rowData[12] !== 'newoffer' && rowIndex.rowData[13] === 'Accept' && rowIndex.rowData[5] !== 'accepted' && <ExpiredCounterOffer counteroffer={rowIndex.rowData[14]} />}
                    </>
                    );
                }
            }
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[15];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[11];
                    let counterofferstatus = rowuserdata.rowData[10];
                    return (<>
                        {counteroffer === '' && counterofferstatus === 'New' && <p className='afterBidDisplay'> NA
                        </p>}
                        {counteroffer !== '' && counterofferstatus === 'New' && <button style={{ width: 'max-content' }} > ${counteroffer}
                        </button>}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && <button style={{ width: 'max-content' }} className='counterofferaccepted-btn' > $ {counteroffer}
                        </button>}
                    </>
                    );
                }
            },
        },
    ];

    const optionsBidTable = {
        filter: false,
        responsive: 'vertical',
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    const [columnDefsExpiredLoadTable, setColumnDefsExpiredLoadTable] = useState(columnDefs);

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // constants to handle disabling buttons after first click
    const [disablePostLoadButton, setDisablePostLoadButton] = useState(false);

    //--------------------------------------------*********************--------------------------//
    const showChatAccordingToUser = (loadInformation, data) => {
        setChatAllMessages(data);
        if (loadInformation.posted_by_user === user.email) {
            if (loadInformation.function !== 'sendmessage') {
                setChatFirstCarrierName(data.carrier_id);
                setChatFirstCompanyName(data.carrier_company);
                setChatCarrierMCNumber(`MC #${data.carrier_mc_number}`);
            }
            else {
                setChatFirstCarrierName(loadInformation.carrier_name);
                setChatFirstCompanyName(loadInformation.carrier_company);
                setChatCarrierMCNumber(`MC #${loadInformation.carrier_mc_number}`);
            }
        }
    };

    const fetchAllComments = async (loadInformation) => {
        if (loadInformation.posted_by_user === undefined) {
            if (loadInformation.broker_id !== undefined) {
                loadInformation.posted_by_user = '&broker_id=' + loadInformation.broker_id;
            }
            else {
                loadInformation.posted_by_user = '&broker_id=' + loadInformation.broker_name;
            }
        }
        let allChatResponse;
        try {
            const getCommentResponse = await getCommentApiCall(loadInformation.load_id, user.email);
            if (getCommentResponse) {
                if (getCommentResponse !== undefined) {
                    if (isPaneOpen === true) {
                        if (loadInformation.posted_by_user === user.email) {
                            getCommentResponse.forEach(data => {
                                if (data.carrier_id === chatFirstCarrierName) {
                                    showChatAccordingToUser(loadInformation, data);
                                }
                            });
                        }
                        else {
                            showChatAccordingToUser(loadInformation, getCommentResponse[0]);
                        }
                    }
                    else {
                        showChatAccordingToUser(loadInformation, getCommentResponse[0]);
                    }
                    setAllChat(getCommentResponse);
                    allChatResponse = getCommentResponse;
                }
            }
        }
        catch (error) {
            if (loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier' && ((loggedInuserRole !== 'Carrier' && loadInformation.posted_by_user === user.email) || (loggedInuserRole === 'Broker')))) {
                setChatAllMessages([]);
                setChatFirstCarrierName('');
            }
        }
        return allChatResponse;
    };

    const pinChat = async (item) => {
        if (item.pinned_user === true) {
            item.pinned_user = false;
            setPinned(false);
            sendMessageForSocketIO('BrokerhaveUnPinnedYou||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, item.carrier_id, false, selectedRowDataValue, item);
        }
        else {
            item.pinned_user = true;
            setPinned(true)
            sendMessageForSocketIO('BrokerhavePinnedYou||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, item.carrier_id, false, selectedRowDataValue, item);
        }
    }

    //function to assign values on row click in userdetails table
    const handleRowClick = (selectedRowData, rowMeta) => {
        scrollTopOnRowClick(expandColapseTopPannel)
        setChatCarrierMCNumber('');
        setMainGridPercent('35%');
        setSelectedRow(selectedRowData);
        setIsPaneOpen(true);
        loadAndRealoadSecondGrid(selectedRowData[0]);
        fetchMainMuiDatatableValues();

        const findLoad = rowDataForExpiredLoads.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        selectedRowDataValueForSocketIO = findLoad;
        removeBackroundColour();
        // if (!window.localStorage.getItem('LoadId')) {
        //     let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
        //     row.setAttribute('style', 'background: #9FD2F3');
        // }
        fetchAllComments(findLoad);
        setSelectedRowDataValue(findLoad);
    };
    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    //---------------------------- Place to handle all the functions ----------------------------

    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowDataForExpiredLoads.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute('style');
                if (styleObject !== null) {
                    if (styleObject.toString().includes('9FD2F3')) {
                        domObject.removeAttribute('style', 'background: #9FD2F3');
                    }
                }
            }
        }
    };

    // Function to check the user role
    const checkUserRole = async () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === 'ProfileAccepted') {
                if (userReduxData.userData.company_role === 'Carrier' || userReduxData.userData.user_role === 'Carrier' || userReduxData.userData.user_role === 'Driver') {
                    history.push({ pathname: '/forbidden' });
                }
                if (userReduxData.userData.company_name) {
                    setUserCompanyName(userReduxData.userData.company_name);
                }
                if (userReduxData.userData.company_role) {
                    setLoggedInuserCompanyRole(userReduxData.userData.company_role)
                }
                if (userReduxData.userData.user_role) {
                    setLoggedInuserRole(userReduxData.userData.user_role)
                }
                if (userReduxData.userData.company_mc_number) {
                    setLoggedInCompanyMCNumber(userReduxData.userData.company_mc_number)
                }
            }
            else {
                history.push({ pathname: '/forbidden' });
            }
        }
    };

    // Function to fetch all the data main MuiDatatble
    const fetchMainMuiDatatableValues = async () => {
        try {
            let response = await getExpiredLoadsApiCall(user.email);
            if (response) {
                if (response.length !== 0) {
                    let loadIds = [];
                    setLoading(true);
                    response.forEach(element => {
                        if (element.load_number) {
                            loadIds.push(element.load_number);
                        }
                    });
                    setLoadNumberLength(Math.max(...loadIds).toString().length);
                }
                else {
                    setLoadNumberLength(8);
                    setLoading(true);
                }

                //To delete loads 
                // Local array to handelremoval of rows
                response.sort((a, b) =>
                    b.time_stamp.localeCompare(a.time_stamp)
                );
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    // Setting Up expired loads table
                    const expiredList = response.filter(function (singleVal) {
                        return singleVal['posted_by_user'] === user.email;
                    });
                    setRowDataForExpiredLoads(expiredList);
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    setRowDataForExpiredLoads(response);
                }
                removeBackroundColour();
            }
        }
        catch (error) { }
    };

    // Function to load and reload bid details
    const loadAndRealoadSecondGrid = async (loadId) => {
        try {
            const getBidResponse = await getBidApiCall(loadId, "loadspecific");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                getBidResponse.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                let findBids = [];
                if (getBidResponse.length !== 0) {
                    setLoadingBids(true);
                }
                else {
                    setLoadingBids(false);
                }
                getBidResponse.forEach(element => {
                    findBids.push(element);
                    setBidAmount(element.bid_amount);
                });
                if (findBids.length === 0) {
                    setLoadingBids(true);
                }
                setRowDataBidTable(findBids);
            }
            else {
                setRowDataBidTable([]);
                setLoadingBids(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    /******************************************Search****************************************** */
    const handleExpandMoreIconClick = () => {
        setTopTabPannelValue(0);
        setExpandColapseTopPannel(false);
        localStorage.setItem('panelValue', 'false');
        closeSecondaryPane();
    };

    const handleExpandLessIconClick = () => {
        if (label === 'Reuse Load' || label === 'Reopen Load') {
            setLabel('Search Load');
            setExpandColapseTopPannel(!expandColapseTopPannel);
            setOpenPostLoadForm(false);
            setIsReopenDisabled(false);
            setIsReuseDisabled(false);
            setShowing(false);
        }
        else {
            closeSecondaryPane();
        }
        setExpandColapseTopPannel(true);
        localStorage.setItem('panelValue', 'true');
    };
    const searchButtonClicked = () => {
        if (label !== 'Reuse Load' && label !== 'Reopen Load') {
            setExpandColapseTopPannel(!expandColapseTopPannel);
            setTopTabPannelValue(0);
            closeSecondaryPane();
            setShowing(!showing);
        }
    };

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === '') {
            const valForFilter = place.target.value;
            const filterColumns = [...columnDefsExpiredLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === '') {
                filterColumns[11].options.filterList = [];
            }
            else {
                filterColumns[11].options.filterList = filterList;
            }
            setColumnDefsExpiredLoadTable(filterColumns);
        }
    };

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsExpiredLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[2].options.filterList = filterList;
            setColumnDefsExpiredLoadTable(filterColumns);
        }
    };

    // function to get all nearby pickup cities within specified miles
    const getNearestPickUpCities = async (event) => {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            setSearchPickUpMiles(event.target.value);
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=pickupcitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (element.load_Expired_Status === true && !element.load_Delete_Status) {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest);
                            }
                            else {
                                filterList.push(element.pickup_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsExpiredLoadTable];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsExpiredLoadTable(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsExpiredLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsExpiredLoadTable(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const filterColumns = [...columnDefsExpiredLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[2].options.filterList = filterList;
                setColumnDefsExpiredLoadTable(filterColumns);
            }
        }
    };

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const filterColumns = [...columnDefsExpiredLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[3].options.filterList = filterList;
            setColumnDefsExpiredLoadTable(filterColumns);
        }
        else {
            const filterColumns = [...columnDefsExpiredLoadTable];
            filterColumns[3].options.filterList = [];
            setColumnDefsExpiredLoadTable(filterColumns);
        }

    };

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsExpiredLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[4].options.filterList = filterList;
            setColumnDefsExpiredLoadTable(filterColumns);
        }
    };

    // function to get all nearby deliver cities within specified miles
    const getNearestCitiesForDeliverCity = async (event) => {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchDeliverToCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            setSearchDeliverMiles(event.target.value);
            // var rad = zipcodes.radius(searchDeliverToZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchDeliverToZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchDeliverToZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=delivercitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchDeliverToCity + ', ' + searchDeliverToState + ' - ' + searchDeliverToZip;
                    response.data.forEach(element => {
                        if (element.load_Expired_Status === true && !element.load_Delete_Status) {
                            if (element.delivery_dest === valForFilter) {
                                filterList.unshift(element.delivery_dest);
                            }
                            else {
                                filterList.push(element.delivery_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsExpiredLoadTable];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsExpiredLoadTable(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsExpiredLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsExpiredLoadTable(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const filterColumns = [...columnDefsExpiredLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[4].options.filterList = filterList;
                setColumnDefsExpiredLoadTable(filterColumns);
            }
        }
    };

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const filterColumns = [...columnDefsExpiredLoadTable];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsExpiredLoadTable(filterColumns);
        }
        else {
            const filterColumns = [...columnDefsExpiredLoadTable];
            filterColumns[6].options.filterList = [];
            setColumnDefsExpiredLoadTable(filterColumns);
        }
    };

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const filterColumns = [...columnDefsExpiredLoadTable];
        if (allTruckType && allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            setColumnDefsExpiredLoadTable(filterColumns);
        }
        else {
            filterColumns[7].options.filterList = [];
            setColumnDefsExpiredLoadTable(filterColumns);
        }
    };

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        const filterColumns = [...columnDefsExpiredLoadTable];
        if (allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            setColumnDefsExpiredLoadTable(filterColumns);
        }
        else {
            filterColumns[7].options.filterList = [];
            setColumnDefsExpiredLoadTable(filterColumns);
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const filterColumns = [...columnDefsExpiredLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    filterColumns[8].options.filterList = [];
                }
                else {
                    filterColumns[8].options.filterList = filterList;
                }
                setColumnDefsExpiredLoadTable(filterColumns);
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const filterColumns = [...columnDefsExpiredLoadTable];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    filterColumns[9].options.filterList = [];
                }
                else {
                    filterColumns[9].options.filterList = filterList;
                }
                setColumnDefsExpiredLoadTable(filterColumns);
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const filterColumns = [...columnDefsExpiredLoadTable];
        switch (index) {
            case 2:
                {
                    filterColumns[2].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(2);
                    break;
                }
            case 3:
                {
                    filterColumns[3].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(3);
                    break;
                }
            case 4:
                {
                    filterColumns[4].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(4);
                    break;
                }
            case 5:
                {
                    filterColumns[5].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(6);
                    break;
                }
            case 6:
                {
                    const filterColumns = [...columnDefsExpiredLoadTable];
                    filterColumns[6].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(6);
                    break;
                }
            case 7:
                {
                    filterColumns[7].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(7);
                    break;
                }
            case 8:
                {
                    filterColumns[8].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(8);
                    break;
                }
            case 9:
                {
                    filterColumns[9].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(9);
                    break;
                }
            case 11:
                {
                    filterColumns[11].options.filterList = [];
                    setColumnDefsExpiredLoadTable(filterColumns);
                    SearchFormRef.current.onSearchClear(14);
                    break;
                }
            default:
                break;

        }
    };

    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    const closeSecondaryPane = () => {
        setLabel('Search Load');
        window.localStorage.removeItem('LoadId');
        fetchMainMuiDatatableValues();
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        setSelectedRow([]);
        setOpenPostLoadForm(false);
        removeBackroundColour();
        isPaneOpenForSocketIO = false;
        setIsReopenDisabled(false);
        setIsReuseDisabled(false);
    };

    // function to handle activate button click
    const handleActivateLoadClick = (rowValues) => {
        Swal.fire({
            text: 'Are you sure you want to reuse a load ?',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok'
        }
        ).then((result) => {
            if (result.value === true) {
                setLabel('Reuse Load');
                setPostLoadFormFunctionality('Reuse Load');
                setExpandColapseTopPannel(false);
                setTopTabPannelValue(1);
                setOpenPostLoadForm(true);
                setShowing(!showing);
                setIsReuseDisabled(true);
                setIsReopenDisabled(false);
                setDisablePostLoadButton(false);
                let findLoad = rowDataForExpiredLoads.find(
                    ({ load_id }) => load_id === rowValues[0]
                );
                setLoadId(findLoad.load_id);
                findLoad.time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                findLoad.load_expiration_time = '00:30';
                if (findLoad.load_id) {
                    delete findLoad['load_id'];
                }
                setCompleteSelectedRowData(findLoad);
            }
        });
    };

    //Function to handle Reopen Load
    const handleReopenLoadClick = (rowValues) => {
        Swal.fire({
            text: 'Are you sure you want to reopen a load ?',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok'
        }
        ).then((result) => {
            if (result.value === true) {
                setLabel('Reopen Load');
                setPostLoadFormFunctionality('Reopen Load');
                setExpandColapseTopPannel(false);
                setTopTabPannelValue(1);
                setOpenPostLoadForm(true);
                setShowing(!showing);
                setIsReuseDisabled(false);
                setIsReopenDisabled(true);
                setDisablePostLoadButton(false);
                let findLoad = rowDataForExpiredLoads.find(
                    ({ load_id }) => load_id === rowValues[0]
                );
                setLoadId(findLoad.load_id);
                findLoad.time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                findLoad.load_expiration_time = '00:30';
                if (findLoad.load_id) {
                    delete findLoad['load_id'];
                }
                setCompleteSelectedRowData(findLoad);
            }
        });
    };

    //Function to createupdatestcomment
    const updateTestComment = async (secondaryArray, action) => {
        if (secondaryArray.carrier_company === null) {
            if (selectedRowDataValue.posted_by_user !== user.email) {
                secondaryArray.carrier_company = userCompanyName
            }
        }
        try {
            let updateCommentResponse = await updateCommentApiCall(secondaryArray, action);
            if (updateCommentResponse) {
                setChatAllMessages(updateCommentResponse);
            }
        }
        catch (error) {
            console.log('update comment', error);
        }
    };

    //Function to createCommentNotification
    const createCommentNotification = async (data) => {
        try {
            let response = await axios({
                url: environmentVariables.createCommentNotification,
                method: 'patch',
                data: data,
            });
            return response;
        }
        catch (error) { }
    };

    //Function to send Message
    const onSendMessageClick = async (enteredMessage, messageType, loadChatMessages) => {
        fetchMainMuiDatatableValues()
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            if (loadChatMessages !== undefined && loadChatMessages.length !== 0 && loadChatMessages !== []) {
                if (enteredMessage !== null && enteredMessage !== '') {
                    const secondaryArray = loadChatMessages;
                    secondaryArray.time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                    secondaryArray.driver_name = selectedRowDataValue.driver_name;
                    let loop = secondaryArray.length;
                    let intialCount = 0;
                    secondaryArray.forEach(getMessage => {
                        if (intialCount < loop) {
                            getMessage.messages = [
                                ...getMessage.messages,
                                {
                                    date: moment().format('hh:mm A'),
                                    messanger_id: user.email,
                                    message: enteredMessage,
                                    user: user.nickname,
                                    message_type: messageType,
                                    read_status: [{ name: user.email, status: true }],
                                },
                            ];
                            intialCount = intialCount + 1;
                            updateTestComment(getMessage, 'patch');
                            if (document.getElementById('inputMesage') && document.getElementById('inputMesage').value !== null) {
                                document.getElementById('inputMesage').value = '';
                            }
                            if (user.email === selectedRowDataValue.posted_by_user || user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Broker' || selectedRowDataValue.posted_by_user === user.email) {
                                fetchAllComments(
                                    {
                                        'load_id': selectedRowDataValue.load_id,
                                        'posted_by_user': selectedRowDataValue.posted_by_user,
                                        'carrier_name': getMessage.carrier_id !== undefined ? getMessage.carrier_id : getMessage.carrier_name,
                                        'function': 'sendmessage'
                                    }
                                );
                                sendMessageForSocketIO('ThereIsANewMessageFromBroker||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + user.email, secondaryArray.carrier_id, true, selectedRowDataValue, []);
                                sendMessageForSocketIO('ThereIsNewMessageFromBroker||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValue, []);
                                sendMessageForSocketIO('newNotification', '', false);
                            }
                            return createCommentNotification({
                                data: {
                                    load_id: selectedRowDataValue.load_id,
                                    fromEmail: user.email,
                                    toEmail: selectedRowDataValue.posted_by_user,
                                    message: enteredMessage
                                },
                            });
                        }
                    });
                }
            }
        }
    };
    // function to handle getting response from post load form
    const handleNewOrderClick = async () => {
        let URL;
        let method;
        setDisablePostLoadButton(true);
        let getResponse = PostLoadFormRef.current.handleSendingResponse();
        if (getResponse[0].reopen_load === true) {
            getResponse[0].time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            URL = environmentVariables.editloaddetail;
            method = 'patch';
        }
        else {
            URL = environmentVariables.createload;
            method = 'post';
        }
        if (getResponse && getResponse !== 'validate failed') {
            await axios({
                url: URL,
                method: method,
                data: getResponse[0],
            })
                .then(async (response) => {
                    let responseArray = JSON.parse(response.data.body);
                    let loadDimension;
                    if (responseArray.freight_dimension_height !== '' || responseArray.freight_dimension_length !== '' || responseArray.freight_dimension_width !== '') {
                        if (responseArray.freight_dimension_length === '') {
                            loadDimension = 'NA X ';
                        }
                        else {
                            loadDimension = responseArray.freight_dimension_length.toString().trim() + 'L X ';
                        }
                        if (responseArray.freight_dimension_width === '') {
                            loadDimension += 'NA X ';
                        }
                        else {
                            loadDimension += responseArray.freight_dimension_width.toString().trim() + 'W X ';
                        }
                        if (responseArray.freight_dimension_height === '') {
                            loadDimension += 'NA';
                        }
                        else {
                            loadDimension += responseArray.freight_dimension_height.toString().trim() + 'H';
                        }
                    }
                    else {
                        loadDimension = '';
                    }
                    let payload = {
                        posted_by_company: responseArray.posted_by_company,
                        load_id: responseArray.load_id,
                        pickupfrom_date: responseArray.pickupfrom_date,
                        pickupto_date: responseArray.pickupto_date,
                        max_weight: responseArray.max_weight,
                        pickup_dest: responseArray.pickup_dest,
                        delivery_dest: responseArray.delivery_dest,
                        posted_by_user: user.email,
                        posted_comp_mc_number: loggedInCompanyMCNumber,
                        loadUrl: window.location.origin + '/loadboard?loadId=' + slugid.encode(responseArray.load_id),
                        equipment_needed: responseArray.truck_type,
                        load_dimension: loadDimension,
                        load_number: responseArray.load_number,
                        load_notes: responseArray.load_notes
                    };
                    if (getResponse[0].reopen_load !== true) {
                        setSnackBarMessageText('Load Succesfully Posted');
                        sendMessageForSocketIO('newLoadHasBeenPlaced||load_id:' + responseArray.load_id, '', true, responseArray, []);
                        try {
                            await createLoadNotificationApiCall(payload);
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }
                    else {
                        onSendMessageClick('Load has been reopened', 'System Message', allChat);
                        setSnackBarMessageText('Load Succesfully Reopen and Posted');
                        sendMessageForSocketIO('LoadHasBeenReopen||load_id:' + responseArray.load_id, '', true, responseArray, []);
                        try {
                            await reopenLoadNotificationApiCall(payload);
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }
                    setOpenSnackBar(true);
                    sendMessageForSocketIO('newNotification', '', false);
                    loadMatcheSaveLaneIncrementCountSendEmail(responseArray, loadDimension);
                    setIsNotificationSent(true);
                    setOpenPostLoadForm(false);
                    setCompleteSelectedRowData([]);
                    setIsReuseDisabled(false);
                    setIsReopenDisabled(false);
                    closeSecondaryPane();
                })
                .catch((error) => {
                    console.log('reuse load error', error);
                });
        }
        setDisablePostLoadButton(false);
    };

    //Function To Send Notification On Search Value Matches the Saved Lane
    const loadMatcheSaveLaneIncrementCountSendEmail = async (list, loadDimension) => {
        try {
            let matchLaneResponse = await getMatchedLoadApiCall(loggedInCompanyMCNumber, moment(new Date()).format('MM/DD/YYYY'));
            if (matchLaneResponse !== undefined) {
                let LaneCarrierName = [];
                matchLaneResponse.forEach(Lane => {
                    if (Lane.delete_lane === false) {
                        if ((new Date(Lane.pick_upfrom_date) >= new Date(list.pickupfrom_date) && Lane.pickup_dest === list.pickup_dest) || (new Date(Lane.pickupto_date) >= new Date(list.pickupto_date) && Lane.delivery_dest === list.delivery_dest)) {
                            LaneCarrierName.push(Lane.carrier_name);
                            let count = parseInt(Lane.matched_lane);
                            count = count + 1;
                            let listValues =
                            {
                                'pick_upfrom_date': Lane.pick_upfrom_date,
                                'pickup_dest': Lane.pickup_dest,
                                'delivery_dest': Lane.delivery_dest,
                                'pickupto_date': Lane.pickupto_date,
                                'carrier_name': Lane.carrier_name,
                                'max_weight': Lane.max_weight,
                                'total_item_count': Lane.total_item_count,
                                'truck_type': Lane.truck_type,
                                'miles_from': Lane.miles_from,
                                'miles_to': Lane.miles_to,
                                'delete_lane': false,
                                'matched_lane': count.toString(),
                                'lane_number': Lane.lane_number,
                                'load_id': list.load_id,
                                'lane_id': Lane.lane_id,
                            };
                            updateCount(listValues);
                            if (LaneCarrierName.length !== 0) {
                                sendEmailForMatchedLane(list, loadDimension, LaneCarrierName);
                            }
                        }
                    }
                });
                sendMessageForSocketIO('ThereIsALoadMatchesYourSavedLane||load_id:' + list.load_id + '||posted_by_user:' + list.posted_by_user, LaneCarrierName, true, list, LaneCarrierName);
                sendMessageForSocketIO('newNotification', '', false);
            }
        }
        catch (error) { }
    };
    // ^^^^^^^^^^^^^^^^^^^^^^^Function to handle All the chat^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    const Disable = (chatFirstCarrierName) => {
        rowDataBidTable.forEach(rowData => {
            if (rowData.carrier_name === chatFirstCarrierName) {
                if (rowData.status === 'Closed' || rowData.status === 'new') {
                    setDisableSendButton(true);
                }
                else if (rowData.status === 'accepted') {
                    setDisableSendButton(false);
                }
            }
            else if (rowData.carrier_name !== chatFirstCarrierName) {
                setDisableSendButton(true);
            }
        });
    };

    const togetCarrierName = async (carrierName) => {
        Disable(carrierName);
        if (selectedRowDataValue.carrier_name !== carrierName) {
            setDisableSendButton(true);
        }
        else {
            setDisableSendButton(false);
        }
        let loadId = '';
        let userId = '';
        if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Broker' || (loggedInuserCompanyRole === 'Broker&Carrier')) {
            loadId = selectedRowDataValue.load_id
            userId = '&broker_id=' + selectedRowDataValue.posted_by_user
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                getCommentResponse.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        setChatAllMessages(element);
                        setChatFirstCarrierName(element.carrier_id);
                        setChatCarrierMCNumber(`MC #${element.carrier_mc_number}`);
                    }

                });
            }
        }
        catch (error) { }
    };

    const getSingleCarrierMessage = async (event) => {
        if (event.target.id !== null && event.target.id !== '') {
            let carrierName = event.target.id;
            togetCarrierName(carrierName);
        }
        else {
            let carrierName = event.target.parentElement.id;
            togetCarrierName(carrierName);
        }
    };

    //------------------------- Place to define Socket related functions ------------------------------

    // Function to handle send Socket IO messages
    const sendMessageForSocketIO = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        try {
            const connectionIds = await handleSendMessageForSocketIO(message, recieverId, sendNotification, selectedRow, bidDetails)
            sendJsonMessage({ 'action': 'onMessage', 'message': message, 'connectionIds': connectionIds.toString() });
        }
        catch (error) { }
    };

    // Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        const recievedMessage = await handleSocketReceiveMessage(message, user.email);

        if (recievedMessage.includes('BrokerLoadHasBeenExpired')) {
            fetchMainMuiDatatableValues();
        }
    }

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    // Function that is triggered on page load
    useEffect(() => {
        if (Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
        fetchMainMuiDatatableValues();
        try {
            setInterval(async () => {
                fetchMainMuiDatatableValues();
            }, 120000);
        } catch (e) {
        }
    }, [userReduxData]);

    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity='success'>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor='#101e35'>
                                        <Tab className={`${classes.leftAlign}`} style={{ color: '#101e35' }} label={label} onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Expand More">
                                        <IconButton button onClick={() => handleExpandMoreIconClick()} hidden={!expandColapseTopPannel} >
                                            <ExpandMoreIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Expand Less">
                                        <IconButton button onClick={() => handleExpandLessIconClick()} hidden={expandColapseTopPannel} >
                                            <ExpandLessIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            <TabPanel value={topTabPannelValue} index={1} hidden={!openPostLoadForm}>
                                <div style={{ height: 5 }} />
                                <div style={{ padding: 2, margin: 1, color: 'black', border: 0 }} onKeyUp={(event) => {
                                    if (event.ctrlKey && event.key === 'Enter') {
                                        handleNewOrderClick();
                                    }
                                }}>
                                    {openPostLoadForm && (
                                        <PostLoadForm reopenId={loadId} selectedRowData={completeSelectedRowData} functionality={postLoadFormFunctionality} ref={PostLoadFormRef} handleNewOrderClick={handleNewOrderClick} updatedUser={user.email} />
                                    )}
                                    <div style={{ height: 10 }} />
                                    <Grid container xs={12} justify='flex-end'>
                                        <Tooltip arrow title='Ctrl+Enter'>
                                            <Button onClick={handleNewOrderClick} variant='contained' disabled={disablePostLoadButton} style={{ marginRight: 20, textTransform: 'none' }}>{label}</Button>
                                        </Tooltip>
                                        <Tooltip arrow title='Ctrl+Enter'>
                                            <Button onClick={handleExpandLessIconClick} variant='contained' disabled={disablePostLoadButton} style={{ marginRight: 20, textTransform: 'none' }}>Cancel</Button>
                                        </Tooltip>
                                    </Grid>
                                </div>
                            </TabPanel>
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandColapseTopPannel}>
                                <SearchForm isPaneOpen={isPaneOpen} closeSecondaryPane={closeSecondaryPane} ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPicesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid style={{ paddingBottom: '15%' }}>
                        <Paper square>
                            <Grid container direction='row' margin={2}>
                                <div style={{ width: mainGridPercent, display: 'table', tableLayout: 'fixed' }}>
                                    <MuiThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            title={'Expired Loads'}
                                            data={rowDataForExpiredLoads}
                                            columns={columnDefsExpiredLoadTable}
                                            options={optionsForExpiredLoads}
                                        ></MUIDataTable>
                                    </MuiThemeProvider>
                                </div>
                                {isPaneOpen && (
                                    <React.Fragment>
                                        <div style={{ width: '1%' }}></div>
                                        <div style={{ width: '64%' }} border={1}>
                                            <Box
                                                boxShadow={3}>
                                                <Paper square className='square'>
                                                    <Grid container xs={12} justify='flex-end'>
                                                        {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                                            <div>
                                                                <Button variant='contained' style={{ marginRight: 15, textTransform: 'none' }} disabled={isReuseDisabled} onClick={() => handleActivateLoadClick(selectedRow)} >
                                                                    Reuse Load
                                                                </Button>
                                                                {(selectedRowDataValue.load_delete_status !== true) && (
                                                                    <Button variant='contained' style={{ marginRight: 15, textTransform: 'none' }} disabled={isReopenDisabled} onClick={() => handleReopenLoadClick(selectedRow)} >
                                                                        Reopen Load
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )}
                                                        <IconButton onClick={closeSecondaryPane}>
                                                            <Tooltip title="Close">
                                                                <CloseIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid>
                                                    <div style={{ height: 7 }} />
                                                    <Row style={{ fontSize: 18 }}>
                                                        <Col sm>
                                                            <b>Load Details</b>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ height: 5 }} />
                                                    <LoadDetailsPannel allLoadDetails={selectedRowDataValue} />
                                                </Paper>
                                            </Box>
                                            {/* {
                                                                (loadPostedByUser === user.email) &&
                                                                (
                                                                    <React.Fragment>
                                                                        <div style={{ height: 5 }} />
                                                                        <div style={{ height: 10 }}>
                                                                            <Grid style={{ marginLeft: 10 }}>
                                                                                <Box style={{ width: '99%', backgroundColor: '#dae3ea' }}
                                                                                    color='black' border={0} padding={2}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                                        <h6><b>Load Expiration</b></h6>
                                                                                    </div>
                                                                                    <Row>
                                                                                        <Col style={{ marginLeft: 25 }} sm={3} >
                                                                                            <MUIAutoComplete
                                                                                                onClose={(event, newInputValue) => loadHandleLoadExpirationClose(event, newInputValue)}
                                                                                                freeSolo
                                                                                                disableClearable
                                                                                                value={loadUpdateLoadExpirationTime}
                                                                                                onChange={(event, newValue) => {
                                                                                                    setLoadUpdateLoadExpirationTime(newValue);
                                                                                                }}
                                                                                                inputValue={loadUpdateLoadExpirationTime}
                                                                                                onInputChange={(event, newInputValue) =>
                                                                                                    loadHandleLoadExpirationChange(event, newInputValue)
                                                                                                }
                                                                                                options={optionValueForLoadExp}
                                                                                                style={{ marginTop: 15 }}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField {...params} variant='standard' />
                                                                                                )}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={8} >
                                                                                            <Button style={{ marginTop: 10, width: '40%' }} variant='contained' size='medium' color='primary' onClick={handleUpdateLoadExpirationUpdateChnage}>
                                                                                                Set
                                                                                            </Button>
                                                                                            <Button style={{ marginTop: 10, marginLeft: 20, width: '40%' }} variant='contained' size='medium' color='primary' onClick={handleLoadExpireNow}>
                                                                                                Expire Now
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Box>
                                                                            </Grid>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            } */}
                                            <div style={{ height: 10 }} />
                                            <Divider />
                                            {
                                                <React.Fragment>
                                                    <div style={{ height: 10 }} />
                                                    <MUIDataTable
                                                        title={'Bid Details'}
                                                        data={rowDataBidTable}
                                                        columns={columnDefsBidTable}
                                                        options={optionsBidTable}
                                                    ></MUIDataTable>
                                                    <div style={{ height: 10 }} />
                                                </React.Fragment>
                                            }
                                            <div style={{ height: 10 }} />
                                            {
                                                ((loggedInuserCompanyRole === 'Broker' || userCompanyName === 'SystemAdmin') ||
                                                    (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin' || loggedInuserRole === 'Broker'))
                                                ) &&
                                                (
                                                    <React.Fragment>
                                                        <Divider />
                                                        <Grid>
                                                            <Box boxShadow={3}>
                                                                <Paper square className='paper'>
                                                                    <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor='primary' textColor='primary'>
                                                                        <Tab label='Comments' />
                                                                    </Tabs>
                                                                    <TabPanel value={tabPannerlValue} index={0}>
                                                                        <React.Fragment>
                                                                            <div className='homePage'>
                                                                                <div className='leftSide'>
                                                                                    <Header />
                                                                                    {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                                    {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                        <List className='Custom-MuiList-root' style={{ maxHeight: 'calc(100vh - 109px)', overflow: 'auto', objectFit: 'cover', height: 415 }}>
                                                                                            {
                                                                                                allChat
                                                                                                    .sort(function (b, a) {
                                                                                                        return new Date(a.timestamp) - new Date(b.timestamp);
                                                                                                    }).sort(function (x, y) {
                                                                                                        return x.pinned_user === true ? -1 : y.pinned_user === true ? 1 : 0;
                                                                                                    }).map((item, i) => {
                                                                                                        return (
                                                                                                            <div key={item.carrier_id}>
                                                                                                                <ListItem button alignItems='flex-start' id={item.carrier_id} value={item.carrier_id} className='Custom-MuiListItem-root' key={item.carrier_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                                    <div id={item.carrier_id} className='discussion'>
                                                                                                                        <div id={item.carrier_id} className='leftDiscussion'>
                                                                                                                            <Avatar>{item.carrier_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                        </div>
                                                                                                                        <div id={item.carrier_id} className='rightDiscussion'>
                                                                                                                            <div id={item.carrier_id} className='rightDiscussionTop'>
                                                                                                                                <div id={item.carrier_id} className='rightDiscussionTopLeft'>{item.carrier_company}</div>
                                                                                                                            </div>
                                                                                                                            <div id={item.carrier_id} className='rightDiscussionBas'>
                                                                                                                                {
                                                                                                                                    (item.bid_amount !== undefined && item.bid_amount !== '$') ?
                                                                                                                                        <div className='rightDiscussionTopRight'>{'Bid: ' + item.bid_amount}</div>
                                                                                                                                        :
                                                                                                                                        <div className='rightDiscussionTopRight'>{'Bid: ' + '$' + bidAmount}</div>
                                                                                                                                }
                                                                                                                                {(item.pinned_user === false ?
                                                                                                                                    <Tooltip title='Pin'>
                                                                                                                                        <IconButton id={item.chat_id} onClick={() => pinChat(item)} className='pin_btn'>
                                                                                                                                            <Pinned />
                                                                                                                                        </IconButton>
                                                                                                                                    </Tooltip>
                                                                                                                                    :
                                                                                                                                    <Tooltip title='Unpin'>
                                                                                                                                        <IconButton id={item.chat_id} onClick={() => pinChat(item)} className='pin_btn'>
                                                                                                                                            <UnPinned />
                                                                                                                                        </IconButton>
                                                                                                                                    </Tooltip>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </ListItem>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                    )
                                                                                            }
                                                                                        </List>
                                                                                    )}
                                                                                </div>
                                                                                <div className='rightSide'>
                                                                                    {(chatFirstCarrierName !== undefined) && (
                                                                                        <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf('@'))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                    )}
                                                                                    <Chat discussion={chatAllMessages} />
                                                                                    <div>
                                                                                        <form onKeyPress={(e) => {
                                                                                            if (e.key === 'Enter') {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}>
                                                                                            <Paper component='form' className={classes.customPaper}>
                                                                                                <InputBase id='inputMesage' autoComplete='off' className={classes.CustomInput} style={{ width: '85%' }} placeholder='Enter your message' />
                                                                                                <IconButton>
                                                                                                    <SendIcon />
                                                                                                </IconButton>
                                                                                            </Paper>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    </TabPanel>
                                                                </Paper>
                                                            </Box>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Typography>
            </main>
        </div>
    );
};
export default ExpiredLoads;