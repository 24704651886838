import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
    Typography,
    Grid,
    Box,
    Snackbar,
    TextField,
    Divider,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import cities from 'cities';
// import zipcodes from 'zipcodes';
import Swal from 'sweetalert2';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

// Import statements for CSS and environment
import '../../module/LandingPage/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { environmentVariables } from '../../constants/environment';
import { saveLaneApiCall } from '../../apis/searchedLane';
import { useSelector } from 'react-redux';

//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 10,
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    },
    indicator: {
        backgroundColor: '#101e35'
    }

}));


// function to get top tab pannels
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

//--------------------------------------------*********************-----------------------------------------
const SearchForm = (props, ref) => {
    //------------------------- Place to declare all the constants ------------------------------
    const classes = useStyles();
    const { user } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    // Variable to handle column defs of load table
    const [columnDefsLoadTable, setColumnDefsLoadTable] = useState(props.columnDefs);

    // Constant to handle ValidatePickUpDetails
    const [selectedTxtFromCity, setSelectedTxtFromCity] = useState('');
    const [selectedTxtToCity, setSelectedTxtToCity] = useState('');
    const [isFromCityValid, setIsFromCityValid] = useState(false);
    const [isToCityValid, setIsToCityValid] = useState(false);

    // Constant to handle errors
    const [errors, setErrors] = useState({});
    const [disableAddToLane, setDisableAddToLane] = useState(false);

    // Constant to handle from latitude and longitude
    const [fromlatitude, setFromlatitude] = useState(0);
    const [fromlongitude, setFromlongitude] = useState(0);

    // Constant to handle to latitude and longitude
    const [tolatitude, settolatitude] = useState(0);
    const [tolongitude, settolongitude] = useState(0);

    // Constants for search component
    const [searchLoadNumber, setSearchLoadNumber] = useState('');
    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState('');
    const [searchPickUpFromState, setSearchPickUpFromState] = useState('');
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState('');
    const [searchPickUpMiles, setSearchPickUpMiles] = useState('');

    // Constant for Pickup from date
    const [searchPickUpDate, setSearchPickUpDate] = useState(null);

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState('');
    const [searchDeliverToState, setSearchDeliverToState] = useState('');
    const [searchDeliverToZip, setSearchDeliverToZip] = useState('');
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState('');

    // Constant for Pickup from date
    const [searchDeliverToDate, setSearchDeliverToDate] = useState(null);

    // Constant for Pickup from date
    const [searchMaxWeight, setSearchMaxWeight] = useState('');

    // Constant to handle truck type
    const [searchTruckTypeCheckboxes, setSearchTruckTypeCheckboxes] = useState('');

    // Constant for Pickup from date
    const [searchNumberOfPices, setSearchNumberOfPices] = useState('');

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    // constant for switching from search load form
    const [isSearchFieldValueChanged, setIsSearchFieldValueChanged] = useState(false);

    // Constant to handle logged in user role
    // const [userReduxData.userData.user_role, setuserReduxData.userData.user_role] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in company role
    // const [userReduxData.userData.company_role, setuserReduxData.userData.company_role] = useState(window.localStorage.getItem('company_role'));

    // Constant to handle socket connections
    // const {
    //     sendJsonMessage,
    // } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
    //     {
    //         onMessage: (msg) => { },
    //         shouldReconnect: (closeEvent) => true,
    //     });

    //Function to close Secondary Pane On searching a value
    const closeloadDetails = () => {
        if (window.location.pathname === '/activeloads') {
            props.closeSecondaryPaneForLoadPosted();
        }
        if (window.location.pathname === '/activebids') {
            props.closeSecondaryPaneForFavorite();
        }
        if (window.location.pathname !== '/activeloads' && window.location.pathname !== '/activebids') {
            if (props.isPaneOpen === true) {
                props.closeSecondaryPane();
            }
        }
    };

    //Function To Handle filter For Pick Up City
    const FromCityfilter = () => {
        if (window.location.pathname === '/expiredbids' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/allloads') {
            props.filter(1);
        }
        if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
            props.filter(3);
        }
        else {
            props.filter(2);
        }
    };

    //function To Handle Deliver City Filter
    const ToCityFilter = () => {
        if (window.location.pathname === '/expiredloads' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/expiredbids' || window.location.pathname === '/allloads') {
            props.filter(3);
        }
        if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
            props.filter(5);
        }
        else {
            props.filter(4);
        }
    };

    const handlefilterIndex = () => {
        if (searchLoadNumber !== '') {
            setSearchLoadNumber('');
            if (window.location.pathname === '/loadboard' || (window.location.pathname === '/activeloads' && userReduxData.userData.company_role !== 'Carrier')) {
                props.filter(14);
            }
            if (window.location.pathname === '/allloads') {
                props.filter(13);
            }
            if (window.location.pathname === '/activebids') {
                props.filter(15);
            }
            if (window.location.pathname === '/expiredbids' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered') {
                props.filter(10);
            }
            else {
                props.filter(11);
            }
        }
        if (searchPickUpFromCity !== '' || searchPickUpFromState !== '' || searchPickUpFromZip !== '' || searchPickUpMiles !== '') {
            setSearchPickUpMiles('');
            setSearchPickUpFromState('');
            setSearchPickUpFromCity('');
            setSearchPickUpFromZip('');
            FromCityfilter();
        }
        if (searchPickUpDate !== '' && searchPickUpDate !== null) {
            setSearchPickUpDate('');
            if (window.location.pathname === '/expiredloads' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/expiredbids' || window.location.pathname === '/allloads') {
                props.filter(2);
            }
            if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
                props.filter(4);
            }
            else {
                props.filter(3);
            }
        }
        if (searchDeliverToCity !== '' || searchDeliverToState !== '' || searchDeliverToZip !== '') {
            setSearchDeliverToCity('');
            setSearchDeliverToState('');
            setSearchDeliverToZip('');
            ToCityFilter();
        }
        if (searchDeliverToDate !== '' && searchDeliverToDate !== null) {
            setSearchDeliverToDate('');
            if (window.location.pathname === '/expiredbids' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/allloads') {
                props.filter(5);
            }
            if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
                props.filter(7);
            }
            else {
                props.filter(6);
            }
        }
        if (searchMaxWeight !== '') {
            setSearchMaxWeight('');
            if (window.location.pathname === '/expiredbids' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/allloads') {
                props.filter(7);
            }
            if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
                props.filter(9);
            }
            else {
                props.filter(8);
            }
        }
        if (searchNumberOfPices !== '') {
            setSearchNumberOfPices('');
            if (window.location.pathname === '/expiredbids' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/allloads') {
                props.filter(8);
            }
            if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
                props.filter(10);
            }
            else {
                props.filter(9);
            }
        }
        if (searchTruckTypeCheckboxes !== '') {
            if (window.location.pathname === '/expiredbids' || window.location.pathname === '/loadsassigned' || window.location.pathname === '/loadspickedup' || window.location.pathname === '/loadsdelivered' || window.location.pathname === '/allloads') {
                props.filter(6);
            }
            if (window.location.pathname === '/activebids' && userReduxData.userData.company_role === 'Carrier') {
                props.filter(8);
            }
            else {
                props.filter(7);
            }
            setSearchTruckTypeCheckboxes('');
            if (document.getElementById('Sprinter/CargoVanForSearch') !== '')
                document.getElementById('Sprinter/CargoVanForSearch').checked = false;

            if (document.getElementById('SmallStraightForSearch') !== '')
                document.getElementById('SmallStraightForSearch').checked = false;

            if (document.getElementById('LargeStraightForSearch') !== '')
                document.getElementById('LargeStraightForSearch').checked = false;
            if (document.getElementById('allTruckSearch') !== '')
                document.getElementById('allTruckSearch').checked = false;
        }
    };

    // Function to handle Search Reset
    const onSearchRestClick = () => {
        removeErrorColor();
        setIsSearchFieldValueChanged(false);
        closeloadDetails();
        handlefilterIndex();
        setColumnDefsLoadTable(props.columnDefs);
        setSearchLoadNumber('');
        setSearchPickUpFromCity('');
        setSearchPickUpFromState('');
        setSearchPickUpFromZip('');
        setSearchPickUpMiles('');
        setSelectedTxtFromCity('');
        setIsFromCityValid(false);
        setSearchPickUpDate(null);

        setSearchDeliverToCity('');
        setSearchDeliverToState('');
        setSearchDeliverToZip('');
        setSearchDeliverMiles('');
        setSelectedTxtToCity('');
        setIsToCityValid(false);
        setSearchDeliverToDate(null);

        setSearchMaxWeight('');

        setSearchTruckTypeCheckboxes('');

        setSearchNumberOfPices('');

        if (document.getElementById('Sprinter/CargoVanForSearch'))
            document.getElementById('Sprinter/CargoVanForSearch').checked = false;

        if (document.getElementById('SmallStraightForSearch'))
            document.getElementById('SmallStraightForSearch').checked = false;

        if (document.getElementById('LargeStraightForSearch'))
            document.getElementById('LargeStraightForSearch').checked = false;
        if (document.getElementById('allTruckSearch'))
            document.getElementById('allTruckSearch').checked = false;
    };

    // Function to handle search value change
    const handleCheckingTextFieldValueChange = (value, searchedValue) => {
        if (value !== '' && value !== null) {
            setIsSearchFieldValueChanged(true);
        }
        if (value === '' || value === null) {
            if (searchedValue === 'loadNumber' && searchLoadNumber !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (value === null && searchedValue === 'pickUpDate' && searchPickUpDate !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'fromcity' && searchPickUpFromCity !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'fromzip' && searchPickUpFromZip !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (value === null && searchedValue === 'DeliverDate' && searchDeliverToDate !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'tocity' && searchDeliverToCity !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'tozip' && searchDeliverToZip !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'weight' && searchMaxWeight !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'pieces' && searchNumberOfPices !== '') {
                setIsSearchFieldValueChanged(false);
            }
            if (searchedValue === 'truck' && searchTruckTypeCheckboxes !== '') {
                setIsSearchFieldValueChanged(false);
            }
        }
    };

    const onSearchClear = (index) => {
        if (index === 14 && searchLoadNumber !== '') {
            setSearchLoadNumber('');
        }
        if (index === 2 && (searchPickUpFromCity !== '' || searchPickUpFromState !== '' || searchPickUpFromZip !== '' || searchPickUpMiles !== '')) {
            setSearchPickUpMiles('');
            setSearchPickUpFromState('');
            setSearchPickUpFromCity('');
            setSearchPickUpFromZip('');
        }
        if (index === 3 && searchPickUpDate !== '') {
            setSearchPickUpDate('');
        }
        if (index === 4 && (searchDeliverToCity !== '' || searchDeliverToState !== '' || searchDeliverToZip !== '')) {
            setSearchDeliverToCity('');
            setSearchDeliverToState('');
            setSearchDeliverToZip('');
        }
        if (index === 6 && searchDeliverToDate !== '') {
            setSearchDeliverToDate('');
        }
        if (index === 8 && searchMaxWeight !== '') {
            setSearchMaxWeight('');
        }
        if (index === 9 && searchNumberOfPices !== '') {
            setSearchNumberOfPices('');
        }
        if (index === 7 && searchTruckTypeCheckboxes !== '') {
            setSearchTruckTypeCheckboxes('');

            if (document.getElementById('Sprinter/CargoVanForSearch') !== '')
                document.getElementById('Sprinter/CargoVanForSearch').checked = false;

            if (document.getElementById('SmallStraightForSearch') !== '')
                document.getElementById('SmallStraightForSearch').checked = false;

            if (document.getElementById('LargeStraightForSearch') !== '')
                document.getElementById('LargeStraightForSearch').checked = false;
            if (document.getElementById('allTruckSearch') !== '')
                document.getElementById('allTruckSearch').checked = false;
        }
    };

    useImperativeHandle(ref, () => ({
        onSearchClear,
        isSearchFieldValueChanged
    }));

    // Function to search from Load number/id
    const onLoadNumberForSearch = (event) => {
        closeloadDetails();
        if (event.target.validity.valid) {
            handleCheckingTextFieldValueChange(event.target.value, 'loadNumber');
            setSearchLoadNumber(event.target.value);
        }
        props.ln(event);
    };

    const handleOnChangeSearchForFromPlace = (value) => {
        handleCheckingTextFieldValueChange(value, 'fromcity');
        closeloadDetails();
        setSearchPickUpFromState('');
        setSearchPickUpFromZip('');
        FromCityfilter();
        if (searchPickUpFromCity !== '') {
            if (value === selectedTxtFromCity) {
                setIsFromCityValid(true);
            }
            else {
                setIsFromCityValid(false);
            }
        }
        setSearchPickUpFromCity(value);
        setSearchPickUpMiles('');
    };

    //$$$$$$$$$$$$$$$$$$$$$$$$$$ Functions to handle top box $$$$$$$$$$$$$$$$$$$$$$$$$$$$
    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };

    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        closeloadDetails();
        props.onPlaceSelected(place);
        setSearchPickUpMiles('');
        setSelectedTxtFromCity(getArea(place.address_components));
        setSearchPickUpFromCity(getArea(place.address_components));
        setIsFromCityValid(true);
        setSearchPickUpFromState(getState(place.address_components));
        setIsSearchFieldValueChanged(true);
        if (place.geometry) {
            if (cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng())) {
                setSearchPickUpFromZip(cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode);
            }
            setFromlatitude(place.geometry.location.lat());
            setFromlongitude(place.geometry.location.lng());
        }
    };

    // function to get all nearby pickup cities within specified miles
    const getNearestPickUpCities = async (event) => {
        closeloadDetails();
        setIsSearchFieldValueChanged(true);
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            setSearchPickUpMiles(event.target.value);
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // } 
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=pickupcitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status) {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest);
                            }
                            else {
                                filterList.push(element.pickup_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsLoadTable];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsLoadTable];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsLoadTable(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    const updateFromZipCodeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                handleCheckingTextFieldValueChange(event.target.value, 'fromzip');
                closeloadDetails();
                setSearchPickUpMiles('');
                setSearchPickUpFromState('');
                setSearchPickUpFromCity('');
                FromCityfilter();
                setSearchPickUpFromZip(event.target.value);
            }
        }
        if (event.target.value.length === 5) {
            setSearchPickUpFromZip(event.target.value);
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                setSearchPickUpFromState(getFromCityState.state_abbr);
                setSearchPickUpFromCity(getFromCityState.city);
                setSelectedTxtFromCity(getFromCityState.city);
                setIsFromCityValid(true);
                props.fromCityZip(event);
            }
            else {
                Swal.fire({
                    text: 'Not a valid Postal Code',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                }
                );
                setSearchPickUpFromZip('');
                setSearchPickUpFromCity('');
                setSearchPickUpFromState('');
            }
        }
    };

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        handleCheckingTextFieldValueChange(date, 'pickUpDate');
        closeloadDetails();
        setSearchPickUpDate(date);
        props.pickUpDate(date);
    };

    const handleOnChangeSearchForDeliverToPlace = (value) => {
        handleCheckingTextFieldValueChange(value), 'tocity';
        closeloadDetails();
        setSearchDeliverToState('');
        setSearchDeliverToZip('');
        ToCityFilter();
        if (searchDeliverToCity !== '') {
            setIsSearchFieldValueChanged(false);
            if (value === selectedTxtToCity) {
                setIsToCityValid(true);
            }
            else {
                setIsToCityValid(false);
            }
        }
        else {
            setIsSearchFieldValueChanged(true);
        }
        setSearchDeliverToCity(value);
        setSearchDeliverMiles('');
    };

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        closeloadDetails();
        setSearchDeliverMiles('');
        setSearchDeliverToCity(getArea(place.address_components));
        setSearchDeliverToState(getState(place.address_components));
        setIsToCityValid(true);
        setIsSearchFieldValueChanged(true);
        if (place.geometry) {
            if (cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng())) {
                setSearchDeliverToZip(cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode);
            }
            settolatitude(place.geometry.location.lat());
            settolongitude(place.geometry.location.lng());
            props.onToPlaceSelected(place);
        }
    };

    // function to get all nearby deliver cities within specified miles
    const getNearestCitiesForDeliverCity = (event) => {
        closeloadDetails();
        searchDeliveryMiles(event);
        props.deliverCityMiles(event);
    };

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                handleCheckingTextFieldValueChange(event.target.value, 'tozip');
                closeloadDetails();
                setSearchDeliverMiles('');
                setSearchDeliverToCity('');
                setSearchDeliverToState('');
                ToCityFilter();
                setSearchDeliverToZip(event.target.value);
            }
        }
        if (event.target.value.length === 5) {
            setSearchDeliverToZip(event.target.value);
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                setSearchDeliverToState(getFromCityState.state_abbr);
                setSearchDeliverToCity(getFromCityState.city);
                setSelectedTxtToCity(getFromCityState.city);
                setIsToCityValid(true);
                props.deliverZip(event);
            }
            else {
                Swal.fire({
                    text: 'Not a valid Postal Code',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                }
                );
                setSearchDeliverToZip('');
                setSearchDeliverToCity('');
                setSearchDeliverToState('');
            }
        }
    };

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        handleCheckingTextFieldValueChange(date, 'deliverDate');
        closeloadDetails();
        setSearchDeliverToDate(date);
        props.deliverDate(date);
    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setSearchMaxWeight(event.target.value);
        handleCheckingTextFieldValueChange(event.target.value, 'weight');
        closeloadDetails();
        props.weight(event);
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                setSearchNumberOfPices(event.target.value);
        handleCheckingTextFieldValueChange(event.target.value, 'pieces');
        closeloadDetails();
        props.pieces(event);
            }
        }
    };

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (event) => {
        closeloadDetails();
        const target = event.target;
        let allTruckType = searchTruckTypeCheckboxes;
        if (target.checked) {
            setSearchTruckTypeCheckboxes('Sprinter/Cargo Van' + ',' + 'Small Straight' + ',' + 'Large Straight');
            allTruckType = 'Sprinter/Cargo Van' + ',' + 'Small Straight' + ',' + 'Large Straight';
            handleCheckingTextFieldValueChange(true);
        }
        else {
            document.getElementById('Sprinter/CargoVanForSearch').checked = false;
            document.getElementById('SmallStraightForSearch').checked = false;
            document.getElementById('LargeStraightForSearch').checked = false;
            setSearchTruckTypeCheckboxes([]);
            allTruckType = '';
            handleCheckingTextFieldValueChange('', 'truck');
        }
        if (allTruckType.includes('Sprinter/Cargo Van') && allTruckType.includes('Small Straight') && allTruckType.includes('Large Straight')) {
            document.getElementById('Sprinter/CargoVanForSearch').checked = true;
            document.getElementById('SmallStraightForSearch').checked = true;
            document.getElementById('LargeStraightForSearch').checked = true;
            handleCheckingTextFieldValueChange(true, 'truck');
        }
        props.handleSelectAllCheckboxChange(allTruckType);
    };

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (event) => {
        closeloadDetails();
        const target = event.target;
        let allTruckType = searchTruckTypeCheckboxes;
        if (target.checked) {
            setIsSearchFieldValueChanged(true);
            if (!allTruckType.includes(target.name)) {
                allTruckType += ',' + target.name;
                allTruckType = allTruckType.replace(/^,|,$|(,+)/g, function (m, g1) {
                    return g1 ? ',' : '';
                });
                setSearchTruckTypeCheckboxes(allTruckType);
                handleCheckingTextFieldValueChange(allTruckType, 'truck');
            }
        } else {
            setIsSearchFieldValueChanged(false);
            document.getElementById('allTruckSearch').checked = false;
            allTruckType = allTruckType.replace(target.name, '');
            allTruckType = allTruckType.replace(/^,|,$|(,+)/g, function (m, g1) {
                return g1 ? ',' : '';
            });
            setSearchTruckTypeCheckboxes(allTruckType);
            handleCheckingTextFieldValueChange(allTruckType, 'truck');
        }
        if (allTruckType.includes('Sprinter/Cargo Van') && allTruckType.includes('Small Straight') && allTruckType.includes('Large Straight')) {
            document.getElementById('allTruckSearch').checked = true;
        }
        props.handleCheckBoxChangeForSearch(allTruckType);
        handleCheckingTextFieldValueChange(allTruckType, 'truck');
    };

    //Function to Remove ValidatePickUpDetails On Reset
    const removeErrorColor = () => {
        let validateError = {};
        validateError.searchPickUpFromCity = searchPickUpFromCity ? '' : '';
        validateError.searchPickUpFromState = searchPickUpFromState ? '' : '';
        validateError.searchPickUpFromZip = searchPickUpFromZip ? '' : '';
        validateError.searchDeliverToCity = searchDeliverToCity ? '' : '';
        validateError.searchDeliverToState = searchDeliverToState ? '' : '';
        validateError.searchDeliverToZip = searchDeliverToZip ? '' : '';
        validateError.searchDeliverToDate = searchDeliverToDate ? '' : '';
        if (searchDeliverToZip.length < 5) {
            validateError.searchDeliverToZipLength = '';
        }
        validateError.isToCityValid = isToCityValid ? '' : '';
        if (searchPickUpFromZip.length < 5) {
            validateError.searchPickUpFromZipLength = '';
        }
        validateError.isFromCityValid = isFromCityValid ? '' : '';
        setErrors({
            ...validateError
        });
        return Object.values(validateError).every(x => x === '');
    };

    //Function To Save Search Lane
    const saveSearchLane = async () => {
        setDisableAddToLane(true);
        let listValues =
        {
            'pick_upfrom_date': moment(searchPickUpDate).format('MM/DD/YYYY'),
            'pickup_dest': searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip,
            'delivery_dest': searchDeliverToCity + ', ' + searchDeliverToState + ' - ' + searchDeliverToZip,
            'pickupto_date': moment(searchDeliverToDate).format('MM/DD/YYYY'),
            'carrier_name': user.email,
            'max_weight': searchMaxWeight,
            'total_item_count': searchNumberOfPices,
            'truck_type': searchTruckTypeCheckboxes,
            'miles_from': searchPickUpMiles,
            'miles_to': searchDeliveryMiles,
            'delete_lane': false,
            'matched_lane': '0',
            'load_id': 'NA',
            'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            'carrier_company': props.userCompanyName,
            'company_mc_number':userReduxData.userData.company_mc_number
        };
        try {
            const saveLaneResponse = await saveLaneApiCall(listValues);
            if (saveLaneResponse) {
                setSnackBarMessageText('Search Lane Saved');
                setOpenSnackBar(true);
                setDisableAddToLane(false);
                onSearchRestClick();
                removeErrorColor();
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //Function to Validate On adding to search Lane
    const validateFromSearch = () => {
        let ValidatePickUpDetails = {};
        ValidatePickUpDetails.searchPickUpFromCity = searchPickUpFromCity ? '' : 'error';
        ValidatePickUpDetails.searchPickUpFromState = searchPickUpFromState ? '' : 'error';
        ValidatePickUpDetails.searchPickUpFromZip = searchPickUpFromZip ? '' : 'error';
        if (searchPickUpFromZip.length < 5) {
            ValidatePickUpDetails.searchPickUpFromZipLength = 'error';
        }
        else {
            ValidatePickUpDetails.searchPickUpFromZipLength = '';
        }
        ValidatePickUpDetails.isFromCityValid = isFromCityValid ? '' : 'error';
        if (!isFromCityValid) {
            // setDisablePostLoadButton(true)
            Swal.fire({
                text: 'invalid  From City name',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        setErrors({
            ...ValidatePickUpDetails
        });
        return Object.values(ValidatePickUpDetails).every(x => x === '');
    };
    const validateToSearch = () => {
        let ValidateDeliverDetails = {};
        ValidateDeliverDetails.searchDeliverToCity = searchDeliverToCity ? '' : 'error';
        ValidateDeliverDetails.searchDeliverToState = searchDeliverToState ? '' : 'error';
        ValidateDeliverDetails.searchDeliverToZip = searchDeliverToZip ? '' : 'error';
        ValidateDeliverDetails.searchDeliverToDate = searchDeliverToDate ? '' : 'error';
        if (searchDeliverToZip.length < 5) {
            ValidateDeliverDetails.searchDeliverToZipLength = 'error';
        }
        else {
            ValidateDeliverDetails.searchDeliverToZipLength = '';
        }
        ValidateDeliverDetails.isToCityValid = isToCityValid ? '' : 'error';
        if (!isToCityValid) {
            Swal.fire({
                text: 'invalid To City name',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        setErrors({
            ...ValidateDeliverDetails
        });
        return Object.values(ValidateDeliverDetails).every(x => x === '');
    };

    //Function To Validate Save Searched Values
    const ValidateSaveSearchLane = () => {
        if (searchPickUpDate !== null || searchDeliverToDate !== null) {
            let pick = moment(searchPickUpDate, 'MM/DD/YYYY');
            let deliver = moment(searchDeliverToDate, 'MM/DD/YYYY');
            let difference = deliver.diff(pick, 'days');
            if (difference < 0) {
                Swal.fire({
                    text: 'Delivery Date should be after the Pick up Date',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }
                );
            }
            else {
                if ((searchPickUpFromCity !== '' && searchPickUpFromZip !== '' && searchPickUpDate !== null) || (searchDeliverToCity !== '' && searchDeliverToZip !== '' && searchDeliverToDate !== null)) {
                    saveSearchLane();
                }
                else {
                    if (searchPickUpFromCity === '' || searchPickUpFromZip === '') {
                        validateFromSearch();
                    }
                    else {
                        validateToSearch();
                    }
                }
            }
        }
        else {
            Swal.fire({
                text: 'Cannot Save a Lane without Date',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc',
            }
            );
        }
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    // Function to handle MuiAlert
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant='filled' {...props} />;
    };

    //Function that is triggered on page load
    useEffect(() => {
        onSearchRestClick();
    }, []);

    //--------------------------------------------*********************--------------------------
    return (
        <div className={classes.root}>
            <div style={{ height: 5 }} />
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity='success'>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            {/* ************************ firtst tab************************* */}
            {/* <TabPanel value={topTabPannelValue} index={0} hidden={expandColapseTopPannel}>
                                {((userReduxData.userData.company_role === 'Broker' || (userReduxData.userData.company_role === 'Broker&Carrier' && userReduxData.userData.user_role !== 'Carrier'))) &&
                                    <form>
                                        <div style={{ height: 5 }} />
                                        <Grid container item xs={12} justify='flex-end'>
                                            <Button onClick={clearPostLoadForm} variant='contained' hidden={showing} disabled={disableClearFormButton} style={{ marginRight: 20, textTransform: 'none' }}>Clear</Button>
                                        </Grid>
                                    </form>
                                }
                            </TabPanel> */}
            {/* ***************************** Second Tab******************************* */}
            <Grid container direction='row'>
                <Grid item xs={12} sm={8}>
                    {/*----------First row of the box which is for Pick up zip,state,city For search-----------*/}
                    <Row>
                        <Col sm>Load ID :</Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder='Load ID'
                                    value={searchLoadNumber}
                                    error={searchLoadNumber < 0}
                                    autoComplete='off'
                                    helperText={searchLoadNumber < 0 ? 'Not Valid Input' : ''}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 8 }}
                                    onChange={onLoadNumberForSearch}
                                    style={{ width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm ></Col>
                        <Col sm ></Col>
                    </Row>
                    <div style={{ height: 15 }} />
                    <Divider />
                    <div style={{ height: 15 }} />
                    <Row>
                        <Col sm>From :</Col>
                        <Col sm>
                            {
                                <Autocomplete
                                    id='AutoCompFromCity'
                                    style={{ height: 39, width: 140, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, }}
                                    value={searchPickUpFromCity}
                                    onChange={(val) => handleOnChangeSearchForFromPlace(val.target.value)}
                                    onPlaceSelected={(place) => {
                                        onFromPlaceSelectedForSearch(place);
                                    }}
                                    placeholder='City'
                                    types={['(regions)']}
                                    componentRestrictions={{ country: 'us' }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    disabled
                                    placeholder='State'
                                    autoComplete='off'
                                    value={searchPickUpFromState}
                                    error={errors.searchPickUpFromState && searchPickUpFromState === ''}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder='Zip'
                                    value={searchPickUpFromZip}
                                    autoComplete='off'
                                    onChange={updateFromZipCodeForSearch}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    error={errors.searchPickUpFromZip && (searchPickUpFromZip === '' || searchPickUpFromZip.length !== 5)}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm></Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder='Miles Within'
                                    value={searchPickUpMiles}
                                    autoComplete='off'
                                    onChange={getNearestPickUpCities}
                                    style={{ marginTop: 7, width: 140 }}
                                    disabled={searchPickUpFromZip === ''}
                                />
                            }
                        </Col>
                        <Col sm></Col>
                        <Col sm></Col>
                    </Row>
                    <div style={{ height: 15 }} />
                    {/*----------Second row of the box which is for selecting pickup date For search-----------*/}
                    <Row>
                        <Col sm>Pick-Up Date :</Col>
                        <Col sm>
                            {
                                <MuiPickersUtilsProvider utils={DateFnsUtils} width={{ width: '100%' }}>
                                    <DatePicker
                                        id='pickup'
                                        selected={searchPickUpDate}
                                        minDate={new Date()}
                                        dateFormat='MM/dd/yyyy'
                                        autoComplete='off'
                                        onChange={handlePickUpDateChangeForSearch}
                                        className={'datepicker-Box'}
                                    />
                                </MuiPickersUtilsProvider>
                            }
                        </Col>
                        <Col sm ></Col>
                        <Col sm></Col>
                    </Row>
                    <div style={{ height: 15 }} />
                    <Divider />
                    <div style={{ height: 15 }} />
                    {/*----------Third row of the box which is for Deliver zip,state,city For search-----------*/}
                    <Row>
                        <Col sm>To :</Col>
                        <Col sm>
                            {
                                <Autocomplete
                                    id='AutoCompFromCity'
                                    style={{ height: 39, width: 140, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, }}
                                    value={searchDeliverToCity}
                                    onChange={(val) => handleOnChangeSearchForDeliverToPlace(val.target.value)}
                                    onPlaceSelected={(place) => {
                                        onDeliverToSelectedForSearch(place);
                                    }}
                                    placeholder='City'
                                    types={['(regions)']}
                                    componentRestrictions={{ country: 'us' }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    disabled
                                    placeholder='State'
                                    autoComplete='off'
                                    value={searchDeliverToState}
                                    error={errors.searchDeliverToState && searchDeliverToState === ''}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder='Zip'
                                    value={searchDeliverToZip}
                                    autoComplete='off'
                                    onChange={updateDeliverToZipCodeForSearch}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    error={errors.searchDeliverToZip && (searchDeliverToZip === '' || searchDeliverToZip.length !== 5)}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm></Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder='Miles Within'
                                    value={searchDeliveryMiles}
                                    autoComplete='off'
                                    disabled={searchDeliverToZip === ''}
                                    onChange={getNearestCitiesForDeliverCity}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm></Col>
                        <Col sm></Col>
                    </Row>
                    <div style={{ height: 15 }} />
                    {/*----------Forth row of the box which is for selecting deliver date For search-----------*/}
                    <Row>
                        <Col sm>Delivery Date :</Col>
                        <Col sm>
                            {
                                <MuiPickersUtilsProvider utils={DateFnsUtils} width={{ width: '100%' }}>
                                    <DatePicker
                                        id='pickup'
                                        selected={searchDeliverToDate}
                                        minDate={new Date()}
                                        dateFormat='MM/dd/yyyy'
                                        autoComplete='off'
                                        onChange={handleDeliverToDateChangeForSearch}
                                        className={'datepicker-Box'}
                                    />
                                </MuiPickersUtilsProvider>
                            }
                        </Col>
                        <Col sm ></Col>
                        <Col sm></Col>
                    </Row>
                    <div style={{ height: 20 }} />
                    <Divider />
                    <div style={{ height: 20 }} />
                    {/*----------Fifth row of the box which is for from Max weight-----------*/}
                    <Row>
                        <Col sm>Weight(lbs) :</Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder='Unit (lbs)'
                                    value={searchMaxWeight}
                                    error={(errors.searchMaxWeight && searchMaxWeight === '') || searchMaxWeight === '0'}
                                    autoComplete='off'
                                    helperText={searchMaxWeight === '0' ? 'Not Valid Input' : ''}
                                    inputProps={{ pattern: '[0-9]*', maxLength: 5 }}
                                    onChange={handelTxtMaxWeightChangeForSearch}
                                    style={{ width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm ></Col>
                        <Col sm ></Col>
                    </Row>
                    <div style={{ height: 20 }} />
                    {/*----------Sixth row of the box which is for from number of pices-----------*/}
                    <Row>
                        <Col sm>Number Of Pieces:</Col>
                        <Col sm>
                            {
                                <TextField value={searchNumberOfPices}
                                    helperText={searchNumberOfPices === '0' ? 'Not Valid Input' : ''}
                                    inputProps={{ pattern: '[0-9]*' }}
                                    error={searchNumberOfPices === '0'}
                                    autoComplete='off'
                                    onChange={handeltxtTotalNoOfPicesForSearch}
                                    style={{ width: 140 }} />
                            }
                        </Col>
                        <Col sm ></Col>
                        <Col sm></Col>
                    </Row>
                    <div style={{ height: 20 }} />
                </Grid>
                <Grid item sm={4}>
                    <div style={{ height: 5 }} />
                    <Grid>
                        <Row style={{ paddingLeft: 20 }}>
                            <Col>Truck Type:     {<input id='allTruckSearch' style={{ width: 17, height: 17, margin: 9 }} onChange={handleSelectAllCheckboxChange} type='checkbox' name='terms' />}Select All</Col>
                        </Row>
                    </Grid>
                    <div style={{ height: 3 }} />
                    <Grid container direction='row'>
                        <Grid>
                            <Col sm={12}>{<input type='checkbox' style={{ width: 17, height: 17, margin: 9 }} value='1' name='Sprinter/Cargo Van' id='Sprinter/CargoVanForSearch' color='primary' onChange={handleCheckBoxChangeForSearch} />}Sprinter/Cargo Van</Col>
                            <Col sm={12}>{<input type='checkbox' style={{ width: 17, height: 17, margin: 9 }} value='2' name='Small Straight' id='SmallStraightForSearch' color='primary' onChange={handleCheckBoxChangeForSearch} />}Small Straight</Col>
                            <Col sm={12}>{<input type='checkbox' style={{ width: 17, height: 17, margin: 9 }} value='3' name='Large Straight' id='LargeStraightForSearch' color='primary' onChange={handleCheckBoxChangeForSearch} />}Large Straight</Col>
                        </Grid>
                    </Grid>
                    <div style={{ height: 10 }} />
                </Grid>
                <div style={{ height: 10 }} />
                <Grid container item xs={12} justify='flex-end'>
                    {(window.location.pathname === '/loadboard' || window.location.pathname === '/activebids') && (userReduxData.userData.company_role === 'Carrier' || (userReduxData.userData.company_role === 'Broker&Carrier' && userReduxData.userData.user_role !== 'Broker')) && (
                        <Button variant='contained' onClick={ValidateSaveSearchLane} disabled={disableAddToLane} style={{ marginRight: 20, textTransform: 'none' }}>Save Lane</Button>
                    )}
                    <Button variant='contained' onClick={onSearchRestClick} style={{ marginRight: 20, textTransform: 'none' }}>Reset</Button>
                </Grid>
            </Grid>
        </div>
    );
};
export default forwardRef(SearchForm);
