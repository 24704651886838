import React, { useState, useEffect, useRef } from 'react';
import {
    CssBaseline,
    List,
    ListItem,
    Divider,
    IconButton,
    Typography,
    Grid,
    Paper,
    Snackbar,
    Box,
    Tabs,
    Tab,
    InputBase,
    Tooltip,
} from '@material-ui/core';
import { AcceptCounterOffer, ExpiredCounterOffer } from '../../components/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import cities from 'cities';
// import zipcodes from "zipcodes";
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import { toNumber } from 'lodash';
import { animateScroll } from 'react-scroll';

// Import statements for Chat Component
import Header from '../../components/CommentsComponent/Header/Header';
import HeaderChat from '../../components/CommentsComponent/HeaderChat/HeaderChat';
import Chat from '../../components/CommentsComponent/Chat/Chat';

// Imports to have all the Icons
import { ExpandMoreIcon } from '../../assets/SvgIcons/ExpandMore';
import { ExpandLessIcon } from '../../assets/SvgIcons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// Import statements for CSS and environment
import { environmentVariables } from '../../constants/environment';
import SearchForm from '../SearchForm';
import Navbar from '../../components/Navbar';
import MUIDrawer from '../../components/Drawer';
import LoadDetailsPannel from '../../components/LoadDetailsPannel';
import sortDate from '../../components/Sorting/sortDate';
import sortNum from '../../components/Sorting/sortNum';
import { getBidApiCall } from '../../apis/bids';
import { getCommentApiCall } from '../../apis/chat';
import { getExpiredBidsApiCall } from '../../apis/loads';

import { pascalCase, scrollTopOnRowClick } from '../../utils';
import useWebSocket, { } from 'react-use-websocket';

// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';
import { handleSocketReceiveMessage } from '../WebSocketConnection';
import { COLORS } from '../../theme/colors';
import './index.css';
//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    }
}));

// Function to handle MuiAlert
const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const ExpiredBid = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const dispatch = useDispatch();

    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();
    const SearchFormRef = useRef();
    const userReduxData = useSelector((state) => {
        return state.userReducer
    });
    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    // Constant to handle logged in user details
    const [userCompanyName, setUserCompanyName] = useState('');
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    /// Constant to handle expand and collapse the top pannel
    const [expandCollapseTopPannel, setExpandCollapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    // Constant to handel main grid percentage
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState('');
    const [searchPickUpFromState, setSearchPickUpFromState] = useState('');
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState('');
    const [searchPickUpMiles, setSearchPickUpMiles] = useState('');

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState('');
    const [searchDeliverToState, setSearchDeliverToState] = useState('');
    const [searchDeliverToZip, setSearchDeliverToZip] = useState('');
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState('');

    //constant to handle mui data table loader
    const [loadingExpiredBids, setLoadingExpiredBids] = useState(false);

    //constant to handle mui bid table loader
    const [loadingBids, setLoadingBids] = useState(false);

    // Constant to handle second muidatatble 
    const [rowDataBidTable, setRowDataBidTable] = useState([]);

    // Constants to handle Awarded Loads
    const [rowDataForBidHistory, setRowDataForBidHistory] = useState([]);
    const optionsForBidHistory = {
        filter: false,
        search: false,
        responsive: 'vertical',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex);
                }
            }
            const findChatResult = chatDetails.find(
                ({ load_id }) => load_id === row[0]
            );
            if (findChatResult !== undefined) {
                if (findChatResult.read_status) {
                    const findChatStatus = findChatResult.read_status.find(
                        ({ name }) => name === user.email
                    );
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValue.load_id !== row[0]) {
                        return {
                            style: { backgroundColor: '#DAF7A6' }
                        };
                    }
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loadingExpiredBids ?
                    <CircularProgress /> :
                    `There are no expired bids for ${pascalCase(user.nickname)}`
            },
        }
    };
    const columnDefs = [
        { label: 'PostId', name: 'load_id', options: { display: false } },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="bold">
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pickupfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return (
                        <div className="bold">
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Miles (Time)',
            name: 'cities_distance',
            options: {
                sort: true,
                display: displaySpecificColums, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== '') {
                                if (valEnteredNumber.includes(',')) {
                                    let splittingValue = valEnteredNumber.split(',');
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== 'NaN' && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Posting Company', name: 'posted_by_company', options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[9]
                    );
                }
            }
        },
        {
            label: 'Load ID',
            name: 'load_number',
            options: {
                // display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
        {
            label: 'Status', name: 'status', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        <div className='expiredBidStatus'>{rowuserdata.rowData[11].toUpperCase()}</div>
                    );
                }
            }
        },
    ];

    const [columnDefsForExpiredBid, setColumnDefsForExpiredBid] = useState(columnDefs);

    const getStatusForBidTables = (status) => {
        let newStatus;
        if (status === 'new') {
            newStatus = 'New';
        }
        else {
            newStatus = 'Closed';
        }
        return newStatus;
    };

    const columnDefsBidTable = [
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier Company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[11] === 'newoffer') && rowIndex.rowData[12] === 'New' && <p className="afterBidDisplay"> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'New' && <p className="afterBidDisplay"> ${rowIndex.rowData[13]} </p>}
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'Accept' && <p className="afterBidDisplay"> $ {rowIndex.rowData[13]}
                        </p>}
                    </>
                    );
                }
            },
        }, {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[5]);
                    return (
                        bidStatus
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[8] === '' || rowuserdata.rowData[8] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[8] !== '' || rowuserdata.rowData[8] !== undefined) && rowuserdata.rowData[8]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_Out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === '' || rowuserdata.rowData[9] === undefined) && <p className="afterBidDisplay"> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== '' || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[10] === 'LXWXH' && <p className="afterBidDisplay"> NA
                        </p>}
                        {rowuserdata.rowData[10] !== 'LXWXH' && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'New' && <p className="afterBidDisplay"> ${rowIndex.rowData[13]} </p>}
                        {(rowIndex.rowData[13] === undefined || rowIndex.rowData[13] === '') && <p className="afterBidDisplay"> NA </p>}
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'Accept' && <p className="afterBidDisplay"> $ {rowIndex.rowData[13]}
                        </p>}
                    </>
                    );
                }
            }
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[15];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[13];
                    let counterofferstatus = rowuserdata.rowData[12];
                    return (<>
                        {counteroffer === '' && counterofferstatus === 'New' && <p className="afterBidDisplay"> NA
                        </p>}
                        {counteroffer !== '' && counterofferstatus === 'New' && <p className="afterBidDisplay"> ${counteroffer}</p>}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && rowuserdata.rowData[5] === 'accepted' && <AcceptCounterOffer counteroffer={counteroffer} />}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && rowuserdata.rowData[5] !== 'accepted' && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                }
            },
        },
    ];

    const optionsBidTable = {
        filter: false,
        responsive: 'vertical',
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    // Constants for showing Loaddetails in the secondary pane
    const [loadLoadNotes, setLoadLoadNotes] = useState('');

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState('');
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState('');
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState('');
    const [chatFirstCompanyName, setChatFirstCompanyName] = useState('');
    const [chatAllMessages, setChatAllMessages] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);

    //--------------------------------------------*********************--------------------------
    //Function to scroll bottom
    const scrollToBottom = () => {
        animateScroll.scrollToBottom({});
    }

    //Function to scroll On recieveing Message
    const ScrollForParticularLoad = (recievedMessage) => {
        if (isPaneOpen === true) {
            if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                scrollToBottom();
            }
        }
        else {
            window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
            scrollToBottom();
        }
    }

    //--------------------------------------------------------------------------------------------------------
    // Adding values to the secondary pane
    //function to assign values on row click in userdetails table
    const handleRowClick = (selectedRowData, rowMeta) => {
        scrollTopOnRowClick(expandCollapseTopPannel)
        chatDetails.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        setMainGridPercent('35%');
        setIsPaneOpen(true);
        const findResult = rowDataForBidHistory.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        setSelectedRowDataValue(findResult);
        fetchAllComments(findResult);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        removeBackroundColour();
        // if (!window.localStorage.getItem('LoadId')) {
        //     let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
        //     row.setAttribute('style', 'background: #9FD2F3');
        // }
        setLoadLoadNotes(findResult.load_notes);
        loadAndRealoadSecondGrid(selectedRowData[0]);
        setDisplaySpecificColums(false);
    };

    const searchButtonClicked = () => {
        setExpandCollapseTopPannel(!expandCollapseTopPannel);
        // setTopTabPannelValue(1);
        setShowing(!showing);
        closeSecondaryPane();
    };

    const handleExpandMoreIconClick = () => {
        setTopTabPannelValue(0);
        setExpandCollapseTopPannel(false);
        localStorage.setItem('panelValue', 'false');
        closeSecondaryPane();
    };

    const handleExpandLessIconClick = () => {
        closeSecondaryPane();
        setExpandCollapseTopPannel(true);
        localStorage.setItem('panelValue', 'true');
    };

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === '') {
            const valForFilter = place.target.value;
            const filterColumns = [...columnDefsForExpiredBid];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === '') {
                filterColumns[10].options.filterList = [];
            }
            else {
                filterColumns[10].options.filterList = filterList;
            }
            setColumnDefsForExpiredBid(filterColumns);
        }
    };

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsForExpiredBid];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[1].options.filterList = filterList;
            setColumnDefsForExpiredBid(filterColumns);
        }
    };

    // function to get all nearby pickup cities within specified miles
    const getNearestPickUpCities = async (value) => {
        if (searchPickUpFromCity !== '') {
            setSnackBarMessageText('Data is loading for you....');
            setOpenSnackBar(true);
        }
        setSearchPickUpMiles(value);
        // var rad = zipcodes.radius(searchPickUpFromZip, value);
        let listvalues = [];
        // listvalues = rad
        // let count = 0
        // for (let i = 0; i < listvalues.length; i++) {
        //     if (listvalues[i] === searchPickUpFromZip) {
        //         count = count + 1
        //     }
        // }
        // if (count === 0) {
        //     listvalues.push(searchPickUpFromZip)
        // }
        try {
            let response = await axios({
                url:
                    environmentVariables.getnearbycities + '?zipList=' +
                    listvalues + 'functionality=pickupcitysearch',
                method: 'get',
            });
            if (response) {
                setOpenSnackBar(false);
                let filterList = [];
                const valForFilter = searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip;
                response.data.forEach(element => {
                    if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== 'Active') {
                        if (element.pickup_dest === valForFilter) {
                            filterList.unshift(element.pickup_dest);
                        }
                        else {
                            filterList.push(element.pickup_dest);
                        }
                    }
                });
                if (filterList.length > 0) {
                    const FilterColumns = [...columnDefsForExpiredBid];
                    FilterColumns[1].options.filterList = filterList;
                    setColumnDefsForExpiredBid(FilterColumns);
                }
                else {
                    const FilterColumns = [...columnDefsForExpiredBid];
                    let filterList = [];
                    filterList = [valForFilter];
                    FilterColumns[1].options.filterList = filterList;
                    setColumnDefsForExpiredBid(FilterColumns);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const filterColumns = [...columnDefsForExpiredBid];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[1].options.filterList = filterList;
                setColumnDefsForExpiredBid(filterColumns);
            }
        }
    };

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const filterColumns = [...columnDefsForExpiredBid];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[2].options.filterList = filterList;
            setColumnDefsForExpiredBid(filterColumns);
        }
        else {
            const filterColumns = [...columnDefsForExpiredBid];
            filterColumns[2].options.filterList = [];
            setColumnDefsForExpiredBid(filterColumns);
        }

    };

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsForExpiredBid];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[3].options.filterList = filterList;
            setColumnDefsForExpiredBid(filterColumns);
        }
    };

    // function to get all nearby deliver cities within specified miles
    const getNearestCitiesForDeliverCity = async (value) => {
        if (searchDeliverToCity !== '') {
            setSnackBarMessageText('Data is loading for you....');
            setOpenSnackBar(true);
        }
        setSearchDeliverMiles(value);
        // var rad = zipcodes.radius(searchDeliverToZip, value);
        let listvalues = [];
        // listvalues = rad
        // let count = 0
        // for (let i = 0; i < listvalues.length; i++) {
        //     if (listvalues[i] === searchDeliverToZip) {
        //         count = count + 1
        //     }
        // }
        // if (count === 0) {
        //     listvalues.push(searchDeliverToZip)
        // }
        try {
            let response = await axios({
                url:
                    environmentVariables.getnearbycities + '?zipList=' +
                    listvalues + 'functionality=delivercitysearch',
                method: 'get',
            });
            if (response) {
                setOpenSnackBar(false);
                let filterList = [];
                const valForFilter = searchDeliverToCity + ', ' + searchDeliverToState + ' - ' + searchDeliverToZip;
                response.data.forEach(element => {
                    if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== 'Active') {
                        if (element.delivery_dest === valForFilter) {
                            filterList.unshift(element.delivery_dest);
                        }
                        else {
                            filterList.push(element.delivery_dest);
                        }
                    }
                });
                if (filterList.length > 0) {
                    const FilterColumns = [...columnDefsForExpiredBid];
                    FilterColumns[3].options.filterList = filterList;
                    setColumnDefsForExpiredBid(FilterColumns);
                }
                else {
                    const FilterColumns = [...columnDefsForExpiredBid];
                    let filterList = [];
                    filterList = [valForFilter];
                    FilterColumns[3].options.filterList = filterList;
                    setColumnDefsForExpiredBid(FilterColumns);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const filterColumns = [...columnDefsForExpiredBid];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[3].options.filterList = filterList;
                setColumnDefsForExpiredBid(filterColumns);
            }
        }
    };

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const filterColumns = [...columnDefsForExpiredBid];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[5].options.filterList = filterList;
            setColumnDefsForExpiredBid(filterColumns);
        }
        else {
            const filterColumns = [...columnDefsForExpiredBid];
            filterColumns[5].options.filterList = [];
            setColumnDefsForExpiredBid(filterColumns);
        }
    };

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const filterColumns = [...columnDefsForExpiredBid];
        if (allTruckType && allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsForExpiredBid(filterColumns);
        }
        else {
            filterColumns[6].options.filterList = [];
            setColumnDefsForExpiredBid(filterColumns);
        }
    };

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        const filterColumns = [...columnDefsForExpiredBid];
        if (allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsForExpiredBid(filterColumns);
        }
        else {
            filterColumns[6].options.filterList = [];
            setColumnDefsForExpiredBid(filterColumns);
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const filterColumns = [...columnDefsForExpiredBid];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    filterColumns[7].options.filterList = [];
                }
                else {
                    filterColumns[7].options.filterList = filterList;
                }
                setColumnDefsForExpiredBid(filterColumns);
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const filterColumns = [...columnDefsForExpiredBid];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    filterColumns[8].options.filterList = [];
                }
                else {
                    filterColumns[8].options.filterList = filterList;
                }
                setColumnDefsForExpiredBid(filterColumns);
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const filterColumns = [...columnDefsForExpiredBid];
        switch (index) {
            case 1:
                {
                    filterColumns[1].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(2);
                    break;
                }
            case 2:
                {
                    filterColumns[2].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(3);
                    break;
                }
            case 3:
                {
                    filterColumns[3].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(4);
                    break;
                }
            case 5:
                {
                    filterColumns[5].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(6);
                    break;
                }
            case 6:
                {
                    const filterColumns = [...columnDefsForExpiredBid];
                    filterColumns[6].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(7);
                    break;
                }
            case 7:
                {
                    filterColumns[7].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(8);
                    break;
                }
            case 8:
                {
                    filterColumns[8].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(9);
                    break;
                }
            case 10:
                {
                    filterColumns[10].options.filterList = [];
                    setColumnDefsForExpiredBid(filterColumns);
                    SearchFormRef.current.onSearchClear(14);
                    break;
                }
            default:
                break;

        }
    };

    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    // Function to handle pane close
    const closeSecondaryPane = () => {
        window.localStorage.removeItem('LoadId');
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        setSelectedRowDataValue([]);
        removeBackroundColour();
        setDisplaySpecificColums(true);
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    //---------------------------- Place to handle all the functions ----------------------------
    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowDataForBidHistory.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute('style');
                if (styleObject !== null) {
                    if (styleObject.toString().includes('9FD2F3')) {
                        domObject.removeAttribute('style', 'background: #9FD2F3');
                    }
                }
            }

        }
    };

    // Function to check the user role
    const checkUserRole = () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === "ProfileAccepted") {
                if (userReduxData.userData.company_role === "Broker" || userReduxData.userData.user_role === "Broker" || userReduxData.userData.user_role === "Driver") {
                    history.push({ pathname: "/forbidden" });
                }
                if (userReduxData.userData.company_name) {
                    setUserCompanyName(userReduxData.userData.company_name);
                }
                if (userReduxData.userData.company_role) {
                    setLoggedInuserCompanyRole(userReduxData.userData.company_role)
                }
                if (userReduxData.userData.user_role) {
                    setLoggedInuserRole(userReduxData.userData.user_role)
                }
            }
            else {
                history.push({ pathname: "/forbidden" });
            }
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: '/callback' });
        }
    };

    // Function to fetch all the data bid history MuiDatatable
    const fetchBidHistoryValues = async () => {
        try {
            let response = await getExpiredBidsApiCall(user.email);
            if (response.length === 0) {
                setLoadingExpiredBids(false);
            }
            else {
                setLoadingExpiredBids(true);
            }
            //To delete loads 
            // Local array to handelremoval of rows
            response.sort((a, b) =>
                b.time_stamp.localeCompare(a.time_stamp)
            );
            let bidHistoryList;
            if (user.email === process.env.REACT_APP_ADMIN_USER) {
                bidHistoryList = response.filter(singleVal => {
                    return (singleVal['carrier_name'] !== ''
                        && singleVal['status'] === 'Closed');
                });
            }
            else {
                if (window.location.pathname === '/allexpiredbids') {
                    if (loggedInuserRole === 'CompanyAdmin') {
                        bidHistoryList = response.filter(singleVal => {
                            return (singleVal['carrier_company'] === userReduxData.userData.company_name
                                && singleVal['status'] === 'Closed');
                        });
                    }
                }
                else {
                    bidHistoryList = response.filter(singleVal => {
                        return (singleVal['carrier_name'] === user.email
                            && singleVal['status'] === 'Closed');
                    });
                }
            }
            setRowDataForBidHistory(bidHistoryList);
            removeBackroundColour();
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to load and reload bid details
    const loadAndRealoadSecondGrid = async (loadId) => {
        try {
            const getBidResponse = await getBidApiCall(loadId, "loadspecific");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                getBidResponse.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                let findBids = [];
                if (getBidResponse.length !== 0) {
                    setLoadingBids(true);
                }
                else {
                    setLoadingBids(false);
                }
                getBidResponse.forEach(element => {
                    if (element.carrier_name === user.email) {
                        findBids.push(element);
                    }
                    else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                        findBids.push(element);
                    }
                });
                if (findBids.length === 0) {
                    setLoadingBids(true);
                }
                setRowDataBidTable(findBids);
            }
            else {
                setRowDataBidTable([]);
                setLoadingBids(true);
            }
        }
        catch (error) {
            setRowDataBidTable([]);
        }
    }

    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    // ^^^^^^^^^^^^^^^^^^^^^^^Function to handle All the chat^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    const togetCarrierName = async (carrierName) => {
        let loadId = '';
        let userId = '';
        if (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier')) {
            loadId = selectedRowDataValue.load_id;
            userId = '&carrier_id=' + user.email;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                getCommentResponse.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        setChatAllMessages(element);
                        setChatFirstCarrierName(element.carrier_id);
                        setChatFirstCompanyName(element.carrier_company);
                        setChatCarrierMCNumber(`MC #${getCommentResponse[0]['carrier_mc_number']}`);
                    }

                });
            }
        }
        catch (error) { }
    }
    async function getSingleCarrierMessage(event) {
        if (event.target.id !== null && event.target.id !== '') {
            let carrierName = event.target.id;
            togetCarrierName(carrierName);
        }
        else {
            let carrierName = event.target.parentElement.id;
            togetCarrierName(carrierName);
        }
    }

    const fetchAllComments = async (loadInformation) => {
        let allChatResponse;
        let loadId = '';
        let userId = '';
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            loadId = loadInformation.load_id;
            userId = "&carrier_id=" + user.email;
        }
        else {
            loadId = loadInformation.load_id;
            userId = "&broker_id=" + loadInformation.posted_by_user;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                if (getCommentResponse[0] !== undefined) {
                    if (loadInformation.load_id === getCommentResponse[0].load_id) {
                        setChatAllMessages(getCommentResponse[0]);
                        if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === "Carrier" || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Carrier" || loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin"))) {
                            setChatFirstCarrierName(getCommentResponse[0]["broker_id"]);
                            setChatFirstCompanyName(getCommentResponse[0]["broker_company"])
                            setChatCarrierMCNumber(`MC #${getCommentResponse[0]["broker_mc_number"]}`)
                        }
                        setAllChat(getCommentResponse);
                    }
                    allChatResponse = getCommentResponse;
                }
            }
        }
        catch (error) {
            if (loggedInuserCompanyRole === "Carrier" || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Carrier" || loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin"))) {
                let tempAllChatArray = {
                    'Items': [{
                        'broker_id': loadInformation.posted_by_user,
                        'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss')
                    }]
                };
                setAllChat(tempAllChatArray);
                setChatFirstCarrierName(loadInformation.posted_by_user);
                setChatFirstCompanyName(loadInformation.posted_by_company);
                setChatCarrierMCNumber(`MC #${loadInformation.posted_by_mc_number}`);
                setChatAllMessages([]);
            }
        }
        return allChatResponse;
    }

    //------------------------- Place to define Socket related functions ------------------------------
    //Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        const recievedMessage = await handleSocketReceiveMessage(message, user.email);

        // receiving message for new notification
        if (recievedMessage.includes("ThereIsNewNotifiction")) {
            fetchBidHistoryValues();
        }

        if (recievedMessage.includes("BrokerLoadHasBeenExpired")) {
            fetchBidHistoryValues();
        }
    }

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    useEffect(() => {
        if (Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
        fetchBidHistoryValues();
    }, [userReduxData]);

    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} />
            <MUIDrawer />
            <main className={classes.content}>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor={COLORS.websiteColor}>
                                        <Tab className={`${classes.leftAlign}`} style={{ color: COLORS.websiteColor }} label="Search Load" onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Expand More">
                                        <IconButton button onClick={() => handleExpandMoreIconClick()} hidden={!expandCollapseTopPannel} >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Expand Less">
                                        <IconButton button onClick={() => handleExpandLessIconClick()} hidden={expandCollapseTopPannel} >
                                            <ExpandLessIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandCollapseTopPannel}>
                                <SearchForm isPaneOpen={isPaneOpen} closeSecondaryPane={closeSecondaryPane} ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPicesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: mainGridPercent, paddingBottom: '15%', display: 'table', tableLayout: 'fixed' }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'Expired Bids'}
                                    data={rowDataForBidHistory}
                                    columns={columnDefsForExpiredBid}
                                    options={optionsForBidHistory}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {
                            isPaneOpen && (
                                <React.Fragment>
                                    <div style={{ width: '1%' }}></div>
                                    <div style={{ width: '64%' }} border={1}>
                                        <Box
                                            boxShadow={3}>
                                            <Paper square className="square">
                                                <Grid container xs={12} justify="flex-end">
                                                    <IconButton onClick={closeSecondaryPane}>
                                                        <Tooltip title="Close">
                                                            <CloseIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Grid>
                                                <div style={{ height: 7 }} />
                                                <Row style={{ fontSize: 18 }}>
                                                    <Col sm>
                                                        <b>Load Details</b>
                                                    </Col>
                                                </Row>
                                                <div style={{ height: 5 }} />
                                                <LoadDetailsPannel allLoadDetails={selectedRowDataValue} />
                                            </Paper>
                                        </Box>
                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        {
                                            <React.Fragment>
                                                <div style={{ height: 10 }} />
                                                <MUIDataTable
                                                    title={'Bid Details'}
                                                    data={rowDataBidTable}
                                                    columns={columnDefsBidTable}
                                                    options={optionsBidTable}
                                                ></MUIDataTable>
                                                <div style={{ height: 10 }} />
                                            </React.Fragment>
                                        }
                                        <div style={{ height: 10 }} />
                                        {((loggedInuserCompanyRole === "Carrier" || userCompanyName === "SystemAdmin") || (loggedInuserCompanyRole === "Broker&Carrier" && (loggedInuserRole === "Broker&Carrier" || loggedInuserRole === "CompanyAdmin" || loggedInuserRole === "Carrier"))) && (
                                            <React.Fragment>
                                                <Divider />
                                                <Grid>
                                                    <Box boxShadow={3}>
                                                        <Paper square className="paper">
                                                            <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor="primary" textColor="primary">
                                                                <Tab label="Comments" />
                                                            </Tabs>
                                                            <TabPanel value={tabPannerlValue} index={0}>
                                                                <React.Fragment>
                                                                    <div className="homePage">
                                                                        <div className="leftSide">
                                                                            <Header />
                                                                            {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                            {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                <List className="Custom-MuiList-root" style={{ maxHeight: 'calc(100vh - 109px)', overflow: 'auto', objectFit: 'cover', height: 415 }}>
                                                                                    {
                                                                                        allChat
                                                                                            .sort(function (b, a) {
                                                                                                return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                            }).map((item) => {
                                                                                                return (
                                                                                                    <div key={item.broker_id}>
                                                                                                        <ListItem button alignItems="flex-start" id={item.broker_id} value={item.broker_id} className="Custom-MuiListItem-root" key={item.broker_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                            {
                                                                                                                (item.broker_id !== undefined) ?
                                                                                                                    <div id={item.broker_id} className="discussion">
                                                                                                                        <div id={item.broker_id} className="leftDiscussion">
                                                                                                                            {
                                                                                                                                (item.broker_id.charAt(0) !== undefined) && (
                                                                                                                                    <Avatar>{item.broker_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div id={item.broker_id} className="rightDiscussion">
                                                                                                                            <div id={item.broker_id} className="rightDiscussionTop">
                                                                                                                                <div id={item.broker_id} className="rightDiscussionTopLeft">{item.broker_company || selectedRowDataValue.posted_by_company}</div>
                                                                                                                            </div>
                                                                                                                            <div id={item.broker_id} className="rightDiscussionBas">
                                                                                                                                {
                                                                                                                                    (item.bid_amount !== undefined && item.bid_amount !== '$') ?
                                                                                                                                        <div className="rightDiscussionTopRight">{'Bid: ' + item.bid_amount}</div>
                                                                                                                                        :
                                                                                                                                        <div className="rightDiscussionTopRight">{'Bid: ' + '$' + selectedRowDataValue.bid_amount}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div id={selectedRowDataValue.posted_by_user} className="discussion">
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className="leftDiscussion">
                                                                                                                            {
                                                                                                                                (selectedRowDataValue.posted_by_user.charAt(0) !== undefined) && (
                                                                                                                                    <Avatar>{selectedRowDataValue.posted_by_user.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className="rightDiscussion">
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className="rightDiscussionTop">
                                                                                                                                <div id={selectedRowDataValue.posted_by_user} className="rightDiscussionTopLeft">{selectedRowDataValue.posted_by_company}</div>
                                                                                                                            </div>
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className="rightDiscussionBas">
                                                                                                                                {
                                                                                                                                    (selectedRowDataValue.bid_amount !== undefined && selectedRowDataValue.bid_amount !== '$') && (
                                                                                                                                        <div className="rightDiscussionTopRight">{'Bid: ' + selectedRowDataValue.bid_amount}</div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </ListItem>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                            )
                                                                                    }
                                                                                </List>
                                                                            )}
                                                                        </div>
                                                                        <div className="rightSide">
                                                                            {
                                                                                (chatFirstCarrierName !== undefined) ?
                                                                                    <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf('@'))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                    :
                                                                                    <HeaderChat name={selectedRowDataValue.posted_by_user.substring(0, selectedRowDataValue.posted_by_user.lastIndexOf('@'))} mcnumber={selectedRowDataValue.posted_by_mc_number} />
                                                                            }
                                                                            <Chat discussion={chatAllMessages} />
                                                                            <div>
                                                                                <form onKeyPress={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}>
                                                                                    <Paper component="form" className={classes.customPaper}>
                                                                                        <InputBase id="inputMesage" autoComplete='off' className={classes.CustomInput} style={{ width: '85%' }} placeholder="Enter your message" />
                                                                                        <IconButton>
                                                                                            <Tooltip title="Send">
                                                                                                <CloseIcon />
                                                                                            </Tooltip>
                                                                                        </IconButton>
                                                                                    </Paper>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            </TabPanel>
                                                        </Paper>
                                                    </Box>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main>
        </div>
    );
};

export default ExpiredBid;
