import React from 'react';
import './style.css';

const LogoComponent = () => {

    return (
        <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
            <div className="logo">
                <img
                    width="220"
                    height="48"
                    src="https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/Loadeo_logo_header.png"
                    alt=""
                />

            </div>
        </div>
    );
};
export default LogoComponent;
