import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SignIn } from '../../components/Button/Button';
import { useSelector } from 'react-redux';

import './index.css';
const styles = {
    header: {
        backgroundImage: `url(${'https://loadeo-company-logo-images.s3.amazonaws.com/404.png'})`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vh',
    },
    header1: {
        backgroundImage: `url(${'https://loadeo-company-logo-images.s3.amazonaws.com/401.png'})`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vh',
        marginLeft: '5%'
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};
//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let pathList = ['/expiredloads', '/awardedloads', '/admin', '/allloads', '/expiredbids',
    '/feedback', '/activeloads', '/activebids', '/bidswon', '/loadboard', '/prospectiveclients',
    '/systemadmincompanies', '/systemadmin', '/underverification', '/createprofile',
    '/useragreement', '/companysuspended', '/usersuspended', '/rejecteduser','/feedbackondriver',
    '/preferredcarrier', '/companyadmin', '/companyprofile', '/profile', '/updateprofile', '/emailsettings', '/settings',
    '/callback', '/privacypolicy', '/verify', '/savedlanes', '/mcverify', '/resetpassword', '/loadsassigned', '/loadspickedup', '/loadsdelivered', '/documents',
    '/allactivebids','/allbidswon','/allexpiredbids','/allsavedlanes','/dashboard'];
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const Page401AndPage404 = (props) => {
    const history = useHistory();
    const [count, setCount] = useState(0);

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    //Handle Constant
    const [match, setMatch] = useState(props.location.location.pathname);

    // Constant to handle logged in user role
    // const [userReduxData.userData.user_role, setuserReduxData.userData.user_role] = useState(window.localStorage.getItem('user_role'));

    // Constant to handle logged in Company name
    // const [userReduxData.userData.company_name, setuserReduxData.userData.company_name] = useState(window.localStorage.getItem('userReduxData.userData.company_name'));

    //function to Check Path is Valid
    const toValidate = () => {
        let pathCount = 0;
        pathList.forEach(element => {
            if (element === props.location.location.pathname && userReduxData.userData.user_role !== null && element !== '/resetpassword') {
                pathCount = pathCount + 1;
                setCount(pathCount);
            }
        });
        if (pathCount === 0 && userReduxData.userData.user_role !== null && match !== '/resetpassword') {
            setMatch('');
            setCount(pathCount);
        }
    };
    //function to Return Home Page
    function handleHome() {
        if (userReduxData.userData.company_name !== 'SystemAdmin' && userReduxData.userData.company_name !== 'SupportPage') {
            setMatch('/loadboard');
            history.push({ pathname: '/loadboard' });
        }
        else {
            if (userReduxData.userData.company_name === 'SystemAdmin') {
                setMatch('/admin');
                history.push({ pathname: '/admin' });
            }
            else {
                setMatch('/mcverify');
                history.push({ pathname: '/mcverify' });
            }

        }
    }

    useEffect(() => {
        toValidate();
    }, []);

    /******************************************************************************************************/
    return (
        <main>
            {
                (count === 0 && match === '') ?
                    <section id='head' className='d-flex align-items-center'>
                        <div className='container'>
                            <div className='row '>
                                <div className='col-md-6'>
                                    <div className='head_content'>
                                        <h1>OOPS!</h1>
                                        <h2>PAGE NOT FOUND</h2>
                                        <p>Sorry, the page you're looking for doesn't exist.</p>
                                        <div className='head_btn'>
                                            {
                                                (userReduxData.userData.user_role === null && userReduxData.userData.company_name !== 'SystemAdmin') ?
                                                    <div>
                                                        <button className='button1' onClick={() => history.push({ pathname: '/' })}>
                                                            Return Home
                                                        </button>
                                                        <SignIn />
                                                    </div>
                                                    :
                                                    <button className='button1' onClick={() => handleHome()}>
                                                        Return Home
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='head_img'>
                                        <div style={styles.header}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    (match !== '' && count !== 0 && userReduxData.userData.user_role === null && props.user === undefined && userReduxData.userData.company_name !== 'SystemAdmin' && userReduxData.userData.company_name !== 'SupportPage') && (
                        history.push({ pathname: '/notauthenticated' })
                    )
            }
        </main>
    );
};

export default Page401AndPage404;