import { GET_SUCCESS } from './ActionType';


let initialState = {
    userData: {},
    ifUserExist: true,
};

export let userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUCCESS:
            return {
                ...state,
                userData: action.payload
            };
        // case IF_USER_EXIST : 
        // return {
        //     ...state,
        //     ifUserExist : action.payload
        // }
        default:
            return state;
    }
};