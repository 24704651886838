import React from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import './style.css';
import Swal from 'sweetalert2';
import Notifications from '../../module/Notifications';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/users/Action';
import { COLORS } from '../../theme/colors';

const Header = (props) => {

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const dispatch = useDispatch();

    const history = useHistory();
    const { user, logout, loginWithRedirect, isAuthenticated } = useAuth0();

    /* hidden feature temperorily */
    // const feedback = () => {
    //   history.push({ pathname: '/feedback' })
    // };

    // function to handle sign in button click
    const handleSignInUser = () => {
        loginWithRedirect();
        window.localStorage.removeItem('LoadId');
    };

    // function to handle signout button click
    async function logoutWithRedirect() {
        Swal.fire({
            text: 'Are you sure you want to sign out?',
            cancelButtonColor: COLORS.websiteColor,
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: COLORS.websiteColor
        }).then(async (result) => {
            if (result.value) {
                window.localStorage.removeItem('panelValue');
                window.localStorage.removeItem('ifUserExist');
                window.localStorage.removeItem('userName');
                window.localStorage.removeItem('assignedLoadId');
                window.localStorage.removeItem('drawerValue');
                dispatch(setUser({}));
                logout({
                    returnTo: window.location.origin,
                });
            }
        });
    }

    const handleOpenCertificationForm = () => {
        history.push('/documents');
    };

    return (
        <header>
            <div className='header-style-1' style={{ height: 70 }}>
                <div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-10 col-sm-10 col-8 col-t'>
                            <div className='logo'>
                                <Link to='/#' style={{ marginLeft: 50 }}>

                                    <img
                                        width='220'
                                        height='48'
                                        src='https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/Loadeo_logo_header.png'
                                        alt=''
                                        className='imgsize'
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-8 d-none d-lg-block col-m'>
                            <div className='main-menu'>
                                <nav className='nav_mobile_menu'>
                                    <ul>
                                        {
                                            isAuthenticated && (!userReduxData.userData.mc_certified || userReduxData.userData.mc_certified !== 'Verified') && (userReduxData.userData.company_role === 'Carrier' && userReduxData.userData.user_role !== 'Driver') && (user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                                <Button disabled={userReduxData.userData.user_role !== 'CompanyAdmin'} onClick={() => handleOpenCertificationForm()} style={{ textTransform: 'none', color: COLORS.bananaYellow, marginTop: '1%' }}>Click Here to Verify your Account</Button>
                                            )
                                        }
                                        {/* {
                                            (isAuthenticated && user.email !== 'support.team@loadeo.com') && (
                                                <li style={{ paddingBottom: 60 }}>
                                                    <Notifications closeSecondaryPane={props.closeSecondaryPane} notificationSent={props.notificationSent} />
                                                </li>
                                            )
                                        } */}
                                        {/* {
                                            !isAuthenticated || isAuthenticated && (
                                                <li onClick={() => feedback()}><Link style={{ textTransform: 'none', fontSize: 16 }}>Feedback</Link></li>
                                            )
                                            } */}
                                        {
                                            !isAuthenticated && (
                                                <li onClick={() => loginWithRedirect({ action: 'signUp' })}><Link className='signOutSettings'> Create New Account</Link></li>
                                            )
                                        }
                                        {isAuthenticated ? (
                                            <li><Link style={{ textTransform: 'capitalize', fontSize: 16 }} >{user.nickname}</Link>
                                                <ul className='submenu'>
                                                    {/* // Removing Update profile as it is not modified
                          <li><Link to='/updateprofile'>Update Profile</Link></li> */}
                                                    {(user.email !== 'support.team@loadeo.com' && user.email !== 'admin@loadeo.com') && (
                                                        <li onClick={() => history.push({ pathname: '/settings' })}><Link to='/profile' className='signOutSettings' >Settings</Link></li>
                                                    )}
                                                    <li onClick={() => logoutWithRedirect()}><Link className='signOutSettings'>Sign Out</Link></li>

                                                </ul>
                                            </li>

                                        ) : (
                                            <li onClick={() => handleSignInUser()}>
                                                <Link className='signOutSettings'>Sign In</Link>
                                            </li>
                                        )}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className='col-md-1 col-sm-1 col-2'>
                            <MobileMenu />
                        </div>
                        {/* {
                (windowOpen) && (
                 
                )
              } */}
                    </div>
                </div>
            </div>
        </header >
    );
};
export default Header;
