import { environmentVariables } from '../../constants/environment';
import axios from 'axios';
import slugid from 'slugid';

//function to fetch data with Matched Lane Details
export const getMatchedLoad = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getMatchedLane +
                '?carrier_name=' +
                user,
            method: 'get'
        });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

//Function to update Count for particular Lane If Saved Lane Match
export const updateCount = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.updateDate,
            method: 'patch',
            data: listValues
        });
        return response;
    }
    catch (error) {
        console.log('update date error', error);
    }
};

// Function to fetch all the data main MuiDatatble
export const reduceCountOnLoadExpire = async (value, user) => {
    try {
        let response = await getMatchedLoad(user);
        if (response) {
            //To reduce lane count 
            let data = JSON.parse(response.data.body);
            if (user !== process.env.REACT_APP_ADMIN_USER) {
                data.forEach(Lane => {
                    if (Lane.delete_lane === false) {
                        if ((Lane.pickup_dest === value.pickup_dest && Lane.pick_upfrom_date === value.pickupfrom_date) || (Lane.delivery_dest === value.delivery_dest && Lane.pickupto_date === value.pickupto_date)) {
                            if (Lane.load_id === value.load_id) {

                                let reduceCount;
                                if (Lane.matched_lane !== '0') {
                                    let count = parseInt(Lane.matched_lane);
                                    reduceCount = count - 1;
                                }
                                else {
                                    reduceCount = Lane.matched_lane;
                                }
                                let listValues =
                                {
                                    'pick_upfrom_date': Lane.pick_upfrom_date,
                                    'pickup_dest': Lane.pickup_dest,
                                    'delivery_dest': Lane.delivery_dest,
                                    'pickupto_date': Lane.pickupto_date,
                                    'carrier_name': Lane.carrier_name,
                                    'max_weight': Lane.max_weight,
                                    'total_item_count': Lane.total_item_count,
                                    'truck_type': Lane.truck_type,
                                    'miles_from': Lane.miles_from,
                                    'miles_to': Lane.miles_to,
                                    'lane_id': Lane.lane_id,
                                    'lane_number': Lane.lane_number,
                                    'delete_lane': false,
                                    'matched_lane': reduceCount.toString(),
                                    'load_id': Lane.load_id,
                                    'time_stamp': Lane.time_stamp,
                                    'carrier_company': Lane.carrier_company
                                };
                                updateCount(listValues);
                            }

                        }
                    }
                });
            }
        }
    }
    catch (error) {
        console.log(error, 'Reduce Count');
    }
};

//Function To send Matched Lane Email
export const sendEmailForMatchedLane = async (list, loadDimension, LaneCarrierName) => {
    try {
        let email = await axios({
            url: environmentVariables.searchLaneEmail,
            method: 'post',
            data: {
                posted_by_company: list.posted_by_company,
                load_id: list.load_id,
                pickupfrom_date: list.pickupfrom_date,
                pickupto_date: list.pickupto_date,
                max_weight: list.max_weight,
                pickup_dest: list.pickup_dest,
                delivery_dest: list.delivery_dest,
                posted_by_user: list.posted_by_user,
                posted_comp_mc_number: list.posted_by_mc_number,
                loadUrl: window.location.origin + '/loadboard?loadId=' + slugid.encode(list.load_id),
                equipment_needed: list.truck_type,
                load_dimension: loadDimension,
                load_number: list.load_number,
                load_notes: list.load_notes,
                Lane_carrier_name: LaneCarrierName
            },
        });
        console.log(email, 'lane Email');
        return email;
    }
    catch (error) {
        console.log(error);
    }
};

//Function to send Email for Load Posted
export const sendEmailForLoadPosted = (emailResponse, loadDimension, email, loggedInCompanyMCNumber) => {
    try {
        //call api to send email
        let LoadMail = axios({
            url: environmentVariables.createloadnotification,
            method: 'post',
            data: {
                posted_by_company: emailResponse.posted_by_company,
                load_id: emailResponse.load_id,
                pickupfrom_date: emailResponse.pickupfrom_date,
                pickupto_date: emailResponse.pickupto_date,
                max_weight: emailResponse.max_weight,
                pickup_dest: emailResponse.pickup_dest,
                delivery_dest: emailResponse.delivery_dest,
                posted_by_user: email,
                posted_comp_mc_number: loggedInCompanyMCNumber,
                loadUrl: window.location.origin + '/loadboard?loadId=' + slugid.encode(emailResponse.load_id),
                equipment_needed: emailResponse.truck_type,
                load_dimension: loadDimension,
                load_number: emailResponse.load_number,
                load_notes: emailResponse.load_notes
            }
        });
        return LoadMail;
    }
    catch (error) {
        console.log('send email for Load Posted Failed', error);
    }
};

//function to send Email for Bid Placed
export const sendEmailForCounterOffer = async (selectedRowDataValue, loadFreightDimension, counterOffer, loggedInuserCompanyRole, loggedInuserRole, loadPostedByUser, user) => {
    try {
        let maillist = selectedRowDataValue;
        maillist = { ...maillist, 'posted_by_user': (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && loggedInuserRole !== 'Broker' && selectedRowDataValue.posted_by_user !== user.email)) ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name };
        maillist = { ...maillist, 'counter_offer': counterOffer };
        maillist = { ...maillist, 'load_notes': selectedRowDataValue.load_notes };
        maillist = { ...maillist, 'load_dimension': loadFreightDimension };
        maillist = { ...maillist, 'load_posted_by': selectedRowDataValue.posted_by_user };
        maillist = { ...maillist, 'loadUrl': window.location.origin + '/loadboard?loadId=' + slugid.encode(selectedRowDataValue.load_id) };
        maillist = { ...maillist, 'name': loadPostedByUser !== user.email ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name };
        maillist = { ...maillist, 'bid_amount': selectedRowDataValue.bid_amount };
        maillist = { ...maillist, 'bid_Notes': selectedRowDataValue.bid_notes };
        maillist = { ...maillist, 'miles_Out': selectedRowDataValue.miles_out };
        maillist = { ...maillist, 'truck_dimension': selectedRowDataValue.truck_dimension };
        let mailForCounterOffer = await axios({
            url: environmentVariables.counteremail,
            method: 'post',
            data: maillist
        });
        return mailForCounterOffer;
    }
    catch (error) {
        console.log('send email for Counter Offer Submitted Failed', error);
    }
};

//function to send Email for Bid Placed
export const sendEmailForAcceptCounterOffer = async (selectedRowDataValue, loadFreightDimension, counterOffer, loggedInuserCompanyRole, loggedInuserRole, loadPostedByUser, user) => {
    try {
        let maillist = selectedRowDataValue;
        maillist = { ...maillist, 'counter_offer': counterOffer };
        maillist = { ...maillist, 'load_notes': selectedRowDataValue.load_notes };
        maillist = { ...maillist, 'load_dimension': loadFreightDimension };
        maillist = { ...maillist, 'load_posted_by': selectedRowDataValue.posted_by_user };
        maillist = { ...maillist, 'loadUrl': window.location.origin + '/loadboard?loadId=' + slugid.encode(selectedRowDataValue.load_id) };
        maillist = { ...maillist, 'name': loadPostedByUser !== user.email ? selectedRowDataValue.posted_by_user : selectedRowDataValue.carrier_name };
        maillist = { ...maillist, 'bid_amount': selectedRowDataValue.bid_amount };
        maillist = { ...maillist, 'bid_Notes': selectedRowDataValue.bid_notes };
        maillist = { ...maillist, 'miles_Out': selectedRowDataValue.miles_out };
        maillist = { ...maillist, 'truck_dimension': selectedRowDataValue.truck_dimension };
        maillist = { ...maillist, 'carrier_name': selectedRowDataValue.carrier_name };
        maillist = { ...maillist, 'posted_by_user': selectedRowDataValue.posted_by_user };

        let mailForAcceptCounterOffer = await axios({
            url: environmentVariables.acceptcounteroffernotification,
            method: 'post',
            data: maillist
        });
        return mailForAcceptCounterOffer;
    }
    catch (error) {
        console.log('send email for Counter Offer Accepted Failed', error);
    }
};
