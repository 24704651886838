import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoComponent from '../LogoComponent';
import { Grid, Paper, Box, Button, TextField } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useHistory } from 'react-router';
import { sendEmailAddressToAdminApiCall } from '../../apis/emailtoAdmin';
import Swal from 'sweetalert2';
import '../../index.css';
import './index.css';
const styles = {
    header: {
        backgroundImage: `url(${'https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/01-Login-truck.png'})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

const Verify = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const history = useHistory();
    const [emailAddress, setEmailAddress] = useState("");
    const [formOpen, setFormOpen] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    //Function to send email address to admin
    const handleStoringAddress = async () => {
        try {
            let response = await sendEmailAddressToAdminApiCall(emailAddress);
            if (response !== undefined) {
                setFormOpen(false);
                Swal.fire({
                    text: 'Verification is Under Process, Please try Signing in after sometime',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }).then((result) => {
                    if (result.value) {
                        history.push({ pathname: '/' });
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    //function to handle Open email form
    const handleOpen = (event) => {
        setFormOpen(event);
        setEmailAddress('');
    };

    //function to handle Close email form
    const handleClose = () => {
        setFormOpen(false);
    };
    //function to handle change in email address
    const handleKeyPress = (event) => {
        if (event.key !== 'Enter') {
            setEmailAddress(event.target.value);
        }
        else {
            event.preventDefault();
            handleClose();
        }
    };

    return (
        <div>
            {isLoading && <h2>Loading..</h2>}
            {!isAuthenticated &&
                <React.Fragment>
                    {
                        <div style={styles.header}>
                            <div style={styles.content}>
                                <br />
                                <LogoComponent />
                                <br />
                                <h2 className='commonh2'>Step 1/4</h2>
                                <Grid container direction="column" alignItems="center" justify="center" className='minHeight'>
                                    <Grid container xs={12} sm={6} alignItems="center" direction="column" className='height'>
                                        <Paper elevation={3} className='verificationPaper'>
                                            <Grid container direction="row">
                                                <div className='commonDiv' />
                                                <div className='commonBox'>
                                                    <Box paddingRight={5} paddingLeft={5} className='commonBox'>
                                                        <h2><b>Verify Your Email</b></h2>
                                                    </Box>
                                                </div>
                                                <div className='commonDiv' />
                                                <div className='commonBox'>
                                                    <Box paddingRight={5} paddingLeft={5} className='commonBox'>
                                                        {/*<p>We have sent you an email to <b>{user.email}</b> </p>*/}
                                                        <p>We have sent you an email</p>
                                                    </Box>
                                                </div>
                                                <div className='commonBox'>
                                                    <Box paddingRight={5} paddingLeft={5} className='commonBox'>
                                                        <p>You need to verify your email to continue.</p>
                                                    </Box>
                                                </div>
                                                <div className='commonBox'>
                                                    <Box paddingRight={5} paddingLeft={7} className="wdithForVerify">
                                                        <p>If you have not received the verification email, please <span className='commonBox'>check your "Spam" or "Bulk Email" folder.</span></p>
                                                    </Box>
                                                </div>
                                                <div className='commonBox'>
                                                    <Button className="commonButton" id="bottom" variant="contained" size="medium" onClick={() => handleOpen(true)}>
                                                        Contact Admin
                                                    </Button>
                                                </div>
                                                <Dialog className='dialogForVerify'
                                                    open={formOpen}
                                                    fullWidth={fullWidth}
                                                    maxWidth={maxWidth}
                                                    onClose={handleClose}
                                                >
                                                    <DialogTitle className='paddingBottomForVerify' >Email Address</DialogTitle>
                                                    <DialogContent className='paddingBottomForVerify'>
                                                        <Box
                                                            noValidate
                                                            component="form"
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                m: 'auto',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className='paddingForVerify'>
                                                                <TextField
                                                                    placeholder="example@gmail.com"
                                                                    value={emailAddress}
                                                                    size="large"
                                                                    autoComplete='off'
                                                                    onChange={handleKeyPress}
                                                                    className='wdithForVerify'
                                                                />
                                                            </div>
                                                        </Box>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <div className='dialogButtontoVerify'>
                                                            <Button onClick={() => {
                                                                handleStoringAddress();
                                                            }}>
                                                                Send
                                                            </Button>
                                                            <Button onClick={handleClose}>
                                                                Close
                                                            </Button>
                                                        </div>
                                                    </DialogActions>
                                                </Dialog>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                </React.Fragment>
            }
        </div>
    );
};

export default Verify;