import React from 'react';
import './Message.css';
import { useAuth0 } from '@auth0/auth0-react';

export default function Message(props) {
    const { user } = useAuth0();
    return (
        <div className={props.me === 'true' ? props.type === 'System Message' ? 'messageMeBid' : 'messageMe' : props.type === 'System Message' ? 'messageYouBid' : 'messageYou'}>
            {(props.user === user.nickname) ?
                <div className="ownUser">{props.user}</div>
                :
                <div className="user">{props.user}</div>
            }
            {props.message}
            <div className="Date">{props.date}</div>
        </div>
    );
}
