import React from 'react';

// components
import Navbar from '../../components/Navbar';
import SimpleSlider from '../../components/hero';
import Footer from '../../components/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';

const Homepage = () => {
    const history = useHistory();
    const { isAuthenticated } = useAuth0();

    function handleRedirect() {
        history.push({ pathname: '/callback' });
    }
    return (
        <div>
            {isAuthenticated ?
                (
                    <React.Fragment>
                        <div>
                            {handleRedirect()}
                        </div>
                    </React.Fragment>
                )
                :
                (
                    <React.Fragment>
                        <Navbar />
                        <SimpleSlider />
                        {/*<FloatingButtonForFeedBack />*/}
                        <Footer />
                    </React.Fragment>
                )
            }

        </div>
    );
};

export default Homepage;