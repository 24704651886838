import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoComponent from '../../features/LogoComponent';
import { Grid, Paper, Button, Box, Avatar } from '@material-ui/core';
import './index.css';

const styles = {
    header: {
        backgroundImage: `url(${'https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/01-Login-truck.png'})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    content: {
        height: '0%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

const SuspendedUser = () => {

    const { logout } = useAuth0();

    const homePage = () =>{
        logout({
            returnTo: window.location.origin,
        });
    };

    return (
        <div style={styles.header}>
            <div style={styles.content}>
                <br />
                <LogoComponent />
                <br />
                <Grid container direction="column" alignItems="center" justify="center" className = 'minHeight'>
                    <Grid container xs={12} sm={6} alignItems="center" direction="column" className = 'height'>
                        <Paper elevation={3} className='verificationPaper'>
                            <Grid container direction="row">
                                <div className='commonDiv'/>
                                <div className='commonBox'>
                                    <Box paddingRight={5} paddingLeft={5} className='commonBox'>
                                        <Avatar alt="Remy Sharp" className= 'verficationAvatar' src="https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/acc_verification.png" />
                                    </Box>
                                </div>
                                <div className='commonDiv'/>
                                <div className='commonBox'>
                                    <Box paddingRight={5} paddingLeft={5} className='commonBox'>
                                        <h2><b>Account Suspended</b></h2>
                                    </Box>
                                </div>
                                <div className='commonDiv' />
                                <div className='commonBox'>
                                    <Box paddingRight={5} paddingLeft={5} className='commonBox'>
                                        <p className='pargraphStyleForSuspend'> Your account has been Suspended. Please contact<span className='commonBox'> Company Admin for more information </span></p>
                                    </Box>
                                </div>
                                <div className='commonDiv' />
                                <div className='commonBox'>
                                    <Button variant="contained" onClick={homePage} className = 'commonButton'> Ok</Button>
                                </div>
                                <div className='commonDiv' />
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
};

export default SuspendedUser;