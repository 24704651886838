import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

{/* ************************ Lane API calls ************************* */ }
export const saveLaneApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.savesearch,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'Error in saving a lane');
    }
};

export const getMatchedLoadApiCall = async (user,date) => {
    try {
        let response = await axios({
            url: environmentVariables.getMatchedLane+`?query=${user}&date=${date}`,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error,'hello');
    }
};