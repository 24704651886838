import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './style.css';
import Swal from 'sweetalert2';
import { useAuth0 } from '@auth0/auth0-react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/users/Action';
import { COLORS } from '../../theme/colors';

const MobileMenu = () => {
    const [isMenuShow, setIsMenuShow] = useState(false);

    const { user, logout, loginWithRedirect, isAuthenticated } = useAuth0();

    const dispatch = useDispatch();

    const logoutWithRedirect = () => {
        Swal.fire({
            text: 'Are you sure you want to sign out?',
            cancelButtonColor: COLORS.websiteColor,
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: COLORS.websiteColor
        }).then((result) => {
            if (result.value) {
                window.localStorage.removeItem('ifUserExist');
                window.localStorage.removeItem('panelValue');

                dispatch(setUser({}));
                //   window.localStorage.removeItem('company_role');
                //   window.localStorage.removeItem('company_mc_number');
                //   window.localStorage.removeItem('login_status');
                //   window.localStorage.removeItem('user_role');
                //   window.localStorage.removeItem('userCompanyName');
                logout({
                    returnTo: window.location.origin,
                });
            }
        });
    };
    return (
        <div>
            <PerfectScrollbar >
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <ul className="responsivemenu">
                        {
                            !isAuthenticated && (
                                <li onClick={() => loginWithRedirect({ action: 'signUp' })}><Link> Create New Account</Link></li>
                            )
                        }
                        {isAuthenticated ? (
                            <li><Link to="/#" >{user.nickname}</Link>
                                <ul className="submenu">
                                    {/* // Removing Update profile as it is not modified
                                    <li><Link to="/updateprofile">Update Profile</Link></li> */}
                                    <li onClick={() => logoutWithRedirect()}><Link to="/#" >Sign out</Link></li>
                                </ul>
                            </li>

                        ) : (
                            <li onClick={() => loginWithRedirect()}>
                                <Link to="/#" >Sign In</Link>
                            </li>
                        )}
                    </ul>
                </div>
            </PerfectScrollbar>
            <div className="showmenu" onClick={() => setIsMenuShow(!isMenuShow)}><i className="fa fa-bars" aria-hidden="true"></i></div>
        </div>
    );
};

export default MobileMenu;