import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

{/* ************************ Web Socket API calls ************************* */ }

export const webSocketFetchUsersApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.websocketfetchallusers,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error);
    }
};