import React from 'react';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import FeedbackUser from '../../module/feedback';

const FeedbackUserPage = () => {
    return (
        <div>
            <Navbar />
            <br />
            <FeedbackUser />
            <Footer />
        </div>
    );
};

export default FeedbackUserPage;