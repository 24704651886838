import React from 'react';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import FeedbackOnDriverUser from '../../module/FeedBackForDriver';

const FeedbackOnDriverUserPage = () => {
    return (
        <div>
            <Navbar />
            <br />
            <FeedbackOnDriverUser />
            <Footer />
        </div>
    );
};

export default FeedbackOnDriverUserPage;