import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from 'react';
import Modal from "@material-ui/core/Modal";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 80 + rand();
  const left = 55 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    width: 400,
    border: "0px solid #000",
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(10);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (props.showModal === false) {
      setProgress(100)
    }
    setOpen(props.showModal);
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 90 ? 90 : prevProgress + 10));
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [props.showModal]);

  function CircularProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h4 id="simple-modal-title">Verification is in progress...</h4>
          <CircularProgressWithLabel value={progress} />
    </div>
  );

  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
          Open Modal
        </button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
      >
        {body}
      </Modal>
    </div>
  );
}