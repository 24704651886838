import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CssBaseline,
    InputLabel,
    Typography,
    Grid,
    MenuItem,
    TextField,
    Select,
    TextareaAutosize,
    IconButton,
    Snackbar,
    Tooltip,
    Box,
    Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Row, Col, Image, Form } from 'react-bootstrap';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import { useAuth0 } from '@auth0/auth0-react';
import { uploadFile } from 'react-s3';
import * as AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import FormControlMUI from '@material-ui/core/FormControl';
import MaskedInput from 'react-text-mask';
import useWebSocket, { } from 'react-use-websocket';
import { pascalCase } from '../../utils/index';
import { getAllUserInformationApiCall } from '../../apis/user';
import { getparticularcompanyinformationApiCall, getApprovedUserDetailsForCompanyApiCall, updateCompanyProfileApiCall } from "../../apis/companies"
import { useDispatch, useSelector } from 'react-redux';

// Imports to have all the Icons
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Import statements for CSS and environment
import { environmentVariables } from '../../constants/environment';
import MUIDrawer from '../../components/Drawer'
import './index.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    bottomTool: {
        marginBottom: 91,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: 'auto'
    }
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const S3_BUCKET = 'loadeo-company-logo-images';
const DIRECTORY = 'company_logos';
const REGION = 'us-east-1';
const ACCESS_KEY = 'AKIAT6WHWU3YF7CIHQP7';
const SECRET_ACCESS_KEY = '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';

const s3 = new AWS.S3({
    secretAccessKey: SECRET_ACCESS_KEY, accessKeyId: ACCESS_KEY, region: REGION
});

const config = {
    bucketName: S3_BUCKET,
    dirName: DIRECTORY,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
};

const PhoneNumberInput = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}

PhoneNumberInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
};


const CompanyProfile = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth0();
    const userReduxData = useSelector((state) => {
        return state.userReducer
    });
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    const [loggedInUserRole, setLoggedInUserRole] = useState('');
    const [emailList, setEmailList] = useState('');

    //constant to handle logged in user company mc number
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState(userReduxData.userData.company_mc_number);

    // Company Info Variables
    const [companyName, setCompanyName] = useState('');
    const [companyMCNumber, setCompanyMCNumber] = useState(location.mcNumber);
    const [companyID, setCompanyID] = useState('');
    const [companyRole, setCompanyRole] = useState('');
    const [companyDescription, setCompanyDescription] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyState, setCompanyState] = useState('');
    const [companyEmailAddress, setCompanyEmailAddress] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [companyStatus, setCompanyStatus] = useState('');
    const [companyDisabledCompanies, setCompanyDisabledCompanies] = useState([]);

    const [selectedCompanyLogo, setSelectedCompanyLogo] = useState(null);
    const [selectedCompanyLogoName, setSelectedCompanyLogoName] = useState('');
    const [previewCompanyLogo, setPreviewCompanyLogo] = useState('');
    const [editCompanyDetails, setEditCompanyDetails] = useState(false);
    const [deleteCompanyLogo, setDeleteCompanyLogo] = useState(false);
    const [oldMCNumber, setOldMCNumber] = useState(location.mcNumber);
    const [mcNumberValid, setMCNumberValid] = useState(true);
    const [tempCompanyLogo, setTempCompanyLogo] = useState('');
    const [isDetailsOpen,setIsDetailsOpen] = useState(false);
    const [defaultLogo,setDefaultLogo] = useState("https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/default_company_logo.png");
    const [errorMessage, setErrorMessage] = useState('');
    const [isFieldEdited, setIsFieldEdited] = useState(false);
    const [changedValue, setChangedValue] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState([]);

    // Constant to handle socket connections
    // const {
    //     sendJsonMessage,
    // } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
    //     {
    //         shouldReconnect: (closeEvent) => true,
    //     });

    useEffect(() => {
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            if (!location.mcNumber) {
                history.push({ pathname: '/systemadmin' });
            }
        }
        getUserMcNumber();
    }, [location.mcNumber ? location.mcNumber : companyMCNumber, history, user.email, location.mcNumber]);

    // Function to handle send Socket IO messages
    const sendMessageForSocketIO = async (message, recieverId) => {
        await axios({
            url: environmentVariables.websocketfetchallusers,
            method: 'get'
        })
            .then(response => {
                let connectionIds = [];
                response.data.Items.forEach(element => {
                    if (recieverId === null || recieverId === '') {
                        connectionIds.push(element.connectionId);
                    }
                    else {
                        if (recieverId === element.userid) {
                            connectionIds.push(element.connectionId);
                        }
                    }

                });
                // sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            })
            .catch(error => { console.log(error); });
    };

    // Function To handle snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    //Function to get User Mc Number
    const getUserMcNumber = async () => {
        if (!location.mcNumber) {
            try {
                let alluserinformationdata = await getAllUserInformationApiCall();
                if (alluserinformationdata !== undefined) {
                    const findUser = alluserinformationdata.find(
                        ({ email_address }) => email_address === user.email
                    );
                    if (findUser.company_mc_number) {
                        setCompanyMCNumber(findUser.company_mc_number);
                    }
                    if (findUser.user_role) {
                        setLoggedInUserRole(findUser.user_role);
                    }
                    if (findUser.user_role === 'Carrier' || findUser.user_role === 'Broker' || findUser.user_role === 'Broker&Carrier') {
                        history.push({ pathname: '/forbidden' });
                    }
                    getCompanyInfo();
                }
                else {
                    history.push({ pathname: '/systemadmin' });
                }
            }
            catch (error) {
                console.log(error, 'Getting user details error in company profile');
                history.push({ pathname: '/systemadmin' });
            }
        }
        else {
            setCompanyMCNumber(location.mcNumber);
            getCompanyInfo();
        }
    };

    //Function to Update Company Profile  
    const updateCompanyProfile = async (logo) => {
        let response = await getUserDetailsResponse(companyMCNumber);
        if (response.data === 'UserNotPresent' || companyMCNumber === oldMCNumber) {
            const listValues = {
                company_id: companyID,
                website: companyWebsite,
                company_mc_number: companyMCNumber,
                companyName: companyName.toUpperCase(),
                company_role: companyRole === 'Broker & Carrier' ? 'Broker&Carrier' : companyRole,
                company_description: companyDescription,
                address: companyAddress,
                city: companyCity,
                state: companyState,
                email_address: companyEmailAddress,
                phone_number: companyPhoneNumber,
                logo: logo,
                old_mc_number: oldMCNumber,
                status: companyStatus,
                disabled_companies: companyDisabledCompanies
            };
            sendMessageForSocketIO('companyDetailsChanged', emailList);
            try {
                let companyProfileData = updateCompanyProfileApiCall(listValues);
                if (companyProfileData !== undefined) {
                    location.mcNumber = companyMCNumber;
                    getCompanyInfo();
                    setOldMCNumber(companyMCNumber);
                }
            }
            catch (error) {
                console.log(error, 'Error in updating company Profile');
            }
        }
        else
            if (companyMCNumber !== 'UserNotPresent' && companyMCNumber !== oldMCNumber && response !== companyMCNumber) {
                Swal.fire({
                    text: 'MC number is already exists in the db',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
                setCompanyMCNumber(oldMCNumber);
                setEditCompanyDetails(true);
                setErrorMessage('');
            }
    };

    const handleCompanyLogo = (e) => {
        var file = e.target.files[0];
        var temp = file.slice(0, file.size, 'image/png');
        if (file.size > 10e5) {
            setErrorMessage('max size');
        }
        else{
            setPreviewCompanyLogo(URL.createObjectURL(e.target.files[0]));
            setSelectedCompanyLogo(new File([temp], `${companyID}.png`, { type: 'image/png' }));
            setSelectedCompanyLogoName(file.name);
            setErrorMessage('');
        }
        
    };

    const deleteFile = async () => {
        var fileName = 'company_logos/' + companyID + '.png';
        await s3.deleteObject({
            Key: fileName,
            Bucket: S3_BUCKET
        }).promise().then(() => {
            setDeleteCompanyLogo(false);
            updateCompanyProfile(tempCompanyLogo);
            setErrorMessage('');
        });

    };

    const handleUpload = (file) => {
        if (companyLogo) {
            deleteFile().then(() => {
                uploadFile(file, config)
                    .then((data) => {
                        setCompanyLogo(data.location);
                        setTempCompanyLogo(data.location);
                        updateCompanyProfile(data.location);
                        setSelectedCompanyLogo(null);
                        setPreviewCompanyLogo(data.location);
                    })
                    .catch((err) => console.log(err));
            });
        }
        else {
            uploadFile(file, config)
                .then((data) => {
                    setTempCompanyLogo(data.location);
                    setCompanyLogo(data.location);
                    updateCompanyProfile(data.location);
                    setSelectedCompanyLogo(null);
                    setPreviewCompanyLogo(data.location);
                })
                .catch((err) => console.log(err));
        }
    };

    //Function to handle Save Changes
    const handleSaveChanges = () => {
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            if (mcNumberValid) {
                setEditCompanyDetails(false);
                if (deleteCompanyLogo) {
                    deleteFile();
                    if (selectedCompanyLogo) {
                        handleUpload(selectedCompanyLogo);
                    }
                }
                else if (!deleteCompanyLogo && selectedCompanyLogo) {
                    handleUpload(selectedCompanyLogo);
                }
                else if (!deleteCompanyLogo && !selectedCompanyLogo) {
                    updateCompanyProfile(tempCompanyLogo);
                }
            }
            else {
                Swal.fire({
                    text: 'MC Number should have length between 6-10 digits',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    };

    const handleDeleteButton = () => {
        setTempCompanyLogo('');
        setDeleteCompanyLogo(true);
        setPreviewCompanyLogo('');
    };

    const handleDeleteSelectedButton = () => {
        setSelectedCompanyLogo(null);
        setSelectedCompanyLogoName('');
        setPreviewCompanyLogo(tempCompanyLogo);
    };

    //Function to get company Information
    const getCompanyInfo = async () => {
        setIsDetailsOpen(true);
        try {
            let companyInformationData = await getparticularcompanyinformationApiCall('?company_mc_number=', companyMCNumber);
            const firstCompanyData = companyInformationData[0] ;
            setSelectedRowData(firstCompanyData);
            if (companyInformationData) {
                // Setting Company ID
                if (firstCompanyData.company_id) {
                    setCompanyID(firstCompanyData.company_id);
                }

                if (firstCompanyData.disabled_companies) {
                    setCompanyDisabledCompanies(firstCompanyData.disabled_companies);
                }

                // Setting Company Name
                if (firstCompanyData.company_name) {
                    setCompanyName(firstCompanyData.company_name);
                }
                // Setting Company Role
                if (firstCompanyData.company_role === 'Broker&Carrier') {
                    setCompanyRole('Broker & Carrier');
                }
                else if (firstCompanyData.company_role) {
                    setCompanyRole(firstCompanyData.company_role);
                }

                // Setting Company Description
                if (firstCompanyData.company_description) {
                    setCompanyDescription(firstCompanyData.company_description);
                }

                // Setting Company Address
                if (firstCompanyData.address) {
                    setCompanyAddress(firstCompanyData.address);
                }

                // Setting Company City
                if (firstCompanyData.city) {
                    setCompanyCity(firstCompanyData.city);
                }

                // Setting Company State
                if (firstCompanyData.state) {
                    setCompanyState(firstCompanyData.state);
                }

                // Setting Company Email
                if (firstCompanyData.email_address) {
                    setCompanyEmailAddress(firstCompanyData.email_address);
                }

                // Setting Company Phone Number
                if (firstCompanyData.phone_number) {
                    setCompanyPhoneNumber(firstCompanyData.phone_number);
                }

                // Setting Company Website
                if (firstCompanyData.website) {
                    setCompanyWebsite(firstCompanyData.website);
                }

                // Setting Company Logo
                if (firstCompanyData.logo) {
                    setErrorMessage('');
                    setCompanyLogo(firstCompanyData.logo);
                    setTempCompanyLogo(firstCompanyData.logo);
                    setPreviewCompanyLogo(firstCompanyData.logo);
                }

                // Setting Company Status
                if (firstCompanyData.status) {
                    setCompanyStatus(firstCompanyData.status);
                }
                setSelectedCompanyLogo(null);
            }
        }
        catch (error) {
            console.log(error, 'Error in getting company info');
        }
    };

    //Function to handle Cancel Changes
    const handleCancelChanges = () => {
        setEditCompanyDetails(false);
        setCompanyEmailAddress(selectedRowData.email_address);
        setCompanyPhoneNumber(selectedRowData.phone_number);
        setCompanyWebsite(selectedRowData.website);
        setCompanyDescription(selectedRowData.company_description);
        setCompanyMCNumber(location.mcNumber);
        setPreviewCompanyLogo(companyLogo);
        setErrorMessage('');
        if (isFieldEdited){
            window.alert("Changes you made may not be saved");
        }
        setIsFieldEdited(false);
        
    };

    // function to set changed values
    const handleChangedValue = (event) => {
        if (event.target.validity.valid) {
            setChangedValue(true);
        }
        else {
            setChangedValue(false);
        }
    };

    // Function to handle ComapnyDescription changes in CompanyDetails
    const handleCompanyDescription = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyDescription (event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyDescription (companyDescription);
        }
    };

    // Function to handle PhoneNumber changes in CompanyDetails
    const handleCompanyPhoneNumber = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyPhoneNumber(event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyPhoneNumber(companyPhoneNumber);
        }
    };

    // Function to handle PhoneNumber changes in CompanyDetails
    const handleCompanyEmailAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyEmailAddress(event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyEmailAddress(companyEmailAddress);
        }
    };

    // Function to handle PhoneNumber changes in CompanyDetails
    const handleCompanyCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyCity(event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyCity(companyCity);
        }
    };

    // Function to handle PhoneNumber changes in CompanyDetails
    const handleCompanyState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyState(event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyState(companyState);
        }
    };

    // Function to handle PhoneNumber changes in CompanyDetails
    const handleCompanyAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyAddress(event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyAddress(companyAddress);
        }
    };

    // Function to handle PhoneNumber changes in CompanyDetails
    const handleCompanyWebsite = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyWebsite(event.target.value);
            setIsFieldEdited(true);
        }
        else{
            setCompanyAddress(companyAddress);
        }
    };

    //function to get email Address of all user with same MC Number
    const getUserDetailsResponse = async (company_mc_number) => {
        try {
            let approvedCompanyUserDetails = await getApprovedUserDetailsForCompanyApiCall(company_mc_number);
            if (approvedCompanyUserDetails !== undefined) {
                let id = [];
                approvedCompanyUserDetails.forEach(data => {
                    if (approvedCompanyUserDetails !== 'UserNotPresent') {
                        id.push(data.email_address);
                    }
                });
                setEmailList(id);
            }
            return approvedCompanyUserDetails;
        }
        catch (error) {
            console.log(error, 'Fetch all approved user error');
        }
    };

    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'}>
                    <Grid>
                        <div style={{ height: 20 }}></div>
                        <Row>
                            <Col sm={1} ></Col>
                            <Col sm={1} className="mt-1">
                                {user.email === process.env.REACT_APP_ADMIN_USER &&
                                    <Tooltip title="Companies">
                                        <IconButton onClick={() => history.push({ pathname: '/systemadmin' })}>
                                            <ArrowBackIcon style={{ color: '#142440' }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Col>
                            <Col>
                                <b style={{ fontSize: 25, fontWeight: 'bold', color: 'black' }}>Company Details</b>
                            </Col>
                        </Row>
                        <Row>
                            <>
                                <Col sm={1}></Col>
                                {isDetailsOpen && (
                                <React.Fragment>
                                <div style={{ width: '1%' }}></div>
                                <div style={{ width: '54%' }} border={1}>
                                    <div className='adminDiv' />
                                    <Box
                                        boxShadow={3}
                                        style={{ width: '150%',height:'81%' ,paddingBottom: 45 }}
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={7}>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <React.Fragment>
                                                    <IconButton hidden={editCompanyDetails} onClick={() => setEditCompanyDetails(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid>
                                        <div className='adminDiv' />
                                        <Grid container style = {{marginBottom:'5%',paddingLeft:'5%'}}spacing={2}>
                                            {/* <Grid item xs={1}>
                                            </Grid> */}
                                            <Row>
                                                <Col>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item >
                                                        <TextField disabled label="Company Name"  style={{width:'20rem'}}  value={pascalCase(companyName)} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" style={{width:'20rem'}} value={companyMCNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField  disabled={!editCompanyDetails} label="Address *"  style={{width:'20rem'}}  value={pascalCase(companyAddress)} onChange={handleCompanyAddress}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={!editCompanyDetails} label="City *" style={{width:'20rem'}}  value={pascalCase(companyCity)} onChange={handleCompanyCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={!editCompanyDetails} label="State *" style={{width:'20rem'}} value={pascalCase(companyState)} onChange={handleCompanyState}></TextField>
                                                    </Grid>
                                                </Grid>
                                                </Col>
                                                <Col>
                                                <Grid container direction="column" style={{paddingLeft:'5%'}} spacing={1}>
                                                {/* <Grid item xs={1}>
                                                </Grid> */}
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" style={{width:'20rem'}}  value={companyRole} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={!editCompanyDetails} label="Email Address" style={{width:'20rem'}}  value={companyEmailAddress} onChange={handleCompanyEmailAddress} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                    <TextField disabled={!editCompanyDetails} label="Phone Number" style={{width:'20rem'}} value={companyPhoneNumber} onChange={handleCompanyPhoneNumber } InputProps={{inputComponent: PhoneNumberInput}}/>
                                                    </Grid>
                                                    <Grid item>
                                                    <TextField style={{width: '20rem' }} placeholder="www.example.com" label="Website" value={companyWebsite} onChange={handleCompanyWebsite}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField label="Company Description" autoComplete='off'  style={{width:'20rem'}} value={companyDescription} disabled={!editCompanyDetails} onChange={handleCompanyDescription}/>
                                                    </Grid>
                
                                                </Grid>
                                                </Col>
                                                <Col>
                                                <Grid container style={{paddingLeft:'15%'}} direction="column"  spacing={5}>
                                                    <Grid item>
                                                        {editCompanyDetails ?
                                                            <>
                                                            <Grid container direction="column"  spacing={5}>
                                                                <Grid item>
                                                                    <Image
                                                                        style={{ width: '25rem', height: '25rem', maxWidth: 300, maxHeight: 300,minHeight:100 }}
                                                                        src={previewCompanyLogo ? previewCompanyLogo : defaultLogo}
                                                                        fluid
                                                                        rounded
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            {(errorMessage !== '') && (
                                                                <span id='invalid-file-chosen'>File exceeded 1MB</span>
                                                            )}
                                                            {(errorMessage === '') && (
                                                                <span id='mySpanClass'>File size max 1MB</span>
                                                            )}
                                                            {user.email === process.env.REACT_APP_ADMIN_USER &&
                                                                <Grid container direction="column"  spacing={5}>
                                                                    <Grid item>
                                                                        <Form style={{width:'90%',paddingTop:'10%'}}>
                                                                            <Form.File id="company-logo" custom>
                                                                                <Form.File.Input isValid={selectedCompanyLogo ? true : false}  onChange={handleCompanyLogo} />
                                                                                <Form.File.Label data-browse="Choose File" >
                                                                                    {selectedCompanyLogo ? selectedCompanyLogoName : 'Company Logo'}
                                                                                </Form.File.Label>
                                                                                {/* <Form.Control.Feedback type="valid">Success!</Form.Control.Feedback> */}
                                                                            </Form.File>
                                                                        </Form>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            </>
                                                            :
                                                            <Grid container direction="column"  spacing={5}>
                                                                    <Grid item>
                                                                    <Image
                                                                        style={{ width: '25rem', height: '25rem', maxWidth: 300, maxHeight: 300,minHeight:100 }}
                                                                        src={tempCompanyLogo ? tempCompanyLogo : defaultLogo}
                                                                        fluid
                                                                        rounded
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                </Col>
                                            </Row>  
                                    
                                        </Grid>
                                        <Grid item xs style={{ paddingLeft: '25%',paddingBottom:'5%'}}>
                                            <Button className='buttonColor' style={(editCompanyDetails) ? { marginLeft: '20%', height: 45,width:85, textTransform: 'none', backgroundColor: '#101e35' } : { textTransform: 'none', marginLeft: '20%', height: 45,width:85, backgroundColor: '#9e9e9ec2' }} size='large' onClick={handleSaveChanges} >
                                                Save
                                            </Button>
                                            <Button className='buttonColor' style={(editCompanyDetails) ? { marginLeft: '2%', height: 45,width:85, textTransform: 'none', backgroundColor: '#101e35' } : { textTransform: 'none', marginLeft: '2%', height: 45,width:85, backgroundColor: '#9e9e9ec2' }} size='large' onClick={handleCancelChanges} >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Box>
                                </div>
                                </React.Fragment>
                            )}
                            </>
                        </Row>
                    </Grid>
                </Typography>
            </main>
        </div>
    );
};

export default CompanyProfile;