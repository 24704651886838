import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './index.css';
import { useAuth0 } from '@auth0/auth0-react';

// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';

const styles = {
    header: {
        backgroundImage: `url(${'https://loadeo-company-logo-images.s3.amazonaws.com/403.gif'})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        width: '50vh',
        marginTop: '20vh'
    },
    header1: {
        backgroundImage: `url(${'https://loadeo-company-logo-images.s3.amazonaws.com/403.PNG'})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginTop: '40vh',
        height: '30vh',
        marginLeft: '35vh',
        width: '50vh'
    },
    content: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const Error403Page = () => {
    const history = useHistory();
    const { user } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const dispatch = useDispatch();

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    // Constant to handle logged in Company name
    const [userCompanyName, setUserCompanyName] = useState('');

    // Function to check the user role
    const checkUserRole = () => {
        if(userReduxData.userData.user_role){
            setLoggedInuserRole(userReduxData.userData.user_role);
        }
        if(userReduxData.userData.company_name){
            setUserCompanyName(userReduxData.userData.company_name);
        }
    };

    // Function that is triggered on page load
    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    useEffect(() => {
        if(Object.keys(userReduxData.userData).length){
            checkUserRole();
        }
    }, [userReduxData]);

    /******************************************************************************************************/
    return (
        <main>
            {
                <section id="head" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-5">
                                <div className="head_contentError">
                                    <h2>ACCESS DENIED!</h2>
                                    <h4>We're sorry, You don't have access to the page you requested</h4>
                                    <p>To view this page you may have to sign in with a different account. You are currently signed in as {loggedInuserRole}</p>
                                    <div className="head_btn">
                                        {(userCompanyName === 'SystemAdmin') && (
                                            <button className="button1" onClick={() => history.push({ pathname: '/admin' })}>
                                                Return Home
                                            </button>)}
                                        {(loggedInuserRole === 'Driver' && userCompanyName !== 'SystemAdmin') ?
                                            <button className="button1" onClick={() => history.push({ pathname: '/loadsassigned' })}>
                                                Return Home
                                            </button>
                                            :
                                            <button className="button1" onClick={() => history.push({ pathname: '/loadboard' })}>
                                                Return Home
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="head_img">
                                    <div style={styles.header}></div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="head_img">
                                    <div style={styles.header1}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </main>
    );
};

export default Error403Page;