import { getAllUserInformationApiCall } from '../../apis/user';
import { GET_SUCCESS } from './ActionType';
import { IF_USER_EXIST } from './ActionType';

export let setUser = (data) => {
    let res = data ? data : {};
    return (
        {
            type: GET_SUCCESS,
            payload: res
        }
    );
};

export let handleUserDataValue = (user) => {
    return async (dispatch) => {
        if (user) {
            let alluserinformationdata = await getAllUserInformationApiCall();
            if (alluserinformationdata !== undefined) {
                if (alluserinformationdata !== 'UserNotPresent') {
                    const findUser = alluserinformationdata.find(
                        ({ email_address }) => email_address === user
                    );
                    // dispatch(setExistUser(true))
                    if (findUser.login_status === 'ProfileAccepted') {
                        dispatch(setUser(findUser));
                    }
                }
            }
        }
    };
};

export let handleUserCallBackData = (user, logout, history) => {

    const handleRemoveLocalStorageValues = () => {
        window.localStorage.removeItem('loadboardLoadId');
        window.localStorage.removeItem('assignedLoadId');
        window.localStorage.removeItem('activeloadsLoadId');
        window.localStorage.removeItem('activebidsLoadId');
        window.localStorage.removeItem('awardedloadsLoadId');
        window.localStorage.removeItem('bidswonLoadId');
        window.localStorage.removeItem('drawerValue');
    };
    return async (dispatch) => {
        try {
            let alluserinformationdata = await getAllUserInformationApiCall();
            if (alluserinformationdata !== undefined && alluserinformationdata !== 'UserNotPresent') {
                const findUser = alluserinformationdata.find(
                    ({ email_address }) => email_address === user
                );
                if (findUser === undefined || findUser === null) {
                    handleRemoveLocalStorageValues();
                    history.push('/useragreement');
                }
                else {
                    window.localStorage.setItem('ifUserExist', true);
                    if (findUser.login_status === 'AgreementAgreed') {
                        handleRemoveLocalStorageValues();
                        history.push('/createprofile' );
                    }
                    else if (findUser.login_status === 'ProfileCreated') {
                        handleRemoveLocalStorageValues();
                        history.push('/underverification' );
                    }
                    else if (findUser.login_status === 'ProfileSuspended') {
                        if (findUser.status === 'ProfileSuspended'){
                            history.push('/companysuspended' );
                        }
                        else{
                            history.push('/usersuspended' );
                        }
                    }
                    else if (findUser.login_status === 'ProfileRejected') {
                        history.push('/rejecteduser');
                    }
                    else if (findUser.login_status === 'ProfileDeleted') {
                        history.push('/userdeleted');
                    }
                    else if (findUser.login_status === 'ProfileAccepted') {
                        dispatch(setUser(findUser));
                        window.localStorage.setItem('userName', findUser.first_name + ' ' + findUser.last_name);
                        window.localStorage.setItem('drawerValue', 'true');
                        if (window.localStorage.getItem('loadboardLoadId')) {
                            history.push({
                                pathname: '/loadboard',
                                state: { loadId: window.localStorage.getItem('loadboardLoadId') }
                            });
                        }
                        else if (window.localStorage.getItem('assignedLoadId')) {
                            history.push({
                                pathname: '/loadsassigned',
                                state: { loadId: window.localStorage.getItem('assignedLoadId') }
                            });
                        }
                        else if (window.localStorage.getItem('activeloadsLoadId')) {
                            history.push({
                                pathname: '/activeloads',
                                state: { loadId: window.localStorage.getItem('activeloadsLoadId') }
                            });
                        }
                        else if (window.localStorage.getItem('activebidsLoadId')) {
                            history.push({
                                pathname: '/activebids',
                                state: { loadId: window.localStorage.getItem('activebidsLoadId') }
                            });
                        }
                        else if (window.localStorage.getItem('awardedloadsLoadId')) {
                            history.push({
                                pathname: '/awardedloads',
                                state: { loadId: window.localStorage.getItem('awardedloadsLoadId') }
                            });
                        }
                        else if (window.localStorage.getItem('bidswonLoadId')) {
                            history.push({
                                pathname: '/bidswon',
                                state: { loadId: window.localStorage.getItem('bidswonLoadId') }
                            });
                        }
                        else if (findUser.user_role === 'Driver') {
                            history.push('/loadsassigned' );
                        }
                        else {
                            history.push('/loadboard' );
                        }
                    }
                    else {
                        logout({
                            returnTo: window.location.origin,
                        });
                    }
                }
            }
            else {
                handleRemoveLocalStorageValues();
                history.push('/useragreement' );
            }
        }
        catch (error) {
            console.log('callback error', error);
        }
    };
};

export let setExistUser = (status) => {
    let res = status ? status : false;
    return (
        {
            type: IF_USER_EXIST,
            payload: res
        }
    );
};