import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

/* ************************ Storing user details from agreement page************************* */
export const storeUserDetailInAgreementApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.useragreement,
            method: 'post',
            data: listValues,
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Storing user agreement details failed');
    }
};

/* ************************ Update User Profile************************* */
export const updateUserProfileApiCall = async (list) => {
    try {
        let response = await axios({
            url: environmentVariables.updateuserprofile,
            method: 'patch',
            data: list,
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Update user profile error');
    }
};

/* ************************ Get new user request************************* */
export const getnewuserrequestApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.getnewuserrequest,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Getting new user data failed');
    }
};

/* ************************ Get all user in system Admin page************************* */
export const getAllUsersForSystemAdminApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.getallusersforsystemadmin,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Getting all user data failed');
    }
};

/* ************************ Get User Information Api Call************************* */
export const getAllUserInformationApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.getuserdetails,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Get all users error');
    }
};
/* ************************ Update Loadeo User ************************* */
export const updateLoadeoUserDetailsApiCall = async (listValues, Page) => {
    try {
        let response = await axios({
            url: environmentVariables.updateloadeouserdetails + '?page=' + Page,
            method: 'patch',
            data: listValues
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Updating loadeo user details failed');
    }
};

/* ************************ update login name************************* */
export const updateLoginNameApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.updateloginname,
            method: 'patch',
            data: listValues,
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Update login name failed');
    }
};

/* ************************ Get new user for company dashboard************************* */
export const getNewUsersForCompanyDashboardApiCall = async (company_mc_number) => {
    try {
        let response = await axios({
            url: environmentVariables.getnewusersforcompanydashboard + '?company_mc_number=' + company_mc_number,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Get new user for Company dashboard failed');
    }
};

/************************Company Admin************************* */
/************************Invite User************************* */
export const inviteUserApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.inviteuser,
            method: 'post',
            data: listValues
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Inviting user failed');
    }
};

/* ************************ get Invited user************************* */
export const getInvitedUserApiCall = async (type, loggedInuserCompanyMcNumber) => {
    try {
        let response = await axios({
            url: environmentVariables.getinviteduser + type + loggedInuserCompanyMcNumber,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Get invited user failed');
    }
};
/* ************************ Reset Password API Call************************* */
export const resetPasswordApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.resetpassword,
            method: 'post',
            data: listValues
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Reseting password failed');
    }
};

/************************Edit User Details from Company Admin************************* */
export const editCompanyAdminApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.editcompanyadmin,
            method: 'post',
            data: listValues
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Error in editing details');
    }
};