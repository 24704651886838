import React from 'react';
export const HomeIcon = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white" />
            <g clipPath="url(#clip0_1118_33)">
                <path d="M17.8405 9.0957L10.7303 3.88973C10.5932 3.78936 10.4069 3.78936 10.2698 3.88973L3.15954 9.0957C2.98587 9.22288 2.94813 9.46674 3.07531 9.64041C3.20249 9.81409 3.44638 9.85176 3.62003 9.72464L10.5 4.68722L17.38 9.72461C17.4494 9.77544 17.53 9.79991 17.6099 9.79991C17.7299 9.79991 17.8483 9.74468 17.9247 9.64039C18.0519 9.46674 18.0142 9.22288 17.8405 9.0957Z" fill="black" />
                <path d="M15.9549 9.8125C15.7397 9.8125 15.5652 9.98699 15.5652 10.2022V16.4058H12.4473V13.0195C12.4473 11.9449 11.5731 11.0707 10.4985 11.0707C9.42396 11.0707 8.54968 11.945 8.54968 13.0195V16.4058H5.43182V10.2023C5.43182 9.98702 5.25729 9.81253 5.04208 9.81253C4.82687 9.81253 4.65234 9.98702 4.65234 10.2023V16.7956C4.65234 17.0109 4.82687 17.1854 5.04208 17.1854H8.93941C9.14438 17.1854 9.3121 17.027 9.32763 16.826C9.32856 16.8169 9.32915 16.8069 9.32915 16.7956V13.0195C9.32915 12.3747 9.85371 11.8502 10.4985 11.8502C11.1433 11.8502 11.6679 12.3748 11.6679 13.0195V16.7956C11.6679 16.8068 11.6684 16.8166 11.6694 16.8256C11.6847 17.0268 11.8525 17.1854 12.0576 17.1854H15.9549C16.1702 17.1854 16.3447 17.0109 16.3447 16.7956V10.2023C16.3446 9.98699 16.1701 9.8125 15.9549 9.8125Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1118_33">
                    <rect width="15" height="15" fill="white" transform="translate(3 3)" />
                </clipPath>
            </defs>
        </svg>
    );
};
