export const environmentVariables = {
    // application related endpoints
    'getapplicationdetails': 'https://flask-api.stage.loadeo.com/api/loadeo_fetch_application_details',

    // user related endpoints 
    'updateuserprofile': 'https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/update-user-profile',
    'getuserdetails': 'https://flask-api.stage.loadeo.com/api/loadeo_get_user_information',
    'getdriverusers':'https://flask-api.stage.loadeo.com/api/loadeo_get_driver_users',
    'useragreement': 'https://flask-api.stage.loadeo.com/api/loadeo_add_agreed_user',
    'updateloginname': 'https://flask-api.stage.loadeo.com/api/loadeo_create_user_profile',
    'getcompanyinformation': 'https://flask-api.stage.loadeo.com/api/loadeo_get_company_information',

    // invite user related endpoints
    'inviteuser':'https://flask-api.stage.loadeo.com/api/loadeo_add_invited_user',
    'getinviteduser':'https://flask-api.stage.loadeo.com/api/loadeo_get_invited_users',
    'resetpassword':'https://flask-api.stage.loadeo.com/api/loadeo_reset_user_password',

    // Company profile related endpoints
    'updatecompanyprofile': 'https://flask-api.stage.loadeo.com/api/loadeo_update_company_details',

    // dashboard related endpoints
    'getnewuserrequest': 'https://flask-api.stage.loadeo.com/api/loadeo_get_all_new_user_details',
    'getallusersforsystemadmin': 'https://flask-api.stage.loadeo.com/api/loadeo_get_all_approved_user_details',
    'approveusernotification': 'https://flask-api.stage.loadeo.com/api/loadeo_approve_user_profile_notification',
    'getnewusersforcompanydashboard': 'https://flask-api.stage.loadeo.com/api/loadeo_get_new_company_user_details',
    'getapproveduserdetailsforcompany': 'https://flask-api.stage.loadeo.com/api/loadeo_get_approved_company_user_details',
    'updateloadeouserdetails': 'https://flask-api.stage.loadeo.com/api/loadeo_update_user_details',

    // Preferred carrier related endpoints
    'updatepreferstatus': 'https://flask-api.stage.loadeo.com/api/loadeo_update_preferred_or_hold_status',
    'getpreferredcompanies': 'https://flask-api.stage.loadeo.com/api/loadeo_get_preferred_companies',
    'getholdcompanies': 'https://flask-api.stage.loadeo.com/api/loadeo_get_hold_companies',
    'getpreferredandholdcompanies': 'https://flask-api.stage.loadeo.com/api/loadeo_get_preferred_and_hold_companies',

    // Prospective clients endpoints
    'addprospectiveclient': 'https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/add-prospective-clients',
    'addprospectiveclientsinbulk': 'https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/add_prospective_clients_in_bulk',
    'getprospectiveclients': 'https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/get_prospective_clients',
    'updateprospectiveemailstatus': 'https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/update-prospective-email-status',
    'updatemainstatus': 'https://sf1m80cz19.execute-api.us-east-1.amazonaws.com/development/update-prospective-main-toggle-status',

    // firebase realted endpoints
    'addtoken': 'https://flask-api.stage.loadeo.com/api/loadeo_add_user_firebase_token',
    'deletetoken':'https://flask-api.stage.loadeo.com/api/loadeo_remove_firebase_token',
    'gettoken':'https://flask-api.stage.loadeo.com/api/loadeo_get_firebase_user_tokens',

    // MC certification endpoints
    'extractJSONdata': 'https://lxvgxtuawf.execute-api.us-east-1.amazonaws.com/Dev/upload',
    'addmccertificate':'https://flask-api.stage.loadeo.com/api/loadeo_add_MC_certificate',
    'getmccertificate': 'https://flask-api.stage.loadeo.com/api/loadeo_get_all_certificate',
    'deletemccertificate': 'https://flask-api.stage.loadeo.com/api/loadeo_delete_mc_certificate',
    'updatecertificatestatus': 'https://flask-api.stage.loadeo.com/api/loadeo_update_certificate_status',

    // load related endpoints
    'createload': 'https://flask-api.stage.loadeo.com/api/loadeo_create_load',
    'getcarrierloadboard': 'https://flask-api.stage.loadeo.com/api/loadeo_get_all_loads',
    'getawardedloads': 'https://flask-api.stage.loadeo.com/api/loadeo_get_awarded_loads',
    'getwonloads': 'https://flask-api.stage.loadeo.com/api/loadeo_get_won_loads',
    'getexpiredloads': 'https://flask-api.stage.loadeo.com/api/loadeo_get_expired_loads',
    'getdriverloads': 'https://flask-api.stage.loadeo.com/api/loadeo_get_driver_loads',
    'editloaddetail': 'https://flask-api.stage.loadeo.com/api/loadeo_update_load',
    'deleteloaddetail': 'https://flask-api.stage.loadeo.com/api/loadeo_delete_load',
    'getbidhistory': 'https://flask-api.stage.loadeo.com/api/loadeo_get_expired_bids_loads',
    'getnearbycities': 'https://sbkhuk73rl.execute-api.us-east-1.amazonaws.com/development/loadeo-get-nearby-cities',

    //bid related endpoints
    'createbidemail': 'https://flask-api.stage.loadeo.com/api/loadeo_bid_placed_email_notification',
    'createbid': 'https://flask-api.stage.loadeo.com/api/loadeo_create_bid',
    'getbid': 'https://flask-api.stage.loadeo.com/api/loadeo_get_bids',
    'acceptbid': 'https://flask-api.stage.loadeo.com/api/loadeo_accept_bid',
    'deletebid': 'https://flask-api.stage.loadeo.com/api/loadeo_delete_bid',

    // counter offer related endpoints
    'editbid': 'https://flask-api.stage.loadeo.com/api/loadeo_create_counter_offer',

    //chat related endpoints
    'createupdatetestcomment': 'https://flask-api.stage.loadeo.com/api/loadeo_create_comment',
    'gettestcomment': 'https://flask-api.stage.loadeo.com/api/loadeo_get_comments',
    'updatetestcomment': 'https://flask-api.stage.loadeo.com/api/loadeo_update_comments',
    // websocket related endpoints
    'websocketfetchallusers': 'https://flask-api.stage.loadeo.com/api/loadeo_get_websocket_users',
    'websocketurl': 'wss://m11e4uzag0.execute-api.us-east-1.amazonaws.com/dev',

    // Email related endpoints
    // dashboard emails
    'sendingnewrequestemailtoadmin': 'https://jcxq2ioxkj.execute-api.us-east-1.amazonaws.com/develop/newrequest_email_to_admin',
    'sendingemailtocompanyadmin': 'https://aw3kp93g97.execute-api.us-east-1.amazonaws.com/development/sendemailtocompanyadmin',
    'sendRejectedMail': 'https://flask-api.stage.loadeo.com/api/loadeo_reject_user_profile_notification',
    // load emails
    'createloadnotification': 'https://flask-api.stage.loadeo.com/api/loadeo_posted_load_email_notification',
    // bid emails
    'acceptbidnotification': 'https://flask-api.stage.loadeo.com/api/loadeo_accept_bid_email_notification',
    //counter offer emails
    'counteremail': 'https://flask-api.stage.loadeo.com/api/loadeo_counter_offer_email_notification',
    'acceptcounteroffernotification': 'https://flask-api.stage.loadeo.com/api/loadeo_accept_counter_offer_email_notification',
    // saved lane emails 
    'searchLaneEmail': 'https://flask-api.stage.loadeo.com/api/loadeo_match_lane_email_notification',
    // confirm bid emails
    'createconfirmbidnotification': 'https://flask-api.stage.loadeo.com/api/loadeo_confirm_bid_email_notification',
    // assign load notification
    'assignloadnotification': 'https://flask-api.stage.loadeo.com/api/loadeo_assign_load_notification_function',
    // reopen Load Email
    'createreopenloadnotification': 'https://flask-api.stage.loadeo.com/api/loadeo_reopen_load_email_notification',    
    
    // Bell icon Notification related endpoints
    'addnotifications': 'https://flask-api.stage.loadeo.com/api/loadeo_add_notification',
    'getnotifications': 'https://flask-api.stage.loadeo.com/api/loadeo_get_notifications',
    'editnotification': 'https://flask-api.stage.loadeo.com/api/loadeo_edit_notification',
    'deletenotification': 'https://flask-api.stage.loadeo.com/api/loadeo_delete_notification',
    'gettabnotifications': 'https://flask-api.stage.loadeo.com/api/loadeo_get_tab_notifications',

    // Saved lane related endpoints
    'savesearch': 'https://flask-api.stage.loadeo.com/api/loadeo_create_saved_lane',
    'getMatchedLane': 'https://flask-api.stage.loadeo.com/api/loadeo_get_saved_lanes',
    'updateDate': 'https://flask-api.stage.loadeo.com/api/loadeo_update_saved_lanes',

    // feedback related endpoints
    'feedbackinfodetails': 'https://flask-api.stage.loadeo.com/api/loadeo_store_feedback',
    'feedbackemailtoadmin': 'https://flask-api.stage.loadeo.com/api/loadeo_send_feedback_email_to_admin',

    // feedback On Driver
    'feedbackemailtobroker': 'https://flask-api.stage.loadeo.com/api/loadeo_send_feedback_email_to_broker',
    // Admin to verify email address
    'admintoVerifyAddress': 'https://flask-api.stage.loadeo.com/api/loadeo_verify_user_email_address',
    
    // thanks feedBack email to user
    'thanksEmailtoUser': 'https://flask-api.stage.loadeo.com/api/loadeo_thanks_email_to_user',

    // unused endpoints
    'updatenewuserdetails': 'https://bux31h7ft6.execute-api.us-east-1.amazonaws.com/development/update-user-details',
    'getuserprofile': 'https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/get-user-profile',
    'getbrokercompanyname': 'https://wrsrqm9bnc.execute-api.us-east-1.amazonaws.com/development/get-broker-companyname',
    'getallusers': ' https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/get-user',
    'addnewuser': 'https://ry0ilqpk10.execute-api.us-east-1.amazonaws.com/development/create-user',
    'gettestcarriercomment': 'https://ng13o2ba3k.execute-api.us-east-1.amazonaws.com/development/get-carrier-comment',
    'getbrokerloadboard': 'https://0itz9q4q23.execute-api.us-east-1.amazonaws.com/development/get-broker-loadbord',
    'createCommentNotification': 'https://ng13o2ba3k.execute-api.us-east-1.amazonaws.com/development/create-comment-notification',
    'getcarriersearch': 'https://0itz9q4q23.execute-api.us-east-1.amazonaws.com/development/get-carrier-search',
    'getallcarriersforadmin': 'https://ehb20ozqle.execute-api.us-east-1.amazonaws.com/development/loadeo-get-allcarriers',
    'getholdcarriers': 'https://ehb20ozqle.execute-api.us-east-1.amazonaws.com/development/get-hold-carriers',
    'getchatdetails': 'https://esahrxnfp0.execute-api.us-east-1.amazonaws.com/development/loadeo-get-all-chat-details',

    'updateuserdetailscompanyadmin': 'https://5lv24vk0g5.execute-api.us-east-1.amazonaws.com/dev/companyadminuser',
    'updatesystemadminuserdetails': 'https://bux31h7ft6.execute-api.us-east-1.amazonaws.com/development/update-user-details',
    'editcompanyadmin': 'https://3j7pidp0id.execute-api.us-east-1.amazonaws.com/dev/edit-companyadmin-user',
    'getallcompanies': 'https://hwuqq8xxi3.execute-api.us-east-1.amazonaws.com/dev/get-companies',
};