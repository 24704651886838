import React from 'react';
export const InviteUserIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0349 12.8146V12.7646H10.9849H9.76545V12.8146L9.76239 12.7647C9.68599 12.7694 9.60943 12.7584 9.53744 12.7324C9.46545 12.7063 9.39955 12.6659 9.34379 12.6134C9.28803 12.561 9.2436 12.4977 9.21323 12.4274C9.18286 12.3571 9.16719 12.2814 9.16719 12.2049C9.16719 12.1283 9.18286 12.0526 9.21323 11.9823C9.2436 11.9121 9.28803 11.8487 9.34379 11.7963C9.39955 11.7439 9.46545 11.7034 9.53744 11.6774C9.60943 11.6513 9.68599 11.6403 9.76239 11.645L9.76238 11.6451H9.76545H10.9849H11.0349V11.5951V10.3749C11.044 10.2329 11.1067 10.0996 11.2104 10.0021C11.3144 9.90429 11.4519 9.8498 11.5947 9.8498C11.7375 9.8498 11.875 9.90429 11.979 10.0021C12.0827 10.0996 12.1454 10.2329 12.1544 10.3749V11.5951V11.6451H12.2044H13.4247C13.5667 11.6542 13.6999 11.7169 13.7974 11.8206C13.8953 11.9246 13.9498 12.062 13.9498 12.2049C13.9498 12.3477 13.8953 12.4851 13.7974 12.5892C13.6999 12.6928 13.5667 12.7555 13.4247 12.7646H12.2044H12.1544V12.8146V14.0341H12.1544L12.1545 14.0372C12.1592 14.1136 12.1482 14.1901 12.1222 14.2621C12.0962 14.3341 12.0557 14.4 12.0033 14.4558C11.9508 14.5115 11.8875 14.556 11.8172 14.5863C11.747 14.6167 11.6712 14.6324 11.5947 14.6324C11.5181 14.6324 11.4424 14.6167 11.3721 14.5863C11.3019 14.556 11.2386 14.5115 11.1861 14.4558C11.1337 14.4 11.0932 14.3341 11.0672 14.2621C11.0412 14.1901 11.0302 14.1136 11.0349 14.0372L11.0349 14.0372V14.0341V12.8146Z" fill="#333333" stroke="white" strokeWidth="0.1" />
            <path d="M6.71425 6.05436C6.12049 6.05435 5.54006 5.87828 5.04637 5.5484C4.55266 5.21852 4.16787 4.74964 3.94064 4.20106C3.71341 3.65249 3.65396 3.04885 3.7698 2.46648C3.88564 1.88412 4.17157 1.34918 4.59143 0.929319C5.01129 0.509457 5.54623 0.223527 6.12859 0.107687C6.71096 -0.00815231 7.3146 0.0513008 7.86317 0.278529C8.41175 0.505756 8.88063 0.890553 9.21051 1.38426C9.54039 1.87795 9.71646 2.45838 9.71647 3.05214C9.71587 3.8482 9.39938 4.61147 8.83648 5.17437C8.27358 5.73727 7.51031 6.05376 6.71425 6.05436ZM6.71429 1.17179C6.34238 1.17179 5.97883 1.28207 5.6696 1.48869C5.36037 1.69531 5.11936 1.98899 4.97703 2.33258C4.83471 2.67618 4.79747 3.05427 4.87003 3.41903C4.94258 3.78379 5.12167 4.11884 5.38465 4.38182C5.64763 4.64479 5.98268 4.82388 6.34744 4.89644C6.7122 4.969 7.09029 4.93176 7.43388 4.78943C7.77748 4.64711 8.07116 4.4061 8.27778 4.09687C8.4844 3.78764 8.59468 3.42409 8.59468 3.05218C8.59468 2.55347 8.39657 2.07518 8.04393 1.72254C7.69128 1.3699 7.213 1.17179 6.71429 1.17179Z" fill="#333333" stroke="white" strokeWidth="0.1" />
            <path d="M1.17234 13.4256L1.1723 13.4756H1.22234H7.32441C7.4728 13.4756 7.61515 13.5344 7.72028 13.6391C7.82538 13.7438 7.88471 13.8859 7.8853 14.0343C7.88528 14.183 7.82619 14.3256 7.72102 14.4308C7.61583 14.536 7.47317 14.5951 7.32441 14.5951H0.614253C0.535088 14.5934 0.457125 14.5753 0.38522 14.5422C0.313155 14.5089 0.248724 14.4612 0.19596 14.4019C0.143195 14.3426 0.10323 14.2731 0.0785657 14.1976C0.0539011 14.1222 0.0450668 14.0425 0.0526154 13.9635L0.0528421 13.9635V13.9587V13.6384C0.0528421 10.1228 2.68432 7.05545 6.18429 6.78308L6.1844 6.78307C7.79522 6.65425 9.39777 7.11685 10.6921 8.0843L10.6923 8.0844C10.7874 8.15503 10.858 8.25377 10.894 8.36668C10.93 8.47958 10.9296 8.60095 10.8929 8.71363C10.8562 8.82631 10.785 8.92461 10.6894 8.99465C10.594 9.06458 10.479 9.1028 10.3606 9.10392C10.24 9.10246 10.123 9.06194 10.0272 8.98842L10.0267 8.98801C9.20308 8.37365 8.22488 8.00039 7.20137 7.90992C6.17787 7.81946 5.14937 8.01536 4.23078 8.47573C3.31219 8.9361 2.53969 9.64282 1.9996 10.5169C1.4595 11.391 1.17309 12.3981 1.17234 13.4256Z" fill="#333333" stroke="white" strokeWidth="0.1" />
        </svg>
    );
};
