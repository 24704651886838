// react related imports
import React, { useState, useEffect, } from 'react';
import { useHistory } from 'react-router';

// Auth0 related imports
import { useAuth0 } from '@auth0/auth0-react';

import {
    Button,
    Box,
    Grid,
    IconButton,
    Snackbar,
    CssBaseline,
    TextField,
    Typography,
    Card,
    CardContent,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Paper,
    Tooltip
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from 'mui-datatables';
import MuiAlert from '@material-ui/lab/Alert';

// MUI styles related imports
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';

// import statements to have all icons
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

// bootstrap components related imports
import { Row, Col } from 'react-bootstrap';

// Import dispatch and selector
import { useDispatch, useSelector } from "react-redux";
import { handleUserDataValue } from '../../store/users/Action';

// API calls related imports
import axios from 'axios';
import { sendRejectedMailApiCall, approveUserNotificationApiCall } from '../../apis/emailtoAdmin';
import { getparticularcompanyinformationApiCall, getApprovedUserDetailsForCompanyApiCall } from '../../apis/companies';
import { getNewUsersForCompanyDashboardApiCall, getInvitedUserApiCall, inviteUserApiCall, updateLoadeoUserDetailsApiCall, editCompanyAdminApiCall, } from '../../apis/user';

import './index.css';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2';
import { pascalCase } from '../../utils';
import MUIDrawer from '../../components/Drawer';

//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: 'none',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    toolbar: {
        marginTop: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}));

const CompanyAdmin = () => {

    //------------------------- Declaring all the constants ------------------------------
    const dispatch = useDispatch();

    //Constants to handle snackbar
    const [colorseverity, setColorseverity] = useState('');
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState(false);

    //Constants to handle MUIDatatable
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [gridPercent, setGridPercent] = useState('100%');
    const [inviteUserGridPercent, setInviteUserGridPercent] = useState('100%');

    //Constants to handle Side Panes
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isFieldEdited, setIsFieldEdited] = useState(false);
    const [isInviteUserPaneOpen, setIsInviteUserPaneOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [clickRowData, setClickRowData] = useState([]);
    const [clickNewUserRowData, setClickNewUserRowData] = useState([]);

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const classes = useStyles();

    const history = useHistory();

    // constants to handle invited user details panel
    const [invitedUserMailId, setInvitedUserMailId] = useState('');
    const [invitedUserMcNumber, setInvitedUserMcNumber] = useState('');
    const [invitedUserRole, setInvitedUserRole] = useState('');
    const [invitedUserCompanyName, setInvitedUserCompanyName] = useState('');
    const [invitedUserCompanyRole, setInvitedUserCompanyRole] = useState('');

    // constants to handle invited user details panel
    const [inviteUserMailId, setInviteUserMailId] = useState('');
    const [inviteUserRole, setInviteUserRole] = useState('');
    const [inviteUserCompanyName, setInviteUserCompanyName] = useState('');
    const [inviteUserCompanyRole, setInviteUserCompanyRole] = useState('');
    const [inviteUserMCNumber, setInviteUserMCNumber] = useState('');
    const [inviteUserCompanyAddress, setInviteUserCompanyAddress] = useState('');
    const [inviteUserCompanyCity, setInviteUserCompanyCity] = useState('');
    const [inviteUserCompanyState, setInviteUserCompanyState] = useState('');

    //Constants to handle user details side pane textfields
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [userRole, setUserRole] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyMcNumber, setCompanyMcNumber] = useState('');
    const [changedValue, setChangedValue] = useState(false);
    const [notes, setNotes] = useState('');

    // Constants to handle new user request side pane text fields
    const [newUserAddress, setNewUserAddress] = useState('');
    const [newUserState, setNewUserState] = useState('');
    const [newUserCity, setNewUserCity] = useState('');
    const [newUserFirstName, setNewUserFirstName] = useState('');
    const [newUserLastName, setNewUserLastName] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newUsercompanyName, setNewUserCompanyName] = useState('');
    const [newUserRole, setNewUserRole] = useState('');
    const [companyRole, setCompanyRole] = useState('');
    const [newUserMobileNumber, setNewUserMobileNumber] = useState('');
    const [newUserEmailAddress, setNewUserEmailAddress] = useState('');
    const [isComapnyAdminDeletable, setIsCompanyAdminDeletable] = useState(false);
    const [isComapnyAdminSuspendable, setIsCompanyAdminSuspendable] = useState(false);
    const [noOfAcceptedCompanyAdmins, setNoOfAcceptedCompanyAdmins] = useState(false);
    const [newUserNotes, setNewUserNotes] = useState('');

    //constant to handle logged in company name
    const [loggedInUserCompanyName, setLoggedInuserCompanyName] = useState('');

    //constant to handle logged in company Role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');

    // Constant to handle errors
    const [errors, setErrors] = useState({});
    const [inviteErrors, setInviteErrors] = useState({});
    const { user } = useAuth0();

    //Constants to handle MUIDatatable
    const [rowData, setRowData] = useState([]);
    const [rowUserData, setRowUserData] = useState([]);
    const [invitedUserRowData, setInvitedUserRowData] = useState([]);

    //constant to handle mui data table loader
    const [loadingNewUsers, setLoadingNewUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingInvitedUsers, setLoadingInvitedUsers] = useState(false);

    // constant to handle disabling button first click
    const [disableApproveButton, setDisableApproveButton] = useState(false);
    const [disableRejectButton, setDisableRejectButton] = useState(false);

    // constant to handle disabling the comment box
    const [disableNotes, setDisableNotes] = useState(false);

    // Function to handle email change
    const handleEmailIdChange = (event) => {
        setInviteUserMailId(event.target.value);
    };

    //Function to handle invited user role change
    const handleInvitedUserRoleChange = (event) => {
        setInviteUserRole(event.target.value);
    };

    const ViewInvitedUserDetails = (rowValues) => {
        setIsInviteUserPaneOpen(true);
        setInviteUserGridPercent('45%');
        if (invitedUserRowData !== undefined && invitedUserRowData !== null) {
            const completeSelectedRowData = invitedUserRowData.find(({ user_id }) => user_id === rowValues[0]);
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setInvitedUserMailId(completeSelectedRowData.email_address);
                setInvitedUserMcNumber(completeSelectedRowData.company_mc_number);
                setInvitedUserRole(completeSelectedRowData.user_role);
                setInvitedUserCompanyName(completeSelectedRowData.company_name);
                setInvitedUserCompanyRole(completeSelectedRowData.company_role);
            }
        }
    };

    //options of invited userdetails table
    const invitedUseroptions = {
        onRowClick: (clickNewUserRowData, rowState) =>
            ViewInvitedUserDetails(clickNewUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: false,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingInvitedUsers ?
                    <CircularProgress /> :
                    `There are no invited user request for ${pascalCase(loggedInUserCompanyName)}`
            },
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to show Details
    const UserDetails = (rowValues) => {
        setClickRowData(rowValues);
        setIsEditOpen(true);
        setGridPercent('45%');
        setDisableNotes(false);
        setNewUserNotes(rowValues[7]);
        handleClose();
        if (rowUserData !== undefined && rowUserData !== null) {
            const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0]);
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setUserName(completeSelectedRowData.user_name);
                setAddress(completeSelectedRowData.address);
                if (completeSelectedRowData.first_name === undefined) {
                    setFirstName('');
                }
                else {
                    setFirstName(completeSelectedRowData.first_name);
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setLastName('');
                }
                else {
                    setLastName(completeSelectedRowData.last_name);
                }
                setCity(completeSelectedRowData.city);
                setCompanyName(completeSelectedRowData.company_name);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
                setEmailAddress(completeSelectedRowData.email_address);
                setMobileNumber(completeSelectedRowData.mobile_number);
                setState(completeSelectedRowData.state);
                setUserRole(completeSelectedRowData.user_role);
                setCompanyRole(completeSelectedRowData.company_role);
                setUserId(completeSelectedRowData.user_id);
                setNotes(completeSelectedRowData.notes);
                setIsDetailOpen(true);
                if (completeSelectedRowData.user_role === 'CompanyAdmin' && completeSelectedRowData.login_status === 'profileRejected') {
                    setIsCompanyAdminDeletable(true);
                }
                else if (completeSelectedRowData.user_role === 'CompanyAdmin') {
                    let mcNumer = completeSelectedRowData.company_mc_number;
                    let noOfActiveCompanyAdmins = 0;
                    let noOfCompanyAdmins = 0;
                    let noOfSuspendedCompanyAdmins = 0;
                    for (let i = 0; i < rowUserData.length; i++) {
                        if ((rowUserData[i].company_mc_number === mcNumer && rowUserData[i].user_role === 'CompanyAdmin' && rowUserData[i].login_status === 'ProfileAccepted')) {
                            noOfActiveCompanyAdmins += 1;
                            if (noOfActiveCompanyAdmins <= 1) {
                                setIsCompanyAdminSuspendable(false);
                            }
                            else {
                                setIsCompanyAdminSuspendable(true);
                                setIsCompanyAdminDeletable(true);
                                setNoOfAcceptedCompanyAdmins(true);
                            }
                        }
                    }
                    for (let i = 0; i < rowUserData.length; i++) {
                        if (rowUserData[i].company_mc_number === mcNumer && rowUserData[i].user_role === 'CompanyAdmin') {
                            noOfCompanyAdmins += 1;
                            if (noOfCompanyAdmins <= 1) {
                                setIsCompanyAdminDeletable(false);
                            }
                            else {
                                setIsCompanyAdminDeletable(true);
                            }
                        }
                    }
                    for (let i = 0; i < rowUserData.length; i++) {
                        if (rowUserData[i].company_mc_number === mcNumer && rowUserData[i].user_role === 'CompanyAdmin' && rowUserData[i].login_status === 'ProfileSuspended') {
                            noOfSuspendedCompanyAdmins += 1;
                            if (noOfActiveCompanyAdmins - noOfSuspendedCompanyAdmins === 1 || noOfActiveCompanyAdmins - noOfSuspendedCompanyAdmins === 0) {
                                setNoOfAcceptedCompanyAdmins(false);
                            }
                        }
                    }
                }
                else {
                    setIsCompanyAdminDeletable(true);
                    setIsCompanyAdminSuspendable(true);
                }
            }
        }
    };

    //options of userdetails table
    const options = {
        onRowClick: (clickRowData, rowMeta) =>
            UserDetails(clickRowData, rowMeta),
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingUsers ?
                    <CircularProgress /> :
                    `There are no users for ${pascalCase(loggedInUserCompanyName)}`
            },
        }
    };

    //function to trigger on click of edit in userdetail table
    const EditChanges = (selectedRowData) => {
        setClickNewUserRowData(selectedRowData);
        setDisableApproveButton(false);
        setDisableRejectButton(false);
        setMainGridPercent('45%');
        if (selectedRowData !== undefined && selectedRowData !== null) {
            const completeSelectedRowData = rowData.find(({ user_id }) => user_id === selectedRowData[0]);
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setNewUserName(completeSelectedRowData.user_name);
                setNewUserAddress(completeSelectedRowData.address);
                setNewUserCity(completeSelectedRowData.city);
                setNewUserCompanyName(completeSelectedRowData.company_name);
                setCompanyRole(completeSelectedRowData.company_role);
                setNewUserEmailAddress(completeSelectedRowData.email_address);
                setNewUserMobileNumber(completeSelectedRowData.mobile_number);
                setNewUserState(completeSelectedRowData.state);
                if (completeSelectedRowData.first_name === undefined) {
                    setNewUserFirstName('');
                }
                else {
                    setNewUserFirstName(completeSelectedRowData.first_name);
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setNewUserLastName('');
                }
                else {
                    setNewUserLastName(completeSelectedRowData.last_name);
                }
                setNewUserRole(completeSelectedRowData.user_role);
                setUserId(completeSelectedRowData.user_id);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
                setIsPaneOpen(true);
            }
        }
    };

    //options of newuser details table
    const options1 = {
        onRowClick: (clickNewUserRowData, rowState) =>
            EditChanges(clickNewUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: false,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingNewUsers ?
                    <CircularProgress /> :
                    `There are no new user request for ${pascalCase(loggedInUserCompanyName)}`
            },
        }
    };

    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {},
            },
        });

    // column definition for invited user table
    const columnDefsInvitedUserTable = [
        { label: 'UserId', name: 'user_id', options: { display: false } },
        {
            name: 'email_address',
            label: 'Email Id',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'company_mc_number',
            label: 'MC Number',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'company_name',
            label: 'Company Name',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'user_role',
            label: 'Role',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'profile_status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    //column values for newuser table
    const columnDefs = [
        { label: 'UserId', name: 'user_id', options: { display: false } },
        {
            name: 'user_name',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        pascalCase(rowuserdata.rowData[1])
                    );
                }
            }
        },
        {
            name: 'email_address',
            label: 'Email Id',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'mobile_number',
            label: 'Mobile',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'user_role',
            label: 'Role',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[4] === 'CompanyAdmin' ? 'Company Admin' : rowuserdata.rowData[4] === 'Broker&Carrier' ? 'Broker & Carrier' : rowuserdata.rowData[4]);
                }
            }
        },
    ];

    //column values for UserDetails table
    const columns = [
        { label: 'UserId', name: 'user_id', options: { display: false } },
        {
            name: 'user_name',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        pascalCase(rowuserdata.rowData[1])
                    );
                }
            }
        },
        {
            name: 'email_address',
            label: 'Email Id',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'mobile_number',
            label: 'Mobile Number',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'user_role',
            label: 'Role',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[4] === 'CompanyAdmin' ? 'Company Admin' : rowuserdata.rowData[4] === 'Broker&Carrier' ? 'Broker & Carrier' : rowuserdata.rowData[4]);
                }
            }
        },
        {
            name: 'login_status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[5] === 'ProfileAccepted' ? 'Accepted' : rowuserdata.rowData[5] === 'ProfileRejected' ? 'Not Approved' : rowuserdata.rowData[5] === 'ProfileSuspended' ? 'Suspended' : rowuserdata.rowData[7] === 'ProfileDeleted' ? 'Deleted' : 'Activated'
                    );
                }
            }
        },
        {
            name: 'notes',
            label: 'Notes',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let condition = rowuserdata.rowData[6] ? rowuserdata.rowData[6].length >= 20 ? rowuserdata.rowData[6].substr(0, 20) + '...' : rowuserdata.rowData[8] : 'NA'
                    return (
                        rowuserdata.rowData[5] === 'ProfileRejected' ? condition : rowuserdata.rowData[6] ? condition : 'NA'
                    );
                }
            }
        },
    ];

    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    // function to handle auto populating company details in invite user form
    const getCompanyInformationForInvitingUser = async (company_mc_number) => {
        try {
            let companyInformationData = await getparticularcompanyinformationApiCall('?company_mc_number=', company_mc_number);
            if (companyInformationData) {
                if (companyInformationData !== 'UserNotPresent') {
                    setInviteUserMCNumber(companyInformationData[0].company_mc_number);
                    setInviteUserCompanyName(companyInformationData[0].company_name);
                    setInviteUserCompanyRole(companyInformationData[0].company_role);
                    setInviteUserCompanyAddress(companyInformationData[0].address);
                    setInviteUserCompanyCity(companyInformationData[0].city);
                    setInviteUserCompanyState(companyInformationData[0].state);
                }
            }
        }
        catch (error) {
            console.log('get company information error', error);
        }
    };

    //Function to handle notes
    const handleNotesChange = (val) => {
        setIsFieldEdited(true);
        setNewUserNotes(val.target.value);
    };

    // Function to get invited users 
    const getInvitedUsers = async () => {
        try {
            let invitedUserData = await getInvitedUserApiCall('?company_mc_number=', userReduxData.userData.company_mc_number);
            if (invitedUserData !== undefined) {
                if (invitedUserData !== 'UserNotPresent') {
                    if (invitedUserData.length === 0) {
                        setLoadingInvitedUsers(false);
                    }
                    else {
                        setLoadingInvitedUsers(true);
                    }
                    setInvitedUserRowData(invitedUserData);
                }
                else {
                    setInvitedUserRowData([]);
                    if (invitedUserData.length === 0) {
                        setLoadingInvitedUsers(false);
                    }
                    else {
                        setLoadingInvitedUsers(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error, 'Getting invited user Failed');
        }
    };

    // Function to get values of newuser Table
    const getAllResponse = async (company_mc_number) => {
        try {
            let newUserForCompanyDashboardData = await getNewUsersForCompanyDashboardApiCall(company_mc_number);
            if (newUserForCompanyDashboardData !== undefined) {
                if (newUserForCompanyDashboardData !== 'UserNotPresent') {
                    if (newUserForCompanyDashboardData.length === 0) {
                        setLoadingNewUsers(false);
                    }
                    else {
                        setLoadingNewUsers(true);
                    }
                    const userDetails = [];
                    newUserForCompanyDashboardData.forEach(newUserData => {
                        if (newUserData.mc_numbers) {
                            const newUsermcNumbers = newUserData['mc_numbers'][0]['mcNumber'];
                            userDetails.push({ ...newUserData, 'mc_number': newUsermcNumbers });
                        }
                    });
                    userDetails.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );
                    setRowData(userDetails);
                }
                else {
                    setRowData([]);
                    if (newUserForCompanyDashboardData.length === 0) {
                        setLoadingNewUsers(false);
                    }
                    else {
                        setLoadingNewUsers(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error, 'Get user for company dashboard failed');
        }
    };

    // Function to fetch data from api for User Details Company Name table
    const getUserDetailsResponse = async (company_mc_number) => {
        try {
            let approvedCompanyUserDetails = await getApprovedUserDetailsForCompanyApiCall(company_mc_number);
            if (approvedCompanyUserDetails !== undefined) {
                if (approvedCompanyUserDetails !== 'UserNotPresent') {
                    if (approvedCompanyUserDetails.length === 0) {
                        setLoadingUsers(false);
                    }
                    else {
                        setLoadingUsers(true);
                    }
                    const userDetails = [];
                    approvedCompanyUserDetails.forEach(approvedUserData => {
                        if (approvedUserData.mc_numbers && approvedUserData.email_address !== user.email) {
                            const mcNumbers = approvedUserData['mc_numbers'][0]['mcNumber'];
                            userDetails.push({ ...approvedUserData, 'mc_number': mcNumbers });
                        }
                    });
                    userDetails.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );
                    setRowUserData(userDetails);
                }
                else {
                    setRowUserData([]);
                    if (approvedCompanyUserDetails.length === 0) {
                        setLoadingUsers(false);
                    }
                    else {
                        setLoadingUsers(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error, 'Fetch all approved user error');
        }
    };

    //function to handle validation for userdetail table
    const validate = () => {
        let validation = {};
        validation.state = state ? '' : 'error';
        validation.city = city ? '' : 'error';
        validation.userName = userName ? '' : 'error';
        validation.firstName = firstName ? '' : 'error';
        validation.lastName = lastName ? '' : 'error';
        //temp.mobileNumber = mobileNumber ? "" : "error"
        validation.address = address ? '' : 'error';
        setErrors({
            ...validation
        });
        return Object.values(validation).every(x => x === '');
    };

    const validateInviteForm = () => {
        let validation = {};
        validation.inviteUserMailId = inviteUserMailId ? '' : 'error';
        validation.inviteUserRole = inviteUserRole ? '' : 'error';
        setInviteErrors({
            ...validation
        });
        return Object.values(validation).every(x => x === '');
    };

    //Function to handle clearing invite fields
    const clearInviteForm = () => {
        setInviteUserMailId('');
        setInviteUserRole('');
    };

    //function to handle validation for newuser table
    const newUserValidate = () => {
        let temp = {};
        temp.newUserName = newUserName ? '' : 'error';
        temp.newUserAddress = newUserAddress ? '' : 'error';
        temp.newUserCity = newUserCity ? '' : 'error';
        temp.newUserState = newUserState ? '' : 'error';
        temp.newUserFirstName = newUserFirstName ? '' : 'error';
        temp.newUserLastName = newUserLastName ? '' : 'error';
        //temp.newUserMobileNumber = newUserMobileNumber ? "" : "error"
        temp.newUserRole = newUserRole ? '' : 'error';
        setErrors({
            ...temp
        });
        return Object.values(temp).every(x => x === '');
    };

    // Function to handle pane close
    const closeInvitedUserSecondaryPane = () => {
        setInviteUserGridPercent('100%');
        setIsInviteUserPaneOpen(false);
    };

    // Function to handle pane close
    const closeSecondaryPane = () => {
        setMainGridPercent('100%');
        setIsPaneOpen(false);
    };

    // Function to handle MuiAlert
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    //function to close secondary pane
    const closeSecondaryDetailPane = () => {
        setGridPercent('100%');
        setIsDetailOpen(false);
    };

    //function to close secondary pane of userdetails Table
    const closeSecondaryUserPane = () => {
        setGridPercent('100%');
        setIsDetailOpen(false);
    };

    //Function to set value for text field
    const handleChangedValue = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            setChangedValue(true);
        }
        else {
            setChangedValue(false);
        }
    };

    // Function to handle FirstName changes in NewUser Table
    const handleNewUserFirstNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserFirstName(event.target.value);
        }
    };

    // Function to handle LastName changes in NewUser Table
    const handleNewUserLastNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserLastName(event.target.value);
        }
    };

    //Function to handle new user state
    const handleNewUserState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserState(event.target.value);
        }
    };

    //Function to handle new user city
    const handleNewUserCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserCity(event.target.value);
        }
    };

    //Function to handle new user address
    const handleNewUserAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserAddress(event.target.value);
        }
    };

    //Function to handle user table phone no change
    const handleNewUserPhoneNoChange = (value) => {
        setNewUserMobileNumber(value);
        setIsFieldEdited(true);

    };

    // Function to handle FirstName changes in UserDetails
    const handleFirstNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setFirstName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to handle LastName changes in UserDetails
    const handleLastNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setLastName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to show Details
    const handleCompanyName = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompanyName(event.target.value);
        }
    };

    //Function to handle state change
    const handleState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setState(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to handle City Changes
    const handleCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCity(event.target.value);
            setIsFieldEdited(true);
        }
    };

    //Function to handle address change
    const handleAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setAddress(event.target.value);
            setIsFieldEdited(true);
        }
    };

    //Function to handle user table phone no change
    const handleUserPhoneNoChange = (value) => {
        setMobileNumber(value);
        setIsFieldEdited(true);
    };

    //function to handle Invite button click
    const handleIviteButtonClick = async () => {
        if (validateInviteForm()) {
            let myuuid = uuidv4();
            let listValues = {
                'email_address': inviteUserMailId,
                'user_role': inviteUserRole,
                'company_name': inviteUserCompanyName,
                'company_role': inviteUserCompanyRole,
                'verification_code': myuuid.slice(-5),
                'company_mc_number': inviteUserMCNumber,
                'profile_status': 'Invited'
            };
            try {
                let response = await axios({
                    url: 'https://loadeo.us.auth0.com/dbconnections/signup',
                    method: 'post',
                    data: {
                        'client_id': process.env.REACT_APP_AUTH0_CLIENT_ID,
                        'connection': 'Username-Password-Authentication',
                        'password': inviteUserMailId.split('@')[0] + '@123',
                        'email': inviteUserMailId,
                        'nickname': 'invitedUser' + myuuid.slice(-5)
                    }
                });
                if (response) {
                    try {
                        let res = await inviteUserApiCall(listValues);
                        if (res !== undefined) {
                            setSnackBarMessageText('Invitation is succeded');
                            setOpenSnackBar(true);
                            setColorseverity('success');
                            clearInviteForm();
                            getInvitedUsers();
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }
            catch (err) {
                console.log(err);
                setSnackBarMessageText('Error while inviting user from Auth0');
                setOpenSnackBar(true);
                setColorseverity('error');
                clearInviteForm();
            }
        }
    };

    //function to update the changes on save changes of userdetails table
    const EditUserDetails = async (rowValues) => {
        if (validate()) {
            if ((mobileNumber.length === 14 || mobileNumber.length === 13 || mobileNumber.length === 10) && mobileNumber !== '') {
                let completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === userId);
                if ((userRole === 'Carrier') || (userRole === 'Broker&Carrier')) {
                    completeSelectedRowData = { ...completeSelectedRowData, 'carrier_preferred': 'Preferred' };
                }
                let listValues = completeSelectedRowData;
                listValues.user_name = userName;
                listValues.first_name = firstName;
                listValues.last_name = lastName;
                listValues.address = address;
                listValues.city = city;
                listValues.state = state;
                listValues.mobile_number = mobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
                listValues.user_role = userRole;
                listValues.new_user_status = 'false';
                try {
                    let companyAdminData = await editCompanyAdminApiCall(listValues);
                    if (companyAdminData !== undefined) {
                        getAllResponse(companyAdminData.company_mc_number);
                        getUserDetailsResponse(companyAdminData.company_mc_number);
                        setColorseverity('success');
                        setSnackBarMessageText(rowValues[1] + ' has been Updated');
                        setOpenSnackBar(true);
                        closeSecondaryUserPane();
                    }
                }
                catch (error) {
                    console.log(error, 'Error in editing company admin data');
                }

            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    };

    //function to trigger on click of approve in newuser table
    const approveUserInformation = async (rowValues) => {
        setDisableApproveButton(true);
        if (newUserValidate()) {
            if ((newUserMobileNumber.length === 14 || newUserMobileNumber.length === 13 || newUserMobileNumber.length === 10) && newUserMobileNumber !== '') {
                let completeSelectedRowData = rowData.find(({ user_id }) => user_id === userId);
                if ((userRole === 'Carrier') || (userRole === 'Broker&Carrier')) {
                    completeSelectedRowData = { ...completeSelectedRowData, 'carrier_preferred': 'Preferred' };
                }
                let listValues = completeSelectedRowData;
                listValues.user_name = newUserName;
                listValues.address = newUserAddress;
                listValues.city = newUserCity;
                listValues.state = newUserState;
                listValues.first_name = newUserFirstName;
                listValues.last_name = newUserLastName;
                listValues.mobile_number = newUserMobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
                listValues.user_role = newUserRole;
                listValues.notes = newUserNotes;
                listValues.new_user_status = 'false';
                listValues.login_status = 'ProfileAccepted';
                try {
                    let loadeoUserDetailsModifying = await updateLoadeoUserDetailsApiCall(listValues, 'CompanyAdmin');
                    if (loadeoUserDetailsModifying !== undefined) {
                        getAllResponse(loadeoUserDetailsModifying.company_mc_number);
                        getUserDetailsResponse(loadeoUserDetailsModifying.company_mc_number);
                        setColorseverity('success');
                        setSnackBarMessageText(rowValues[1] + ' has been approved');
                        setOpenSnackBar(true);
                        closeSecondaryPane();
                        try {
                            await approveUserNotificationApiCall(completeSelectedRowData.email_address);
                        }
                        catch (error) {
                            console.log(error, 'User approved notification failed to send');
                        }
                    }
                }
                catch (error) {
                    console.log(error, 'Failed to approve user Information');
                }
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
        setDisableApproveButton(false);
    };

    //function to trigger on click of reject in newuser table
    const rejectedUser = async (rowValues) => {
        setDisableRejectButton(true);
        let completeSelectedRowData = rowData.find(({ user_id }) => user_id === userId);
        let listValues = completeSelectedRowData;
        listValues.mobile_number = newUserMobileNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
        listValues.login_status = 'ProfileRejected';
        listValues.new_user_status = 'false';
        listValues.notes = newUserNotes;
        listValues = { ...listValues };
        listValues = { ...listValues, 'rejected_by': 'CompanyAdmin' };
        try {
            let loadeoUserDetailsModifying = await updateLoadeoUserDetailsApiCall(listValues, 'CompanyAdmin');
            if (loadeoUserDetailsModifying !== undefined) {
                getAllResponse(loadeoUserDetailsModifying.company_mc_number);
                getUserDetailsResponse(loadeoUserDetailsModifying.company_mc_number);
                closeSecondaryPane();
                setColorseverity('error');
                setSnackBarMessageText(rowValues[1] + ' has been rejected');
                setOpenSnackBar(true);
                try {
                    await sendRejectedMailApiCall(completeSelectedRowData.email_address);
                }
                catch (error) {
                    console.log(error, 'Send rejected email for user failed in company Admin');
                }
            }
            setDisableRejectButton(false);
        }
        catch (error) {
            console.log(error, 'Failed to reject user');
        }
    };

    // Function to show pop-up while Suspend
    const handleRejectConfirmClick = (rowValues) => {
        handleClose();
        Swal.fire({
            text: 'Are you sure you want to reject ' + rowValues[1] + '?',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                rejectedUser(rowValues);
            }
        });
    };

    const EditSelected = (rowvalues) => {
        setIsEditOpen(false);
        setGridPercent('45%');
        handleClose();
        if (rowUserData !== undefined && rowUserData !== null) {
            const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowvalues[0]);
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setUserName(completeSelectedRowData.user_name);
                setAddress(completeSelectedRowData.address);
                setCity(completeSelectedRowData.city);
                if (completeSelectedRowData.first_name === undefined) {
                    setFirstName('');
                }
                else {
                    setFirstName(completeSelectedRowData.first_name);
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setLastName('');
                }
                else {
                    setLastName(completeSelectedRowData.last_name);
                }
                setCompanyName(completeSelectedRowData.company_name);
                setEmailAddress(completeSelectedRowData.email_address);
                setMobileNumber(completeSelectedRowData.mobile_number);
                setState(completeSelectedRowData.state);
                setUserRole(completeSelectedRowData.user_role);
                setUserId(completeSelectedRowData.user_id);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
            }
        }
    };

    // Function to show pop-up while delete
    const handleCancelButtonConfirmClick = (rowValues) => {
        handleClose();
        if (isFieldEdited) {
            Swal.fire({
                text: 'Do you want to save the changes before cancelling',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    EditUserDetails(rowValues);
                    UserDetails(rowValues);
                }
            });
        }
        else {
            UserDetails(rowValues);
        }
    };

    // Function to suspend the user
    const SuspendUser = async (rowValues) => {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0]);
        let listValues = completeSelectedRowData;
        listValues.login_status = 'ProfileSuspended';
        listValues = { ...listValues };
        try {
            let loadeoUserDetailsModifying = await updateLoadeoUserDetailsApiCall(listValues, 'CompanyAdmin');
            if (loadeoUserDetailsModifying !== undefined) {
                getAllResponse(loadeoUserDetailsModifying.company_mc_number);
                getUserDetailsResponse(loadeoUserDetailsModifying.company_mc_number);
                setColorseverity('error');
                setSnackBarMessageText(rowValues[1] + ' has been Suspended');
                setOpenSnackBar(true);
            }
            closeSecondaryUserPane();
        }
        catch (error) {
            console.log(error, 'Failed to suspend user');
        }

    };

    // Function to show pop-up while Suspend
    const handleSuspendConfirmClick = (rowValues) => {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0]);
        handleClose();
        if (user.email === completeSelectedRowData.email_address) {
            Swal.fire({
                text: 'Cannot suspend own account',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            });
        }
        else if (isComapnyAdminSuspendable) {
            Swal.fire({
                text: 'Are you sure you want to suspend ' + rowValues[1] + '?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    SuspendUser(rowValues);
                }
            });
        }
        else (
            Swal.fire({
                text: 'Cannot suspend Company Admin',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        );
    };

    // Function to activate user
    const ActivateUser = async (rowValues) => {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0]);
        let listValues = completeSelectedRowData;
        listValues.login_status = 'ProfileAccepted';
        listValues = { ...listValues };
        try {
            let loadeoUserDetailsModifying = await updateLoadeoUserDetailsApiCall(listValues, 'CompanyAdmin');
            if (loadeoUserDetailsModifying !== undefined) {
                getUserDetailsResponse(loadeoUserDetailsModifying.company_mc_number);
                setColorseverity('success');
                setSnackBarMessageText(rowValues[1] + ' has been Activated');
                setOpenSnackBar(true);
            }
            closeSecondaryUserPane();
        }
        catch (error) {
            console.log(error, 'Failed to activate user');
        }
    };

    // Function to show pop-up while Activate
    const handleActivateConfirmClick = (rowValues) => {
        handleClose();
        Swal.fire({
            text: 'Are you sure you want to activate ' + rowValues[1] + ' ?',
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                ActivateUser(rowValues);
            }
        });
    };

    // Function to delete the user
    const handleSoftDelete = async (rowValues) => {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0]);
        let listValues = completeSelectedRowData;
        listValues.login_status = 'ProfileDeleted';
        listValues = { ...listValues };
        try {
            let companyAdminData = await editCompanyAdminApiCall(listValues);
            if (companyAdminData !== undefined) {
                getAllResponse(companyAdminData.data.company_mc_number);
                getUserDetailsResponse(companyAdminData.data.company_mc_number);
                setColorseverity('error');
                setSnackBarMessageText(rowValues[1] + ' has been Deleted');
                setOpenSnackBar(true);
            }
            closeSecondaryUserPane();
        }
        catch (error) {
            console.log(error, 'Error in editing company admin data');
        }
    };

    // Function to show pop-up while delete
    const handleSoftDeleteConfirmClick = (rowValues) => {
        const completeSelectedRowData = rowUserData.find(({ user_id }) => user_id === rowValues[0]);
        handleClose();
        if ((isComapnyAdminDeletable && completeSelectedRowData.login_status !== 'ProfileAccepted') || (isComapnyAdminDeletable && noOfAcceptedCompanyAdmins && completeSelectedRowData.login_status === 'ProfileAccepted') || (completeSelectedRowData.user_role !== 'CompanyAdmin')) {
            Swal.fire({
                text: 'Are you sure you want to delete ' + rowValues[1] + ' ?',
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handleSoftDelete(rowValues);
                }
            });
        }
    };

    // Function to getall response of both the tables
    const checkUserRole = () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === 'ProfileAccepted') {
                if (userReduxData.userData.company_role) {
                    setLoggedInuserCompanyRole(userReduxData.userData.company_role);
                }
                if (userReduxData.userData.company_name) {
                    setLoggedInuserCompanyName(userReduxData.userData.company_name);
                }
                if (user.email === process.env.REACT_APP_ADMIN_USER || userReduxData.userData.user_role === 'CompanyAdmin') {
                    getAllResponse(userReduxData.userData.company_mc_number);
                    getUserDetailsResponse(userReduxData.userData.company_mc_number);
                    getCompanyInformationForInvitingUser(userReduxData.userData.company_mc_number);
                }
                else {
                    history.push({ pathname: '/forbidden' });
                }
            }
            else {
                history.push({ pathname: '/forbidden' });
            }
        }
    };
    useEffect(() => {
        if (Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
        getInvitedUsers();
    }, [userReduxData]);

    /*************************************************************************************************/
    return (
        <div className={classes.root}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <div className="main" style={{ padding: '60px 30px 10px 20px', width: '100%' }} id="InvitedUsersForm">
                    <Paper elevation={3}  >
                        <Grid container spacing={3} style={{paddingTop:'10px'}} className='invite1stGrid'>
                            <Grid item xs={4}>
                                <b className='invitePaddingLeft'>Invite User</b>
                            </Grid>
                            <Grid item xs={3}  >
                            </Grid>
                            <Grid container xs={5}  >
                            </Grid>
                        </Grid>
                        <Card height={8} style={{ maxWidth: '80%', margin: '0 auto', paddingTop: '20px', paddingBottom: '20px' }} >
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid container spacing={3} className='companyPaddingleft'>
                                        <Grid item xs >
                                            <TextField id="email" type='email' label="Email Address" placeholder="Enter email" variant="standard" value={inviteUserMailId} className='formWidth' error={inviteErrors.inviteUserMailId && inviteUserMailId === ''} onChange={handleEmailIdChange} required />
                                        </Grid>
                                        <div className="companyDiv" />
                                        <Grid item xs>
                                            <FormControl className='formWidth'>
                                                <InputLabel id="demo-simple-select-label">User Role*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={inviteUserRole}
                                                    onChange={handleInvitedUserRoleChange}
                                                    error={inviteErrors.inviteUserRole && inviteUserRole === ''}>

                                                    <MenuItem value="CompanyAdmin">Company Admin</MenuItem>
                                                    <MenuItem hidden={loggedInuserCompanyRole === 'Broker'} value="Carrier">Carrier User</MenuItem>
                                                    <MenuItem hidden={loggedInuserCompanyRole === 'Broker'} value="Driver">Driver User</MenuItem>
                                                    <MenuItem hidden={loggedInuserCompanyRole === 'Carrier'} value="Broker">Broker User</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <div className="companyDiv" />
                                        <Grid item xs>
                                            <TextField disabled id="invitemcnumber" type='email' variant="standard" label="MC Number" value={inviteUserMCNumber} className='paddingAndWidth' />
                                        </Grid>
                                        <div className="companyDiv" />
                                    </Grid>
                                    <Grid container spacing={3} className='companyPaddingleft'></Grid>
                                    <Grid container spacing={3} className='companyPaddingleft'>
                                        <Grid item xs >
                                            <TextField disabled id="invitecompname" label="Company Name" variant="standard" value={inviteUserCompanyName} className='formWidth' />
                                        </Grid>
                                        <div className="companyDiv" />
                                        <Grid item xs>
                                            <TextField disabled id="invitecomprole" type='email' label="Company Role" variant="standard" value={inviteUserCompanyRole} className='formWidth' />
                                        </Grid>
                                        <div className="companyDiv" />
                                        <Grid item xs>
                                            <TextField disabled id="inviteaddress" type='email' label="Address" variant="standard" value={inviteUserCompanyAddress} className='paddingAndWidth' />
                                        </Grid>
                                        <div className="companyDiv" />
                                    </Grid>
                                    <Grid container spacing={3} className='companyPaddingleft' >
                                        <Grid item xs >
                                            <TextField disabled id="invitecity" type='email' label="City" variant="standard" value={inviteUserCompanyCity} className='formWidth' />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField disabled id="invitestate" type='email' label="State" variant="standard" value={inviteUserCompanyState} className='formWidth' />
                                        </Grid>
                                        <Grid item xs>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} justify="flex-end" paddingTop={35} className='paddigAndHeight'>
                                    <Button type='submit' variant="contained" style={{ marginRight: '10%' }} className={(inviteUserMailId !== '' && inviteUserRole !== '') ? 'commonButton' : 'commonDisableButton'} disabled={inviteUserMailId !== '' && inviteUserRole !== '' ? false : true} onClick={handleIviteButtonClick}>Invite</Button>
                                </Grid>
                            </CardContent>
                        </Card>
                        <div style={{ height: 30 }} />
                    </Paper>
                </div>
                {/*Invite user Table */}
                <Typography component={'div'}>
                    <Grid container direction="row" margin={3}>
                        <div style={{ width: inviteUserGridPercent }} className='paddigAndHeight' id="InvitedUsers">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'Invited User List'}
                                    data={invitedUserRowData}
                                    columns={columnDefsInvitedUserTable}
                                    options={invitedUseroptions}
                                />
                            </MuiThemeProvider>
                        </div><br></br>
                        {isInviteUserPaneOpen && (
                            <React.Fragment>
                                <div className='userTableWidth'></div>
                                <div className='paneWidth' border={1} style={{ marginTop: 63 }}>
                                    <Box
                                        boxShadow={3}
                                        className='invitePaneBox'
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={3} justify-content="center">
                                                <b className='tableHeaderSize' >User Details</b>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <IconButton onClick={closeInvitedUserSecondaryPane}>
                                                    <Tooltip title="Close">
                                                        <CloseIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Row >
                                            <Col sm>
                                            </Col>
                                        </Row>
                                        <div className='paddigAndHeight' />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" className='textfieldForTable' value={pascalCase(invitedUserCompanyName)}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" className='textfieldForTable' value={invitedUserMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" className='textfieldForTable' value={invitedUserCompanyRole}></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" className='textfieldForTable' value={invitedUserMailId} ></TextField>
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <TextField disabled={user.email === newUserEmailAddress} label="First Name *" style={{ width: "85%" }} error={errors.newUserFirstName} value={pascalCase(newUserFirstName)} onChange={handleNewUserFirstNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={user.email === newUserEmailAddress} label="Last Name *" style={{ width: "85%" }} error={errors.newUserLastName} value={pascalCase(newUserLastName)} onChange={handleNewUserLastNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ paddingTop: "3%" }} />
                                                        <PhoneInput
                                                            disabled={user.email === newUserEmailAddress}
                                                            value={newUserMobileNumber}
                                                            onChange={(value, data, event, formattedValue) => { handleNewUserPhoneNoChange(value, data, event, formattedValue) }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            country={'us'}
                                                            defaultCountry={"us"}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                width: "80%",
                                                                border: "1px solid black"
                                                            }}
                                                            //dropdownStyle={{ height: '40%' }}
                                                            inputStyle={{ width: "100%" }}
                                                        />
                                                    </Grid> */}
                                                    <Grid item xs={5}>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role " className='textfieldForTable' value={invitedUserRole} ></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                        {/*New User Table */}
                        <div style={{ width: mainGridPercent }} className='paddigAndHeight' id="NewUsers">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'New User List'}
                                    data={rowData}
                                    columns={columnDefs}
                                    options={options1}
                                />
                            </MuiThemeProvider>
                        </div><br></br>
                        {isPaneOpen && (
                            <React.Fragment>
                                <div className='userTableWidth'></div>
                                <div className='paneWidth' style={{ paddingTop: 5 }} border={1}>
                                    <Box
                                        boxShadow={3}
                                        className='newUserPaneBox'
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={3} justify-content="center">
                                                <b className='tableHeaderSize'>User Details</b>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <IconButton onClick={closeSecondaryPane}>
                                                    <Tooltip title="Close">
                                                        <CloseIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Row >
                                            <Col sm>
                                            </Col>
                                        </Row>
                                        <div className='paddigAndHeight' />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" className='textfieldForTable' value={pascalCase(newUsercompanyName)}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" className='textfieldForTable' value={companyMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" className='textfieldForTable' value={companyRole}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Address *" className='textfieldForTable' error={errors.newUserAddress} value={pascalCase(newUserAddress)} onChange={handleNewUserAddress}>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="City *" className='textfieldForTable' error={errors.newUserCity} value={pascalCase(newUserCity)} onChange={handleNewUserCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="State *" className='textfieldForTable' error={errors.newUserState} value={pascalCase(newUserState)} onChange={handleNewUserState} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" className='textfieldForTable' value={newUserEmailAddress} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={user.email === newUserEmailAddress} label="First Name *" className='textfieldForTable' error={errors.newUserFirstName} value={pascalCase(newUserFirstName)} onChange={handleNewUserFirstNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={user.email === newUserEmailAddress} label="Last Name *" className='textfieldForTable' error={errors.newUserLastName} value={pascalCase(newUserLastName)} onChange={handleNewUserLastNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div className='paddingforGrid' />
                                                        <PhoneInput
                                                            disabled={user.email === newUserEmailAddress}
                                                            value={newUserMobileNumber}
                                                            onChange={(value, data, event, formattedValue) => { handleNewUserPhoneNoChange(value, data, event, formattedValue); }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            country={'us'}
                                                            defaultCountry={'us'}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                width: '80%',
                                                                border: '1px solid black'
                                                            }}
                                                            //dropdownStyle={{ height: '40%' }}
                                                            inputStyle={{ width: '100%' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role " className='textfieldForTable' value={newUserRole} ></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Row>
                                                <TextField
                                                    label="Notes"
                                                    autoComplete='off'
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    style={{ width: '70%', backgroundColor: '#ffffff', marginLeft: '60px', marginTop: '10px' }}
                                                    value={newUserNotes}
                                                    onChange={(val) => handleNotesChange(val)}
                                                    disabled={disableNotes}
                                                    placeholder="Maximum 250 characters"
                                                    inputProps={{ maxLength: 250 }}
                                                />
                                            </Row>
                                        </Grid>
                                        <div className='paddigAndHeight'>
                                            <Grid container justify="space-around" spacing={4}>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Button variant="contained" color="primary" disabled={disableApproveButton} className='commonButton' onClick={() => { approveUserInformation(clickNewUserRowData); }} >
                                                            Approve
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Button variant="contained" color="primary" disabled={disableRejectButton} className='commonButton' onClick={() => { handleRejectConfirmClick(clickNewUserRowData); }} >
                                                            Reject
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Grid className='tableHeaderSize' xs={12}>
                                            <Grid container direction="row">
                                                <div className='paddigAndHeight'>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                        {/*User Details */}
                        <div style={{ width: gridPercent, paddingBottom: '25%' }} className='paddigAndHeight' id="Users">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'User Details'}
                                    data={rowUserData}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </div>
                        {isDetailOpen && (
                            <React.Fragment>
                                <div className='userTableWidth'></div>
                                <div className='paneWidth' border={1} style={{ marginTop: 65 }}>
                                    <Box
                                        boxShadow={3}
                                        className='userDetailsPaneBox'
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={5}>
                                            </Grid>
                                            <Grid item xs={3} justify-content="center">
                                                <b className='tableHeaderSize' hidden={!isEditOpen}>View Details</b>
                                                <b className='tableHeaderSize' hidden={isEditOpen}>Edit Details</b>
                                            </Grid>
                                            <Grid container xs={4} justify="flex-end">
                                                <React.Fragment>
                                                    <IconButton onClick={() => EditSelected(clickRowData)} hidden={clickRowData[5] === 'Suspended' || clickRowData[5] === 'Not Approved'} >
                                                        <Tooltip title="Edit">
                                                            <Edit />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleSoftDeleteConfirmClick(clickRowData)}>
                                                        <Tooltip title="Delete User">
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton onClick={closeSecondaryDetailPane}>
                                                        <Tooltip title="Close">
                                                            <CloseIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid>
                                        <div className='paddigAndHeight' />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" className='textfieldForTable' onChange={handleCompanyName} value={pascalCase(companyName)} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" className='textfieldForTable' value={companyMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" className='textfieldForTable' value={companyRole}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Address *" className='textfieldForTable' error={errors.address} value={pascalCase(address)} onChange={handleAddress}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="City *" className='textfieldForTable' error={errors.city} value={pascalCase(city)} onChange={handleCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="State *" className='textfieldForTable' error={errors.state} value={pascalCase(state)} onChange={handleState}></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" className='textfieldForTable' value={emailAddress} >{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || user.email === emailAddress} label="First Name *" className='textfieldForTable' error={errors.firstName} value={pascalCase(firstName)} onChange={handleFirstNametxt} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || user.email === emailAddress} label="Last Name *" className='textfieldForTable' error={errors.lastName} value={pascalCase(lastName)} onChange={handleLastNametxt} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div className='paddingforGrid' />
                                                        <PhoneInput
                                                            disabled={isEditOpen || user.email === emailAddress}
                                                            value={mobileNumber}
                                                            onChange={(value, data, event, formattedValue) => { handleUserPhoneNoChange(value, data, event, formattedValue); }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            country={'us'}
                                                            defaultCountry={'us'}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                border: '1px solid black',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role " className='textfieldForTable' value={userRole} ></TextField>
                                                    </Grid>
                                                </Grid>
                                                <Grid className='tableHeaderSize' xs={12}>
                                                    <Grid container direction="row">
                                                        <div className='paddingforGrid'>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={1}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ marginTop: '5%' }}>
                                            <TextField
                                                label="Notes"
                                                autoComplete='off'
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                style={{ width: '75%', backgroundColor: '#ffffff', marginLeft: '60px', marginTop: '10px' }}
                                                value={notes}
                                                disabled={true}
                                                hidden={notes === null}
                                            />
                                        </Grid>
                                        <Grid container spacing={3}>
                                            {(isEditOpen === true) ?
                                                <Grid item xs className='paddigAndHeight' style={{ marginLeft: '35%', marginTop: '5%' }}>
                                                    <Button variant="contained" color="primary" className='commonButton' onClick={() => handleSuspendConfirmClick(clickRowData)} hidden={clickRowData[5] === 'Suspended' || clickRowData[5] === 'Not Approved' || !isEditOpen} >
                                                        Suspend
                                                    </Button>
                                                    <Button variant="contained" color="primary" className='commonButton' onClick={() => handleActivateConfirmClick(clickRowData)} hidden={(clickRowData[5] !== 'Suspended' && clickRowData[5] !== 'Not Approved') || !isEditOpen} >
                                                        Activate
                                                    </Button>
                                                </Grid>
                                                :
                                                <Grid item xs className='SaveButtonSpacing'>
                                                    <Button variant="contained" color="primary" className='commonButton' onClick={() => EditUserDetails(clickRowData)} hidden={isEditOpen} >
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" color="primary" className='commonButton' style={{ marginLeft: '30%' }} onClick={() => handleCancelButtonConfirmClick(clickRowData)} hidden={isEditOpen}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid className='tableHeaderSize' xs={12}>
                                            <Grid container direction="row">
                                                <div className='paddigAndHeight'>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                    </Grid>
                </Typography>
            </main>
        </div>
    );
};

export default CompanyAdmin;