import React from 'react';
export const ProspectiveClientAdminIcon = () => {
    return (
        <svg width="50%" height="30" viewBox="0 0 70 43" style= {{backgroundColor: '#8ea3b9', margin: '25%'}} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.2354 21.2588C35.2496 21.2588 35.2638 21.2588 35.2923 21.2588C35.3065 21.2588 35.3207 21.2588 35.3491 21.2588C44.4596 21.1593 43.1378 8.99308 43.1378 8.99308C42.7682 0.863315 35.9603 0.934379 35.2923 0.962805C34.6242 0.934379 27.8305 0.863315 27.4467 8.99308C27.4467 9.00729 26.1249 21.1735 35.2354 21.2588Z" fill="white" />
            <path d="M56.5553 25.4087C56.5696 25.4087 56.5838 25.4087 56.598 25.4087C56.6122 25.4087 56.6264 25.4087 56.6406 25.4087C63.9603 25.3377 62.9085 15.545 62.9085 15.545C62.61 9.00708 57.1381 9.06393 56.598 9.07814C56.0579 9.04972 50.5859 9.00708 50.2875 15.545C50.2875 15.5592 49.2215 25.3377 56.5553 25.4087Z" fill="white" />
            <path d="M69.9864 35.3293C69.9296 33.2258 69.5174 31.7619 67.3713 30.4117C64.5145 28.6208 61.3166 27.0716 61.3166 27.0716L58.7725 35.1161L57.2943 30.8949C59.8953 27.2564 57.1096 27.0716 56.6121 27.0716H56.5979H56.5837C56.0862 27.0716 53.3005 27.2564 55.9015 30.8949L54.4233 35.1019L51.8792 27.0574C51.8792 27.0574 50.9696 27.498 49.6904 28.1802C49.392 27.9386 49.0651 27.697 48.6955 27.4696C45.1423 25.2382 41.1769 23.3052 41.1769 23.3052L38.0217 33.2969L36.174 28.0523C39.4145 23.5326 35.9466 23.3052 35.3212 23.291H35.307H35.2928C34.6674 23.291 31.1995 23.5184 34.44 28.0523L32.5924 33.2969L29.4371 23.3052C29.4371 23.3052 25.4717 25.2382 21.9185 27.4696C21.5347 27.7112 21.2078 27.9528 20.9094 28.1945C19.616 27.498 18.6922 27.0574 18.6922 27.0574L16.1481 35.1019L14.6699 30.8949C17.2709 27.2564 14.4852 27.0716 13.9877 27.0716H13.9735H13.9593C13.4618 27.0716 10.6761 27.2564 13.2771 30.8949L11.7989 35.1019L9.25482 27.0574C9.25482 27.0574 6.05692 28.6066 3.20013 30.3975C1.03977 31.7477 0.641812 33.2116 0.584961 35.3151V42.4358H13.9451H18.6211H27.3336H35.2644H43.1952H51.9361H56.5837H70.0006L69.9864 35.3293Z" fill="white" />
            <path d="M13.8886 25.4087C13.9029 25.4087 13.9171 25.4087 13.9313 25.4087C13.9455 25.4087 13.9597 25.4087 13.9739 25.4087C21.3078 25.3377 20.2418 15.545 20.2418 15.545C19.9433 9.00708 14.4714 9.06393 13.9313 9.07814C13.3912 9.04972 7.91924 9.00708 7.62077 15.545C7.62077 15.5592 6.56902 25.3377 13.8886 25.4087Z" fill="white" />
        </svg>
    );
};
