import React from 'react';
import Navbar from '../../components/Navbar';
import Breadcumb1 from '../../components/bredcrumb1';
import Footer from '../../components/Footer';
import CompanyDetails from '../../module/SystemAdminCompanies';


const CompanyDetailsPage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'Company Details'} />
            <CompanyDetails />
            <Footer />
        </div>
    );
};

export default CompanyDetailsPage;