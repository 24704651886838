import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

{/* ************************ Notification API calls ************************* */ }

export const addNotificationApiCall = async (data) => {
    try {
        let response = await axios({
            url: environmentVariables.addnotifications,
            method: 'post',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'error in adding notification');
    }

};

export const getNotificationApiCall = async (user) => {
    try {
        let response = await axios({
            url: environmentVariables.getnotifications + '?user_id=' + user,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'Error in get notificatuon api call');
    }

};

export const editNotificationApiCall = async (data, action) => {
    try {
        let response = await axios({
            url: environmentVariables.editnotification + '?action=' + action,
            method: 'patch',
            data: data
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'Error in edit notification api call');
    }

};

export const deleteNotificationApiCall = async (data, functionality) => {
    try {
        let response = await axios({
            url: environmentVariables.deletenotification + '?action=' + functionality,
            method: 'patch',
            data: data,
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'Error in delte notification api call');
    }

};

export const getTabNotificationApiCall = async (loadId) => {
    try {
        let response = await axios({
            url: environmentVariables.gettabnotifications + '?load_id=' + loadId,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    } catch (error) {
        console.log(error, 'Error in get Tab Notification api call');
    }
};