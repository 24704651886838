import React from 'react';
export const LoadsWonIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white" />
            <path d="M16.3333 9.05715V9.67048C16.3325 11.1081 15.867 12.5069 15.0062 13.6584C14.1454 14.8098 12.9355 15.6521 11.5569 16.0597C10.1783 16.4674 8.70484 16.4184 7.35631 15.9202C6.00779 15.422 4.85644 14.5012 4.07397 13.2952C3.29151 12.0892 2.91986 10.6625 3.01445 9.22803C3.10904 7.79353 3.6648 6.42803 4.59885 5.3352C5.5329 4.24236 6.79519 3.48074 8.19746 3.16391C9.59973 2.84709 11.0668 2.99204 12.38 3.57715" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.3346 4.33594L9.66797 11.0093L7.66797 9.00927" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.3333 9.05715V9.67048C16.3325 11.1081 15.867 12.5069 15.0062 13.6584C14.1454 14.8098 12.9355 15.6521 11.5569 16.0597C10.1783 16.4674 8.70484 16.4184 7.35631 15.9202C6.00779 15.422 4.85644 14.5012 4.07397 13.2952C3.29151 12.0892 2.91986 10.6625 3.01445 9.22803C3.10904 7.79353 3.6648 6.42803 4.59885 5.3352C5.5329 4.24236 6.79519 3.48074 8.19746 3.16391C9.59973 2.84709 11.0668 2.99204 12.38 3.57715" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.3346 4.33594L9.66797 11.0093L7.66797 9.00927" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
