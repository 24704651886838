import React from 'react';
export const DashBoardIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white" />
            <path d="M11.3475 16.8839V16.9339H11.3975H16.1864C16.3852 16.9339 16.5758 16.855 16.7164 16.7144C16.8569 16.5738 16.9359 16.3832 16.9359 16.1844V11.3956V11.3456H16.8859H11.3975H11.3475V11.3956V16.8839ZM10.8394 10.3293H17.444C17.5788 10.3293 17.7081 10.3828 17.8034 10.4781C17.8986 10.5734 17.9522 10.7027 17.9522 10.8374V16.1843C17.9512 16.6524 17.7649 17.101 17.4339 17.4319C17.1029 17.7629 16.6543 17.9493 16.1863 17.9502H10.8394C10.7046 17.9502 10.5754 17.8967 10.4801 17.8014C10.3848 17.7061 10.3313 17.5769 10.3313 17.4421V10.8374C10.3313 10.7027 10.3848 10.5734 10.4801 10.4781C10.5754 10.3828 10.7046 10.3293 10.8394 10.3293Z" fill="#333333" stroke="white" strokeWidth="0.1" />
            <path d="M16.8859 8.65465H16.9359V8.60465V3.81581C16.9359 3.61703 16.8569 3.42638 16.7164 3.28581C16.5758 3.14525 16.3852 3.06628 16.1864 3.06628H11.3975H11.3475V3.11628V8.60465V8.65465H11.3975H16.8859ZM10.8394 2.05H16.1863C16.6544 2.05097 17.1029 2.23733 17.4339 2.56828C17.7649 2.89925 17.9512 3.34786 17.9522 3.81592V9.16279C17.9522 9.29756 17.8986 9.42681 17.8034 9.5221C17.7081 9.61739 17.5788 9.67093 17.444 9.67093H10.8394C10.7046 9.67093 10.5754 9.61739 10.4801 9.5221C10.3848 9.42681 10.3313 9.29756 10.3313 9.16279V2.55814C10.3313 2.42337 10.3848 2.29413 10.4801 2.19883C10.5754 2.10354 10.7046 2.05 10.8394 2.05Z" fill="#333333" stroke="white" strokeWidth="0.1" />
            <path d="M8.60465 16.9339H8.65465V16.8839V11.3956V11.3456H8.60465H3.11628H3.06628V11.3956V16.1844C3.06628 16.3832 3.14525 16.5738 3.28581 16.7144C3.42638 16.855 3.61703 16.9339 3.81581 16.9339H8.60465ZM2.55814 10.3293H9.16279C9.29756 10.3293 9.42681 10.3828 9.5221 10.4781C9.61739 10.5734 9.67093 10.7027 9.67093 10.8374V17.4421C9.67093 17.5769 9.61739 17.7061 9.5221 17.8014C9.42681 17.8967 9.29756 17.9502 9.16279 17.9502H3.81592C3.34786 17.9493 2.89925 17.7629 2.56828 17.4319C2.23731 17.101 2.05096 16.6524 2.05 16.1843L2.05 10.8374C2.05 10.7027 2.10354 10.5734 2.19883 10.4781C2.29413 10.3828 2.42337 10.3293 2.55814 10.3293Z" fill="#333333" stroke="white" strokeWidth="0.1" />
            <path d="M8.60465 8.65465H8.65465V8.60465V3.11628V3.06628H8.60465H3.81581C3.61703 3.06628 3.42638 3.14525 3.28581 3.28581C3.14525 3.42638 3.06628 3.61703 3.06628 3.81581V8.60465V8.65465H3.11628H8.60465ZM2.56828 2.56828C2.89925 2.23731 3.34786 2.05096 3.81592 2.05L9.16279 2.05C9.29756 2.05 9.42681 2.10354 9.5221 2.19883C9.61739 2.29413 9.67093 2.42337 9.67093 2.55814V9.16279C9.67093 9.29756 9.61739 9.42681 9.5221 9.5221C9.42681 9.61739 9.29756 9.67093 9.16279 9.67093H2.55814C2.42337 9.67093 2.29413 9.61739 2.19883 9.5221C2.10354 9.42681 2.05 9.29756 2.05 9.16279V3.81587C2.05097 3.34783 2.23733 2.89924 2.56828 2.56828Z" fill="#333333" stroke="white" strokeWidth="0.1" />
        </svg>
    );
};
