import React from 'react';
import addNotification from 'react-push-notification';
import { webSocketFetchUsersApiCall } from '../../apis/websocket';

export const handleSocketReceiveMessage = async (message, user) => {
    let recievedMessage = message.data.replace(/\'/g, '');
    let subtitle = '';
    let customBrowserMessage = '';
    
    if (recievedMessage.includes('newLoadHasBeenPlaced')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'There is a new Load';
    }
    if (recievedMessage === 'companyDetailsChanged') {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'Your Company Details Is Changed';
    }
    if (recievedMessage.includes('BrokerhavePinnedYou')) {
        subtitle = 'Pinned';
        customBrowserMessage = 'Pinned';
    }
    if (recievedMessage.includes('BrokerhaveUnPinnedYou')) {
        subtitle = 'UnPinned';
        customBrowserMessage = 'UnPinned';
    }
    if (recievedMessage.includes('ThereIsANewMessageFromCarrier') || recievedMessage.includes('ThereIsANewMessageFromBroker')) {
        subtitle = 'There is a Message';
        customBrowserMessage = 'There is a new Message';
    }
    if (recievedMessage.includes('ThereIsACounterOffer')) {
        subtitle = 'There is a counter offer';
        customBrowserMessage = 'There is a counter offer';
    }
    if (recievedMessage.includes('CounterOfferIsUpdated')) {
        subtitle = 'Counter Offer is updated';
        customBrowserMessage = 'Counter Offer is updated';
    }
    if (recievedMessage.includes('ThereIsALoadMatchesYourSavedLane')) {
        subtitle = 'There is a Load Matches your saved Lane';
        customBrowserMessage = 'There is a Load Matches your saved Lane';
    }
    if (recievedMessage.includes('ThereIsANewBid')) {
        subtitle = 'There is a new Bid';
        customBrowserMessage = 'There is a new Bid';
    }
    if (recievedMessage.includes('ABidHasBeenRemoved')) {
        subtitle = 'A Bid has been Removed';
        customBrowserMessage = 'A Bid has been Removed';
    }
    if (recievedMessage.includes('YourLoadHasBennAccepted')) {
        if (recievedMessage.split('||')[2].split(':')[1] === user) {
            subtitle = 'Your bid has been accepted';
            customBrowserMessage = 'Your bid has been accepted';
        }
        else {
            subtitle = 'Load has been awarded';
            customBrowserMessage = 'Load has been awarded';
        }
    }
    if (recievedMessage.includes('BrokerLoadHasBeenUpdated')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'Load has been Updated';
    }
    if (recievedMessage.includes('BrokerLoadHasBeenDeleted')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'Load has been deleted';
    }
    if (recievedMessage.includes('BrokerLoadHasBeenExpired')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'Load has been Expired';
    }
    if (recievedMessage.includes('YourBidHasExpired')) {
        if (recievedMessage.split('||')[2].split(':')[1] === user) {
            subtitle = 'Your Bid has been Expired';
            customBrowserMessage = 'our Bid has been Expired';
        }
    }
    if (recievedMessage.includes('BrokerhavePinnedYou')) {
        subtitle = 'Broker pinned you';
        customBrowserMessage = 'Broker pinned you';
    }
    if (recievedMessage.includes('BrokerhaveUnPinnedYou')) {
        subtitle = 'Broker Unpinned you';
        customBrowserMessage = 'Broker Unpinned you';
    }
    if (recievedMessage.includes('BidConfirmedFromCarrier')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'Bid is confirmed from Carrier';
    }
    if (recievedMessage.includes('NewLoadAssigned')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = 'New load is Assigned';
    }
    if (recievedMessage.includes('Load status changed to')) {
        subtitle = 'There is a new notification';
        customBrowserMessage = recievedMessage.split('||')[0];
    }
    if (subtitle !== '' && customBrowserMessage !== '') {
        addNotification({
            title: 'Notification',
            subtitle: subtitle,
            message: customBrowserMessage,
            theme: 'darkblue',
            duration: 10000,
            native: true // when using native, your OS will handle theming.
        });
    }
    return recievedMessage;
};

export const handleSendMessageForSocketIO = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
    try {
        let webSocketFetchUserResponse = await webSocketFetchUsersApiCall();
        let connectionIds = [];
        if(webSocketFetchUserResponse){
            webSocketFetchUserResponse.forEach(element => {
                if (recieverId === null || recieverId.length === 0 || recieverId.length === '') {
                    connectionIds.push(element.connection_id);
                }
                else if(typeof recieverId === 'string' && recieverId !== ''){
                    if (recieverId === element.user_id) {
                        connectionIds.push(element.connection_id);
                    }
                }
                else {
                    for (let i = 0; i<recieverId.length; i++){
                        if(recieverId[i] === element.user_id){
                            connectionIds.push(element.connection_id);
                        }
                    }                   
                }
            });
            return connectionIds ; 
        }
    }
    catch (e) {
        console.log('send message error', e);
    }
};