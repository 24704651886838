import React from 'react';
import './Header.css';
import Avatar from '@mui/material/Avatar';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Tooltip } from '@material-ui/core';
import { NotVerifiedUserIcon } from '../../../assets/SvgIcons/NotVerifiedUserIcon';

export default function Header(props) {
    const { user } = useAuth0();
    return (
        <div className="header">
            <Grid container>
                <Grid item xs={3}>
                    <Avatar src={props.avatar}>{user.email.charAt(0).toUpperCase()}</Avatar>
                </Grid>

                <Grid item xs={6}>
                    {(!window.localStorage.getItem('mc_certified') || window.localStorage.getItem('mc_certified') !== 'Verified') && (window.localStorage.getItem('company_role') === 'Carrier') && (
                        <Tooltip title="Not Verified Company">
                            <NotVerifiedUserIcon />
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
