import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Box,
    CssBaseline,
    IconButton,
    Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';
import { Row, Col } from 'react-bootstrap';
import * as AWS from 'aws-sdk';
import CloseIcon from '@material-ui/icons/Close';
import ReCAPTCHA from 'react-google-recaptcha';
import { uploadFile } from 'react-s3';
import { pascalCase } from '../../utils';

import './index.css';

import { getAllUserInformationApiCall } from '../../apis/user';
import { addFeedBackDetailsApiCall, sendFeedBackDataToAdminApiCall, sendThanksEmailToUserApiCall } from '../../apis/emailtoAdmin';

const s3 = new AWS.S3({
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, accessKeyId: process.env.REACT_APP_ACCESS_KEY, region: process.env.REACT_APP_S3_BUCKET_REGION
});

const styles = {
    root: {
        display: 'flex',
    },
    header: {
        backgroundImage: `url(${'https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/Loadeo_pricing_1920x550.jpg'})`,
        height: '110vh',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    header1: {
        marginTop: '1%'
    },
    content: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content1: {
        marginLeft: '1%'
    }
};

const FeedbackUser = (props) => {
    const history = useHistory();
    const { user, isAuthenticated } = useAuth0();
    const [userName, setUserName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [userComments, setUserComments] = useState('');
    const [user_first_name, setUser_first_name] = React.useState('');
    const [user_last_name, setUser_last_name] = React.useState('');
    const [validateUser, setValidateUser] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');

    const [openDropZone, setOpenDropZone] = useState(false);

    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        directory: 'feedback_images',
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };

    const emailValidationTest = (enteredAddress) => {
        let emailtest = {};
        let updateaddress = enteredAddress;
        if (emailId === '' && user !== undefined) {
            updateaddress = user.email;
        }
        emailtest.emailId = updateaddress ? '' : 'error';
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(enteredAddress)) {
            setIsEmailValid(true);
        }
        else {
            //alert("You have entered an invalid email address!")
            setIsEmailValid(false);
            emailtest.emailId = 'error';
        }
        setErrors({
            ...emailtest
        });
        return Object.values(emailtest).every(x => x === '');
    }

    const validate = () => {
        let errorValidation = {};
        let updateaddress = emailId;
        if (emailId === '' && user !== undefined) {
            updateaddress = user.email;
        }
        errorValidation.userName = userName ? '' : 'error';
        errorValidation.emailId = updateaddress ? '' : 'error';
        errorValidation.userComments = userComments ? '' : 'error';
        if (userName === null && emailId === null && userComments === null) {
            Swal.fire({
                text: 'Full Name, Email Id and User Comments are Required',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(updateaddress)) {
            setIsEmailValid(true);
        }
        else {
            setIsEmailValid(false);
            errorValidation.emailId = 'error';
        }
        setErrors({
            ...errorValidation
        });
        return Object.values(errorValidation).every(x => x === '');
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant='filled' {...props} />;
    };

    // Function to handle open drop down zone
    const handleOpen = () => {
        setOpenDropZone(true);
    };

    // Function to handle open drop down zone
    const handleClose = () => {
        setOpenDropZone(false);
    };

    // Function to upload selected file to s3 bucket
    async function handleSave(files) {
        uploadFile(files[0], config)
            .then((data) => {
            })
            .catch((err) => {
                console.log('error', err);
            }
            );
        setOpenDropZone(false);
    }

    const handleUserNameChange = (event) => {
        if (isAuthenticated !== true) {
            setUserName(event.target.value);
        }
    };

    const handleEmaiIdChange = (event) => {
        if (isAuthenticated !== true) {
            emailValidationTest(event.target.value)
            setEmailId(event.target.value);
        }
    };

    const handleUserCommentsChange = (event) => {
        setUserComments(event.target.value);
    };

    //function to get user Name
    const getAllResponse = async () => {
        try {
            let alluserinformationdata = await getAllUserInformationApiCall();
            if (alluserinformationdata !== undefined) {
                if (user !== undefined) {
                    const findUser = alluserinformationdata.find(
                        ({ email_address }) => email_address === user.email
                    );
                    setUser_first_name(findUser.first_name);
                    setUser_last_name(findUser.last_name);
                    setUserName(pascalCase(findUser.first_name) + ' ' + pascalCase(findUser.last_name),);
                    setEmailId(findUser.email_address);
                }
            }
        }
        catch (error) {
            console.log(error, 'Get user details error in feedback');
        }
    };

    //Function to handle CloseForm
    const handleCloseFeedBack = (event) => {
        props.closeForm(event);
    };

    //function to trigger on click of approve in newuser table
    const handleSubmit = async () => {
        let userId;
        if (isAuthenticated === true && emailId === '') {
            userId = user.email;
            setEmailId(user.email);
        }
        else {
            userId = emailId;
        }
        if (validateUser === true) {
            let listValues =
            {
                'user_name': userName,
                'user_email': userId,
                'user_comments': userComments
            };
            if (validate()) {
                try {
                    let feedBackDetails = await addFeedBackDetailsApiCall(listValues);
                    if (feedBackDetails !== undefined) {
                        setSnackBarMessageText('FeedBack Submitted SuccessFully');
                        setOpenSnackBar(true);
                        try {
                            let feedBackData = await sendFeedBackDataToAdminApiCall(feedBackDetails);
                            if (feedBackData !== undefined) {
                                if (window.location.pathname !== '/feedback') {
                                    handleCloseFeedBack(false);
                                }
                                try {
                                    await sendThanksEmailToUserApiCall(listValues.user_email);
                                }
                                catch (error) {
                                    console.log('error', error);
                                }
                                setUserComments('');
                                if (window.location.pathname === '/feedback' && isAuthenticated === false) {
                                    history.push({ pathname: '/' });
                                }
                                if (window.location.pathname === '/feedback' && isAuthenticated === true) {
                                    history.push({ pathname: '/loadboard' });
                                }
                            }
                        }
                        catch (error) {
                            console.log('error', error);
                        }
                    }
                }
                catch (error) {
                    console.log('error', error);
                }
            }
        }
    };

    const onChange = (value) => {
        if (value) {
            setValidateUser(true);
        }
    };

    // on page load event
    useEffect(() => {
        setUserName(window.localStorage.getItem('userName'));
        if (user !== undefined) {
            setEmailId(user.email);
        }
        getAllResponse();
    }, []);


    return (
        <div className={styles.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity='success'>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <div style={window.location.pathname === '/feedback' ? styles.header : { margin: '1%' }}>
                <div style={window.location.pathname === '/feedback' ? styles.content : styles.content1}>
                    <Grid container direction='column' className='rowStyle' style={window.location.pathname === '/feedback' ? { minHeight: '90vh' } : { margin: '1%' }}>
                        <Grid container xs={12} sm={6} alignItems='center' direction='column' style={{ height: '90vh' }}>
                            <Paper id={window.location.pathname !== '/feedback' ? 'overlap' : 'center'}>
                                <Grid container direction='column' className='gridStyle' style={{ backgroundColor: '#0d2346' }}>
                                    <Row className='rowStyle' style={window.location.pathname !== '/feedback' ? { height: 25, marginBottom: '3%' } : { height: 25, marginTop: '3%' }}>
                                        <Col>
                                            <h6 style={{ color: 'white', fontFamily: 'Cambria', fontWeight: 'bold', padding: '0px 10px' }}>Feedback </h6>
                                        </Col>
                                        <Col>
                                            {(window.location.pathname !== '/feedback') && (
                                                <IconButton onClick={() => handleCloseFeedBack(false)} style={{ marginLeft: '75%', paddingTop: '4%', paddingBottom: 0 }}>
                                                    <CloseIcon className='closeIconColor'
                                                        fontSize='small'
                                                        style={{ marginBottom: 10 }}
                                                    />
                                                </IconButton>
                                            )}
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid container direction='column' alignItems='center' className='gridStyle'>
                                    <Row className='rowStyle' style={{ width: '92%', margin: 5, height: 50 }}>
                                        <Col>
                                            <TextField
                                                label='Full Name'
                                                style={{ color: (userName === '' || userName === null) ? 'red' : 'black', width: '100%', margin: '5px 0px 5px 0px' }}
                                                value={userName}
                                                error={errors.userName && (userName === '' || userName === null)}
                                                onChange={(val) => handleUserNameChange(val)} />
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid container direction='column' alignItems='center' style={{ width: '100%' }}>
                                    <Row className='rowStyle' style={{ width: '92%', margin: 5 }}>
                                        <Col>
                                            <TextField label='Email id'
                                                style={{ color: emailId === '' ? 'red' : 'black', width: '100%', margin: '5px 0px 5px 0px', height: 40 }}
                                                value={isAuthenticated ? user.email : emailId}
                                                error={errors.emailId && (isAuthenticated ? user.email === '' : emailId === '')}
                                                onChange={(val) => handleEmaiIdChange(val)}
                                                helperText={isEmailValid === false ? 'Please enter valid email address' : ''} />
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid container style={{ width: '100%', height: '40%' }}>
                                    <Box border={0} padding={1} style={{ width: '100%', height: 290 }}>
                                        <Row className='rowStyle' style={{ width: '95%', margin: 5 }}>
                                            <Col style={{ marginTop: 15 }}>
                                                <TextField
                                                    label='Your Comments'
                                                    style={{ color: userComments === '' ? 'red' : 'black', width: '100%', margin: '5px 0px 5px 0px' }}
                                                    value={userComments}
                                                    error={errors.userComments && userComments === ''}
                                                    multiline
                                                    rows={4}
                                                    variant='outlined'
                                                    onChange={(val) => handleUserCommentsChange(val)} />
                                            </Col>
                                        </Row>

                                        <Row className='rowStyle' style={{ marginBottom: '2%' }}>
                                            <div className='rc-anchor rc-anchor-normal rc-anchor-light'>
                                                <ReCAPTCHA width='200%'
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Row>
                                        {/* commented code for upload functionality currently unused */}
                                        {/* <Col>
                            <AttachFileIcon style={{ color: 'royalblue' }} />
                            <Typography variant='caption' style={{ fontSize: '16px', cursor: 'pointer', color: 'royalblue' }} onClick={handleOpen}>Upload your attachment </Typography>
                            <DropzoneDialog
                                open={openDropZone}
                                onSave={handleSave}
                                acceptedFiles={['.png']}
                                showPreviews={true}
                                maxFileSize={5000000}
                                onClose={handleClose}
                                filesLimit={1}
                            />
                        </Col> */}
                                        <Row className='rowStyle' style={{ marginLeft: 10, marginRight: 10 }}>
                                            <Col>
                                                <Button className="feedbackButton" onClick={() => { handleSubmit(); }} style={validateUser === true ? { border: '1px solid #09F', backgroundColor: '#0d2346' } : { backgroundColor: '#d8d8d8' }} >Submit </Button>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};
export default FeedbackUser;