export const COLORS = {
    background: '#e4e4e4',
    black: '#000000',
    white: '#FFFFFF',
    border: '#F0F0F0',
    link: '#2874F0',
    placeHolder: '#AEB1B3',
    subText: '#7A7A7A',
    /*darkPastelGreen */
    success: '#03c03c',
    menthol: '#c1f9a2',
    /*Orange */
    warning: '#ffa500',
    bananaYellow: '#ffe135',
    darkGrey: '#8F979F',
    red: '#ff0000',    
    tulip: '#f88379',
    violet: '#ee82ee',
    keppel: '#3ab09e',
    rockBlue: '#8ea3b9',
    deepSkyBlue: '#00bfff',
    royalBlue: '#4169e1',
    /*Oxford Blue */
    websiteColor: '#002147',
    Tan:'#d2b48c',
};