import React, {useEffect} from 'react';
import Header from '../header';

export default function Navbar(props) {
    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => setScroll(document.documentElement.scrollTop);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const className = 'fixed-navbar animated fadeInDown active';

    return (
        <div className={className}>
            <Header closeSecondaryPane={props.closeSecondaryPane}  notificationSent = {props.notificationSent}  />
        </div>
    );
}