import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

/* ************************ sending new request email to admin ************************* */
export const sendingNewRequestEmailToAdminApiCall = async (email) => {
    try {
        let response = await axios({
            url: environmentVariables.sendingnewrequestemailtoadmin,
            method: 'post',
            data: {
                email_address: email,
            },
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Sending new request email to admin failed');
    }
};

/* ************************ sending email to company admin************************* */
export const SendingEmailToCompanyAdminApiCall = async (email, newResponse) => {
    try {
        let response = await axios({
            url: environmentVariables.sendingemailtocompanyadmin + '?email_address=' + newResponse[0].email_address,
            method: 'post',
            data: {
                email_address: email,
            },
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Sending email to company admin failed');
    }
};

/* ************************Send email for approved user********************* */
export const approveUserNotificationApiCall = async (email_address) => {
    try {
        let response = await axios({
            url: environmentVariables.approveusernotification,
            method: 'post',
            data: {
                email_address: email_address,
            }
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Sending approved user email failed');
    }
};

/* ************************Send email for rejected user************************* */
export const sendRejectedMailApiCall = async (email_address) => {
    try {
        let response = await axios({
            url: environmentVariables.sendRejectedMail,
            method: 'post',
            data: {
                email_address: email_address,
            }
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Sending rejected email failed');
    }
};

/************************Updating email status for prospective client************************* */
export const handleUpdateEmailStatusApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.updateprospectiveemailstatus,
            method: 'patch',
            data: listValues
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Upating email status for prospective client failed');
    }
};

/************************Updating main status for prospective client************************* */
export const handleUpdateMainToggleStatusApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.updatemainstatus,
            method: 'patch',
            data: listValues
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Update main toggle status failed');
    }
};

/************************Verfication email not recievd for user************************* */
export const sendEmailAddressToAdminApiCall = async (emailAddress) => {
    try {
        let response = await axios({
            url: environmentVariables.admintoVerifyAddress,
            method: 'post',
            data:  {
                email_address: emailAddress,
            },
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Failed to send email to admin');
    }
};

/************************Store feedback email************************* */
export const addFeedBackDetailsApiCall = async (listValues) => {
    try {
        let response = await axios({
            url: environmentVariables.feedbackinfodetails,
            method: 'post',
            data: listValues,
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Failed to store feedback');
    }
};

/************************Send feedback email************************* */
export const sendFeedBackDataToAdminApiCall = async (feedBackDetails) => {
    try {
        let response = await axios({
            url: environmentVariables.feedbackemailtoadmin,
            method: 'post',
            data: {
                email_address: feedBackDetails.user_email,
                user_name: feedBackDetails.user_name,
                user_comments: feedBackDetails.user_comments,
            },
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Failed to send feedback email to admin');
    }
};

/************************Send feedback email************************* */
export const sendThanksEmailToUserApiCall = async (emailAddress) => {
    try {
        let response = await axios({
            url: environmentVariables.thanksEmailtoUser,
            method: 'post',
            data: {
                email_address: emailAddress,
            },
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Failed to send feedback email to admin');
    }
};
