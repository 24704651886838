import React from 'react';
export const Good = () => {
    return (
        <svg width="50px" height="50px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="miter">
            <circle cx="12" cy="12" r="10"></circle><line x1="8" y1="9" x2="8.01" y2="9" strokeWidth="2" strokeLinecap="round"></line>
            <line x1="15.99" y1="9" x2="16" y2="9" strokeWidth="2" strokeLinecap="round"></line>
            <path d="M16.61,14A5,5,0,0,1,14,16.61a5.07,5.07,0,0,1-3.9,0A5,5,0,0,1,7.39,14"></path>
        </svg>
    );
};
