import React, { useState } from 'react';
import {
    Grid,
    Button,
    Snackbar,
    TextField,
    Card,
    CardContent
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PasswordChecklist from 'react-password-checklist';
import './index.css';
import { useHistory } from 'react-router';
import { getInvitedUserApiCall, resetPasswordApiCall } from '../../apis/user';
const ResetPassword = () => {

    // constants to handle reset password page
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // constants to handle snackbar
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState(false);

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    const history = useHistory();

    // Function to handle MuiAlert
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const validate = () => {
        let validation = {};
        validation.verificationCode = verificationCode ? '' : 'error';
        validation.password = password ? '' : 'error';
        validation.confirmPassword = confirmPassword ? '' : 'error';

        setErrors({
            ...validation
        });
        return Object.values(validation).every(x => x === '');
    };

    const handleSubmitButtonClick = async(list) => {
        let listValues = list;
        listValues.profile_status = 'Verified';
        listValues = { ...listValues, 'password': confirmPassword };
        try {
            let resetData = await resetPasswordApiCall(listValues);
            if (resetData !== undefined) {
                setVerificationCode('');
                setPassword('');
                setConfirmPassword('');
                history.push({ pathname: '/' });
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Function to handle get invited user details
    const getInvitedUserDetails = async () => {
        if (validate()){
            try {
                let invitedUserData = await getInvitedUserApiCall('?verification_code=', verificationCode);
                if (invitedUserData !== undefined) {
                    if (invitedUserData !== 'UserNotPresent') {
                        handleSubmitButtonClick(invitedUserData[0]);
                    }
                    else {
                        setSnackBarMessageText('Found wrong verification code');
                        setOpenSnackBar(true);
                    }
                }
            } catch (e) {
                console.log('get invited user error', e);
            }
        }
    };

    // Function to handle close snackbar
    const handleCloseSnackbar = () => {
        setOpenSnackBar(false);
    };

    // function to handle verification code changes
    const handleVerificationCodeChange = (event) => {
        setVerificationCode(event.target.value);
    };
    // Function to handle password change
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    //Function to handle confirm password change
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    return (
        <div className="content">
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: '90vh' }}>
                    {/* <Paper elevation={3}  > */}
                    < Card style={{ maxWidth: '45%', height: '60%', margin: '0 auto' }} >
                        <div style={{ backgroundColor: '#142440' }}>
                            <img
                                width="80%"
                                height="68"
                                src="https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/Loadeo_logo_header.png"
                                alt=""
                                className="imgsize"
                            />
                        </div>
                        <CardContent style={{ maxWidth: '100%' }}>
                            <Grid container direction="row">
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField id="first" label="Verification Code" error={errors.verificationCode} placeholder="Enter verificationcode" variant="standard" autoComplete='off' style={{ width: '100%' }} value={verificationCode} onChange={handleVerificationCodeChange} />
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField id="first" label="Password" type="password" error={errors.password} placeholder="Enter password" variant="standard" style={{ width: '100%' }} value={password} onChange={handlePasswordChange} />
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField id="first" type="password" label="Confirm Password" error={errors.confirmPassword} placeholder="Enter passowrd agian" variant="standard" style={{ width: '100%' }} value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div style={{ height: 15 }} />
                            <Grid container direction="row">
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button type='submit' variant='contained' className="btncolor" style={{ backgroundColor: '#0d2346', fontSize: 12 }} onClick={getInvitedUserDetails}>
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr></hr>
                            <Grid>
                                {(password !== '') && (
                                    <PasswordChecklist
                                        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        onChange={(isValid) => { }}
                                    />
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                    {/* </Paper> */}
                </Grid>
            </Grid>
        </div>
    );
};
export default ResetPassword;