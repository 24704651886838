import React from 'react';
export const ColorSatisfied = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 513 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M256.1 512C397.485 512 512.1 397.385 512.1 256C512.1 114.615 397.485 0 256.1 0C114.715 0 0.100098 114.615 0.100098 256C0.100098 397.385 114.715 512 256.1 512Z" fill="#ECB13A" />
            <path d="M248.9 486.5C382.829 486.5 491.4 377.929 491.4 244C491.4 110.071 382.829 1.5 248.9 1.5C114.971 1.5 6.3999 110.071 6.3999 244C6.3999 377.929 114.971 486.5 248.9 486.5Z" fill="#FFDE4F" />
            <path d="M143.5 198.3C162.499 198.3 177.9 182.899 177.9 163.9C177.9 144.901 162.499 129.5 143.5 129.5C124.502 129.5 109.1 144.901 109.1 163.9C109.1 182.899 124.502 198.3 143.5 198.3Z" fill="#645033" />
            <path d="M365.4 198.7C384.399 198.7 399.8 183.299 399.8 164.3C399.8 145.301 384.399 129.9 365.4 129.9C346.401 129.9 331 145.301 331 164.3C331 183.299 346.401 198.7 365.4 198.7Z" fill="#645033" />
            <path d="M324.448 320.201H193.743C188.436 320.201 184.132 324.504 184.132 329.812C184.132 335.12 188.436 339.424 193.743 339.424H324.449C329.757 339.424 334.06 335.12 334.06 329.812C334.059 324.504 329.756 320.201 324.448 320.201Z" fill="#282D35" />
        </svg>

    );
};
