import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { handleUserCallBackData } from '../../store/users/Action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const Callback = () => {
    const history = useHistory();
    const { user, logout, isAuthenticated, isLoading } = useAuth0();
    const dispatch = useDispatch();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    // on page load event
    useEffect(() => {
        (async () => {
            if (isAuthenticated === true) {
                if (userReduxData.userData.user_role === 'Driver') {
                    history.push({ pathname: '/loadsassigned' });
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    window.localStorage.userCompanyName = 'SystemAdmin';
                    window.localStorage.removeItem('loadboardLoadId');
                    window.localStorage.removeItem('assignedLoadId');
                    window.localStorage.removeItem('activeloadsLoadId');
                    window.localStorage.removeItem('activebidsLoadId');
                    window.localStorage.removeItem('awardedloadsLoadId');
                    window.localStorage.removeItem('bidswonLoadId');
                    window.localStorage.removeItem('drawerValue');
                    history.push({ pathname: '/admin' });
                    window.localStorage.setItem('drawerValue', 'true');
                }
                else {
                    dispatch(handleUserCallBackData(user.email, logout, history));
                }
            }
        })();
    }, [user, isAuthenticated]);

    return (
        <div>
            {isLoading && <h2>Loading..</h2>}
        </div>
    );
};

export default Callback;