import React from 'react';

// components
import Breadcumb1 from '../../components/bredcrumb1';
import Footer from '../../components/Footer';
import LoadsAssignedScript from '../../module/LoadsAssigned';

const LoadsAssignedPage = () => {
    return (
        <div className="price-sec">
            <Breadcumb1 bdtitle={'AssignedLoads'} />
            <LoadsAssignedScript />
            <Footer />
        </div>
    );
};

export default LoadsAssignedPage;