import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CssBaseline,
    List,
    ListItem,
    Divider,
    IconButton,
    Typography,
    Tooltip,
    Grid,
    Paper,
    Button,
    Snackbar,
    Box,
    Tabs,
    Tab,
    InputBase,
} from '@material-ui/core';
import { AcceptCounterOffer, ExpiredCounterOffer } from '../../components/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import cities from 'cities';
import { COLORS } from '../../theme/colors';
// import zipcodes from 'zipcodes';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';
import moment from 'moment';
import slugid from 'slugid';
import MuiAlert from '@material-ui/lab/Alert';
import { toNumber } from 'lodash';
import useWebSocket from 'react-use-websocket';
import addNotification from 'react-push-notification';
import { animateScroll } from 'react-scroll';
import { pascalCase, scrollTopOnRowClick } from '../../utils';

// Import statements for Chat Component
import SearchBar from '../../components/CommentsComponent/SearchBar/SearchBar';
import Header from '../../components/CommentsComponent/Header/Header';
import HeaderChat from '../../components/CommentsComponent/HeaderChat/HeaderChat';
import Chat from '../../components/CommentsComponent/Chat/Chat';

// Imports to have all the Icons
import { ExpandLessIcon } from '../../assets/SvgIcons/ExpandLess';
import { ExpandMoreIcon } from '../../assets/SvgIcons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@material-ui/icons/Send';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// Import statements for CSS and environment
import { environmentVariables } from '../../constants/environment';
import SearchForm from '../SearchForm';
import Navbar from '../../components/Navbar';
import MUIDrawer from '../../components/Drawer';
import sortDate from '../../components/Sorting/sortDate';
import sortNum from '../../components/Sorting/sortNum';
import LoadDetailsPannel from '../../components/LoadDetailsPannel';

import { getBidsWonLoadsApiCall } from '../../apis/loads';
import { acceptBidApiCall, getBidApiCall } from '../../apis/bids';
import { createCommentApiCall, getCommentApiCall, updateCommentApiCall } from '../../apis/chat';
import { addNotificationApiCall } from '../../apis/notifications';


// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';

import { assignLoadNotificationApiCall, confirmLoadNotificationApiCall } from '../../apis/emailNotifications';
import { handleSendMessageForSocketIO, handleSocketReceiveMessage } from '../WebSocketConnection';

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
let name;
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    leftAlign: {
        marginLeft: '25px',
        textTransform: 'none',
        fontSize: 16,
    }
}));

// Function to handle MuiAlert
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const LoadsWon = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const dispatch = useDispatch();

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { user } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [recieveMessage, setRecieveMessage] = useState(false);
    const SearchFormRef = useRef();

    /// Constant to handle expand and collapse the top pannel
    const [expandCollapseTopPannel, setExpandCollapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle logged in user details
    const [userCompanyName, setUserCompanyName] = useState('');
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState('')
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState('');
    const [loggedInuserRole, setLoggedInuserRole] = useState('');

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    // constants to handle driver role
    const [companyDriversList, setCompanyDriversList] = useState([]);
    const [driverName, setDriverName] = useState('');
    const [driverStatus, setDriverStatus] = useState('');

    // Constant to handel main grid percentage
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState('');
    const [searchPickUpFromState, setSearchPickUpFromState] = useState('');
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState('');
    const [searchPickUpMiles, setSearchPickUpMiles] = useState('');

    // Constant for Pickup from date
    const [searchPickUpDate, setSearchPickUpDate] = useState(null);

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState('');
    const [searchDeliverToState, setSearchDeliverToState] = useState('');
    const [searchDeliverToZip, setSearchDeliverToZip] = useState('');
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState('');

    //constant to handle mui data table loader
    const [loading, setLoading] = useState(false);

    //constant to handle mui bid table loader
    const [loadingBids, setLoadingBids] = useState(false);

    // Constant to handle second muidatatble 
    const [rowDataBidTable, setRowDataBidTable] = useState([]);

    // constant to handle drawer notification badge
    const [isNotificationSent, setIsNotificationSent] = useState(false);
    const [disableConfirmbutton, setDisableConfirmbutton] = useState(false);

    // Constants to handle Awarded Loads
    const [rowDataForLoadsWon, setRowDataFoLoadsWon] = useState([]);
    const optionsForLoadsWon = {
        filter: false,
        search: false,
        responsive: 'vertical',
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex);
                }
            }
            const findChatResult = chatDetails.find(
                ({ load_id }) => load_id === row[0]
            );
            if (findChatResult !== undefined) {
                if (findChatResult.read_status) {
                    const findChatStatus = findChatResult.read_status.find(
                        ({ name }) => name === user.email
                    );
                    if (findChatStatus !== undefined && findChatStatus.status === false && selectedRowDataValue.load_id !== row[0]) {
                        return {
                            style: { backgroundColor: '#DAF7A6' }
                        };
                    }
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    `There are no Bids won by ${pascalCase(user.nickname)}`
            },
        }
    };
    const columnDefs = [
        { label: 'PostId', name: 'load_id', options: { display: false } },
        {
            label: ' ',
            name: 'unread_chat_count',
            options: {
                customBodyRender: (value, row) => {
                    if (value !== undefined && value.includes(',')) {
                        let normalChat = value.split(',')[0];
                        let bidChat = value.split(',')[1];
                        if (normalChat > 0 && selectedRowDataValueForSocketIO.load_id !== row.rowData[0]) {
                            return (
                                < MessageRoundedIcon />
                            );
                        }
                    }
                }
            }
        },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pickupfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className='bold'>
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Miles (Time)',
            name: 'cities_distance',
            options: {
                sort: true, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== '') {
                                if (valEnteredNumber.includes(',')) {
                                    let splittingValue = valEnteredNumber.split(',');
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== 'NaN' && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Posting Company', name: 'posted_by_company', options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[10] !== null && rowuserdata.rowData[10] !== undefined) {
                        return (
                            rowuserdata.rowData[10].toUpperCase()
                        );
                    }
                }
            }
        },
        {
            label: 'Load ID',
            name: 'load_number',
            options: {
                customBodyRender: (value) => {
                    return value;
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
        {
            label: 'Status', name: 'status', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        <div style={{ fontWeight: 'bold' }}>{rowuserdata.rowData[12].toUpperCase()}</div>
                    );
                }
            }
        },
    ];

    const [columnDefsForLoadsWon, setColumnDefsForLoadsWon] = useState(columnDefs);

    const getStatusForBidTables = (status) => {
        let newStatus;
        if (status === 'new') {
            newStatus = 'New';
        }
        else if (status === 'accepted') {
            newStatus = 'Won';
        }
        else {
            newStatus = 'Closed';
        }
        return newStatus;
    };

    const handleRedirectLoadFromEmailTemplate = async (rowData) => {
        if (location.state) {
            if (location.state.loadId && window.localStorage.getItem('bidswonLoadId')) {
                // OPen secondary pane
                const findLoad = rowData.find(
                    ({ load_id }) => load_id === location.state.loadId
                );
                if (findLoad) {
                    removeBackroundColour();
                    setSelectedRowDataValue(findLoad);
                    const FilterColumns = [...columnDefsForLoadsWon];
                    for (let i = 1; i < FilterColumns.length; i++) {
                        if (FilterColumns[i].options.display !== undefined) {
                            FilterColumns[i].options.display = false;
                        }
                    }
                    setColumnDefsForLoadsWon(FilterColumns);
                    setMainGridPercent('35%');
                    setIsPaneOpen(true);
                    isPaneOpenForSocketIO = true;
                    selectedRowDataValueForSocketIO = findLoad;
                    fetchAllComments(findLoad);
                    setSelectedRowDataValue(findLoad);
                    loadAndRealoadSecondGrid(location.state.loadId);
                    window.localStorage.removeItem('bidswonLoadId');
                }
            }
            window.localStorage.removeItem('bidswonLoadId');
        }
    }

    // Function to fetch all the data main MuiDatatble
    const fetchMainMuiDatatableValues = async () => {
        try {
            let response = await getBidsWonLoadsApiCall(user.email);
            if (response) {
                if (response.length === 0) {
                    let loadIds = [];
                    setLoading(true);
                    response.forEach(element => {
                        if (element.load_number) {
                            loadIds.push(element.load_number);
                        }
                    });
                }
                else {
                    setLoading(true);
                }
                //To delete loads 
                // Local array to handelremoval of rows
                response.sort((a, b) =>
                    b.time_stamp.localeCompare(a.time_stamp)
                );
                // Setting Up expired loads table
                let awardedLoadList;
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    if (window.location.pathname === '/allbidswon') {
                        if (loggedInuserRole === 'CompanyAdmin') {
                            awardedLoadList = response.filter(function (singleVal) {
                                return singleVal['carrier_company'] === userReduxData.userData.company_name;
                            });
                        }
                    }
                    else {
                        awardedLoadList = response.filter(function (singleVal) {
                            return singleVal['carrier_name'] === user.email;
                        });
                    }
                    setRowDataFoLoadsWon(awardedLoadList);
                    removeBackroundColour();
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    setRowDataFoLoadsWon(response);
                    removeBackroundColour();
                }
                await handleRedirectLoadFromEmailTemplate(response);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // function that is execute after confirm button press in Bid details
    const confirmLoadWon = (value, rowuserdata, rowDataValue) => {
        Swal.fire({
            text: `Do you want to confirm the Load ID - ${value} ?`,
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then(async (result) => {
            if (result.value === true) {
                setDisableConfirmbutton(true);
                let selectedRow = selectedRowDataValue;
                selectedRow.status = 'Confirmed';
                selectedRow.broker_id = selectedRowDataValue.posted_by_user;
                selectedRow.carrier_id = user.email;
                selectedRow.equipment_needed = '';
                selectedRow.load_dimension = '';
                selectedRow = { ...selectedRow, 'broker_loadUrl': window.location.origin + '/awardedloads?loadId=' + slugid.encode(selectedRowDataValue.load_id), 'carrier_loadUrl': window.location.origin + '/bidswon?loadId=' + slugid.encode(selectedRowDataValue.load_id) };
                setSelectedRowDataValue(selectedRow);
                fetchMainMuiDatatableValues();
                removeBackroundColour();
                let data = {
                    load_id: rowuserdata.rowData[1],
                    bid_id: rowuserdata.rowData[2],
                    carrier_name: rowuserdata.rowData[6],
                    status: 'Confirmed',
                    carrier_company: rowuserdata.rowData[3],
                    driver_name: selectedRowDataValue.driver_name,
                    driver_status: selectedRowDataValue.driver_status
                };
                try {
                    const acceptBidResponse = await acceptBidApiCall(data);
                    if (acceptBidResponse) {
                        setSnackBarMessageText('Load Succesfully Confirmed');
                        setOpenSnackBar(true);
                        fetchMainMuiDatatableValues();
                        sendMessageForSocketIO('BidConfirmedFromCarrier||load_id:' + rowuserdata.rowData[1] + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                        onSendMessageClick(`Load ${selectedRowDataValue.load_number} has been confirmed`, 'System Message');
                        return await confirmLoadNotificationApiCall(selectedRow);
                    }
                }
                catch (error) { }
            }
        });
    };

    const columnDefsBidTable = [
        {
            label: 'Posted By',
            name: 'posted_by_company',
            options: { display: false },
        },
        { label: 'PostId', name: 'load_id', options: { display: false } },
        { label: 'BidId', name: 'bid_id', options: { display: false } },
        { label: 'Carrier Company', name: 'carrier_company' },
        {
            label: 'Bid Amount', name: 'bid_amount', options: {
                filter: true,
                sort: true,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {(rowIndex.rowData[11] === 'newoffer') && rowIndex.rowData[12] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[4]}</p>}
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[13]} </p>}
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'Accept' && <p className='afterBidDisplay'> $ {rowIndex.rowData[13]}
                        </p>}
                    </>
                    );
                }
            },
        }, {
            label: 'Status', name: 'status', options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let bidStatus = getStatusForBidTables(rowuserdata.rowData[5]);
                    return (
                        bidStatus
                    );
                }
            }
        },
        { label: 'Carrier Name', name: 'carrier_name', options: { display: false } },
        { label: 'bid_time_stamp', name: 'bid_time_stamp', options: { display: false } },
        {
            label: 'Bid Notes', name: 'bid_notes', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[8] === '' || rowuserdata.rowData[8] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[8] !== '' || rowuserdata.rowData[8] !== undefined) && rowuserdata.rowData[8]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Miles Out', name: 'miles_out', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {(rowuserdata.rowData[9] === '' || rowuserdata.rowData[9] === undefined) && <p className='afterBidDisplay'> NA
                        </p>}
                        {(rowuserdata.rowData[9] !== '' || rowuserdata.rowData[9] !== undefined) && rowuserdata.rowData[9]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Truck Dimension', name: 'truck_dimension', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (<>
                        {rowuserdata.rowData[10] === 'LXWXH' && <p className='afterBidDisplay'> NA
                        </p>}
                        {rowuserdata.rowData[10] !== 'LXWXH' && rowuserdata.rowData[10]}
                    </>
                    );
                }
            }
        },
        {
            label: 'Counter Offer',
            name: 'counteroffer_sent',
            options: {
                filter: true,
                sort: true,
                display: false,
                customBodyRender: (dataIndex, rowIndex) => {
                    return (<>
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'New' && <p className='afterBidDisplay'> ${rowIndex.rowData[13]} </p>}
                        {(rowIndex.rowData[13] === undefined || rowIndex.rowData[13] === '') && <p className='afterBidDisplay'> NA </p>}
                        {rowIndex.rowData[11] !== 'newoffer' && rowIndex.rowData[12] === 'Accept' && <p className='afterBidDisplay'> $ {rowIndex.rowData[13]}
                        </p>}
                    </>
                    );
                }
            }
        },
        {
            label: 'Counter Offer Status',
            name: 'counteroffer_status',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: false,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counterofferstatus = rowuserdata.rowData[12];
                    return (<>
                        {counterofferstatus === 'Accept' && <div>{counterofferstatus} </div>}
                    </>
                    );
                }
            },

        },
        {
            label: 'Counter Offer',
            name: 'counter_offer',
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    let counteroffer = rowuserdata.rowData[13];
                    let counterofferstatus = rowuserdata.rowData[12];

                    return (<>
                        {(counteroffer === '' || counteroffer === undefined) && counterofferstatus === 'New' && <p className='afterBidDisplay'> NA
                        </p>}
                        {counteroffer !== '' && counteroffer !== undefined && counterofferstatus === 'New' && <p className='afterBidDisplay' style={{ width: 'max-content' }} > ${counteroffer}
                        </p>}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && (rowuserdata.rowData[5] === 'accepted' || rowuserdata.rowData[5] === 'Confirmed') && <AcceptCounterOffer counteroffer={counteroffer} />}
                        {counteroffer !== '' && counterofferstatus === 'Accept' && rowuserdata.rowData[5] !== 'accepted' && <ExpiredCounterOffer counteroffer={counteroffer} />}
                    </>
                    );
                }
            },
        },
        {
            label: 'Actions',
            name: 'load_number',
            options: {
                customBodyRender: (value, rowuserdata) => {
                    return (<>
                        {<Button variant='contained' className='buttonColor' id='buttonColorConfirm' style={(selectedRowDataValue.status === 'Awarded' && rowuserdata.rowData[5] !== 'Closed' && user.email !== process.env.REACT_APP_ADMIN_USER) ? { backgroundColor: '#0d2346', fontSize: 12 } : { fontSize: 12 }}
                            disabled={selectedRowDataValue.status === 'Awarded' && rowuserdata.rowData[5] !== 'Closed' && user.email !== process.env.REACT_APP_ADMIN_USER ? false : true}
                            onClick={() => user.email !== process.env.REACT_APP_ADMIN_USER ? confirmLoadWon(value, rowuserdata, selectedRowDataValue) : ''}>Confirm</Button>
                        }
                    </>
                    );
                }
            }
        }
    ];

    const optionsBidTable = {
        filter: false,
        responsive: 'vertical',
        download: false,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingBids ?
                    <CircularProgress /> :
                    'No bids available'
            },
        }
    };

    // Constant to handle bottom tab
    const [tabPannerlValue, setTabPannerlValue] = useState(0);

    // Constants to handle all Chat
    const [allChat, setAllChat] = useState([]);
    const [chatSearchFilter, setChatSearchFilter] = useState('');
    const [chatFirstCarrierName, setChatFirstCarrierName] = useState('');
    const [chatCarrierMCNumber, setChatCarrierMCNumber] = useState('');
    const [chatFirstCompanyName, setChatFirstCompanyName] = useState('');
    const [chatAllMessages, setChatAllMessages] = useState([]);
    const [chatDetails, setChatDetails] = useState([]);

    //--------------------------------------------*********************--------------------------//
    //Function to scroll bottom
    const scrollToBottom = () => {
        animateScroll.scrollToBottom({});
    };

    //Function to scroll On recieveing Message
    const ScrollForParticularLoad = (recievedMessage) => {
        if (isPaneOpen === true) {
            if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                scrollToBottom();
            }
        }
    };

    //function to assign values on row click in userdetails table
    const handleRowClick = async (selectedRowData, rowMeta) => {
        scrollTopOnRowClick(expandCollapseTopPannel)
        localStorage.setItem('selectedLoadId', selectedRowData[0]);
        setMainGridPercent('35%');
        setIsPaneOpen(true);
        isPaneOpenForSocketIO = true;
        loadAndRealoadSecondGrid(selectedRowData[0]);
        let response = await getBidsWonLoadsApiCall(user.email);
        const findResult = response.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        let fetchAllCommentResponse = await fetchAllComments(findResult);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowData[0]
                );
                updateChatReadStatus(findChatResult);
            }
        }
        if (findResult.status === 'Confirmed') {
            setDisableConfirmbutton(true);
        }
        selectedRowDataValueForSocketIO = findResult;
        removeBackroundColour();
        setSelectedRowDataValue(findResult);
        fetchAllComments(findResult);
    };

    const searchButtonClicked = () => {
        setExpandCollapseTopPannel(!expandCollapseTopPannel);
        // setTopTabPannelValue(1);
        setShowing(!showing);
    };

    const handleExpandMoreIconClick = () => {
        setTopTabPannelValue(0);
        setExpandCollapseTopPannel(false);
        localStorage.setItem('panelValue', 'false');
    };

    const handleExpandLessIconClick = () => {
        setExpandCollapseTopPannel(true);
        localStorage.setItem('panelValue', 'true');
    };

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === '') {
            const valForFilter = place.target.value;
            const filterColumns = [...columnDefsForLoadsWon];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === '') {
                filterColumns[11].options.filterList = [];
            }
            else {
                filterColumns[11].options.filterList = filterList;
            }
            setColumnDefsForLoadsWon(filterColumns);
        }
    };

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsForLoadsWon];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[2].options.filterList = filterList;
            setColumnDefsForLoadsWon(filterColumns);
        }
    };

    // function to get all nearby pickup cities within specified miles
    const getNearestPickUpCities = async (event) => {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchPickUpFromCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            setSearchPickUpMiles(event.target.value);
            // var rad = zipcodes.radius(searchPickUpFromZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchPickUpFromZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchPickUpFromZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=pickupcitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== 'Active') {
                            if (element.pickup_dest === valForFilter) {
                                filterList.unshift(element.pickup_dest);
                            }
                            else {
                                filterList.push(element.pickup_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsForLoadsWon];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsForLoadsWon(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsForLoadsWon];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[2].options.filterList = filterList;
                        setColumnDefsForLoadsWon(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const filterColumns = [...columnDefsForLoadsWon];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[2].options.filterList = filterList;
                setColumnDefsForLoadsWon(filterColumns);
            }
        }
    };

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const filterColumns = [...columnDefsForLoadsWon];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[3].options.filterList = filterList;
            setColumnDefsForLoadsWon(filterColumns);
        }
        else {
            const filterColumns = [...columnDefsForLoadsWon];
            filterColumns[3].options.filterList = [];
            setColumnDefsForLoadsWon(filterColumns);
            setSearchPickUpDate(null);
        }

    };

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const filterColumns = [...columnDefsForLoadsWon];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[4].options.filterList = filterList;
            setColumnDefsForLoadsWon(filterColumns);
        }
    };

    // function to get all nearby deliver cities within specified miles
    const getNearestCitiesForDeliverCity = async (event) => {
        const format = /^[0-9\b]+$/;
        if (event.target.value === '' || format.test(event.target.value)) {
            if (searchDeliverToCity !== '') {
                setSnackBarMessageText('Data is loading for you....');
                setOpenSnackBar(true);
            }
            setSearchDeliverMiles(event.target.value);
            // var rad = zipcodes.radius(searchDeliverToZip, event.target.value);
            let listvalues = [];
            // listvalues = rad
            // let count = 0
            // for (let i = 0; i < listvalues.length; i++) {
            //     if (listvalues[i] === searchDeliverToZip) {
            //         count = count + 1
            //     }
            // }
            // if (count === 0) {
            //     listvalues.push(searchDeliverToZip)
            // }
            try {
                let response = await axios({
                    url:
                        environmentVariables.getnearbycities + '?zipList=' +
                        listvalues + 'functionality=delivercitysearch',
                    method: 'get',
                });
                if (response) {
                    setOpenSnackBar(false);
                    let filterList = [];
                    const valForFilter = searchDeliverToCity + ', ' + searchDeliverToState + ' - ' + searchDeliverToZip;
                    response.data.forEach(element => {
                        if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== 'Active') {
                            if (element.delivery_dest === valForFilter) {
                                filterList.unshift(element.delivery_dest);
                            }
                            else {
                                filterList.push(element.delivery_dest);
                            }
                        }
                    });
                    if (filterList.length > 0) {
                        const FilterColumns = [...columnDefsForLoadsWon];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsForLoadsWon(FilterColumns);
                    }
                    else {
                        const FilterColumns = [...columnDefsForLoadsWon];
                        let filterList = [];
                        filterList = [valForFilter];
                        FilterColumns[4].options.filterList = filterList;
                        setColumnDefsForLoadsWon(FilterColumns);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const filterColumns = [...columnDefsForLoadsWon];
                let filterList = [];
                filterList = [valForFilter];
                filterColumns[4].options.filterList = filterList;
                setColumnDefsForLoadsWon(filterColumns);
            }
        }
    };

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const filterColumns = [...columnDefsForLoadsWon];
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[6].options.filterList = filterList;
            setColumnDefsForLoadsWon(filterColumns);
        }
        else {
            const filterColumns = [...columnDefsForLoadsWon];
            filterColumns[6].options.filterList = [];
            setColumnDefsForLoadsWon(filterColumns);
        }
    };

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const filterColumns = [...columnDefsForLoadsWon];
        if (allTruckType && allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            setColumnDefsForLoadsWon(filterColumns);
        }
        else {
            filterColumns[7].options.filterList = [];
            setColumnDefsForLoadsWon(filterColumns);
        }
    };

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        const filterColumns = [...columnDefsForLoadsWon];
        if (allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            filterColumns[7].options.filterList = filterList;
            setColumnDefsForLoadsWon(filterColumns);
        }
        else {
            filterColumns[7].options.filterList = [];
            setColumnDefsForLoadsWon(filterColumns);
        }

    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const filterColumns = [...columnDefsForLoadsWon];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    filterColumns[8].options.filterList = [];
                }
                else {
                    filterColumns[8].options.filterList = filterList;
                }
                setColumnDefsForLoadsWon(filterColumns);
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.value !== '0' && (event.target.validity.valid || event.target.value === '')) {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const filterColumns = [...columnDefsForLoadsWon];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    filterColumns[9].options.filterList = [];
                }
                else {
                    filterColumns[9].options.filterList = filterList;
                }
                setColumnDefsForLoadsWon(filterColumns);
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const filterColumns = [...columnDefsForLoadsWon];
        switch (index) {
            case 2:
                {
                    filterColumns[2].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(2);
                    break;
                }
            case 3:
                {
                    filterColumns[3].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(3);
                    break;
                }
            case 4:
                {
                    filterColumns[4].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(4);
                    break;
                }
            case 6:
                {
                    filterColumns[6].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(6);
                    break;
                }
            case 7:
                {
                    const filterColumns = [...columnDefsForLoadsWon];
                    filterColumns[7].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(7);
                    break;
                }
            case 8:
                {
                    filterColumns[8].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(8);
                    break;
                }
            case 9:
                {
                    filterColumns[9].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(9);
                    break;
                }
            case 11:
                {
                    filterColumns[11].options.filterList = [];
                    setColumnDefsForLoadsWon(filterColumns);
                    SearchFormRef.current.onSearchClear(14);
                    break;
                }
            default:
                break;

        }
    };

    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    // function to handle get invited drivers 
    const handleGetDrivers = async () => {
        try {
            let response = await axios({
                url:
                    environmentVariables.getdriverusers +
                    '?company_mc_number=' +
                    loggedInCompanyMCNumber,
                method: 'get',
            });
            let newResponse = JSON.parse(response.data.body);
            setCompanyDriversList(newResponse);
            return newResponse;
        } catch (error) {
            console.log('get driver error', error);
        }
    };

    const handleLeavingSecondaryForm = () => {
        if (window.confirm('Changes you made may not be saved')) {
            return true;
        }
    };

    const handleSavingDriverNameAndStatus = (info, availability) => {
        if (info !== null) {
            setDriverName(info);
            setDriverStatus(availability);
            if (availability === 'Free') {
                setSnackBarMessageText('Load assigned to driver' + ' ' + info + '!');
            }
            else {
                setSnackBarMessageText('Load queued up to driver' + ' ' + info + '!');
            }
            setOpenSnackBar(true);
            if (availability === 'Busy') {
                closeSecondaryPane();
            }
            updateLoadAssignedStatus(info, availability,);
        }
    };

    //Function to Update Status On Change To DB
    const updateLoadAssignedStatus = async (driverName, availability) => {
        let listValues = selectedRowDataValue;
        listValues = { ...listValues, 'driver_name': driverName, 'driver_status': availability };
        listValues.status = availability !== 'Busy' ? 'assigned' : 'queued';
        let freightDimension = '';
        if (listValues.freight_dimension_length.toString().trim() === '' &&
            listValues.freight_dimension_width.toString().trim() === '' &&
            listValues.freight_dimension_height.toString().trim() === '') {
            freightDimension = 'NA';
        }
        else {
            if (listValues.freight_dimension_length.toString().trim() === '') {
                freightDimension += 'NA X ';
            }
            else {
                freightDimension += listValues.freight_dimension_length.toString().trim() + 'L X ';
            }
            if (listValues.freight_dimension_width.toString().trim() === '') {
                freightDimension += 'NA X ';
            }
            else {
                freightDimension += listValues.freight_dimension_width.toString().trim() + 'W X ';
            }
            if (listValues.freight_dimension_height.toString().trim() === '') {
                freightDimension += 'NA';
            }
            else {
                freightDimension += listValues.freight_dimension_height.toString().trim() + 'H';
            }
        }
        listValues = { ...listValues, 'load_dimension': freightDimension, 'loadUrl': window.location.origin + '/loadsassigned?loadId=' + slugid.encode(selectedRowDataValue.load_id) };
        try {
            let data = {
                load_id: selectedRowDataValue.load_id,
                bid_id: selectedRowDataValue.bid_id,
                carrier_name: selectedRowDataValue.carrier_name,
                status: availability !== 'Busy' ? 'assigned' : 'queued',
                carrier_company: selectedRowDataValue.carrier_company,
                driver_name: driverName,
                driver_status: availability
            };
            const acceptBidResponse = await acceptBidApiCall(data);
            if (acceptBidResponse) {
                fetchMainMuiDatatableValues();
                setSelectedRowDataValue(listValues);
                let receiverId = [];
                try {
                    const assingLoadEmailResponse = await assignLoadNotificationApiCall(listValues);
                    if (assingLoadEmailResponse) {
                        setDriverName('');
                        setDriverStatus('');
                        receiverId.push(selectedRowDataValue.posted_by_user);
                        receiverId.push(driverName);
                        sendMessageForSocketIO('NewLoadAssigned||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, receiverId, true, selectedRowDataValue, []);
                    }
                }
                catch (error) {
                    console.log('assign driver Email error', error);
                }
            }
        }
        catch (error) {
            console.log('assign driver error', error);
        }
    };

    //Function to store Load Status On Change
    const handleSavingLoadStatus = (status, driverStatus) => {
        Swal.fire({
            text: 'Are you sure you want to change load status to ' + status + '?',
            cancelButtonColor: COLORS.websiteColor,
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: COLORS.websiteColor
        }).then(async (result) => {
            if (result.value === true) {
                if (status !== null) {
                    setSnackBarMessageText('Status updated succesfully');
                    setOpenSnackBar(true);
                    updateLoadStatus(status, driverStatus);
                }
            }
        });
    };

    //Function to Update Status On Change To DB
    const updateLoadStatus = async (loadStatus, driverStatus) => {
        let receiverId = [];
        let status = '';
        if (loadStatus === 'On-site for Pick up') {
            status = 'onsite_for_pickup';
        }
        if (loadStatus === 'Picked up') {
            status = 'picked_up';
        }
        if (loadStatus === 'In-Transit') {
            status = 'in_transit';
        }
        if (loadStatus === 'On-site for Delivery') {
            status = 'onsite_for_delivery';
        }
        if (loadStatus === 'Delivered') {
            status = 'delivered';
        }
        let listValues = selectedRowDataValue;
        listValues.status = status;
        listValues.driver_status = status;
        listValues.load_expired_status = false;
        try {
            let data = {
                load_id: selectedRowDataValue.load_id,
                bid_id: selectedRowDataValue.bid_id,
                carrier_name: selectedRowDataValue.carrier_name,
                status: status,
                carrier_company: selectedRowDataValue.carrier_company,
                driver_name: selectedRowDataValue.driver_name,
                driver_status: driverStatus
            };
            const acceptBidResponse = await acceptBidApiCall(data);
            if (acceptBidResponse) {
                listValues.status = loadStatus;
                setSelectedRowDataValue(listValues);
                fetchMainMuiDatatableValues();
                receiverId.push(selectedRowDataValue.posted_by_user);
                receiverId.push(selectedRowDataValue.driver_name);
                sendMessageForSocketIO(`Load status changed to ${loadStatus}||load_id:` + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, receiverId, true, selectedRowDataValue, []);
            }
        } catch (error) {
            console.log('changing status error', error);
        }
    };

    const handleCloseSecondaryPanelConfirmation = () => {
        if (selectedRowDataValue.driver_name === undefined && driverName !== '') {
            let leavingResponse = handleLeavingSecondaryForm();
            if (leavingResponse === true) {
                closeSecondaryPane();
            }
        }
        else {
            closeSecondaryPane();
        }
    };

    // Function to handle pane close
    const closeSecondaryPane = async () => {
        window.localStorage.removeItem('LoadId');
        let fetchAllCommentResponse = await fetchAllComments(selectedRowDataValue);
        if (fetchAllCommentResponse && fetchAllCommentResponse !== undefined) {
            if (fetchAllCommentResponse.length === 1) {
                updateChatReadStatus(fetchAllCommentResponse);
            }
            else if (fetchAllCommentResponse.length > 1) {
                const findChatResult = fetchAllCommentResponse.filter(
                    ({ load_id }) => load_id === selectedRowDataValue.load_id
                );
                updateChatReadStatus(findChatResult);
            }
        }
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        setSelectedRowDataValue([]);
        removeBackroundColour();
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    //---------------------------- Place to handle all the functions ----------------------------

    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowDataForLoadsWon.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute('style');
                if (styleObject !== null) {
                    if (styleObject.toString().includes('9FD2F3')) {
                        domObject.removeAttribute('style', 'background: #9FD2F3');
                    }
                }
            }

        }
    };

    // Function to check the user role
    const checkUserRole = () => {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === 'ProfileAccepted') {
                if (userReduxData.userData.company_role === 'Broker' || userReduxData.userData.user_role === 'Broker' || userReduxData.userData.user_role === 'Driver') {
                    history.push({ pathname: '/forbidden' });
                }
                if (userReduxData.userData.company_name) {
                    setUserCompanyName(userReduxData.userData.company_name);
                }
                if (userReduxData.userData.company_role) {
                    setLoggedInuserCompanyRole(userReduxData.userData.company_role);
                }
                if (userReduxData.userData.user_role) {
                    setLoggedInuserRole(userReduxData.userData.user_role);
                }
                if (userReduxData.userData.company_mc_number) {
                    setLoggedInCompanyMCNumber(userReduxData.userData.company_mc_number)
                }
            }
            else {
                history.push({ pathname: '/forbidden' });
            }
        }
    };

    // Function to load and reload bid details 
    const loadAndRealoadSecondGrid = async (loadId) => {
        try {
            const getBidResponse = await getBidApiCall(loadId, "loadspecific");
            if (getBidResponse !== 'NoBidsAreAvailable') {
                getBidResponse.sort((a, b) =>
                    b.bid_time_stamp.localeCompare(a.bid_time_stamp)
                );
                let findBids = [];
                if (getBidResponse.length !== 0) {
                    setLoadingBids(true);
                }
                else {
                    setLoadingBids(false);
                }
                getBidResponse.forEach(element => {
                    if (element.carrier_name === user.email) {
                        findBids.push(element);
                    }
                    else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                        if (element.carrier_name !== user.email) {
                            findBids.push(element);
                        }
                    }
                });
                if (findBids.length === 0) {
                    setLoadingBids(true);
                }
                setRowDataBidTable(findBids);
            }
            else {
                setRowDataBidTable([]);
                setLoadingBids(true);
            }
        }
        catch (error) {
            console.log("error", error)
            setRowDataBidTable([]);
        }
    };

    // Function to handle Tab Pannel Changes
    const handleTabPannelChanges = (event, newValue) => {
        setTabPannerlValue(newValue);
    };

    // ^^^^^^^^^^^^^^^^^^^^^^^Function to handle All the chat^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const changeFilter = (e) => {
        setChatSearchFilter(e);
    };

    const togetCarrierName = async (carrierName) => {
        let loadId = '';
        let userId = '';
        if (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier')) {
            loadId = selectedRowDataValue.load_id;
            userId = '&Carrier_id=' + user.email;
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                getCommentResponse.forEach(element => {
                    if (element.carrier_id === carrierName) {
                        setChatAllMessages(element);
                        setChatFirstCarrierName(element.carrier_id);
                        setChatFirstCompanyName(element.carrier_company);
                        setChatCarrierMCNumber(`MC #${getCommentResponse[0]['carrier_mc_number']}`);
                    }
                });
            }
        }
        catch (error) { }
    };
    const getSingleCarrierMessage = async (event) => {
        if (event.target.id !== null && event.target.id !== '') {
            let carrierName = event.target.id;
            togetCarrierName(carrierName);
        }
        else {
            let carrierName = event.target.parentElement.id;
            togetCarrierName(carrierName);
        }
    };

    const onEnterButtonForChat = event => {
        if (event.key === 'Enter') {
            if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                event.preventDefault();
                onSendMessageClick(document.getElementById('inputMesage').value, 'Normal Message');
            }
            else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                event.preventDefault();
            }
        }
    };

    //Function to updatestcomment
    const updateTestComment = async (secondaryArray, action) => {
        if (secondaryArray.carrier_company === null) {
            secondaryArray.carrier_company = userCompanyName;
        }
        try {
            let updateCommentResponse = await updateCommentApiCall(secondaryArray, action);
            return updateCommentResponse;
        }
        catch (error) {
            console.log('create comment', error);
        }
    };

    //Function to createCommentNotification
    const createCommentNotification = async (data) => {
        try {
            let response = await axios({
                url: environmentVariables.createCommentNotification,
                method: 'patch',
                data: data,
            });
            return response;
        }
        catch (error) { }
    };

    //Function to send message 
    const onSendMessageClick = async (enteredMessage, messageType) => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            if (enteredMessage !== null && enteredMessage !== '') {
                document.getElementById('inputMesage').value = '';
                if (chatAllMessages.length === 0) {
                    const secondaryArray = [
                        {
                            time_stamp: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                            broker_id: selectedRowDataValue.posted_by_user,
                            carrier_id: user.email,
                            load_id: selectedRowDataValue.load_id,
                            driver_id: name,
                            messages: [
                                {
                                    date: moment().format('hh:mm A'),
                                    messanger_id: user.email,
                                    message: enteredMessage,
                                    message_type: 'Normal Message',
                                    read_status: [{ name: user.email, status: true }, { name: selectedRowDataValue.posted_by_user, status: false }],
                                    user: user.nickname,
                                },
                            ],
                        },
                    ];
                    let createCommentResponse = await createCommentApiCall(secondaryArray, 'put');
                    if (createCommentResponse) {
                        fetchAllComments(createCommentResponse);
                        setChatAllMessages(createCommentResponse);
                    }
                    if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
                        sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                        sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValue, []);
                        sendMessageForSocketIO('newNotification||load_id:' + selectedRowDataValue.load_id, '', false);
                        setIsNotificationSent(true)
                    }
                    return await createCommentNotification({
                        data: {
                            load_id: selectedRowDataValue.load_id,
                            fromEmail: user.email,
                            toEmail: selectedRowDataValue.posted_by_user,
                            message: enteredMessage
                        },
                    });
                } else {
                    const secondaryArray = chatAllMessages;
                    if (name !== undefined) {
                        secondaryArray.driver_id = name;
                    }
                    else {
                        secondaryArray.driver_id = selectedRowDataValue.driver_id;
                    }
                    secondaryArray.time_stamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                    if (secondaryArray.read_status) {
                        const ownUser = secondaryArray.read_status.find(
                            ({ name }) => name === user.email
                        );
                        const otherUser = secondaryArray.read_status.find(
                            ({ name }) => name !== user.email
                        );
                        if (ownUser !== undefined && ownUser.name === user.email) {
                            ownUser.status = true;
                        }
                        if (otherUser !== undefined) {
                            otherUser.status = false;
                        }
                    }
                    if (secondaryArray.driver_id === undefined) {
                        secondaryArray.driver_id = 'NA';
                    }
                    secondaryArray.messages = [
                        ...secondaryArray.messages,
                        {
                            date: moment().format('hh:mm A'),
                            messanger_id: user.email,
                            message: enteredMessage,
                            user: user.nickname,
                            message_type: messageType,
                            read_status: [{ name: user.email, status: true }, { name: selectedRowDataValue.posted_by_user, status: false }],
                        },
                    ];
                    let response = await updateTestComment(secondaryArray, 'patch');
                    if (response) {
                        fetchAllComments(response);
                        setChatAllMessages(response);
                    }
                    document.getElementById('inputMesage').value = '';
                    if (user.email === process.env.REACT_APP_DRIVER_USER || user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
                        sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, selectedRowDataValue.posted_by_user, true, selectedRowDataValue, []);
                        sendMessageForSocketIO('ThereIsANewMessageFromCarrier||load_id:' + selectedRowDataValue.load_id + '||posted_by_user:' + selectedRowDataValue.posted_by_user, process.env.REACT_APP_ADMIN_USER, false, selectedRowDataValue, []);
                        sendMessageForSocketIO('newNotification', '', false);
                        setIsNotificationSent(true);
                    }
                    return await createCommentNotification({
                        data: {
                            load_id: selectedRowDataValue.load_id,
                            fromEmail: user.email,
                            toEmail: selectedRowDataValue.posted_by_user,
                            message: enteredMessage
                        },
                    });
                }
            }
        }
    };

    const fetchAllComments = async (loadInformation) => {
        let allChatResponse;
        let loadId = '';
        let userId = '';
        if (recieveMessage === true && loggedInuserCompanyRole === '') {
            if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
                loadId = loadInformation.load_id;
                userId = '&carrier_id=' + loadInformation.carrier_id;
            }
        }
        if (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
            loadId = loadInformation.load_id;
            userId = '&carrier_id=' + user.email;
        }
        else if (window.localStorage.getItem('bidswonLoadId') !== undefined && window.localStorage.getItem('bidswonLoadId') !== '') {
            loadId = loadInformation.load_id;
            userId = '&carrier_id=' + loadInformation.carrier_name;
        }
        else if (recieveMessage !== true) {
            if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
                loadId = loadInformation.load_id;
                userId = '&carrier_id=' + loadInformation.carrier_name;
            }
        }
        try {
            const getCommentResponse = await getCommentApiCall(loadId, userId);
            if (getCommentResponse) {
                if (getCommentResponse !== undefined) {
                    if (loadInformation.load_id === getCommentResponse[0].load_id) {
                        setChatAllMessages(getCommentResponse[0]);
                        if (user.email === process.env.REACT_APP_ADMIN_USER || loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
                            setChatFirstCarrierName(getCommentResponse[0]['broker_id']);
                            setChatFirstCompanyName(getCommentResponse[0]['broker_company']);
                            setChatCarrierMCNumber(`MC #${getCommentResponse[0]['broker_mc_number']}`);
                        }
                        setAllChat(getCommentResponse);
                        allChatResponse = getCommentResponse;
                    }
                }
            }
        }
        catch (error) {
            if (loggedInuserCompanyRole === 'Carrier' || (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Carrier' || loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin'))) {
                let tempAllChatArray = {
                    'Items': [{
                        'broker_id': loadInformation.posted_by_user,
                        'time_stamp': moment.utc().format('YYYY-MM-DD HH:mm:ss')
                    }]
                };
                setAllChat(tempAllChatArray);
                setChatFirstCarrierName(loadInformation.posted_by_user);
                setChatFirstCompanyName(loadInformation.posted_by_company);
                setChatCarrierMCNumber(`MC #${loadInformation.posted_by_mc_number}`);
                setChatAllMessages([]);
            }
        }
        return allChatResponse;
    };

    // Function to update chat read status on click of row
    const updateChatReadStatus = async (selectedRowChatDetails) => {
        let listValues = JSON.parse(JSON.stringify(selectedRowChatDetails));
        if (listValues !== undefined) {
            listValues.forEach(element => {
                if (element.messages !== undefined) {
                    element.messages.forEach(message => {
                        if (message.read_status && message.read_status !== undefined) {
                            const findUsers = message.read_status.find(
                                ({ name }) => name === user.email
                            );
                            if (findUsers.status === false) {
                                findUsers.status = true;
                            }
                        }

                    });
                }
            });
            let response = await updateTestComment(listValues, 'patch');
            if (response) {
                sendMessageForSocketIO('chatHasBeenRead||load_id:' + response.load_id, '', false);
                sendMessageForSocketIO('newNotification', '', false);
            }
        }
    };

    //------------------------- Place to define Socket related functions ------------------------------

    // Function to handle send Socket IO messages
    const sendMessageForSocketIO = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        try {
            handleAddNotification(message, recieverId, sendNotification, selectedRow, bidDetails);
            const connectionIds = await handleSendMessageForSocketIO(message, recieverId, sendNotification, selectedRow, bidDetails)
            sendJsonMessage({ 'action': 'onMessage', 'message': message, 'connectionIds': connectionIds.toString() });
        }
        catch (e) {
            console.log('send message error', e);
        }
    };

    const handleAddNotification = async (message, recieverId, sendNotification, selectedRow, bidDetails) => {
        let loadId = '';
        let messages = '';
        if (message.includes('load_id')) {
            loadId = message.split('||')[1].split(':')[1];
            messages = message.split('||')[0];
        }
        let postedByUser = '';
        let wonUser = '';
        let bidCarrier = '';
        let postedCompany = '';
        let carrierCompany = '';
        let loadNumber = '';
        let bidCarriers = [];
        if (sendNotification === true) {
            if (messages === 'ThereIsANewMessageFromCarrier') {
                loadNumber = selectedRow.load_number;
                postedByUser = selectedRow.posted_by_user;
                bidCarrier = '';
                carrierCompany = userCompanyName;
            }
            let listValues = {
                'load_id': loadId,
                'receiver_id': recieverId,
                'bid_carrier': bidCarrier,
                'won_carrier': wonUser,
                'load_posted_by_user': postedByUser,
                'notified_user': user.email,
                'message': messages,
                'carrier_company': carrierCompany,
                'posted_company': postedCompany,
                'load_expired_status': false,
                'bid_carriers': bidCarriers,
                'load_number': loadNumber,
                'won_message': true,
                'time_stamp_notification': moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                'generated_date': moment(new Date()).format('MM/DD/YYYY'),
                'read_users_list': []
            };
            try {
                await addNotificationApiCall(listValues);
            } catch (e) {
                console.log(e);
            }
        }
    };

    // Function to recive all the socket io messages
    const recieveMessageForSocketIo = async (message) => {
        const recievedMessage = await handleSocketReceiveMessage(message, user.email);

        // Recieve message for a new chat
        if (recievedMessage.includes('ThereIsANewMessageFromBroker')) {
            setRecieveMessage(true);
            ScrollForParticularLoad(recievedMessage);
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    if (loggedInuserCompanyRole === 'Carrier') {
                        fetchAllComments({
                            'load_id': recievedMessage.split('||')[1].split(':')[1],
                            'posted_by_user': recievedMessage.split('||')[2].split(':')[1]
                        });
                    }
                    if (user.email === process.env.REACT_APP_ADMIN_USER) {
                        setRecieveMessage(true);
                        fetchAllComments({
                            'load_id': recievedMessage.split('||')[1].split(':')[1],
                            'carrier_id': recievedMessage.split('||')[2].split(':')[1]
                        }
                        );
                    }
                    let response = await getBidsWonLoadsApiCall(user.email);
                    if (response) {
                        const findResult = response.find(
                            ({ load_id }) => load_id === recievedMessage.split('||')[1].split(':')[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }
        // receiving message for chat read
        if (recievedMessage.includes('chatHasBeenRead')) {
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    let response = await getBidsWonLoadsApiCall(user.email);
                    if (response) {
                        const findResult = response.find(
                            ({ load_id }) => load_id === recievedMessage.split('||')[1].split(':')[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }

        // receiving message for new notification
        if (recievedMessage.includes('ThereIsNewNotifiction')) {
            fetchMainMuiDatatableValues();
        }

        if (recievedMessage.includes('YourLoadHasBennAccepted')) {
            let notificationMessage = '';
            if (recievedMessage.split('||')[2].split(':')[1] === user.email) {
                notificationMessage = 'Your bid has been accepted';
            }
            else {
                notificationMessage = 'Load has been awarded';
            }
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValueForSocketIO.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    Swal.fire({
                        text: notificationMessage,
                        type: null,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#0066cc'
                    });
                    closeSecondaryPane();
                }
            }
        }

        if (recievedMessage.includes('Your MC Certificate Verification is Succeeded')) {
            // handleLocalStorageValue(user.email)
            dispatch(handleUserDataValue(user.email));

            fetchAllComments({
                'load_id': selectedRowDataValueForSocketIO.load_id,
                'posted_by_user': selectedRowDataValueForSocketIO.posted_by_user
            });
            loadAndRealoadSecondGrid(selectedRowDataValueForSocketIO.load_id);
        }

        if (recievedMessage.includes('Load status changed to')) {
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    let response = await getBidsWonLoadsApiCall(user.email);
                    if (response) {
                        const findResult = response.find(
                            ({ load_id }) => load_id === recievedMessage.split('||')[1].split(':')[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }
    };

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    useEffect(() => {
        if (Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
        fetchMainMuiDatatableValues();
        handleGetDrivers();
    }, [userReduxData]);

    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity='success'>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs textColor='#101e35'>
                                        <Tab className={`${classes.leftAlign}`} style={{ color: '#101e35' }} label='Search Load' onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <Tooltip title='Expand More'>
                                        <IconButton button onClick={() => handleExpandMoreIconClick()} hidden={!expandCollapseTopPannel} >
                                            <ExpandMoreIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Expand Less'>
                                        <IconButton button onClick={() => handleExpandLessIconClick()} hidden={expandCollapseTopPannel} >
                                            <ExpandLessIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandCollapseTopPannel}>
                                <SearchForm isPaneOpen={isPaneOpen} closeSecondaryPane={closeSecondaryPane} ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPicesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid container direction='row' margin={2}>
                        <div style={{ width: mainGridPercent, paddingBottom: '15%', display: 'table', tableLayout: 'fixed' }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'Bids Won'}
                                    data={rowDataForLoadsWon}
                                    columns={columnDefsForLoadsWon}
                                    options={optionsForLoadsWon}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {
                            isPaneOpen && (
                                <React.Fragment>
                                    <div style={{ width: '1%' }}></div>
                                    <div style={{ width: '64%' }} border={1}>
                                        <Box
                                            boxShadow={3}>
                                            <Paper square className='square'>
                                                <Grid container xs={12} justify='flex-end'>
                                                    <Row>
                                                        {/* {(selectedRowDataValue.driver_name === null && selectedRowDataValue.pickup_location !== null) && (
                                                            <Tooltip title='Save changes' className='Save'>
                                                                <IconButton onClick={handleSavingDriverNameAndStatus}>
                                                                    <SaveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )} */}
                                                        <IconButton onClick={handleCloseSecondaryPanelConfirmation}>
                                                            <Tooltip title="Close">
                                                                <CloseIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Row>
                                                </Grid>
                                                <div style={{ height: 7 }} />
                                                <Row style={{ fontSize: 18 }}>
                                                    <Col sm>
                                                        <b>Load Details</b>
                                                    </Col>
                                                </Row>
                                                <div style={{ height: 5 }} />
                                                <LoadDetailsPannel allLoadDetails={selectedRowDataValue} saveStatus={handleSavingLoadStatus} changeDriver={handleSavingDriverNameAndStatus} driversList={companyDriversList} />
                                            </Paper>
                                        </Box>
                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        {
                                            <React.Fragment>
                                                <div style={{ height: 10 }} />
                                                <MUIDataTable
                                                    title={'Bid Details'}
                                                    data={rowDataBidTable}
                                                    columns={columnDefsBidTable}
                                                    options={optionsBidTable}
                                                ></MUIDataTable>
                                                <div style={{ height: 10 }} />
                                            </React.Fragment>
                                        }
                                        <div style={{ height: 10 }} />
                                        {
                                            ((loggedInuserCompanyRole === 'Carrier' || userCompanyName === 'SystemAdmin') ||
                                                (loggedInuserCompanyRole === 'Broker&Carrier' && (loggedInuserRole === 'Broker&Carrier' || loggedInuserRole === 'CompanyAdmin' || loggedInuserRole === 'Carrier'))) && (
                                                <React.Fragment>
                                                    <Divider />
                                                    <Grid>
                                                        <Box boxShadow={3}>
                                                            <Paper square className='paper'>
                                                                <Tabs value={tabPannerlValue} onChange={handleTabPannelChanges} indicatorColor='primary' textColor='primary'>
                                                                    <Tab label='Comments' />
                                                                </Tabs>
                                                                <TabPanel value={tabPannerlValue} index={0}>
                                                                    <React.Fragment>
                                                                        <div className='homePage'>
                                                                            <div className='leftSide'>
                                                                                <Header />
                                                                                {/* <SearchBar changeFilter={changeFilter} /> */}
                                                                                {Array.isArray(allChat) && allChat.length !== 0 && (
                                                                                    <List className='Custom-MuiList-root' style={{ maxHeight: 'calc(100vh - 109px)', overflow: 'auto', objectFit: 'cover', height: 415 }}>
                                                                                        {
                                                                                            allChat
                                                                                                .sort(function (b, a) {
                                                                                                    return new Date(a.time_stamp) - new Date(b.time_stamp);
                                                                                                }).map((item, i) => {
                                                                                                    return (
                                                                                                        <div key={item.broker_id}>
                                                                                                            <ListItem button alignItems='flex-start' id={item.broker_id} value={item.broker_id} className='Custom-MuiListItem-root' key={item.broker_id} onClick={(event) => getSingleCarrierMessage(event)}>
                                                                                                                {
                                                                                                                    (item.broker_id !== undefined) ?
                                                                                                                        <div id={item.broker_id} className='discussion'>
                                                                                                                            <div id={item.broker_id} className='leftDiscussion'>
                                                                                                                                {
                                                                                                                                    (item.broker_id.charAt(0) !== undefined) && (
                                                                                                                                        <Avatar>{item.broker_id.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div id={item.broker_id} className='rightDiscussion'>
                                                                                                                                <div id={item.broker_id} className='rightDiscussionTop'>
                                                                                                                                    <div id={item.broker_id} className='rightDiscussionTopLeft'>{item.broker_company || selectedRowDataValue.posted_by_company}</div>
                                                                                                                                </div>
                                                                                                                                <div id={item.broker_id} className='rightDiscussionBas'>
                                                                                                                                    {
                                                                                                                                        (item.bid_amount !== undefined && item.bid_amount !== '$') ?
                                                                                                                                            <div className='rightDiscussionTopRight'>{'Bid: ' + item.bid_amount}</div>
                                                                                                                                            :
                                                                                                                                            <div className='rightDiscussionTopRight'>{'Bid: ' + '$' + selectedRowDataValue.bid_amount}</div>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div id={selectedRowDataValue.posted_by_user} className='discussion'>
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className='leftDiscussion'>
                                                                                                                                {
                                                                                                                                    (selectedRowDataValue.posted_by_user.charAt(0) !== undefined) && (
                                                                                                                                        <Avatar>{selectedRowDataValue.posted_by_user.charAt(0).toUpperCase()}</Avatar>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <div id={selectedRowDataValue.posted_by_user} className='rightDiscussion'>
                                                                                                                                <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionTop'>
                                                                                                                                    <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionTopLeft'>{selectedRowDataValue.posted_by_company}</div>
                                                                                                                                </div>
                                                                                                                                <div id={selectedRowDataValue.posted_by_user} className='rightDiscussionBas'>
                                                                                                                                    {
                                                                                                                                        (selectedRowDataValue.bid_amount !== undefined && selectedRowDataValue.bid_amount !== '$') && (
                                                                                                                                            <div className='rightDiscussionTopRight'>{'Bid: ' + selectedRowDataValue.bid_amount}</div>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                }
                                                                                                            </ListItem>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                                )
                                                                                        }
                                                                                    </List>
                                                                                )}
                                                                            </div>
                                                                            <div className='rightSide'>
                                                                                {
                                                                                    (chatFirstCarrierName !== undefined) ?
                                                                                        <HeaderChat name={chatFirstCarrierName.substring(0, chatFirstCarrierName.lastIndexOf('@'))} companyName={chatFirstCompanyName} mcnumber={chatCarrierMCNumber} />
                                                                                        :
                                                                                        <HeaderChat name={selectedRowDataValue.posted_by_user.substring(0, selectedRowDataValue.posted_by_user.lastIndexOf('@'))} mcnumber={selectedRowDataValue.posted_by_mc_number} />
                                                                                }
                                                                                <Chat discussion={chatAllMessages} />
                                                                                <div>
                                                                                    <form onKeyPress={(e) => { onEnterButtonForChat(e); }}>
                                                                                        <Paper component='form' className={classes.customPaper}>
                                                                                            <InputBase id='inputMesage' autoComplete='off' className={classes.CustomInput} style={{ width: '85%' }} placeholder='Enter your message' />
                                                                                            <IconButton onClick={() => onSendMessageClick(document.getElementById('inputMesage').value)}>
                                                                                                <Tooltip title="Send">
                                                                                                    <SendIcon />
                                                                                                </Tooltip>
                                                                                            </IconButton>
                                                                                        </Paper>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                </TabPanel>
                                                            </Paper>
                                                        </Box>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main>
        </div>
    );
};

export default LoadsWon;