import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Homepage from '../features/HomePage';
import Callaback from '../features/Callback';
import RejectedUser from '../module/RejectedUser';
import CompanyAdminPage from '../features/CompanyAdminpage';
import Verify from '../features/Verify';
import PrivacyPolicy from '../staticPages/PrivacyPolicy';
import UpdateProfile from '../module/UpdateProfile';
import CompanyProfilePage from '../features/CompanyProfilePage';
import UserAgreement from '../module/UserAgreement';
import UserCreateProfile from '../module/UserCreateProfile';
import UnderVerification from '../module/UnderVerification';
import SystemAdminPage from '../features/SystemAdminPage';
import SystemAdminCompanies from '../features/SystemAdminCompaniesPage';
import ProspectiveClientPage from '../features/ProspectiveClientPage';
import CombinedLandingPage from '../features/CombinedLandingPage';
import SuspendedUser from '../module/SuspendUser';
import DeletedUser from '../module/DeletedUser';
import ExpiredLandingPage from '../features/ExpiredLoadPage';
import AwardedLandingPage from '../features/AwardedLoadsPage';
import LoadsWonPage from '../features/LoadsWonPage';
import ActiveLoadsPage from '../features/ActiveLoadspage';
import { useAuth0 } from '@auth0/auth0-react';
import PreferredCarrierPage from '../features/PreferredCarrierPage';
import slugid from 'slugid';
import SuspendedCompany from '../module/SuspendedCompany';
import FeedbackUserPage from '../features/FeedbackPage';
import ExpiredBidPage from '../features/ExpiredBidPage';
import AdminPage from '../features/AdminPage';
import NotAuthorizedOrNoPath from '../features/NotAuthorizedNoPathPage';
import Page403Error from '../features/Page403Error';
import NotAuthenticatePage from '../features/NotAuthenticatedPage';
import SavedLanePage from '../features/SavedLanesPage';
import SupportPage from '../features/SupportPage';
import LoadsDeliverInProgessPage from '../features/LoadsDeliverInProgressPage';
import LoadsAssignedPage from '../features/LoadsAssignedPage';
import LoadsDeliveredPage from '../features/LoadsDeliveredPage';
import ResetPasswordPage from '../features/ResetPasswordPage';
import CertificationsPage from '../features/CertificationsPage';
import EmailSettingsPage from '../features/EmailSettingsPage';
import FeedbackOnDriverUserPage from '../features/FeedBackOnDriverPage';
import { useSelector } from 'react-redux';
import ProfilePage from '../features/ProfilePage';

const AllRoute = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    useEffect(() => {
        let urlFromEmail = window.location.href;
        let loadGuid = urlFromEmail.substring(urlFromEmail.indexOf('=') + 1);
        if (window.location.href.includes('loadboard?loadId') || window.location.href.includes('loadsassigned?loadId') || window.location.href.includes('activeloads?loadId') || window.location.href.includes('activebids?loadId') || window.location.href.includes('awardedloads?loadId') || window.location.href.includes('bidswon?loadId')) {
            if (isAuthenticated) {
                if (slugid.decode(loadGuid)) {
                    if (window.location.href.includes('loadboard?loadId')) {
                        window.localStorage.setItem('loadboardLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('activeloads?loadId')) {
                        window.localStorage.setItem('activeloadsLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('activebids?loadId')) {
                        window.localStorage.setItem('activebidsLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('awardedloads?loadId')) {
                        window.localStorage.setItem('awardedloadsLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('bidswon?loadId')) {
                        window.localStorage.setItem('bidswonLoadId', slugid.decode(loadGuid));
                    }
                }
            } else {
                if (slugid.decode(loadGuid)) {
                    if (window.location.href.includes('loadboard?loadId')) {
                        window.localStorage.setItem('loadboardLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('activeloads?loadId')) {
                        window.localStorage.setItem('activeloadsLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('activebids?loadId')) {
                        window.localStorage.setItem('activebidsLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('awardedloads?loadId')) {
                        window.localStorage.setItem('awardedloadsLoadId', slugid.decode(loadGuid));
                    }
                    if (window.location.href.includes('bidswon?loadId')) {
                        window.localStorage.setItem('bidswonLoadId', slugid.decode(loadGuid));
                    }
                }
                loginWithRedirect();
            }
        }
    }, [isAuthenticated, loginWithRedirect]);

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route path="/privacypolicy" component={PrivacyPolicy} />
                    <Route path="/notauthenticated" component={NotAuthenticatePage} />
                    <Route path="/feedback" component={FeedbackUserPage} />
                    {isAuthenticated && (
                        <React.Fragment>
                            <Route path="/callback" component={Callaback} />
                            <Route path="/updateprofile" component={UpdateProfile} />
                            <Route path="/settings" component={ProfilePage} />
                            <Route path="/profile" component={ProfilePage} />
                            <Route path="/companyprofile" component={CompanyProfilePage} />
                            <Route path="/companyadmin" component={CompanyAdminPage} />
                            <Route path="/preferredcarrier" component={PreferredCarrierPage} />
                            <Route path="/rejecteduser" component={RejectedUser} />
                            <Route path="/usersuspended" component={SuspendedUser} />
                            <Route path="/userdeleted" component={DeletedUser}/>
                            <Route path="/companysuspended" component={SuspendedCompany} />
                            <Route path="/useragreement" component={UserAgreement} />
                            <Route path="/createprofile" component={UserCreateProfile} />
                            <Route path="/underverification" component={UnderVerification} />
                            <Route path="/systemadmin" component={SystemAdminPage} />
                            <Route path="/systemadmincompanies" component={SystemAdminCompanies} />
                            <Route path="/prospectiveclients" component={ProspectiveClientPage} />
                            <Route path="/loadboard" component={CombinedLandingPage} />
                            <Route path="/expiredloads" component={ExpiredLandingPage} />
                            <Route path="/awardedloads" component={AwardedLandingPage} />
                            <Route path="/bidswon" component={LoadsWonPage} />
                            <Route path="/allbidswon" component={LoadsWonPage} />
                            <Route path="/activebids" component={ActiveLoadsPage} />
                            <Route path="/allactivebids" component={ActiveLoadsPage} />
                            <Route path="/activeloads" component={ActiveLoadsPage} />
                            <Route path="/expiredbids" component={ExpiredBidPage} />
                            <Route path="/allexpiredbids" component={ExpiredBidPage} />
                            <Route path="/admin" component={AdminPage} />
                            <Route path="/dashboard" component={AdminPage} />
                            <Route path="/savedlanes" component={SavedLanePage} />
                            <Route path="/allsavedlanes" component={SavedLanePage} />
                            <Route path="/mcverify" component={SupportPage} />
                            <Route path="/loadsassigned" component={LoadsAssignedPage} />
                            <Route path="/loadspickedup" component={LoadsDeliverInProgessPage} />
                            <Route path="/loadsdelivered" component={LoadsDeliveredPage} />
                            <Route path="/documents" component={CertificationsPage} />
                            <Route path="/emailsettings" component={EmailSettingsPage} />
                            <Route path="/feedbackondriver" component={FeedbackOnDriverUserPage} />
                            <Route path="/forbidden" component={Page403Error} />
                            <Route exact component={NotAuthorizedOrNoPath} />
                        </React.Fragment>
                    )}
                    <Route path="/callback" component={Callaback} />
                    <Route path="/verify" component={Verify} />
                    <Route path="/feedback" component={FeedbackUserPage} />
                    {
                        userReduxData.userData.user_role === null &&
                        userReduxData.userData.company_name !== 'SystemAdmin' &&
                        userReduxData.userData.user_role !== 'Driver' &&
                        userReduxData.userData.company_name !== 'SupportPage' && (
                            <Route exact component={NotAuthorizedOrNoPath} />
                        )
                    }
                </Switch>
                <Route path="/resetpassword" component={ResetPasswordPage} />
            </Router>
        </div>
    );
};

export default AllRoute;
