export const pascalCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const scrollTopOnRowClick = (expandCollapseTopPannel) => {
    if (expandCollapseTopPannel === true) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else {
        window.scrollTo({ top: 650, behavior: 'smooth' });
    }
}