import axios from 'axios';
import { environmentVariables } from '../../constants/environment';

/****************************************Certificate************************************/

/************************Add Mc certificate data************************* */
export const addMCCertificateApiCall = async (certificateData) => {
    try {
        let response = await axios({
            url: environmentVariables.addmccertificate,
            method: 'post',
            data: certificateData
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Uploading mc certificate failed');
    }
};

/***********************************Get mc certificate**********************************/
export const getMCCertificateApiCall = async (loggedInCompanyMCNumber) => {
    try {
        let response = await axios({
            url: environmentVariables.getmccertificate + '?mc_number=' + loggedInCompanyMCNumber,
            method: 'get'
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Error in getting user certificate data');
    }
};

/* ************************ Get all certificate ************************* */
export const getAllMCCertificateApiCall = async () => {
    try {
        let response = await axios({
            url: environmentVariables.getmccertificate,
            method: 'get',
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Get all mc certificates error');
    }
};

/************************Extra JSON data from mc certificate************************* */
export const extractJSONDataApiCall = async (companyId, selectedFileInBinary, Pdf) => {
    try {
        let response = await axios({
            url: environmentVariables.extractJSONdata,
            method: 'post',
            data: {
                customerId: companyId,
                fileBinary: selectedFileInBinary,
                fileName: Pdf,
                Environment: 'develop'
            }
        });
        return response;
    }
    catch (error) {
        console.log(error, 'Error in extrating JSON data');
        return error ;
    }
};

/* ************************ update certificate status************************* */
export const updateCertificateStatusApiCall = async (comment, certificateStatus, clickRowData) => {
    try {
        let response = await axios({
            url: environmentVariables.updatecertificatestatus,
            method: 'patch',
            data: {
                comment_reason: comment,
                verification_status: certificateStatus,
                company_mc_bumber: clickRowData
            }
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Update status for certificate failed');
    }
};
/************************Deleting mc certificate************************* */
export const deleteMCCertificateApiCall = async (completeSelectedRowData) => {
    try {
        let response = await axios({
            url: environmentVariables.deletemccertificate,
            method: 'patch',
            data: completeSelectedRowData
        });
        return JSON.parse(response.data.body);
    }
    catch (error) {
        console.log(error, 'Deleting mc certificate failed');
    }
};
