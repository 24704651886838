import React from 'react';

// components
import Navbar from '../../components/Navbar';
import Breadcumb1 from '../../components/bredcrumb1';
import Footer from '../../components/Footer';
import LoadsDeliverInProgressScript from '../../module/LoadsDeliverInProgress';

const LoadsDeliverInProgressPage = () => {
    return (
        <div className="price-sec">
            <Navbar />
            <Breadcumb1 bdtitle={'DeliverInProgress'} />
            <LoadsDeliverInProgressScript/>
            <Footer />
        </div>
    );
};

export default LoadsDeliverInProgressPage;