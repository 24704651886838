import React from 'react';
export const ColorExcellent = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 513 513" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M256.5 513C398.161 513 513 398.161 513 256.5C513 114.839 398.161 0 256.5 0C114.839 0 0 114.839 0 256.5C0 398.161 114.839 513 256.5 513Z" fill="#ECB13A" />
            <path d="M250.7 487.5C384.905 487.5 493.7 378.705 493.7 244.5C493.7 110.295 384.905 1.5 250.7 1.5C116.495 1.5 7.7002 110.295 7.7002 244.5C7.7002 378.705 116.495 487.5 250.7 487.5Z" fill="#FFDE4F" />
            <path d="M394.9 245.4C407.3 245.3 417.2 255.8 416.1 268.2C407.5 362.3 340.6 435.8 258.6 436.7C176.6 437.6 108.2 365.5 97.6 271.6C96.2 259.3 105.9 248.5 118.3 248.4L394.9 245.4V245.4Z" fill="#645033" />
            <path d="M146.9 198.7C165.954 198.7 181.4 183.254 181.4 164.2C181.4 145.146 165.954 129.7 146.9 129.7C127.846 129.7 112.4 145.146 112.4 164.2C112.4 183.254 127.846 198.7 146.9 198.7Z" fill="#645033" />
            <path d="M369.2 199.1C388.254 199.1 403.7 183.654 403.7 164.6C403.7 145.546 388.254 130.1 369.2 130.1C350.146 130.1 334.7 145.546 334.7 164.6C334.7 183.654 350.146 199.1 369.2 199.1Z" fill="#645033" />
            <path d="M256.7 269L115.2 270.5C116.9 287.6 121.5 304.1 128.7 319.1L130.2 322.2L257.3 320.8L384.4 319.4L385.8 316.3C392.6 301.2 396.9 284.5 398.2 267.4L256.7 269Z" fill="white" />
            <path d="M326.2 375.5C283.7 346.6 231.5 347.2 189.6 377L178.7 384.8L189.8 392.3C232.3 421.2 284.5 420.6 326.4 390.8L337.3 383L326.2 375.5V375.5Z" fill="#EF4F43" />
        </svg>
    );
};
