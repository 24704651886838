import React, { useState, useEffect, useRef } from 'react';
import {
    CssBaseline,
    IconButton,
    Typography,
    Grid,
    Paper,
    Snackbar,
    Box,
    Tabs,
    Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Row, Col, Tooltip } from 'react-bootstrap';
import cities from 'cities';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import MuiAlert from '@material-ui/lab/Alert';
import { toNumber } from 'lodash';
import useWebSocket, { } from 'react-use-websocket';
import LoadDetailsPannel from '../../components/LoadDetailsPannel';
import { animateScroll } from 'react-scroll';
import { pascalCase, scrollTopOnRowClick } from '../../utils';

// Imports to have all the Icons
import { ExpandMoreIcon } from '../../assets/SvgIcons/ExpandMore';
import { ExpandLessIcon } from '../../assets/SvgIcons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// Import statements for CSS and environment
import { environmentVariables } from '../../constants/environment';
import Navbar from '../../components/Navbar';

//import DriverNavBar from "../Drawer"
import MUIDrawer from '../../components/Drawer';

import sortDate from '../../components/Sorting/sortDate';
import sortNum from '../../components/Sorting/sortNum';
import { fetchLoadApiCall, getDriverLoadsApiCall } from '../../apis/loads';
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';

//import statement related to search
import SearchForm from '../SearchForm';
import { handleSocketReceiveMessage } from '../WebSocketConnection';

//@@@@@@@@@@@@@@@@@@@ Global Variable @@@@@@@@@@@@@@@@@@@@@@@
let isPaneOpenForSocketIO = false;
let selectedRowDataValueForSocketIO = [];
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

//--------------------------------------------*********************-----------------------------------------
//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
}));

// Function to handle MuiAlert
const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const LoadsDeliveredScript = () => {
    //------------------------- Place to declare all the constants ------------------------------
    const classes = useStyles();
    const history = useHistory();
    const { user } = useAuth0();

    //constant to handle useref 
    const SearchFormRef = useRef();

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const dispatch = useDispatch();
    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [recieveMessage, setRecieveMessage] = useState(false);

    /// Constant to handle expand and collapse the top pannel
    const [expandCollapseTopPannel, setExpandCollapseTopPannel] = useState(true);

    // Constants to handle Top Tab Pannel 
    const [topTabPannelValue, setTopTabPannelValue] = useState(0);

    // Constant to handle Load Update,Clear and Post buttons
    const [showing, setShowing] = useState(false);

    // Constant to handel main grid percentage
    const [mainGridPercent, setMainGridPercent] = useState('100%');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [selectedRowDataValue, setSelectedRowDataValue] = useState([]);

    // Constant for Pickup from location
    const [searchPickUpFromCity, setSearchPickUpFromCity] = useState('');
    const [searchPickUpFromState, setSearchPickUpFromState] = useState('');
    const [searchPickUpFromZip, setSearchPickUpFromZip] = useState('');
    const [searchPickUpMiles, setSearchPickUpMiles] = useState('');

    // Constant for Pickup from location
    const [searchDeliverToCity, setSearchDeliverToCity] = useState('');
    const [searchDeliverToState, setSearchDeliverToState] = useState('');
    const [searchDeliverToZip, setSearchDeliverToZip] = useState('');
    const [searchDeliveryMiles, setSearchDeliverMiles] = useState('');


    //constant to handle mui data table loader
    const [loading, setLoading] = useState(false);

    // constant to handle drawer notification badge
    const [isNotificationSent, setIsNotificationSent] = useState(false);

    // Constants to handle Awarded Loads
    const [rowDataForLoadsDelivered, setRowDataForLoadsDelivered] = useState([]);
    const optionsForLoadsDelivered = {
        filter: false,
        search: false,
        responsive: 'stacked',
        filterType: false,
        selectableRows: false,
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) => handleRowClick(rowData, rowState),
        onFilterChipClose: (index, removedFilter, filterList) => (onFilterChipCloseEvent(index, removedFilter, filterList)),
        setRowProps: (row, dataIndex) => {
            if (window.localStorage.getItem('LoadId') && !isPaneOpen) {
                if (window.localStorage.getItem('LoadId') === row[0]) {
                    handleRowClick(row, dataIndex);
                }
            }
        },
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    `There are no loads delivered by ${pascalCase(user.nickname)}`
            },
        }
    };
    const columnDefs = [
        { label: 'PostId', name: 'load_id', options: { display: false } },
        {
            label: 'Pick-up From',
            name: 'pickup_dest',
            options: {
                customBodyRender: (value) => {
                    return (
                        <div className="bold">
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up From: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpCity = value;
                        const valEnteredPickUpCity = filters;
                        if (valEnteredPickUpCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredPickUpCity.length; i++) {
                                if (valPickUpCity.includes(valEnteredPickUpCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Pick-up Date',
            name: 'pickupfrom_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Pick-up Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valPickUpDate = value;
                        const valEnteredPickUpDate = filters[0];
                        if (valEnteredPickUpDate) {
                            if (!moment(valPickUpDate).isSameOrAfter(valEnteredPickUpDate)) {
                                return valPickUpDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Deliver To',
            name: 'delivery_dest',
            options: {
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return (
                        <div className="bold">
                            {value}
                        </div>
                    );
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Deliver To: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryCity = value;
                        const valEnteredDeliveryCity = filters;
                        if (valEnteredDeliveryCity.length > 0) {
                            let found = false;
                            for (let i = 0; i < valEnteredDeliveryCity.length; i++) {
                                if (valDeliveryCity.includes(valEnteredDeliveryCity[i])) {
                                    found = true;
                                }
                            }
                            if (found === true) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                },
            },
        },
        {
            label: 'Miles (Time)',
            name: 'cities_distance',
            options: {
                sort: true,
                display: displaySpecificColums, filterList: [],
                sortCompare: (order) => {
                    return sortNum(order);
                },
            },
        },
        {
            label: 'Delivery Date',
            name: 'pickupto_date',
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortDate(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Delivery Date: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valDeliveryDate = value;
                        const valEnteredDeliveryDate = filters[0];
                        if (valEnteredDeliveryDate) {
                            if (!moment(valDeliveryDate).isSameOrAfter(valEnteredDeliveryDate)) {
                                return valDeliveryDate;
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Truck Type',
            name: 'truck_type',
            options: {
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Truck Type : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valTruckType = value;
                        const valEnteredNumber = filters[0];
                        if (valEnteredNumber) {
                            if (valTruckType.trim() !== '') {
                                if (valEnteredNumber.includes(',')) {
                                    let splittingValue = valEnteredNumber.split(',');
                                    if (splittingValue.length === 2) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])) {
                                            return valTruckType;
                                        }
                                    }
                                    else if (splittingValue.length === 3) {
                                        if (!valTruckType.includes(splittingValue[0])
                                            || !valTruckType.includes(splittingValue[1])
                                            || !valTruckType.includes(splittingValue[2])) {
                                            return valTruckType;
                                        }
                                    }
                                }
                                else {
                                    if (!valTruckType.includes(valEnteredNumber)) {
                                        return valTruckType;
                                    }
                                }
                            }
                            else {
                                return [];
                            }

                        }
                    }
                }
            }
        },
        {
            label: 'Weight',
            name: 'max_weight',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Weight : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valMaxWeight = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            return valMaxWeight > valEnteredNumber;
                        }
                    }
                }
            },
        },
        {
            label: 'Number Of Pieces',
            name: 'total_item_count',
            options: {
                sort: true,
                display: displaySpecificColums,
                customBodyRender: (value) => {
                    if (value !== '') {
                        return value;
                    }
                    else {
                        return 'NA';
                    }
                },
                sortCompare: (order) => {
                    return sortNum(order);
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Number Of Pieces : ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valNoOfPieces = toNumber(value);
                        const valEnteredNumber = toNumber(filters[0]);
                        if (valEnteredNumber) {
                            if (!isNaN(valNoOfPieces) && valNoOfPieces !== NaN && valNoOfPieces !== 'NaN' && valNoOfPieces !== 0) {
                                return valNoOfPieces > valEnteredNumber;
                            }
                            else {
                                return [];
                            }
                        }
                    }
                }
            },
        },
        {
            label: 'Posting Company', name: 'posted_by_company', options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[9].toUpperCase()
                    );
                }
            }
        },
        {
            label: 'Load ID',
            name: 'load_number',
            options: {
                // display: displaySpecificColums,
                customBodyRender: (value) => {
                    return value;
                },
                filterType: 'custom',
                filterList: [],
                customFilterListOptions: {
                    render: (value) => {
                        if (value[0]) {
                            return `Load Number: ${value[0]}`;
                        }
                    }
                },
                filterOptions: {
                    logic(value, filters) {
                        const valLoadNumber = toNumber(value);
                        const valEnteredLoadNumber = toNumber(filters[0]);
                        if (valEnteredLoadNumber) {
                            let tempVal = valLoadNumber.toString();
                            let tempEnteredVal = valEnteredLoadNumber.toString();
                            if (!tempVal.startsWith(tempEnteredVal)) {
                                return tempVal;
                            }
                        }
                    }
                }
            }
        },
        {
            label: 'Status', name: 'status', options: {
                display: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        <div className='correctIcon' style={{ fontWeight: 'bold' }}>{rowuserdata.rowData[11].toUpperCase()}</div>
                    );
                }
            }
        },
    ];

    const [columnDefForLoadsDelivered, setColumnDefForLoadsDelivered] = useState(columnDefs);

    // Constants for showing Loaddetails in the secondary pane
    const [loadLoadNotes, setLoadLoadNotes] = useState('');

    //Function to scroll bottom
    function scrollToBottom() {
        animateScroll.scrollToBottom({});
    }

    //Function to scroll On recieveing Message
    function ScrollForParticularLoad(recievedMessage) {
        if (isPaneOpen === true) {
            if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                scrollToBottom();
            }
        }
        else {
            window.localStorage.setItem('LoadId', recievedMessage.split('||')[1].split(':')[1]);
            scrollToBottom();
        }
    }

    // Function to handle remove backround color from selection
    const removeBackroundColour = () => {
        for (let i = 0; i < rowDataForLoadsDelivered.length; i++) {
            let domObject = document.getElementById(`MUIDataTableBodyRow-${i}`);
            if (domObject !== null) {
                let styleObject = domObject.getAttribute('style');
                if (styleObject !== null) {
                    if (styleObject.toString().includes('9FD2F3')) {
                        domObject.removeAttribute('style', 'background: #9FD2F3');
                    }
                }
            }

        }
    };
    // Function to fetch all the data main MuiDatatble
    const fetchMainMuiDatatableValues = async () => {
        let loadsDeliveredList = [];
        try {
            let fetchLoadResponse = await getDriverLoadsApiCall(user.email);
            if (fetchLoadResponse) {
                if (fetchLoadResponse.length === 0) {
                    setLoading(true);
                }
                else {
                    setLoading(true);
                }
                //To delete loads 
                // Local array to handelremoval of rows
                fetchLoadResponse.sort((a, b) =>
                    b.time_stamp.localeCompare(a.time_stamp)
                );
                // Setting Up expired loads table
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    loadsDeliveredList = fetchLoadResponse.filter(function (singleVal) {
                        return singleVal['status'] === 'Delivered';
                    });
                    setRowDataForLoadsDelivered(loadsDeliveredList);
                    removeBackroundColour();
                }
                else if (user.email === process.env.REACT_APP_ADMIN_USER) {
                    loadsDeliveredList = fetchLoadResponse.filter(function (singleVal) {
                        return /*singleVal['driver_name'] !== user.email &&*/ singleVal['status'] === 'Delivered';

                    });
                    setRowDataForLoadsDelivered(loadsDeliveredList);
                    removeBackroundColour();
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    //function to assign values on row click in userdetails table
    const handleRowClick = (selectedRowData, rowMeta) => {
        scrollTopOnRowClick(expandCollapseTopPannel)
        setMainGridPercent('35%');
        setIsPaneOpen(true);
        const findResult = rowDataForLoadsDelivered.find(
            ({ load_id }) => load_id === selectedRowData[0]
        );
        setSelectedRowDataValue(findResult);
        setRecieveMessage(true);
        isPaneOpenForSocketIO = true;
        selectedRowDataValueForSocketIO = findResult;
        removeBackroundColour();
        if (!window.localStorage.getItem('LoadId')) {
            let row = document.getElementById(`MUIDataTableBodyRow-${rowMeta.dataIndex}`);
            row.setAttribute('style', 'background: #9FD2F3');
        }
        setLoadLoadNotes(findResult.load_notes);
        setDisplaySpecificColums(false);
    };

    // Function to handle pane close
    const closeSecondaryPane = () => {
        window.localStorage.removeItem('LoadId');
        setMainGridPercent('100%');
        setIsPaneOpen(false);
        setSelectedRowDataValue([]);
        removeBackroundColour();
        setDisplaySpecificColums(true);
    };

    const searchButtonClicked = () => {
        setExpandCollapseTopPannel(!expandCollapseTopPannel);
        // setTopTabPannelValue(1);
        setShowing(!showing);
        closeSecondaryPane();
    };

    const handleExpandMoreIconClick = () => {
        setTopTabPannelValue(0);
        setExpandCollapseTopPannel(false);
        localStorage.setItem('panelValue', 'false');
        closeSecondaryPane();
    };

    const handleExpandLessIconClick = () => {
        closeSecondaryPane();
        setExpandCollapseTopPannel(true);
        localStorage.setItem('panelValue', 'true');
    };

    // Function to search from Load number/id
    const onLoadNumberForSearch = (place) => {
        if (place.target.validity.valid || place.target.value === '') {
            const valForFilter = place.target.value;
            const FilterColumns = [...columnDefForLoadsDelivered];
            let filterList = [];
            filterList = [valForFilter];
            if (place.target.value.trim() === '') {
                FilterColumns[10].options.filterList = [];
            }
            else {
                FilterColumns[10].options.filterList = filterList;
            }
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    const getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            'sublocality_level_1' === addressArray[i].types[j] ||
                            'locality' === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };

    const getState = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
    };

    // Function to search From City
    const onFromPlaceSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const FilterColumns = [...columnDefForLoadsDelivered];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[1].options.filterList = filterList;
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    // function to get all nearby pickup cities within specified miles
    const getNearestPickUpCities = async (value) => {
        if (searchPickUpFromCity !== '') {
            setSnackBarMessageText('Data is loading for you....');
            setOpenSnackBar(true);
        }
        setSearchPickUpMiles(value);
        // var rad = zipcodes.radius(searchPickUpFromZip, value);
        let listvalues = [];
        // listvalues = rad
        // let count = 0
        // for (let i = 0; i < listvalues.length; i++) {
        //     if (listvalues[i] === searchPickUpFromZip) {
        //         count = count + 1
        //     }
        // }
        // if (count === 0) {
        //     listvalues.push(searchPickUpFromZip)
        // }
        try {
            let response = await axios({
                url:
                    environmentVariables.getnearbycities + '?zipList=' +
                    listvalues + 'functionality=pickupcitysearch',
                method: 'get',
            });
            if (response) {
                setOpenSnackBar(false);
                let filterList = [];
                const valForFilter = searchPickUpFromCity + ', ' + searchPickUpFromState + ' - ' + searchPickUpFromZip;
                response.data.forEach(element => {
                    if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== 'Active') {
                        if (element.pickup_dest === valForFilter) {
                            filterList.unshift(element.pickup_dest);
                        }
                        else {
                            filterList.push(element.pickup_dest);
                        }
                    }
                });
                if (filterList.length > 0) {
                    const FilterColumns = [...columnDefForLoadsDelivered];
                    FilterColumns[1].options.filterList = filterList;
                    setColumnDefForLoadsDelivered(FilterColumns);
                }
                else {
                    const FilterColumns = [...columnDefForLoadsDelivered];
                    let filterList = [];
                    filterList = [valForFilter];
                    FilterColumns[1].options.filterList = filterList;
                    setColumnDefForLoadsDelivered(FilterColumns);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to search From zip
    const updateFromZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const FilterColumns = [...columnDefForLoadsDelivered];
                let filterList = [];
                filterList = [valForFilter];
                FilterColumns[1].options.filterList = filterList;
                setColumnDefForLoadsDelivered(FilterColumns);
            }
        }
    };

    // Function to search Pick up From
    const handlePickUpDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const FilterColumns = [...columnDefForLoadsDelivered];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[2].options.filterList = filterList;
            setColumnDefForLoadsDelivered(FilterColumns);
        }
        else {
            const FilterColumns = [...columnDefForLoadsDelivered];
            FilterColumns[2].options.filterList = [];
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    // Function to search To city
    const onDeliverToSelectedForSearch = (place) => {
        if (place.geometry) {
            const valForFilter = getArea(place.address_components) + ', ' + getState(place.address_components) + ' - ' + cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode;
            const FilterColumns = [...columnDefForLoadsDelivered];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[3].options.filterList = filterList;
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    // function to get all nearby deliver cities within specified miles
    const getNearestCitiesForDeliverCity = async (value) => {
        if (searchDeliverToCity !== '') {
            setSnackBarMessageText('Data is loading for you....');
            setOpenSnackBar(true);
        }
        setSearchDeliverMiles(value);
        // var rad = zipcodes.radius(searchDeliverToZip, value);
        let listvalues = [];
        // listvalues = rad
        // let count = 0
        // for (let i = 0; i < listvalues.length; i++) {
        //     if (listvalues[i] === searchDeliverToZip) {
        //         count = count + 1
        //     }
        // }
        // if (count === 0) {
        //     listvalues.push(searchDeliverToZip)
        // }
        try {
            let response = await axios({
                url:
                    environmentVariables.getnearbycities + '?zipList=' +
                    listvalues + 'functionality=delivercitysearch',
                method: 'get',
            });
            if (response) {
                setOpenSnackBar(false);
                let filterList = [];
                const valForFilter = searchDeliverToCity + ', ' + searchDeliverToState + ' - ' + searchDeliverToZip;
                response.data.forEach(element => {
                    if (!element.load_Expired_Status && !element.load_Delete_Status && element.status !== 'Active') {
                        if (element.delivery_dest === valForFilter) {
                            filterList.unshift(element.delivery_dest);
                        }
                        else {
                            filterList.push(element.delivery_dest);
                        }
                    }
                });
                if (filterList.length > 0) {
                    const FilterColumns = [...columnDefForLoadsDelivered];
                    FilterColumns[2].options.filterList = filterList;
                    setColumnDefForLoadsDelivered(FilterColumns);
                }
                else {
                    const FilterColumns = [...columnDefForLoadsDelivered];
                    let filterList = [];
                    filterList = [valForFilter];
                    FilterColumns[2].options.filterList = filterList;
                    setColumnDefForLoadsDelivered(FilterColumns);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Function to search To zip
    const updateDeliverToZipCodeForSearch = (event) => {
        if (event.target.value.length === 5) {
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                const valForFilter = getFromCityState.city + ', ' + getFromCityState.state_abbr + ' - ' + event.target.value;
                const FilterColumns = [...columnDefForLoadsDelivered];
                let filterList = [];
                filterList = [valForFilter];
                FilterColumns[3].options.filterList = filterList;
                setColumnDefForLoadsDelivered(FilterColumns);
            }
        }
    };

    // Function to search Delivery Date
    const handleDeliverToDateChangeForSearch = date => {
        if (date) {
            const valForFilter = moment(date).format('MM/DD/YYYY');
            const FilterColumns = [...columnDefForLoadsDelivered];
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[5].options.filterList = filterList;
            setColumnDefForLoadsDelivered(FilterColumns);
        }
        else {
            const FilterColumns = [...columnDefForLoadsDelivered];
            FilterColumns[5].options.filterList = [];
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    // Function to handle select all checkbox for truck type search
    const handleSelectAllCheckboxChange = (allTruckType) => {
        const FilterColumns = [...columnDefForLoadsDelivered];
        if (allTruckType && allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[6].options.filterList = filterList;
            setColumnDefForLoadsDelivered(FilterColumns);
        }
        else {
            FilterColumns[6].options.filterList = [];
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    // Function to search checkbox truck type
    const handleCheckBoxChangeForSearch = (allTruckType) => {
        const FilterColumns = [...columnDefForLoadsDelivered];
        if (allTruckType.trim() !== '') {
            const valForFilter = allTruckType;
            let filterList = [];
            filterList = [valForFilter];
            FilterColumns[6].options.filterList = filterList;
            setColumnDefForLoadsDelivered(FilterColumns);
        }
        else {
            FilterColumns[6].options.filterList = [];
            setColumnDefForLoadsDelivered(FilterColumns);
        }
    };

    // Function to search max weight
    const handelTxtMaxWeightChangeForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const FilterColumns = [...columnDefForLoadsDelivered];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    FilterColumns[7].options.filterList = [];
                }
                else {
                    FilterColumns[7].options.filterList = filterList;
                }
                setColumnDefForLoadsDelivered(FilterColumns);
            }
        }
    };

    // Function to search number of pieces
    const handeltxtTotalNoOfPicesForSearch = (event) => {
        if (event.target.validity.valid || event.target.value === '') {
            let regExp = /^0+$/g;
            let found = regExp.test(event.target.value);
            let result = found ? true : false;
            if (result === false) {
                const valForFilter = event.target.value;
                const FilterColumns = [...columnDefForLoadsDelivered];
                let filterList = [];
                filterList = [valForFilter];
                if (event.target.value.trim() === '') {
                    FilterColumns[8].options.filterList = [];
                }
                else {
                    FilterColumns[8].options.filterList = filterList;
                }
                setColumnDefForLoadsDelivered(FilterColumns);
            }
        }
    };

    // Function to handle Filter Chip Close event
    const onFilterChipCloseEvent = (index, removedFilter, filterList) => {
        const filterColumns = [...columnDefForLoadsDelivered];
        switch (index) {
            case 1:
                {
                    filterColumns[1].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(2);
                    break;
                }
            case 2:
                {
                    filterColumns[2].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(3);
                    break;
                }
            case 3:
                {
                    filterColumns[3].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(4);
                    break;
                }
            case 5:
                {
                    filterColumns[5].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(6);
                    break;
                }
            case 6:
                {
                    const filterColumns = [...columnDefForLoadsDelivered];
                    filterColumns[6].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(7);
                    break;
                }
            case 7:
                {
                    filterColumns[7].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(8);
                    break;
                }
            case 8:
                {
                    filterColumns[8].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(9);
                    break;
                }
            case 10:
                {
                    filterColumns[10].options.filterList = [];
                    setColumnDefForLoadsDelivered(filterColumns);
                    SearchFormRef.current.onSearchClear(14);
                    break;
                }
            default:
                break;

        }
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    //---------------------------- Place to handle all the functions ----------------------------
    // FUnction to handle MuiTeme for stripped rows
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#ECECEC',
                        },
                        '&:hover': { backgroundColor: '#aab5be!important' },
                        '&:focus': { backgroundColor: '#000!important' },
                    },
                },
                MUIDataTableBodyCell: {
                }
            }
        });

    // Function to check the user role
    async function checkUserRole() {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === 'ProfileAccepted') {
                if (userReduxData.userData.user_role !== 'Driver') {
                    history.push({ pathname: '/forbidden' });
                }
            }
            else {
                history.push({ pathname: '/forbidden' });
            }
        }
    }

    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    // Function to recive all the socket io messages
    const recieveMessageForSocketIo = async (message) => {

        let recievedMessage = await handleSocketReceiveMessage(message, user.email)
        if (recievedMessage.includes('Load status changed to')) {
            fetchMainMuiDatatableValues();
            if (isPaneOpenForSocketIO) {
                if (selectedRowDataValue.load_id === recievedMessage.split('||')[1].split(':')[1]) {
                    let fetchLoadResponse = await getDriverLoadsApiCall(user.email);
                    if (fetchLoadResponse) {
                        const findResult = fetchLoadResponse.find(
                            ({ load_id }) => load_id === recievedMessage.split('||')[1].split(':')[1]
                        );
                        selectedRowDataValueForSocketIO = findResult;
                        setSelectedRowDataValue(findResult);
                    }
                }
            }
        }
        // receiving message for new notification
        if (recievedMessage.includes('ThereIsNewNotifiction')) {
            fetchMainMuiDatatableValues();
        }
    };

    // Function that is triggered on page load
    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
    }, []);

    useEffect(() => {
        if (Object.keys(userReduxData.userData).length) {
            checkUserRole();
        }
        fetchMainMuiDatatableValues();
    }, []);
    //---------------------------------------------------------------------------------------------
    return (
        <div className={classes.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <Navbar closeSecondaryPane={closeSecondaryPane} />
            <MUIDrawer notificationSent={isNotificationSent} />
            <main className={classes.content}>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid>
                        <Paper square>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <Tabs value={topTabPannelValue} indicatorColor="none" textColor="#101e35">
                                        <Tab className={`${classes.leftAlign}`} style={{ color: '#101e35', textTransform: 'none' }} label="Search Load" onClick={() => searchButtonClicked()} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Expand More">
                                        <IconButton button onClick={() => handleExpandMoreIconClick()} hidden={!expandCollapseTopPannel} >
                                            <ExpandMoreIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Expand Less">
                                        <IconButton button onClick={() => handleExpandLessIconClick()} hidden={expandCollapseTopPannel} >
                                            <ExpandLessIcon style={{ width: 45, height: 45, color: '#0d2346' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <div style={{ height: 5 }} />
                            <TabPanel value={topTabPannelValue} index={0} hidden={expandCollapseTopPannel}>
                                <div style={{ height: 5 }} />
                                <SearchForm isPaneOpen={isPaneOpen} closeSecondaryPane={closeSecondaryPane} ln={onLoadNumberForSearch} columnDefs={columnDefs} ref={SearchFormRef} filter={onFilterChipCloseEvent} onPlaceSelected={onFromPlaceSelectedForSearch} fromCityZip={updateFromZipCodeForSearch} pickUpDate={handlePickUpDateChangeForSearch} onToPlaceSelected={onDeliverToSelectedForSearch} deliverCityMiles={getNearestCitiesForDeliverCity} deliverZip={updateDeliverToZipCodeForSearch} deliverDate={handleDeliverToDateChangeForSearch} weight={handelTxtMaxWeightChangeForSearch} pieces={handeltxtTotalNoOfPicesForSearch}
                                    handleCheckBoxChangeForSearch={handleCheckBoxChangeForSearch} handleSelectAllCheckboxChange={handleSelectAllCheckboxChange} />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Typography>
                <div style={{ height: 20 }} />
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: mainGridPercent, paddingBottom: '15%', display: 'table', tableLayout: 'fixed' }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={'Loads Delivered'}
                                    data={rowDataForLoadsDelivered}
                                    columns={columnDefForLoadsDelivered}
                                    options={optionsForLoadsDelivered}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {
                            isPaneOpen && (
                                <React.Fragment>
                                    <div style={{ width: '1%' }}></div>
                                    <div style={{ width: '64%', paddingBottom: '10%' }} border={1}>
                                        <Box
                                            boxShadow={3}>
                                            <Paper square className="square">
                                                <Grid container xs={12} justify="flex-end">
                                                    <IconButton onClick={closeSecondaryPane}>
                                                        <Tooltip title="Close">
                                                            <CloseIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Grid>
                                                <div style={{ height: 7 }} />
                                                <Row style={{ fontSize: 18 }}>
                                                    <Col sm>
                                                        <b>Load Details</b>
                                                    </Col>
                                                </Row>
                                                <div style={{ height: 5 }} />
                                                <LoadDetailsPannel allLoadDetails={selectedRowDataValue} />
                                            </Paper>
                                        </Box>
                                        <div style={{ height: 10 }} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Typography>
            </main>
        </div>
    );
};

export default LoadsDeliveredScript;