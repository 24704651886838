import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Box,
    CssBaseline,
    IconButton,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';
import { Row, Col } from 'react-bootstrap';
import { Disappointed } from '../../assets/SvgIcons/Disappointed';
import { Satisfied } from '../../assets/SvgIcons/Satisfied';
import { Good } from '../../assets/SvgIcons/Good';
import { Excellent } from '../../assets/SvgIcons/Excellent';

import { ColorDisappointed } from '../../assets/SvgIcons/ColorDisappointed';
import { ColorSatisfied } from '../../assets/SvgIcons/ColorSatisfied';
import { ColorGood } from '../../assets/SvgIcons/ColorGood';
import { ColorExcellent } from '../../assets/SvgIcons/ColorExcellent';

import ReCAPTCHA from 'react-google-recaptcha';
import { pascalCase } from '../../utils';

import '../feedback/index.css';

import { getAllUserInformationApiCall } from '../../apis/user';
import { sendFeedBackDataToAdminApiCall, sendThanksEmailToUserApiCall } from '../../apis/emailtoAdmin';
import { getAwardedLoadsApiCall, editLoadDetailApiCall } from '../../apis/loads';

// Import dispatch and selector
import { useDispatch, useSelector } from 'react-redux';
import { handleUserDataValue } from '../../store/users/Action';

const styles = {
    root: {
        display: 'flex',
    },
    header: {
        backgroundImage: `url(${'https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/Loadeo_pricing_1920x550.jpg'})`,
        height: '110vh',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    header1: {
        marginTop: '1%'
    },
    content: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content1: {
        marginLeft: '1%'
    }
};

const FeedbackOnDriverUser = () => {
    const history = useHistory();
    const { user, isAuthenticated } = useAuth0();
    const [userName, setUserName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [userComments, setUserComments] = useState('');
    const [validateUser, setValidateUser] = useState(false);

    const userReduxData = useSelector((state) => {
        return state.userReducer;
    });

    const dispatch = useDispatch();

    // Constant to handle rating
    const [rating, setRating] = useState(false);
    const [reaction, setReaction] = useState('');

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState('');
    const [selectedRowDataValue, setSelectedRowDataValue] = useState('');

    // Function to fetch all the data main MuiDatatble
    const fetchMainMuiDatatableValues = async () => {
        try {
            let response = await getAwardedLoadsApiCall(user.email);
            if (response) {
                if (user.email !== process.env.REACT_APP_ADMIN_USER) {
                    // Setting Up awarded loads table
                    let awardedLoadList = response.filter(function (singleVal) {
                        return singleVal['posted_by_user'] === user.email && singleVal['load_number'] === window.localStorage.getItem('feedbackLoadNo');
                    });
                    setSelectedRowDataValue(awardedLoadList);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const validate = () => {
        let errorValidation = {};
        let updateaddress = emailId;
        if (emailId === '' && user !== undefined) {
            updateaddress = user.email;
        }
        errorValidation.userName = userName ? '' : 'error';
        errorValidation.emailId = updateaddress ? '' : 'error';
        errorValidation.userComments = userComments ? '' : 'error';
        if (userName === null && emailId === null && userComments === null) {
            Swal.fire({
                text: 'Full Name, Email Id and User Comments are Required',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        if(reaction === '') {
            errorValidation.reaction = reaction ? '' : 'error';
            setRating(true);
        }
        setErrors({
            ...errorValidation
        });
        return Object.values(errorValidation).every(x => x === '');
    };

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant='filled' {...props} />;
    };

    const handleUserCommentsChange = (event) => {
        setUserComments(event.target.value);
    };

    //function to get user Name
    const getAllResponse = async () => {
        try {
            let alluserinformationdata = await getAllUserInformationApiCall();
            if (alluserinformationdata !== undefined) {
                if (user !== undefined) {
                    const findUser = alluserinformationdata.find(
                        ({ email_address }) => email_address === user.email
                    );
                    setUserName(pascalCase(findUser.first_name) + ' ' + pascalCase(findUser.last_name),);
                    setEmailId(findUser.email_address);
                }
            }
        }
        catch (error) {
            console.log(error, 'Get user details error in feedback');
        }
    };

    //function to trigger on click of approve in newuser table
    const handleSubmit = async () => {
        let userId;
        if (isAuthenticated === true && emailId === '') {
            userId = user.email;
            setEmailId(user.email);
        }
        else {
            userId = emailId;
        }
        if (validateUser === true) {
            let listValues = selectedRowDataValue[0];
            listValues.load_expired_status = false;
            listValues.status = 'delivered';
            listValues = listValues = { ...listValues, 'feedback_on_driver': userComments };
            if (validate()) {
                try {
                    let data = await editLoadDetailApiCall(listValues);
                    if (data !== undefined) {
                        setSnackBarMessageText('FeedBack Submitted SuccessFully');
                        setOpenSnackBar(true);
                        window.localStorage.removeItem('feedbackLoadNo');
                        let thanksData =
                        {
                            'user_name': userName,
                            'user_email': userId,
                            'user_comments': userComments
                        };
                        try {
                            let feedBackData = await sendFeedBackDataToAdminApiCall(thanksData);
                            if (feedBackData !== undefined) {
                                if (window.location.pathname === '/feedbackondriver' && isAuthenticated === false) {
                                    history.push({ pathname: '/' });
                                }
                                if (window.location.pathname === '/feedbackondriver' && isAuthenticated === true) {
                                    history.push({ pathname: '/awardedloads' });
                                }
                                try {
                                    await sendThanksEmailToUserApiCall(user.email);
                                }
                                catch (error) {
                                    console.log('error', error);
                                }
                                setUserComments('');

                            }
                        }
                        catch (error) {
                            console.log('error', error);
                        }
                    }
                }
                catch (error) {
                    console.log('error', error);
                }
            }
        }
    };

    const onChange = (value) => {
        if (value) {
            setValidateUser(true);
        }
    };

    // Function to check the user role
    async function checkUserRole() {
        if (window.localStorage.ifUserExist) {
            if (userReduxData.userData.login_status === 'ProfileAccepted') {
                if (userReduxData.userData.company_role !== 'Broker') {
                    history.push({ pathname: '/forbidden' });
                }
            }
            else{
                history.push({ pathname: '/forbidden' });
            }
        }
    }

    const storeRating = (rating) => {
        setReaction(rating);
        setUserComments(rating);
        setRating(false);
    };

    const removeRating = () => {
        setReaction('');
        setUserComments('');
    };

    // on page load event
    useEffect(() => {
        dispatch(handleUserDataValue(user.email));
        setUserName(window.localStorage.getItem('userName'));
        if (user !== undefined) {
            setEmailId(user.email);
        }
        fetchMainMuiDatatableValues();
        getAllResponse();
    }, []);

    useEffect(() => {
        if(Object.keys(userReduxData.userData).length){
            checkUserRole();
        }
        fetchMainMuiDatatableValues();
    }, [userReduxData]);

    return (
        <div className={styles.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === 'Data is loading for you....' ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity='success'>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <div style={styles.header}>
                <div style={styles.content}>
                    <Grid container direction='column' className='rowStyle' style={{ minHeight: '90vh' }}>
                        <Grid container xs={12} sm={6} alignItems='center' direction='column' style={{ height: '90vh' }}>
                            <Paper id={'center'} style={{ marginTop: '10%' }}>
                                <Grid container direction='column' className='gridStyle' style={{ backgroundColor: '#0d2346' }}>
                                    <div className="headerDiv">Rate your Experience! </div>
                                </Grid>
                                <Grid container direction='column' alignItems='center' className='gridStyle'>
                                    <Row style={{ paddingTop: '15px' }}>
                                        {(reaction !== 'Disappointed') ?
                                            <IconButton onClick={() => { storeRating('Disappointed'); }}>
                                                <Tooltip title='Disappointed'>
                                                    <Disappointed />
                                                </Tooltip>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => { removeRating(); }}>
                                                <Tooltip title='Unselect Disappointed'>
                                                    <ColorDisappointed />
                                                </Tooltip>
                                            </IconButton>
                                        }
                                        {(reaction !== 'Satisfied') ?
                                            <IconButton onClick={() => { storeRating('Satisfied'); }}>
                                                <Tooltip title='Satisfied'>
                                                    <Satisfied />
                                                </Tooltip>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => { removeRating(); }}>
                                                <Tooltip title='Unselect Satisfied'>
                                                    <ColorSatisfied />
                                                </Tooltip>
                                            </IconButton>
                                        }
                                        {(reaction !== 'Happy') ?
                                            <IconButton onClick={() => { storeRating('Happy'); }}>
                                                <Tooltip title='Happy'>
                                                    <Good />
                                                </Tooltip>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => { removeRating(); }}>
                                                <Tooltip title='Unselect Happy'>
                                                    <ColorGood />
                                                </Tooltip>
                                            </IconButton>
                                        }
                                        {(reaction !== 'Excellent') ?
                                            <IconButton onClick={() => { storeRating('Excellent'); }}>
                                                <Tooltip title='Excellent'>
                                                    <Excellent />
                                                </Tooltip>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => { removeRating(); }}>
                                                <Tooltip title='Unselect Excellent'>
                                                    <ColorExcellent />
                                                </Tooltip>
                                            </IconButton>
                                        }
                                    </Row>
                                    <Row style={{ justifyContent: 'center', width: '45%'}}>
                                        <Typography className="helperText" component={'div'} hidden={!rating} variant='caption' style={{ fontSize: '12px', paddingLeft: 8, color: 'red' }}>Rate your Experience</Typography>
                                    </Row>
                                </Grid>
                                <Grid container style={{ width: '100%', height: '40%' }}>
                                    <Box border={0} padding={1} style={{ width: '100%', height: 290 }}>
                                        <Row className='rowStyle' style={{ width: '95%', margin: 5 }}>
                                            <Col>
                                                <TextField
                                                    label='Your Comments'
                                                    style={{ color: userComments === '' ? 'red' : 'black', width: '100%', margin: '5px 0px 5px 0px' }}
                                                    value={userComments}
                                                    error={errors.userComments && userComments === ''}
                                                    multiline
                                                    rows={4}
                                                    variant='outlined'
                                                    onChange={(val) => handleUserCommentsChange(val)} />
                                            </Col>
                                        </Row>
                                        <Row className='rowStyle' style={{ marginBottom: '2%' }}>
                                            <div className='rc-anchor rc-anchor-normal rc-anchor-light'>
                                                <ReCAPTCHA width='200%'
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Row>
                                        <Row className='rowStyle' style={{ marginLeft: 10, marginRight: 10 }}>
                                            <Col>
                                                <Button onClick={() => { handleSubmit(); }} style={validateUser === true ? { margin: '0px', border: '1px solid #09F', backgroundColor: '#0d2346', color: 'white', fontWeight: 'bold', width: '100%', padding: '10px', cursor: 'pointer' } : { margin: '0px', color: 'white', backgroundColor: '#d8d8d8', fontWeight: 'bold', width: '100%', padding: '10px', cursor: 'pointer' }} >Submit </Button>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};
export default FeedbackOnDriverUser;